import {TableType as BookingTableType} from "../../containers/bookings/BookingList/BookingListColumns";
import {BookingType} from "../../generated/graphql";
import {
    csvArray,
    csvBirthdayAsAge,
    csvBookingType,
    csvBoolean,
    csvDate,
    csvInvoice,
    csvMoney,
    csvPayDate,
    csvPaymentType,
} from "./TableColumnExports";
import {CurrencyShort} from "../renderAmount";
import {isDefined} from "../typeScriptHelpers";

export const bookingValues = (
    key: string,
    row: BookingTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    outputFormat?: "pdf" | "csv",
    // eslint-disable-next-line max-params
): string | number => {
    const firstCourseLesson = row.courseLessons[0];
    const lastCourseLesson = row.courseLessons[row.courseLessons.length - 1];

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "bookingNumber":
            return `${row.bookingNumberPrefix}-${row.bookingNumber}`;
        case "bookingNumberPrefix":
            return `${row.bookingNumberPrefix}`;
        case "bookingType":
            return csvBookingType(row.bookingType as BookingType);
        case "updateDate":
            return csvDate(row.updateDate);
        case "noticeStaff":
            return `${row.noticeStaff}`;
        case "noticeBooker":
            return `${row.noticeBooker}`;
        case "coursePriceId":
            return `${row.coursePriceId}`;
        case "payDate":
            return csvPayDate(row.payDate);
        case "coursePrice":
            return `${row.coursePrice.grossPrice}`;
        case "invoice":
            return csvInvoice(row.invoice);
        case "paymentType":
            return csvPaymentType(row.paymentType);
        case "courseLessons":
            return `${row.courseLessons.length}`;
        case "bookedBy":
            return csvBoolean(Boolean(row.bookedBy === "Booker"));
        case "courseType":
            return `${firstCourseLesson.course.courseType.name}`;
        case "prefixedCourseNumber":
            return `${firstCourseLesson.course.prefixedCourseNumber}`;
        case "bookerFullName":
            return `${row.attendee?.booker?.firstname} ${row.attendee?.booker?.lastname}`;
        case "phoneNumber":
            return `${row.attendee?.booker?.phoneNumber}`;
        case "mobileNumber":
            return `${row.attendee?.booker?.mobileNumber}`;
        case "email":
            return `${row.attendee?.booker?.email}`;
        case "attendeeFullName":
            return `${row.attendee?.firstname} ${row.attendee?.lastname}`;
        case "city":
            return `${row.attendee?.booker?.city}`;
        case "location":
            return `${firstCourseLesson.location.name}`;
        case "firstCourseLesson":
            return csvDate(firstCourseLesson.startDateTime, true);
        case "lastCourseLesson":
            return csvDate(lastCourseLesson.startDateTime, true);
        case "billed":
            return isDefined(row.invoice) === true ? "Ja" : "Nein";
        case "grossPrice":
            return csvMoney(row.grossPrice, currencyShort, outputFormat);
        case "netPrice":
            return csvMoney(row.netPrice, currencyShort, outputFormat);
        case "vat":
            return `${row.vat}`;
        case "invoiceNumber":
            return `${row.invoice?.invoiceNumber}`;
        case "isSpecial":
            return row.isSpecial === true ? "Ja" : "Nein";
        case "emailNotifications":
            return notExportable;
        case "nonDisclosureNotice":
            return `${row.nonDisclosureNotice}`;
        case "birthday":
            return csvDate(row.attendee?.birthday);
        case "age":
            return csvBirthdayAsAge(row.attendee?.birthday);
        case "dateOfBooking":
            return csvDate(row.createDate);
        case "createDate":
            return csvDate(row.createDate, true);
        case "credit":
            return csvMoney(row.credit, currencyShort);
        case "instructors":
            return csvArray(
                firstCourseLesson.course.instructors.map(
                    (instructor) => `${instructor.firstname} ${instructor.lastname}`,
                ),
            );
        case "lessonCount":
            return `${row.courseLessons.length}`;
        default:
            return notExportable;
    }
};
