import {useCurrency} from "../../../helpers/currencyHelper";
import {CsvDataRow, getCSVData} from "../../../helpers/tableCsvExport/getCSVData";
import {TablesEnum} from "../../../generated/graphql";

export const useExportHandler = ({
    selectedRows,
    userColumns,
    allColumns,
    hiddenColumns,
    tablesEnum,
}: {
    selectedRows: Array<any>;
    userColumns: Array<string>;
    allColumns: {[key: string]: string};
    hiddenColumns?: Array<string>;
    tablesEnum?: TablesEnum;
}) => {
    const currency = useCurrency();

    const csvDataPDF: Array<CsvDataRow> = getCSVData({
        selectedRows,
        userColumns,
        allColumns,
        hiddenColumns,
        currency,
        tablesEnum,
        outputFormat: "pdf",
    });

    const csvDataCSV: Array<CsvDataRow> = getCSVData({
        selectedRows,
        userColumns,
        allColumns,
        hiddenColumns,
        currency,
        tablesEnum,
        outputFormat: "csv",
    });

    return {csvDataPDF, csvDataCSV};
};
