import moment from "moment";
// import {naturalLanguageAge} from "../../helpers/natualLanguageAge";
import {
    naturalLanguageAge,
    naturalLanguageAgeShort,
} from "../natualLanguageAge";
import {TableType as BookingTableType} from "../../containers/bookings/BookingList/BookingListColumns";
import i18n from "../../services/i18n";
import {
    BookedBy,
    BookingType,
    Currency,
    Gender,
    GeneralLocationContact,
    Maybe,
    PaymentInterval,
    PaymentMethod,
    PaymentType,
} from "../../generated/graphql";
import {CurrencyShort, renderAmount} from "../renderAmount";
import {getPaymentMethodType} from "../getPaymentMethodType";

export const csvNumber = (number: number): number => {
    return number;
};

export const csvString = (string: string): string => {
    return string;
};

export const csvMoney = (
    amount: number | string | undefined | null,
    currency: Currency | CurrencyShort,
    outputFormat?: "pdf" | "csv",
): string => {
    if (outputFormat === "csv") {
        return Number(amount)
            .toFixed(2)
            .replace(".", ",");
    }

    if (!amount) return renderAmount({amount: 0, currency});

    return renderAmount({amount: Number(amount), currency});
};

// export const csvMoney = (amount: number | string | undefined | null, currency: Currency | CurrencyShort): string => {
//     if (!amount) return 0;

//     return Number(amount);
//     // if (!amount) return renderAmount({amount: 0, currency});

//     // return renderAmount({amount: Number(amount), currency});
// };

export const csvDate = (date: string, time?: boolean): string => {
    return moment(date).format("DD.MM.YYYY");
};

export const csvTime = (date: string): string => {
    return moment(date).format("HH:mm");
};

export const csvDateTime = (date: string, time?: boolean): string => {
    return moment(date).format("DD.MM.YYYY HH:mm");
};

export const csvDateTimeCourseLesson = (
    startDateTime: string,
    endDateTime: string,
): string => {
    // return `${csvDateTime(startDateTime)} - ${csvDateTime(endDateTime)}`;
    return `${csvDate(startDateTime)} ${csvTime(startDateTime)} - ${csvTime(
        endDateTime,
    )}`;
};

export const csvArray = (array: Array<string>): string => {
    return array.join(", ");
};

export const csvBoolean = (boolean: boolean): string => {
    return boolean ? "Ja" : "Nein";
};

export const csvBookingNumber = ({
    bookingNumberPrefix,
    bookingNumber,
    canceled,
}: {
    bookingNumberPrefix: string;
    bookingNumber: number | string;
    canceled?: boolean;
}): string => {
    const canceledText = canceled ? "storniert" : "";

    return `${bookingNumberPrefix}-${bookingNumber} ${canceledText}`;
};

export const csvAge = (months: number): string => {
    return naturalLanguageAge(months);
};

export const csvAddress = (address: {
    street: string;
    houseNumber: string;
    postCode: string;
    city: string;
}): string => {
    return `${address.street} ${address.houseNumber}, ${address.postCode} ${address.city}`;
};

export const csvGender = (gender: Gender): string => {
    return i18n.containers.common.Gender[gender]();
};

export const csvBirthdayAsAge = (birthday: string): string => {
    return naturalLanguageAgeShort(moment().diff(birthday, "months"));
};

// SPECIAL CASES ----------------------------------------------------------------------------------------------
export const csvInvoice = (invoice: BookingTableType["invoice"]): string => {
    return invoice
        ? `${invoice.invoiceNumberPrefix}-${invoice.invoiceNumber}`
        : "-";
};

export const csvPaymentType = (
    paymentType: Maybe<PaymentType> | undefined,
): string => {
    switch (paymentType) {
        case PaymentType.PrePaid:
            return i18n.containers.payments.DonePaymentsList.prePaid();
        case PaymentType.Bill:
            return i18n.containers.payments.DonePaymentsList.bill();
        case PaymentType.Monthly:
            return i18n.containers.payments.DonePaymentsList.monthly();
        case PaymentType.OnTheSpot:
            return i18n.containers.payments.DonePaymentsList.onTheSpot();
        default:
            return "-";
    }
};

export const csvBookedBy = (bookedBy: BookedBy): string => {
    switch (bookedBy) {
        case BookedBy.Staff:
            return "Mitarbeiter:in";
        case BookedBy.Booker:
            return "Bucher:in";
        default:
            return "-";
    }
};

export const csvPayDate = (payDate: string | null): string => {
    return payDate ? moment(payDate).format("DD.MM.YYYY") : "offen";
};

export const csvBookingType = (bookingType: BookingType): string => {
    switch (bookingType) {
        case BookingType.Booking:
            return "Buchung";
        case BookingType.Waitlist:
            return "Warteliste";
        case BookingType.PlaceReservation:
            return "Platzreservierung";
        default:
            return "-";
    }
};

export const csvPaymentMethod = (
    paymentMethod?: Partial<PaymentMethod> | null,
): string => {
    if (!paymentMethod) return ("-" as unknown) as string;

    const method = getPaymentMethodType(paymentMethod);

    switch (method) {
        case "cash":
            return "Bar";
        case "bankTransfer":
            return "Überweisung";
        case "creditCard":
            return "Kreditkarte";
        case "sepaDebit":
            return "Lastschrift";
        case "payPal":
            return "Paypal";
        case "esr":
            return "Einzugsschein";
        case "none":
            return "Keine";
        default:
            return "-";
    }
};

export const csvPercent = (percent: number | string): string => {
    const percentFormated = Number(percent) * 100;

    return `${percentFormated.toFixed(2)}%`;
};

// COURSE SPECIFIC CASES ---------------------------------------------------------------------------------------

export const csvCoursePrice = (
    grossPrice: number | string | undefined | null,
    currency: Currency | CurrencyShort,
    paymentInterval: PaymentInterval,
): string => {
    if (!grossPrice) return renderAmount({amount: 0, currency});

    if (PaymentInterval.PerCourse === paymentInterval) {
        return `${renderAmount({amount: Number(grossPrice), currency})} / Kurs`;
    }

    return `{${renderAmount({
        amount: Number(grossPrice),
        currency,
    })} / ${i18n.containers.courses.CourseEdit.perLesson()}`;
};

// PAYMENT SPECIFIC CASES --------------------------------------------------------------------------------------
export const csvPaymentDone = (
    paymentDone: string | null | undefined,
): string => {
    return paymentDone ? moment(paymentDone).format("DD.MM.YYYY") : "-";
};

export const csvPaymentAmount = (
    openAmount: number | null,
    currency: Currency | CurrencyShort,
    outputFormat?: "pdf" | "csv",
): string => {
    if (outputFormat === "csv") {
        return Number(openAmount)
            .toFixed(2)
            .replace(".", ",");
    }

    if (!openAmount) return renderAmount({amount: 0, currency});

    return renderAmount({amount: Number(openAmount), currency});
};

export const csvSepaClearance = (
    paymentMethod: Partial<PaymentMethod>,
    sepaClearance: boolean,
): string => {
    const method = getPaymentMethodType(paymentMethod);

    if (method === "sepaDebit") {
        return csvBoolean(sepaClearance);
    }

    return "-";
};

export const csvOpenAmount = (
    openAmount: Maybe<number> | undefined,
    paymentAmount: number | null,
    currency: Currency | CurrencyShort,
    outputFormat?: "pdf" | "csv",
): string | number => {
    if (outputFormat === "csv") {
        return Number(openAmount)
            .toFixed(2)
            .replace(".", ",");
    }
    // if (outputFormat === "csv") {
    //     return Number(openAmount);
    // }

    if (!openAmount) {
        return paymentAmount
            ? csvMoney(paymentAmount, currency)
            : csvMoney(0, currency);
    }

    return csvMoney(openAmount, currency);
};

// LOCATION SPECIFIC CASES -------------------------------------------------------------------------------------

export const csvGeneralLocationContact = (
    generalLocationContact: GeneralLocationContact,
): string => {
    return `${generalLocationContact.email} - ${generalLocationContact.phoneNumber}`;
};

// CUSTOMER SPECIFIC CASES -------------------------------------------------------------------------------------
export const csvCustomerNumber = (
    customerNumber: number | null | undefined,
    customerNumberPrefix: string,
): string => {
    if (!customerNumber) return "-";

    return `${customerNumberPrefix}-${customerNumber}`;
};
