import {TableType as PaymentTableType} from "../../containers/payments/PaymentsListColumns";
import {
    csvBookingNumber,
    csvDate,
    csvInvoice,
    csvMoney,
    csvOpenAmount,
    csvPaymentAmount,
    csvPaymentDone,
    csvPaymentMethod,
    csvPaymentType,
    csvPercent,
    csvSepaClearance,
} from "./TableColumnExports";
import {CurrencyShort} from "../renderAmount";

export const paymentValues = (
    key: string,
    row: PaymentTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    outputFormat?: "pdf" | "csv",
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);
    const firstCourseLesson = row.booking.courseLessons[0];
    const attendee = row.booking.attendee;
    const booking = row.booking;
    const bookingNumber = booking.bookingNumber;
    const bookingNumberPrefix = booking.bookingNumberPrefix;
    const canceled = booking.canceled;

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "bookingNumber":
            // return `${row.booking.bookingNumberPrefix}-${row.booking.bookingNumber}`;
            return csvBookingNumber({
                bookingNumberPrefix,
                bookingNumber,
                canceled,
            });
        case "courseType":
            return `${firstCourseLesson.course.courseType.name}`;
        case "courseNumber":
            return `${firstCourseLesson.course.prefixedCourseNumber}`;
        case "booker":
            return `${attendee?.booker?.firstname} ${attendee?.booker?.lastname}`;
        case "attendee":
            return `${attendee?.firstname} ${attendee?.lastname}`;
        case "city":
            return firstCourseLesson.location.city;
        case "location":
            return firstCourseLesson.location.name;
        case "firstCourseLesson":
            return csvDate(firstCourseLesson.startDateTime, true);
        case "createDate":
            return csvDate(row.createDate, true);
        case "paymentDue":
            return csvDate(row.paymentDue, true);
        case "paymentDone":
            return csvPaymentDone(row.paymentDone);
        case "paymentAmount":
            return csvPaymentAmount(row.paymentAmount, currencyShort, outputFormat);
        case "invoice":
            return csvInvoice(row.invoice);
        case "paymentType":
            return csvPaymentType(row.booking.paymentType);
        case "paymentMethod":
            return csvPaymentMethod(row.paymentMethod);
        case "sepaClearance":
            return csvSepaClearance(row.paymentMethod, Boolean(row.sepaClearance));
        case "vat":
            return csvPercent(row.booking.vat);
        case "openAmount":
            return csvOpenAmount(row.openAmount, row.paymentAmount, currencyShort, outputFormat);
        case "invoiceDate":
            if (!row.invoice?.updateDate) {
                return "-";
            }

            return csvDate(row.invoice.updateDate, true);
        case "title":
            return row.title ?? "Automatisch";
        default:
            return notExportable;
    }
};
