import {
    ControlFilled,
    ControlOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import {Button, Card, Space, Tooltip} from "antd";
import {ColumnProps} from "antd/lib/table";
import {RowSelectionType} from "antd/es/table/interface";
import React, {FC, memo, useState} from "react";
import classnames from "classnames";
import {blue} from "@ant-design/colors";
import {RowSelectMethod, TableRowSelection} from "antd/lib/table/interface";
import {colorBlueSlight} from "../../../styles/colors";
import css from "./TableToolBar.less";
import ExportModal from "../../../views/ExportModal/ExportModal";
import {
    BlankMailTypes,
    GetColumnsForTableDocument,
    MailDataType,
    TablesEnum,
    useGetColumnsForTableQuery,
    useGetCompanyInfoQuery,
    useUpdateTableColumnSettingsMutation,
} from "../../../generated/graphql";
import SendEmailTemplate, {
    CompatibleMailDataType,
} from "./SendEmailTemplate/SendEmailTemplate";
import ColumnConfigurator from "../../../views/ColumnConfigurator/ColumnConfigurator";
import EmailTemplatePicker from "../../templates/EmailRefactoring/EmailTemplatePicker";
import TransferMultipleBookings from "./TransferMultipleBookings";
import BulkDeleteWaitlist from "./BulkDeleteWaitlist";
import SendSmsModal from "../../sms/SendSmsModal/SendSmsModal";
import BulkShowInWeb from "./BulkShowInWeb";
import {useExportHandler} from "./useExportHandler";

const radio: RowSelectionType = "radio";

export type ReceiverType = {
    key: string;
    label: string;
    value: string;
};

export type BulkButtonType =
    | "columnSelector"
    | "pdf"
    | "checkList"
    | "bulkDelete"
    | "eMail"
    | "transferBookings"
    | "bulkDeleteWaitlist"
    | "sms"
    | "bulkShowInWeb";

type TableToolBarProps = {
    selectMode?: Function;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns?: Array<ColumnProps<any>>;
    allColumns: {[key: string]: string};
    variableKeys: {[key: string]: string};
    columnsToShow?: Array<string>;
    // selectedColumns?: Array<string>;
    setSelectedColumns: (e: Array<string>) => void;
    buttons?: Array<BulkButtonType>;
    specialButtons?: Array<React.ReactNode>;
    mandatoryColumns?: Array<string>;
    hiddenColumns?: Array<string>;
    rowSelection?: {
        result: unknown;
        type: RowSelectionType;
        hideSelectAll?: boolean | undefined;
        selectedRowKeys: SelectedRowKeysType;
        selectedRows: Array<string> | Array<number> | any;
        // renderCell?: any;
    };
    selected?: Array<string> | Array<number> | any;
    exportFileName?: string;
    dataSource?: any;
    data?: Array<ReceiverType>;
    type?: BlankMailTypes;
    hideColumnSelector?: boolean;
    mailDataType?: MailDataType;
    tablesEnum?: TablesEnum;
    defaultColumns?: Array<string>;
    refetch?: () => void;
};

export type IcnProps = {
    icnKey: number | string;
    icon: React.ReactNode;
    text: string | React.ReactNode;
    danger?: boolean;
    action?: Function;
    className?: string;
};

const TableToolBar: FC<TableToolBarProps> = ({
    selectMode = () => console.log("typeSelection"),
    columns,
    allColumns,
    variableKeys,
    columnsToShow,
    setSelectedColumns,
    buttons = [],
    specialButtons = [],
    mandatoryColumns,
    hiddenColumns,
    rowSelection = {
        result: "",
        selectedRowKeys: [],
        selectedRows: [],
        type: radio,
        hideSelectAll: false,
    },
    selected,
    exportFileName,
    dataSource,
    data,
    type,
    hideColumnSelector,
    mailDataType,
    tablesEnum,
    defaultColumns,
    refetch,
}) => {
    const [columnSelectorVisible, setColumnSelectorVisible] = useState(false);
    const [exportPreviewVisible, setExportPreviewVisible] = useState(false);
    const [emailTemplatePickerOpen, setEmailTemplatePickerOpen] = useState(
        false,
    );
    const [tableToolButtonsOpen, setTableToolButtonsOpen] = useState(
        rowSelection?.selectedRows.length > 0,
    );

    React.useEffect(() => {
        if (rowSelection?.selectedRows.length > 0) {
            setTableToolButtonsOpen(true);
        } else {
            setTableToolButtonsOpen(false);
        }
    }, [rowSelection]);

    const compatibleMailDataTypes = [
        MailDataType.Course,
        MailDataType.Booker,
        MailDataType.Attendee,
        MailDataType.Booking,
        MailDataType.ScheduledPayment,
        MailDataType.Invoice,
    ];
    const refactoredMailDataTypes = [
        MailDataType.Course,
        MailDataType.Booker,
        MailDataType.Attendee,
        MailDataType.Booking,
        MailDataType.ScheduledPayment,
        MailDataType.Staff,
    ];

    const {data: companyInfoData} = useGetCompanyInfoQuery({
        fetchPolicy: "cache-and-network",
    });

    const companyInfo = companyInfoData?.companyInfo;

    const SendEmailIcon = () => {
        // eslint-disable-next-line no-negated-condition
        const emailIconToShow: "refactored" | "old" | "none" = !mailDataType
            ? "none"
            : /* highestRole === Roles.Superuser && */
            refactoredMailDataTypes.includes(mailDataType)
            ? "refactored"
            : type === undefined ||
              !compatibleMailDataTypes.includes(mailDataType)
            ? "none"
            : "old";

        if (emailIconToShow === "none" || !mailDataType) {
            return null;
        } else if (emailIconToShow === "old")
            return (
                <SendEmailTemplate
                    input={{
                        mailDataType: mailDataType as CompatibleMailDataType,
                        selection: rowSelection.selectedRows,
                    }}
                />
            );

        return (
            <EmailTemplatePicker
                open={emailTemplatePickerOpen}
                setOpen={setEmailTemplatePickerOpen}
                mailDataType={mailDataType}
                selection={rowSelection.selectedRows}
                btnClassName={css.tableToolBarButton}
            />
        );
    };

    // IF NO USER COLUMNS ARE SET, SET DEFAULT COLUMNS -----------------------------------------------------------------------
    React.useEffect(() => {
        const thereAreNoColumnsSet =
            columnsToShow === undefined || columnsToShow.length === 0;

        if (thereAreNoColumnsSet && defaultColumns) {
            setSelectedColumns(defaultColumns);
        }
    }, [columnsToShow, defaultColumns, setSelectedColumns]);

    // USER COLUMNS ---------------------------------------------------------------------------------------------------------
    const {
        data: userColumnsData,
        refetch: refetchUserColumns,
        loading: loadingUserColumns,
    } = useGetColumnsForTableQuery({
        skip: !tablesEnum,
        variables: {table: tablesEnum ?? TablesEnum.Bookings},
        // partialRefetch: true,
    });
    const userColumns = userColumnsData?.getColumnsForTable;

    // OPTIMIZATION ---------------------------------------------------------------------------------------------------------
    // STAND AB 24-03-05 ========================================================================================================
    React.useEffect(() => {
        if (tablesEnum && !loadingUserColumns)
            refetchUserColumns({table: tablesEnum}).then(() => {
                if (userColumns) setSelectedColumns(userColumns);
            });
        // if (userColumns) setSelectedColumns(userColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tablesEnum,
        loadingUserColumns,
        // refetchUserColumns,
        userColumns,
        // setSelectedColumns,
        // columnsToShow,
    ]);

    // RESTORE DEFAULT COLUMNS ----------------------------------------------------------------------------------------------

    const [updateUserColumns] = useUpdateTableColumnSettingsMutation();
    const setDefaultColumns = (defaultColumns?: Array<string>) => {
        if (tablesEnum && defaultColumns) {
            try {
                updateUserColumns({
                    variables: {
                        updateTableColumnSettingsInput: {
                            table: tablesEnum,
                            columns: defaultColumns,
                        },
                    },
                    refetchQueries: [
                        "GetColumnsForTable",
                        {
                            query: GetColumnsForTableDocument,
                            variables: {table: tablesEnum},
                        },
                    ],
                });
            } catch (error) {
                console.log("error", error);
            } finally {
                refetchUserColumns({table: tablesEnum}).then(() => {
                    // if (userColumns) setSelectedColumns(userColumns);
                    setSelectedColumns(defaultColumns);
                });
            }
        }
    };
    // //  ----------------------------------------------------------------------------------------------------------------------

    const Icn: FC<IcnProps> = ({
        icnKey,
        icon,
        text,
        danger = false,
        action = () => console.log("action"),
        className,
    }) => {
        return (
            <Tooltip
                title={text}
                style={{color: colorBlueSlight}}
                key={icnKey}
                // color="lime"
                mouseEnterDelay={0.3}
            >
                <Button
                    key={icnKey}
                    size="small"
                    type="link"
                    onClick={() => action()}
                    shape="circle"
                    className={className}
                >
                    {icon}
                </Button>
            </Tooltip>
        );
    };

    const ActionButtons = () => {
        const activeButtons: Array<React.ReactNode> = [];
        const icons: {[K in BulkButtonType]: React.ReactNode} = {
            eMail: (null as unknown) as React.ReactNode,
            columnSelector: (null as unknown) as React.ReactNode,
            pdf: (
                <Icn
                    key={1}
                    icnKey={1}
                    icon={<FilePdfOutlined />}
                    text="Export"
                    action={() => setExportPreviewVisible(true)}
                    className={css.tableToolBarButton}
                />
            ),
            checkList: (
                <Icn
                    key={3}
                    icnKey={3}
                    icon={<UnorderedListOutlined />}
                    text="Teilnehmerliste erstellen"
                    className={css.tableToolBarButton}
                />
            ),
            bulkDelete: (
                <Icn
                    danger
                    key={4}
                    icnKey={4}
                    icon={<DeleteOutlined />}
                    text="Auswahl löschen"
                    className={css.tableToolBarButton}
                />
            ),
            transferBookings: (
                <TransferMultipleBookings
                    currentCourseId={rowSelection.selectedRows[0].courseId}
                    selectedBookings={rowSelection.selectedRows}
                    key={5}
                    btnClassName={css.tableToolBarButton}
                    onFinished={() => setTableToolButtonsOpen(false)}
                />
            ),
            bulkDeleteWaitlist: (
                <BulkDeleteWaitlist
                    currentCourseId={rowSelection.selectedRows[0].courseId}
                    selectedBookings={rowSelection.selectedRows}
                    key={6}
                    btnClassName={css.tableToolBarButton}
                    onFinished={() => setTableToolButtonsOpen(false)}
                />
            ),
            sms:
                (selectMode("checkbox" as const),
                companyInfo?.subscription?.featureSms && (
                    <SendSmsModal
                        key={7}
                        btnClassName={css.tableToolBarButton}
                        mailDataType={mailDataType as CompatibleMailDataType}
                        selection={rowSelection.selectedRows}
                    />
                )),
            bulkShowInWeb: (
                <BulkShowInWeb
                    courseIds={rowSelection.selectedRows.map(
                        (row: {id: string}) => row.id,
                    )}
                    key={8}
                    btnClassName={css.tableToolBarButton}
                    onFinished={() => setTableToolButtonsOpen(false)}
                />
            ),
        };

        buttons.forEach((btn) => {
            activeButtons.push(icons[btn]);
        });

        return <div className={css.space}>{activeButtons}</div>;
    };

    const {csvDataPDF, csvDataCSV} = useExportHandler({
        selectedRows: rowSelection.selectedRows,
        // userColumns: userColumns ?? [],
        userColumns:
            userColumns && userColumns.length > 0
                ? userColumns
                : defaultColumns ?? [],
        allColumns,
        hiddenColumns,
        tablesEnum,
    });

    return (
        <div className={css.tableToolBar}>
            {exportPreviewVisible ? (
                <>
                    <ExportModal
                        visible={exportPreviewVisible}
                        handleCancel={() => {
                            setExportPreviewVisible(false);
                        }}
                        // csvData={csvData}
                        csvDataPDF={csvDataPDF}
                        csvDataCSV={csvDataCSV}
                        downloadPDF={() => {
                            setExportPreviewVisible(false);
                        }}
                        fileName={exportFileName}
                    />
                </>
            ) : null}
            {!hideColumnSelector && (
                <div className={css.container}>
                    <div className={classnames(css.buttonBar, css.space)}>
                        <Tooltip
                            title="Spalten konfigurieren"
                            style={{color: colorBlueSlight}}
                            open={columnSelectorVisible ? false : undefined}
                            trigger="hover"
                        >
                            <>
                                {!columnSelectorVisible && (
                                    <Button
                                        size="small"
                                        type="link"
                                        onClick={() =>
                                            setColumnSelectorVisible(
                                                !columnSelectorVisible,
                                            )
                                        }
                                    >
                                        <Space>
                                            <ControlOutlined />
                                            <div>{"Spalten konfigurieren"}</div>
                                        </Space>
                                    </Button>
                                )}
                            </>
                        </Tooltip>

                        {columnSelectorVisible && (
                            <Card
                                title={
                                    <Space
                                        // className={css.space}
                                        style={{
                                            color: blue[5],
                                            fontSize: 1.3 + "em",
                                        }}
                                    >
                                        <ControlFilled />
                                        {"Spalten konfigurieren"}
                                    </Space>
                                }
                                size="small"
                                style={{
                                    border: "2px solid",
                                    borderColor: blue[2],
                                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.25)",
                                    background: "0,0,0,0.1",
                                    zIndex: 1000,
                                    width: "100%",
                                }}
                                bordered
                                extra={
                                    <Space size="small">
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                setSelectedColumns(
                                                    defaultColumns ?? [],
                                                );
                                                setDefaultColumns(
                                                    defaultColumns ?? [],
                                                );
                                            }}
                                        >
                                            {"Standard wiederherstellen"}
                                        </Button>
                                        <Button
                                            type="link"
                                            onClick={() =>
                                                setColumnSelectorVisible(false)
                                            }
                                        >
                                            {"schließen"}
                                        </Button>
                                    </Space>
                                }
                            >
                                <ColumnConfigurator
                                    // columnsToShow={userColumns ?? []}
                                    columnsToShow={
                                        columnsToShow ?? userColumns ?? []
                                    }
                                    allColumns={allColumns}
                                    setSelectedColumns={(e: Array<string>) => {
                                        if (tablesEnum)
                                            refetchUserColumns({
                                                table: tablesEnum,
                                            }).then(() => {
                                                setSelectedColumns(e);
                                                // if (userColumns)
                                                //     setSelectedColumns(
                                                //         userColumns,
                                                //     );
                                            });
                                    }}
                                    setColumnSelectorVisible={(open: boolean) =>
                                        setColumnSelectorVisible(open)
                                    }
                                    mandatoryColumns={mandatoryColumns}
                                    hiddenColumns={hiddenColumns}
                                    tablesEnum={tablesEnum}
                                    // style={{width: "100%"}}
                                />
                            </Card>
                        )}
                        {rowSelection.selectedRows.length === 0 ||
                        !rowSelection.selectedRows[0] ||
                        !tableToolButtonsOpen ||
                        columnSelectorVisible ? null : (
                            <>
                                {buttons.length > 0 && (
                                    <>
                                        <ActionButtons />
                                        <SendEmailIcon />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className={css.space}>
                        {specialButtons.length > 0 && !columnSelectorVisible
                            ? specialButtons.map((button, i) => {
                                  return <div key={i}>{button}</div>;
                              })
                            : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(TableToolBar);

export type SelectedRowsType = Array<unknown>;
export type SelectedRowKeysType = Array<string>;

export const useRowSelection = (config: {
    type: RowSelectionType;
    defaultSelectedKeys?: SelectedRowKeysType;
    defaultSelectedRows?: SelectedRowsType;
    singleSelect?: boolean;
    disabled?: Array<string>;
    preserveSelectedRowKeys?: boolean;
}) => {
    const {type, singleSelect, disabled} = config;
    const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysType>(
        config.defaultSelectedKeys ? config.defaultSelectedKeys : [],
    );
    const [selectedRows, setSelectedRows] = useState<SelectedRowsType>([]);
    const disabledArray = disabled ?? [];

    const result = () => {
        return ({
            onChange: (
                selectedRowKeys: SelectedRowKeysType,
                selectedRows: SelectedRowsType,
                info: {type: RowSelectMethod},
            ) => {
                if (singleSelect) {
                    const selectedKey =
                        selectedRowKeys[selectedRowKeys.length - 1];
                    const selectedRow = selectedRows[selectedRows.length - 1];

                    setSelectedRowKeys([selectedKey]);
                    setSelectedRows([selectedRow]);
                } else {
                    setSelectedRowKeys(selectedRowKeys);
                    setSelectedRows(selectedRows);
                }
            },
            type,
            hideDefaultSelections: false,
            columnTitle: singleSelect ? " " : null,
            selectedRowKeys,
            columnWidth: 30,
            getCheckboxProps: (record: {id: string}) => ({
                disabled: disabledArray.includes(record.id),
                id: record.id,
            }),
            preserveSelectedRowKeys: config.preserveSelectedRowKeys ?? false,
        } as unknown) as TableRowSelection<unknown>;
    };

    return {
        result: result(),
        selectedRowKeys,
        selectedRows,
        type,
        setSelectedRowKeys,
        setSelectedRows,
    };
};
