import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any,
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export type AddNamedScheduledPaymentInput = {
  bookingId: Scalars['ID'],
  paymentAmount?: Maybe<Scalars['Float']>,
  paymentDue?: Maybe<Scalars['DateTime']>,
  sepaClearance?: Maybe<Scalars['Boolean']>,
  title: Scalars['String'],
  type?: Maybe<ScheduledPaymentType>,
};

export type AddPaymentMethodInput = {
  bank?: Maybe<Scalars['String']>,
  bankTransfer?: Maybe<Scalars['Boolean']>,
  bic?: Maybe<Scalars['String']>,
  bookerId: Scalars['String'],
  cash?: Maybe<Scalars['Boolean']>,
  creditCardAccountholder?: Maybe<Scalars['String']>,
  creditcardNumber?: Maybe<Scalars['String']>,
  cvc?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  esr?: Maybe<Scalars['Boolean']>,
  expirationDate?: Maybe<Scalars['DateTime']>,
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  paypalAccountholder?: Maybe<Scalars['String']>,
  sepaAccountholder?: Maybe<Scalars['String']>,
  type?: Maybe<PaymentTransferType>,
  validFrom?: Maybe<Scalars['DateTime']>,
};

export type Admin = {
  accountHolder?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['String']>,
  birthPlace?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber?: Maybe<Scalars['String']>,
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  rate?: Maybe<Scalars['Float']>,
  salutation?: Maybe<SalutationType>,
  socialSecurityNumber?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['Float']>,
};

export enum AgeError {
  TooOld = 'TooOld',
  TooYoung = 'TooYoung'
}

export type AnalyticsParams = {
  end?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['String']>,
};

export type ArrayDataOptions = {
  filter?: Maybe<Array<FilterOption>>,
  limit?: Maybe<Scalars['Float']>,
  offset?: Maybe<Scalars['Float']>,
  sortColumn?: Maybe<Scalars['String']>,
  sortOrder?: Maybe<SortOrder>,
};

export type AssignedLocationIdInput = {
  id: Scalars['ID'],
};

export type Attachment = {
  Base64Content: Scalars['String'],
  ContentType: Scalars['String'],
  Filename: Scalars['String'],
};

export enum AttendanceListFontSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export type Attendee = {
  addition?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  badgeXAttendee?: Maybe<Array<AttendeeBadge>>,
  birthday: Scalars['DateTime'],
  booker?: Maybe<Booker>,
  bookerId?: Maybe<Scalars['ID']>,
  bookings?: Maybe<Array<Booking>>,
  bookingsKo9?: Maybe<Array<BookingsKo9>>,
  createDate: Scalars['DateTime'],
  createdBy?: Maybe<CreatedBy>,
  createdByUserId?: Maybe<Scalars['String']>,
  customer: Customer,
  customerId: Scalars['ID'],
  customerNumber: Scalars['Float'],
  customerNumberPrefix: Scalars['String'],
  defaultPaymentMethod: PaymentMethod,
  defaultPaymentMethodId: Scalars['ID'],
  deletable: Scalars['Boolean'],
  firstname: Scalars['String'],
  gender: Gender,
  id: Scalars['ID'],
  lastname: Scalars['String'],
  member: Scalars['Boolean'],
  startedCourses: Array<Course>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type AttendeeBadge = {
  attendee: Attendee,
  attendeeId?: Maybe<Scalars['ID']>,
  badge: Badge,
  badgeId?: Maybe<Scalars['ID']>,
  comment?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  dateOfAchivement?: Maybe<Scalars['DateTime']>,
  id: Scalars['ID'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type AttendeesAgeAtCourseStart = {
  ageError?: Maybe<AgeError>,
  attendeeAge: Scalars['Float'],
  attendeeBirthday: Scalars['DateTime'],
  attendeesAgeAtFirstCourseLessonDate: Scalars['Float'],
  firstCourseLessonDate: Scalars['DateTime'],
  maxAge?: Maybe<Scalars['Float']>,
  maxAgeAnniversary?: Maybe<Scalars['DateTime']>,
  minAge?: Maybe<Scalars['Float']>,
  minAgeAnniversary?: Maybe<Scalars['DateTime']>,
};

export type AttendeeWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Attendee>,
  total: Scalars['Float'],
};

export type Badge = {
  badgeXAttendee?: Maybe<Array<AttendeeBadge>>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  deleted?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  name: Scalars['String'],
  rank: Scalars['Float'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type Base = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type BaseInput = {
  id: Scalars['ID'],
};

export type BaseLookupInput = {
  number: Scalars['String'],
  type: LookupType,
};

export type BasicEmailVariables = {
  footerText: Scalars['String'],
  headerText: Scalars['String'],
  replyTo: Scalars['String'],
  signature: Scalars['String'],
  subject: Scalars['String'],
};

export type BlankMailInputTypes = {
  bcc: Array<Scalars['String']>,
  cc: Array<Scalars['String']>,
  receivers: Array<BlankMailReceiversInput>,
  subject: Scalars['String'],
  text: Scalars['String'],
  type: BlankMailTypes,
};

export type BlankMailReceiversInput = {
  email: Scalars['String'],
  ids?: Maybe<Array<Scalars['String']>>,
};

export enum BlankMailTypes {
  Attendee = 'Attendee',
  Booker = 'Booker',
  Booking = 'Booking',
  Course = 'Course',
  Invoice = 'Invoice'
}

export type BookAttendees = {
  selectedAttendee?: Maybe<SelectedAttendee>,
  selectedCourseId?: Maybe<Scalars['ID']>,
};

export enum BookedBy {
  Booker = 'Booker',
  Staff = 'Staff'
}

export type Booker = {
  addition?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  attendees?: Maybe<Array<Attendee>>,
  birthday?: Maybe<Scalars['DateTime']>,
  city: Scalars['String'],
  createDate: Scalars['DateTime'],
  createdBy?: Maybe<CreatedBy>,
  createdByUserId?: Maybe<Scalars['String']>,
  credit?: Maybe<Scalars['Float']>,
  customer: Customer,
  customerId: Scalars['ID'],
  customerNumber: Scalars['Float'],
  customerNumberPrefix: Scalars['String'],
  dsgvo: Scalars['Boolean'],
  email?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  gender?: Maybe<Gender>,
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber: Scalars['String'],
  id: Scalars['ID'],
  initialContact: Scalars['DateTime'],
  lastname: Scalars['String'],
  mobileNumber?: Maybe<Scalars['String']>,
  newsletter: Scalars['Boolean'],
  nonDisclosureNotice?: Maybe<Scalars['String']>,
  paymentMethods?: Maybe<Array<PaymentMethod>>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  street: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type BookerAttendeeBookingInput = {
  bookingNumberPrefix?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  courseLessons: Array<BaseInput>,
  id: Scalars['ID'],
  payDate?: Maybe<Scalars['DateTime']>,
  paymentMethod?: Maybe<BaseInput>,
  paymentType?: Maybe<PaymentType>,
};

export type BookerAttendeeInput = {
  addition?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  birthday: Scalars['DateTime'],
  bookings?: Maybe<Array<BookerAttendeeBookingInput>>,
  bookingsKo9?: Maybe<Array<NewBookingsKo9Input>>,
  customer: BaseInput,
  defaultPaymentMethod?: Maybe<BaseInput>,
  deletable?: Maybe<Scalars['Boolean']>,
  firstname: Scalars['String'],
  gender: Gender,
  id: Scalars['ID'],
  lastname: Scalars['String'],
  member: Scalars['Boolean'],
};

export type BookerCustomerInput = {
  customer: BaseInput,
  firstname: Scalars['String'],
  gender: Gender,
  id: Scalars['ID'],
  lastname: Scalars['String'],
};

export type BookerCustomerUpdateInput = {
  customer?: Maybe<BaseInput>,
  customerNumber?: Maybe<Scalars['Float']>,
  firstname?: Maybe<Scalars['String']>,
  gender?: Maybe<Gender>,
  id: Scalars['ID'],
  lastname?: Maybe<Scalars['String']>,
};

export type BookerIdMissmatch = {
  attachments?: Maybe<Array<Scalars['JSONObject']>>,
  bookerId?: Maybe<Scalars['String']>,
  booking?: Maybe<Booking>,
  bookingDeleted?: Maybe<Scalars['DateTime']>,
  bookingId?: Maybe<Scalars['ID']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  currentBooker?: Maybe<Booker>,
  email: Scalars['String'],
  foundByMailBooker?: Maybe<Booker>,
  html?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  lastMessageStatus?: Maybe<MessageStatus>,
  mailjetTemplateId?: Maybe<Scalars['Float']>,
  messageHistory?: Maybe<MessageHistory>,
  messageHref?: Maybe<Scalars['String']>,
  messageID: Scalars['ID'],
  messageStatus?: Maybe<MessageStatusData>,
  receiverName?: Maybe<Scalars['String']>,
  status: Scalars['String'],
  subject?: Maybe<Scalars['String']>,
  templateName?: Maybe<Scalars['String']>,
  templateType?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type BookerIdMissmatchWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<BookerIdMissmatch>,
  total: Scalars['Float'],
};

export type BookerInput = {
  addition?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  attendees?: Maybe<Array<BookerAttendeeInput>>,
  birthday?: Maybe<Scalars['DateTime']>,
  city?: Maybe<Scalars['String']>,
  createdBy?: Maybe<CreatedBy>,
  credit?: Maybe<Scalars['Float']>,
  customer: BaseInput,
  customerNumber?: Maybe<Scalars['Float']>,
  dsgvo: Scalars['Boolean'],
  email?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  gender: Gender,
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  initialContact: Scalars['DateTime'],
  lastname: Scalars['String'],
  mobileNumber?: Maybe<Scalars['String']>,
  newsletter?: Maybe<Scalars['Boolean']>,
  nonDisclosureNotice?: Maybe<Scalars['String']>,
  paymentMethods?: Maybe<Array<BookerPaymentMethodInput>>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['ID']>,
};

export type BookerPaymentMethodInput = {
  bank?: Maybe<Scalars['String']>,
  bankTransfer?: Maybe<Scalars['Boolean']>,
  bic?: Maybe<Scalars['String']>,
  cash?: Maybe<Scalars['Boolean']>,
  creditCardAccountholder?: Maybe<Scalars['String']>,
  creditcardNumber?: Maybe<Scalars['String']>,
  cvc?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  esr?: Maybe<Scalars['Boolean']>,
  expirationDate?: Maybe<Scalars['DateTime']>,
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  paypalAccountholder?: Maybe<Scalars['String']>,
  sepaAccountholder?: Maybe<Scalars['String']>,
  type?: Maybe<PaymentTransferType>,
  validFrom?: Maybe<Scalars['DateTime']>,
};

export type BookerWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Booker>,
  total: Scalars['Float'],
};

export type Booking = {
  additionalInfo?: Maybe<Scalars['String']>,
  agbConfirmed?: Maybe<Scalars['Boolean']>,
  attendanceListNote?: Maybe<Scalars['String']>,
  attendee?: Maybe<Attendee>,
  attendeeId?: Maybe<Scalars['ID']>,
  bookedBy: BookedBy,
  bookingConfirmationSent?: Maybe<Scalars['DateTime']>,
  bookingNumber: Scalars['Float'],
  bookingNumberPrefix: Scalars['String'],
  bookingType: Scalars['String'],
  cancelationSent?: Maybe<Scalars['DateTime']>,
  canceled?: Maybe<Scalars['DateTime']>,
  courseId?: Maybe<Scalars['ID']>,
  courseLessonBookings?: Maybe<Array<CourseLessonBooking>>,
  courseLessons: Array<CourseLesson>,
  coursePrice: CoursePrice,
  coursePriceId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  creditedBalance?: Maybe<Scalars['Float']>,
  emailNotifications?: Maybe<Array<EmailNotification>>,
  grossPrice: Scalars['Float'],
  id: Scalars['ID'],
  instructors: Array<Instructor>,
  invoice?: Maybe<Invoice>,
  invoiceSent?: Maybe<Scalars['DateTime']>,
  isSpecial: Scalars['Boolean'],
  memberDiscount?: Maybe<Scalars['Float']>,
  monthlySchedule: MonthlySchedule,
  netPrice?: Maybe<Scalars['Float']>,
  noticeBooker?: Maybe<Scalars['String']>,
  noticeStaff?: Maybe<Scalars['String']>,
  payDate?: Maybe<Scalars['DateTime']>,
  paymentMethod?: Maybe<PaymentMethod>,
  paymentMethodId?: Maybe<Scalars['ID']>,
  paymentType?: Maybe<PaymentType>,
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>,
  reversalInvoice?: Maybe<ReversalInvoice>,
  scheduledPayments?: Maybe<Array<ScheduledPayment>>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  vat: Scalars['Float'],
  waitingListConfirmationSent?: Maybe<Scalars['DateTime']>,
  waitListCount?: Maybe<Scalars['Float']>,
};

export type BookingDeleteAction = {
  bookingId?: Maybe<Scalars['ID']>,
  type: BookingDeleteActionType,
};

export enum BookingDeleteActionType {
  DoNothing = 'DO_NOTHING',
  NextFromWaitingList = 'NEXT_FROM_WAITING_LIST',
  PlaceReservation = 'PLACE_RESERVATION',
  SelectFromWaitingList = 'SELECT_FROM_WAITING_LIST'
}

export type BookingHistory = {
  action: BookingHistoryAction,
  attendeeId?: Maybe<Scalars['ID']>,
  bookedBy: BookedBy,
  bookerId?: Maybe<Scalars['ID']>,
  bookingId: Scalars['ID'],
  bookingNumber?: Maybe<Scalars['Float']>,
  bookingNumberPrefix: Scalars['String'],
  bookingType: BookingType,
  canceled?: Maybe<Scalars['DateTime']>,
  courseId?: Maybe<Scalars['ID']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  grossPrice: Scalars['Float'],
  id: Scalars['ID'],
  netPrice?: Maybe<Scalars['Float']>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  vat: Scalars['Float'],
};

export enum BookingHistoryAction {
  BookingCancle = 'BookingCancle',
  BookingCreate = 'BookingCreate',
  BookingCreateTransfer = 'BookingCreateTransfer',
  BookingCreateTransferCopy = 'BookingCreateTransferCopy',
  BookingCreateTransferMove = 'BookingCreateTransferMove',
  BookingDelete = 'BookingDelete',
  BookingDeleteTransfer = 'BookingDeleteTransfer',
  BookingOrphanDelete = 'BookingOrphanDelete',
  BookingSettle = 'BookingSettle',
  BookingToWaitlist = 'BookingToWaitlist',
  CorrectionInvoice = 'CorrectionInvoice',
  PlaceReservationCreate = 'PlaceReservationCreate',
  PlaceReservationDelete = 'PlaceReservationDelete',
  PlaceReservationToBooking = 'PlaceReservationToBooking',
  Unknown = 'Unknown',
  WaitlistCreate = 'WaitlistCreate',
  WaitlistCreateTransfer = 'WaitlistCreateTransfer',
  WaitlistCreateTransferCopy = 'WaitlistCreateTransferCopy',
  WaitlistCreateTransferMove = 'WaitlistCreateTransferMove',
  WaitlistDelete = 'WaitlistDelete',
  WaitlistDeleteTransfer = 'WaitlistDeleteTransfer',
  WaitlistToBooking = 'WaitlistToBooking'
}

export type BookingOptions = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
};

export type BookingRestriction = {
  active: Scalars['Boolean'],
  blockedPlaces?: Maybe<Scalars['Float']>,
  course?: Maybe<Course>,
  courseId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  endDateTime?: Maybe<Scalars['DateTime']>,
  expireAction?: Maybe<ExpireAction>,
  id: Scalars['ID'],
  includeBookingList: Scalars['Boolean'],
  includeWaitingList: Scalars['Boolean'],
  membersOnly: Scalars['Boolean'],
  prerequisiteCourses?: Maybe<Array<Course>>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  visibleForAll: Scalars['Boolean'],
};

export type BookingsKo9 = {
  attendee?: Maybe<Attendee>,
  attendeeId: Scalars['String'],
  courseName: Scalars['String'],
  courseNumber: Scalars['String'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  firstCourseLesson: Scalars['String'],
  id: Scalars['ID'],
  teilnahmeId: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export enum BookingType {
  Booking = 'Booking',
  PlaceReservation = 'PlaceReservation',
  Waitlist = 'Waitlist'
}

export type BookingWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Booking>,
  total: Scalars['Float'],
};

export type CamtGeneratorInput = {
  amountOfTransactions: Scalars['String'],
  createDateTime: Scalars['String'],
  creditorIban: Scalars['String'],
  creditorName: Scalars['String'],
  entryBookDate: Scalars['String'],
  entryReference: Scalars['String'],
  entrySumAmount: Scalars['String'],
  entryValuaDate: Scalars['String'],
  msgId: Scalars['String'],
  notificationId: Scalars['String'],
  rangeEnd: Scalars['String'],
  rangeStart: Scalars['String'],
  transactions?: Maybe<Array<CamtGeneratorTransactionInput>>,
};

export type CamtGeneratorTransactionInput = {
  deptorCity: Scalars['String'],
  deptorCountry: Scalars['String'],
  deptorHouseNumber: Scalars['String'],
  deptorIban: Scalars['String'],
  deptorName: Scalars['String'],
  deptorPostalCode: Scalars['String'],
  deptorStreet: Scalars['String'],
  invoiceAmount: Scalars['String'],
  packageId: Scalars['String'],
  qrReference: Scalars['String'],
  transactionReference: Scalars['String'],
};

export type CamtScheduledPaymentInput = {
  amount?: Maybe<Scalars['Float']>,
  scheduledPaymentId: Scalars['ID'],
};

export type CollitionCheckInput = {
  courseLessonId?: Maybe<Scalars['ID']>,
  courseLessonIndex?: Maybe<Scalars['Float']>,
  endDateTime: Scalars['DateTime'],
  startDateTime: Scalars['DateTime'],
  venueId: Scalars['ID'],
};

export type ColorsInput = {
  enabeld?: Maybe<Scalars['Boolean']>,
  primary?: Maybe<Scalars['String']>,
  secondary?: Maybe<Scalars['String']>,
  tertiary?: Maybe<Scalars['String']>,
};

export type CompanySetting = {
  agb?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  bankAccountHolder?: Maybe<Scalars['String']>,
  BIC?: Maybe<Scalars['String']>,
  businessForm?: Maybe<Scalars['String']>,
  commercialRegister?: Maybe<Scalars['String']>,
  createDate: Scalars['DateTime'],
  createdByUserId: Scalars['String'],
  creditorId?: Maybe<Scalars['String']>,
  host?: Maybe<Scalars['String']>,
  IBAN?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  industry?: Maybe<Scalars['String']>,
  koOrganization: KoOrganization,
  localCourt?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  mailjetSenderId: Scalars['String'],
  name: Scalars['String'],
  ownerCity: Scalars['String'],
  ownerCountry: Scalars['String'],
  ownerEmail: Scalars['String'],
  ownerFax?: Maybe<Scalars['String']>,
  ownerFirstName: Scalars['String'],
  ownerLastName: Scalars['String'],
  ownerPhone?: Maybe<Scalars['String']>,
  ownerStreet: Scalars['String'],
  ownerStreetNumber: Scalars['String'],
  ownerZip: Scalars['String'],
  privacyStatement?: Maybe<Scalars['String']>,
  qrIban?: Maybe<Scalars['String']>,
  sendFromEmail: Scalars['String'],
  taxId?: Maybe<Scalars['String']>,
  taxNumber?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId: Scalars['String'],
  website?: Maybe<Scalars['String']>,
};

export type CompanySettingInput = {
  agb?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  bankAccountHolder?: Maybe<Scalars['String']>,
  BIC?: Maybe<Scalars['String']>,
  businessForm?: Maybe<Scalars['String']>,
  commercialRegister?: Maybe<Scalars['String']>,
  creditorId?: Maybe<Scalars['String']>,
  IBAN?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  industry?: Maybe<Scalars['String']>,
  localCourt?: Maybe<Scalars['String']>,
  logo?: Maybe<LogoInput>,
  name: Scalars['String'],
  ownerCity: Scalars['String'],
  ownerCountry: Scalars['String'],
  ownerEmail: Scalars['String'],
  ownerFax?: Maybe<Scalars['String']>,
  ownerFirstName: Scalars['String'],
  ownerLastName: Scalars['String'],
  ownerPhone?: Maybe<Scalars['String']>,
  ownerStreet: Scalars['String'],
  ownerStreetNumber: Scalars['String'],
  ownerZip: Scalars['String'],
  privacyStatement?: Maybe<Scalars['String']>,
  qrIban?: Maybe<Scalars['String']>,
  taxId?: Maybe<Scalars['String']>,
  taxNumber?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
};

export type Content = {
  ETag?: Maybe<Scalars['String']>,
  Key: Scalars['String'],
  LastModified?: Maybe<Scalars['DateTime']>,
  Name?: Maybe<Scalars['String']>,
  pdfBlob?: Maybe<Scalars['String']>,
  signedImageUrl?: Maybe<Scalars['String']>,
  Size?: Maybe<Scalars['Float']>,
  StorageClass?: Maybe<Array<Scalars['JSONObject']>>,
};

export type ContentOfBucket = {
  ContentOfBucketResponse?: Maybe<ContentOfBucketResponse>,
  Files?: Maybe<Array<Content>>,
  Folders?: Maybe<Array<Content>>,
};

export type ContentOfBucketResponse = {
  CommonPrefixes?: Maybe<Array<Scalars['JSONObject']>>,
  Contents?: Maybe<Array<Scalars['JSONObject']>>,
  Delimiter?: Maybe<Scalars['String']>,
  EncodingType?: Maybe<Scalars['String']>,
  IsTruncated?: Maybe<Scalars['Boolean']>,
  Marker?: Maybe<Scalars['String']>,
  MaxKeys?: Maybe<Scalars['Float']>,
  Name?: Maybe<Scalars['String']>,
  NextMarker?: Maybe<Scalars['String']>,
  Prefix?: Maybe<Scalars['String']>,
};

export type CountryNetwork = {
  comment?: Maybe<Scalars['String']>,
  features: Array<Scalars['String']>,
  mcc: Scalars['String'],
  mncs?: Maybe<Array<Scalars['String']>>,
  networkName?: Maybe<Scalars['String']>,
  price: Scalars['Float'],
};

export type Course = {
  additionalShortInfo?: Maybe<Scalars['String']>,
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>,
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>,
  annotation: Scalars['String'],
  billedBookingCount: Scalars['Float'],
  bookingCount: Scalars['Float'],
  bookingCountWithoutWaitlist: Scalars['Float'],
  bookingRestriction?: Maybe<BookingRestriction>,
  bookingRestrictionId?: Maybe<Scalars['ID']>,
  canceledBookingsCount: Scalars['Float'],
  courseNumber: Scalars['Float'],
  courseNumberFormat: CourseNumberFormat,
  coursePrice: Array<CoursePrice>,
  coursePrices: Array<CoursePrice>,
  courseType: CourseType,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  customCourseNumberPrefix: Scalars['String'],
  cycle: CourseCycle,
  enabledCourses?: Maybe<Array<EnabledCourse>>,
  endDateTime: Scalars['DateTime'],
  endDateTimeFirstCourseLesson: Scalars['DateTime'],
  freePlaces: Scalars['Float'],
  id: Scalars['ID'],
  installmentRates: Array<InstallmentRate>,
  instructors: Array<Instructor>,
  isActive: Scalars['Boolean'],
  lessonCount?: Maybe<Scalars['Float']>,
  lessons: Array<CourseLesson>,
  location: Location,
  locationId: Scalars['ID'],
  maxAttendees: Scalars['Float'],
  maxWaitlist?: Maybe<Scalars['Float']>,
  minAttendees: Scalars['Float'],
  monthlySchedule: MonthlySchedule,
  paymentInterval?: Maybe<PaymentInterval>,
  paymentType?: Maybe<PaymentType>,
  placeReservationsCount: Scalars['Float'],
  prefixedCourseNumber?: Maybe<Scalars['String']>,
  prerequisiteCourses?: Maybe<Array<Course>>,
  settledBookingCount: Scalars['Float'],
  settledBookings: Array<Booking>,
  showInWeb: Scalars['Boolean'],
  startDateTime: Scalars['DateTime'],
  unSettledBookings: Array<Booking>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  venue: Venue,
  venueId: Scalars['ID'],
  visibility: CourseVisibilityResult,
  waitListCount: Scalars['Float'],
  websiteInfo?: Maybe<Scalars['String']>,
};

export type CourseCategory = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isActive: Scalars['Boolean'],
  linkedCourseTypes: Array<CourseType>,
  name: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export enum CourseCycle {
  Custom = 'Custom',
  EveryTwoWeeks = 'EveryTwoWeeks',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type CourseDetailsInput = {
  age?: Maybe<Scalars['JSONObject']>,
  calendar?: Maybe<Scalars['JSONObject']>,
  city?: Maybe<Scalars['JSONObject']>,
  courseFee?: Maybe<Scalars['JSONObject']>,
  courseInfos?: Maybe<Scalars['JSONObject']>,
  courseLessons?: Maybe<Scalars['JSONObject']>,
  entranceFee?: Maybe<Scalars['JSONObject']>,
  firstCourseLesson?: Maybe<Scalars['JSONObject']>,
  freePlaces?: Maybe<Scalars['JSONObject']>,
  instructor?: Maybe<Scalars['JSONObject']>,
  location?: Maybe<Scalars['JSONObject']>,
  status?: Maybe<Scalars['JSONObject']>,
};

export type CourseIdInput = {
  id: Scalars['ID'],
};

export type CourseInstructorIdInput = {
  id: Scalars['ID'],
};

export type CourseLesson = {
  bookings?: Maybe<Array<Booking>>,
  course: Course,
  courseId: Scalars['ID'],
  courseLessonBookings: Array<CourseLessonBooking>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  endDateTime: Scalars['DateTime'],
  id: Scalars['ID'],
  indexOfLessonInCourse?: Maybe<Scalars['Float']>,
  instructors: Array<Instructor>,
  location: Location,
  locationId: Scalars['ID'],
  startDateTime: Scalars['DateTime'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  venue: Venue,
  venueId: Scalars['ID'],
};

export type CourseLessonBooking = {
  attendance?: Maybe<Scalars['Boolean']>,
  booking: Booking,
  bookingId: Scalars['ID'],
  courseId?: Maybe<Scalars['String']>,
  courseLesson: CourseLesson,
  courseLessonId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  note?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type CourseLessonBookingAttendanceList = {
  attendee: Attendee,
  booker: Booker,
  bookingId: Scalars['String'],
  courseLessonBooking: Array<CourseLessonBooking>,
};

export type CourseLessonIdInput = {
  id: Scalars['ID'],
};

export type CourseLessonWithIndexOfCourseLessonInCourse = {
  bookings?: Maybe<Array<Booking>>,
  course: Course,
  courseId: Scalars['ID'],
  courseLessonBookings: Array<CourseLessonBooking>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  endDateTime: Scalars['DateTime'],
  id: Scalars['ID'],
  indexOfLessonInCourse: Scalars['Float'],
  instructors: Array<Instructor>,
  location: Location,
  locationId: Scalars['ID'],
  originalCourseLessonId: Scalars['ID'],
  startDateTime: Scalars['DateTime'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  venue: Venue,
  venueId: Scalars['ID'],
};

export type CourseLessonWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<CourseLesson>,
  total: Scalars['Float'],
};

export type CourseListFilters = {
  city?: Maybe<Scalars['String']>,
  courseCategoryId?: Maybe<Scalars['String']>,
  courseCategoryIds?: Maybe<Array<Scalars['String']>>,
  courseTypeId?: Maybe<Scalars['String']>,
  courseTypeIds?: Maybe<Array<Scalars['String']>>,
  dayFilter?: Maybe<Array<DayFilter>>,
  ids?: Maybe<Array<Scalars['String']>>,
  instructorId?: Maybe<Scalars['String']>,
  locationId?: Maybe<Scalars['String']>,
  monthFilter?: Maybe<Array<MonthFilter>>,
  prerequisiteCourseId?: Maybe<Scalars['String']>,
  restrictiveCourses?: Maybe<Array<RestrictiveCourses>>,
  showOnlyInWebActive?: Maybe<Scalars['Boolean']>,
};

export enum CourseNotVisibleReason {
  CourseShowInWeb = 'CourseShowInWeb',
  CourseTypeShowInWeb = 'CourseTypeShowInWeb',
  HideAfterDays = 'HideAfterDays',
  HideAfterRestrictionEnd = 'HideAfterRestrictionEnd',
  LocationIsPublic = 'LocationIsPublic',
  MembersOnly = 'MembersOnly',
  PrerequisiteCourses = 'PrerequisiteCourses'
}

export enum CourseNumberFormat {
  CourseLocationPrefix = 'CourseLocationPrefix',
  CourseTypePrefix = 'CourseTypePrefix',
  CustomPrefix = 'CustomPrefix',
  NoPrefix = 'NoPrefix'
}

export type CoursePrice = {
  bookings?: Maybe<Array<Booking>>,
  course: Course,
  courseId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<Scalars['String']>,
  grossPrice: Scalars['Float'],
  id: Scalars['ID'],
  includedEntranceFee?: Maybe<Scalars['Float']>,
  isSpecial: Scalars['Boolean'],
  netPrice?: Maybe<Scalars['Float']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  vat: Scalars['Float'],
};

export type CoursePriceIdInput = {
  id: Scalars['ID'],
};

export type CourseTableInput = {
  columns?: Maybe<Array<Scalars['JSONObject']>>,
};

export type CourseType = {
  annotation?: Maybe<Scalars['String']>,
  bold: Scalars['Boolean'],
  category?: Maybe<CourseCategory>,
  color: Scalars['String'],
  courseCount: Scalars['Float'],
  courses?: Maybe<Array<Course>>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  fee?: Maybe<Scalars['Float']>,
  id: Scalars['ID'],
  inWeekPlanner: Scalars['Boolean'],
  isActive: Scalars['Boolean'],
  maxAge?: Maybe<Scalars['Float']>,
  maxAttendees?: Maybe<Scalars['Float']>,
  minAge?: Maybe<Scalars['Float']>,
  minAttendees?: Maybe<Scalars['Float']>,
  name: Scalars['String'],
  participationPrerequisites?: Maybe<Array<ParticipationPrerequisites>>,
  paymentInterval?: Maybe<PaymentInterval>,
  selfBookerCourse?: Maybe<Scalars['Boolean']>,
  shortHandId: Scalars['String'],
  showInWeb: Scalars['Boolean'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['Float']>,
  websiteInfo?: Maybe<Scalars['String']>,
};

export type CourseVisibilityRestriction = {
  endDateTime?: Maybe<Scalars['DateTime']>,
  expireAction: ExpireAction,
  inTimeRange: Scalars['Boolean'],
  membersOnly: Scalars['Boolean'],
  prerequisiteCourses: Array<Course>,
  startDateTime?: Maybe<Scalars['DateTime']>,
};

export type CourseVisibilityResult = {
  hideAfterDays?: Maybe<Scalars['Float']>,
  reason: Array<CourseNotVisibleReason>,
  restriction: CourseVisibilityRestriction,
  visibleForAll: Scalars['Boolean'],
};

export type CourseWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Course>,
  total: Scalars['Float'],
};

export type CourseWithPaginationWithEnabledCourses = {
  existsMore: Scalars['Boolean'],
  items: Array<Course>,
  total: Scalars['Float'],
};

export type CreateCourse = {
  locationId?: Maybe<Scalars['ID']>,
};

export enum CreatedBy {
  AdminApp = 'ADMIN_APP',
  DummyData = 'DUMMY_DATA',
  EndUserApp = 'END_USER_APP',
  Import = 'IMPORT'
}

export type CreateDummySwissCamtByScheduledPaymentsInput = {
  scheduledPayments?: Maybe<Array<CamtScheduledPaymentInput>>,
};

export type CreateInstallmentRateByCourseCreateInput = {
  id?: Maybe<Scalars['ID']>,
  installmentAmount?: Maybe<Scalars['Float']>,
  installmentDue?: Maybe<Scalars['DateTime']>,
  installmentType?: Maybe<InstallmentType>,
};

export type CreateMailjetSenderInput = {
  Email: Scalars['String'],
  EmailType?: Maybe<EmailTypeEnum>,
  IsDefaultSender?: Maybe<Scalars['Boolean']>,
  Name: Scalars['String'],
};

export type CreateMailjetSenderOutput = {
  CreatedAt: Scalars['String'],
  DNSID: Scalars['Float'],
  Email: Scalars['String'],
  EmailType: Scalars['String'],
  Filename: Scalars['String'],
  ID: Scalars['Float'],
  IsDefaultSender: Scalars['Boolean'],
  Name: Scalars['String'],
  Status: Scalars['String'],
};

export type CreateMultipleSepaXmlInput = {
  collectionDate: Scalars['DateTime'],
  ids: Array<Scalars['String']>,
};

export type CreatePrefixedCourseNumberInput = {
  courseNumber: Scalars['Float'],
  courseNumberFormat: CourseNumberFormat,
  courseTypeId: Scalars['ID'],
  customCourseNumberPrefix?: Maybe<Scalars['String']>,
  koOrganizationId: Scalars['ID'],
  locationId: Scalars['ID'],
  prefixedCourseNumber?: Maybe<Scalars['String']>,
};

export type CreateSepaXmlByTimeRangeInput = {
  collectionDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  maxAmount?: Maybe<Scalars['Float']>,
  maxMoneyAmount?: Maybe<Scalars['Float']>,
  saveFile?: Maybe<Scalars['Boolean']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type CreateSingleSepaXmlInput = {
  collectionDate: Scalars['DateTime'],
  id: Scalars['String'],
};

export enum Currency {
  Chf = 'Chf',
  Eur = 'Eur',
  Gbp = 'Gbp',
  Usd = 'Usd'
}

export type Customer = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  customerNumber: Scalars['Float'],
  customerNumberPrefix: Scalars['String'],
  firstname: Scalars['String'],
  gender: Gender,
  id: Scalars['ID'],
  lastname: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};


export enum DayFilter {
  Dienstag = 'Dienstag',
  Donnerstag = 'Donnerstag',
  Freitag = 'Freitag',
  Mittwoch = 'Mittwoch',
  Montag = 'Montag',
  Samstag = 'Samstag',
  Sonntag = 'Sonntag'
}

export type DetailsCheckboxInput = {
  enabled?: Maybe<Scalars['Boolean']>,
  label?: Maybe<Scalars['String']>,
};

export enum DiscountUnit {
  Euro = 'Euro',
  Percentage = 'Percentage'
}

export type EditAttendeeBadgeInput = {
  attendeeBadgeId: Scalars['ID'],
  comment?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  dateOfAchivement?: Maybe<Scalars['DateTime']>,
};

export type EditBookingInput = {
  additionalInfo?: Maybe<Scalars['String']>,
  agbConfirmed?: Maybe<Scalars['Boolean']>,
  attendeeId?: Maybe<Scalars['ID']>,
  bookingNumberPrefix?: Maybe<Scalars['String']>,
  bookingType?: Maybe<BookingType>,
  courseId?: Maybe<Scalars['ID']>,
  coursePriceId?: Maybe<Scalars['ID']>,
  creditedBalance?: Maybe<Scalars['Float']>,
  grossPrice?: Maybe<Scalars['Float']>,
  isSpecial?: Maybe<Scalars['Boolean']>,
  memberDiscount?: Maybe<Scalars['Float']>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  netPrice?: Maybe<Scalars['Float']>,
  noticeBooker?: Maybe<Scalars['String']>,
  noticeStaff?: Maybe<Scalars['String']>,
  paymentMethodId?: Maybe<Scalars['ID']>,
  paymentType?: Maybe<PaymentType>,
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>,
  scheduledPayments?: Maybe<Array<UpdateScheduledPaymentByBookingInput>>,
  specialPrice?: Maybe<Scalars['Float']>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  vat?: Maybe<Scalars['Float']>,
};

export type EditCourseInput = {
  additionalShortInfo?: Maybe<Scalars['String']>,
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>,
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>,
  annotation?: Maybe<Scalars['String']>,
  courseNumberFormat: CourseNumberFormat,
  coursePrices?: Maybe<Array<EditCoursePriceInput>>,
  courseTypeId?: Maybe<Scalars['ID']>,
  customCourseNumberPrefix?: Maybe<Scalars['String']>,
  cycle?: Maybe<CourseCycle>,
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>,
  defaultLessonCount?: Maybe<Scalars['Float']>,
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<Scalars['String']>,
  endDateTime?: Maybe<Scalars['DateTime']>,
  fee?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['ID']>,
  installmentRates?: Maybe<Array<UpdateInstallmentRateInput>>,
  instructors?: Maybe<Array<CourseInstructorIdInput>>,
  isActive?: Maybe<Scalars['Boolean']>,
  lessons: Array<EditCourseLessonInput>,
  locationId?: Maybe<Scalars['ID']>,
  maxAttendees?: Maybe<Scalars['Float']>,
  maxWaitlist?: Maybe<Scalars['Float']>,
  minAttendees?: Maybe<Scalars['Float']>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  paymentInterval?: Maybe<PaymentInterval>,
  paymentType?: Maybe<PaymentType>,
  showInWeb?: Maybe<Scalars['Boolean']>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  vat?: Maybe<Scalars['Float']>,
  venueId?: Maybe<Scalars['ID']>,
  websiteInfo?: Maybe<Scalars['String']>,
};

export type EditCourseLessonInput = {
  courseId?: Maybe<Scalars['ID']>,
  endDateTime?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  instructors?: Maybe<Array<CourseInstructorIdInput>>,
  locationId?: Maybe<Scalars['ID']>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  venueId?: Maybe<Scalars['ID']>,
};

export type EditCoursePriceInput = {
  courseId: Scalars['ID'],
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<DiscountUnit>,
  grossPrice?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['ID']>,
  includedEntranceFee?: Maybe<Scalars['Float']>,
  isSpecial?: Maybe<Scalars['Boolean']>,
  netPrice?: Maybe<Scalars['Float']>,
  vat?: Maybe<Scalars['Float']>,
};

export type EditInvoiceInput = {
  billed?: Maybe<Scalars['DateTime']>,
  qrReference?: Maybe<Scalars['String']>,
};

export type EditLocationContactInput = {
  department?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  firstname?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  lastname?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  salutation?: Maybe<SalutationType>,
};

export type EditLocationInput = {
  abbreviation?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  generalLocationContact?: Maybe<GeneralLocationContactInput>,
  id: Scalars['ID'],
  info?: Maybe<Scalars['String']>,
  isPublished?: Maybe<Scalars['Boolean']>,
  locationContacts?: Maybe<Array<LocationContactIdInput>>,
  name?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  venues?: Maybe<Array<EditVenueInput>>,
  website?: Maybe<Scalars['String']>,
  zipCode?: Maybe<Scalars['String']>,
};

export type EditPageInput = {
  content: Scalars['JSONObject'],
  id: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type EditScheduledPaymentInput = {
  openAmount?: Maybe<Scalars['Float']>,
  paymentDone?: Maybe<Scalars['DateTime']>,
  paymentMethodId?: Maybe<Scalars['String']>,
  paymentTransferType?: Maybe<PaymentTransferType>,
};

export type EditTemplateInput = {
  footerText?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  isDefault?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  signature?: Maybe<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
};

export type EditVenueInput = {
  id?: Maybe<Scalars['ID']>,
  isActive?: Maybe<Scalars['Boolean']>,
  locationId?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  rent?: Maybe<Scalars['Float']>,
  rentType?: Maybe<RentType>,
};

export type EmailData = {
  ids: Array<Scalars['String']>,
  mailDataType: MailDataType,
  receivers: Array<Scalars['String']>,
};

export type EmailNotification = {
  attachments?: Maybe<Array<Scalars['JSONObject']>>,
  bookerId?: Maybe<Scalars['String']>,
  booking?: Maybe<Booking>,
  bookingDeleted?: Maybe<Scalars['DateTime']>,
  bookingId?: Maybe<Scalars['ID']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  html?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  lastMessageStatus?: Maybe<MessageStatus>,
  mailjetTemplateId?: Maybe<Scalars['Float']>,
  messageHistory?: Maybe<MessageHistory>,
  messageHref?: Maybe<Scalars['String']>,
  messageID: Scalars['ID'],
  messageStatus?: Maybe<MessageStatusData>,
  receiverName?: Maybe<Scalars['String']>,
  status: Scalars['String'],
  subject?: Maybe<Scalars['String']>,
  templateName?: Maybe<Scalars['String']>,
  templateType?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type EmailNotificationWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<EmailNotification>,
  total: Scalars['Float'],
};

export enum EmailTypeEnum {
  Bulk = 'Bulk',
  Transactional = 'Transactional',
  Unknown = 'Unknown'
}

export type EnabledCourse = {
  bookingRestriction?: Maybe<BookingRestriction>,
  courseType: CourseType,
  enabledPrerequisites?: Maybe<Array<Course>>,
  enabledRestriction?: Maybe<BookingRestriction>,
  id: Scalars['ID'],
  prefixedCourseNumber: Scalars['String'],
  visibility?: Maybe<CourseVisibilityResult>,
};

export type EndUserPasswordlessLoginInputType = {
  companyName?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  redirectUrl?: Maybe<Scalars['String']>,
  sendFromMail?: Maybe<Scalars['String']>,
};

export enum ExpireAction {
  HideFromAll = 'HideFromAll',
  ShowToAll = 'ShowToAll'
}

export type ExportedSepaXml = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  object?: Maybe<Scalars['JSONObject']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  xml?: Maybe<Scalars['String']>,
};

export type ExportedSepaXmlWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<ExportedSepaXml>,
  total: Scalars['Float'],
};

export type FaUser = {
  active?: Maybe<Scalars['Boolean']>,
  birthDate?: Maybe<Scalars['String']>,
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>,
  cleanSpeakId?: Maybe<Scalars['ID']>,
  connectorId?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['JSONObject']>,
  email?: Maybe<Scalars['String']>,
  encryptionScheme?: Maybe<Scalars['String']>,
  expiry?: Maybe<Scalars['Float']>,
  factor?: Maybe<Scalars['Float']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  imageUrl?: Maybe<Scalars['String']>,
  insertInstant?: Maybe<Scalars['Float']>,
  lastLoginInstant?: Maybe<Scalars['Float']>,
  lastName?: Maybe<Scalars['String']>,
  lastUpdateInstant?: Maybe<Scalars['Float']>,
  middleName?: Maybe<Scalars['String']>,
  mobilePhone?: Maybe<Scalars['String']>,
  parentEmail?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  passwordChangeReason?: Maybe<Scalars['String']>,
  passwordChangeRequired?: Maybe<Scalars['Boolean']>,
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>,
  preferredLanguages?: Maybe<Array<Scalars['String']>>,
  registrations?: Maybe<Array<UserRegistration>>,
  salt?: Maybe<Scalars['String']>,
  tenantId?: Maybe<Scalars['ID']>,
  timezone?: Maybe<Scalars['String']>,
  uniqueUsername?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  verified?: Maybe<Scalars['Boolean']>,
};

export enum FilterCompareOperation {
  Eq = 'EQ',
  Gr = 'GR',
  Greq = 'GREQ',
  Lo = 'LO',
  Loeq = 'LOEQ'
}

export type FilterExpressionInput = {
  childExpressions?: Maybe<Array<FilterExpressionInput>>,
  filters: Array<FilterInput>,
  operator: Operator,
};

export type FilterInput = {
  field: Scalars['String'],
  op: Operation,
  relationField?: Maybe<Scalars['String']>,
  values: Array<Scalars['String']>,
};

export enum FilterOperation {
  Compare = 'COMPARE',
  Search = 'SEARCH'
}

export type FilterOption = {
  column: Scalars['String'],
  compareOperation?: Maybe<FilterCompareOperation>,
  operation?: Maybe<FilterOperation>,
  table: Scalars['String'],
  values: Array<Scalars['String']>,
};

export type FiltersInput = {
  age?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  courseCategory?: Maybe<Scalars['String']>,
  courseType?: Maybe<Scalars['String']>,
  instructor?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  month?: Maybe<Scalars['String']>,
  weekDay?: Maybe<Scalars['String']>,
};

export type ForgotPasswordInput = {
  changePasswordId: Scalars['String'],
  newPassword: Scalars['String'],
};

export type ForgotPasswordWithApiKeyInput = {
  applicationId: Scalars['String'],
  loginId: Scalars['String'],
  sendFogotPasswordEmail?: Maybe<Scalars['Boolean']>,
  state: ResetPasswordStateType,
};

export type ForgotPasswordWithStateInput = {
  loginId: Scalars['String'],
  sendForgotPasswordEmail?: Maybe<Scalars['Boolean']>,
  state: ResetPasswordStateType,
};

export enum Gender {
  Diverse = 'Diverse',
  Female = 'Female',
  Male = 'Male',
  NotDefined = 'NotDefined'
}

export type GeneralLocationContact = {
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
};

export type GeneralLocationContactInput = {
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
};

export type GetReceiversForCourseInput = {
  courseIds: Array<Scalars['String']>,
  notifyBookings: Scalars['Boolean'],
  notifyCancelations: Scalars['Boolean'],
  notifyWaitlist: Scalars['Boolean'],
};

export enum GrossNet {
  Gross = 'Gross',
  Net = 'Net'
}

export type Holiday = {
  color?: Maybe<Scalars['String']>,
  end: Scalars['DateTime'],
  holidayStateColor: Scalars['String'],
  id: Scalars['ID'],
  koOrganizationId: Scalars['String'],
  name: Scalars['String'],
  slug: Scalars['String'],
  start: Scalars['DateTime'],
  stateCode: StateCode,
  type: HolidayType,
  year: Scalars['Float'],
};

export type HolidayInput = {
  color?: Maybe<Scalars['String']>,
  end: Scalars['DateTime'],
  holidayStateColor?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  slug?: Maybe<Scalars['String']>,
  start: Scalars['DateTime'],
  stateCode?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  year: Scalars['Float'],
};

export enum HolidayType {
  Custom = 'CUSTOM',
  Public = 'PUBLIC',
  School = 'SCHOOL'
}

export type HolidayWithDays = {
  color?: Maybe<Scalars['String']>,
  days: Array<Scalars['DateTime']>,
  end: Scalars['DateTime'],
  holidayStateColor: Scalars['String'],
  id: Scalars['ID'],
  koOrganizationId: Scalars['String'],
  name: Scalars['String'],
  slug: Scalars['String'],
  start: Scalars['DateTime'],
  stateCode: StateCode,
  type: HolidayType,
  year: Scalars['Float'],
};

export type ImportBookerInput = {
  annotation?: Maybe<Scalars['String']>,
  attendeeAddition: Scalars['String'],
  attendeeAnnotation: Scalars['String'],
  attendeeBirthDate: Scalars['String'],
  attendeeFirstName: Scalars['String'],
  attendeeGender?: Maybe<Scalars['String']>,
  attendeeLastName: Scalars['String'],
  badges?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  BIC?: Maybe<Scalars['String']>,
  bookerAddition: Scalars['String'],
  bookerAnnotation: Scalars['String'],
  bookerBirthDate?: Maybe<Scalars['String']>,
  bookerCity: Scalars['String'],
  bookerEmail: Scalars['String'],
  bookerFirstName: Scalars['String'],
  bookerGender: Scalars['String'],
  bookerHouseNumber?: Maybe<Scalars['String']>,
  bookerLastName: Scalars['String'],
  bookerMobileNumber: Scalars['String'],
  bookerPhoneNumber: Scalars['String'],
  bookerPostCode: Scalars['String'],
  bookerStreet: Scalars['String'],
  bookingsKo9?: Maybe<Scalars['String']>,
  credit?: Maybe<Scalars['Float']>,
  defaultPaymentMethod: Scalars['String'],
  dsgvo: Scalars['String'],
  healthInsuranceFund: Scalars['String'],
  IBAN?: Maybe<Scalars['String']>,
  initialContact: Scalars['String'],
  koOrganizationId: Scalars['String'],
  member: Scalars['String'],
  newsletter: Scalars['String'],
  nonDisclosureNotice?: Maybe<Scalars['String']>,
  sepaAccountholder?: Maybe<Scalars['String']>,
};

export type ImportBookersInput = {
  bookers: Array<ImportBookerInput>,
};

export type InlinedAttachment = {
  Base64Content: Scalars['String'],
  ContentID: Scalars['String'],
  ContentType: Scalars['String'],
  Filename: Scalars['String'],
};

export type InstallmentRate = {
  course: Course,
  courseId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  installmentAmount: Scalars['Float'],
  installmentDue: Scalars['DateTime'],
  installmentType: InstallmentType,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export enum InstallmentType {
  Discount = 'Discount',
  Standard = 'Standard'
}

export type Instructor = {
  accountHolder?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  activeCourseCount: Scalars['Float'],
  allowLogin: Scalars['Boolean'],
  annotation?: Maybe<Scalars['String']>,
  assignedLocations: Array<Location>,
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['String']>,
  birthPlace?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  email: Scalars['String'],
  firstname: Scalars['String'],
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber: Scalars['String'],
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  level: Scalars['Float'],
  mobileNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  rate?: Maybe<Scalars['Float']>,
  salutation: SalutationType,
  showInWeb: Scalars['Boolean'],
  socialSecurityNumber?: Maybe<Scalars['String']>,
  street: Scalars['String'],
  taxId?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  userId: Scalars['String'],
  vat?: Maybe<Scalars['Float']>,
};

export type InstructorDetailsView = {
  active: Scalars['Boolean'],
  email: Scalars['String'],
  firstname: Scalars['String'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  level: Scalars['Float'],
  showInWeb: Scalars['Boolean'],
};

export type InstructorsWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Instructor>,
  total: Scalars['Float'],
};

export type Invoice = {
  billed?: Maybe<Scalars['DateTime']>,
  booking: Booking,
  bookingId: Scalars['ID'],
  correctionDate?: Maybe<Scalars['DateTime']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  invoiceBody: Scalars['String'],
  invoiceFileName: Scalars['String'],
  invoiceNumber: Scalars['Float'],
  invoiceNumberPrefix: Scalars['String'],
  koOrganization: KoOrganization,
  paymentDue?: Maybe<Scalars['DateTime']>,
  qrReference?: Maybe<Scalars['String']>,
  scheduledPayments?: Maybe<Array<ScheduledPayment>>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type InvoiceInput = {
  bookingId: Scalars['ID'],
};

export type InvoiceWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Invoice>,
  total: Scalars['Float'],
};

export type JournalParams = {
  dateFrom?: Maybe<Scalars['String']>,
  dateTo?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Float']>,
  limit?: Maybe<Scalars['Float']>,
  state?: Maybe<Scalars['String']>,
  to?: Maybe<Scalars['String']>,
  type: JournalType,
};

export enum JournalType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Replies = 'Replies',
  Voice = 'Voice'
}


export type KeyValue = {
  key: Scalars['String'],
  value: Scalars['String'],
};

export type KoOrganization = {
  companySettings: CompanySetting,
  companySettingsId: Scalars['ID'],
  createdByUserId: Scalars['String'],
  id: Scalars['String'],
  name: Scalars['String'],
  settings: Setting,
  settingsId: Scalars['ID'],
  subscription?: Maybe<Subscription>,
  subscriptionId?: Maybe<Scalars['ID']>,
  subscriptionType: SubscriptionType,
  updatedByUserId: Scalars['String'],
};

export type KoOrganizationInputType = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type KoOrganizationWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<KoOrganization>,
  total: Scalars['Float'],
};

export enum Language {
  De = 'DE'
}

export type LocalState = {
  bookAttendees: BookAttendees,
  createCourse: CreateCourse,
};

export type Location = {
  abbreviation: Scalars['String'],
  city: Scalars['String'],
  courses?: Maybe<Array<Course>>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  generalLocationContact: GeneralLocationContact,
  id: Scalars['ID'],
  info?: Maybe<Scalars['String']>,
  instructors: Array<Instructor>,
  isPublished?: Maybe<Scalars['Boolean']>,
  lessons?: Maybe<Array<CourseLesson>>,
  locationContacts: Array<LocationContact>,
  name: Scalars['String'],
  street: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  venues: Array<Venue>,
  website?: Maybe<Scalars['String']>,
  zipCode: Scalars['String'],
};

export type LocationCity = {
  city: Scalars['String'],
};

export type LocationContact = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  department?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  id: Scalars['ID'],
  lastname: Scalars['String'],
  locations: Array<Location>,
  mobileNumber?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  salutation: SalutationType,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type LocationContactIdInput = {
  id: Scalars['ID'],
};

export type LocationGroupedByCityLocation = {
  city: Scalars['String'],
  id: Scalars['ID'],
  name: Scalars['String'],
  venues: Array<LocationGroupedByCityVenue>,
};

export type LocationGroupedByCityVenue = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type LocationsGroupedByCity = {
  city: Scalars['String'],
  locations: Array<LocationGroupedByCityLocation>,
};

export type LocationsWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Location>,
  total: Scalars['Float'],
};

export type LoggingInput = {
  additionalInfo?: Maybe<Scalars['String']>,
  level?: Maybe<LogLevelType>,
  msg: Scalars['String'],
};

export type LoginAttempt = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  errorMessage?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  loginCode: Scalars['String'],
  status: LoginStatus,
  tenantId: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  usedAt?: Maybe<Scalars['DateTime']>,
};

/** Status eines Login-Versuchs */
export enum LoginStatus {
  AlreadyUsed = 'AlreadyUsed',
  Created = 'Created',
  Expired = 'Expired',
  Invalid = 'Invalid',
  Used = 'Used'
}

export enum LogLevelType {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Silly = 'SILLY',
  Verbose = 'VERBOSE',
  Warn = 'WARN'
}

export type LogoInput = {
  uid: Scalars['String'],
};

export enum LookupType {
  CallingNameDelivery = 'CallingNameDelivery',
  Format = 'Format',
  HomeLocationRegister = 'HomeLocationRegister',
  MobileNumberPortability = 'MobileNumberPortability'
}

export enum MailDataType {
  AttendanceList = 'AttendanceList',
  Attendee = 'Attendee',
  Booker = 'Booker',
  Booking = 'Booking',
  Course = 'Course',
  Invoice = 'Invoice',
  InvoicePdf = 'InvoicePdf',
  ScheduledPayment = 'ScheduledPayment',
  Staff = 'Staff'
}

export type MailjetEmailQueryParams = {
  Campaign?: Maybe<Scalars['Float']>,
  Contact?: Maybe<Scalars['Float']>,
  countOnly?: Maybe<Scalars['Boolean']>,
  CustomID?: Maybe<Scalars['String']>,
  Destination?: Maybe<Scalars['Float']>,
  FromTS?: Maybe<Scalars['String']>,
  FromType?: Maybe<Scalars['Float']>,
  Limit?: Maybe<Scalars['Float']>,
  MessageState?: Maybe<Scalars['Float']>,
  MessageStatus?: Maybe<Scalars['Float']>,
  Offset?: Maybe<Scalars['Float']>,
  PlanSubscription?: Maybe<Scalars['Float']>,
  SenderID?: Maybe<Scalars['Float']>,
  ShowContactAlt?: Maybe<Scalars['Boolean']>,
  ShowCustomID?: Maybe<Scalars['Boolean']>,
  ShowSubject?: Maybe<Scalars['Boolean']>,
  Sort?: Maybe<Scalars['String']>,
  ToTS?: Maybe<Scalars['String']>,
};

export enum MailjetEmailTemplates {
  Blank = 'Blank',
  BookingCancellation = 'BookingCancellation',
  BookingConfirmation = 'BookingConfirmation',
  FreeText = 'FreeText',
  InvoiceSettlement = 'InvoiceSettlement',
  StandardTemplateWithoutCourseDetails = 'StandardTemplateWithoutCourseDetails',
  WaitingListConfirmation = 'WaitingListConfirmation'
}

export type MailjetMessage = {
  ArrivedAt: Scalars['String'],
  AttachmentCount: Scalars['Float'],
  AttemptCount: Scalars['Float'],
  CampaignID?: Maybe<Scalars['Float']>,
  ContactAlt?: Maybe<Scalars['String']>,
  ContactID: Scalars['Float'],
  CustomID?: Maybe<Scalars['String']>,
  Delay: Scalars['Float'],
  DestinationID: Scalars['Float'],
  FilterTime: Scalars['Float'],
  ID: Scalars['ID'],
  IsClickTracked: Scalars['Boolean'],
  IsHTMLPartIncluded: Scalars['Boolean'],
  IsOpenTracked: Scalars['Boolean'],
  IsTextPartIncluded: Scalars['Boolean'],
  IsUnsubTracked: Scalars['Boolean'],
  MessageSize: Scalars['Float'],
  SenderID: Scalars['Float'],
  SpamassassinScore: Scalars['Float'],
  SpamassRules?: Maybe<Scalars['String']>,
  StateID?: Maybe<Scalars['Float']>,
  StatePermanent: Scalars['Boolean'],
  Status: MailjetStatus,
  Subject?: Maybe<Scalars['String']>,
  UUID: Scalars['String'],
};

export type MailjetMessageResponse = {
  Count: Scalars['Float'],
  Data: Array<MailjetMessage>,
  Total: Scalars['Float'],
};

export type MailjetSender = {
  CreatedAt: Scalars['String'],
  DNSID: Scalars['Float'],
  Email: Scalars['String'],
  EmailType: Scalars['String'],
  Filename?: Maybe<Scalars['String']>,
  ID: Scalars['Float'],
  IsDefaultSender: Scalars['Boolean'],
  Name?: Maybe<Scalars['String']>,
  Status: Scalars['String'],
};

export type MailjetSenderQueryParams = {
  countOnly?: Maybe<Scalars['Boolean']>,
  DnsID?: Maybe<Scalars['Float']>,
  Domain?: Maybe<Scalars['String']>,
  Email?: Maybe<Scalars['String']>,
  IsDomainSender?: Maybe<Scalars['Boolean']>,
  Limit?: Maybe<Scalars['Float']>,
  LocalPart?: Maybe<Scalars['String']>,
  Offset?: Maybe<Scalars['Float']>,
  ShowDeleted?: Maybe<Scalars['Boolean']>,
  Sort?: Maybe<Scalars['String']>,
  Status?: Maybe<Scalars['String']>,
};

export type MailjetSenderResponse = {
  Count: Scalars['Float'],
  Data: Array<MailjetSender>,
  Total: Scalars['Float'],
};

export enum MailjetStatus {
  Blocked = 'blocked',
  BookingDeleted = 'bookingDeleted',
  Bounce = 'bounce',
  Clicked = 'clicked',
  Deferred = 'deferred',
  Hardbounced = 'hardbounced',
  Opened = 'opened',
  Queued = 'queued',
  Sent = 'sent',
  Softbounced = 'softbounced',
  Spam = 'spam',
  Unknown = 'unknown',
  Unsub = 'unsub'
}

export type MailjetVariables = {
  attendee?: Maybe<KeyValue>,
  booker?: Maybe<KeyValue>,
  bookerId?: Maybe<Scalars['String']>,
  booking?: Maybe<KeyValue>,
  bookingId?: Maybe<Scalars['String']>,
  course?: Maybe<KeyValue>,
  customId: Scalars['String'],
  footerText: Scalars['String'],
  headerText: Scalars['String'],
  instructorId?: Maybe<Scalars['String']>,
  location?: Maybe<KeyValue>,
  replyTo: Scalars['String'],
  signature: Scalars['String'],
  subject: Scalars['String'],
};

export type MailReplacementData = {
  email: Scalars['String'],
  receiverName: Scalars['String'],
  textBlocks?: Maybe<Array<BasicEmailVariables>>,
};

export enum MailSentStatus {
  Error = 'Error',
  Pending = 'Pending',
  Success = 'Success'
}

export type MessageHistory = {
  Count: Scalars['Float'],
  Data: Array<MessageHistoryData>,
  Total: Scalars['Float'],
};

export type MessageHistoryData = {
  Comment: Scalars['String'],
  EventAt: Scalars['Float'],
  EventType: Scalars['String'],
  State: Scalars['String'],
  Useragent: Scalars['String'],
  UseragentID: Scalars['String'],
};

export type MessageStatus = {
  Count: Scalars['Float'],
  Data: Array<MessageStatusData>,
  Total: Scalars['Float'],
};

export type MessageStatusData = {
  ArrivedAt: Scalars['String'],
  AttachmentCount: Scalars['Float'],
  AttemptCount: Scalars['Float'],
  ContactAlt: Scalars['String'],
  ContactID: Scalars['Float'],
  Delay: Scalars['Float'],
  DestinationID: Scalars['Float'],
  FilterTime: Scalars['Float'],
  ID: Scalars['String'],
  IsClickTracked: Scalars['Boolean'],
  IsHTMLPartIncluded: Scalars['Boolean'],
  IsOpenTracked: Scalars['Boolean'],
  IsTextPartIncluded: Scalars['Boolean'],
  IsUnsubTracked: Scalars['Boolean'],
  MessageSize: Scalars['Float'],
  SenderID: Scalars['Float'],
  SpamassassinScore: Scalars['Float'],
  SpamassRules: Scalars['String'],
  StatePermanent: Scalars['Boolean'],
  Status: MailjetStatus,
  Subject: Scalars['String'],
  UUID: Scalars['String'],
};

export type MjmlTemplate = {
  Count: Scalars['Float'],
  Data: Scalars['String'],
  Total: Scalars['Float'],
};

export type ModifyScheduledPaymentInput = {
  id: Scalars['ID'],
  openAmount?: Maybe<Scalars['Float']>,
  paymentAmount?: Maybe<Scalars['Float']>,
  paymentDone?: Maybe<Scalars['DateTime']>,
  paymentDue?: Maybe<Scalars['DateTime']>,
  paymentMethodId?: Maybe<Scalars['String']>,
  sepaClearance?: Maybe<Scalars['Boolean']>,
};

export enum MonthFilter {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

export enum MonthlySchedule {
  Consistent = 'Consistent',
  Custom = 'Custom',
  Dynamic = 'Dynamic'
}

export type Mutation = {
  addBookableCourses: Scalars['Boolean'],
  addBookerAsAttendee: Booker,
  addDefaultTemplate: Template,
  addFaUserByBookerId: FaUser,
  addFolderDo: Scalars['JSONObject'],
  addMultiplePlaceReservations: Array<Booking>,
  addNamedScheduledPayment: ScheduledPayment,
  addPage: PageStructure,
  addPaymentMethod: PaymentMethod,
  addRoleToOrganization: Scalars['Boolean'],
  billMultipleBookings: Array<Booking>,
  bookAttendeeSetSelectedAttendee?: Maybe<Scalars['ID']>,
  bookAttendeeSetSelectedCourse?: Maybe<Scalars['ID']>,
  cancelBookingAndGenerateReversalInvoice: Scalars['Boolean'],
  changeAttendeeMemberStatus: Attendee,
  changeDefaultPaymentMethod: Attendee,
  changeForgottenPassword: Scalars['Boolean'],
  completePasswordlessFlow: UserType,
  completePasswordlessFlowEndUserApp: UserType,
  confirmAGB: Scalars['Boolean'],
  confirmPrivacyStatement: Scalars['Boolean'],
  convertBookingToWaitlist: Booking,
  convertPhoneNumbersToInternationalFormat: Array<Sms77FormatApiResponse>,
  convertWaitlistToBooking: Booking,
  copyAttendeeToCourse: Booking,
  createAndRegisterAdmin: UserType,
  createAndRegisterThirdPartyUser: UserType,
  createAndRegisterThirdPartyUserAndBooker: UserType,
  createAttendeeBadge: AttendeeBadge,
  createAttendeeBadgeByBadgeName: AttendeeBadge,
  createBadge: Badge,
  createBooker: Booker,
  createBooking: Booking,
  createBookingRestriction: BookingRestriction,
  createCorrectionInvoice: Invoice,
  createCourse: Course,
  createCourseCategory: CourseCategory,
  createCourseLesson: CourseLesson,
  createCourseLessonBooking: CourseLessonBooking,
  createCourseLessonBookingsByCourseId: Scalars['JSONObject'],
  createCourseLessons: Array<CourseLesson>,
  createCourseType: CourseType,
  createDummyCamtFileForMultiplePayments: Scalars['String'],
  createFaUserWithOrAddEndUserRights: FaUser,
  createInstallmentRate: InstallmentRate,
  createInvoice: Invoice,
  createInvoicesByBookingIds: Array<Invoice>,
  createInvoicesByCourseId: Array<Invoice>,
  createLocation: Location,
  createLocationContact: LocationContact,
  createMailjetSender: CreateMailjetSenderOutput,
  createOrganization: KoOrganization,
  createParticipationPrerequisite: ParticipationPrerequisites,
  createSepaXmlById: Scalars['String'],
  createSepaXmlByTimeRange: Scalars['JSONObject'],
  createSettingEndUserApp: Scalars['JSONObject'],
  createSettingEndUserAppWithDefaultValues: SettingEndUserApp,
  createTemplate: Template,
  deleteAllPlaceReservationsByCourseId: Scalars['Float'],
  deleteAttendee: Scalars['Boolean'],
  deleteAttendeeBadge: Scalars['Boolean'],
  deleteBadge: Scalars['Boolean'],
  deleteBooker: Scalars['Boolean'],
  deleteBookingRestriction: Scalars['Boolean'],
  deleteCategoryById: CourseCategory,
  deleteCompanyLogo: CompanySetting,
  deleteCourseLessonBooking: Scalars['Boolean'],
  deleteFileDo: Scalars['JSONObject'],
  deleteFolderDo: Scalars['JSONObject'],
  deleteFusionAuthUserByIds: Scalars['Boolean'],
  deleteFusionAuthUsersByEmails: Scalars['JSONObject'],
  deleteInstallmentRate: Scalars['Boolean'],
  deleteInstallmentRatesByCourseId: Scalars['Float'],
  deletePage: PageStructure,
  deleteScheduledPayment: ScheduledPayment,
  deleteSelectionOfWaitlist: Scalars['Float'],
  deleteTemplate: Scalars['Boolean'],
  disablePasswordlessLoginEndUserApp: Scalars['JSONObject'],
  downloadFileDo: Scalars['JSONObject'],
  enablePasswordlessLoginEndUserApp: Scalars['JSONObject'],
  fetchByYear: Array<Holiday>,
  fetchByYearAndStateCode: Array<Holiday>,
  fetchPublicHolidaysByYear: Array<Holiday>,
  forgetPassword: Scalars['Boolean'],
  forgotPassword: Scalars['Boolean'],
  generatePaymentSchedulePreview: Array<Scalars['JSONObject']>,
  generateScheduledPaymentsByCourse: ScheduledPaymentPreview,
  generateScheduledPaymentsForSettledBooking: Array<ScheduledPayment>,
  generateUpdatedSchedulesNewBookingPrice: Array<ScheduledPayment>,
  importBookers: Scalars['JSONObject'],
  logging: Scalars['Boolean'],
  logoutUser: Scalars['Boolean'],
  mergeBookers: Booker,
  modifyScheduledPayment: ScheduledPayment,
  passwordlessLogin: Scalars['Boolean'],
  passwordlessLoginEndUserApp: Scalars['Boolean'],
  refactoredCourseDetails: Scalars['Boolean'],
  refreshAccessToken: Scalars['String'],
  removeAllBookersOfTenant: Scalars['Boolean'],
  removeAllBookings: Scalars['Boolean'],
  removeBookableCourse: Scalars['Boolean'],
  removeBooking: Scalars['Boolean'],
  removeBookings: Scalars['Boolean'],
  removeCourse: Scalars['Boolean'],
  removeCourseLesson: Scalars['Boolean'],
  removeCourseType: Scalars['Boolean'],
  removeInstructor: Scalars['Boolean'],
  removeLocation: Scalars['Boolean'],
  removeLocationContact: Scalars['Boolean'],
  removeParticipationPrerequisite: Scalars['Boolean'],
  renameFileDo: Scalars['JSONObject'],
  renameFolderDo: Scalars['JSONObject'],
  reOrderBadges: Scalars['Boolean'],
  resendBookingConfirmation: Scalars['JSONObject'],
  resendEmailVerification: Scalars['Boolean'],
  resetAllUnsetteledScheduledPaymentsOfCourse: ResetScheduledPaymentsOfCourseResult,
  resetScheduledPaymentsOfBooking: ResetScheduledPaymentsOfBookingResult,
  rollbackPaymentStatus: Scalars['Boolean'],
  saveAdmin: Admin,
  saveInstructor: Instructor,
  sendBlankMailToMultipleBookers: Scalars['Boolean'],
  sendBookingConfirmation: Scalars['JSONObject'],
  sendBookingInvoice: Scalars['JSONObject'],
  sendBookingInvoicesByBookingIds: Scalars['JSONObject'],
  sendEmailsToSelectionOfCourse: Scalars['JSONObject'],
  sendEmailTemplate: Scalars['JSONObject'],
  sendEmailToSelectionOfAttendees: Scalars['JSONObject'],
  sendEmailToSelectionOfBookers: Scalars['JSONObject'],
  sendEmailToSelectionOfScheduledPayments: Scalars['JSONObject'],
  sendEmailToSelectionOfStaff: Scalars['JSONObject'],
  sendInvoicesToSelectionOfBookings: Scalars['JSONObject'],
  sendMail: Array<Scalars['JSONObject']>,
  sendMailToSelectionOfBookings: Scalars['JSONObject'],
  sendSms: Sms77JsonResponse,
  sendTestMail: Scalars['JSONObject'],
  sendTestMailBooker: Scalars['JSONObject'],
  setIncludeCourseDetails: Template,
  setMultipleScheduledPaymentsPaid: Scalars['Boolean'],
  setNewCustomerNumber: Customer,
  setNewPaymentAmount: ScheduledPayment,
  setNewPaymentDoneDate: ScheduledPayment,
  setScheduledPaymentPaid: ScheduledPayment,
  setScheduledPaymentUnpaid: ScheduledPayment,
  setSepaClearance: ScheduledPayment,
  singleUpload: Scalars['JSONObject'],
  softDeleteBadge: Badge,
  thirdPartyLogin: UserType,
  transferAttendeeToBooker: Attendee,
  transferAttendeeToCourse: Booking,
  transferMultipleBookings: Array<Booking>,
  unBillMultipleBookings: Array<Booking>,
  updateAttendanceListNote: Booking,
  updateAttendanceListSettings: UserSetting,
  updateAttendeeBadge: AttendeeBadge,
  updateBadge: Badge,
  updateBooker: Booker,
  updateBookerAccountEndUserApp: Booker,
  updateBookerCredit: Booker,
  updateBookerIdMissmatch: EmailNotification,
  updateBooking: Booking,
  updateBookingPaymentMethod: Booking,
  updateBookingRestriction: BookingRestriction,
  updateCompanyAgb: CompanySetting,
  updateCompanyPrivacyStatement: CompanySetting,
  updateCompanySettings: CompanySetting,
  updateCourse: Course,
  updateCourseAnnotation: Course,
  updateCourseCategory: CourseCategory,
  updateCourseLessonBooking: CourseLessonBooking,
  updateCoursesStartDateTime: Scalars['JSONObject'],
  updateCourseType: CourseType,
  updateCustomCompanyHolidays: Array<Holiday>,
  updateInstallmentRate: InstallmentRate,
  updateInvoice: Invoice,
  updateLocalLocationId?: Maybe<Scalars['ID']>,
  updateLocation: Location,
  updateLocationContact: LocationContact,
  updateManyScheduledPaymentIds: ScheduledPayment,
  updateMultipleBookerIdMissmatches: Array<EmailNotification>,
  updateMultipleCoursesShowInWeb: Array<Course>,
  updateNoticeStaff: Booking,
  updateOrganizationSubscriptionType: KoOrganization,
  updatePage: PageStructure,
  updateParticipationPrerequisite: ParticipationPrerequisites,
  updateScheduledPayment: ScheduledPayment,
  updateSepaPaymentMethod: PaymentMethod,
  updateSettingEndUserApp: SettingEndUserApp,
  updateSettings: Setting,
  updateSubscriptionFeatures: Subscription,
  updateTableColumnSettings: UserSetting,
  updateTemplate: Template,
  uploadCompanyLogo: Scalars['JSONObject'],
  uploadDummyFile: Scalars['JSONObject'],
  uploadFilesDo: Scalars['JSONObject'],
  uploadSingleFileDo: Scalars['JSONObject'],
  userSettingSetOptimizedCourseCalendar: UserSetting,
  userSettingsUpdateOrCreate: UserSetting,
  verifyBookersData: Scalars['JSONObject'],
};


export type MutationAddBookableCoursesArgs = {
  bookableCourseIds: Array<Scalars['String']>,
  prerequisiteCourseId: Scalars['String']
};


export type MutationAddBookerAsAttendeeArgs = {
  bookerId: Scalars['ID']
};


export type MutationAddDefaultTemplateArgs = {
  newTemplateData: NewTemplateInput
};


export type MutationAddFaUserByBookerIdArgs = {
  bookerId: Scalars['String']
};


export type MutationAddFolderDoArgs = {
  folderName: Scalars['String']
};


export type MutationAddMultiplePlaceReservationsArgs = {
  count: Scalars['Float'],
  courseId: Scalars['ID']
};


export type MutationAddNamedScheduledPaymentArgs = {
  addNamedScheduledPaymentInput: AddNamedScheduledPaymentInput
};


export type MutationAddPageArgs = {
  newPageInput: NewPageInput
};


export type MutationAddPaymentMethodArgs = {
  bookerId: Scalars['String'],
  paymentMethodType: Scalars['String'],
  sepaDebitIntput?: Maybe<SepaDebitIntput>
};


export type MutationAddRoleToOrganizationArgs = {
  role: Scalars['String']
};


export type MutationBillMultipleBookingsArgs = {
  billDate?: Maybe<Scalars['DateTime']>,
  ids: Array<Scalars['ID']>
};


export type MutationBookAttendeeSetSelectedAttendeeArgs = {
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  firstname?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>
};


export type MutationBookAttendeeSetSelectedCourseArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationCancelBookingAndGenerateReversalInvoiceArgs = {
  id: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type MutationChangeAttendeeMemberStatusArgs = {
  id: Scalars['ID'],
  member: Scalars['Boolean']
};


export type MutationChangeDefaultPaymentMethodArgs = {
  attendeeId: Scalars['ID'],
  paymentMethodId: Scalars['ID']
};


export type MutationChangeForgottenPasswordArgs = {
  changePasswordId: Scalars['String'],
  newPassword: Scalars['String']
};


export type MutationCompletePasswordlessFlowArgs = {
  loginCode: Scalars['String']
};


export type MutationCompletePasswordlessFlowEndUserAppArgs = {
  loginCode: Scalars['String']
};


export type MutationConfirmAgbArgs = {
  agbConfirmed: Scalars['Boolean'],
  bookingId: Scalars['ID']
};


export type MutationConfirmPrivacyStatementArgs = {
  bookingId: Scalars['ID'],
  privacyStatementConfirmed: Scalars['Boolean']
};


export type MutationConvertBookingToWaitlistArgs = {
  bookingId: Scalars['ID']
};


export type MutationConvertPhoneNumbersToInternationalFormatArgs = {
  phoneNumber: Scalars['String']
};


export type MutationConvertWaitlistToBookingArgs = {
  bookingId: Scalars['ID']
};


export type MutationCopyAttendeeToCourseArgs = {
  bookingId: Scalars['ID'],
  targetCourseId: Scalars['ID'],
  usePlaceReservation?: Maybe<Scalars['Boolean']>
};


export type MutationCreateAndRegisterAdminArgs = {
  tenantId: Scalars['String'],
  userInput: UserInput
};


export type MutationCreateAndRegisterThirdPartyUserArgs = {
  tenantId: Scalars['String'],
  thirdPartyUserInput: ThirdPartyUserInput
};


export type MutationCreateAndRegisterThirdPartyUserAndBookerArgs = {
  newBookingData: BookerInput,
  thirdPartyUserInput: ThirdPartyUserInput
};


export type MutationCreateAttendeeBadgeArgs = {
  newAttendeeBadgeInput: NewAttendeeBadgeInput
};


export type MutationCreateAttendeeBadgeByBadgeNameArgs = {
  newAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput
};


export type MutationCreateBadgeArgs = {
  newBadgeInput: NewBadgeInput
};


export type MutationCreateBookerArgs = {
  newBookerData: BookerInput
};


export type MutationCreateBookingArgs = {
  newBookingData: NewBookingInput,
  onlineBooking?: Maybe<Scalars['Boolean']>,
  sendMail: Scalars['Boolean']
};


export type MutationCreateBookingRestrictionArgs = {
  newBookingRestrictionInput: NewBookingRestrictionInput
};


export type MutationCreateCorrectionInvoiceArgs = {
  bookingId: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type MutationCreateCourseArgs = {
  newCourseData: NewCourseInput
};


export type MutationCreateCourseCategoryArgs = {
  newCourseCategoryData: NewCourseCategoryInput
};


export type MutationCreateCourseLessonArgs = {
  newCourseLessonData: NewCourseLessonInput
};


export type MutationCreateCourseLessonBookingArgs = {
  newCourseLessonBookingInput: NewCourseLessonBookingInput
};


export type MutationCreateCourseLessonBookingsByCourseIdArgs = {
  courseId: Scalars['String']
};


export type MutationCreateCourseLessonsArgs = {
  newCourseLessonData: Array<NewCourseLessonInput>
};


export type MutationCreateCourseTypeArgs = {
  newCourseTypeData: NewCourseTypeInput
};


export type MutationCreateDummyCamtFileForMultiplePaymentsArgs = {
  createDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput
};


export type MutationCreateFaUserWithOrAddEndUserRightsArgs = {
  email: Scalars['String']
};


export type MutationCreateInstallmentRateArgs = {
  newInstallmentRateInput: NewInstallmentRateInput
};


export type MutationCreateInvoiceArgs = {
  newInvoiceData: InvoiceInput,
  sendMail?: Maybe<Scalars['Boolean']>
};


export type MutationCreateInvoicesByBookingIdsArgs = {
  bookingIds: Array<Scalars['ID']>,
  sendMail?: Maybe<Scalars['Boolean']>
};


export type MutationCreateInvoicesByCourseIdArgs = {
  courseId: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type MutationCreateLocationArgs = {
  newLocationData: NewLocationInput
};


export type MutationCreateLocationContactArgs = {
  newLocationContactData: NewLocationContactInput
};


export type MutationCreateMailjetSenderArgs = {
  createMailjetSenderInput: CreateMailjetSenderInput
};


export type MutationCreateOrganizationArgs = {
  koOrganizationData: KoOrganizationInputType
};


export type MutationCreateParticipationPrerequisiteArgs = {
  newParticipationPrerequisitesInput: NewParticipationPrerequisitesInput
};


export type MutationCreateSepaXmlByIdArgs = {
  createSingleSepaXmlInput: CreateSingleSepaXmlInput
};


export type MutationCreateSepaXmlByTimeRangeArgs = {
  createSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput
};


export type MutationCreateSettingEndUserAppArgs = {
  settingEndUserAppInput: SettingEndUserAppInput
};


export type MutationCreateTemplateArgs = {
  newTemplateData: NewTemplateInput
};


export type MutationDeleteAllPlaceReservationsByCourseIdArgs = {
  courseId: Scalars['ID']
};


export type MutationDeleteAttendeeArgs = {
  id: Scalars['String']
};


export type MutationDeleteAttendeeBadgeArgs = {
  id: Scalars['String']
};


export type MutationDeleteBadgeArgs = {
  id: Scalars['String']
};


export type MutationDeleteBookerArgs = {
  id: Scalars['String']
};


export type MutationDeleteBookingRestrictionArgs = {
  bookingRestrictionId: Scalars['String']
};


export type MutationDeleteCategoryByIdArgs = {
  id: Scalars['String']
};


export type MutationDeleteCourseLessonBookingArgs = {
  id: Scalars['String']
};


export type MutationDeleteFileDoArgs = {
  fileName: Scalars['String']
};


export type MutationDeleteFolderDoArgs = {
  folderName: Scalars['String']
};


export type MutationDeleteFusionAuthUserByIdsArgs = {
  koOrganizationId: Scalars['String'],
  userIds: Array<Scalars['String']>
};


export type MutationDeleteFusionAuthUsersByEmailsArgs = {
  emailAddresses: Array<Scalars['String']>
};


export type MutationDeleteInstallmentRateArgs = {
  id: Scalars['ID']
};


export type MutationDeleteInstallmentRatesByCourseIdArgs = {
  courseId: Scalars['ID']
};


export type MutationDeletePageArgs = {
  pageId: Scalars['String']
};


export type MutationDeleteScheduledPaymentArgs = {
  id: Scalars['String']
};


export type MutationDeleteSelectionOfWaitlistArgs = {
  bookingIds: Array<Scalars['ID']>
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['String']
};


export type MutationDownloadFileDoArgs = {
  fileName: Scalars['String']
};


export type MutationEnablePasswordlessLoginEndUserAppArgs = {
  passwordlessIdCodeLength: Scalars['Float']
};


export type MutationFetchByYearArgs = {
  year: Scalars['String']
};


export type MutationFetchByYearAndStateCodeArgs = {
  stateCode: StateCode,
  year: Scalars['String']
};


export type MutationFetchPublicHolidaysByYearArgs = {
  year: Scalars['String']
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String']
};


export type MutationForgotPasswordArgs = {
  forgotPasswordWithStateInput: ForgotPasswordWithStateInput
};


export type MutationGeneratePaymentSchedulePreviewArgs = {
  bookingPrice: Scalars['Float'],
  courseId?: Maybe<Scalars['ID']>,
  lessonDates: Array<Scalars['DateTime']>,
  monthlySchedule: MonthlySchedule,
  paymentType: PaymentType
};


export type MutationGenerateScheduledPaymentsByCourseArgs = {
  attendeeId?: Maybe<Scalars['ID']>,
  courseId: Scalars['ID'],
  creditedBalance?: Maybe<Scalars['Float']>,
  currentScheduledPayments?: Maybe<Array<ScheduledPaymentSimpleInput>>,
  memberDiscount?: Maybe<Scalars['Float']>,
  omitMemberDiscount?: Maybe<Scalars['Boolean']>,
  paymentType?: Maybe<PaymentType>
};


export type MutationGenerateScheduledPaymentsForSettledBookingArgs = {
  bookingId: Scalars['ID'],
  savePayments?: Maybe<Scalars['Boolean']>
};


export type MutationGenerateUpdatedSchedulesNewBookingPriceArgs = {
  bookingPrice: Scalars['Float'],
  scheduledPayments: Array<UpdateScheduledPaymentByBookingInput>
};


export type MutationImportBookersArgs = {
  importBookersInput: ImportBookersInput
};


export type MutationLoggingArgs = {
  input: LoggingInput
};


export type MutationMergeBookersArgs = {
  mergedBookerId: Scalars['ID'],
  updateBookerData: BookerInput
};


export type MutationModifyScheduledPaymentArgs = {
  modifyScheduledPaymentInput: ModifyScheduledPaymentInput
};


export type MutationPasswordlessLoginArgs = {
  loginData: PasswordlessLoginInputType
};


export type MutationPasswordlessLoginEndUserAppArgs = {
  endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType
};


export type MutationRefactoredCourseDetailsArgs = {
  courseId: Scalars['ID']
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
};


export type MutationRemoveBookableCourseArgs = {
  bookableCourseId: Scalars['String'],
  prerquisiteCourseId: Scalars['String']
};


export type MutationRemoveBookingArgs = {
  bookingDeleteAction?: Maybe<BookingDeleteAction>,
  id: Scalars['ID'],
  notifyBooker?: Maybe<Scalars['Boolean']>
};


export type MutationRemoveBookingsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationRemoveCourseArgs = {
  id: Scalars['ID']
};


export type MutationRemoveCourseLessonArgs = {
  id: Scalars['ID']
};


export type MutationRemoveCourseTypeArgs = {
  id: Scalars['ID']
};


export type MutationRemoveInstructorArgs = {
  id: Scalars['String']
};


export type MutationRemoveLocationArgs = {
  id: Scalars['ID']
};


export type MutationRemoveLocationContactArgs = {
  id: Scalars['ID']
};


export type MutationRemoveParticipationPrerequisiteArgs = {
  id: Scalars['ID']
};


export type MutationRenameFileDoArgs = {
  fileName: Scalars['String'],
  newFileName: Scalars['String']
};


export type MutationRenameFolderDoArgs = {
  folderName: Scalars['String'],
  newFolderName: Scalars['String']
};


export type MutationReOrderBadgesArgs = {
  movedBadgeId: Scalars['ID'],
  targetBadgeId: Scalars['ID']
};


export type MutationResendBookingConfirmationArgs = {
  bookingId: Scalars['ID']
};


export type MutationResendEmailVerificationArgs = {
  userId: Scalars['String']
};


export type MutationResetAllUnsetteledScheduledPaymentsOfCourseArgs = {
  courseId: Scalars['ID'],
  preview?: Maybe<Scalars['Boolean']>
};


export type MutationResetScheduledPaymentsOfBookingArgs = {
  bookingId: Scalars['ID'],
  preview?: Maybe<Scalars['Boolean']>
};


export type MutationRollbackPaymentStatusArgs = {
  id: Scalars['ID']
};


export type MutationSaveAdminArgs = {
  saveAdminData: SaveAdminInput
};


export type MutationSaveInstructorArgs = {
  saveInstructorData: SaveInstructorInput
};


export type MutationSendBlankMailToMultipleBookersArgs = {
  file?: Maybe<Scalars['Upload']>,
  input: BlankMailInputTypes
};


export type MutationSendBookingConfirmationArgs = {
  bookingId: Scalars['ID']
};


export type MutationSendBookingInvoiceArgs = {
  bookingId: Scalars['ID'],
  reversal?: Maybe<Scalars['Boolean']>
};


export type MutationSendBookingInvoicesByBookingIdsArgs = {
  bookingIds: Array<Scalars['ID']>,
  reversal?: Maybe<Scalars['Boolean']>
};


export type MutationSendEmailsToSelectionOfCourseArgs = {
  sendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput
};


export type MutationSendEmailTemplateArgs = {
  sendTemplateInput: SendTemplateInput
};


export type MutationSendEmailToSelectionOfAttendeesArgs = {
  sendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput
};


export type MutationSendEmailToSelectionOfBookersArgs = {
  sendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput
};


export type MutationSendEmailToSelectionOfScheduledPaymentsArgs = {
  sendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput
};


export type MutationSendEmailToSelectionOfStaffArgs = {
  sendMailToSelectionOfStaffInput: SendMailToSelectionOfStaffInput
};


export type MutationSendInvoicesToSelectionOfBookingsArgs = {
  sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput
};


export type MutationSendMailArgs = {
  sandboxMode?: Maybe<Scalars['Boolean']>,
  sendParamsMessageInput: SendParamsMessageInput
};


export type MutationSendMailToSelectionOfBookingsArgs = {
  sendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput
};


export type MutationSendSmsArgs = {
  smsParams: SendSmsInput
};


export type MutationSendTestMailArgs = {
  sandboxMode?: Maybe<Scalars['Boolean']>
};


export type MutationSendTestMailBookerArgs = {
  bookerId: Scalars['ID']
};


export type MutationSetIncludeCourseDetailsArgs = {
  includeCourseDetails: Scalars['Boolean'],
  templateId: Scalars['ID']
};


export type MutationSetMultipleScheduledPaymentsPaidArgs = {
  paymentDone?: Maybe<Scalars['DateTime']>,
  paymentIds: Array<Scalars['ID']>
};


export type MutationSetNewCustomerNumberArgs = {
  newCustomerNumber: Scalars['Float']
};


export type MutationSetNewPaymentAmountArgs = {
  id: Scalars['String'],
  paymentAmount: Scalars['Float']
};


export type MutationSetNewPaymentDoneDateArgs = {
  id: Scalars['String'],
  paymentDoneDate: Scalars['DateTime']
};


export type MutationSetScheduledPaymentPaidArgs = {
  id: Scalars['String'],
  paymentDone?: Maybe<Scalars['DateTime']>
};


export type MutationSetScheduledPaymentUnpaidArgs = {
  id: Scalars['String']
};


export type MutationSetSepaClearanceArgs = {
  id: Scalars['ID'],
  sepaClearance: Scalars['Boolean']
};


export type MutationSingleUploadArgs = {
  analysis?: Maybe<Scalars['Boolean']>,
  file: Scalars['Upload']
};


export type MutationSoftDeleteBadgeArgs = {
  id: Scalars['String']
};


export type MutationThirdPartyLoginArgs = {
  thirdPartyLoginInput: ThirdPartyLoginInput
};


export type MutationTransferAttendeeToBookerArgs = {
  attendeeId: Scalars['ID'],
  newBookerId: Scalars['ID']
};


export type MutationTransferAttendeeToCourseArgs = {
  bookingDeleteAction?: Maybe<BookingDeleteAction>,
  bookingId: Scalars['ID'],
  targetCourseId: Scalars['ID'],
  usePlaceReservation?: Maybe<Scalars['Boolean']>
};


export type MutationTransferMultipleBookingsArgs = {
  transferMultipleBookingsInput: TransferMultipleBookingsInput
};


export type MutationUnBillMultipleBookingsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUpdateAttendanceListNoteArgs = {
  attendanceListNote?: Maybe<Scalars['String']>,
  bookingId: Scalars['ID']
};


export type MutationUpdateAttendanceListSettingsArgs = {
  updateAttendanceListSettingsInput: UpdateAttendanceListSettingsInput
};


export type MutationUpdateAttendeeBadgeArgs = {
  editAttendeeBadgeInput: EditAttendeeBadgeInput
};


export type MutationUpdateBadgeArgs = {
  updateBadgeInput: UpdateBadgeInput
};


export type MutationUpdateBookerArgs = {
  fromEndUserApp?: Maybe<Scalars['Boolean']>,
  updateBookerData: BookerInput
};


export type MutationUpdateBookerAccountEndUserAppArgs = {
  fromEndUserApp?: Maybe<Scalars['Boolean']>,
  updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput
};


export type MutationUpdateBookerCreditArgs = {
  bookerId: Scalars['ID'],
  credit: Scalars['Float']
};


export type MutationUpdateBookerIdMissmatchArgs = {
  newBookerId: Scalars['ID'],
  notificationId: Scalars['ID'],
  oldBookerId?: Maybe<Scalars['ID']>
};


export type MutationUpdateBookingArgs = {
  editBookingData: EditBookingInput,
  id: Scalars['String']
};


export type MutationUpdateBookingPaymentMethodArgs = {
  bookingId: Scalars['ID'],
  paymentMethodId: Scalars['ID']
};


export type MutationUpdateBookingRestrictionArgs = {
  updateBookingRestrictionInput: UpdateBookingRestrictionInput
};


export type MutationUpdateCompanyAgbArgs = {
  agb: Scalars['String']
};


export type MutationUpdateCompanyPrivacyStatementArgs = {
  privacyStatement: Scalars['String']
};


export type MutationUpdateCompanySettingsArgs = {
  newCompanyData: CompanySettingInput
};


export type MutationUpdateCourseArgs = {
  editCourseData: EditCourseInput,
  fillFromWaitingList?: Maybe<Scalars['Boolean']>,
  id: Scalars['String'],
  notifyBooker?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateCourseAnnotationArgs = {
  annotation?: Maybe<Scalars['String']>,
  id: Scalars['String']
};


export type MutationUpdateCourseCategoryArgs = {
  id: Scalars['String'],
  newCourseCategoryData: NewCourseCategoryInput
};


export type MutationUpdateCourseLessonBookingArgs = {
  updateCourseLessonBookingInput: UpdateCourseLessonBookingInput
};


export type MutationUpdateCoursesStartDateTimeArgs = {
  saveResult?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateCourseTypeArgs = {
  id: Scalars['String'],
  newCourseTypeData: NewCourseTypeInput
};


export type MutationUpdateCustomCompanyHolidaysArgs = {
  holidayInputs: Array<HolidayInput>
};


export type MutationUpdateInstallmentRateArgs = {
  updateInstallmentRateInput: UpdateInstallmentRateInput
};


export type MutationUpdateInvoiceArgs = {
  editInvoiceData: EditInvoiceInput,
  id: Scalars['String']
};


export type MutationUpdateLocalLocationIdArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationUpdateLocationArgs = {
  editLocationData: EditLocationInput
};


export type MutationUpdateLocationContactArgs = {
  editLocationContactData: EditLocationContactInput
};


export type MutationUpdateManyScheduledPaymentIdsArgs = {
  editScheduledPaymentData: EditScheduledPaymentInput,
  paymentIds: Array<Scalars['ID']>
};


export type MutationUpdateMultipleBookerIdMissmatchesArgs = {
  updateMultipleBookerIdMissmatchesInput: Array<UpdateBookerIdMissmatchesInput>
};


export type MutationUpdateMultipleCoursesShowInWebArgs = {
  courseIds: Array<Scalars['ID']>,
  showInWeb: Scalars['Boolean']
};


export type MutationUpdateNoticeStaffArgs = {
  id: Scalars['String'],
  noticeStaff: Scalars['String']
};


export type MutationUpdateOrganizationSubscriptionTypeArgs = {
  koOrganizationId: Scalars['ID'],
  subscriptionType: SubscriptionType
};


export type MutationUpdatePageArgs = {
  editPageInput: EditPageInput
};


export type MutationUpdateParticipationPrerequisiteArgs = {
  updateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput
};


export type MutationUpdateScheduledPaymentArgs = {
  editScheduledPaymentData: EditScheduledPaymentInput,
  id: Scalars['String']
};


export type MutationUpdateSepaPaymentMethodArgs = {
  input: UpdatePaymentMethodInput
};


export type MutationUpdateSettingEndUserAppArgs = {
  updateSettingEndUserAppInput: UpdateSettingEndUserAppInput
};


export type MutationUpdateSettingsArgs = {
  newSettingsData: SettingInput
};


export type MutationUpdateSubscriptionFeaturesArgs = {
  features: SubscriptionFeaturesInput,
  subscriptionId: Scalars['ID']
};


export type MutationUpdateTableColumnSettingsArgs = {
  updateTableColumnSettingsInput: UpdateTableColumnSettingsInput
};


export type MutationUpdateTemplateArgs = {
  editTemplateData: EditTemplateInput,
  id: Scalars['String']
};


export type MutationUploadCompanyLogoArgs = {
  analysis?: Maybe<Scalars['Boolean']>,
  file: Scalars['Upload']
};


export type MutationUploadFilesDoArgs = {
  fileArray: Array<Scalars['Upload']>,
  metadata?: Maybe<UploadMetadataInput>,
  path: Scalars['String']
};


export type MutationUploadSingleFileDoArgs = {
  file: Scalars['Upload'],
  path: Scalars['String']
};


export type MutationUserSettingSetOptimizedCourseCalendarArgs = {
  optimize: Scalars['Boolean']
};


export type MutationUserSettingsUpdateOrCreateArgs = {
  userSettingsInput: UserSettingsInputTypes
};


export type MutationVerifyBookersDataArgs = {
  importBookersInput: ImportBookersInput
};

export type NewAttendeeBadgeByBadgeNameInput = {
  attendeeId: Scalars['ID'],
  comment?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  dateOfAchivement?: Maybe<Scalars['DateTime']>,
  name: Scalars['ID'],
};

export type NewAttendeeBadgeInput = {
  attendeeId: Scalars['ID'],
  badgeId: Scalars['ID'],
  comment?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  dateOfAchivement?: Maybe<Scalars['DateTime']>,
};

export type NewBadgeInput = {
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
};

export type NewBookingInput = {
  additionalInfo?: Maybe<Scalars['String']>,
  agbConfirmed?: Maybe<Scalars['Boolean']>,
  attendeeId?: Maybe<Scalars['ID']>,
  bookingNumber?: Maybe<Scalars['Float']>,
  bookingNumberPrefix?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ID']>,
  coursePriceId?: Maybe<Scalars['ID']>,
  creditedBalance?: Maybe<Scalars['Float']>,
  foundUs?: Maybe<Scalars['String']>,
  grossPrice?: Maybe<Scalars['Float']>,
  isSpecial?: Maybe<Scalars['Boolean']>,
  memberDiscount?: Maybe<Scalars['Float']>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  netPrice?: Maybe<Scalars['Float']>,
  newsLetter?: Maybe<Scalars['Boolean']>,
  noticeBooker?: Maybe<Scalars['String']>,
  noticeStaff?: Maybe<Scalars['String']>,
  notifyUser: Scalars['Boolean'],
  paymentMethodId?: Maybe<Scalars['ID']>,
  paymentType?: Maybe<PaymentType>,
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>,
  scheduledPayments?: Maybe<Array<UpdateScheduledPaymentByBookingInput>>,
  specialPrice?: Maybe<Scalars['Float']>,
  startDateTime: Scalars['DateTime'],
  vat?: Maybe<Scalars['Float']>,
};

export type NewBookingRestrictionInput = {
  active: Scalars['Boolean'],
  blockedPlaces?: Maybe<Scalars['Float']>,
  courseId: Scalars['ID'],
  endDateTime?: Maybe<Scalars['DateTime']>,
  expireAction?: Maybe<ExpireAction>,
  includeBookingList?: Maybe<Scalars['Boolean']>,
  includeWaitingList?: Maybe<Scalars['Boolean']>,
  membersOnly: Scalars['Boolean'],
  prerequisiteCourseIds?: Maybe<Array<Scalars['ID']>>,
  prerequisiteCourses?: Maybe<Array<Scalars['ID']>>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  visibleForAll: Scalars['Boolean'],
};

export type NewBookingsKo9Input = {
  courseName: Scalars['String'],
  courseNumber: Scalars['String'],
  firstCourseLesson: Scalars['DateTime'],
  teilnahmeId: Scalars['String'],
};

export type NewCourseCategoryInput = {
  description?: Maybe<Scalars['String']>,
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
};

export type NewCourseInput = {
  additionalShortInfo?: Maybe<Scalars['String']>,
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>,
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>,
  annotation: Scalars['String'],
  courseNumberFormat: CourseNumberFormat,
  coursePrices: Array<NewCoursePriceInput>,
  courseTypeId: Scalars['ID'],
  customCourseNumberPrefix?: Maybe<Scalars['String']>,
  cycle: CourseCycle,
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>,
  defaultLessonCount: Scalars['Float'],
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<Scalars['String']>,
  endDateTime: Scalars['DateTime'],
  fee?: Maybe<Scalars['Float']>,
  installmentRates?: Maybe<Array<CreateInstallmentRateByCourseCreateInput>>,
  instructors: Array<CourseInstructorIdInput>,
  isActive?: Maybe<Scalars['Boolean']>,
  lessons: Array<NewCourseLessonInput>,
  locationId: Scalars['ID'],
  maxAttendees: Scalars['Float'],
  maxWaitlist?: Maybe<Scalars['Float']>,
  minAttendees: Scalars['Float'],
  monthlySchedule?: Maybe<MonthlySchedule>,
  paymentInterval: PaymentInterval,
  paymentType?: Maybe<PaymentType>,
  showInWeb?: Maybe<Scalars['Boolean']>,
  startDateTime: Scalars['DateTime'],
  vat?: Maybe<Scalars['Float']>,
  venueId: Scalars['ID'],
  websiteInfo?: Maybe<Scalars['String']>,
};

export type NewCourseLessonBookingInput = {
  attendance?: Maybe<Scalars['Boolean']>,
  bookingId: Scalars['String'],
  courseId?: Maybe<Scalars['String']>,
  courseLessonId: Scalars['String'],
  note?: Maybe<Scalars['String']>,
};

export type NewCourseLessonInput = {
  courseId?: Maybe<Scalars['ID']>,
  endDateTime: Scalars['DateTime'],
  instructors: Array<CourseInstructorIdInput>,
  locationId: Scalars['ID'],
  startDateTime: Scalars['DateTime'],
  venueId: Scalars['ID'],
};

export type NewCoursePriceInput = {
  bookings?: Maybe<Array<NewBookingInput>>,
  course?: Maybe<NewCourseInput>,
  courseId?: Maybe<Scalars['ID']>,
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<DiscountUnit>,
  grossPrice: Scalars['Float'],
  includedEntranceFee?: Maybe<Scalars['Float']>,
  isSpecial?: Maybe<Scalars['Boolean']>,
  netPrice?: Maybe<Scalars['Float']>,
  vat: Scalars['Float'],
};

export type NewCourseTypeInput = {
  annotation?: Maybe<Scalars['String']>,
  bold: Scalars['Boolean'],
  categoryId?: Maybe<Scalars['ID']>,
  color: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  fee?: Maybe<Scalars['Float']>,
  inWeekPlanner: Scalars['Boolean'],
  isActive: Scalars['Boolean'],
  maxAge?: Maybe<Scalars['Float']>,
  maxAttendees?: Maybe<Scalars['Float']>,
  minAge?: Maybe<Scalars['Float']>,
  minAttendees?: Maybe<Scalars['Float']>,
  name: Scalars['String'],
  participationPrerequisites?: Maybe<Array<UpdateParticipationPrerequisitesInput>>,
  paymentInterval?: Maybe<PaymentInterval>,
  selfBookerCourse: Scalars['Boolean'],
  shortHandId: Scalars['String'],
  showInWeb: Scalars['Boolean'],
  vat?: Maybe<Scalars['Float']>,
  websiteInfo?: Maybe<Scalars['String']>,
};

export type NewInstallmentPlanInput = {
  courseId?: Maybe<Scalars['String']>,
  courseLessonStartDates: Array<Scalars['DateTime']>,
  coursePrice: Scalars['Float'],
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>,
  discount?: Maybe<Scalars['Float']>,
  discountUnit?: Maybe<Scalars['String']>,
  monthCount?: Maybe<Scalars['Float']>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  paymentInterval: PaymentInterval,
  paymentType: PaymentType,
  vat: Scalars['Float'],
};

export type NewInstallmentRateInput = {
  courseId: Scalars['String'],
  installmentAmount: Scalars['Float'],
  installmentDue: Scalars['DateTime'],
  installmentType?: Maybe<InstallmentType>,
};

export type NewLocationContactInput = {
  department?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  mobileNumber?: Maybe<Scalars['String']>,
  note?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  salutation: SalutationType,
};

export type NewLocationInput = {
  abbreviation: Scalars['String'],
  city: Scalars['String'],
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  generalLocationContact: GeneralLocationContactInput,
  info: Scalars['String'],
  isPublished: Scalars['Boolean'],
  locationContacts: Array<LocationContactIdInput>,
  name: Scalars['String'],
  street: Scalars['String'],
  venues: Array<NewVenueInput>,
  website: Scalars['String'],
  zipCode: Scalars['String'],
};

export type NewNotificationInput = {
  attachments?: Maybe<Array<Scalars['JSONObject']>>,
  bcc: PostInputTo,
  bookerId?: Maybe<Scalars['String']>,
  bookingId?: Maybe<Scalars['String']>,
  cc: PostInputTo,
  email: Scalars['String'],
  html: Scalars['String'],
  mailjetTemplateId?: Maybe<Scalars['Float']>,
  messageHref?: Maybe<Scalars['String']>,
  messageID: Scalars['ID'],
  receiverName: Scalars['String'],
  status: MailSentStatus,
  subject: Scalars['String'],
  templateName: Scalars['String'],
  templateType: Scalars['String'],
  text?: Maybe<Scalars['String']>,
};

export type NewPageInput = {
  content: Scalars['JSONObject'],
  url: Scalars['String'],
};

export type NewParticipationPrerequisitesInput = {
  courseTypeId: Scalars['String'],
  enabled: Scalars['Boolean'],
  groupDescription?: Maybe<Scalars['String']>,
  groupLabel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  minCheckCount?: Maybe<Scalars['Float']>,
  optionalInputFields?: Maybe<Array<OptionalInputFieldsInput>>,
};

export type NewSepaXmlInput = {
  collectionDate: Scalars['DateTime'],
  creationDate: Scalars['DateTime'],
  initiatorId: Scalars['String'],
  initiatorName: Scalars['String'],
  positions: Array<SepaXmlPositionInput>,
};

export type NewTemplateInput = {
  footerText?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  isDefault?: Maybe<Scalars['Boolean']>,
  mailDataType?: Maybe<MailDataType>,
  name?: Maybe<Scalars['String']>,
  signature?: Maybe<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
  templateType: TemplateType,
};

export type NewVenueInput = {
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
  rent: Scalars['Float'],
  rentType: RentType,
};

export enum Operation {
  Eq = 'EQ',
  Gte = 'GTE',
  In = 'IN',
  Like = 'LIKE',
  Lte = 'LTE'
}

export enum Operator {
  And = 'AND',
  Or = 'OR'
}

export type OptionalInputFieldsInput = {
  description?: Maybe<Scalars['String']>,
  enabeld?: Maybe<Scalars['Boolean']>,
  label?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  options?: Maybe<Array<Scalars['JSONObject']>>,
  placeholder?: Maybe<Scalars['String']>,
  required?: Maybe<Scalars['Boolean']>,
  type?: Maybe<Scalars['String']>,
};

export type Page = {
  content: PageContent,
  id: Scalars['ID'],
  url: Scalars['String'],
};

export type PageContent = {
  body: Scalars['String'],
  subPages?: Maybe<Array<Page>>,
  subTitle?: Maybe<Scalars['String']>,
  title: Scalars['String'],
};

export type PageContentInput = {
  body: Scalars['String'],
  subTitle?: Maybe<Scalars['String']>,
  title: Scalars['String'],
};

export type PageStructure = {
  pages: Array<Page>,
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Float']>,
  page?: Maybe<Scalars['Float']>,
  sortBy?: Maybe<Scalars['String']>,
};

export type ParticipationPrerequisites = {
  courseType: CourseType,
  courseTypeId?: Maybe<Scalars['ID']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  groupDescription?: Maybe<Scalars['String']>,
  groupLabel?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  minCheckCount?: Maybe<Scalars['Float']>,
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type PasswordlessLoginInputType = {
  email: Scalars['String'],
};

export type Payment = {
  amount: Scalars['Float'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  reference: Scalars['String'],
  scheduledPayment: ScheduledPayment,
  source: Scalars['String'],
  swissQRFile?: Maybe<SwissQrFile>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  valutaDate: Scalars['DateTime'],
};

export type PaymentInput = {
  bookingId: Scalars['ID'],
};

export enum PaymentInterval {
  PerCourse = 'PerCourse',
  PerLesson = 'PerLesson'
}

export type PaymentMethod = {
  bank?: Maybe<Scalars['String']>,
  bankTransfer?: Maybe<Scalars['Boolean']>,
  bic?: Maybe<Scalars['String']>,
  booker?: Maybe<Booker>,
  bookerId?: Maybe<Scalars['ID']>,
  bookings?: Maybe<Array<Booking>>,
  cash?: Maybe<Scalars['Boolean']>,
  createDate: Scalars['DateTime'],
  createdBy?: Maybe<CreatedBy>,
  createdByUserId?: Maybe<Scalars['String']>,
  creditCardAccountholder?: Maybe<Scalars['String']>,
  creditcardNumber?: Maybe<Scalars['String']>,
  cvc?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  esr?: Maybe<Scalars['Boolean']>,
  expirationDate?: Maybe<Scalars['DateTime']>,
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  paypalAccountholder?: Maybe<Scalars['String']>,
  scheduledPayments?: Maybe<Array<ScheduledPayment>>,
  sepaAccountholder?: Maybe<Scalars['String']>,
  type?: Maybe<PaymentTransferType>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  validFrom?: Maybe<Scalars['DateTime']>,
};

export enum PaymentTransferType {
  BankTransfer = 'BankTransfer',
  Cash = 'Cash',
  Creditcard = 'Creditcard',
  Esr = 'Esr',
  None = 'None',
  Paypal = 'Paypal',
  SepaDebit = 'SepaDebit'
}

export enum PaymentType {
  Bill = 'Bill',
  Monthly = 'Monthly',
  OnTheSpot = 'OnTheSpot',
  PrePaid = 'PrePaid'
}

export type PostInputTo = {
  Email: Scalars['String'],
  MessageHref: Scalars['String'],
  MessageID: Scalars['Float'],
  MessageUUID: Scalars['String'],
};

export type PricingParams = {
  country?: Maybe<Scalars['String']>,
};

export type Query = {
  attendanceListByCourse: Array<Booking>,
  attendee: Attendee,
  attendeeCount: Scalars['Float'],
  attendees: AttendeeWithPagination,
  attendeesByBookerFirstLast: AttendeeWithPagination,
  attendeesByIds: Array<Attendee>,
  authenticate: UserType,
  badge: Badge,
  badges: Array<Badge>,
  bookedAttendeesByCourseId: Array<Attendee>,
  bookedSpots: Scalars['Float'],
  booker: Booker,
  bookerByUserId: Booker,
  bookerExistsAsAttendeeEndUserApp: Scalars['Boolean'],
  bookers: BookerWithPagination,
  bookersById: Array<Booker>,
  bookersDynamic: BookerWithPagination,
  bookersExport: Array<Booker>,
  booking: Array<Booking>,
  bookingById: Booking,
  bookingRestrictions: Array<BookingRestriction>,
  bookings: BookingWithPagination,
  bookingsByUser: Array<Booking>,
  bookingsDynamic: BookingWithPagination,
  bookingsInStartedCourses: BookingWithPagination,
  bookingsKo9: Array<BookingsKo9>,
  bookingsKo9ByAttendeeId: Array<BookingsKo9>,
  canBecomeInstructor: Scalars['Boolean'],
  checkAttendeesAgeAtCourseStart: AttendeesAgeAtCourseStart,
  checkEmailUniqueness: Scalars['Boolean'],
  checkIfIsBookedIntoCourse: Scalars['Boolean'],
  checkIfTemplateOfTypeExists: Scalars['JSONObject'],
  checkSmsApiKey: SmsBalanceResponse,
  cities: Array<LocationCity>,
  companyCurrencyPublic: Setting,
  companyInfo: KoOrganization,
  companyPublic: CompanySetting,
  companySettings: CompanySetting,
  courseById: Course,
  courseByIdPublic: Course,
  courseByLocationId: Array<Course>,
  courseCategories: Array<CourseCategory>,
  courseCategoriesPublic: Array<CourseType>,
  courseCategoryById: CourseCategory,
  courseHasAtLeastOneCourseLessonBooking: Scalars['Boolean'],
  courseIsPrerequisiteCourseFor: Array<Course>,
  courseLessonBooking: CourseLessonBooking,
  courseLessonBookingByCourseLessonAndBooking: CourseLessonBooking,
  courseLessonBookingsByCourse: Array<CourseLessonBooking>,
  courseLessonById: CourseLesson,
  courseLessons: Array<CourseLesson>,
  courseLessonsByCalendarWeek: Array<CourseLesson>,
  courseLessonsByCourseId: Array<CourseLesson>,
  courseLessonsByCourseIdPublic: Array<CourseLesson>,
  courseLessonsByDate: Array<CourseLesson>,
  courseLessonsForMonthView: Array<CourseLesson>,
  courseLessonsWithCollitions: Array<CourseLessonWithIndexOfCourseLessonInCourse>,
  courseLessonsWithPagination: CourseLessonWithPagination,
  courses: Array<Course>,
  coursesByIds: Array<Course>,
  coursesPublic: Array<Course>,
  coursesWithPagination: CourseWithPagination,
  coursesWithPaginationPublic: CourseWithPagination,
  coursesWithPrerequisiteCourses: CourseWithPagination,
  courseTypeById: CourseType,
  courseTypes: Array<CourseType>,
  courseTypesPublic: Array<CourseType>,
  courseTypeStateById: CourseType,
  currentUser: UserType,
  customerById: Customer,
  customHolidaysByCompany: Array<Holiday>,
  emailNotificationById: EmailNotification,
  emailNotifications: EmailNotificationWithPagination,
  exportedSepaXml: ExportedSepaXml,
  exportedSepaXmls: ExportedSepaXmlWithPagination,
  faUserByEmail: FaUser,
  faUserByUserId?: Maybe<FaUser>,
  findByCourseId: Instructor,
  findDuplicateBookersUnperformant: Array<Array<Booker>>,
  findDuplicateBookersWithoutEmail: Array<Array<Booker>>,
  findInstructorById: Instructor,
  findInstructorByIds: Array<Instructor>,
  findInstructorsByLocation: Array<Instructor>,
  getAdminAndInstructorUsers: Array<UserType>,
  getAllCoursesWithAllowMonthlyPayment: Array<Course>,
  getAnalyticsByDate: Array<Sms77AnalyticGroupByDate>,
  getAttendanceListSettings: UserSetting,
  getAttendeeBadge: AttendeeBadge,
  getAttendeeBadges: Array<AttendeeBadge>,
  getAttendeeBadgesByAttendeeId: Array<AttendeeBadge>,
  getAttendeeBadgesByBadgeId: Array<AttendeeBadge>,
  getBankData: Scalars['JSONObject'],
  getBilledBookings: Array<Booking>,
  getBookerByEmail?: Maybe<Booker>,
  getBookerIdMissmatchesWithPagination: BookerIdMissmatchWithPagination,
  getBookersByBookingIds: Array<Booker>,
  getBookersByCourseIds: Array<Booker>,
  getBookersByCourseWithBookingType: Array<Booker>,
  getBookingRestrictionByCourseId?: Maybe<BookingRestriction>,
  getBookingsAsCsv: Scalars['String'],
  getBookingsByAttendeeId: Array<Booking>,
  getBookingsByAttendeeIdWithOptions: Array<Booking>,
  getBookingsByCourse: BookingWithPagination,
  getBookingsByCourseIds: BookingWithPagination,
  getBookingsByIds: Array<Booking>,
  getBookingsByInvoiceIds: Array<Booking>,
  getBookingsByScheduledPaymentIds: Array<Booking>,
  getCanceledBookings: BookingWithPagination,
  getColumnsForTable: Array<Scalars['String']>,
  getContentByPathDo: ContentOfBucket,
  getContentOfBucketDo: ContentOfBucket,
  getContentOfFolderDo: ContentOfBucket,
  getCourseMinMaxAge: Scalars['JSONObject'],
  getCoursesWithHighDemand: CourseWithPagination,
  getCourseVisibility: CourseVisibilityResult,
  getDuplicateBookers: Array<Array<Booker>>,
  getEmailTemplateById: MjmlTemplate,
  getFileDo: Content,
  getInfoUserByUserId: UserType,
  getInstallmentPlanPreview: Scalars['JSONObject'],
  getInvoiceDummyPreview: Scalars['JSONObject'],
  getInvoicePreview: Scalars['JSONObject'],
  getLastCustomer: Customer,
  getMailjetSenderById: MailjetSenderResponse,
  getMailjetSenders: MailjetSenderResponse,
  getMonthlyPaymentPreview: Scalars['JSONObject'],
  getOnlineBookingsByTimeRangeCount: Scalars['Float'],
  getPages: PageStructure,
  getPasswordlessLoginSettings: Scalars['JSONObject'],
  getPaymentMethodsByBookerId: Array<PaymentMethod>,
  getPaymentMethodTypeById: Scalars['String'],
  getReferenceCoursesLink: Scalars['String'],
  getSentMailjetMessage: Scalars['JSONObject'],
  getSentMailjetMessageHistory: Scalars['JSONObject'],
  getSentMailjetMessagemessageInformation: Scalars['JSONObject'],
  getSentMailjetMessagesByMessageStatus: Scalars['JSONObject'],
  getSentMailjetMessagesWithParams: MailjetMessageResponse,
  getSentSmsMessages: Array<Sms77JournalOutbound>,
  getSettledBookings: BookingWithPagination,
  getSignedUrlDo: Scalars['String'],
  getSmsAccountBalance: SmsBalanceResponse,
  getSmsPricing: Sms77PricingResponse,
  getSmsPricingByCountryCode: Sms77PricingResponse,
  getSmsReceivers: Array<SmsReceiver>,
  getSmsReceiversCourse: Array<SmsReceiver>,
  getTenantUsers: Array<UserType>,
  getUnBilledBookings: Array<Booking>,
  getUnSettledBookings: BookingWithPagination,
  getUpcomingCoursesByTimeRange: CourseWithPagination,
  getUserDetails: UserDetailsType,
  getUserRoles: Scalars['JSONObject'],
  getWaitlistByCourse: BookingWithPagination,
  getWaitlistCountOfCourse: Scalars['Float'],
  holidays: Array<Holiday>,
  holidaysByCompany: Array<HolidayWithDays>,
  holidaysByCompanyAndDateRange: Array<HolidayWithDays>,
  holidaysByStateCode: Array<HolidayWithDays>,
  installmentRate: InstallmentRate,
  installmentRates: Array<InstallmentRate>,
  installmentRatesByCourseId: Array<InstallmentRate>,
  instructor: Instructor,
  instructors: Array<Instructor>,
  instructorsByStaffIds: Array<Instructor>,
  instructorsPublic: Array<Instructor>,
  instructorsWithPagination: InstructorsWithPagination,
  invoice: Invoice,
  invoiceBodyByBookingId: Invoice,
  invoiceBodyById: Invoice,
  invoices: InvoiceWithPagination,
  invoicesWithRelations: Array<Invoice>,
  isBadgeNameUnique: Scalars['Boolean'],
  isCourseNameUnique: Scalars['Boolean'],
  isInstructor: Scalars['Boolean'],
  isUniqueEmail: Scalars['Boolean'],
  isUserEmailVerified: Scalars['Boolean'],
  koOrganizations: Array<KoOrganization>,
  listBucketsDO: Scalars['JSONObject'],
  LocalState: LocalState,
  locationById: Location,
  locationContactById: LocationContact,
  locationContacts: Array<LocationContact>,
  locationContactsByLocation: Array<LocationContact>,
  locations: Array<Location>,
  locationsByCity: Array<Location>,
  locationsByLocationContactId: Array<Location>,
  locationsPublic: Array<Location>,
  locationsSortedByCity: Array<LocationsGroupedByCity>,
  locationsWithPagination: LocationsWithPagination,
  multipleCourseLessonsCollisionCheck: Array<CourseLessonWithIndexOfCourseLessonInCourse>,
  notificationsByBookerId: EmailNotificationWithPagination,
  notificationsByBookerOrEmail: EmailNotificationWithPagination,
  notificationsByBooking: Array<EmailNotification>,
  ownerEmail: CompanySetting,
  paidScheduledPayments: ScheduledPaymentsWithPagination,
  participationPrerequisite: ParticipationPrerequisites,
  participationPrerequisites: Array<ParticipationPrerequisites>,
  participationPrerequisitesByCourseTypeId: Array<ParticipationPrerequisites>,
  paymentMethod: PaymentMethod,
  paymentMethods: Array<PaymentMethod>,
  payments: Array<Payment>,
  prerequisiteCourses: CourseWithPagination,
  reversalInvoiceBodyById: ReversalInvoice,
  reversalInvoices: ReversalInvoiceWithPagination,
  scheduledPayment: Array<ScheduledPayment>,
  scheduledPaymentById: ScheduledPayment,
  scheduledPayments: ScheduledPaymentsWithPagination,
  scheduledPaymentsByBookerId: Array<ScheduledPayment>,
  scheduledPaymentsByBookingId: Array<ScheduledPayment>,
  scheduledPaymentSummary: ScheduledPaymentSummary,
  searchAttendeesByFullName: Array<Attendee>,
  searchBilledPayments: Array<PaymentMethod>,
  searchBookerByStreetAndHouseNumberAndPpostCodeAndCity: Array<Booker>,
  searchBookersByFirstAndLastName: Array<Booker>,
  searchBookersByStreetAndHouseNumberAndPostCodeAndCity: Array<Booker>,
  searchBookingByBookingNumberAndBookingNumberPrefix: Array<Booking>,
  searchBookingByCourseLocationPrefixAndCourseNumber: Array<Booking>,
  searchCourseByCourseLocationPrefixAndCourseNumber: Array<Course>,
  searchCourseByInstructorFirstNameAndLastName: Array<Course>,
  searchCustomerByCustomerNumberPrefixAndCustomerNumber: Array<Customer>,
  searchCustomerByFirstNameAndLastName: Array<Customer>,
  searchInvoicesByCourseNumber: Array<Invoice>,
  searchInvoicesByInvoiceNumberAndInvoiceNumberPrefix: Array<Invoice>,
  searchLocationsByName: Array<Location>,
  searchLocationsByPhoneNumberAndEmail: Array<Location>,
  searchStaffByFullnameAndAddress: Array<Staff>,
  searchUnBilledPayments: Array<PaymentMethod>,
  searchUnSettledBooking: Array<Booking>,
  sentEmailStatusFromMailjet: Scalars['JSONObject'],
  settingEndUserApp: SettingEndUserApp,
  settingEndUserAppPublic: SettingEndUserApp,
  settings: Setting,
  stressTestUserQuery: Array<UserType>,
  swissQRFiles: Array<SwissQrFile>,
  template: Template,
  templateByType: Template,
  templates: Array<Template>,
  templatesWithPagination: TemplatesWithPagination,
  tenantsWithPagination: KoOrganizationWithPagination,
  testFusioAuthFunctions: Scalars['JSONObject'],
  unpaidScheduledPayments: ScheduledPaymentsWithPagination,
  unpaidScheduledPaymentsZeroValues: Array<ScheduledPayment>,
  userSettings?: Maybe<UserSetting>,
};


export type QueryAttendanceListByCourseArgs = {
  courseId: Scalars['String']
};


export type QueryAttendeeArgs = {
  id: Scalars['String']
};


export type QueryAttendeeCountArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryAttendeesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryAttendeesByBookerFirstLastArgs = {
  searchKey: Scalars['String']
};


export type QueryAttendeesByIdsArgs = {
  attendeeIds: Array<Scalars['ID']>
};


export type QueryBadgeArgs = {
  id: Scalars['String']
};


export type QueryBookedAttendeesByCourseIdArgs = {
  courseId: Scalars['ID']
};


export type QueryBookedSpotsArgs = {
  courseId: Scalars['String']
};


export type QueryBookerArgs = {
  id: Scalars['String']
};


export type QueryBookerByUserIdArgs = {
  id: Scalars['String']
};


export type QueryBookerExistsAsAttendeeEndUserAppArgs = {
  bookerId: Scalars['ID']
};


export type QueryBookersArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookersByIdArgs = {
  bookerIds: Array<Scalars['String']>
};


export type QueryBookersDynamicArgs = {
  includeAttendees?: Maybe<Scalars['Boolean']>,
  includePayment?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookersExportArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookingArgs = {
  id: Scalars['String']
};


export type QueryBookingByIdArgs = {
  id: Scalars['ID']
};


export type QueryBookingsArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookingsByUserArgs = {
  userId: Scalars['String']
};


export type QueryBookingsDynamicArgs = {
  includeAttendee?: Maybe<Scalars['Boolean']>,
  includeCourseLessons?: Maybe<Scalars['Boolean']>,
  includeCoursePrice?: Maybe<Scalars['Boolean']>,
  includeEmailNotifications?: Maybe<Scalars['Boolean']>,
  includeInvoice?: Maybe<Scalars['Boolean']>,
  includePaymentMethod?: Maybe<Scalars['Boolean']>,
  includeReversalInvoice?: Maybe<Scalars['Boolean']>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookingsInStartedCoursesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryBookingsKo9ByAttendeeIdArgs = {
  attendeeId: Scalars['String']
};


export type QueryCanBecomeInstructorArgs = {
  email: Scalars['String']
};


export type QueryCheckAttendeesAgeAtCourseStartArgs = {
  attendeeId: Scalars['String'],
  courseId: Scalars['String']
};


export type QueryCheckEmailUniquenessArgs = {
  email: Scalars['String']
};


export type QueryCheckIfIsBookedIntoCourseArgs = {
  attendeeId: Scalars['ID'],
  courseId: Scalars['ID']
};


export type QueryCheckIfTemplateOfTypeExistsArgs = {
  templateType: TemplateType
};


export type QueryCheckSmsApiKeyArgs = {
  apiKey: Scalars['String']
};


export type QueryCourseByIdArgs = {
  id: Scalars['String']
};


export type QueryCourseByIdPublicArgs = {
  id: Scalars['String']
};


export type QueryCourseByLocationIdArgs = {
  id: Scalars['ID']
};


export type QueryCourseCategoryByIdArgs = {
  id: Scalars['String']
};


export type QueryCourseHasAtLeastOneCourseLessonBookingArgs = {
  courseId: Scalars['String']
};


export type QueryCourseIsPrerequisiteCourseForArgs = {
  courseId: Scalars['ID']
};


export type QueryCourseLessonBookingArgs = {
  id: Scalars['String']
};


export type QueryCourseLessonBookingByCourseLessonAndBookingArgs = {
  bookingId: Scalars['String'],
  courseLessonId: Scalars['String']
};


export type QueryCourseLessonBookingsByCourseArgs = {
  courseId: Scalars['String']
};


export type QueryCourseLessonByIdArgs = {
  id: Scalars['String']
};


export type QueryCourseLessonsArgs = {
  ids?: Maybe<Array<Scalars['String']>>
};


export type QueryCourseLessonsByCalendarWeekArgs = {
  calendarWeek: Scalars['Float'],
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>,
  year: Scalars['Float']
};


export type QueryCourseLessonsByCourseIdArgs = {
  courseId: Scalars['ID']
};


export type QueryCourseLessonsByCourseIdPublicArgs = {
  courseId: Scalars['ID']
};


export type QueryCourseLessonsByDateArgs = {
  date: Scalars['DateTime']
};


export type QueryCourseLessonsForMonthViewArgs = {
  month: Scalars['Float'],
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>,
  year: Scalars['Float']
};


export type QueryCourseLessonsWithCollitionsArgs = {
  collitionCheckInput: CollitionCheckInput
};


export type QueryCourseLessonsWithPaginationArgs = {
  date?: Maybe<Scalars['DateTime']>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryCoursesArgs = {
  filterExpression?: Maybe<FilterExpressionInput>,
  ids?: Maybe<Array<Scalars['String']>>
};


export type QueryCoursesByIdsArgs = {
  ids: Array<Scalars['String']>
};


export type QueryCoursesPublicArgs = {
  courseCategoryId?: Maybe<Scalars['ID']>,
  dayFilter?: Maybe<DayFilter>,
  filterExpression?: Maybe<FilterExpressionInput>,
  ids?: Maybe<Array<Scalars['String']>>,
  instructorId?: Maybe<Scalars['ID']>,
  showOnlyInWebActive?: Maybe<Scalars['Boolean']>
};


export type QueryCoursesWithPaginationArgs = {
  currentUserOnly?: Maybe<Scalars['Boolean']>,
  excludedCourseIds?: Maybe<Array<Scalars['String']>>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryCoursesWithPaginationPublicArgs = {
  filterExpression?: Maybe<FilterExpressionInput>,
  filters?: Maybe<CourseListFilters>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryCoursesWithPrerequisiteCoursesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryCourseTypeByIdArgs = {
  id: Scalars['String']
};


export type QueryCourseTypeStateByIdArgs = {
  id: Scalars['String']
};


export type QueryCustomerByIdArgs = {
  id: Scalars['String']
};


export type QueryEmailNotificationByIdArgs = {
  id: Scalars['String']
};


export type QueryEmailNotificationsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryExportedSepaXmlArgs = {
  id: Scalars['String']
};


export type QueryExportedSepaXmlsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryFaUserByEmailArgs = {
  email: Scalars['String']
};


export type QueryFaUserByUserIdArgs = {
  userId: Scalars['String']
};


export type QueryFindByCourseIdArgs = {
  id: Scalars['String']
};


export type QueryFindDuplicateBookersUnperformantArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryFindDuplicateBookersWithoutEmailArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryFindInstructorByIdArgs = {
  instructorId: Scalars['String']
};


export type QueryFindInstructorByIdsArgs = {
  instructorIds: Array<Scalars['ID']>
};


export type QueryFindInstructorsByLocationArgs = {
  locationId: Scalars['String']
};


export type QueryGetAdminAndInstructorUsersArgs = {
  koOrganizationId: Scalars['String']
};


export type QueryGetAnalyticsByDateArgs = {
  end?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['String']>
};


export type QueryGetAttendeeBadgeArgs = {
  id: Scalars['String']
};


export type QueryGetAttendeeBadgesByAttendeeIdArgs = {
  attendeeId: Scalars['String']
};


export type QueryGetAttendeeBadgesByBadgeIdArgs = {
  badgeId: Scalars['String']
};


export type QueryGetBankDataArgs = {
  iban: Scalars['String']
};


export type QueryGetBilledBookingsArgs = {
  pagination?: Maybe<PaginationInput>
};


export type QueryGetBookerByEmailArgs = {
  email: Scalars['String']
};


export type QueryGetBookerIdMissmatchesWithPaginationArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetBookersByBookingIdsArgs = {
  bookingIds: Array<Scalars['String']>
};


export type QueryGetBookersByCourseIdsArgs = {
  courseIds: Array<Scalars['String']>
};


export type QueryGetBookersByCourseWithBookingTypeArgs = {
  courseId: Scalars['String']
};


export type QueryGetBookingRestrictionByCourseIdArgs = {
  courseId: Scalars['String']
};


export type QueryGetBookingsAsCsvArgs = {
  bookingIds: Array<Scalars['String']>,
  filterFields: Array<Scalars['String']>
};


export type QueryGetBookingsByAttendeeIdArgs = {
  id: Scalars['ID']
};


export type QueryGetBookingsByAttendeeIdWithOptionsArgs = {
  canceledBookings?: Maybe<Scalars['Boolean']>,
  endDateTime?: Maybe<Scalars['DateTime']>,
  id: Scalars['ID'],
  startDateTime?: Maybe<Scalars['DateTime']>
};


export type QueryGetBookingsByCourseArgs = {
  id: Scalars['ID'],
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetBookingsByCourseIdsArgs = {
  ids: Array<Scalars['ID']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetBookingsByIdsArgs = {
  ids: Array<Scalars['String']>
};


export type QueryGetBookingsByInvoiceIdsArgs = {
  invoiceIds: Array<Scalars['String']>
};


export type QueryGetBookingsByScheduledPaymentIdsArgs = {
  scheduledPaymentIds: Array<Scalars['String']>
};


export type QueryGetCanceledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetColumnsForTableArgs = {
  table: TablesEnum
};


export type QueryGetContentByPathDoArgs = {
  options?: Maybe<ArrayDataOptions>,
  path: Scalars['String']
};


export type QueryGetContentOfFolderDoArgs = {
  folderName?: Maybe<Scalars['String']>
};


export type QueryGetCourseMinMaxAgeArgs = {
  courseId: Scalars['String']
};


export type QueryGetCoursesWithHighDemandArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetCourseVisibilityArgs = {
  courseId: Scalars['String']
};


export type QueryGetDuplicateBookersArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetEmailTemplateByIdArgs = {
  templateId: Scalars['String']
};


export type QueryGetFileDoArgs = {
  fileName: Scalars['String']
};


export type QueryGetInfoUserByUserIdArgs = {
  userId: Scalars['String']
};


export type QueryGetInstallmentPlanPreviewArgs = {
  newInstallmentPlanInput: NewInstallmentPlanInput
};


export type QueryGetInvoiceDummyPreviewArgs = {
  editTemplateInput?: Maybe<EditTemplateInput>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  paymentType?: Maybe<PaymentType>,
  reversal?: Maybe<Scalars['Boolean']>
};


export type QueryGetInvoicePreviewArgs = {
  invoiceId: Scalars['String']
};


export type QueryGetMailjetSenderByIdArgs = {
  mailjetSenderId: Scalars['Float']
};


export type QueryGetMailjetSendersArgs = {
  queryParameters?: Maybe<MailjetSenderQueryParams>
};


export type QueryGetMonthlyPaymentPreviewArgs = {
  monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput
};


export type QueryGetOnlineBookingsByTimeRangeCountArgs = {
  endDateTime?: Maybe<Scalars['DateTime']>,
  startDateTime?: Maybe<Scalars['DateTime']>
};


export type QueryGetPaymentMethodsByBookerIdArgs = {
  bookerId: Scalars['String']
};


export type QueryGetPaymentMethodTypeByIdArgs = {
  paymentMethodId: Scalars['String']
};


export type QueryGetReferenceCoursesLinkArgs = {
  courseId: Scalars['ID']
};


export type QueryGetSentMailjetMessageArgs = {
  messageId: Scalars['String']
};


export type QueryGetSentMailjetMessageHistoryArgs = {
  messageID: Scalars['String']
};


export type QueryGetSentMailjetMessagemessageInformationArgs = {
  messageID: Scalars['String']
};


export type QueryGetSentMailjetMessagesByMessageStatusArgs = {
  MessageStatus: Scalars['Float']
};


export type QueryGetSentMailjetMessagesWithParamsArgs = {
  queryParameters: MailjetEmailQueryParams
};


export type QueryGetSentSmsMessagesArgs = {
  journalParams: JournalParams
};


export type QueryGetSettledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetSignedUrlDoArgs = {
  fileName: Scalars['String']
};


export type QueryGetSmsPricingArgs = {
  country?: Maybe<Scalars['String']>
};


export type QueryGetSmsPricingByCountryCodeArgs = {
  countryCode: Scalars['String']
};


export type QueryGetSmsReceiversArgs = {
  mailDataType: MailDataType,
  selectedIds: Array<Scalars['String']>
};


export type QueryGetSmsReceiversCourseArgs = {
  getReceiversForCourseInput: GetReceiversForCourseInput
};


export type QueryGetTenantUsersArgs = {
  tenantId: Scalars['ID']
};


export type QueryGetUnBilledBookingsArgs = {
  pagination?: Maybe<PaginationInput>
};


export type QueryGetUnSettledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetUpcomingCoursesByTimeRangeArgs = {
  endDateTime: Scalars['DateTime'],
  options?: Maybe<ArrayDataOptions>,
  startDateTime: Scalars['DateTime']
};


export type QueryGetUserDetailsArgs = {
  userId: Scalars['String']
};


export type QueryGetWaitlistByCourseArgs = {
  id: Scalars['ID'],
  options?: Maybe<ArrayDataOptions>
};


export type QueryGetWaitlistCountOfCourseArgs = {
  id: Scalars['ID']
};


export type QueryHolidaysByCompanyAndDateRangeArgs = {
  end: Scalars['DateTime'],
  start: Scalars['DateTime']
};


export type QueryHolidaysByStateCodeArgs = {
  stateCode: StateCode
};


export type QueryInstallmentRateArgs = {
  id: Scalars['ID']
};


export type QueryInstallmentRatesByCourseIdArgs = {
  courseId: Scalars['ID']
};


export type QueryInstructorArgs = {
  id: Scalars['String']
};


export type QueryInstructorsByStaffIdsArgs = {
  staffIds: Array<Scalars['ID']>
};


export type QueryInstructorsWithPaginationArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryInvoiceArgs = {
  id: Scalars['String']
};


export type QueryInvoiceBodyByBookingIdArgs = {
  bookingId: Scalars['String']
};


export type QueryInvoiceBodyByIdArgs = {
  id: Scalars['String']
};


export type QueryInvoicesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryIsBadgeNameUniqueArgs = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String']
};


export type QueryIsCourseNameUniqueArgs = {
  courseId: Scalars['String'],
  customCourseNumberPrefix: Scalars['String']
};


export type QueryIsUniqueEmailArgs = {
  email: Scalars['String']
};


export type QueryIsUserEmailVerifiedArgs = {
  userId: Scalars['String']
};


export type QueryLocationByIdArgs = {
  id: Scalars['ID']
};


export type QueryLocationContactByIdArgs = {
  id: Scalars['String']
};


export type QueryLocationContactsByLocationArgs = {
  locationId: Scalars['ID']
};


export type QueryLocationsByCityArgs = {
  city: Scalars['String']
};


export type QueryLocationsByLocationContactIdArgs = {
  id: Scalars['ID']
};


export type QueryLocationsWithPaginationArgs = {
  currentUserOnly?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryMultipleCourseLessonsCollisionCheckArgs = {
  collitionCheckInput: Array<CollitionCheckInput>
};


export type QueryNotificationsByBookerIdArgs = {
  bookerId: Scalars['ID'],
  options?: Maybe<ArrayDataOptions>
};


export type QueryNotificationsByBookerOrEmailArgs = {
  bookerId: Scalars['ID'],
  email?: Maybe<Scalars['String']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryNotificationsByBookingArgs = {
  bookingId: Scalars['ID']
};


export type QueryPaidScheduledPaymentsArgs = {
  bookingId?: Maybe<Scalars['ID']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryParticipationPrerequisiteArgs = {
  id: Scalars['ID']
};


export type QueryParticipationPrerequisitesByCourseTypeIdArgs = {
  courseTypeId: Scalars['ID']
};


export type QueryPaymentMethodArgs = {
  id: Scalars['String']
};


export type QueryPrerequisiteCoursesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryReversalInvoiceBodyByIdArgs = {
  id: Scalars['String']
};


export type QueryReversalInvoicesArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryScheduledPaymentArgs = {
  id: Scalars['String']
};


export type QueryScheduledPaymentByIdArgs = {
  id: Scalars['ID']
};


export type QueryScheduledPaymentsArgs = {
  options?: Maybe<ArrayDataOptions>
};


export type QueryScheduledPaymentsByBookerIdArgs = {
  bookerId: Scalars['ID']
};


export type QueryScheduledPaymentsByBookingIdArgs = {
  bookingId: Scalars['ID']
};


export type QueryScheduledPaymentSummaryArgs = {
  options?: Maybe<ArrayDataOptions>,
  paymentDone: Scalars['Boolean']
};


export type QuerySearchAttendeesByFullNameArgs = {
  name: Scalars['String']
};


export type QuerySearchBilledPaymentsArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchBookerByStreetAndHouseNumberAndPpostCodeAndCityArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchBookersByFirstAndLastNameArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchBookersByStreetAndHouseNumberAndPostCodeAndCityArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchBookingByBookingNumberAndBookingNumberPrefixArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchBookingByCourseLocationPrefixAndCourseNumberArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchCourseByCourseLocationPrefixAndCourseNumberArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchCourseByInstructorFirstNameAndLastNameArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchCustomerByCustomerNumberPrefixAndCustomerNumberArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchCustomerByFirstNameAndLastNameArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchInvoicesByCourseNumberArgs = {
  courseNumber: Scalars['Float']
};


export type QuerySearchInvoicesByInvoiceNumberAndInvoiceNumberPrefixArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchLocationsByNameArgs = {
  name: Scalars['String']
};


export type QuerySearchLocationsByPhoneNumberAndEmailArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchStaffByFullnameAndAddressArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchUnBilledPaymentsArgs = {
  searchKey: Scalars['String']
};


export type QuerySearchUnSettledBookingArgs = {
  searchKey: Scalars['String']
};


export type QuerySentEmailStatusFromMailjetArgs = {
  messageID: Scalars['String']
};


export type QueryStressTestUserQueryArgs = {
  tenantId: Scalars['String']
};


export type QueryTemplateArgs = {
  id: Scalars['String']
};


export type QueryTemplateByTypeArgs = {
  templateType: TemplateType
};


export type QueryTemplatesWithPaginationArgs = {
  icludeStandardTemplates?: Maybe<Scalars['Boolean']>,
  includeInvoiceTemplates?: Maybe<Scalars['Boolean']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryTenantsWithPaginationArgs = {
  koOrganizationId?: Maybe<Scalars['String']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryTestFusioAuthFunctionsArgs = {
  userId: Scalars['String']
};


export type QueryUnpaidScheduledPaymentsArgs = {
  bookingId?: Maybe<Scalars['ID']>,
  options?: Maybe<ArrayDataOptions>
};


export type QueryUnpaidScheduledPaymentsZeroValuesArgs = {
  options?: Maybe<ArrayDataOptions>
};

export type Rate = {
  key: Scalars['String'],
  value: RateDetail,
};

export type RateDetail = {
  amount: Scalars['String'],
  due: Scalars['String'],
};

export enum RentType {
  Per45Minutes = 'Per45Minutes',
  Per60Minutes = 'Per60Minutes',
  PerCourse = 'PerCourse',
  PerCourseHour = 'PerCourseHour',
  PerDay = 'PerDay'
}

export type ResetPasswordStateType = {
  resetPasswordUrl: Scalars['String'],
};

export type ResetScheduledPaymentsOfBookingResult = {
  bookingId: Scalars['String'],
  bookingNumber: Scalars['String'],
  bookingPrice: Scalars['Float'],
  coursePrice: Scalars['Float'],
  creditedBalance?: Maybe<Scalars['Float']>,
  currentScheduledPaymentsAmountsAndDueDates: Array<Rate>,
  currentScheduledPaymentsCount: Scalars['Float'],
  currentScheduledPaymnetsCount: Scalars['Float'],
  installmentPlanAmountsAndDueDates: Array<Rate>,
  newScheduledPaymentsAmountsAndDueDates: Array<Rate>,
  newScheduledPaymentsCount: Scalars['Float'],
};

export type ResetScheduledPaymentsOfCourseResult = {
  bookings: Array<ResetScheduledPaymentsOfBookingResult>,
  courseDueDates: Array<Rate>,
  courseId: Scalars['String'],
  courseNumber: Scalars['String'],
  coursePrice: Scalars['Float'],
};

export enum RestrictiveCourses {
  All = 'All',
  MembersOnly = 'MembersOnly',
  PrerequisiteCourses = 'PrerequisiteCourses',
  StandardCourses = 'StandardCourses'
}

export type ReversalInvoice = {
  booking: Booking,
  bookingId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  invoiceNumber: Scalars['Float'],
  invoiceNumberPrefix: Scalars['String'],
  koOrganization: KoOrganization,
  qrReference?: Maybe<Scalars['String']>,
  reversalInvoiceBody: Scalars['String'],
  reversalInvoiceFileName: Scalars['String'],
  scheduledPayments?: Maybe<Array<ScheduledPayment>>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type ReversalInvoiceWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<ReversalInvoice>,
  total: Scalars['Float'],
};

export enum SalutationType {
  Mr = 'Mr',
  Ms = 'Ms',
  Mx = 'Mx'
}

export type SaveAdminInput = {
  accountHolder?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['String']>,
  birthPlace?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  firstname: Scalars['String'],
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber: Scalars['String'],
  iban?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  rate?: Maybe<Scalars['Float']>,
  roles?: Maybe<Array<Scalars['String']>>,
  salutation: SalutationType,
  socialSecurityNumber?: Maybe<Scalars['String']>,
  street: Scalars['String'],
  userId: Scalars['String'],
  vat?: Maybe<Scalars['Float']>,
};

export type SaveInstructorInput = {
  accountHolder?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  allowLogin?: Maybe<Scalars['Boolean']>,
  annotation?: Maybe<Scalars['String']>,
  assignedLocations: Array<AssignedLocationIdInput>,
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['String']>,
  birthPlace?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  customFields?: Maybe<Array<Scalars['JSONObject']>>,
  email: Scalars['String'],
  firstname: Scalars['String'],
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber: Scalars['String'],
  iban?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  level: Scalars['Float'],
  mobileNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  rate?: Maybe<Scalars['Float']>,
  salutation: SalutationType,
  showInWeb: Scalars['Boolean'],
  socialSecurityNumber?: Maybe<Scalars['String']>,
  street: Scalars['String'],
  taxId?: Maybe<Scalars['String']>,
  userId: Scalars['String'],
  vat?: Maybe<Scalars['Float']>,
};

export type ScheduledPayment = {
  booking: Booking,
  bookingId: Scalars['ID'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  invoice?: Maybe<Invoice>,
  invoiceId?: Maybe<Scalars['ID']>,
  openAmount?: Maybe<Scalars['Float']>,
  paymentAmount: Scalars['Float'],
  paymentDone?: Maybe<Scalars['DateTime']>,
  paymentDue: Scalars['DateTime'],
  paymentMethod?: Maybe<PaymentMethod>,
  paymentMethodId?: Maybe<Scalars['ID']>,
  paymentTransferType?: Maybe<PaymentTransferType>,
  sepaClearance: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  type: ScheduledPaymentType,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type ScheduledPaymentPreview = {
  grossPrice: Scalars['Float'],
  memberDiscount?: Maybe<Scalars['Float']>,
  netPrice: Scalars['Float'],
  scheduledPayments: Array<ScheduledPaymentSimple>,
};

export type ScheduledPaymentPreviewInput = {
  courseId: Scalars['ID'],
  editMode?: Maybe<Scalars['Boolean']>,
  monthlySchedule?: Maybe<MonthlySchedule>,
  specialPrice?: Maybe<Scalars['Float']>,
};

export type ScheduledPaymentSimple = {
  id: Scalars['ID'],
  paymentAmount: Scalars['Float'],
  paymentDue: Scalars['DateTime'],
  type: ScheduledPaymentType,
};

export type ScheduledPaymentSimpleInput = {
  id: Scalars['ID'],
  paymentAmount: Scalars['Float'],
  paymentDue: Scalars['DateTime'],
  type: ScheduledPaymentType,
};

export type ScheduledPaymentSummary = {
  filtered: ScheduledPaymentSummaryPart,
  total: ScheduledPaymentSummaryPart,
};

export type ScheduledPaymentSummaryPart = {
  count: Scalars['Float'],
  sum: Scalars['Float'],
};

export type ScheduledPaymentsWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<ScheduledPayment>,
  total: Scalars['Float'],
};

export enum ScheduledPaymentType {
  CancelationFee = 'CancelationFee',
  Chargeback = 'Chargeback',
  Custom = 'Custom',
  Discount = 'Discount',
  DunningLevel1 = 'DunningLevel1',
  DunningLevel2 = 'DunningLevel2',
  DunningLevel3 = 'DunningLevel3',
  Standard = 'Standard'
}

export type SecureIdentity = {
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>,
  connectorId?: Maybe<Scalars['String']>,
  encryptionScheme?: Maybe<Scalars['String']>,
  factor?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['ID']>,
  lastLoginInstant?: Maybe<Scalars['Float']>,
  password?: Maybe<Scalars['String']>,
  passwordChangeReason?: Maybe<Scalars['String']>,
  passwordChangeRequired?: Maybe<Scalars['Boolean']>,
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>,
  salt?: Maybe<Scalars['String']>,
  uniqueUsername?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  verified?: Maybe<Scalars['Boolean']>,
};

export type SelectedAttendee = {
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  firstname: Scalars['String'],
  id: Scalars['ID'],
};

export type SendInvoicesToSelectionOfBookingInput = {
  bookingIds: Array<Scalars['String']>,
  defaultTemplateType?: Maybe<TemplateType>,
  reversal?: Maybe<Scalars['Boolean']>,
};

export type SendMailToSelectionOfAttendeesInput = {
  attachments?: Maybe<Array<Attachment>>,
  attendeeIds: Array<Scalars['String']>,
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  templateId: Scalars['String'],
};

export type SendMailToSelectionOfBookersInput = {
  attachments?: Maybe<Array<Attachment>>,
  bookerIds: Array<Scalars['String']>,
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  templateId: Scalars['String'],
};

export type SendMailToSelectionOfBookingInput = {
  attachments?: Maybe<Array<Attachment>>,
  bookingIds: Array<Scalars['String']>,
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  templateId: Scalars['String'],
};

export type SendMailToSelectionOfCourseInput = {
  additionalReceivers?: Maybe<Array<Scalars['String']>>,
  attachments?: Maybe<Array<Attachment>>,
  courseId: Scalars['String'],
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  notifyBookings?: Maybe<Scalars['Boolean']>,
  notifyCancelations?: Maybe<Scalars['Boolean']>,
  notifyWaitlist?: Maybe<Scalars['Boolean']>,
  receiverIds?: Maybe<Array<Scalars['String']>>,
  templateId: Scalars['String'],
};

export type SendMailToSelectionOfScheduledPaymentsInput = {
  attachments?: Maybe<Array<Attachment>>,
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  scheduledPaymentIds: Array<Scalars['String']>,
  templateId: Scalars['String'],
};

export type SendMailToSelectionOfStaffInput = {
  attachments?: Maybe<Array<Attachment>>,
  defaultTemplateType?: Maybe<TemplateType>,
  editedTemplateInput?: Maybe<EditTemplateInput>,
  staffIds: Array<Scalars['String']>,
  templateId: Scalars['String'],
};

export type SendParamsMessageInput = {
  Attachments?: Maybe<Array<Attachment>>,
  Bcc?: Maybe<Array<SendParamsRecipient>>,
  Cc?: Maybe<Array<SendParamsRecipient>>,
  CustomCampaign?: Maybe<Scalars['String']>,
  CustomID?: Maybe<Scalars['String']>,
  DeduplicateCampaign?: Maybe<Scalars['Boolean']>,
  EventPayload?: Maybe<Scalars['String']>,
  From: SendParamsRecipient,
  HTMLPart?: Maybe<Scalars['String']>,
  InlinedAttachments?: Maybe<Array<InlinedAttachment>>,
  MonitoringCategory?: Maybe<Scalars['String']>,
  ReplyTo?: Maybe<SendParamsRecipient>,
  Sender?: Maybe<SendParamsRecipient>,
  Subject: Scalars['String'],
  TemplateID?: Maybe<Scalars['Float']>,
  TemplateLanguage?: Maybe<Scalars['Boolean']>,
  TextPart?: Maybe<Scalars['String']>,
  To: Array<SendParamsRecipient>,
  URLTags?: Maybe<Scalars['String']>,
  Variables?: Maybe<MailjetVariables>,
};

export type SendParamsRecipient = {
  Email: Scalars['String'],
  Name?: Maybe<Scalars['String']>,
};

export type SendSmsInput = {
  from?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  to: Scalars['String'],
};

export type SendTemplateInput = {
  emailData: EmailData,
  mailjetTemplateId: MailjetEmailTemplates,
  messages: Array<SendParamsMessageInput>,
  templateName: Scalars['String'],
  templateType: TemplateType,
};

export type SepaDebitIntput = {
  bank: Scalars['String'],
  bic: Scalars['String'],
  iban: Scalars['String'],
  sepaAccountholder: Scalars['String'],
};

export type SepaXmlPaymentInput = {
  amount: Scalars['Float'],
  currency: Scalars['String'],
  debtorBIC: Scalars['String'],
  debtorIBAN: Scalars['String'],
  debtorName: Scalars['String'],
  end2endId: Scalars['String'],
  mandateId: Scalars['String'],
  mandateSignatureDate: Scalars['DateTime'],
  remittanceInfo: Scalars['String'],
};

export type SepaXmlPositionInput = {
  batchBooking: Scalars['Boolean'],
  collectionDate: Scalars['DateTime'],
  creditorBIC: Scalars['String'],
  creditorIBAN: Scalars['String'],
  creditorId: Scalars['String'],
  creditorName: Scalars['String'],
  payments: Array<SepaXmlPaymentInput>,
};

export type Setting = {
  bankTransfer: Scalars['Boolean'],
  cash: Scalars['Boolean'],
  courseNumberFormat: CourseNumberFormat,
  createDate: Scalars['DateTime'],
  createdByUserId: Scalars['String'],
  currency: Currency,
  customerNumberPrefix: Scalars['String'],
  defaultVat: Scalars['Float'],
  esr: Scalars['Boolean'],
  firstInstallmentDayOfMonth: Scalars['Float'],
  firstInstallmentMonth: Scalars['Float'],
  grossNet: GrossNet,
  holidayStateCode?: Maybe<StateCode>,
  holidayStateColor: Scalars['String'],
  id: Scalars['ID'],
  invoiceDueDays: Scalars['Float'],
  invoiceNumberPrefix: Scalars['String'],
  sendReversalInvoice: Scalars['Boolean'],
  sepaDebit: Scalars['Boolean'],
  sevenIoApiKey?: Maybe<Scalars['String']>,
  smsSenderName?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId: Scalars['String'],
  vatAppearance: Scalars['String'],
};

export type SettingEndUserApp = {
  colors?: Maybe<Scalars['JSONObject']>,
  courseDetails?: Maybe<Scalars['JSONObject']>,
  courseTable?: Maybe<Scalars['JSONObject']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  filters?: Maybe<Scalars['JSONObject']>,
  hideAfterDays: Scalars['Float'],
  id: Scalars['ID'],
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>,
  passwordlessIdCodeLength: Scalars['Float'],
  passwordlessLoginEnabled: Scalars['Boolean'],
  textEntries?: Maybe<Scalars['JSONObject']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type SettingEndUserAppInput = {
  colors: ColorsInput,
  courseDetails: CourseDetailsInput,
  courseTable: CourseTableInput,
  filters: FiltersInput,
  hideAfterDays?: Maybe<Scalars['Float']>,
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>,
  textEntries: TextEntriesInput,
};

export type SettingInput = {
  bankTransfer: Scalars['Boolean'],
  cash: Scalars['Boolean'],
  courseNumberFormat: CourseNumberFormat,
  currency: Currency,
  customerNumberPrefix?: Maybe<Scalars['String']>,
  defaultVat: Scalars['Float'],
  esr: Scalars['Boolean'],
  firstInstallmentDayOfMonth: Scalars['Float'],
  firstInstallmentMonth: Scalars['Float'],
  grossNet: GrossNet,
  holidayStateCode?: Maybe<StateCode>,
  holidayStateColor?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  invoiceDueDays: Scalars['Float'],
  invoiceNumberPrefix?: Maybe<Scalars['String']>,
  sendReversalInvoice?: Maybe<Scalars['Boolean']>,
  sepaDebit: Scalars['Boolean'],
  sevenIoApiKey?: Maybe<Scalars['String']>,
  smsSenderName?: Maybe<Scalars['String']>,
  vatAppearance: VatAppearance,
};

export type Sms77AnalyticBase = {
  hlr: Scalars['Float'],
  inbound: Scalars['Float'],
  mnp: Scalars['Float'],
  sms: Scalars['Float'],
  usageEur: Scalars['Float'],
  voice: Scalars['Float'],
};

export type Sms77AnalyticGroupByDate = {
  date: Scalars['String'],
  hlr: Scalars['Float'],
  inbound: Scalars['Float'],
  mnp: Scalars['Float'],
  sms: Scalars['Float'],
  usageEur: Scalars['Float'],
  voice: Scalars['Float'],
};

export type Sms77CountryPricing = {
  countryCode: Scalars['String'],
  countryName: Scalars['String'],
  countryPrefix: Scalars['String'],
  networks: Array<CountryNetwork>,
};

export type Sms77FormatApiResponse = {
  carrier: Scalars['String'],
  countryCode: Scalars['String'],
  countryIso: Scalars['String'],
  countryName: Scalars['String'],
  international: Scalars['String'],
  internationalFormatted: Scalars['String'],
  national: Scalars['String'],
  networkType: Scalars['String'],
  success: Scalars['Boolean'],
};

export type Sms77JournalBase = {
  from: Scalars['String'],
  id: Scalars['String'],
  price: Scalars['String'],
  text: Scalars['String'],
  timestamp: Scalars['String'],
  to: Scalars['String'],
};

export type Sms77JournalOutbound = {
  channel: Scalars['String'],
  dlr: Scalars['String'],
  dlrTimestamp: Scalars['String'],
  foreignId?: Maybe<Scalars['String']>,
  from: Scalars['String'],
  id: Scalars['String'],
  label?: Maybe<Scalars['String']>,
  latency: Scalars['String'],
  mccmnc: Scalars['String'],
  price: Scalars['String'],
  text: Scalars['String'],
  timestamp: Scalars['String'],
  to: Scalars['String'],
  type: Scalars['String'],
};

export type Sms77JsonResponse = {
  balance: Scalars['String'],
  debug: Scalars['String'],
  description: Scalars['String'],
  messages: Array<Sms77Message>,
  smsType: Scalars['String'],
  success: Scalars['String'],
  totalPrice: Scalars['Float'],
};

export type Sms77Message = {
  encoding: Scalars['String'],
  error?: Maybe<Scalars['String']>,
  errorText?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  isBinary: Scalars['Boolean'],
  label?: Maybe<Scalars['String']>,
  parts: Scalars['Float'],
  price: Scalars['Float'],
  recipient: Scalars['String'],
  sender: Scalars['String'],
  success: Scalars['Boolean'],
  text: Scalars['String'],
  udh?: Maybe<Scalars['String']>,
};

export type Sms77PricingResponse = {
  countCountries: Scalars['Float'],
  countNetworks: Scalars['Float'],
  countries: Array<Sms77CountryPricing>,
};

export type SmsBalanceResponse = {
  balance: Scalars['Float'],
};

export type SmsReceiver = {
  bookerId: Scalars['String'],
  fullName?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  prefixedBookingNumber?: Maybe<Scalars['String']>,
  valid?: Maybe<Scalars['String']>,
};

export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type Staff = {
  accountHolder?: Maybe<Scalars['String']>,
  annotation?: Maybe<Scalars['String']>,
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['String']>,
  birthPlace?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  firstname: Scalars['String'],
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber: Scalars['String'],
  iban?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  joinDate?: Maybe<Scalars['String']>,
  lastname: Scalars['String'],
  leaveDate?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  rate?: Maybe<Scalars['Float']>,
  salutation?: Maybe<SalutationType>,
  socialSecurityNumber?: Maybe<Scalars['String']>,
  street: Scalars['String'],
  taxId?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  userId: Scalars['ID'],
  vat?: Maybe<Scalars['Float']>,
};

export type StaffLogin = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  pages: Array<Scalars['JSONObject']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export enum StateCode {
  Custom = 'CUSTOM',
  DeBb = 'DeBb',
  DeBe = 'DeBe',
  DeBw = 'DeBw',
  DeBy = 'DeBy',
  DeHb = 'DeHb',
  DeHe = 'DeHe',
  DeHh = 'DeHh',
  DeMv = 'DeMv',
  DeNi = 'DeNi',
  DeNw = 'DeNw',
  DeRp = 'DeRp',
  DeSh = 'DeSh',
  DeSl = 'DeSl',
  DeSn = 'DeSn',
  DeSt = 'DeSt',
  DeTh = 'DeTh',
  National = 'NATIONAL'
}

export type Subscription = {
  createDate: Scalars['DateTime'],
  featureExport: Scalars['Boolean'],
  featurePrerequisiteCourses: Scalars['Boolean'],
  featureRefactoredCourseDetails: Scalars['Boolean'],
  featureSms: Scalars['Boolean'],
  featureStaffLogin: Scalars['Boolean'],
  featureWeekPlaner: Scalars['Boolean'],
  holidays: Scalars['Boolean'],
  id: Scalars['ID'],
  seatsAdmin: Scalars['Float'],
  seatsInstructor: Scalars['Float'],
  updateDate: Scalars['DateTime'],
};

export type SubscriptionFeaturesInput = {
  featureExport?: Maybe<Scalars['Boolean']>,
  featurePrerequisiteCourses?: Maybe<Scalars['Boolean']>,
  featureRefactoredCourseDetails?: Maybe<Scalars['Boolean']>,
  featureSms?: Maybe<Scalars['Boolean']>,
  featureStaffLogin?: Maybe<Scalars['Boolean']>,
  featureWeekPlaner?: Maybe<Scalars['Boolean']>,
  holidays?: Maybe<Scalars['Boolean']>,
  seatsAdmin?: Maybe<Scalars['Float']>,
  seatsInstructor?: Maybe<Scalars['Float']>,
};

export enum SubscriptionType {
  StandardSubscription = 'StandardSubscription',
  TestSubscription = 'TestSubscription'
}

export type SwissQrFile = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  fileContent: Scalars['String'],
  fileId: Scalars['String'],
  id: Scalars['ID'],
  records?: Maybe<Array<SwissQrFileRecord>>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type SwissQrFileRecord = {
  amount: Scalars['Float'],
  comment: Scalars['String'],
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  payment: Payment,
  result: Scalars['String'],
  scheduledPayment: ScheduledPayment,
  successful: Scalars['Boolean'],
  swissQRFile?: Maybe<SwissQrFile>,
  swissQRFileId?: Maybe<Scalars['ID']>,
  swissQrRef: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  valutaDate: Scalars['DateTime'],
};

export type TableColumnsInput = {
  enabled?: Maybe<Scalars['Boolean']>,
  label?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  sortIndex?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['String']>,
};

export enum TablesEnum {
  AttendanceList = 'AttendanceList',
  Attendees = 'Attendees',
  AttendeesOverview = 'AttendeesOverview',
  AttendeeTransferToBooker = 'AttendeeTransferToBooker',
  BookerExportModal = 'BookerExportModal',
  BookerOverview = 'BookerOverview',
  Bookers = 'Bookers',
  Bookings = 'Bookings',
  BookingsAttendeeDetails = 'BookingsAttendeeDetails',
  BookingsByCourse = 'BookingsByCourse',
  BookingsByCourseExpandedBookings = 'BookingsByCourseExpandedBookings',
  BookingsInStartedCourses = 'BookingsInStartedCourses',
  CourseCategories = 'CourseCategories',
  CourseDetailsCanceledBookingsList = 'CourseDetailsCanceledBookingsList',
  CourseDetailsCanceledBookingsList2 = 'CourseDetailsCanceledBookingsList2',
  CourseDetailsPlaceReservations = 'CourseDetailsPlaceReservations',
  CourseDetailsPlacereservationsList2 = 'CourseDetailsPlacereservationsList2',
  CourseDetailsSettledBookings = 'CourseDetailsSettledBookings',
  CourseDetailsSettledBookingsList2 = 'CourseDetailsSettledBookingsList2',
  CourseDetailsSettledPlacereservations = 'CourseDetailsSettledPlacereservations',
  CourseDetailsUnsettledBookings = 'CourseDetailsUnsettledBookings',
  CourseDetailsUnsettledBookingsList2 = 'CourseDetailsUnsettledBookingsList2',
  CourseDetailsWaitlist = 'CourseDetailsWaitlist',
  CourseDetailsWaitlistList2 = 'CourseDetailsWaitlistList2',
  CourseOverview = 'CourseOverview',
  Courses = 'Courses',
  CourseSelectPrerequisiteCourses = 'CourseSelectPrerequisiteCourses',
  CourseTransferAttendeeToCourse = 'CourseTransferAttendeeToCourse',
  CourseTypes = 'CourseTypes',
  DonePaymentsList = 'DonePaymentsList',
  EmailTemplatePicker = 'EmailTemplatePicker',
  EmailTemplatesList = 'EmailTemplatesList',
  Instructors = 'Instructors',
  InvoiceListByBookingUnsetteled = 'InvoiceListByBookingUnsetteled',
  InvoiceListByCourse = 'InvoiceListByCourse',
  InvoicesByCourse = 'InvoicesByCourse',
  InvoicesReversals = 'InvoicesReversals',
  InvoicesSettled = 'InvoicesSettled',
  InvoicesUnsetteled = 'InvoicesUnsetteled',
  LocationOverviewTable = 'LocationOverviewTable',
  Locations = 'Locations',
  MyBookings = 'MyBookings',
  MyBookingsByCourse = 'MyBookingsByCourse',
  MyBookingsOverview = 'MyBookingsOverview',
  NotificationBookerMissmatch = 'NotificationBookerMissmatch',
  NotificationsByBookerId = 'NotificationsByBookerId',
  NotificationsSent = 'NotificationsSent',
  OpenPaymentsList = 'OpenPaymentsList',
  PaymentsOpen = 'PaymentsOpen',
  PaymentsPaid = 'PaymentsPaid',
  PrerequisiteCourses = 'PrerequisiteCourses',
  SelectAttendee = 'SelectAttendee',
  SettledBookingDonePaymentsList = 'SettledBookingDonePaymentsList',
  SettledBookingOpenPaymentsList = 'SettledBookingOpenPaymentsList',
  Staff = 'Staff',
  StaffAll = 'StaffAll',
  StaffAllInstructor = 'StaffAllInstructor',
  StepSelectCourse = 'StepSelectCourse',
  TodaysCourseLessonsList = 'TodaysCourseLessonsList',
  TransferCourseSelector = 'TransferCourseSelector',
  TransferMultipleAttendeesSelectCourse = 'TransferMultipleAttendeesSelectCourse',
  TransferSimpleCourseSelector = 'TransferSimpleCourseSelector',
  ZeroValuePayments = 'ZeroValuePayments'
}

export type Template = {
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  footerText?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  includeCourseDetails: Scalars['Boolean'],
  isDefault: Scalars['Boolean'],
  mailDataType?: Maybe<MailDataType>,
  name?: Maybe<Scalars['String']>,
  signature?: Maybe<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
  templateType: Scalars['String'],
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type TemplatesWithPagination = {
  existsMore: Scalars['Boolean'],
  items: Array<Template>,
  total: Scalars['Float'],
};

export enum TemplateType {
  AttendanceList = 'AttendanceList',
  BookingCancellation = 'BookingCancellation',
  BookingConfirmation = 'BookingConfirmation',
  FreeText = 'FreeText',
  Invoice = 'Invoice',
  InvoiceMonthly = 'InvoiceMonthly',
  InvoiceMonthlyConsistent = 'InvoiceMonthlyConsistent',
  InvoiceReversal = 'InvoiceReversal',
  InvoiceReversalMonthly = 'InvoiceReversalMonthly',
  InvoiceReversalMonthlyConsistent = 'InvoiceReversalMonthlyConsistent',
  NoTemplate = 'NoTemplate',
  Reminder = 'Reminder',
  SettledBookingCancellation = 'SettledBookingCancellation',
  SettlementConfirmation = 'SettlementConfirmation',
  WaitingListConfirmation = 'WaitingListConfirmation',
  Warning = 'Warning'
}

export type TextEntriesInput = {
  header?: Maybe<Scalars['String']>,
};

export type ThirdPartyLoginInput = {
  password: Scalars['String'],
  username: Scalars['String'],
};

export type ThirdPartyUserInput = {
  email: Scalars['String'],
  password: Scalars['String'],
  username: Scalars['String'],
};

export type TransferBookingInput = {
  bookingId: Scalars['ID'],
  isSettled: Scalars['Boolean'],
  originBookingType: BookingType,
  targetBookingType: BookingType,
  usePlaceReservation: Scalars['Boolean'],
};

export type TransferMultipleBookingsInput = {
  bookings: Array<TransferBookingInput>,
  copyBookings: Scalars['Boolean'],
  notifyBooker: Scalars['Boolean'],
  originCourseId: Scalars['ID'],
  targetCourseId: Scalars['ID'],
};

export type UpdateAttendanceListSettingsInput = {
  fontSize: AttendanceListFontSize,
  showCourseLessons: Scalars['Boolean'],
};

export type UpdateBadgeInput = {
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
};

export type UpdateBookerAccountEndUserAppInput = {
  birthday?: Maybe<Scalars['DateTime']>,
  city?: Maybe<Scalars['String']>,
  customer?: Maybe<BaseInput>,
  customerNumber?: Maybe<Scalars['Float']>,
  dsgvo?: Maybe<Scalars['Boolean']>,
  firstname?: Maybe<Scalars['String']>,
  gender?: Maybe<Gender>,
  healthInsuranceFund?: Maybe<Scalars['String']>,
  houseNumber?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  lastname?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  newsletter?: Maybe<Scalars['Boolean']>,
  paymentMethods?: Maybe<Array<BookerPaymentMethodInput>>,
  phoneNumber?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
};

export type UpdateBookerIdMissmatchesInput = {
  newBookerId: Scalars['ID'],
  notificationId: Scalars['ID'],
  oldBookerId?: Maybe<Scalars['ID']>,
};

export type UpdateBookingRestrictionInput = {
  active?: Maybe<Scalars['Boolean']>,
  blockedPlaces?: Maybe<Scalars['Float']>,
  courseId: Scalars['ID'],
  endDateTime?: Maybe<Scalars['DateTime']>,
  expireAction?: Maybe<ExpireAction>,
  id: Scalars['ID'],
  includeBookingList?: Maybe<Scalars['Boolean']>,
  includeWaitingList?: Maybe<Scalars['Boolean']>,
  membersOnly?: Maybe<Scalars['Boolean']>,
  prerequisiteCourseIds?: Maybe<Array<Scalars['ID']>>,
  startDateTime?: Maybe<Scalars['DateTime']>,
  visibleForAll?: Maybe<Scalars['Boolean']>,
};

export type UpdateCourseLessonBookingInput = {
  attendance?: Maybe<Scalars['Boolean']>,
  id: Scalars['String'],
  note?: Maybe<Scalars['String']>,
};

export type UpdateInstallmentRateInput = {
  courseId?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  installmentAmount?: Maybe<Scalars['Float']>,
  installmentDue?: Maybe<Scalars['DateTime']>,
  installmentType?: Maybe<InstallmentType>,
};

export type UpdateParticipationPrerequisitesInput = {
  courseTypeId?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>,
  groupDescription?: Maybe<Scalars['String']>,
  groupLabel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  minCheckCount?: Maybe<Scalars['Float']>,
  optionalInputFields?: Maybe<Array<OptionalInputFieldsInput>>,
};

export type UpdatePaymentMethodInput = {
  bank?: Maybe<Scalars['String']>,
  bic?: Maybe<Scalars['String']>,
  iban?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  sepaAccountholder?: Maybe<Scalars['String']>,
};

export type UpdateScheduledPaymentByBookingInput = {
  bookingId?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  invoiceId?: Maybe<Scalars['String']>,
  openAmount?: Maybe<Scalars['Float']>,
  paymentAmount?: Maybe<Scalars['Float']>,
  paymentDone?: Maybe<Scalars['DateTime']>,
  paymentDue?: Maybe<Scalars['DateTime']>,
  paymentTransferType?: Maybe<PaymentTransferType>,
  type?: Maybe<ScheduledPaymentType>,
};

export type UpdateSettingEndUserAppInput = {
  colors?: Maybe<ColorsInput>,
  courseDetails?: Maybe<CourseDetailsInput>,
  courseTable?: Maybe<CourseTableInput>,
  filters?: Maybe<FiltersInput>,
  hideAfterDays?: Maybe<Scalars['Float']>,
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>,
  passwordlessIdCodeLength?: Maybe<Scalars['Float']>,
  passwordlessLoginEnabled?: Maybe<Scalars['Boolean']>,
  textEntries?: Maybe<TextEntriesInput>,
};

export type UpdateTableColumnSettingsInput = {
  columns?: Maybe<Array<Scalars['String']>>,
  table: TablesEnum,
};


export type UploadFileInput = {
  cacheControl?: Maybe<Scalars['String']>,
  contentDisposition?: Maybe<Scalars['String']>,
  contentEncoding?: Maybe<Scalars['String']>,
  contentLength: Scalars['Float'],
  contentType: Scalars['String'],
  fileContent: Scalars['Upload'],
  fileName: Scalars['String'],
  metadata?: Maybe<Scalars['String']>,
};

export type UploadMetadataInput = {
  cacheControl?: Maybe<Scalars['String']>,
  contentDisposition?: Maybe<Scalars['String']>,
  contentEncoding?: Maybe<Scalars['String']>,
  contentLength?: Maybe<Scalars['Float']>,
  contentType?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
};

export type UserDetailsType = {
  email: Scalars['String'],
  id: Scalars['String'],
  insertInstant: Scalars['String'],
  instructorDetails?: Maybe<InstructorDetailsView>,
  lastLoginInstant?: Maybe<Scalars['String']>,
  loginAttempts?: Maybe<Array<LoginAttempt>>,
  roles: Array<Scalars['String']>,
  userSettings?: Maybe<UserSetting>,
  verified: Scalars['Boolean'],
};

export type UserInput = {
  email: Scalars['String'],
  id: Scalars['String'],
};

export type UserRegistration = {
  applicationId?: Maybe<Scalars['ID']>,
  authenticationToken?: Maybe<Scalars['String']>,
  cleanSpeakId?: Maybe<Scalars['ID']>,
  data?: Maybe<Scalars['JSONObject']>,
  id?: Maybe<Scalars['String']>,
  insertInstant?: Maybe<Scalars['Float']>,
  lastLoginInstant?: Maybe<Scalars['Float']>,
  lastUpdateInstant?: Maybe<Scalars['Float']>,
  preferredLanguages?: Maybe<Array<Scalars['String']>>,
  roles?: Maybe<Array<Scalars['String']>>,
  timezone?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  verified?: Maybe<Scalars['Boolean']>,
};

export type UserSetting = {
  attendanceListSettings: Scalars['JSONObject'],
  columnSettings?: Maybe<Scalars['JSONObject']>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  language?: Maybe<Language>,
  optimizedCourseCalendar: Scalars['Boolean'],
  profilePictureUrl?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
  userId: Scalars['String'],
};

export type UserSettingsInputTypes = {
  columnSettings?: Maybe<Scalars['JSONObject']>,
  language?: Maybe<Language>,
};

export type UserType = {
  accessToken?: Maybe<Scalars['String']>,
  booker?: Maybe<Booker>,
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  hasProfile: Scalars['Boolean'],
  id: Scalars['String'],
  lastName?: Maybe<Scalars['String']>,
  refreshToken?: Maybe<Scalars['String']>,
  roles?: Maybe<Array<Scalars['String']>>,
};

export enum VatAppearance {
  Hide = 'Hide',
  Mention = 'Mention',
  Show = 'Show'
}

export type Venue = {
  courses?: Maybe<Array<Course>>,
  createDate: Scalars['DateTime'],
  createdByUserId?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isActive?: Maybe<Scalars['Boolean']>,
  lessons?: Maybe<Array<CourseLesson>>,
  location?: Maybe<Location>,
  name: Scalars['String'],
  rent?: Maybe<Scalars['Float']>,
  rentType?: Maybe<Scalars['String']>,
  updateDate: Scalars['DateTime'],
  updatedByUserId?: Maybe<Scalars['String']>,
};

export type WithEnabledCourse = {
  course: Course,
  enabledCourses: Array<EnabledCourse>,
};

export type CreateAttendeeBadgeMutationVariables = {
  newAttendeeBadgeInput: NewAttendeeBadgeInput
};


export type CreateAttendeeBadgeMutation = { createAttendeeBadge: Pick<AttendeeBadge, 'id'> };

export type UpdateAttendeeBadgeMutationVariables = {
  editAttendeeBadgeInput: EditAttendeeBadgeInput
};


export type UpdateAttendeeBadgeMutation = { updateAttendeeBadge: Pick<AttendeeBadge, 'id'> };

export type DeleteAttendeeBadgeMutationVariables = {
  id: Scalars['String']
};


export type DeleteAttendeeBadgeMutation = Pick<Mutation, 'deleteAttendeeBadge'>;

export type GetAttendeeBadgeQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeBadgeQuery = { getAttendeeBadge: (
    Pick<AttendeeBadge, 'id' | 'comment' | 'dateOfAchivement' | 'courseId'>
    & { badge: Pick<Badge, 'id' | 'name'>, attendee: Pick<Attendee, 'id'> }
  ) };

export type GetAttendeeBadgesByAttendeeIdQueryVariables = {
  attendeeId: Scalars['String']
};


export type GetAttendeeBadgesByAttendeeIdQuery = { getAttendeeBadgesByAttendeeId: Array<(
    Pick<AttendeeBadge, 'id' | 'comment' | 'dateOfAchivement' | 'courseId'>
    & { badge: Pick<Badge, 'id' | 'name'>, attendee: Pick<Attendee, 'id'> }
  )> };

export type GetBadgeQueryVariables = {
  id: Scalars['String']
};


export type GetBadgeQuery = { badge: Pick<Badge, 'id' | 'name' | 'description' | 'createDate' | 'updateDate' | 'createdByUserId' | 'updatedByUserId' | 'rank'> };

export type GetBadgesQueryVariables = {};


export type GetBadgesQuery = { badges: Array<Pick<Badge, 'id' | 'name' | 'description' | 'createDate' | 'updateDate' | 'createdByUserId' | 'updatedByUserId' | 'rank'>> };

export type CreateBadgeMutationVariables = {
  newBadgeInput: NewBadgeInput
};


export type CreateBadgeMutation = { createBadge: Pick<Badge, 'id' | 'name' | 'description' | 'createDate' | 'updateDate' | 'createdByUserId' | 'updatedByUserId'> };

export type UpdateBadgeMutationVariables = {
  updateBadgeInput: UpdateBadgeInput
};


export type UpdateBadgeMutation = { updateBadge: Pick<Badge, 'id' | 'name' | 'description' | 'createDate' | 'updateDate' | 'createdByUserId' | 'updatedByUserId'> };

export type DeleteBadgeMutationVariables = {
  id: Scalars['String']
};


export type DeleteBadgeMutation = Pick<Mutation, 'deleteBadge'>;

export type SoftDeleteBadgeMutationVariables = {
  id: Scalars['String']
};


export type SoftDeleteBadgeMutation = { softDeleteBadge: Pick<Badge, 'id'> };

export type IsBadgeNameUniqueQueryVariables = {
  name: Scalars['String'],
  id?: Maybe<Scalars['String']>
};


export type IsBadgeNameUniqueQuery = Pick<Query, 'isBadgeNameUnique'>;

export type ReOrderBadgesMutationVariables = {
  movedBadgeId: Scalars['ID'],
  targetBadgeId: Scalars['ID']
};


export type ReOrderBadgesMutation = Pick<Mutation, 'reOrderBadges'>;

export type GetBookinsQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetBookinsQuery = { bookings: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'startDateTime' | 'courseId' | 'noticeStaff' | 'attendanceListNote' | 'noticeBooker' | 'coursePriceId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount' | 'additionalInfo'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
        & { course: (
          Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment' | 'lessonCount'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
      )>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> }
    )> }
  ) };

export type BookingCoreFieldsFragment = Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'startDateTime' | 'courseId' | 'noticeStaff' | 'attendanceListNote' | 'noticeBooker' | 'coursePriceId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount' | 'additionalInfo'>;

export type BookingCoursePriceFieldsFragment = { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'> };

export type BookingInvoiceFieldsFragment = { invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>> };

export type BookingReversalInvoiceFieldsFragment = { reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>> };

export type BookingCourseLessonsFieldsFragment = { courseLessons: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
    & { course: (
      Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment' | 'lessonCount'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
    ), location: Pick<Location, 'id' | 'city' | 'name'> }
  )> };

export type BookingAttendeeFieldsFragment = { attendee: Maybe<(
    Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
    & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
  )> };

export type BookingEmailNotificationsFieldsFragment = { emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>> };

export type BookingPaymentMethodFieldsFragment = { paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> };

export type GetBookingsDynamicQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  includeCoursePrice: Scalars['Boolean'],
  includeInvoice: Scalars['Boolean'],
  includeReversalInvoice: Scalars['Boolean'],
  includeCourseLessons: Scalars['Boolean'],
  includeAttendee: Scalars['Boolean'],
  includeEmailNotifications: Scalars['Boolean'],
  includePaymentMethod: Scalars['Boolean']
};


export type GetBookingsDynamicQuery = { bookingsDynamic: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      BookingCoreFieldsFragment
      & BookingCoursePriceFieldsFragment
      & BookingInvoiceFieldsFragment
      & BookingReversalInvoiceFieldsFragment
      & BookingCourseLessonsFieldsFragment
      & BookingAttendeeFieldsFragment
      & BookingEmailNotificationsFieldsFragment
      & BookingPaymentMethodFieldsFragment
    )> }
  ) };

export type CreateBookingMutationVariables = {
  newBookingData: NewBookingInput,
  sendMail: Scalars['Boolean']
};


export type CreateBookingMutation = { createBooking: Pick<Booking, 'id' | 'bookingType'> };

export type RemoveBookingMutationVariables = {
  id: Scalars['ID'],
  bookingDeleteAction?: Maybe<BookingDeleteAction>,
  notifyBooker?: Maybe<Scalars['Boolean']>
};


export type RemoveBookingMutation = Pick<Mutation, 'removeBooking'>;

export type RemoveBookingsMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type RemoveBookingsMutation = Pick<Mutation, 'removeBookings'>;

export type UpdateBookingMutationVariables = {
  editBookingData: EditBookingInput,
  id: Scalars['String']
};


export type UpdateBookingMutation = { updateBooking: Pick<Booking, 'id' | 'bookingType'> };

export type UpdateNoticeStaffMutationVariables = {
  id: Scalars['String'],
  noticeStaff: Scalars['String']
};


export type UpdateNoticeStaffMutation = { updateNoticeStaff: Pick<Booking, 'id' | 'noticeStaff'> };

export type GetMonthlyPaymentPreviewQueryVariables = {
  monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput
};


export type GetMonthlyPaymentPreviewQuery = Pick<Query, 'getMonthlyPaymentPreview'>;

export type GetBookingByIdQueryVariables = {
  id: Scalars['ID']
};


export type GetBookingByIdQuery = { bookingById: (
    Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'noticeStaff' | 'noticeBooker' | 'coursePriceId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'memberDiscount' | 'isSpecial' | 'startDateTime' | 'courseId' | 'paymentType' | 'paymentMethodId' | 'bookedBy' | 'canceled' | 'monthlySchedule' | 'creditedBalance' | 'waitListCount' | 'additionalInfo'>
    & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate' | 'correctionDate' | 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
      Pick<CourseLesson, 'startDateTime' | 'endDateTime' | 'id' | 'locationId'>
      & { course: (
        Pick<Course, 'id' | 'freePlaces' | 'bookingCount' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment' | 'allowUpFrontPayment' | 'monthlySchedule' | 'paymentType'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, installmentRates: Array<Pick<InstallmentRate, 'id' | 'installmentAmount' | 'installmentDue'>>, lessons: Array<Pick<CourseLesson, 'id' | 'startDateTime'>> }
      ), location: Pick<Location, 'id' | 'city' | 'name'> }
    )>, attendee: Maybe<(
      Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber' | 'member'>
      & { booker: Maybe<(
        Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'gender' | 'nonDisclosureNotice' | 'credit'>
        & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>> }
      )>, defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'> }
    )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditcardNumber' | 'creditCardAccountholder' | 'expirationDate' | 'sepaAccountholder' | 'iban' | 'bic' | 'bank' | 'paypalAccountholder' | 'email' | 'bankTransfer'>>, scheduledPayments: Maybe<Array<Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'paymentDue' | 'paymentDone' | 'type'>>> }
  ) };

export type GetBookingsByCourseQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetBookingsByCourseQuery = { getBookingsByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'noticeStaff' | 'noticeBooker' | 'attendanceListNote' | 'coursePriceId' | 'courseId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
        & { course: (
          Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
      )>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> }
    )> }
  ) };

export type GetWaitlistByCourseQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetWaitlistByCourseQuery = { getWaitlistByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'coursePriceId' | 'courseId' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'canceled' | 'waitListCount'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, courseLessons: Array<(
        Pick<CourseLesson, 'id'>
        & { course: Pick<Course, 'id' | 'paymentInterval'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice'>> }
      )> }
    )> }
  ) };

export type GetWaitlistCountOfCourseQueryVariables = {
  id: Scalars['ID']
};


export type GetWaitlistCountOfCourseQuery = Pick<Query, 'getWaitlistCountOfCourse'>;

export type GetBookingsByCourseMinimalQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetBookingsByCourseMinimalQuery = { getBookingsByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'canceled' | 'waitListCount'>
      & { attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'birthday' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'customerNumberPrefix' | 'customerNumber' | 'email' | 'nonDisclosureNotice'>> }
      )> }
    )> }
  ) };

export type GetBookingsByCourseForTransferQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetBookingsByCourseForTransferQuery = { getBookingsByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'canceled' | 'waitListCount'>
      & { attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'birthday' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'customerNumberPrefix' | 'customerNumber' | 'email' | 'nonDisclosureNotice'>> }
      )>, invoice: Maybe<Pick<Invoice, 'id'>> }
    )> }
  ) };

export type GetBookingsCountByCourseQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetBookingsCountByCourseQuery = { getBookingsByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingType' | 'canceled' | 'waitListCount'>> }
  ) };

export type RemoveAllBookingsMutationVariables = {};


export type RemoveAllBookingsMutation = Pick<Mutation, 'removeAllBookings'>;

export type SendBookingConfirmationMutationVariables = {
  bookingId: Scalars['ID']
};


export type SendBookingConfirmationMutation = Pick<Mutation, 'sendBookingConfirmation'>;

export type GetVariablesForBookingWithCourseQueryVariables = {
  id: Scalars['ID']
};


export type GetVariablesForBookingWithCourseQuery = { bookingById: (
    Pick<Booking, 'id' | 'creditedBalance' | 'memberDiscount'>
    & { attendee: Maybe<(
      Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday'>
      & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname'>> }
    )>, courseLessons: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
      & { course: (
        Pick<Course, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'description'> }
      ), location: Pick<Location, 'id' | 'city' | 'name'> }
    )> }
  ) };

export type ConvertBookingToWaitlistMutationVariables = {
  bookingId: Scalars['ID']
};


export type ConvertBookingToWaitlistMutation = { convertBookingToWaitlist: (
    Pick<Booking, 'id' | 'bookingType' | 'bookingNumberPrefix' | 'bookingNumber'>
    & { attendee: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname'>> }
  ) };

export type ConvertWaitlistToBookingMutationVariables = {
  bookingId: Scalars['ID']
};


export type ConvertWaitlistToBookingMutation = { convertWaitlistToBooking: (
    Pick<Booking, 'id' | 'bookingType' | 'bookingNumberPrefix' | 'bookingNumber'>
    & { attendee: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname'>> }
  ) };

export type TransferAttendeeToCourseMutationVariables = {
  bookingId: Scalars['ID'],
  targetCourseId: Scalars['ID'],
  bookingDeleteAction?: Maybe<BookingDeleteAction>,
  usePlaceReservation?: Maybe<Scalars['Boolean']>
};


export type TransferAttendeeToCourseMutation = { transferAttendeeToCourse: (
    Pick<Booking, 'id' | 'bookingType' | 'bookingNumberPrefix'>
    & { attendee: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname'>>, courseLessons: Array<(
      Pick<CourseLesson, 'id'>
      & { course: (
        Pick<Course, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name'> }
      ) }
    )> }
  ) };

export type CopyAttendeeToCourseMutationVariables = {
  bookingId: Scalars['ID'],
  targetCourseId: Scalars['ID'],
  usePlaceReservation?: Maybe<Scalars['Boolean']>
};


export type CopyAttendeeToCourseMutation = { copyAttendeeToCourse: (
    Pick<Booking, 'id' | 'bookingType' | 'bookingNumberPrefix'>
    & { attendee: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname'>>, courseLessons: Array<(
      Pick<CourseLesson, 'id'>
      & { course: (
        Pick<Course, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name'> }
      ) }
    )> }
  ) };

export type GetBookingsByIdsQueryVariables = {
  ids: Array<Scalars['String']>
};


export type GetBookingsByIdsQuery = { getBookingsByIds: Array<(
    Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'courseId' | 'canceled' | 'waitListCount'>
    & { attendee: Maybe<(
      Pick<Attendee, 'id'>
      & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email' | 'mobileNumber'>> }
    )> }
  )> };

export type GetBookingsByScheduledPaymentIdsQueryVariables = {
  scheduledPaymentIds: Array<Scalars['String']>
};


export type GetBookingsByScheduledPaymentIdsQuery = { getBookingsByScheduledPaymentIds: Array<(
    Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'canceled'>
    & { attendee: Maybe<(
      Pick<Attendee, 'id'>
      & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
    )> }
  )> };

export type GetBookingsByInvoiceIdsQueryVariables = {
  invoiceIds: Array<Scalars['String']>
};


export type GetBookingsByInvoiceIdsQuery = { getBookingsByInvoiceIds: Array<Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType'>> };

export type GetBookingsByAttendeeIdWithOptionsQueryVariables = {
  id: Scalars['ID'],
  startDateTime?: Maybe<Scalars['DateTime']>,
  endDateTime?: Maybe<Scalars['DateTime']>,
  canceledBookings?: Maybe<Scalars['Boolean']>
};


export type GetBookingsByAttendeeIdWithOptionsQuery = { getBookingsByAttendeeIdWithOptions: Array<(
    Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'startDateTime' | 'courseId' | 'noticeStaff' | 'attendanceListNote' | 'noticeBooker' | 'coursePriceId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount'>
    & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
      & { course: (
        Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment' | 'lessonCount'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
      ), location: Pick<Location, 'id' | 'city' | 'name'> }
    )>, attendee: Maybe<(
      Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
      & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
    )>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> }
  )> };

export type UpdateAttendanceListNoteMutationVariables = {
  bookingId: Scalars['ID'],
  attendanceListNote?: Maybe<Scalars['String']>
};


export type UpdateAttendanceListNoteMutation = { updateAttendanceListNote: Pick<Booking, 'id' | 'attendanceListNote'> };

export type UpdateBookingPaymentMethodMutationVariables = {
  bookingId: Scalars['ID'],
  paymentMethodId: Scalars['ID']
};


export type UpdateBookingPaymentMethodMutation = { updateBookingPaymentMethod: Pick<Booking, 'id' | 'paymentMethodId'> };

export type AddMultiplePlaceReservationsMutationVariables = {
  courseId: Scalars['ID'],
  count: Scalars['Float']
};


export type AddMultiplePlaceReservationsMutation = { addMultiplePlaceReservations: Array<Pick<Booking, 'id'>> };

export type DeleteAllPlaceReservationsByCourseIdMutationVariables = {
  courseId: Scalars['ID']
};


export type DeleteAllPlaceReservationsByCourseIdMutation = Pick<Mutation, 'deleteAllPlaceReservationsByCourseId'>;

export type DeleteSelectionOfWaitlistMutationVariables = {
  bookingIds: Array<Scalars['ID']>
};


export type DeleteSelectionOfWaitlistMutation = Pick<Mutation, 'deleteSelectionOfWaitlist'>;

export type GetBookingsByCourseRefactoredQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  id: Scalars['ID']
};


export type GetBookingsByCourseRefactoredQuery = { getBookingsByCourse: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'noticeStaff' | 'noticeBooker' | 'attendanceListNote' | 'coursePriceId' | 'courseId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
        & { course: (
          Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
      )>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> }
    )> }
  ) };

export type TransferMultipleBookingsMutationVariables = {
  transferMultipleBookingsInput: TransferMultipleBookingsInput
};


export type TransferMultipleBookingsMutation = { transferMultipleBookings: Array<(
    Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingNumber' | 'grossPrice' | 'courseId'>
    & { scheduledPayments: Maybe<Array<Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'type'>>>, attendee: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname'>> }
  )> };

export type GetBookingsInStartedCoursesQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetBookingsInStartedCoursesQuery = { bookingsInStartedCourses: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'updateDate' | 'createDate' | 'startDateTime' | 'courseId' | 'noticeStaff' | 'attendanceListNote' | 'noticeBooker' | 'coursePriceId' | 'payDate' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'bookedBy' | 'canceled' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'waitListCount' | 'additionalInfo'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'locationId'>
        & { course: (
          Pick<Course, 'id' | 'freePlaces' | 'startDateTime' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'prefixedCourseNumber' | 'allowMonthlyPayment' | 'lessonCount'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'nonDisclosureNotice' | 'credit'>> }
      )>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>> }
    )> }
  ) };

export type AuthenticateQueryVariables = {};


export type AuthenticateQuery = { authenticate: Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName' | 'roles' | 'hasProfile'> };

export type GetUserRolesQueryVariables = {};


export type GetUserRolesQuery = Pick<Query, 'getUserRoles'>;

export type DebugDefaultCourseDetailsMutationVariables = {
  courseId: Scalars['ID']
};


export type DebugDefaultCourseDetailsMutation = Pick<Mutation, 'refactoredCourseDetails'>;

export type GetPaymentMethodTypeByIdQueryVariables = {
  paymentMethodId: Scalars['String']
};


export type GetPaymentMethodTypeByIdQuery = Pick<Query, 'getPaymentMethodTypeById'>;

export type GetUserInfoByIdQueryVariables = {
  userId: Scalars['String']
};


export type GetUserInfoByIdQuery = { getInfoUserByUserId: Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName'> };

export type GetCurrentUserQueryVariables = {};


export type GetCurrentUserQuery = { currentUser: Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName'> };

export type GetAdminAndInstructorUsersQueryVariables = {
  koOrganizationId: Scalars['String']
};


export type GetAdminAndInstructorUsersQuery = { getAdminAndInstructorUsers: Array<Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName' | 'hasProfile' | 'roles'>> };

export type GetUserDetailsQueryVariables = {
  userId: Scalars['String']
};


export type GetUserDetailsQuery = { userDetails: (
    Pick<UserDetailsType, 'id' | 'email' | 'verified' | 'insertInstant' | 'lastLoginInstant' | 'roles'>
    & { loginAttempts: Maybe<Array<Pick<LoginAttempt, 'status'>>>, instructorDetails: Maybe<Pick<InstructorDetailsView, 'level' | 'active' | 'showInWeb' | 'firstname' | 'lastname' | 'email' | 'joinDate' | 'leaveDate'>> }
  ) };

export type CourseLessonBookingsByCourseQueryVariables = {
  courseId: Scalars['String']
};


export type CourseLessonBookingsByCourseQuery = { courseLessonBookingsByCourse: Array<(
    Pick<CourseLessonBooking, 'id' | 'attendance' | 'courseLessonId' | 'bookingId'>
    & { booking: (
      Pick<Booking, 'id'>
      & { attendee: Maybe<(
        Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname'>> }
      )> }
    ) }
  )> };

export type AttendanceListByCourseQueryVariables = {
  courseId: Scalars['String']
};


export type AttendanceListByCourseQuery = { attendanceListByCourse: Array<(
    Pick<Booking, 'id' | 'noticeStaff' | 'attendanceListNote' | 'courseId'>
    & { attendee: Maybe<(
      Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'customerNumber' | 'customerNumberPrefix'>
      & { customer: Pick<Customer, 'firstname' | 'lastname'>, booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'phoneNumber' | 'mobileNumber' | 'email' | 'street' | 'houseNumber' | 'postCode' | 'city'>> }
    )>, courseLessons: Array<Pick<CourseLesson, 'id' | 'startDateTime'>>, courseLessonBookings: Maybe<Array<Pick<CourseLessonBooking, 'id' | 'attendance' | 'note' | 'courseLessonId'>>> }
  )> };

export type UpdateCourseLessonBookingMutationVariables = {
  updateCourseLessonBookingInput: UpdateCourseLessonBookingInput
};


export type UpdateCourseLessonBookingMutation = { updateCourseLessonBooking: Pick<CourseLessonBooking, 'id' | 'attendance' | 'note'> };

export type CreateCourseLessonBookingsByCourseIdMutationVariables = {
  courseId: Scalars['String']
};


export type CreateCourseLessonBookingsByCourseIdMutation = Pick<Mutation, 'createCourseLessonBookingsByCourseId'>;

export type GetCourseLessonBookingByCourseLessonAndBookingQueryVariables = {
  courseLessonId: Scalars['String'],
  bookingId: Scalars['String']
};


export type GetCourseLessonBookingByCourseLessonAndBookingQuery = { courseLessonBookingByCourseLessonAndBooking: Pick<CourseLessonBooking, 'id' | 'bookingId' | 'attendance' | 'courseLessonId'> };

export type GetCourseTypeQueryVariables = {
  id: Scalars['String']
};


export type GetCourseTypeQuery = { courseTypeById: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color' | 'fee' | 'vat' | 'selfBookerCourse'>
    & { category: Maybe<Pick<CourseCategory, 'id'>>, participationPrerequisites: Maybe<Array<Pick<ParticipationPrerequisites, 'id' | 'groupLabel' | 'groupDescription' | 'optionalInputFields' | 'minCheckCount' | 'courseTypeId'>>> }
  ) };

export type GetDetailedCourseTypesQueryVariables = {};


export type GetDetailedCourseTypesQuery = { courseTypes: Array<(
    Pick<CourseType, 'id' | 'courseCount' | 'shortHandId' | 'name' | 'updateDate' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'fee' | 'vat' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'color' | 'isActive' | 'showInWeb' | 'selfBookerCourse'>
    & { participationPrerequisites: Maybe<Array<Pick<ParticipationPrerequisites, 'id' | 'groupLabel' | 'groupDescription' | 'optionalInputFields' | 'minCheckCount' | 'courseTypeId'>>> }
  )> };

export type GetCourseTypesQueryVariables = {};


export type GetCourseTypesQuery = { courseTypes: Array<Pick<CourseType, 'id' | 'name' | 'description' | 'courseCount'>> };

export type GetPrerequisiteCoursesQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetPrerequisiteCoursesQuery = { prerequisiteCourses: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCountWithoutWaitlist' | 'billedBookingCount' | 'freePlaces' | 'waitListCount' | 'bookingCount' | 'placeReservationsCount' | 'canceledBookingsCount' | 'settledBookingCount' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson' | 'annotation' | 'maxAttendees' | 'paymentInterval' | 'allowMonthlyPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb' | 'paymentType' | 'lessonCount'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>>, installmentRates: Array<Pick<InstallmentRate, 'id' | 'courseId' | 'installmentAmount' | 'installmentDue' | 'installmentType'>>, lessons: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>>, visibility: (
        Pick<CourseVisibilityResult, 'visibleForAll' | 'reason' | 'hideAfterDays'>
        & { restriction: (
          Pick<CourseVisibilityRestriction, 'membersOnly' | 'startDateTime' | 'endDateTime' | 'inTimeRange' | 'expireAction'>
          & { prerequisiteCourses: Array<Pick<Course, 'id'>> }
        ) }
      ), enabledCourses: Maybe<Array<(
        Pick<EnabledCourse, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'color'>, enabledRestriction: Maybe<Pick<BookingRestriction, 'id' | 'includeBookingList' | 'includeWaitingList' | 'membersOnly'>> }
      )>>, bookingRestriction: Maybe<Pick<BookingRestriction, 'id' | 'active' | 'visibleForAll' | 'includeBookingList' | 'includeWaitingList' | 'membersOnly' | 'blockedPlaces' | 'startDateTime' | 'endDateTime'>> }
    )> }
  ) };

export type GetLocalStateQueryVariables = {};


export type GetLocalStateQuery = { LocalState: { createCourse: Pick<CreateCourse, 'locationId'>, bookAttendees: (
      Pick<BookAttendees, 'selectedCourseId'>
      & { selectedAttendee: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> }
    ) } };

export type GetInstructorsByIdQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type GetInstructorsByIdQuery = { findInstructorByIds: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> };

export type GetReferenceCoursesLinkQueryVariables = {
  courseId: Scalars['ID']
};


export type GetReferenceCoursesLinkQuery = Pick<Query, 'getReferenceCoursesLink'>;

export type CourseIsPrerequisiteCourseForQueryVariables = {
  courseId: Scalars['ID']
};


export type CourseIsPrerequisiteCourseForQuery = { courseIsPrerequisiteCourseFor: Array<(
    Pick<Course, 'id' | 'prefixedCourseNumber'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, bookingRestriction: Maybe<Pick<BookingRestriction, 'id' | 'includeWaitingList' | 'includeBookingList'>>, visibility: (
      Pick<CourseVisibilityResult, 'visibleForAll' | 'reason'>
      & { restriction: Pick<CourseVisibilityRestriction, 'membersOnly' | 'inTimeRange' | 'expireAction'> }
    ) }
  )> };

export type RemoveCourseMutationVariables = {
  id: Scalars['ID']
};


export type RemoveCourseMutation = Pick<Mutation, 'removeCourse'>;

export type BookAttendeeGetLocalStateQueryVariables = {};


export type BookAttendeeGetLocalStateQuery = { LocalState: { bookAttendees: (
      Pick<BookAttendees, 'selectedCourseId'>
      & { selectedAttendee: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> }
    ) } };

export type BookAttendeeGetSelectedAttendeeQueryVariables = {};


export type BookAttendeeGetSelectedAttendeeQuery = { LocalState: { bookAttendees: { selectedAttendee: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> } } };

export type BookAttendeeGetSelectedCourseQueryVariables = {};


export type BookAttendeeGetSelectedCourseQuery = { LocalState: { bookAttendees: Pick<BookAttendees, 'selectedCourseId'> } };

export type BookAttendeeSetSelectedAttendeeMutationVariables = {
  id?: Maybe<Scalars['ID']>,
  firstname?: Maybe<Scalars['String']>,
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type BookAttendeeSetSelectedAttendeeMutation = Pick<Mutation, 'bookAttendeeSetSelectedAttendee'>;

export type BookAttendeeSetSelectedCourseMutationVariables = {
  id?: Maybe<Scalars['ID']>
};


export type BookAttendeeSetSelectedCourseMutation = Pick<Mutation, 'bookAttendeeSetSelectedCourse'>;

export type UpdateLocalLocationIdMutationVariables = {
  id?: Maybe<Scalars['ID']>
};


export type UpdateLocalLocationIdMutation = Pick<Mutation, 'updateLocalLocationId'>;

export type GetCoursesQueryVariables = {};


export type GetCoursesQuery = { courses: Array<(
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCount' | 'billedBookingCount' | 'freePlaces' | 'settledBookingCount' | 'startDateTime' | 'endDateTime' | 'annotation' | 'maxAttendees' | 'paymentInterval' | 'allowMonthlyPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>> }
  )> };

export type GetCoursesWithPaginationQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  currentUserOnly?: Maybe<Scalars['Boolean']>,
  excludedCourseIds?: Maybe<Array<Scalars['String']>>
};


export type GetCoursesWithPaginationQuery = { coursesWithPagination: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCountWithoutWaitlist' | 'billedBookingCount' | 'freePlaces' | 'waitListCount' | 'bookingCount' | 'placeReservationsCount' | 'canceledBookingsCount' | 'settledBookingCount' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson' | 'annotation' | 'maxAttendees' | 'paymentInterval' | 'allowMonthlyPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb' | 'paymentType' | 'lessonCount'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>>, installmentRates: Array<Pick<InstallmentRate, 'id' | 'courseId' | 'installmentAmount' | 'installmentDue' | 'installmentType'>>, lessons: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>>, visibility: (
        Pick<CourseVisibilityResult, 'visibleForAll' | 'reason' | 'hideAfterDays'>
        & { restriction: (
          Pick<CourseVisibilityRestriction, 'membersOnly' | 'startDateTime' | 'endDateTime' | 'inTimeRange' | 'expireAction'>
          & { prerequisiteCourses: Array<Pick<Course, 'id'>> }
        ) }
      ) }
    )> }
  ) };

export type GetCourseByIdQueryVariables = {
  id: Scalars['String']
};


export type GetCourseByIdQuery = { courseById: (
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'courseNumberFormat' | 'customCourseNumberPrefix' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'maxWaitlist' | 'bookingCount' | 'canceledBookingsCount' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson' | 'cycle' | 'annotation' | 'websiteInfo' | 'additionalShortInfo' | 'allowMonthlyPayment' | 'allowUpFrontPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb' | 'freePlaces' | 'paymentType'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'showInWeb'>, location: (
      Pick<Location, 'id' | 'city' | 'name'>
      & { venues: Array<Pick<Venue, 'id' | 'name'>> }
    ), venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level' | 'email'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial' | 'includedEntranceFee'>>, lessons: Array<(
      Pick<CourseLesson, 'id' | 'locationId' | 'venueId' | 'startDateTime' | 'endDateTime'>
      & { location: Pick<Location, 'id' | 'city'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, courseLessonBookings: Array<Pick<CourseLessonBooking, 'id' | 'bookingId' | 'attendance' | 'courseLessonId'>> }
    )>, installmentRates: Array<Pick<InstallmentRate, 'id' | 'courseId' | 'installmentAmount' | 'installmentDue' | 'installmentType'>>, visibility: (
      Pick<CourseVisibilityResult, 'visibleForAll' | 'reason' | 'hideAfterDays'>
      & { restriction: (
        Pick<CourseVisibilityRestriction, 'membersOnly' | 'startDateTime' | 'endDateTime' | 'inTimeRange' | 'expireAction'>
        & { prerequisiteCourses: Array<Pick<Course, 'id'>> }
      ) }
    ) }
  ) };

export type GetCourseByLocationIdQueryVariables = {
  id: Scalars['ID']
};


export type GetCourseByLocationIdQuery = { courseByLocationId: Array<(
    Pick<Course, 'id'>
    & { lessons: Array<Pick<CourseLesson, 'startDateTime' | 'endDateTime'>>, courseType: Pick<CourseType, 'name' | 'description' | 'annotation' | 'websiteInfo'> }
  )> };

export type UpdateCourseMutationVariables = {
  id: Scalars['String'],
  editCourseData: EditCourseInput,
  fillFromWaitingList?: Maybe<Scalars['Boolean']>,
  notifyBooker?: Maybe<Scalars['Boolean']>
};


export type UpdateCourseMutation = { updateCourse: (
    Pick<Course, 'id' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'maxWaitlist' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson' | 'cycle' | 'annotation' | 'websiteInfo' | 'allowMonthlyPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb'>
    & { coursePrice: Array<Pick<CoursePrice, 'id' | 'vat' | 'grossPrice' | 'discount' | 'discountUnit' | 'courseId' | 'includedEntranceFee'>> }
  ) };

export type GetCitiesQueryVariables = {};


export type GetCitiesQuery = { cities: Array<Pick<LocationCity, 'city'>> };

export type GetLocationsSortedByCityQueryVariables = {};


export type GetLocationsSortedByCityQuery = { locationsSortedByCity: Array<(
    Pick<LocationsGroupedByCity, 'city'>
    & { locations: Array<(
      Pick<LocationGroupedByCityLocation, 'id' | 'city' | 'name'>
      & { venues: Array<Pick<LocationGroupedByCityVenue, 'id' | 'name'>> }
    )> }
  )> };

export type GetLocationsByCityQueryVariables = {
  city: Scalars['String']
};


export type GetLocationsByCityQuery = { locationsByCity: Array<(
    Pick<Location, 'id' | 'name'>
    & { venues: Array<Pick<Venue, 'id' | 'name'>> }
  )> };

export type GetInstructorsQueryVariables = {};


export type GetInstructorsQuery = { instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level' | 'leaveDate' | 'active'>> };

export type CreateCourseMutationVariables = {
  newCourseData: NewCourseInput
};


export type CreateCourseMutation = { createCourse: Pick<Course, 'id'> };

export type GetCourseTypeStateQueryVariables = {
  id: Scalars['String']
};


export type GetCourseTypeStateQuery = { courseTypeStateById: Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name'> };

export type RemoveCourseTypeMutationVariables = {
  id: Scalars['ID']
};


export type RemoveCourseTypeMutation = Pick<Mutation, 'removeCourseType'>;

export type CreateCourseTypeMutationVariables = {
  newCourseTypeData: NewCourseTypeInput
};


export type CreateCourseTypeMutation = { createCourseType: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color'>
    & { category: Maybe<Pick<CourseCategory, 'id'>>, participationPrerequisites: Maybe<Array<Pick<ParticipationPrerequisites, 'id' | 'groupLabel' | 'groupDescription' | 'optionalInputFields' | 'minCheckCount' | 'courseTypeId'>>> }
  ) };

export type UpdateCourseTypeMutationVariables = {
  id: Scalars['String'],
  newCourseTypeData: NewCourseTypeInput
};


export type UpdateCourseTypeMutation = { updateCourseType: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color'>
    & { category: Maybe<Pick<CourseCategory, 'id'>> }
  ) };

export type CourseTypeGetCourseCategoriesQueryVariables = {};


export type CourseTypeGetCourseCategoriesQuery = { courseCategories: Array<Pick<CourseCategory, 'id' | 'name'>> };

export type GetCourseCategoriesQueryVariables = {};


export type GetCourseCategoriesQuery = { courseCategories: Array<(
    Pick<CourseCategory, 'id' | 'isActive' | 'name' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id' | 'name' | 'color'>> }
  )> };

export type GetCourseCategoryQueryVariables = {
  id: Scalars['String']
};


export type GetCourseCategoryQuery = { courseCategoryById: (
    Pick<CourseCategory, 'id' | 'isActive' | 'name' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id' | 'name'>> }
  ) };

export type CreateCourseCategoryMutationVariables = {
  newCourseCategoryData: NewCourseCategoryInput
};


export type CreateCourseCategoryMutation = { createCourseCategory: (
    Pick<CourseCategory, 'id' | 'name' | 'isActive' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id'>> }
  ) };

export type UpdateCourseCategoryMutationVariables = {
  id: Scalars['String'],
  newCourseCategoryData: NewCourseCategoryInput
};


export type UpdateCourseCategoryMutation = { updateCourseCategory: (
    Pick<CourseCategory, 'id' | 'name' | 'isActive' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id'>> }
  ) };

export type CourseGetInstructorsQueryVariables = {};


export type CourseGetInstructorsQuery = { instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> };

export type CourseGetInstructorsByLocationQueryVariables = {
  locationId: Scalars['String']
};


export type CourseGetInstructorsByLocationQuery = { findInstructorsByLocation: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level' | 'active' | 'leaveDate'>> };

export type GetCourseLessonsByCourseIdQueryVariables = {
  courseId: Scalars['ID']
};


export type GetCourseLessonsByCourseIdQuery = { courseLessonsByCourseId: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>> };

export type GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables = {
  courseId: Scalars['ID']
};


export type GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery = { courseLessonsByCourseId: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
    & { bookings: Maybe<Array<(
      Pick<Booking, 'id'>
      & { attendee: Maybe<Pick<Attendee, 'id' | 'firstname'>> }
    )>>, courseLessonBookings: Array<Pick<CourseLessonBooking, 'id' | 'courseLessonId' | 'attendance' | 'bookingId'>> }
  )> };

export type GetCourseLessonsByCourseIdWithLocationQueryVariables = {
  courseId: Scalars['ID']
};


export type GetCourseLessonsByCourseIdWithLocationQuery = { courseLessonsByCourseId: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
    & { location: Pick<Location, 'id' | 'city' | 'name'> }
  )> };

export type GetCourseVariablesForTemplateQueryVariables = {
  courseId: Scalars['ID']
};


export type GetCourseVariablesForTemplateQuery = { courseLessonsByCourseId: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
    & { location: Pick<Location, 'id' | 'city' | 'name'>, course: (
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'description'> }
    ) }
  )> };

export type IsCourseNumberAvailableQueryVariables = {
  customCourseNumberPrefix: Scalars['String'],
  courseId: Scalars['String']
};


export type IsCourseNumberAvailableQuery = Pick<Query, 'isCourseNameUnique'>;

export type GetFreePlacesByCourseIdQueryVariables = {
  id: Scalars['String']
};


export type GetFreePlacesByCourseIdQuery = { courseById: Pick<Course, 'id' | 'freePlaces' | 'bookingCount'> };

export type GetMaxAttendeesByCourseIdQueryVariables = {
  id: Scalars['String']
};


export type GetMaxAttendeesByCourseIdQuery = { courseById: Pick<Course, 'id' | 'maxAttendees'> };

export type GetAllCoursesWithAllowMonthlyPaymentQueryVariables = {};


export type GetAllCoursesWithAllowMonthlyPaymentQuery = { getAllCoursesWithAllowMonthlyPayment: Array<Pick<Course, 'id' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>> };

export type GetCourseAnnotationQueryVariables = {
  id: Scalars['String']
};


export type GetCourseAnnotationQuery = { courseById: Pick<Course, 'id' | 'annotation'> };

export type UpdateCourseAnnotationMutationVariables = {
  id: Scalars['String'],
  annotation?: Maybe<Scalars['String']>
};


export type UpdateCourseAnnotationMutation = { updateCourseAnnotation: Pick<Course, 'id' | 'annotation'> };

export type GetCourseMemberDiscountQueryVariables = {
  id: Scalars['String']
};


export type GetCourseMemberDiscountQuery = { courseById: (
    Pick<Course, 'id'>
    & { coursePrice: Array<Pick<CoursePrice, 'id' | 'discount' | 'discountUnit'>> }
  ) };

export type GetCoursesByIdsQueryVariables = {
  ids: Array<Scalars['String']>
};


export type GetCoursesByIdsQuery = { coursesByIds: Array<(
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCount' | 'billedBookingCount' | 'freePlaces' | 'settledBookingCount' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson' | 'annotation' | 'maxAttendees' | 'paymentInterval' | 'allowMonthlyPayment' | 'monthlySchedule' | 'isActive' | 'showInWeb'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>> }
  )> };

export type GetCoursesByIdsMinimalQueryVariables = {
  ids: Array<Scalars['String']>
};


export type GetCoursesByIdsMinimalQuery = { coursesByIds: Array<(
    Pick<Course, 'id' | 'prefixedCourseNumber' | 'startDateTime' | 'endDateTime' | 'endDateTimeFirstCourseLesson'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
  )> };

export type UpdateMultipleCoursesShowInWebMutationVariables = {
  courseIds: Array<Scalars['ID']>,
  showInWeb: Scalars['Boolean']
};


export type UpdateMultipleCoursesShowInWebMutation = { updateMultipleCoursesShowInWeb: Array<Pick<Course, 'id' | 'showInWeb'>> };

export type GetInstallmentPlanQueryVariables = {
  newInstallmentPlanInput: NewInstallmentPlanInput
};


export type GetInstallmentPlanQuery = Pick<Query, 'getInstallmentPlanPreview'>;

export type GetInstallmentRatesByCourseIdQueryVariables = {
  courseId: Scalars['ID']
};


export type GetInstallmentRatesByCourseIdQuery = { installmentRatesByCourseId: Array<Pick<InstallmentRate, 'id' | 'courseId' | 'installmentAmount' | 'installmentDue'>> };

export type GetAttendeeQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeQuery = { attendee: (
    Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'gender'>
    & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender'>> }
  ) };

export type GetAttendeesByIdsQueryVariables = {
  attendeeIds: Array<Scalars['ID']>
};


export type GetAttendeesByIdsQuery = { attendeesByIds: Array<(
    Pick<Attendee, 'id' | 'firstname' | 'lastname'>
    & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
  )> };

export type AttendeeOverviewGetAttendeesMinimalQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type AttendeeOverviewGetAttendeesMinimalQuery = { attendees: (
    Pick<AttendeeWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Attendee, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'birthday' | 'gender' | 'deletable' | 'member' | 'addition' | 'annotation'>
      & { booker: Maybe<(
        Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice'>
        & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>> }
      )>, defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>, bookingsKo9: Maybe<Array<Pick<BookingsKo9, 'id' | 'courseNumber' | 'courseName' | 'firstCourseLesson'>>> }
    )> }
  ) };

export type CheckIfIsBookedIntoCourseQueryVariables = {
  courseId: Scalars['ID'],
  attendeeId: Scalars['ID']
};


export type CheckIfIsBookedIntoCourseQuery = Pick<Query, 'checkIfIsBookedIntoCourse'>;

export type GetBookedAttendeesByCourseIdQueryVariables = {
  courseId: Scalars['ID']
};


export type GetBookedAttendeesByCourseIdQuery = { bookedAttendeesByCourseId: Array<Pick<Attendee, 'id'>> };

export type GetAttendeeVariableForTemplateQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeVariableForTemplateQuery = { attendee: (
    Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday'>
    & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname'>> }
  ) };

export type GetAttendeeDetailsQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeDetailsQuery = { attendee: (
    Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'gender' | 'member' | 'customerNumberPrefix' | 'customerNumber'>
    & { defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>, booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender'>> }
  ) };

export type GetAttendeeDetailsByIdQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeDetailsByIdQuery = { attendee: (
    Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'gender' | 'member' | 'customerNumberPrefix' | 'customerNumber'>
    & { defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>, booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender'>> }
  ) };

export type TransferAttendeeToBookerMutationVariables = {
  attendeeId: Scalars['ID'],
  newBookerId: Scalars['ID']
};


export type TransferAttendeeToBookerMutation = { transferAttendeeToBooker: Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'> };

export type GetAttendeeMemberStatusQueryVariables = {
  id: Scalars['String']
};


export type GetAttendeeMemberStatusQuery = { attendee: Pick<Attendee, 'id' | 'member'> };

export type ChangeAttendeeMemberStatusMutationVariables = {
  id: Scalars['ID'],
  member: Scalars['Boolean']
};


export type ChangeAttendeeMemberStatusMutation = { changeAttendeeMemberStatus: Pick<Attendee, 'id' | 'member'> };

export type ChangeDefaultPaymentMethodMutationVariables = {
  attendeeId: Scalars['ID'],
  paymentMethodId: Scalars['ID']
};


export type ChangeDefaultPaymentMethodMutation = { changeDefaultPaymentMethod: (
    Pick<Attendee, 'id'>
    & { defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditcardNumber' | 'creditCardAccountholder' | 'expirationDate' | 'sepaAccountholder' | 'iban' | 'bic' | 'bank' | 'paypalAccountholder' | 'email' | 'bankTransfer'> }
  ) };

export type CreateBookerMutationVariables = {
  newBookerData: BookerInput
};


export type CreateBookerMutation = { createBooker: (
    Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'addition' | 'annotation'>
      & { defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type GetBookerWithAttendeeBookingsQueryVariables = {
  id: Scalars['String']
};


export type GetBookerWithAttendeeBookingsQuery = { booker: (
    Pick<Booker, 'id' | 'customerId' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'dsgvo' | 'newsletter' | 'credit' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'nonDisclosureNotice' | 'customerNumber' | 'userId'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'customerId' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'addition' | 'annotation' | 'deletable'>
      & { defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'courseId'>
          & { course: Pick<Course, 'id' | 'maxAttendees'> }
        )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type GetBookerForDuplicateBookerOverviewQueryVariables = {
  id: Scalars['String']
};


export type GetBookerForDuplicateBookerOverviewQuery = { booker: (
    Pick<Booker, 'id' | 'customerId' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'dsgvo' | 'newsletter' | 'credit' | 'healthInsuranceFund' | 'annotation' | 'customerNumber' | 'customerNumberPrefix' | 'userId'>
    & { attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'customerId' | 'customerNumberPrefix' | 'customerNumber' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'annotation'>
      & { defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'bookingNumberPrefix' | 'bookingType' | 'bookingNumber' | 'canceled'>
        & { invoice: Maybe<Pick<Invoice, 'id'>>, courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'courseId'>
          & { course: (
            Pick<Course, 'id' | 'courseNumber'>
            & { courseType: Pick<CourseType, 'id' | 'color' | 'name'> }
          ) }
        )> }
      )>> }
    )>> }
  ) };

export type UpdateBookerMutationVariables = {
  updateBookerData: BookerInput
};


export type UpdateBookerMutation = { updateBooker: (
    Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'deletable' | 'addition' | 'annotation'>
      & { startedCourses: Array<Pick<Course, 'id' | 'prefixedCourseNumber'>>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type AttendeeTableGetCourseLessonsQueryVariables = {
  ids?: Maybe<Array<Scalars['String']>>
};


export type AttendeeTableGetCourseLessonsQuery = { courseLessons: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime'>
    & { course: (
      Pick<Course, 'id' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
    ), location: Pick<Location, 'id' | 'name' | 'city'> }
  )> };

export type GetBookingsByAttendeeQueryVariables = {
  attendeeId: Scalars['ID']
};


export type GetBookingsByAttendeeQuery = { getBookingsByAttendeeId: Array<(
    Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'canceled' | 'noticeStaff' | 'startDateTime'>
    & { courseLessons: Array<(
      Pick<CourseLesson, 'id'>
      & { course: (
        Pick<Course, 'id' | 'prefixedCourseNumber' | 'courseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
      ) }
    )> }
  )> };

export type DeleteAttendeeMutationVariables = {
  id: Scalars['String']
};


export type DeleteAttendeeMutation = Pick<Mutation, 'deleteAttendee'>;

export type GetCourseLessonStartDatesQueryVariables = {
  ids?: Maybe<Array<Scalars['String']>>
};


export type GetCourseLessonStartDatesQuery = { courseLessons: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime'>
    & { course: Pick<Course, 'id' | 'prefixedCourseNumber'> }
  )> };

export type GetBookersOverviewQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetBookersOverviewQuery = { bookers: (
    Pick<BookerWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booker, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'dsgvo' | 'newsletter' | 'email' | 'mobileNumber' | 'phoneNumber' | 'birthday' | 'gender' | 'initialContact' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice' | 'userId'>
      & { attendees: Maybe<Array<Pick<Attendee, 'firstname' | 'lastname' | 'id' | 'deletable' | 'addition' | 'annotation' | 'member'>>>, paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'bankTransfer' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>> }
    )> }
  ) };

export type BookerCoreFieldsFragment = (
  { __typename: 'Booker' }
  & Pick<Booker, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'dsgvo' | 'newsletter' | 'email' | 'mobileNumber' | 'phoneNumber' | 'birthday' | 'gender' | 'initialContact' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice' | 'userId'>
);

export type BookerPaymentMethodsFieldsFragment = { paymentMethods: Maybe<Array<(
    { __typename: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id' | 'bankTransfer' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>
  )>> };

export type BookerAttendeesFieldsFragment = { attendees: Maybe<Array<(
    { __typename: 'Attendee' }
    & Pick<Attendee, 'firstname' | 'lastname' | 'id' | 'deletable' | 'addition' | 'annotation' | 'member'>
  )>> };

export type GetDynamicBookersQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  includePayment: Scalars['Boolean'],
  includeAttendees: Scalars['Boolean']
};


export type GetDynamicBookersQuery = { bookersDynamic: (
    { __typename: 'BookerWithPagination' }
    & Pick<BookerWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      BookerCoreFieldsFragment
      & BookerPaymentMethodsFieldsFragment
      & BookerAttendeesFieldsFragment
    )> }
  ) };

export type GetBookerByEmailQueryVariables = {
  email: Scalars['String']
};


export type GetBookerByEmailQuery = { getBookerByEmail: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> };

export type GetBookersByIdQueryVariables = {
  bookerIds: Array<Scalars['String']>
};


export type GetBookersByIdQuery = { bookersById: Array<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> };

export type SendBlankMailToMultipleBookersMutationVariables = {
  input: BlankMailInputTypes,
  file?: Maybe<Scalars['Upload']>
};


export type SendBlankMailToMultipleBookersMutation = Pick<Mutation, 'sendBlankMailToMultipleBookers'>;

export type GetBookersByCourseIdsQueryVariables = {
  courseIds: Array<Scalars['String']>
};


export type GetBookersByCourseIdsQuery = { getBookersByCourseIds: Array<(
    Pick<Booker, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'dsgvo' | 'newsletter' | 'email' | 'mobileNumber' | 'phoneNumber' | 'birthday' | 'gender' | 'initialContact' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice'>
    & { attendees: Maybe<Array<(
      Pick<Attendee, 'firstname' | 'lastname' | 'id' | 'deletable' | 'addition' | 'annotation' | 'member'>
      & { startedCourses: Array<Pick<Course, 'id' | 'prefixedCourseNumber'>> }
    )>>, paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'bankTransfer' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>> }
  )> };

export type GetReceiversByCourseIdsQueryVariables = {
  courseIds: Array<Scalars['String']>
};


export type GetReceiversByCourseIdsQuery = { getBookersByCourseIds: Array<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> };

export type DeleteBookerMutationVariables = {
  id: Scalars['String']
};


export type DeleteBookerMutation = Pick<Mutation, 'deleteBooker'>;

export type RemoveAllBookersOfTenantMutationVariables = {};


export type RemoveAllBookersOfTenantMutation = Pick<Mutation, 'removeAllBookersOfTenant'>;

export type AddPaymentMethodMutationVariables = {
  bookerId: Scalars['String'],
  paymentMethodType: Scalars['String'],
  sepaDebitInput?: Maybe<SepaDebitIntput>
};


export type AddPaymentMethodMutation = { addPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type'> };

export type GetPaymentMethodsByBookerIdQueryVariables = {
  bookerId: Scalars['String']
};


export type GetPaymentMethodsByBookerIdQuery = { getPaymentMethodsByBookerId: Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditcardNumber' | 'creditCardAccountholder' | 'expirationDate' | 'sepaAccountholder' | 'iban' | 'bic' | 'bank' | 'paypalAccountholder' | 'email' | 'bankTransfer'>> };

export type GetBookerDetailsQueryVariables = {
  id: Scalars['String']
};


export type GetBookerDetailsQuery = { booker: (
    Pick<Booker, 'id' | 'customerId' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'initialContact' | 'dsgvo' | 'newsletter' | 'credit' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'nonDisclosureNotice' | 'customerNumber' | 'customerNumberPrefix'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'customerId' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'addition' | 'annotation' | 'customerNumber' | 'customerNumberPrefix'>
      & { defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'courseId'>
          & { course: Pick<Course, 'id' | 'maxAttendees'> }
        )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type GetDuplicateBookersQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetDuplicateBookersQuery = { getDuplicateBookers: Array<Array<(
    Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'customerNumber' | 'customerNumberPrefix' | 'email' | 'userId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'nonDisclosureNotice' | 'credit' | 'birthday' | 'updateDate' | 'createdBy'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'customerNumber' | 'customerNumberPrefix'>
      & { bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'startDateTime' | 'canceled'>
        & { courseLessons: Array<(
          Pick<CourseLesson, 'id'>
          & { course: (
            Pick<Course, 'id'>
            & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
          ) }
        )> }
      )>> }
    )>> }
  )>> };

export type MergeBookersMutationVariables = {
  updateBookerData: BookerInput,
  mergedBookerId: Scalars['ID']
};


export type MergeBookersMutation = { mergeBookers: (
    Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'customerNumber' | 'email' | 'userId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'nonDisclosureNotice' | 'credit' | 'birthday' | 'updateDate' | 'createdBy'>
    & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>>, attendees: Maybe<Array<(
      Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'customerNumber'>
      & { bookings: Maybe<Array<(
        Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'startDateTime' | 'canceled'>
        & { courseLessons: Array<(
          Pick<CourseLesson, 'id'>
          & { course: (
            Pick<Course, 'id'>
            & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
          ) }
        )> }
      )>> }
    )>> }
  ) };

export type UpdateBookerCreditMutationVariables = {
  bookerId: Scalars['ID'],
  credit: Scalars['Float']
};


export type UpdateBookerCreditMutation = { updateBookerCredit: Pick<Booker, 'id' | 'credit'> };

export type GetBookersExportQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetBookersExportQuery = { bookersExport: Array<(
    Pick<Booker, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'email' | 'mobileNumber' | 'phoneNumber' | 'birthday' | 'gender' | 'initialContact' | 'healthInsuranceFund' | 'addition' | 'annotation' | 'credit' | 'nonDisclosureNotice'>
    & { attendees: Maybe<Array<Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'member' | 'addition' | 'annotation'>>> }
  )> };

export type GetOnlineBookingsByTimeRangeCountQueryVariables = {
  startDateTime: Scalars['DateTime'],
  endDateTime: Scalars['DateTime']
};


export type GetOnlineBookingsByTimeRangeCountQuery = Pick<Query, 'getOnlineBookingsByTimeRangeCount'>;

export type GetUpcomingCoursesByTimeRangeQueryVariables = {
  startDateTime: Scalars['DateTime'],
  endDateTime: Scalars['DateTime'],
  options?: Maybe<ArrayDataOptions>
};


export type GetUpcomingCoursesByTimeRangeQuery = { getUpcomingCoursesByTimeRange: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Course, 'id' | 'startDateTime' | 'prefixedCourseNumber' | 'maxAttendees' | 'minAttendees' | 'freePlaces' | 'bookingCount' | 'waitListCount' | 'placeReservationsCount'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, location: Pick<Location, 'id' | 'name' | 'city'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
    )> }
  ) };

export type GetUpcomingCoursesByTimeRangeMinQueryVariables = {
  startDateTime: Scalars['DateTime'],
  endDateTime: Scalars['DateTime'],
  options?: Maybe<ArrayDataOptions>
};


export type GetUpcomingCoursesByTimeRangeMinQuery = { getUpcomingCoursesByTimeRange: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<Course, 'id' | 'maxAttendees' | 'freePlaces' | 'waitListCount' | 'bookingCount'>> }
  ) };

export type GetCoursesWithHighDemandQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetCoursesWithHighDemandQuery = { getCoursesWithHighDemand: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Course, 'id' | 'maxAttendees' | 'waitListCount' | 'bookingCount' | 'prefixedCourseNumber' | 'startDateTime'>
      & { location: Pick<Location, 'id' | 'name'>, courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
    )> }
  ) };

export type SendSimpleEmailMutationVariables = {
  sendParamsMessageInput: SendParamsMessageInput,
  sandboxMode: Scalars['Boolean']
};


export type SendSimpleEmailMutation = Pick<Mutation, 'sendMail'>;

export type FaUserByEmailQueryVariables = {
  email: Scalars['String']
};


export type FaUserByEmailQuery = { faUserByEmail: (
    Pick<FaUser, 'id' | 'lastLoginInstant' | 'passwordLastUpdateInstant' | 'verified' | 'active' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'imageUrl' | 'insertInstant' | 'lastUpdateInstant' | 'tenantId' | 'timezone'>
    & { registrations: Maybe<Array<Pick<UserRegistration, 'applicationId' | 'data' | 'id' | 'insertInstant' | 'lastLoginInstant' | 'lastUpdateInstant' | 'roles' | 'username' | 'verified'>>> }
  ) };

export type FaUserByUserIdQueryVariables = {
  userId: Scalars['String']
};


export type FaUserByUserIdQuery = { faUserByUserId: Maybe<(
    Pick<FaUser, 'id' | 'lastLoginInstant' | 'passwordLastUpdateInstant' | 'verified' | 'active' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'imageUrl' | 'insertInstant' | 'lastUpdateInstant' | 'tenantId' | 'timezone'>
    & { registrations: Maybe<Array<Pick<UserRegistration, 'applicationId' | 'data' | 'id' | 'insertInstant' | 'lastLoginInstant' | 'lastUpdateInstant' | 'roles' | 'username' | 'verified'>>> }
  )> };

export type CreateFaUserWithOrAddEndUserRightsMutationVariables = {
  email: Scalars['String']
};


export type CreateFaUserWithOrAddEndUserRightsMutation = { createFaUserWithOrAddEndUserRights: (
    Pick<FaUser, 'id' | 'lastLoginInstant' | 'passwordLastUpdateInstant' | 'verified' | 'active' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'imageUrl' | 'insertInstant' | 'lastUpdateInstant' | 'tenantId' | 'timezone'>
    & { registrations: Maybe<Array<Pick<UserRegistration, 'applicationId' | 'data' | 'id' | 'insertInstant' | 'lastLoginInstant' | 'lastUpdateInstant' | 'roles' | 'username' | 'verified'>>> }
  ) };

export type AddFaUserByBookerIdMutationVariables = {
  bookerId: Scalars['String']
};


export type AddFaUserByBookerIdMutation = { addFaUserByBookerId: (
    Pick<FaUser, 'id' | 'lastLoginInstant' | 'passwordLastUpdateInstant' | 'verified' | 'active' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'imageUrl' | 'insertInstant' | 'lastUpdateInstant' | 'tenantId' | 'timezone'>
    & { registrations: Maybe<Array<Pick<UserRegistration, 'applicationId' | 'data' | 'id' | 'insertInstant' | 'lastLoginInstant' | 'lastUpdateInstant' | 'roles' | 'username' | 'verified'>>> }
  ) };

export type CheckEmailUniquenessQueryVariables = {
  email: Scalars['String']
};


export type CheckEmailUniquenessQuery = Pick<Query, 'checkEmailUniqueness'>;

export type GetHolidaysByStateCodeQueryVariables = {
  stateCode: StateCode
};


export type GetHolidaysByStateCodeQuery = { holidaysByStateCode: Array<Pick<HolidayWithDays, 'id' | 'name' | 'slug' | 'start' | 'end' | 'year' | 'stateCode' | 'holidayStateColor' | 'type' | 'days' | 'color'>> };

export type GetHolidaysByCompanyQueryVariables = {};


export type GetHolidaysByCompanyQuery = { holidaysByCompany: Array<Pick<HolidayWithDays, 'id' | 'name' | 'slug' | 'start' | 'end' | 'year' | 'stateCode' | 'holidayStateColor' | 'type' | 'days' | 'color'>> };

export type GetHolidaysByCompanyAndDateRangeQueryVariables = {
  start: Scalars['DateTime'],
  end: Scalars['DateTime']
};


export type GetHolidaysByCompanyAndDateRangeQuery = { holidaysByCompanyAndDateRange: Array<Pick<HolidayWithDays, 'id' | 'name' | 'slug' | 'start' | 'end' | 'year' | 'stateCode' | 'holidayStateColor' | 'type' | 'days' | 'color'>> };

export type GetCustomHolidaysByCompanyQueryVariables = {};


export type GetCustomHolidaysByCompanyQuery = { customHolidaysByCompany: Array<Pick<Holiday, 'id' | 'name' | 'slug' | 'start' | 'end' | 'year' | 'stateCode' | 'holidayStateColor' | 'type' | 'color'>> };

export type UpdateCustomCompanyHolidaysMutationVariables = {
  holidayInputs: Array<HolidayInput>
};


export type UpdateCustomCompanyHolidaysMutation = { updateCustomCompanyHolidays: Array<Pick<Holiday, 'id' | 'name' | 'slug' | 'start' | 'end' | 'year' | 'stateCode' | 'holidayStateColor' | 'type' | 'color'>> };

export type CreateInvoiceMutationVariables = {
  newInvoiceData: InvoiceInput,
  sendMail?: Maybe<Scalars['Boolean']>
};


export type CreateInvoiceMutation = { createInvoice: Pick<Invoice, 'id'> };

export type CreateInvoicesByCourseIdMutationVariables = {
  courseId: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type CreateInvoicesByCourseIdMutation = { createInvoicesByCourseId: Array<Pick<Invoice, 'id'>> };

export type CreateInvoicesByBookingIdsMutationVariables = {
  bookingIds: Array<Scalars['ID']>,
  sendMail?: Maybe<Scalars['Boolean']>
};


export type CreateInvoicesByBookingIdsMutation = { createInvoicesByBookingIds: Array<Pick<Invoice, 'id' | 'bookingId'>> };

export type GetInvoicesQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetInvoicesQuery = { invoices: (
    Pick<InvoiceWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<Invoice, 'id' | 'invoiceBody' | 'invoiceFileName'>> }
  ) };

export type GetInvoiceBodyByIdQueryVariables = {
  id: Scalars['String']
};


export type GetInvoiceBodyByIdQuery = { invoiceBodyById: Pick<Invoice, 'id' | 'invoiceBody' | 'invoiceFileName'> };

export type GetInvoiceBodyByBookingIdQueryVariables = {
  bookingId: Scalars['String']
};


export type GetInvoiceBodyByBookingIdQuery = { invoiceBodyByBookingId: Pick<Invoice, 'id' | 'invoiceBody' | 'invoiceFileName'> };

export type GetCoursesDetailsQueryVariables = {};


export type GetCoursesDetailsQuery = { courses: Array<(
    Pick<Course, 'id'>
    & { settledBookings: Array<(
      Pick<Booking, 'id' | 'canceled' | 'bookingType'>
      & { attendee: Maybe<(
        Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>> }
      )>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'createDate' | 'invoiceNumberPrefix' | 'invoiceNumber'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>> }
    )>, unSettledBookings: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'canceled' | 'bookingType' | 'bookedBy'>
      & { attendee: Maybe<(
        Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>> }
      )>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'createDate' | 'invoiceNumberPrefix' | 'invoiceNumber'>> }
    )> }
  )> };

export type GetUnsettledBookingsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetUnsettledBookingsQuery = { getUnSettledBookings: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'noticeStaff' | 'coursePriceId' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'paymentMethodId' | 'canceled' | 'createDate' | 'updateDate' | 'waitListCount' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'bookedBy'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate' | 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'startDateTime' | 'endDateTime' | 'id' | 'locationId'>
        & { course: (
          Pick<Course, 'freePlaces' | 'startDateTime' | 'id' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<(
          Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'credit'>
          & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>> }
        )>, defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'> }
      )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>> }
    )> }
  ) };

export type GetSettledBookingsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetSettledBookingsQuery = { getSettledBookings: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'noticeStaff' | 'coursePriceId' | 'grossPrice' | 'netPrice' | 'vat' | 'isSpecial' | 'paymentType' | 'paymentMethodId' | 'canceled' | 'payDate' | 'createDate' | 'updateDate' | 'waitListCount' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent' | 'bookedBy'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate' | 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'startDateTime' | 'endDateTime' | 'id' | 'locationId'>
        & { course: (
          Pick<Course, 'freePlaces' | 'startDateTime' | 'id' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'birthday' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<(
          Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'credit'>
          & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>> }
        )>, defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'> }
      )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>> }
    )> }
  ) };

export type GetCanceledBookingsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetCanceledBookingsQuery = { getCanceledBookings: (
    Pick<BookingWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'noticeStaff' | 'coursePriceId' | 'grossPrice' | 'netPrice' | 'paymentType' | 'paymentMethodId' | 'canceled' | 'payDate' | 'createDate' | 'updateDate' | 'waitListCount' | 'bookingConfirmationSent' | 'waitingListConfirmationSent' | 'cancelationSent' | 'invoiceSent'>
      & { coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat'>, invoice: Maybe<Pick<Invoice, 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate' | 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate'>>, courseLessons: Array<(
        Pick<CourseLesson, 'startDateTime' | 'endDateTime' | 'id' | 'locationId'>
        & { course: (
          Pick<Course, 'freePlaces' | 'startDateTime' | 'id' | 'prefixedCourseNumber' | 'allowMonthlyPayment'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'gender' | 'birthday' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber'>
        & { booker: Maybe<(
          Pick<Booker, 'id' | 'firstname' | 'lastname' | 'customerNumberPrefix' | 'customerNumber' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email'>
          & { paymentMethods: Maybe<Array<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>> }
        )>, defaultPaymentMethod: Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'> }
      )>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type' | 'cash' | 'esr' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer'>>, emailNotifications: Maybe<Array<Pick<EmailNotification, 'id' | 'templateType' | 'subject' | 'createDate' | 'bookingId' | 'email'>>> }
    )> }
  ) };

export type GetBookersByCourseWithBookingTypeQueryVariables = {
  courseId: Scalars['String']
};


export type GetBookersByCourseWithBookingTypeQuery = { getBookersByCourseWithBookingType: Array<(
    Pick<Booker, 'id' | 'customerNumberPrefix' | 'customerNumber' | 'firstname' | 'lastname' | 'email' | 'gender' | 'phoneNumber'>
    & { attendees: Maybe<Array<Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday'>>> }
  )> };

export type GetInvoicePreviewQueryVariables = {
  invoiceId: Scalars['String']
};


export type GetInvoicePreviewQuery = Pick<Query, 'getInvoicePreview'>;

export type GetInvoiceDummyPreviewQueryVariables = {
  editTemplateInput?: Maybe<EditTemplateInput>,
  reversal?: Maybe<Scalars['Boolean']>,
  paymentType?: Maybe<PaymentType>,
  monthlySchedule?: Maybe<MonthlySchedule>
};


export type GetInvoiceDummyPreviewQuery = Pick<Query, 'getInvoiceDummyPreview'>;

export type SendBookingInvoiceMutationVariables = {
  bookingId: Scalars['ID'],
  reversal?: Maybe<Scalars['Boolean']>
};


export type SendBookingInvoiceMutation = Pick<Mutation, 'sendBookingInvoice'>;

export type SendBookingInvoicesByBookingIdsMutationVariables = {
  bookingIds: Array<Scalars['ID']>,
  reversal?: Maybe<Scalars['Boolean']>
};


export type SendBookingInvoicesByBookingIdsMutation = Pick<Mutation, 'sendBookingInvoicesByBookingIds'>;

export type CreateCorrectionInvoiceMutationVariables = {
  bookingId: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type CreateCorrectionInvoiceMutation = { createCorrectionInvoice: Pick<Invoice, 'id' | 'invoiceFileName'> };

export type GetReversalInvoicesQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetReversalInvoicesQuery = { reversalInvoices: (
    Pick<ReversalInvoiceWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<ReversalInvoice, 'id' | 'reversalInvoiceBody' | 'reversalInvoiceFileName'>> }
  ) };

export type GetReversalInvoiceBodyByIdQueryVariables = {
  id: Scalars['String']
};


export type GetReversalInvoiceBodyByIdQuery = { reversalInvoiceBodyById: Pick<ReversalInvoice, 'id' | 'reversalInvoiceBody' | 'reversalInvoiceFileName'> };

export type CancelBookingAndGenerateReversalInvoiceMutationVariables = {
  id: Scalars['String'],
  sendMail?: Maybe<Scalars['Boolean']>
};


export type CancelBookingAndGenerateReversalInvoiceMutation = Pick<Mutation, 'cancelBookingAndGenerateReversalInvoice'>;

export type GetCourseLessonsByDateQueryVariables = {
  date: Scalars['DateTime']
};


export type GetCourseLessonsByDateQuery = { courseLessonsByDate: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse'>
    & { course: (
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> }
    ), location: Pick<Location, 'id' | 'city' | 'name' | 'abbreviation'> }
  )> };

export type GetCourseLessonsWithPaginationQueryVariables = {
  date?: Maybe<Scalars['DateTime']>,
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetCourseLessonsWithPaginationQuery = { courseLessonsWithPagination: (
    Pick<CourseLessonWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse'>
      & { course: (
        Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCount'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
      ), location: Pick<Location, 'id' | 'city' | 'name' | 'abbreviation'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> }
    )> }
  ) };

export type GetCourseLessonsForMonthViewQueryVariables = {
  month: Scalars['Float'],
  year: Scalars['Float'],
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetCourseLessonsForMonthViewQuery = { courseLessonsForMonthView: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime'>
    & { course: (
      Pick<Course, 'id' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'color'> }
    ) }
  )> };

export type GetCourseLessonsByCalendarWeekQueryVariables = {
  calendarWeek: Scalars['Float'],
  year: Scalars['Float'],
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetCourseLessonsByCalendarWeekQuery = { courseLessonsByCalendarWeek: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse'>
    & { course: (
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
    ), location: Pick<Location, 'id' | 'city' | 'name' | 'abbreviation'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> }
  )> };

export type GetCourseLessonsByCourseIdAttendanceQueryVariables = {
  courseId: Scalars['ID']
};


export type GetCourseLessonsByCourseIdAttendanceQuery = { courseLessonsByCourseId: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse'>
    & { course: (
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> }
    ), location: Pick<Location, 'id' | 'city' | 'name' | 'abbreviation'> }
  )> };

export type GetCourseLessonsWithCollitionsQueryVariables = {
  collitionCheckInput: CollitionCheckInput
};


export type GetCourseLessonsWithCollitionsQuery = { courseLessonsWithCollitions: Array<(
    Pick<CourseLessonWithIndexOfCourseLessonInCourse, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse' | 'originalCourseLessonId'>
    & { course: Pick<Course, 'id' | 'prefixedCourseNumber'> }
  )> };

export type GetMultipleCourseLessonsCollisionCheckQueryVariables = {
  collitionCheckInput: Array<CollitionCheckInput>
};


export type GetMultipleCourseLessonsCollisionCheckQuery = { multipleCourseLessonsCollisionCheck: Array<(
    Pick<CourseLessonWithIndexOfCourseLessonInCourse, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse' | 'originalCourseLessonId'>
    & { course: Pick<Course, 'id' | 'prefixedCourseNumber'> }
  )> };

export type GetCourseLessonByIdQueryVariables = {
  id: Scalars['String']
};


export type GetCourseLessonByIdQuery = { courseLessonById: (
    Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime' | 'indexOfLessonInCourse'>
    & { course: (
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'freePlaces' | 'waitListCount' | 'bookingCount' | 'placeReservationsCount' | 'lessonCount' | 'maxAttendees' | 'maxWaitlist' | 'startDateTime' | 'endDateTime'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'color'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname' | 'level'>> }
    ), location: Pick<Location, 'id' | 'city' | 'name' | 'abbreviation'> }
  ) };

export type GetLocationsQueryVariables = {};


export type GetLocationsQuery = { locations: Array<(
    Pick<Location, 'id' | 'abbreviation' | 'city' | 'name'>
    & { venues: Array<Pick<Venue, 'id' | 'name'>>, generalLocationContact: Pick<GeneralLocationContact, 'email' | 'phoneNumber'>, courses: Maybe<Array<Pick<Course, 'id' | 'prefixedCourseNumber'>>> }
  )> };

export type GetLocationsWithPaginationQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  currentUserOnly?: Maybe<Scalars['Boolean']>
};


export type GetLocationsWithPaginationQuery = { locationsWithPagination: (
    Pick<LocationsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Location, 'id' | 'abbreviation' | 'city' | 'name'>
      & { venues: Array<Pick<Venue, 'id' | 'name'>>, generalLocationContact: Pick<GeneralLocationContact, 'email' | 'phoneNumber'>, courses: Maybe<Array<Pick<Course, 'id' | 'prefixedCourseNumber'>>>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
    )> }
  ) };

export type CreateLocationMutationVariables = {
  newLocationData: NewLocationInput
};


export type CreateLocationMutation = { createLocation: (
    Pick<Location, 'id' | 'name' | 'isPublished' | 'zipCode' | 'city' | 'street' | 'website' | 'abbreviation' | 'info'>
    & { venues: Array<Pick<Venue, 'id' | 'name' | 'isActive' | 'rent' | 'rentType'>>, generalLocationContact: Pick<GeneralLocationContact, 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'>, locationContacts: Array<Pick<LocationContact, 'id' | 'firstname' | 'lastname' | 'phoneNumber' | 'mobileNumber' | 'fax' | 'email'>> }
  ) };

export type GetLocationByIdQueryVariables = {
  id: Scalars['ID']
};


export type GetLocationByIdQuery = { locationById: (
    Pick<Location, 'id' | 'name' | 'isPublished' | 'zipCode' | 'city' | 'street' | 'website' | 'abbreviation' | 'info' | 'customFields'>
    & { venues: Array<Pick<Venue, 'id' | 'name' | 'isActive' | 'rent' | 'rentType'>>, generalLocationContact: Pick<GeneralLocationContact, 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'>, locationContacts: Array<Pick<LocationContact, 'id' | 'salutation' | 'firstname' | 'lastname' | 'department' | 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'>>, courses: Maybe<Array<Pick<Course, 'id' | 'locationId' | 'venueId' | 'courseNumber'>>> }
  ) };

export type UpdateLocationMutationVariables = {
  editLocationData: EditLocationInput
};


export type UpdateLocationMutation = { updateLocation: (
    Pick<Location, 'id' | 'name' | 'isPublished' | 'zipCode' | 'city' | 'street' | 'website' | 'abbreviation' | 'info'>
    & { venues: Array<Pick<Venue, 'id' | 'name' | 'isActive' | 'rent' | 'rentType'>>, generalLocationContact: Pick<GeneralLocationContact, 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'>, locationContacts: Array<Pick<LocationContact, 'id' | 'salutation' | 'firstname' | 'lastname' | 'department' | 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'>> }
  ) };

export type RemoveLocationByIdMutationVariables = {
  id: Scalars['ID']
};


export type RemoveLocationByIdMutation = Pick<Mutation, 'removeLocation'>;

export type CreateLocationContactMutationVariables = {
  newLocationContactData: NewLocationContactInput
};


export type CreateLocationContactMutation = { createLocationContact: Pick<LocationContact, 'id' | 'salutation' | 'firstname' | 'lastname' | 'department' | 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'> };

export type UpdateLocationContactMutationVariables = {
  editLocationContactData: EditLocationContactInput
};


export type UpdateLocationContactMutation = { updateLocationContact: Pick<LocationContact, 'id' | 'salutation' | 'firstname' | 'lastname' | 'department' | 'phoneNumber' | 'mobileNumber' | 'fax' | 'email' | 'note'> };

export type GetLocationContactsQueryVariables = {};


export type GetLocationContactsQuery = { locationContacts: Array<Pick<LocationContact, 'id' | 'salutation' | 'firstname' | 'lastname' | 'department' | 'phoneNumber' | 'mobileNumber' | 'email' | 'fax' | 'note'>> };

export type GetLocationsByLocationContactIdQueryVariables = {
  id: Scalars['ID']
};


export type GetLocationsByLocationContactIdQuery = { locationsByLocationContactId: Array<Pick<Location, 'name'>> };

export type RemoveLocationContactMutationVariables = {
  id: Scalars['ID']
};


export type RemoveLocationContactMutation = Pick<Mutation, 'removeLocationContact'>;

export type GetLocationsPublicQueryVariables = {};


export type GetLocationsPublicQuery = { locationsPublic: Array<(
    Pick<Location, 'id' | 'abbreviation' | 'city' | 'name'>
    & { generalLocationContact: Pick<GeneralLocationContact, 'email' | 'phoneNumber'> }
  )> };

export type PasswordlessLoginMutationVariables = {
  loginData: PasswordlessLoginInputType
};


export type PasswordlessLoginMutation = Pick<Mutation, 'passwordlessLogin'>;

export type CompletePasswordlessFlowMutationVariables = {
  loginCode: Scalars['String']
};


export type CompletePasswordlessFlowMutation = { completePasswordlessFlow: Pick<UserType, 'id' | 'roles' | 'email'> };

export type ChangeForgottenPasswordMutationVariables = {
  changePasswordId: Scalars['String'],
  newPassword: Scalars['String']
};


export type ChangeForgottenPasswordMutation = Pick<Mutation, 'changeForgottenPassword'>;

export type LogoutMutationVariables = {};


export type LogoutMutation = Pick<Mutation, 'logoutUser'>;

export type GetEmailNotificationsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetEmailNotificationsQuery = { emailNotifications: (
    Pick<EmailNotificationWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<EmailNotification, 'id' | 'createDate' | 'status' | 'email' | 'receiverName' | 'subject' | 'templateType' | 'mailjetTemplateId' | 'messageHref' | 'text' | 'messageID' | 'templateName' | 'bookerId' | 'bookingId' | 'attachments' | 'bookingDeleted'>
      & { booking: Maybe<(
        Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'canceled'>
        & { invoice: Maybe<Pick<Invoice, 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id'>> }
      )>, messageHistory: Maybe<(
        Pick<MessageHistory, 'Count'>
        & { Data: Array<Pick<MessageHistoryData, 'EventAt' | 'EventType' | 'State'>> }
      )>, messageStatus: Maybe<Pick<MessageStatusData, 'ArrivedAt' | 'Status'>>, lastMessageStatus: Maybe<{ Data: Array<Pick<MessageStatusData, 'ArrivedAt' | 'Status'>> }> }
    )> }
  ) };

export type GetEmailNotificationsByBookingIdQueryVariables = {
  bookingId: Scalars['ID']
};


export type GetEmailNotificationsByBookingIdQuery = { notificationsByBooking: Array<(
    Pick<EmailNotification, 'id' | 'createDate' | 'status' | 'email' | 'receiverName' | 'subject' | 'templateType' | 'mailjetTemplateId' | 'messageHref' | 'text' | 'messageID' | 'templateName' | 'attachments'>
    & { messageHistory: Maybe<(
      Pick<MessageHistory, 'Count'>
      & { Data: Array<Pick<MessageHistoryData, 'EventAt' | 'EventType' | 'State'>> }
    )>, messageStatus: Maybe<Pick<MessageStatusData, 'ArrivedAt' | 'Status'>> }
  )> };

export type GetEmailNotificationsByBookingIdOrEmailQueryVariables = {
  bookerId: Scalars['ID'],
  email?: Maybe<Scalars['String']>,
  options?: Maybe<ArrayDataOptions>
};


export type GetEmailNotificationsByBookingIdOrEmailQuery = { notificationsByBookerOrEmail: (
    Pick<EmailNotificationWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<EmailNotification, 'id' | 'createDate' | 'status' | 'email' | 'receiverName' | 'subject' | 'templateType' | 'mailjetTemplateId' | 'messageHref' | 'text' | 'messageID' | 'templateName' | 'bookerId' | 'bookingId' | 'attachments'>
      & { booking: Maybe<(
        Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'bookingType' | 'canceled'>
        & { invoice: Maybe<Pick<Invoice, 'id'>>, reversalInvoice: Maybe<Pick<ReversalInvoice, 'id'>> }
      )>, messageHistory: Maybe<(
        Pick<MessageHistory, 'Count'>
        & { Data: Array<Pick<MessageHistoryData, 'EventAt' | 'EventType' | 'State'>> }
      )>, messageStatus: Maybe<Pick<MessageStatusData, 'ArrivedAt' | 'Status'>> }
    )> }
  ) };

export type GetSentEmailStatusFromMailjetQueryVariables = {
  messageID: Scalars['String']
};


export type GetSentEmailStatusFromMailjetQuery = Pick<Query, 'sentEmailStatusFromMailjet'>;

export type GetBookerIdMissmatchQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetBookerIdMissmatchQuery = { getBookerIdMissmatchesWithPagination: (
    Pick<BookerIdMissmatchWithPagination, 'total' | 'existsMore'>
    & { items: Array<(
      Pick<BookerIdMissmatch, 'id' | 'email' | 'receiverName' | 'createDate' | 'subject' | 'templateName' | 'bookerId' | 'bookingId'>
      & { currentBooker: Maybe<Pick<Booker, 'firstname' | 'lastname' | 'email' | 'id'>>, foundByMailBooker: Maybe<Pick<Booker, 'firstname' | 'lastname' | 'email' | 'id'>> }
    )> }
  ) };

export type UpdateBookerIdMissmatchMutationVariables = {
  notificationId: Scalars['ID'],
  oldBookerId?: Maybe<Scalars['ID']>,
  newBookerId: Scalars['ID']
};


export type UpdateBookerIdMissmatchMutation = { updateBookerIdMissmatch: Pick<EmailNotification, 'id' | 'email' | 'receiverName' | 'createDate' | 'subject' | 'templateName' | 'bookerId' | 'bookingId'> };

export type UpdateMultipleBookerIdMissmatchesMutationVariables = {
  updateMultipleBookerIdMissmatchesInput: Array<UpdateBookerIdMissmatchesInput>
};


export type UpdateMultipleBookerIdMissmatchesMutation = { updateMultipleBookerIdMissmatches: Array<Pick<EmailNotification, 'id' | 'email' | 'receiverName' | 'createDate' | 'subject' | 'templateName' | 'bookerId' | 'bookingId'>> };

export type CreateParticipationPrerequisiteMutationVariables = {
  newParticipationPrerequisitesInput: NewParticipationPrerequisitesInput
};


export type CreateParticipationPrerequisiteMutation = { createParticipationPrerequisite: Pick<ParticipationPrerequisites, 'id' | 'enabled' | 'groupDescription' | 'groupLabel' | 'minCheckCount' | 'optionalInputFields' | 'courseTypeId'> };

export type UpdateParticipationPrerequisiteMutationVariables = {
  updateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput
};


export type UpdateParticipationPrerequisiteMutation = { updateParticipationPrerequisite: Pick<ParticipationPrerequisites, 'id' | 'enabled' | 'groupDescription' | 'groupLabel' | 'minCheckCount' | 'optionalInputFields' | 'courseTypeId'> };

export type RemoveParticipationPrerequisiteMutationVariables = {
  id: Scalars['ID']
};


export type RemoveParticipationPrerequisiteMutation = Pick<Mutation, 'removeParticipationPrerequisite'>;

export type GetParticipationPrerequisitesByCourseTypeIdQueryVariables = {
  courseTypeId: Scalars['ID']
};


export type GetParticipationPrerequisitesByCourseTypeIdQuery = { participationPrerequisitesByCourseTypeId: Array<Pick<ParticipationPrerequisites, 'id' | 'enabled' | 'groupDescription' | 'groupLabel' | 'minCheckCount' | 'optionalInputFields' | 'courseTypeId'>> };

export type GetExportedSepaXmlsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetExportedSepaXmlsQuery = { exportedSepaXmls: (
    Pick<ExportedSepaXmlWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<ExportedSepaXml, 'id' | 'object' | 'xml' | 'createDate' | 'createdByUserId' | 'updateDate' | 'updatedByUserId' | 'fileName'>> }
  ) };

export type GetExportedSepaXmlQueryVariables = {
  id: Scalars['String']
};


export type GetExportedSepaXmlQuery = { exportedSepaXml: Pick<ExportedSepaXml, 'id' | 'object' | 'xml' | 'createDate' | 'createdByUserId' | 'updateDate' | 'updatedByUserId' | 'fileName'> };

export type GetBilledBookingsQueryVariables = {
  courseType: Scalars['Boolean'],
  courseNumber: Scalars['Boolean'],
  booker: Scalars['Boolean'],
  attendee: Scalars['Boolean'],
  location: Scalars['Boolean'],
  firstCourse: Scalars['Boolean'],
  paymentType: Scalars['Boolean'],
  amount: Scalars['Boolean'],
  paymentDate: Scalars['Boolean'],
  invoice: Scalars['Boolean'],
  due: Scalars['Boolean'],
  discount: Scalars['Boolean']
};


export type GetBilledBookingsQuery = { getBilledBookings: Array<(
    Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'paymentType'>
    & { courseLessons: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
      & { course: (
        Pick<Course, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
      ) }
    )>, attendee: Maybe<(
      Pick<Attendee, 'firstname' | 'lastname' | 'id'>
      & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'city'>> }
    )>, coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'discount'>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'paymentDue' | 'invoiceNumber' | 'invoiceNumberPrefix'>> }
  )> };

export type GetUnBilledBookingsQueryVariables = {
  courseType: Scalars['Boolean'],
  courseNumber: Scalars['Boolean'],
  booker: Scalars['Boolean'],
  attendee: Scalars['Boolean'],
  location: Scalars['Boolean'],
  firstCourse: Scalars['Boolean'],
  paymentType: Scalars['Boolean'],
  amount: Scalars['Boolean'],
  paymentDate: Scalars['Boolean'],
  invoice: Scalars['Boolean'],
  due: Scalars['Boolean'],
  discount: Scalars['Boolean'],
  place: Scalars['Boolean'],
  invoiceDate: Scalars['Boolean']
};


export type GetUnBilledBookingsQuery = { getUnBilledBookings: Array<(
    Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix' | 'paymentType' | 'payDate'>
    & { courseLessons: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
      & { location: Pick<Location, 'id' | 'city'>, course: (
        Pick<Course, 'id' | 'prefixedCourseNumber'>
        & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
      ) }
    )>, attendee: Maybe<(
      Pick<Attendee, 'firstname' | 'lastname' | 'id'>
      & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'city'>> }
    )>, coursePrice: Pick<CoursePrice, 'id' | 'grossPrice' | 'discount'>, paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'type'>>, invoice: Maybe<Pick<Invoice, 'id' | 'billed' | 'paymentDue' | 'invoiceNumber' | 'invoiceNumberPrefix'>> }
  )> };

export type GetUnBilledBookingsMinimalQueryVariables = {};


export type GetUnBilledBookingsMinimalQuery = { getUnBilledBookings: Array<Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix'>> };

export type GetBilledBookingsMinimalQueryVariables = {};


export type GetBilledBookingsMinimalQuery = { getBilledBookings: Array<Pick<Booking, 'id' | 'bookingNumber' | 'bookingNumberPrefix'>> };

export type UpdateInvoiceMutationVariables = {
  id: Scalars['String'],
  editInvoiceData: EditInvoiceInput
};


export type UpdateInvoiceMutation = { updateInvoice: Pick<Invoice, 'id'> };

export type BillMultipleBookingsMutationVariables = {
  ids: Array<Scalars['ID']>,
  billDate?: Maybe<Scalars['DateTime']>
};


export type BillMultipleBookingsMutation = { billMultipleBookings: Array<Pick<Booking, 'id'>> };

export type UnBillMultipleBookingsMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type UnBillMultipleBookingsMutation = { unBillMultipleBookings: Array<Pick<Booking, 'id'>> };

export type GetScheduledPaymentsQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetScheduledPaymentsQuery = { scheduledPayments: (
    Pick<ScheduledPaymentsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'createDate' | 'paymentDone' | 'paymentTransferType' | 'paymentMethodId' | 'title' | 'type'>
      & { invoice: Maybe<Pick<Invoice, 'billed' | 'invoiceNumber' | 'invoiceNumberPrefix' | 'createDate' | 'updateDate' | 'id'>>, booking: (
        Pick<Booking, 'id' | 'courseId' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'isSpecial' | 'vat' | 'netPrice' | 'grossPrice' | 'bookedBy' | 'paymentType' | 'canceled' | 'noticeStaff'>
        & { paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>, attendee: Maybe<(
          Pick<Attendee, 'id' | 'firstname' | 'lastname'>
          & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
        )>, courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
          & { course: (
            Pick<Course, 'id' | 'startDateTime' | 'paymentInterval' | 'prefixedCourseNumber'>
            & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
          ), location: Pick<Location, 'id' | 'city' | 'name'> }
        )> }
      ), paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>> }
    )> }
  ) };

export type GetUnpaidScheduledPaymentsQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  bookingId?: Maybe<Scalars['ID']>
};


export type GetUnpaidScheduledPaymentsQuery = { unpaidScheduledPayments: (
    Pick<ScheduledPaymentsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'createDate' | 'paymentDone' | 'sepaClearance' | 'paymentTransferType' | 'paymentMethodId' | 'title' | 'type'>
      & { invoice: Maybe<Pick<Invoice, 'id' | 'createDate' | 'updateDate' | 'invoiceNumber' | 'invoiceNumberPrefix'>>, booking: (
        Pick<Booking, 'id' | 'courseId' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'isSpecial' | 'vat' | 'netPrice' | 'grossPrice' | 'bookedBy' | 'paymentType' | 'canceled' | 'noticeStaff'>
        & { paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>, attendee: Maybe<(
          Pick<Attendee, 'id' | 'firstname' | 'lastname'>
          & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
        )>, courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
          & { course: (
            Pick<Course, 'id' | 'startDateTime' | 'paymentInterval' | 'prefixedCourseNumber'>
            & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
          ), location: Pick<Location, 'id' | 'city' | 'name'> }
        )> }
      ), paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>> }
    )> }
  ) };

export type GetUnpaidScheduledPaymentsZeroValuesQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetUnpaidScheduledPaymentsZeroValuesQuery = { unpaidScheduledPaymentsZeroValues: Array<(
    Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'createDate' | 'paymentDone' | 'sepaClearance' | 'paymentTransferType' | 'paymentMethodId' | 'title' | 'type'>
    & { invoice: Maybe<Pick<Invoice, 'id' | 'createDate' | 'updateDate' | 'invoiceNumber' | 'invoiceNumberPrefix'>>, booking: (
      Pick<Booking, 'id' | 'courseId' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'isSpecial' | 'vat' | 'netPrice' | 'grossPrice' | 'bookedBy' | 'paymentType' | 'canceled' | 'noticeStaff'>
      & { paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>, attendee: Maybe<(
        Pick<Attendee, 'id' | 'firstname' | 'lastname'>
        & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
      )>, courseLessons: Array<(
        Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
        & { course: (
          Pick<Course, 'id' | 'startDateTime' | 'paymentInterval' | 'prefixedCourseNumber'>
          & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
        ), location: Pick<Location, 'id' | 'city' | 'name'> }
      )> }
    ), paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>> }
  )> };

export type GetPaidScheduledPaymentsQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  bookingId?: Maybe<Scalars['ID']>
};


export type GetPaidScheduledPaymentsQuery = { paidScheduledPayments: (
    Pick<ScheduledPaymentsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'createDate' | 'paymentDone' | 'sepaClearance' | 'paymentTransferType' | 'paymentMethodId' | 'title' | 'type'>
      & { invoice: Maybe<Pick<Invoice, 'id' | 'createDate' | 'updateDate' | 'invoiceNumber' | 'invoiceNumberPrefix'>>, booking: (
        Pick<Booking, 'id' | 'courseId' | 'bookingNumberPrefix' | 'bookingNumber' | 'bookingType' | 'isSpecial' | 'vat' | 'netPrice' | 'grossPrice' | 'bookedBy' | 'paymentType' | 'canceled' | 'noticeStaff'>
        & { paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>>, attendee: Maybe<(
          Pick<Attendee, 'id' | 'firstname' | 'lastname'>
          & { booker: Maybe<Pick<Booker, 'id' | 'firstname' | 'lastname' | 'email'>> }
        )>, courseLessons: Array<(
          Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
          & { course: (
            Pick<Course, 'id' | 'startDateTime' | 'paymentInterval' | 'prefixedCourseNumber'>
            & { courseType: Pick<CourseType, 'id' | 'name' | 'color'> }
          ), location: Pick<Location, 'id' | 'city' | 'name'> }
        )> }
      ), paymentMethod: Maybe<Pick<PaymentMethod, 'id' | 'cash' | 'esr' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'type' | 'bankTransfer'>> }
    )> }
  ) };

export type GetScheduledPaymentsSummaryQueryVariables = {
  paymentDone: Scalars['Boolean'],
  options?: Maybe<ArrayDataOptions>
};


export type GetScheduledPaymentsSummaryQuery = { scheduledPaymentSummary: { filtered: Pick<ScheduledPaymentSummaryPart, 'count' | 'sum'>, total: Pick<ScheduledPaymentSummaryPart, 'count' | 'sum'> } };

export type GetScheduledPaymentsMinQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetScheduledPaymentsMinQuery = { scheduledPayments: (
    Pick<ScheduledPaymentsWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'paymentTransferType' | 'title'>> }
  ) };

export type GetScheduledPaymentByIdQueryVariables = {
  id: Scalars['ID']
};


export type GetScheduledPaymentByIdQuery = { scheduledPaymentById: Pick<ScheduledPayment, 'id'> };

export type DeleteScheduledPaymentMutationVariables = {
  id: Scalars['String']
};


export type DeleteScheduledPaymentMutation = { deleteScheduledPayment: Pick<ScheduledPayment, 'id'> };

export type UpdateScheduledPaymentMutationVariables = {
  editScheduledPaymentData: EditScheduledPaymentInput,
  id: Scalars['String']
};


export type UpdateScheduledPaymentMutation = { updateScheduledPayment: Pick<ScheduledPayment, 'id' | 'paymentDone'> };

export type UpdateManyScheduledPaymentIdsMutationVariables = {
  paymentIds: Array<Scalars['ID']>,
  editScheduledPaymentData: EditScheduledPaymentInput
};


export type UpdateManyScheduledPaymentIdsMutation = { updateManyScheduledPaymentIds: Pick<ScheduledPayment, 'id' | 'paymentDone'> };

export type SetMultipleScheduledPaymentsPaidMutationVariables = {
  paymentIds: Array<Scalars['ID']>,
  paymentDone: Scalars['DateTime']
};


export type SetMultipleScheduledPaymentsPaidMutation = Pick<Mutation, 'setMultipleScheduledPaymentsPaid'>;

export type SetSepaClearanceMutationVariables = {
  id: Scalars['ID'],
  sepaClearance: Scalars['Boolean']
};


export type SetSepaClearanceMutation = { setSepaClearance: Pick<ScheduledPayment, 'id' | 'sepaClearance'> };

export type GetScheduledPaymentsByBookingIdQueryVariables = {
  bookingId: Scalars['ID']
};


export type GetScheduledPaymentsByBookingIdQuery = { scheduledPaymentsByBookingId: Array<(
    Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'createDate' | 'paymentDone' | 'paymentTransferType' | 'title'>
    & { invoice: Maybe<Pick<Invoice, 'id' | 'createDate' | 'invoiceNumber' | 'invoiceNumberPrefix'>> }
  )> };

export type GeneratePaymentSchedulePreviewMutationVariables = {
  lessonDates: Array<Scalars['DateTime']>,
  bookingPrice: Scalars['Float'],
  monthlySchedule: MonthlySchedule,
  paymentType: PaymentType,
  courseId?: Maybe<Scalars['ID']>
};


export type GeneratePaymentSchedulePreviewMutation = Pick<Mutation, 'generatePaymentSchedulePreview'>;

export type GenerateUpdatedSchedulesNewBookingPriceMutationVariables = {
  scheduledPayments: Array<UpdateScheduledPaymentByBookingInput>,
  bookingPrice: Scalars['Float']
};


export type GenerateUpdatedSchedulesNewBookingPriceMutation = { generateUpdatedSchedulesNewBookingPrice: Array<Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'openAmount' | 'paymentDue'>> };

export type ResetScheduledPaymentsOfBookingMutationVariables = {
  bookingId: Scalars['ID'],
  preview?: Maybe<Scalars['Boolean']>
};


export type ResetScheduledPaymentsOfBookingMutation = { resetScheduledPaymentsOfBooking: (
    Pick<ResetScheduledPaymentsOfBookingResult, 'bookingId' | 'bookingNumber' | 'bookingPrice' | 'coursePrice' | 'creditedBalance' | 'currentScheduledPaymentsCount' | 'newScheduledPaymentsCount' | 'currentScheduledPaymnetsCount'>
    & { installmentPlanAmountsAndDueDates: Array<(
      Pick<Rate, 'key'>
      & { value: Pick<RateDetail, 'due' | 'amount'> }
    )>, newScheduledPaymentsAmountsAndDueDates: Array<(
      Pick<Rate, 'key'>
      & { value: Pick<RateDetail, 'due' | 'amount'> }
    )>, currentScheduledPaymentsAmountsAndDueDates: Array<(
      Pick<Rate, 'key'>
      & { value: Pick<RateDetail, 'due' | 'amount'> }
    )> }
  ) };

export type ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables = {
  courseId: Scalars['ID'],
  preview?: Maybe<Scalars['Boolean']>
};


export type ResetAllUnsetteledScheduledPaymentsOfCourseMutation = { resetAllUnsetteledScheduledPaymentsOfCourse: (
    Pick<ResetScheduledPaymentsOfCourseResult, 'courseId' | 'coursePrice'>
    & { courseDueDates: Array<(
      Pick<Rate, 'key'>
      & { value: Pick<RateDetail, 'due' | 'amount'> }
    )>, bookings: Array<(
      Pick<ResetScheduledPaymentsOfBookingResult, 'bookingId' | 'bookingNumber' | 'bookingPrice' | 'creditedBalance' | 'currentScheduledPaymentsCount' | 'newScheduledPaymentsCount' | 'currentScheduledPaymnetsCount'>
      & { newScheduledPaymentsAmountsAndDueDates: Array<(
        Pick<Rate, 'key'>
        & { value: Pick<RateDetail, 'due' | 'amount'> }
      )>, currentScheduledPaymentsAmountsAndDueDates: Array<(
        Pick<Rate, 'key'>
        & { value: Pick<RateDetail, 'due' | 'amount'> }
      )> }
    )> }
  ) };

export type ScheduledPaymentsByBookerIdQueryVariables = {
  bookerId: Scalars['ID']
};


export type ScheduledPaymentsByBookerIdQuery = { scheduledPaymentsByBookerId: Array<Pick<ScheduledPayment, 'id' | 'paymentDue' | 'paymentAmount' | 'openAmount' | 'paymentDone' | 'createDate' | 'sepaClearance' | 'paymentMethodId'>> };

export type SetScheduledPaymentPaidMutationVariables = {
  id: Scalars['String'],
  paymentDone: Scalars['DateTime']
};


export type SetScheduledPaymentPaidMutation = { setScheduledPaymentPaid: Pick<ScheduledPayment, 'id' | 'paymentDone'> };

export type SetScheduledPaymentUnpaidMutationVariables = {
  id: Scalars['String']
};


export type SetScheduledPaymentUnpaidMutation = { setScheduledPaymentUnpaid: Pick<ScheduledPayment, 'id' | 'paymentDone'> };

export type SetNewPaymentDoneDateMutationVariables = {
  id: Scalars['String'],
  paymentDoneDate: Scalars['DateTime']
};


export type SetNewPaymentDoneDateMutation = { setNewPaymentDoneDate: Pick<ScheduledPayment, 'id' | 'paymentDone'> };

export type SetNewPaymentAmountMutationVariables = {
  id: Scalars['String'],
  paymentAmount: Scalars['Float']
};


export type SetNewPaymentAmountMutation = { setNewPaymentAmount: Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'openAmount'> };

export type ModifyScheduledPaymentMutationVariables = {
  modifyScheduledPaymentInput: ModifyScheduledPaymentInput
};


export type ModifyScheduledPaymentMutation = { modifyScheduledPayment: Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'openAmount' | 'paymentDue' | 'paymentDone' | 'paymentMethodId' | 'sepaClearance'> };

export type GenerateScheduledPaymentsForSettledBookingMutationVariables = {
  bookingId: Scalars['ID'],
  savePayments?: Maybe<Scalars['Boolean']>
};


export type GenerateScheduledPaymentsForSettledBookingMutation = { generateScheduledPaymentsForSettledBooking: Array<Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'openAmount' | 'paymentDue' | 'paymentDone' | 'paymentMethodId' | 'sepaClearance'>> };

export type AddNamedScheduledPaymentMutationVariables = {
  addNamedScheduledPaymentInput: AddNamedScheduledPaymentInput
};


export type AddNamedScheduledPaymentMutation = { addNamedScheduledPayment: Pick<ScheduledPayment, 'id' | 'paymentAmount' | 'openAmount' | 'paymentDue' | 'paymentDone' | 'paymentMethodId' | 'sepaClearance'> };

export type GenerateScheduledPaymentsByCourseMutationVariables = {
  courseId: Scalars['ID'],
  attendeeId?: Maybe<Scalars['ID']>,
  omitMemberDiscount?: Maybe<Scalars['Boolean']>,
  creditedBalance?: Maybe<Scalars['Float']>,
  currentScheduledPayments?: Maybe<Array<ScheduledPaymentSimpleInput>>,
  paymentType?: Maybe<PaymentType>,
  memberDiscount?: Maybe<Scalars['Float']>
};


export type GenerateScheduledPaymentsByCourseMutation = { generateScheduledPaymentsByCourse: (
    Pick<ScheduledPaymentPreview, 'grossPrice' | 'netPrice' | 'memberDiscount'>
    & { scheduledPayments: Array<Pick<ScheduledPaymentSimple, 'id' | 'paymentAmount' | 'paymentDue' | 'type'>> }
  ) };

export type CreateSepaXmlByIdMutationVariables = {
  createSingleSepaXmlInput: CreateSingleSepaXmlInput
};


export type CreateSepaXmlByIdMutation = Pick<Mutation, 'createSepaXmlById'>;

export type CreateSepaXmlByTimeRangeMutationVariables = {
  createSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput
};


export type CreateSepaXmlByTimeRangeMutation = Pick<Mutation, 'createSepaXmlByTimeRange'>;

export type GetBankDataQueryVariables = {
  iban: Scalars['String']
};


export type GetBankDataQuery = Pick<Query, 'getBankData'>;

export type GetRestrictiveBookingsByCourseIdQueryVariables = {
  courseId: Scalars['String']
};


export type GetRestrictiveBookingsByCourseIdQuery = { getBookingRestrictionByCourseId: Maybe<(
    Pick<BookingRestriction, 'id' | 'courseId' | 'active' | 'visibleForAll' | 'blockedPlaces' | 'startDateTime' | 'endDateTime' | 'includeWaitingList' | 'includeBookingList' | 'membersOnly' | 'expireAction'>
    & { prerequisiteCourses: Maybe<Array<Pick<Course, 'id'>>> }
  )> };

export type CreateBookingRestrictionMutationVariables = {
  newBookingRestrictionInput: NewBookingRestrictionInput
};


export type CreateBookingRestrictionMutation = { createBookingRestriction: (
    Pick<BookingRestriction, 'id' | 'active' | 'visibleForAll' | 'blockedPlaces' | 'startDateTime' | 'endDateTime' | 'includeWaitingList' | 'includeBookingList' | 'membersOnly' | 'expireAction'>
    & { prerequisiteCourses: Maybe<Array<Pick<Course, 'id'>>> }
  ) };

export type UpdateBookingRestrictionMutationVariables = {
  updateBookingRestrictionInput: UpdateBookingRestrictionInput
};


export type UpdateBookingRestrictionMutation = { updateBookingRestriction: (
    Pick<BookingRestriction, 'id' | 'active' | 'visibleForAll' | 'blockedPlaces' | 'startDateTime' | 'endDateTime' | 'includeWaitingList' | 'includeBookingList' | 'membersOnly' | 'expireAction'>
    & { prerequisiteCourses: Maybe<Array<Pick<Course, 'id'>>> }
  ) };

export type DeleteBookingRestrictionMutationVariables = {
  bookingRestrictionId: Scalars['String']
};


export type DeleteBookingRestrictionMutation = Pick<Mutation, 'deleteBookingRestriction'>;

export type AddBookableCoursesMutationVariables = {
  prerequisiteCourseId: Scalars['String'],
  bookableCourseIds: Array<Scalars['String']>
};


export type AddBookableCoursesMutation = Pick<Mutation, 'addBookableCourses'>;

export type RemoveBookableCourseMutationVariables = {
  bookableCourseId: Scalars['String'],
  prerquisiteCourseId: Scalars['String']
};


export type RemoveBookableCourseMutation = Pick<Mutation, 'removeBookableCourse'>;

export type ImportBookersMutationVariables = {
  importBookersInput: ImportBookersInput
};


export type ImportBookersMutation = Pick<Mutation, 'importBookers'>;

export type VerifyBookersDataMutationVariables = {
  importBookersInput: ImportBookersInput
};


export type VerifyBookersDataMutation = Pick<Mutation, 'verifyBookersData'>;

export type DeleteFusionAuthUsersByEmailsMutationVariables = {
  emailAddresses: Array<Scalars['String']>
};


export type DeleteFusionAuthUsersByEmailsMutation = Pick<Mutation, 'deleteFusionAuthUsersByEmails'>;

export type SettingEndUserAppQueryVariables = {};


export type SettingEndUserAppQuery = { settingEndUserApp: Pick<SettingEndUserApp, 'colors' | 'courseTable' | 'courseDetails' | 'filters' | 'textEntries' | 'optionalInputFields' | 'hideAfterDays' | 'passwordlessLoginEnabled' | 'passwordlessIdCodeLength'> };

export type UpdateSettingEndUserAppMutationVariables = {
  updateSettingEndUserAppInput: UpdateSettingEndUserAppInput
};


export type UpdateSettingEndUserAppMutation = { updateSettingEndUserApp: Pick<SettingEndUserApp, 'id' | 'colors' | 'courseTable' | 'courseDetails' | 'filters' | 'textEntries' | 'optionalInputFields' | 'hideAfterDays' | 'passwordlessLoginEnabled' | 'passwordlessIdCodeLength'> };

export type CreateSettingEndUserAppWithDefaultValuesMutationVariables = {};


export type CreateSettingEndUserAppWithDefaultValuesMutation = { createSettingEndUserAppWithDefaultValues: Pick<SettingEndUserApp, 'id' | 'colors' | 'courseTable' | 'courseDetails' | 'filters' | 'textEntries' | 'optionalInputFields' | 'hideAfterDays' | 'passwordlessLoginEnabled' | 'passwordlessIdCodeLength'> };

export type UploadFileMutationVariables = {
  file: Scalars['Upload']
};


export type UploadFileMutation = Pick<Mutation, 'uploadCompanyLogo'>;

export type DeleteCompanyLogoMutationVariables = {};


export type DeleteCompanyLogoMutation = { deleteCompanyLogo: Pick<CompanySetting, 'id' | 'logo'> };

export type GetAppSettingsQueryVariables = {};


export type GetAppSettingsQuery = { settings: Pick<Setting, 'id' | 'courseNumberFormat' | 'customerNumberPrefix' | 'invoiceNumberPrefix' | 'currency' | 'grossNet' | 'defaultVat' | 'cash' | 'bankTransfer' | 'sepaDebit' | 'esr' | 'invoiceDueDays' | 'firstInstallmentDayOfMonth' | 'firstInstallmentMonth' | 'vatAppearance' | 'holidayStateCode' | 'holidayStateColor' | 'sevenIoApiKey' | 'smsSenderName' | 'sendReversalInvoice'> };

export type GetAppSettingsInvoiceDueDaysQueryVariables = {};


export type GetAppSettingsInvoiceDueDaysQuery = { settings: Pick<Setting, 'id' | 'invoiceDueDays'> };

export type GetCourseNumberFormatQueryVariables = {};


export type GetCourseNumberFormatQuery = { settings: Pick<Setting, 'courseNumberFormat'> };

export type UpdateSettingsMutationVariables = {
  newSettingsData: SettingInput
};


export type UpdateSettingsMutation = { updateSettings: Pick<Setting, 'id' | 'courseNumberFormat' | 'customerNumberPrefix' | 'invoiceNumberPrefix' | 'currency' | 'grossNet' | 'defaultVat' | 'cash' | 'bankTransfer' | 'sepaDebit' | 'esr' | 'invoiceDueDays' | 'holidayStateCode' | 'holidayStateColor' | 'sendReversalInvoice'> };

export type GetCompanySettingsQueryVariables = {};


export type GetCompanySettingsQuery = { companySettings: (
    Pick<CompanySetting, 'id' | 'name' | 'businessForm' | 'website' | 'agb' | 'privacyStatement' | 'ownerFirstName' | 'ownerLastName' | 'ownerStreet' | 'ownerStreetNumber' | 'ownerZip' | 'ownerCity' | 'ownerCountry' | 'ownerPhone' | 'ownerFax' | 'ownerEmail' | 'industry' | 'localCourt' | 'commercialRegister' | 'taxId' | 'taxNumber' | 'bank' | 'bankAccountHolder' | 'IBAN' | 'BIC' | 'creditorId' | 'qrIban' | 'logo'>
    & { koOrganization: Pick<KoOrganization, 'id'> }
  ) };

export type UpdateCompanySettingsMutationVariables = {
  editCompanyData: CompanySettingInput
};


export type UpdateCompanySettingsMutation = { updateCompanySettings: Pick<CompanySetting, 'id' | 'name' | 'businessForm' | 'website' | 'agb' | 'privacyStatement' | 'ownerFirstName' | 'ownerLastName' | 'ownerStreet' | 'ownerStreetNumber' | 'ownerZip' | 'ownerCity' | 'ownerCountry' | 'ownerPhone' | 'ownerFax' | 'ownerEmail' | 'industry' | 'localCourt' | 'commercialRegister' | 'taxId' | 'taxNumber' | 'bank' | 'bankAccountHolder' | 'IBAN' | 'BIC' | 'creditorId' | 'qrIban'> };

export type CreateTenantMutationVariables = {
  koOrganizationData: KoOrganizationInputType
};


export type CreateTenantMutation = { createOrganization: Pick<KoOrganization, 'id'> };

export type CreateAndRegisterAdminUserMutationVariables = {
  userInput: UserInput,
  tenantId: Scalars['String']
};


export type CreateAndRegisterAdminUserMutation = { createAndRegisterAdmin: Pick<UserType, 'id'> };

export type GetTenantsQueryVariables = {};


export type GetTenantsQuery = { koOrganizations: Array<(
    Pick<KoOrganization, 'id' | 'name' | 'subscriptionType'>
    & { subscription: Maybe<Pick<Subscription, 'id' | 'featureSms' | 'featureStaffLogin' | 'featureRefactoredCourseDetails' | 'featureWeekPlaner' | 'featurePrerequisiteCourses' | 'featureExport' | 'holidays' | 'seatsAdmin' | 'seatsInstructor'>> }
  )> };

export type GetTenantsWithPaginationQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  koOrganizationId?: Maybe<Scalars['String']>
};


export type GetTenantsWithPaginationQuery = { tenantsWithPagination: (
    Pick<KoOrganizationWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<KoOrganization, 'id' | 'name' | 'subscriptionType'>
      & { subscription: Maybe<Pick<Subscription, 'id' | 'featureSms' | 'featureStaffLogin' | 'featureRefactoredCourseDetails' | 'featureWeekPlaner' | 'featurePrerequisiteCourses' | 'featureExport' | 'holidays' | 'seatsAdmin' | 'seatsInstructor'>> }
    )> }
  ) };

export type GetCompanyQueryVariables = {};


export type GetCompanyQuery = { companySettings: Pick<CompanySetting, 'id' | 'name'> };

export type GetCompanyInfoQueryVariables = {};


export type GetCompanyInfoQuery = { companyInfo: (
    Pick<KoOrganization, 'id' | 'name' | 'subscriptionType'>
    & { subscription: Maybe<Pick<Subscription, 'seatsAdmin' | 'seatsInstructor' | 'featureSms' | 'featureRefactoredCourseDetails' | 'featureStaffLogin' | 'featureWeekPlaner' | 'featurePrerequisiteCourses' | 'featureExport'>>, companySettings: Pick<CompanySetting, 'logo'> }
  ) };

export type GetOwnerEmailQueryVariables = {};


export type GetOwnerEmailQuery = { ownerEmail: Pick<CompanySetting, 'id' | 'ownerEmail'> };

export type GetCompanyPublicQueryVariables = {};


export type GetCompanyPublicQuery = { companyPublic: Pick<CompanySetting, 'name' | 'host' | 'website' | 'agb' | 'privacyStatement'> };

export type GetCurrencySettingQueryVariables = {};


export type GetCurrencySettingQuery = { settings: Pick<Setting, 'currency'> };

export type UpdateCompanyAgbMutationVariables = {
  agb: Scalars['String']
};


export type UpdateCompanyAgbMutation = { updateCompanyAgb: Pick<CompanySetting, 'agb'> };

export type UpdateCompanyPrivacyStatementMutationVariables = {
  privacyStatement: Scalars['String']
};


export type UpdateCompanyPrivacyStatementMutation = { updateCompanyPrivacyStatement: Pick<CompanySetting, 'privacyStatement'> };

export type UpdateSubscriptionFeaturesMutationVariables = {
  subscriptionId: Scalars['ID'],
  features: SubscriptionFeaturesInput
};


export type UpdateSubscriptionFeaturesMutation = { updateSubscriptionFeatures: Pick<Subscription, 'id' | 'featureSms' | 'featureStaffLogin' | 'featureRefactoredCourseDetails' | 'featureWeekPlaner' | 'featurePrerequisiteCourses' | 'featureExport' | 'holidays' | 'seatsAdmin' | 'seatsInstructor'> };

export type GetTenantUsersQueryVariables = {
  tenantId: Scalars['ID']
};


export type GetTenantUsersQuery = { getTenantUsers: Array<Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email' | 'roles'>> };

export type UpdateOrganizationSubscriptionTypeMutationVariables = {
  koOrganizationId: Scalars['ID'],
  subscriptionType: SubscriptionType
};


export type UpdateOrganizationSubscriptionTypeMutation = { updateOrganizationSubscriptionType: Pick<KoOrganization, 'id' | 'subscriptionType'> };

export type SaveAdminMutationVariables = {
  adminInput: SaveAdminInput
};


export type SaveAdminMutation = { saveAdmin: Pick<Admin, 'id'> };

export type CheckSmsApiKeyQueryVariables = {
  apiKey: Scalars['String']
};


export type CheckSmsApiKeyQuery = { checkSmsApiKey: Pick<SmsBalanceResponse, 'balance'> };

export type GetSmsAccountBalanceQueryVariables = {};


export type GetSmsAccountBalanceQuery = { getSmsAccountBalance: Pick<SmsBalanceResponse, 'balance'> };

export type GetSmsReceiversQueryVariables = {
  mailDataType: MailDataType,
  selectedIds: Array<Scalars['String']>
};


export type GetSmsReceiversQuery = { getSmsReceivers: Array<Pick<SmsReceiver, 'bookerId' | 'fullName' | 'mobileNumber' | 'valid' | 'prefixedBookingNumber'>> };

export type GetSmsReceiversCourseQueryVariables = {
  getReceiversForCourseInput: GetReceiversForCourseInput
};


export type GetSmsReceiversCourseQuery = { getSmsReceiversCourse: Array<Pick<SmsReceiver, 'bookerId' | 'fullName' | 'mobileNumber' | 'valid' | 'prefixedBookingNumber'>> };

export type SendSmsMutationVariables = {
  smsParams: SendSmsInput
};


export type SendSmsMutation = { sendSms: Pick<Sms77JsonResponse, 'success' | 'totalPrice' | 'balance'> };

export type StaffSaveInstructorMutationVariables = {
  saveInstructorData: SaveInstructorInput
};


export type StaffSaveInstructorMutation = { saveInstructor: (
    Pick<Instructor, 'id' | 'level' | 'salutation' | 'firstname' | 'lastname' | 'active' | 'showInWeb' | 'birthDate' | 'birthPlace' | 'annotation' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'joinDate' | 'leaveDate' | 'socialSecurityNumber' | 'healthInsuranceFund' | 'rate' | 'vat' | 'accountHolder' | 'iban' | 'bic' | 'bank' | 'taxId' | 'allowLogin'>
    & { assignedLocations: Array<Pick<Location, 'id'>> }
  ) };

export type StaffGetInstructorQueryVariables = {
  id: Scalars['String']
};


export type StaffGetInstructorQuery = { instructor: (
    Pick<Instructor, 'id' | 'userId' | 'level' | 'salutation' | 'firstname' | 'lastname' | 'active' | 'showInWeb' | 'birthDate' | 'birthPlace' | 'annotation' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'joinDate' | 'leaveDate' | 'socialSecurityNumber' | 'healthInsuranceFund' | 'rate' | 'vat' | 'accountHolder' | 'iban' | 'bic' | 'bank' | 'taxId' | 'allowLogin' | 'customFields'>
    & { assignedLocations: Array<Pick<Location, 'id'>> }
  ) };

export type StaffGetInstructorsQueryVariables = {};


export type StaffGetInstructorsQuery = { instructors: Array<(
    Pick<Instructor, 'id' | 'userId' | 'activeCourseCount' | 'level' | 'salutation' | 'firstname' | 'lastname' | 'active' | 'showInWeb' | 'birthDate' | 'birthPlace' | 'annotation' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'joinDate' | 'leaveDate' | 'socialSecurityNumber' | 'healthInsuranceFund' | 'rate' | 'vat' | 'accountHolder' | 'iban' | 'bic' | 'bank' | 'allowLogin'>
    & { assignedLocations: Array<Pick<Location, 'id' | 'name'>> }
  )> };

export type GetInstructorsByStaffIdsQueryVariables = {
  staffIds: Array<Scalars['ID']>
};


export type GetInstructorsByStaffIdsQuery = { instructorsByStaffIds: Array<(
    Pick<Instructor, 'id' | 'userId' | 'activeCourseCount' | 'level' | 'salutation' | 'firstname' | 'lastname' | 'active' | 'showInWeb' | 'birthDate' | 'birthPlace' | 'annotation' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'joinDate' | 'leaveDate' | 'socialSecurityNumber' | 'healthInsuranceFund' | 'rate' | 'vat' | 'accountHolder' | 'iban' | 'bic' | 'bank' | 'allowLogin'>
    & { assignedLocations: Array<Pick<Location, 'id' | 'name'>> }
  )> };

export type GetInstructorsWithPaginationQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetInstructorsWithPaginationQuery = { instructorsWithPagination: (
    Pick<InstructorsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Instructor, 'id' | 'userId' | 'activeCourseCount' | 'level' | 'salutation' | 'firstname' | 'lastname' | 'active' | 'showInWeb' | 'birthDate' | 'birthPlace' | 'annotation' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'joinDate' | 'leaveDate' | 'socialSecurityNumber' | 'healthInsuranceFund' | 'rate' | 'vat' | 'accountHolder' | 'iban' | 'bic' | 'bank' | 'allowLogin'>
      & { assignedLocations: Array<Pick<Location, 'id' | 'name'>> }
    )> }
  ) };

export type GetInstructorsWithPaginationInstructorQueryVariables = {
  options?: Maybe<ArrayDataOptions>,
  onlyCurrentUser?: Maybe<Scalars['Boolean']>
};


export type GetInstructorsWithPaginationInstructorQuery = { instructorsWithPagination: (
    Pick<InstructorsWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Instructor, 'id' | 'level' | 'firstname' | 'lastname' | 'active' | 'birthDate' | 'annotation' | 'phoneNumber' | 'mobileNumber' | 'email' | 'allowLogin'>
      & { assignedLocations: Array<Pick<Location, 'id' | 'name'>> }
    )> }
  ) };

export type IsInstructorQueryVariables = {};


export type IsInstructorQuery = Pick<Query, 'isInstructor'>;

export type CanBecomeInstructorQueryVariables = {
  email: Scalars['String']
};


export type CanBecomeInstructorQuery = Pick<Query, 'canBecomeInstructor'>;

export type StaffGetLocationsQueryVariables = {};


export type StaffGetLocationsQuery = { locations: Array<Pick<Location, 'id' | 'name'>> };

export type RemoveInstructorMutationVariables = {
  id: Scalars['String']
};


export type RemoveInstructorMutation = Pick<Mutation, 'removeInstructor'>;

export type GetContentOfBucketDoQueryVariables = {};


export type GetContentOfBucketDoQuery = { getContentOfBucketDo: { ContentOfBucketResponse: Maybe<Pick<ContentOfBucketResponse, 'IsTruncated' | 'Marker' | 'NextMarker' | 'Name' | 'Prefix' | 'Delimiter' | 'MaxKeys' | 'CommonPrefixes' | 'EncodingType'>>, Folders: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size'>>>, Files: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size' | 'signedImageUrl'>>> } };

export type GetContentOfFolderDoQueryVariables = {
  folderName?: Maybe<Scalars['String']>
};


export type GetContentOfFolderDoQuery = { getContentOfFolderDo: { ContentOfBucketResponse: Maybe<Pick<ContentOfBucketResponse, 'IsTruncated' | 'Marker' | 'NextMarker' | 'Name' | 'Prefix' | 'Delimiter' | 'MaxKeys' | 'CommonPrefixes' | 'EncodingType'>>, Folders: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size'>>>, Files: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size' | 'signedImageUrl'>>> } };

export type GetContentByPathDoQueryVariables = {
  path: Scalars['String'],
  options?: Maybe<ArrayDataOptions>
};


export type GetContentByPathDoQuery = { getContentByPathDo: { ContentOfBucketResponse: Maybe<Pick<ContentOfBucketResponse, 'IsTruncated' | 'Marker' | 'NextMarker' | 'Name' | 'Prefix' | 'Delimiter' | 'MaxKeys' | 'CommonPrefixes' | 'EncodingType'>>, Folders: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size'>>>, Files: Maybe<Array<Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size' | 'signedImageUrl'>>> } };

export type GetPdfDoQueryVariables = {
  fileName: Scalars['String']
};


export type GetPdfDoQuery = { getFileDo: Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size' | 'pdfBlob' | 'signedImageUrl'> };

export type GetFileDoQueryVariables = {
  fileName: Scalars['String']
};


export type GetFileDoQuery = { getFileDo: Pick<Content, 'Name' | 'Key' | 'LastModified' | 'ETag' | 'Size' | 'pdfBlob' | 'signedImageUrl'> };

export type AddFolderDoMutationVariables = {
  folderName: Scalars['String']
};


export type AddFolderDoMutation = Pick<Mutation, 'addFolderDo'>;

export type DeleteFolderDoMutationVariables = {
  folderName: Scalars['String']
};


export type DeleteFolderDoMutation = Pick<Mutation, 'deleteFolderDo'>;

export type UploadFilesDoMutationVariables = {
  fileArray: Array<Scalars['Upload']>,
  path: Scalars['String'],
  metadata: UploadMetadataInput
};


export type UploadFilesDoMutation = Pick<Mutation, 'uploadFilesDo'>;

export type UploadSingleFileDoMutationVariables = {
  file: Scalars['Upload'],
  path: Scalars['String']
};


export type UploadSingleFileDoMutation = Pick<Mutation, 'uploadSingleFileDo'>;

export type DeleteFileDoMutationVariables = {
  fileName: Scalars['String']
};


export type DeleteFileDoMutation = Pick<Mutation, 'deleteFileDo'>;

export type RenameFileDoMutationVariables = {
  fileName: Scalars['String'],
  newFileName: Scalars['String']
};


export type RenameFileDoMutation = Pick<Mutation, 'renameFileDo'>;

export type RenameFolderDoMutationVariables = {
  folderName: Scalars['String'],
  newFolderName: Scalars['String']
};


export type RenameFolderDoMutation = Pick<Mutation, 'renameFolderDo'>;

export type GetSignedUrlDoQueryVariables = {
  fileName: Scalars['String']
};


export type GetSignedUrlDoQuery = Pick<Query, 'getSignedUrlDo'>;

export type DownloadFileDoMutationVariables = {
  fileName: Scalars['String']
};


export type DownloadFileDoMutation = Pick<Mutation, 'downloadFileDo'>;

export type GetPagesQueryVariables = {};


export type GetPagesQuery = { getPages: { pages: Array<(
      Pick<Page, 'id' | 'url'>
      & { content: Pick<PageContent, 'title' | 'subTitle' | 'body'> }
    )> } };

export type UpdatePageMutationVariables = {
  editPageInput: EditPageInput
};


export type UpdatePageMutation = { updatePage: { pages: Array<(
      Pick<Page, 'id' | 'url'>
      & { content: Pick<PageContent, 'title' | 'subTitle' | 'body'> }
    )> } };

export type AddPageMutationVariables = {
  newPageInput: NewPageInput
};


export type AddPageMutation = { addPage: { pages: Array<(
      Pick<Page, 'id' | 'url'>
      & { content: Pick<PageContent, 'title' | 'subTitle' | 'body'> }
    )> } };

export type DeletePageMutationVariables = {
  pageId: Scalars['String']
};


export type DeletePageMutation = { deletePage: { pages: Array<(
      Pick<Page, 'id' | 'url'>
      & { content: Pick<PageContent, 'title' | 'subTitle' | 'body'> }
    )> } };

export type UploadSingleCamtFileMutationVariables = {
  file: Scalars['Upload'],
  analysis?: Maybe<Scalars['Boolean']>
};


export type UploadSingleCamtFileMutation = Pick<Mutation, 'singleUpload'>;

export type CreateDummyCamtFileForMultiplePaymentsMutationVariables = {
  createDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput
};


export type CreateDummyCamtFileForMultiplePaymentsMutation = Pick<Mutation, 'createDummyCamtFileForMultiplePayments'>;

export type SendEmailToSelectionOfCourseMutationVariables = {
  sendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput
};


export type SendEmailToSelectionOfCourseMutation = Pick<Mutation, 'sendEmailsToSelectionOfCourse'>;

export type SendEmailToSelectionOfBookingsMutationVariables = {
  sendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput
};


export type SendEmailToSelectionOfBookingsMutation = Pick<Mutation, 'sendMailToSelectionOfBookings'>;

export type SendEmailToSelectionOfBookersMutationVariables = {
  sendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput
};


export type SendEmailToSelectionOfBookersMutation = Pick<Mutation, 'sendEmailToSelectionOfBookers'>;

export type SendEmailToSelectionOfStaffMutationVariables = {
  sendMailToSelectionOfStaffInput: SendMailToSelectionOfStaffInput
};


export type SendEmailToSelectionOfStaffMutation = Pick<Mutation, 'sendEmailToSelectionOfStaff'>;

export type SendEmailToSelectionOfAttendeesMutationVariables = {
  sendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput
};


export type SendEmailToSelectionOfAttendeesMutation = Pick<Mutation, 'sendEmailToSelectionOfAttendees'>;

export type SendEmailToSelectionOfScheduledPaymentsMutationVariables = {
  sendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput
};


export type SendEmailToSelectionOfScheduledPaymentsMutation = Pick<Mutation, 'sendEmailToSelectionOfScheduledPayments'>;

export type SendInvoicesToSelectionOfBookingsMutationVariables = {
  sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput
};


export type SendInvoicesToSelectionOfBookingsMutation = Pick<Mutation, 'sendInvoicesToSelectionOfBookings'>;

export type SendEmailTemplateMutationVariables = {
  sendTemplateInput: SendTemplateInput
};


export type SendEmailTemplateMutation = Pick<Mutation, 'sendEmailTemplate'>;

export type GetTemplatesQueryVariables = {};


export type GetTemplatesQuery = { templates: Array<Pick<Template, 'id' | 'templateType' | 'name' | 'isDefault' | 'mailDataType' | 'createDate' | 'updateDate' | 'includeCourseDetails'>> };

export type GetTemplatesWithPaginationQueryVariables = {
  options?: Maybe<ArrayDataOptions>
};


export type GetTemplatesWithPaginationQuery = { templatesWithPagination: (
    Pick<TemplatesWithPagination, 'existsMore' | 'total'>
    & { items: Array<Pick<Template, 'id' | 'templateType' | 'name' | 'subject' | 'isDefault' | 'mailDataType' | 'createDate' | 'updateDate' | 'includeCourseDetails'>> }
  ) };

export type CheckIfTemplateOfTypeExistsQueryVariables = {
  templateType: TemplateType
};


export type CheckIfTemplateOfTypeExistsQuery = Pick<Query, 'checkIfTemplateOfTypeExists'>;

export type CreateTemplateMutationVariables = {
  newTemplateData: NewTemplateInput
};


export type CreateTemplateMutation = { createTemplate: Pick<Template, 'id'> };

export type DeleteTemplateMutationVariables = {
  id: Scalars['String']
};


export type DeleteTemplateMutation = Pick<Mutation, 'deleteTemplate'>;

export type GetTemplateByIdQueryVariables = {
  id: Scalars['String']
};


export type GetTemplateByIdQuery = { template: Pick<Template, 'id' | 'name' | 'templateType' | 'headerText' | 'footerText' | 'signature' | 'subject' | 'isDefault' | 'includeCourseDetails'> };

export type UpdateTemplateMutationVariables = {
  editTemplateData: EditTemplateInput,
  id: Scalars['String']
};


export type UpdateTemplateMutation = { updateTemplate: Pick<Template, 'id' | 'templateType'> };

export type GetTemplateByTypeQueryVariables = {
  templateType: TemplateType
};


export type GetTemplateByTypeQuery = { templateByType: Pick<Template, 'id' | 'name' | 'templateType' | 'headerText' | 'footerText' | 'signature' | 'subject' | 'includeCourseDetails'> };

export type SetIncludeCourseDetailsMutationVariables = {
  templateId: Scalars['ID'],
  includeCourseDetails: Scalars['Boolean']
};


export type SetIncludeCourseDetailsMutation = { setIncludeCourseDetails: Pick<Template, 'id' | 'includeCourseDetails'> };

export type LoggerMutationVariables = {
  input: LoggingInput
};


export type LoggerMutation = Pick<Mutation, 'logging'>;

export type GetUserSettingQueryVariables = {};


export type GetUserSettingQuery = { userSettings: Maybe<Pick<UserSetting, 'id' | 'language' | 'profilePictureUrl' | 'columnSettings'>> };

export type GetUserSettingByIdAllQueryVariables = {};


export type GetUserSettingByIdAllQuery = { userSettings: Maybe<Pick<UserSetting, 'id' | 'language' | 'profilePictureUrl' | 'columnSettings' | 'optimizedCourseCalendar'>> };

export type SetOptimizedCourseCalendarMutationVariables = {
  optimize: Scalars['Boolean']
};


export type SetOptimizedCourseCalendarMutation = { userSettingSetOptimizedCourseCalendar: Pick<UserSetting, 'id'> };

export type UpdateUserSettingMutationVariables = {
  userSettingsInput: UserSettingsInputTypes
};


export type UpdateUserSettingMutation = { userSettingsUpdateOrCreate: Pick<UserSetting, 'id' | 'language' | 'profilePictureUrl' | 'columnSettings'> };

export type UpdateTableColumnSettingsMutationVariables = {
  updateTableColumnSettingsInput: UpdateTableColumnSettingsInput
};


export type UpdateTableColumnSettingsMutation = { updateTableColumnSettings: Pick<UserSetting, 'id' | 'columnSettings'> };

export type GetColumnsForTableQueryVariables = {
  table: TablesEnum
};


export type GetColumnsForTableQuery = Pick<Query, 'getColumnsForTable'>;

export type GetAttendanceListSettingsQueryVariables = {};


export type GetAttendanceListSettingsQuery = { userSettings: Maybe<Pick<UserSetting, 'id' | 'attendanceListSettings'>> };

export type UpdateAttendanceListSettingsMutationVariables = {
  updateAttendanceListSettingsInput: UpdateAttendanceListSettingsInput
};


export type UpdateAttendanceListSettingsMutation = { updateAttendanceListSettings: Pick<UserSetting, 'id' | 'attendanceListSettings'> };

export type GetMjmlTemplateByIdQueryVariables = {
  templateId: Scalars['String']
};


export type GetMjmlTemplateByIdQuery = { getEmailTemplateById: Pick<MjmlTemplate, 'Count' | 'Data' | 'Total'> };

export const BookingCoreFieldsFragmentDoc = gql`
    fragment BookingCoreFields on Booking {
  id
  bookingNumber
  bookingNumberPrefix
  bookingType
  updateDate
  createDate
  startDateTime
  courseId
  noticeStaff
  attendanceListNote
  noticeBooker
  coursePriceId
  payDate
  grossPrice
  netPrice
  vat
  isSpecial
  paymentType
  bookedBy
  canceled
  bookingConfirmationSent
  waitingListConfirmationSent
  cancelationSent
  invoiceSent
  waitListCount
  additionalInfo
}
    `;
export const BookingCoursePriceFieldsFragmentDoc = gql`
    fragment BookingCoursePriceFields on Booking {
  coursePrice {
    id
    grossPrice
    netPrice
    vat
  }
}
    `;
export const BookingInvoiceFieldsFragmentDoc = gql`
    fragment BookingInvoiceFields on Booking {
  invoice {
    id
    billed
    invoiceNumber
    invoiceNumberPrefix
    createDate
  }
}
    `;
export const BookingReversalInvoiceFieldsFragmentDoc = gql`
    fragment BookingReversalInvoiceFields on Booking {
  reversalInvoice {
    id
    invoiceNumber
    invoiceNumberPrefix
    createDate
  }
}
    `;
export const BookingCourseLessonsFieldsFragmentDoc = gql`
    fragment BookingCourseLessonsFields on Booking {
  courseLessons {
    id
    startDateTime
    endDateTime
    locationId
    course {
      id
      freePlaces
      startDateTime
      paymentInterval
      minAttendees
      maxAttendees
      prefixedCourseNumber
      allowMonthlyPayment
      paymentInterval
      courseType {
        id
        name
        color
      }
      instructors {
        id
        firstname
        lastname
      }
      lessonCount
    }
    location {
      id
      city
      name
    }
  }
}
    `;
export const BookingAttendeeFieldsFragmentDoc = gql`
    fragment BookingAttendeeFields on Booking {
  attendee {
    id
    gender
    firstname
    lastname
    birthday
    customerNumberPrefix
    customerNumber
    booker {
      id
      gender
      firstname
      lastname
      customerNumberPrefix
      customerNumber
      street
      houseNumber
      postCode
      city
      phoneNumber
      mobileNumber
      email
      gender
      nonDisclosureNotice
      credit
    }
  }
}
    `;
export const BookingEmailNotificationsFieldsFragmentDoc = gql`
    fragment BookingEmailNotificationsFields on Booking {
  emailNotifications {
    id
    templateType
    subject
    createDate
    bookingId
    email
  }
}
    `;
export const BookingPaymentMethodFieldsFragmentDoc = gql`
    fragment BookingPaymentMethodFields on Booking {
  paymentMethod {
    id
    type
    cash
    esr
    iban
    bank
    bic
    sepaAccountholder
    bankTransfer
  }
}
    `;
export const BookerCoreFieldsFragmentDoc = gql`
    fragment BookerCoreFields on Booker {
  __typename
  id
  customerNumberPrefix
  customerNumber
  firstname
  lastname
  street
  houseNumber
  postCode
  city
  dsgvo
  newsletter
  email
  mobileNumber
  phoneNumber
  birthday
  gender
  initialContact
  healthInsuranceFund
  addition
  annotation
  credit
  nonDisclosureNotice
  credit
  userId
}
    `;
export const BookerPaymentMethodsFieldsFragmentDoc = gql`
    fragment BookerPaymentMethodsFields on Booker {
  paymentMethods {
    __typename
    id
    bankTransfer
    cash
    esr
    creditCardAccountholder
    creditcardNumber
    expirationDate
    validFrom
    cvc
    paypalAccountholder
    email
    iban
    bank
    bic
    sepaAccountholder
  }
}
    `;
export const BookerAttendeesFieldsFragmentDoc = gql`
    fragment BookerAttendeesFields on Booker {
  attendees {
    __typename
    firstname
    lastname
    id
    deletable
    addition
    annotation
    member
  }
}
    `;
export const CreateAttendeeBadgeDocument = gql`
    mutation createAttendeeBadge($newAttendeeBadgeInput: NewAttendeeBadgeInput!) {
  createAttendeeBadge(newAttendeeBadgeInput: $newAttendeeBadgeInput) {
    id
  }
}
    `;
export type CreateAttendeeBadgeMutationFn = ApolloReactCommon.MutationFunction<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables>;
export type CreateAttendeeBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables>, 'mutation'>;

    export const CreateAttendeeBadgeComponent = (props: CreateAttendeeBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables> mutation={CreateAttendeeBadgeDocument} {...props} />
    );
    
export type CreateAttendeeBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables> & TChildProps;
export function withCreateAttendeeBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAttendeeBadgeMutation,
  CreateAttendeeBadgeMutationVariables,
  CreateAttendeeBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables, CreateAttendeeBadgeProps<TChildProps>>(CreateAttendeeBadgeDocument, {
      alias: 'createAttendeeBadge',
      ...operationOptions
    });
};

/**
 * __useCreateAttendeeBadgeMutation__
 *
 * To run a mutation, you first call `useCreateAttendeeBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttendeeBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttendeeBadgeMutation, { data, loading, error }] = useCreateAttendeeBadgeMutation({
 *   variables: {
 *      newAttendeeBadgeInput: // value for 'newAttendeeBadgeInput'
 *   },
 * });
 */
export function useCreateAttendeeBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables>(CreateAttendeeBadgeDocument, baseOptions);
      }
export type CreateAttendeeBadgeMutationHookResult = ReturnType<typeof useCreateAttendeeBadgeMutation>;
export type CreateAttendeeBadgeMutationResult = ApolloReactCommon.MutationResult<CreateAttendeeBadgeMutation>;
export type CreateAttendeeBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttendeeBadgeMutation, CreateAttendeeBadgeMutationVariables>;
export const UpdateAttendeeBadgeDocument = gql`
    mutation updateAttendeeBadge($editAttendeeBadgeInput: EditAttendeeBadgeInput!) {
  updateAttendeeBadge(editAttendeeBadgeInput: $editAttendeeBadgeInput) {
    id
  }
}
    `;
export type UpdateAttendeeBadgeMutationFn = ApolloReactCommon.MutationFunction<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables>;
export type UpdateAttendeeBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables>, 'mutation'>;

    export const UpdateAttendeeBadgeComponent = (props: UpdateAttendeeBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables> mutation={UpdateAttendeeBadgeDocument} {...props} />
    );
    
export type UpdateAttendeeBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables> & TChildProps;
export function withUpdateAttendeeBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAttendeeBadgeMutation,
  UpdateAttendeeBadgeMutationVariables,
  UpdateAttendeeBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables, UpdateAttendeeBadgeProps<TChildProps>>(UpdateAttendeeBadgeDocument, {
      alias: 'updateAttendeeBadge',
      ...operationOptions
    });
};

/**
 * __useUpdateAttendeeBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeeBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeeBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendeeBadgeMutation, { data, loading, error }] = useUpdateAttendeeBadgeMutation({
 *   variables: {
 *      editAttendeeBadgeInput: // value for 'editAttendeeBadgeInput'
 *   },
 * });
 */
export function useUpdateAttendeeBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables>(UpdateAttendeeBadgeDocument, baseOptions);
      }
export type UpdateAttendeeBadgeMutationHookResult = ReturnType<typeof useUpdateAttendeeBadgeMutation>;
export type UpdateAttendeeBadgeMutationResult = ApolloReactCommon.MutationResult<UpdateAttendeeBadgeMutation>;
export type UpdateAttendeeBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAttendeeBadgeMutation, UpdateAttendeeBadgeMutationVariables>;
export const DeleteAttendeeBadgeDocument = gql`
    mutation deleteAttendeeBadge($id: String!) {
  deleteAttendeeBadge(id: $id)
}
    `;
export type DeleteAttendeeBadgeMutationFn = ApolloReactCommon.MutationFunction<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables>;
export type DeleteAttendeeBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables>, 'mutation'>;

    export const DeleteAttendeeBadgeComponent = (props: DeleteAttendeeBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables> mutation={DeleteAttendeeBadgeDocument} {...props} />
    );
    
export type DeleteAttendeeBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables> & TChildProps;
export function withDeleteAttendeeBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAttendeeBadgeMutation,
  DeleteAttendeeBadgeMutationVariables,
  DeleteAttendeeBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables, DeleteAttendeeBadgeProps<TChildProps>>(DeleteAttendeeBadgeDocument, {
      alias: 'deleteAttendeeBadge',
      ...operationOptions
    });
};

/**
 * __useDeleteAttendeeBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteAttendeeBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttendeeBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttendeeBadgeMutation, { data, loading, error }] = useDeleteAttendeeBadgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttendeeBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables>(DeleteAttendeeBadgeDocument, baseOptions);
      }
export type DeleteAttendeeBadgeMutationHookResult = ReturnType<typeof useDeleteAttendeeBadgeMutation>;
export type DeleteAttendeeBadgeMutationResult = ApolloReactCommon.MutationResult<DeleteAttendeeBadgeMutation>;
export type DeleteAttendeeBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttendeeBadgeMutation, DeleteAttendeeBadgeMutationVariables>;
export const GetAttendeeBadgeDocument = gql`
    query getAttendeeBadge($id: String!) {
  getAttendeeBadge(id: $id) {
    id
    badge {
      id
      name
    }
    attendee {
      id
    }
    comment
    dateOfAchivement
    courseId
  }
}
    `;
export type GetAttendeeBadgeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>, 'query'> & ({ variables: GetAttendeeBadgeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeBadgeComponent = (props: GetAttendeeBadgeComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables> query={GetAttendeeBadgeDocument} {...props} />
    );
    
export type GetAttendeeBadgeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables> & TChildProps;
export function withGetAttendeeBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeBadgeQuery,
  GetAttendeeBadgeQueryVariables,
  GetAttendeeBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables, GetAttendeeBadgeProps<TChildProps>>(GetAttendeeBadgeDocument, {
      alias: 'getAttendeeBadge',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeBadgeQuery__
 *
 * To run a query within a React component, call `useGetAttendeeBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeBadgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeBadgeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>(GetAttendeeBadgeDocument, baseOptions);
      }
export function useGetAttendeeBadgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>(GetAttendeeBadgeDocument, baseOptions);
        }
export type GetAttendeeBadgeQueryHookResult = ReturnType<typeof useGetAttendeeBadgeQuery>;
export type GetAttendeeBadgeLazyQueryHookResult = ReturnType<typeof useGetAttendeeBadgeLazyQuery>;
export type GetAttendeeBadgeQueryResult = ApolloReactCommon.QueryResult<GetAttendeeBadgeQuery, GetAttendeeBadgeQueryVariables>;
export const GetAttendeeBadgesByAttendeeIdDocument = gql`
    query getAttendeeBadgesByAttendeeId($attendeeId: String!) {
  getAttendeeBadgesByAttendeeId(attendeeId: $attendeeId) {
    id
    badge {
      id
      name
    }
    attendee {
      id
    }
    comment
    dateOfAchivement
    courseId
  }
}
    `;
export type GetAttendeeBadgesByAttendeeIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>, 'query'> & ({ variables: GetAttendeeBadgesByAttendeeIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeBadgesByAttendeeIdComponent = (props: GetAttendeeBadgesByAttendeeIdComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables> query={GetAttendeeBadgesByAttendeeIdDocument} {...props} />
    );
    
export type GetAttendeeBadgesByAttendeeIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables> & TChildProps;
export function withGetAttendeeBadgesByAttendeeId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeBadgesByAttendeeIdQuery,
  GetAttendeeBadgesByAttendeeIdQueryVariables,
  GetAttendeeBadgesByAttendeeIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables, GetAttendeeBadgesByAttendeeIdProps<TChildProps>>(GetAttendeeBadgesByAttendeeIdDocument, {
      alias: 'getAttendeeBadgesByAttendeeId',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeBadgesByAttendeeIdQuery__
 *
 * To run a query within a React component, call `useGetAttendeeBadgesByAttendeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeBadgesByAttendeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeBadgesByAttendeeIdQuery({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useGetAttendeeBadgesByAttendeeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>(GetAttendeeBadgesByAttendeeIdDocument, baseOptions);
      }
export function useGetAttendeeBadgesByAttendeeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>(GetAttendeeBadgesByAttendeeIdDocument, baseOptions);
        }
export type GetAttendeeBadgesByAttendeeIdQueryHookResult = ReturnType<typeof useGetAttendeeBadgesByAttendeeIdQuery>;
export type GetAttendeeBadgesByAttendeeIdLazyQueryHookResult = ReturnType<typeof useGetAttendeeBadgesByAttendeeIdLazyQuery>;
export type GetAttendeeBadgesByAttendeeIdQueryResult = ApolloReactCommon.QueryResult<GetAttendeeBadgesByAttendeeIdQuery, GetAttendeeBadgesByAttendeeIdQueryVariables>;
export const GetBadgeDocument = gql`
    query getBadge($id: String!) {
  badge(id: $id) {
    id
    name
    description
    createDate
    updateDate
    createdByUserId
    updatedByUserId
    rank
  }
}
    `;
export type GetBadgeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBadgeQuery, GetBadgeQueryVariables>, 'query'> & ({ variables: GetBadgeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBadgeComponent = (props: GetBadgeComponentProps) => (
      <ApolloReactComponents.Query<GetBadgeQuery, GetBadgeQueryVariables> query={GetBadgeDocument} {...props} />
    );
    
export type GetBadgeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBadgeQuery, GetBadgeQueryVariables> & TChildProps;
export function withGetBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBadgeQuery,
  GetBadgeQueryVariables,
  GetBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBadgeQuery, GetBadgeQueryVariables, GetBadgeProps<TChildProps>>(GetBadgeDocument, {
      alias: 'getBadge',
      ...operationOptions
    });
};

/**
 * __useGetBadgeQuery__
 *
 * To run a query within a React component, call `useGetBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBadgeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBadgeQuery, GetBadgeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBadgeQuery, GetBadgeQueryVariables>(GetBadgeDocument, baseOptions);
      }
export function useGetBadgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBadgeQuery, GetBadgeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBadgeQuery, GetBadgeQueryVariables>(GetBadgeDocument, baseOptions);
        }
export type GetBadgeQueryHookResult = ReturnType<typeof useGetBadgeQuery>;
export type GetBadgeLazyQueryHookResult = ReturnType<typeof useGetBadgeLazyQuery>;
export type GetBadgeQueryResult = ApolloReactCommon.QueryResult<GetBadgeQuery, GetBadgeQueryVariables>;
export const GetBadgesDocument = gql`
    query getBadges {
  badges {
    id
    name
    description
    createDate
    updateDate
    createdByUserId
    updatedByUserId
    rank
  }
}
    `;
export type GetBadgesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBadgesQuery, GetBadgesQueryVariables>, 'query'>;

    export const GetBadgesComponent = (props: GetBadgesComponentProps) => (
      <ApolloReactComponents.Query<GetBadgesQuery, GetBadgesQueryVariables> query={GetBadgesDocument} {...props} />
    );
    
export type GetBadgesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBadgesQuery, GetBadgesQueryVariables> & TChildProps;
export function withGetBadges<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBadgesQuery,
  GetBadgesQueryVariables,
  GetBadgesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBadgesQuery, GetBadgesQueryVariables, GetBadgesProps<TChildProps>>(GetBadgesDocument, {
      alias: 'getBadges',
      ...operationOptions
    });
};

/**
 * __useGetBadgesQuery__
 *
 * To run a query within a React component, call `useGetBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBadgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBadgesQuery, GetBadgesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBadgesQuery, GetBadgesQueryVariables>(GetBadgesDocument, baseOptions);
      }
export function useGetBadgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBadgesQuery, GetBadgesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBadgesQuery, GetBadgesQueryVariables>(GetBadgesDocument, baseOptions);
        }
export type GetBadgesQueryHookResult = ReturnType<typeof useGetBadgesQuery>;
export type GetBadgesLazyQueryHookResult = ReturnType<typeof useGetBadgesLazyQuery>;
export type GetBadgesQueryResult = ApolloReactCommon.QueryResult<GetBadgesQuery, GetBadgesQueryVariables>;
export const CreateBadgeDocument = gql`
    mutation createBadge($newBadgeInput: NewBadgeInput!) {
  createBadge(newBadgeInput: $newBadgeInput) {
    id
    name
    description
    createDate
    updateDate
    createdByUserId
    updatedByUserId
  }
}
    `;
export type CreateBadgeMutationFn = ApolloReactCommon.MutationFunction<CreateBadgeMutation, CreateBadgeMutationVariables>;
export type CreateBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBadgeMutation, CreateBadgeMutationVariables>, 'mutation'>;

    export const CreateBadgeComponent = (props: CreateBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBadgeMutation, CreateBadgeMutationVariables> mutation={CreateBadgeDocument} {...props} />
    );
    
export type CreateBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateBadgeMutation, CreateBadgeMutationVariables> & TChildProps;
export function withCreateBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBadgeMutation,
  CreateBadgeMutationVariables,
  CreateBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBadgeMutation, CreateBadgeMutationVariables, CreateBadgeProps<TChildProps>>(CreateBadgeDocument, {
      alias: 'createBadge',
      ...operationOptions
    });
};

/**
 * __useCreateBadgeMutation__
 *
 * To run a mutation, you first call `useCreateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadgeMutation, { data, loading, error }] = useCreateBadgeMutation({
 *   variables: {
 *      newBadgeInput: // value for 'newBadgeInput'
 *   },
 * });
 */
export function useCreateBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBadgeMutation, CreateBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBadgeMutation, CreateBadgeMutationVariables>(CreateBadgeDocument, baseOptions);
      }
export type CreateBadgeMutationHookResult = ReturnType<typeof useCreateBadgeMutation>;
export type CreateBadgeMutationResult = ApolloReactCommon.MutationResult<CreateBadgeMutation>;
export type CreateBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBadgeMutation, CreateBadgeMutationVariables>;
export const UpdateBadgeDocument = gql`
    mutation updateBadge($updateBadgeInput: UpdateBadgeInput!) {
  updateBadge(updateBadgeInput: $updateBadgeInput) {
    id
    name
    description
    createDate
    updateDate
    createdByUserId
    updatedByUserId
  }
}
    `;
export type UpdateBadgeMutationFn = ApolloReactCommon.MutationFunction<UpdateBadgeMutation, UpdateBadgeMutationVariables>;
export type UpdateBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>, 'mutation'>;

    export const UpdateBadgeComponent = (props: UpdateBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBadgeMutation, UpdateBadgeMutationVariables> mutation={UpdateBadgeDocument} {...props} />
    );
    
export type UpdateBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBadgeMutation, UpdateBadgeMutationVariables> & TChildProps;
export function withUpdateBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBadgeMutation,
  UpdateBadgeMutationVariables,
  UpdateBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBadgeMutation, UpdateBadgeMutationVariables, UpdateBadgeProps<TChildProps>>(UpdateBadgeDocument, {
      alias: 'updateBadge',
      ...operationOptions
    });
};

/**
 * __useUpdateBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBadgeMutation, { data, loading, error }] = useUpdateBadgeMutation({
 *   variables: {
 *      updateBadgeInput: // value for 'updateBadgeInput'
 *   },
 * });
 */
export function useUpdateBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBadgeMutation, UpdateBadgeMutationVariables>(UpdateBadgeDocument, baseOptions);
      }
export type UpdateBadgeMutationHookResult = ReturnType<typeof useUpdateBadgeMutation>;
export type UpdateBadgeMutationResult = ApolloReactCommon.MutationResult<UpdateBadgeMutation>;
export type UpdateBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>;
export const DeleteBadgeDocument = gql`
    mutation deleteBadge($id: String!) {
  deleteBadge(id: $id)
}
    `;
export type DeleteBadgeMutationFn = ApolloReactCommon.MutationFunction<DeleteBadgeMutation, DeleteBadgeMutationVariables>;
export type DeleteBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>, 'mutation'>;

    export const DeleteBadgeComponent = (props: DeleteBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBadgeMutation, DeleteBadgeMutationVariables> mutation={DeleteBadgeDocument} {...props} />
    );
    
export type DeleteBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteBadgeMutation, DeleteBadgeMutationVariables> & TChildProps;
export function withDeleteBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteBadgeMutation,
  DeleteBadgeMutationVariables,
  DeleteBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteBadgeMutation, DeleteBadgeMutationVariables, DeleteBadgeProps<TChildProps>>(DeleteBadgeDocument, {
      alias: 'deleteBadge',
      ...operationOptions
    });
};

/**
 * __useDeleteBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBadgeMutation, { data, loading, error }] = useDeleteBadgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBadgeMutation, DeleteBadgeMutationVariables>(DeleteBadgeDocument, baseOptions);
      }
export type DeleteBadgeMutationHookResult = ReturnType<typeof useDeleteBadgeMutation>;
export type DeleteBadgeMutationResult = ApolloReactCommon.MutationResult<DeleteBadgeMutation>;
export type DeleteBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>;
export const SoftDeleteBadgeDocument = gql`
    mutation softDeleteBadge($id: String!) {
  softDeleteBadge(id: $id) {
    id
  }
}
    `;
export type SoftDeleteBadgeMutationFn = ApolloReactCommon.MutationFunction<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables>;
export type SoftDeleteBadgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables>, 'mutation'>;

    export const SoftDeleteBadgeComponent = (props: SoftDeleteBadgeComponentProps) => (
      <ApolloReactComponents.Mutation<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables> mutation={SoftDeleteBadgeDocument} {...props} />
    );
    
export type SoftDeleteBadgeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables> & TChildProps;
export function withSoftDeleteBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SoftDeleteBadgeMutation,
  SoftDeleteBadgeMutationVariables,
  SoftDeleteBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables, SoftDeleteBadgeProps<TChildProps>>(SoftDeleteBadgeDocument, {
      alias: 'softDeleteBadge',
      ...operationOptions
    });
};

/**
 * __useSoftDeleteBadgeMutation__
 *
 * To run a mutation, you first call `useSoftDeleteBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteBadgeMutation, { data, loading, error }] = useSoftDeleteBadgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteBadgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables>) {
        return ApolloReactHooks.useMutation<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables>(SoftDeleteBadgeDocument, baseOptions);
      }
export type SoftDeleteBadgeMutationHookResult = ReturnType<typeof useSoftDeleteBadgeMutation>;
export type SoftDeleteBadgeMutationResult = ApolloReactCommon.MutationResult<SoftDeleteBadgeMutation>;
export type SoftDeleteBadgeMutationOptions = ApolloReactCommon.BaseMutationOptions<SoftDeleteBadgeMutation, SoftDeleteBadgeMutationVariables>;
export const IsBadgeNameUniqueDocument = gql`
    query isBadgeNameUnique($name: String!, $id: String) {
  isBadgeNameUnique(name: $name, id: $id)
}
    `;
export type IsBadgeNameUniqueComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>, 'query'> & ({ variables: IsBadgeNameUniqueQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsBadgeNameUniqueComponent = (props: IsBadgeNameUniqueComponentProps) => (
      <ApolloReactComponents.Query<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables> query={IsBadgeNameUniqueDocument} {...props} />
    );
    
export type IsBadgeNameUniqueProps<TChildProps = {}> = ApolloReactHoc.DataProps<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables> & TChildProps;
export function withIsBadgeNameUnique<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IsBadgeNameUniqueQuery,
  IsBadgeNameUniqueQueryVariables,
  IsBadgeNameUniqueProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables, IsBadgeNameUniqueProps<TChildProps>>(IsBadgeNameUniqueDocument, {
      alias: 'isBadgeNameUnique',
      ...operationOptions
    });
};

/**
 * __useIsBadgeNameUniqueQuery__
 *
 * To run a query within a React component, call `useIsBadgeNameUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBadgeNameUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBadgeNameUniqueQuery({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsBadgeNameUniqueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>) {
        return ApolloReactHooks.useQuery<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>(IsBadgeNameUniqueDocument, baseOptions);
      }
export function useIsBadgeNameUniqueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>(IsBadgeNameUniqueDocument, baseOptions);
        }
export type IsBadgeNameUniqueQueryHookResult = ReturnType<typeof useIsBadgeNameUniqueQuery>;
export type IsBadgeNameUniqueLazyQueryHookResult = ReturnType<typeof useIsBadgeNameUniqueLazyQuery>;
export type IsBadgeNameUniqueQueryResult = ApolloReactCommon.QueryResult<IsBadgeNameUniqueQuery, IsBadgeNameUniqueQueryVariables>;
export const ReOrderBadgesDocument = gql`
    mutation reOrderBadges($movedBadgeId: ID!, $targetBadgeId: ID!) {
  reOrderBadges(movedBadgeId: $movedBadgeId, targetBadgeId: $targetBadgeId)
}
    `;
export type ReOrderBadgesMutationFn = ApolloReactCommon.MutationFunction<ReOrderBadgesMutation, ReOrderBadgesMutationVariables>;
export type ReOrderBadgesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReOrderBadgesMutation, ReOrderBadgesMutationVariables>, 'mutation'>;

    export const ReOrderBadgesComponent = (props: ReOrderBadgesComponentProps) => (
      <ApolloReactComponents.Mutation<ReOrderBadgesMutation, ReOrderBadgesMutationVariables> mutation={ReOrderBadgesDocument} {...props} />
    );
    
export type ReOrderBadgesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReOrderBadgesMutation, ReOrderBadgesMutationVariables> & TChildProps;
export function withReOrderBadges<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReOrderBadgesMutation,
  ReOrderBadgesMutationVariables,
  ReOrderBadgesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReOrderBadgesMutation, ReOrderBadgesMutationVariables, ReOrderBadgesProps<TChildProps>>(ReOrderBadgesDocument, {
      alias: 'reOrderBadges',
      ...operationOptions
    });
};

/**
 * __useReOrderBadgesMutation__
 *
 * To run a mutation, you first call `useReOrderBadgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOrderBadgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOrderBadgesMutation, { data, loading, error }] = useReOrderBadgesMutation({
 *   variables: {
 *      movedBadgeId: // value for 'movedBadgeId'
 *      targetBadgeId: // value for 'targetBadgeId'
 *   },
 * });
 */
export function useReOrderBadgesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReOrderBadgesMutation, ReOrderBadgesMutationVariables>) {
        return ApolloReactHooks.useMutation<ReOrderBadgesMutation, ReOrderBadgesMutationVariables>(ReOrderBadgesDocument, baseOptions);
      }
export type ReOrderBadgesMutationHookResult = ReturnType<typeof useReOrderBadgesMutation>;
export type ReOrderBadgesMutationResult = ApolloReactCommon.MutationResult<ReOrderBadgesMutation>;
export type ReOrderBadgesMutationOptions = ApolloReactCommon.BaseMutationOptions<ReOrderBadgesMutation, ReOrderBadgesMutationVariables>;
export const GetBookinsDocument = gql`
    query GetBookins($options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  bookings(options: $options, onlyCurrentUser: $onlyCurrentUser) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      updateDate
      createDate
      startDateTime
      courseId
      noticeStaff
      attendanceListNote
      noticeBooker
      coursePriceId
      payDate
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        id
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        id
        startDateTime
        endDateTime
        locationId
        course {
          id
          freePlaces
          startDateTime
          paymentInterval
          minAttendees
          maxAttendees
          prefixedCourseNumber
          allowMonthlyPayment
          paymentInterval
          courseType {
            id
            name
            color
          }
          instructors {
            id
            firstname
            lastname
          }
          lessonCount
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          gender
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          gender
          nonDisclosureNotice
          credit
        }
      }
      bookedBy
      canceled
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      waitListCount
      additionalInfo
    }
  }
}
    `;
export type GetBookinsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookinsQuery, GetBookinsQueryVariables>, 'query'>;

    export const GetBookinsComponent = (props: GetBookinsComponentProps) => (
      <ApolloReactComponents.Query<GetBookinsQuery, GetBookinsQueryVariables> query={GetBookinsDocument} {...props} />
    );
    
export type GetBookinsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookinsQuery, GetBookinsQueryVariables> & TChildProps;
export function withGetBookins<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookinsQuery,
  GetBookinsQueryVariables,
  GetBookinsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookinsQuery, GetBookinsQueryVariables, GetBookinsProps<TChildProps>>(GetBookinsDocument, {
      alias: 'getBookins',
      ...operationOptions
    });
};

/**
 * __useGetBookinsQuery__
 *
 * To run a query within a React component, call `useGetBookinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookinsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookinsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetBookinsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookinsQuery, GetBookinsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookinsQuery, GetBookinsQueryVariables>(GetBookinsDocument, baseOptions);
      }
export function useGetBookinsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookinsQuery, GetBookinsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookinsQuery, GetBookinsQueryVariables>(GetBookinsDocument, baseOptions);
        }
export type GetBookinsQueryHookResult = ReturnType<typeof useGetBookinsQuery>;
export type GetBookinsLazyQueryHookResult = ReturnType<typeof useGetBookinsLazyQuery>;
export type GetBookinsQueryResult = ApolloReactCommon.QueryResult<GetBookinsQuery, GetBookinsQueryVariables>;
export const GetBookingsDynamicDocument = gql`
    query GetBookingsDynamic($options: ArrayDataOptions, $includeCoursePrice: Boolean!, $includeInvoice: Boolean!, $includeReversalInvoice: Boolean!, $includeCourseLessons: Boolean!, $includeAttendee: Boolean!, $includeEmailNotifications: Boolean!, $includePaymentMethod: Boolean!) {
  bookingsDynamic(options: $options, includeCoursePrice: $includeCoursePrice, includeInvoice: $includeInvoice, includeReversalInvoice: $includeReversalInvoice, includeCourseLessons: $includeCourseLessons, includeAttendee: $includeAttendee, includeEmailNotifications: $includeEmailNotifications, includePaymentMethod: $includePaymentMethod) {
    existsMore
    total
    items {
      ...BookingCoreFields
      ...BookingCoursePriceFields @include(if: $includeCoursePrice)
      ...BookingInvoiceFields @include(if: $includeInvoice)
      ...BookingReversalInvoiceFields @include(if: $includeReversalInvoice)
      ...BookingCourseLessonsFields @include(if: $includeCourseLessons)
      ...BookingAttendeeFields @include(if: $includeAttendee)
      ...BookingEmailNotificationsFields @include(if: $includeEmailNotifications)
      ...BookingPaymentMethodFields @include(if: $includePaymentMethod)
    }
  }
}
    ${BookingCoreFieldsFragmentDoc}
${BookingCoursePriceFieldsFragmentDoc}
${BookingInvoiceFieldsFragmentDoc}
${BookingReversalInvoiceFieldsFragmentDoc}
${BookingCourseLessonsFieldsFragmentDoc}
${BookingAttendeeFieldsFragmentDoc}
${BookingEmailNotificationsFieldsFragmentDoc}
${BookingPaymentMethodFieldsFragmentDoc}`;
export type GetBookingsDynamicComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>, 'query'> & ({ variables: GetBookingsDynamicQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsDynamicComponent = (props: GetBookingsDynamicComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables> query={GetBookingsDynamicDocument} {...props} />
    );
    
export type GetBookingsDynamicProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables> & TChildProps;
export function withGetBookingsDynamic<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsDynamicQuery,
  GetBookingsDynamicQueryVariables,
  GetBookingsDynamicProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables, GetBookingsDynamicProps<TChildProps>>(GetBookingsDynamicDocument, {
      alias: 'getBookingsDynamic',
      ...operationOptions
    });
};

/**
 * __useGetBookingsDynamicQuery__
 *
 * To run a query within a React component, call `useGetBookingsDynamicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsDynamicQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsDynamicQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeCoursePrice: // value for 'includeCoursePrice'
 *      includeInvoice: // value for 'includeInvoice'
 *      includeReversalInvoice: // value for 'includeReversalInvoice'
 *      includeCourseLessons: // value for 'includeCourseLessons'
 *      includeAttendee: // value for 'includeAttendee'
 *      includeEmailNotifications: // value for 'includeEmailNotifications'
 *      includePaymentMethod: // value for 'includePaymentMethod'
 *   },
 * });
 */
export function useGetBookingsDynamicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>(GetBookingsDynamicDocument, baseOptions);
      }
export function useGetBookingsDynamicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>(GetBookingsDynamicDocument, baseOptions);
        }
export type GetBookingsDynamicQueryHookResult = ReturnType<typeof useGetBookingsDynamicQuery>;
export type GetBookingsDynamicLazyQueryHookResult = ReturnType<typeof useGetBookingsDynamicLazyQuery>;
export type GetBookingsDynamicQueryResult = ApolloReactCommon.QueryResult<GetBookingsDynamicQuery, GetBookingsDynamicQueryVariables>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($newBookingData: NewBookingInput!, $sendMail: Boolean!) {
  createBooking(newBookingData: $newBookingData, sendMail: $sendMail) {
    id
    bookingType
  }
}
    `;
export type CreateBookingMutationFn = ApolloReactCommon.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;
export type CreateBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBookingMutation, CreateBookingMutationVariables>, 'mutation'>;

    export const CreateBookingComponent = (props: CreateBookingComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBookingMutation, CreateBookingMutationVariables> mutation={CreateBookingDocument} {...props} />
    );
    
export type CreateBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateBookingMutation, CreateBookingMutationVariables> & TChildProps;
export function withCreateBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBookingMutation,
  CreateBookingMutationVariables,
  CreateBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBookingMutation, CreateBookingMutationVariables, CreateBookingProps<TChildProps>>(CreateBookingDocument, {
      alias: 'createBooking',
      ...operationOptions
    });
};

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      newBookingData: // value for 'newBookingData'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, baseOptions);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = ApolloReactCommon.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const RemoveBookingDocument = gql`
    mutation RemoveBooking($id: ID!, $bookingDeleteAction: BookingDeleteAction, $notifyBooker: Boolean) {
  removeBooking(id: $id, bookingDeleteAction: $bookingDeleteAction, notifyBooker: $notifyBooker)
}
    `;
export type RemoveBookingMutationFn = ApolloReactCommon.MutationFunction<RemoveBookingMutation, RemoveBookingMutationVariables>;
export type RemoveBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveBookingMutation, RemoveBookingMutationVariables>, 'mutation'>;

    export const RemoveBookingComponent = (props: RemoveBookingComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveBookingMutation, RemoveBookingMutationVariables> mutation={RemoveBookingDocument} {...props} />
    );
    
export type RemoveBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveBookingMutation, RemoveBookingMutationVariables> & TChildProps;
export function withRemoveBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveBookingMutation,
  RemoveBookingMutationVariables,
  RemoveBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveBookingMutation, RemoveBookingMutationVariables, RemoveBookingProps<TChildProps>>(RemoveBookingDocument, {
      alias: 'removeBooking',
      ...operationOptions
    });
};

/**
 * __useRemoveBookingMutation__
 *
 * To run a mutation, you first call `useRemoveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingMutation, { data, loading, error }] = useRemoveBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bookingDeleteAction: // value for 'bookingDeleteAction'
 *      notifyBooker: // value for 'notifyBooker'
 *   },
 * });
 */
export function useRemoveBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBookingMutation, RemoveBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBookingMutation, RemoveBookingMutationVariables>(RemoveBookingDocument, baseOptions);
      }
export type RemoveBookingMutationHookResult = ReturnType<typeof useRemoveBookingMutation>;
export type RemoveBookingMutationResult = ApolloReactCommon.MutationResult<RemoveBookingMutation>;
export type RemoveBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBookingMutation, RemoveBookingMutationVariables>;
export const RemoveBookingsDocument = gql`
    mutation RemoveBookings($ids: [ID!]!) {
  removeBookings(ids: $ids)
}
    `;
export type RemoveBookingsMutationFn = ApolloReactCommon.MutationFunction<RemoveBookingsMutation, RemoveBookingsMutationVariables>;
export type RemoveBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveBookingsMutation, RemoveBookingsMutationVariables>, 'mutation'>;

    export const RemoveBookingsComponent = (props: RemoveBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveBookingsMutation, RemoveBookingsMutationVariables> mutation={RemoveBookingsDocument} {...props} />
    );
    
export type RemoveBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveBookingsMutation, RemoveBookingsMutationVariables> & TChildProps;
export function withRemoveBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveBookingsMutation,
  RemoveBookingsMutationVariables,
  RemoveBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveBookingsMutation, RemoveBookingsMutationVariables, RemoveBookingsProps<TChildProps>>(RemoveBookingsDocument, {
      alias: 'removeBookings',
      ...operationOptions
    });
};

/**
 * __useRemoveBookingsMutation__
 *
 * To run a mutation, you first call `useRemoveBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingsMutation, { data, loading, error }] = useRemoveBookingsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBookingsMutation, RemoveBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBookingsMutation, RemoveBookingsMutationVariables>(RemoveBookingsDocument, baseOptions);
      }
export type RemoveBookingsMutationHookResult = ReturnType<typeof useRemoveBookingsMutation>;
export type RemoveBookingsMutationResult = ApolloReactCommon.MutationResult<RemoveBookingsMutation>;
export type RemoveBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBookingsMutation, RemoveBookingsMutationVariables>;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($editBookingData: EditBookingInput!, $id: String!) {
  updateBooking(editBookingData: $editBookingData, id: $id) {
    id
    bookingType
  }
}
    `;
export type UpdateBookingMutationFn = ApolloReactCommon.MutationFunction<UpdateBookingMutation, UpdateBookingMutationVariables>;
export type UpdateBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookingMutation, UpdateBookingMutationVariables>, 'mutation'>;

    export const UpdateBookingComponent = (props: UpdateBookingComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookingMutation, UpdateBookingMutationVariables> mutation={UpdateBookingDocument} {...props} />
    );
    
export type UpdateBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookingMutation, UpdateBookingMutationVariables> & TChildProps;
export function withUpdateBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookingMutation,
  UpdateBookingMutationVariables,
  UpdateBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookingMutation, UpdateBookingMutationVariables, UpdateBookingProps<TChildProps>>(UpdateBookingDocument, {
      alias: 'updateBooking',
      ...operationOptions
    });
};

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      editBookingData: // value for 'editBookingData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookingMutation, UpdateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(UpdateBookingDocument, baseOptions);
      }
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationResult = ApolloReactCommon.MutationResult<UpdateBookingMutation>;
export type UpdateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookingMutation, UpdateBookingMutationVariables>;
export const UpdateNoticeStaffDocument = gql`
    mutation UpdateNoticeStaff($id: String!, $noticeStaff: String!) {
  updateNoticeStaff(id: $id, noticeStaff: $noticeStaff) {
    id
    noticeStaff
  }
}
    `;
export type UpdateNoticeStaffMutationFn = ApolloReactCommon.MutationFunction<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables>;
export type UpdateNoticeStaffComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables>, 'mutation'>;

    export const UpdateNoticeStaffComponent = (props: UpdateNoticeStaffComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables> mutation={UpdateNoticeStaffDocument} {...props} />
    );
    
export type UpdateNoticeStaffProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables> & TChildProps;
export function withUpdateNoticeStaff<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateNoticeStaffMutation,
  UpdateNoticeStaffMutationVariables,
  UpdateNoticeStaffProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables, UpdateNoticeStaffProps<TChildProps>>(UpdateNoticeStaffDocument, {
      alias: 'updateNoticeStaff',
      ...operationOptions
    });
};

/**
 * __useUpdateNoticeStaffMutation__
 *
 * To run a mutation, you first call `useUpdateNoticeStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoticeStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoticeStaffMutation, { data, loading, error }] = useUpdateNoticeStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      noticeStaff: // value for 'noticeStaff'
 *   },
 * });
 */
export function useUpdateNoticeStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables>(UpdateNoticeStaffDocument, baseOptions);
      }
export type UpdateNoticeStaffMutationHookResult = ReturnType<typeof useUpdateNoticeStaffMutation>;
export type UpdateNoticeStaffMutationResult = ApolloReactCommon.MutationResult<UpdateNoticeStaffMutation>;
export type UpdateNoticeStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNoticeStaffMutation, UpdateNoticeStaffMutationVariables>;
export const GetMonthlyPaymentPreviewDocument = gql`
    query getMonthlyPaymentPreview($monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput!) {
  getMonthlyPaymentPreview(monthlyPaymentPreviewOptions: $monthlyPaymentPreviewOptions)
}
    `;
export type GetMonthlyPaymentPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>, 'query'> & ({ variables: GetMonthlyPaymentPreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMonthlyPaymentPreviewComponent = (props: GetMonthlyPaymentPreviewComponentProps) => (
      <ApolloReactComponents.Query<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables> query={GetMonthlyPaymentPreviewDocument} {...props} />
    );
    
export type GetMonthlyPaymentPreviewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables> & TChildProps;
export function withGetMonthlyPaymentPreview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMonthlyPaymentPreviewQuery,
  GetMonthlyPaymentPreviewQueryVariables,
  GetMonthlyPaymentPreviewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables, GetMonthlyPaymentPreviewProps<TChildProps>>(GetMonthlyPaymentPreviewDocument, {
      alias: 'getMonthlyPaymentPreview',
      ...operationOptions
    });
};

/**
 * __useGetMonthlyPaymentPreviewQuery__
 *
 * To run a query within a React component, call `useGetMonthlyPaymentPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyPaymentPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyPaymentPreviewQuery({
 *   variables: {
 *      monthlyPaymentPreviewOptions: // value for 'monthlyPaymentPreviewOptions'
 *   },
 * });
 */
export function useGetMonthlyPaymentPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>(GetMonthlyPaymentPreviewDocument, baseOptions);
      }
export function useGetMonthlyPaymentPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>(GetMonthlyPaymentPreviewDocument, baseOptions);
        }
export type GetMonthlyPaymentPreviewQueryHookResult = ReturnType<typeof useGetMonthlyPaymentPreviewQuery>;
export type GetMonthlyPaymentPreviewLazyQueryHookResult = ReturnType<typeof useGetMonthlyPaymentPreviewLazyQuery>;
export type GetMonthlyPaymentPreviewQueryResult = ApolloReactCommon.QueryResult<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>;
export const GetBookingByIdDocument = gql`
    query getBookingById($id: ID!) {
  bookingById(id: $id) {
    id
    bookingNumber
    bookingNumberPrefix
    bookingType
    updateDate
    createDate
    noticeStaff
    noticeBooker
    coursePriceId
    payDate
    grossPrice
    netPrice
    vat
    memberDiscount
    isSpecial
    startDateTime
    courseId
    coursePrice {
      id
      grossPrice
      netPrice
      vat
    }
    invoice {
      billed
      invoiceNumber
      invoiceNumberPrefix
      createDate
      correctionDate
      createDate
      id
    }
    reversalInvoice {
      id
      invoiceNumber
      invoiceNumberPrefix
      createDate
    }
    courseLessons {
      startDateTime
      endDateTime
      id
      locationId
      course {
        id
        freePlaces
        bookingCount
        startDateTime
        paymentInterval
        minAttendees
        maxAttendees
        prefixedCourseNumber
        allowMonthlyPayment
        allowUpFrontPayment
        monthlySchedule
        courseType {
          id
          name
          color
        }
        installmentRates {
          id
          installmentAmount
          installmentDue
        }
        paymentType
        lessons {
          id
          startDateTime
        }
      }
      location {
        id
        city
        name
      }
    }
    attendee {
      id
      gender
      firstname
      lastname
      birthday
      customerNumberPrefix
      customerNumber
      member
      booker {
        id
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        street
        houseNumber
        postCode
        city
        phoneNumber
        mobileNumber
        email
        gender
        paymentMethods {
          id
          type
          cash
          esr
          iban
          bank
          bic
          sepaAccountholder
          bankTransfer
        }
        nonDisclosureNotice
        credit
      }
      defaultPaymentMethod {
        id
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
      }
    }
    paymentType
    paymentMethod {
      id
      cash
      esr
      creditcardNumber
      creditCardAccountholder
      expirationDate
      sepaAccountholder
      iban
      bic
      bank
      paypalAccountholder
      email
      bankTransfer
    }
    paymentMethodId
    bookedBy
    canceled
    scheduledPayments {
      id
      paymentAmount
      paymentDue
      paymentDone
      type
    }
    monthlySchedule
    paymentType
    creditedBalance
    waitListCount
    additionalInfo
  }
}
    `;
export type GetBookingByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingByIdQuery, GetBookingByIdQueryVariables>, 'query'> & ({ variables: GetBookingByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingByIdComponent = (props: GetBookingByIdComponentProps) => (
      <ApolloReactComponents.Query<GetBookingByIdQuery, GetBookingByIdQueryVariables> query={GetBookingByIdDocument} {...props} />
    );
    
export type GetBookingByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingByIdQuery, GetBookingByIdQueryVariables> & TChildProps;
export function withGetBookingById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingByIdQuery,
  GetBookingByIdQueryVariables,
  GetBookingByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingByIdQuery, GetBookingByIdQueryVariables, GetBookingByIdProps<TChildProps>>(GetBookingByIdDocument, {
      alias: 'getBookingById',
      ...operationOptions
    });
};

/**
 * __useGetBookingByIdQuery__
 *
 * To run a query within a React component, call `useGetBookingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingByIdQuery, GetBookingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingByIdQuery, GetBookingByIdQueryVariables>(GetBookingByIdDocument, baseOptions);
      }
export function useGetBookingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingByIdQuery, GetBookingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingByIdQuery, GetBookingByIdQueryVariables>(GetBookingByIdDocument, baseOptions);
        }
export type GetBookingByIdQueryHookResult = ReturnType<typeof useGetBookingByIdQuery>;
export type GetBookingByIdLazyQueryHookResult = ReturnType<typeof useGetBookingByIdLazyQuery>;
export type GetBookingByIdQueryResult = ApolloReactCommon.QueryResult<GetBookingByIdQuery, GetBookingByIdQueryVariables>;
export const GetBookingsByCourseDocument = gql`
    query GetBookingsByCourse($options: ArrayDataOptions, $id: ID!) {
  getBookingsByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      updateDate
      createDate
      noticeStaff
      noticeBooker
      attendanceListNote
      coursePriceId
      courseId
      noticeBooker
      payDate
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        id
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        id
        startDateTime
        endDateTime
        locationId
        course {
          id
          freePlaces
          startDateTime
          paymentInterval
          minAttendees
          maxAttendees
          prefixedCourseNumber
          allowMonthlyPayment
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          gender
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          gender
          nonDisclosureNotice
          credit
        }
      }
      bookedBy
      canceled
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      waitListCount
    }
  }
}
    `;
export type GetBookingsByCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>, 'query'> & ({ variables: GetBookingsByCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByCourseComponent = (props: GetBookingsByCourseComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables> query={GetBookingsByCourseDocument} {...props} />
    );
    
export type GetBookingsByCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables> & TChildProps;
export function withGetBookingsByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByCourseQuery,
  GetBookingsByCourseQueryVariables,
  GetBookingsByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables, GetBookingsByCourseProps<TChildProps>>(GetBookingsByCourseDocument, {
      alias: 'getBookingsByCourse',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByCourseQuery__
 *
 * To run a query within a React component, call `useGetBookingsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByCourseQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingsByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>(GetBookingsByCourseDocument, baseOptions);
      }
export function useGetBookingsByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>(GetBookingsByCourseDocument, baseOptions);
        }
export type GetBookingsByCourseQueryHookResult = ReturnType<typeof useGetBookingsByCourseQuery>;
export type GetBookingsByCourseLazyQueryHookResult = ReturnType<typeof useGetBookingsByCourseLazyQuery>;
export type GetBookingsByCourseQueryResult = ApolloReactCommon.QueryResult<GetBookingsByCourseQuery, GetBookingsByCourseQueryVariables>;
export const GetWaitlistByCourseDocument = gql`
    query GetWaitlistByCourse($options: ArrayDataOptions, $id: ID!) {
  getWaitlistByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      coursePriceId
      courseId
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      courseLessons {
        id
        course {
          id
          paymentInterval
        }
      }
      paymentType
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          gender
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          gender
          nonDisclosureNotice
        }
      }
      canceled
      waitListCount
    }
  }
}
    `;
export type GetWaitlistByCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>, 'query'> & ({ variables: GetWaitlistByCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWaitlistByCourseComponent = (props: GetWaitlistByCourseComponentProps) => (
      <ApolloReactComponents.Query<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables> query={GetWaitlistByCourseDocument} {...props} />
    );
    
export type GetWaitlistByCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables> & TChildProps;
export function withGetWaitlistByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetWaitlistByCourseQuery,
  GetWaitlistByCourseQueryVariables,
  GetWaitlistByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables, GetWaitlistByCourseProps<TChildProps>>(GetWaitlistByCourseDocument, {
      alias: 'getWaitlistByCourse',
      ...operationOptions
    });
};

/**
 * __useGetWaitlistByCourseQuery__
 *
 * To run a query within a React component, call `useGetWaitlistByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitlistByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitlistByCourseQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitlistByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>(GetWaitlistByCourseDocument, baseOptions);
      }
export function useGetWaitlistByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>(GetWaitlistByCourseDocument, baseOptions);
        }
export type GetWaitlistByCourseQueryHookResult = ReturnType<typeof useGetWaitlistByCourseQuery>;
export type GetWaitlistByCourseLazyQueryHookResult = ReturnType<typeof useGetWaitlistByCourseLazyQuery>;
export type GetWaitlistByCourseQueryResult = ApolloReactCommon.QueryResult<GetWaitlistByCourseQuery, GetWaitlistByCourseQueryVariables>;
export const GetWaitlistCountOfCourseDocument = gql`
    query GetWaitlistCountOfCourse($id: ID!) {
  getWaitlistCountOfCourse(id: $id)
}
    `;
export type GetWaitlistCountOfCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>, 'query'> & ({ variables: GetWaitlistCountOfCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWaitlistCountOfCourseComponent = (props: GetWaitlistCountOfCourseComponentProps) => (
      <ApolloReactComponents.Query<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables> query={GetWaitlistCountOfCourseDocument} {...props} />
    );
    
export type GetWaitlistCountOfCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables> & TChildProps;
export function withGetWaitlistCountOfCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetWaitlistCountOfCourseQuery,
  GetWaitlistCountOfCourseQueryVariables,
  GetWaitlistCountOfCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables, GetWaitlistCountOfCourseProps<TChildProps>>(GetWaitlistCountOfCourseDocument, {
      alias: 'getWaitlistCountOfCourse',
      ...operationOptions
    });
};

/**
 * __useGetWaitlistCountOfCourseQuery__
 *
 * To run a query within a React component, call `useGetWaitlistCountOfCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitlistCountOfCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitlistCountOfCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitlistCountOfCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>(GetWaitlistCountOfCourseDocument, baseOptions);
      }
export function useGetWaitlistCountOfCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>(GetWaitlistCountOfCourseDocument, baseOptions);
        }
export type GetWaitlistCountOfCourseQueryHookResult = ReturnType<typeof useGetWaitlistCountOfCourseQuery>;
export type GetWaitlistCountOfCourseLazyQueryHookResult = ReturnType<typeof useGetWaitlistCountOfCourseLazyQuery>;
export type GetWaitlistCountOfCourseQueryResult = ApolloReactCommon.QueryResult<GetWaitlistCountOfCourseQuery, GetWaitlistCountOfCourseQueryVariables>;
export const GetBookingsByCourseMinimalDocument = gql`
    query GetBookingsByCourseMinimal($options: ArrayDataOptions, $id: ID!) {
  getBookingsByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      attendee {
        id
        gender
        birthday
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          gender
          customerNumberPrefix
          customerNumber
          email
          nonDisclosureNotice
        }
      }
      canceled
      waitListCount
    }
  }
}
    `;
export type GetBookingsByCourseMinimalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>, 'query'> & ({ variables: GetBookingsByCourseMinimalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByCourseMinimalComponent = (props: GetBookingsByCourseMinimalComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables> query={GetBookingsByCourseMinimalDocument} {...props} />
    );
    
export type GetBookingsByCourseMinimalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables> & TChildProps;
export function withGetBookingsByCourseMinimal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByCourseMinimalQuery,
  GetBookingsByCourseMinimalQueryVariables,
  GetBookingsByCourseMinimalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables, GetBookingsByCourseMinimalProps<TChildProps>>(GetBookingsByCourseMinimalDocument, {
      alias: 'getBookingsByCourseMinimal',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByCourseMinimalQuery__
 *
 * To run a query within a React component, call `useGetBookingsByCourseMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByCourseMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByCourseMinimalQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingsByCourseMinimalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>(GetBookingsByCourseMinimalDocument, baseOptions);
      }
export function useGetBookingsByCourseMinimalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>(GetBookingsByCourseMinimalDocument, baseOptions);
        }
export type GetBookingsByCourseMinimalQueryHookResult = ReturnType<typeof useGetBookingsByCourseMinimalQuery>;
export type GetBookingsByCourseMinimalLazyQueryHookResult = ReturnType<typeof useGetBookingsByCourseMinimalLazyQuery>;
export type GetBookingsByCourseMinimalQueryResult = ApolloReactCommon.QueryResult<GetBookingsByCourseMinimalQuery, GetBookingsByCourseMinimalQueryVariables>;
export const GetBookingsByCourseForTransferDocument = gql`
    query GetBookingsByCourseForTransfer($options: ArrayDataOptions, $id: ID!) {
  getBookingsByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      attendee {
        id
        gender
        birthday
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          gender
          customerNumberPrefix
          customerNumber
          email
          nonDisclosureNotice
        }
      }
      canceled
      waitListCount
      invoice {
        id
      }
    }
  }
}
    `;
export type GetBookingsByCourseForTransferComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>, 'query'> & ({ variables: GetBookingsByCourseForTransferQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByCourseForTransferComponent = (props: GetBookingsByCourseForTransferComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables> query={GetBookingsByCourseForTransferDocument} {...props} />
    );
    
export type GetBookingsByCourseForTransferProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables> & TChildProps;
export function withGetBookingsByCourseForTransfer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByCourseForTransferQuery,
  GetBookingsByCourseForTransferQueryVariables,
  GetBookingsByCourseForTransferProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables, GetBookingsByCourseForTransferProps<TChildProps>>(GetBookingsByCourseForTransferDocument, {
      alias: 'getBookingsByCourseForTransfer',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByCourseForTransferQuery__
 *
 * To run a query within a React component, call `useGetBookingsByCourseForTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByCourseForTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByCourseForTransferQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingsByCourseForTransferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>(GetBookingsByCourseForTransferDocument, baseOptions);
      }
export function useGetBookingsByCourseForTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>(GetBookingsByCourseForTransferDocument, baseOptions);
        }
export type GetBookingsByCourseForTransferQueryHookResult = ReturnType<typeof useGetBookingsByCourseForTransferQuery>;
export type GetBookingsByCourseForTransferLazyQueryHookResult = ReturnType<typeof useGetBookingsByCourseForTransferLazyQuery>;
export type GetBookingsByCourseForTransferQueryResult = ApolloReactCommon.QueryResult<GetBookingsByCourseForTransferQuery, GetBookingsByCourseForTransferQueryVariables>;
export const GetBookingsCountByCourseDocument = gql`
    query GetBookingsCountByCourse($options: ArrayDataOptions, $id: ID!) {
  getBookingsByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumberPrefix
      bookingType
      canceled
      waitListCount
    }
  }
}
    `;
export type GetBookingsCountByCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>, 'query'> & ({ variables: GetBookingsCountByCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsCountByCourseComponent = (props: GetBookingsCountByCourseComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables> query={GetBookingsCountByCourseDocument} {...props} />
    );
    
export type GetBookingsCountByCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables> & TChildProps;
export function withGetBookingsCountByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsCountByCourseQuery,
  GetBookingsCountByCourseQueryVariables,
  GetBookingsCountByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables, GetBookingsCountByCourseProps<TChildProps>>(GetBookingsCountByCourseDocument, {
      alias: 'getBookingsCountByCourse',
      ...operationOptions
    });
};

/**
 * __useGetBookingsCountByCourseQuery__
 *
 * To run a query within a React component, call `useGetBookingsCountByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsCountByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsCountByCourseQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingsCountByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>(GetBookingsCountByCourseDocument, baseOptions);
      }
export function useGetBookingsCountByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>(GetBookingsCountByCourseDocument, baseOptions);
        }
export type GetBookingsCountByCourseQueryHookResult = ReturnType<typeof useGetBookingsCountByCourseQuery>;
export type GetBookingsCountByCourseLazyQueryHookResult = ReturnType<typeof useGetBookingsCountByCourseLazyQuery>;
export type GetBookingsCountByCourseQueryResult = ApolloReactCommon.QueryResult<GetBookingsCountByCourseQuery, GetBookingsCountByCourseQueryVariables>;
export const RemoveAllBookingsDocument = gql`
    mutation RemoveAllBookings {
  removeAllBookings
}
    `;
export type RemoveAllBookingsMutationFn = ApolloReactCommon.MutationFunction<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables>;
export type RemoveAllBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables>, 'mutation'>;

    export const RemoveAllBookingsComponent = (props: RemoveAllBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables> mutation={RemoveAllBookingsDocument} {...props} />
    );
    
export type RemoveAllBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables> & TChildProps;
export function withRemoveAllBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveAllBookingsMutation,
  RemoveAllBookingsMutationVariables,
  RemoveAllBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables, RemoveAllBookingsProps<TChildProps>>(RemoveAllBookingsDocument, {
      alias: 'removeAllBookings',
      ...operationOptions
    });
};

/**
 * __useRemoveAllBookingsMutation__
 *
 * To run a mutation, you first call `useRemoveAllBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllBookingsMutation, { data, loading, error }] = useRemoveAllBookingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables>(RemoveAllBookingsDocument, baseOptions);
      }
export type RemoveAllBookingsMutationHookResult = ReturnType<typeof useRemoveAllBookingsMutation>;
export type RemoveAllBookingsMutationResult = ApolloReactCommon.MutationResult<RemoveAllBookingsMutation>;
export type RemoveAllBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAllBookingsMutation, RemoveAllBookingsMutationVariables>;
export const SendBookingConfirmationDocument = gql`
    mutation SendBookingConfirmation($bookingId: ID!) {
  sendBookingConfirmation(bookingId: $bookingId)
}
    `;
export type SendBookingConfirmationMutationFn = ApolloReactCommon.MutationFunction<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables>;
export type SendBookingConfirmationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables>, 'mutation'>;

    export const SendBookingConfirmationComponent = (props: SendBookingConfirmationComponentProps) => (
      <ApolloReactComponents.Mutation<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables> mutation={SendBookingConfirmationDocument} {...props} />
    );
    
export type SendBookingConfirmationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables> & TChildProps;
export function withSendBookingConfirmation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendBookingConfirmationMutation,
  SendBookingConfirmationMutationVariables,
  SendBookingConfirmationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables, SendBookingConfirmationProps<TChildProps>>(SendBookingConfirmationDocument, {
      alias: 'sendBookingConfirmation',
      ...operationOptions
    });
};

/**
 * __useSendBookingConfirmationMutation__
 *
 * To run a mutation, you first call `useSendBookingConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingConfirmationMutation, { data, loading, error }] = useSendBookingConfirmationMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useSendBookingConfirmationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables>(SendBookingConfirmationDocument, baseOptions);
      }
export type SendBookingConfirmationMutationHookResult = ReturnType<typeof useSendBookingConfirmationMutation>;
export type SendBookingConfirmationMutationResult = ApolloReactCommon.MutationResult<SendBookingConfirmationMutation>;
export type SendBookingConfirmationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBookingConfirmationMutation, SendBookingConfirmationMutationVariables>;
export const GetVariablesForBookingWithCourseDocument = gql`
    query getVariablesForBookingWithCourse($id: ID!) {
  bookingById(id: $id) {
    id
    attendee {
      id
      firstname
      lastname
      birthday
      booker {
        id
        firstname
        lastname
      }
    }
    courseLessons {
      id
      startDateTime
      endDateTime
      course {
        id
        prefixedCourseNumber
        courseType {
          id
          name
          description
        }
      }
      location {
        id
        city
        name
      }
    }
    creditedBalance
    memberDiscount
  }
}
    `;
export type GetVariablesForBookingWithCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>, 'query'> & ({ variables: GetVariablesForBookingWithCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetVariablesForBookingWithCourseComponent = (props: GetVariablesForBookingWithCourseComponentProps) => (
      <ApolloReactComponents.Query<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables> query={GetVariablesForBookingWithCourseDocument} {...props} />
    );
    
export type GetVariablesForBookingWithCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables> & TChildProps;
export function withGetVariablesForBookingWithCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetVariablesForBookingWithCourseQuery,
  GetVariablesForBookingWithCourseQueryVariables,
  GetVariablesForBookingWithCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables, GetVariablesForBookingWithCourseProps<TChildProps>>(GetVariablesForBookingWithCourseDocument, {
      alias: 'getVariablesForBookingWithCourse',
      ...operationOptions
    });
};

/**
 * __useGetVariablesForBookingWithCourseQuery__
 *
 * To run a query within a React component, call `useGetVariablesForBookingWithCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVariablesForBookingWithCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVariablesForBookingWithCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVariablesForBookingWithCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>(GetVariablesForBookingWithCourseDocument, baseOptions);
      }
export function useGetVariablesForBookingWithCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>(GetVariablesForBookingWithCourseDocument, baseOptions);
        }
export type GetVariablesForBookingWithCourseQueryHookResult = ReturnType<typeof useGetVariablesForBookingWithCourseQuery>;
export type GetVariablesForBookingWithCourseLazyQueryHookResult = ReturnType<typeof useGetVariablesForBookingWithCourseLazyQuery>;
export type GetVariablesForBookingWithCourseQueryResult = ApolloReactCommon.QueryResult<GetVariablesForBookingWithCourseQuery, GetVariablesForBookingWithCourseQueryVariables>;
export const ConvertBookingToWaitlistDocument = gql`
    mutation convertBookingToWaitlist($bookingId: ID!) {
  convertBookingToWaitlist(bookingId: $bookingId) {
    id
    bookingType
    bookingNumberPrefix
    bookingNumber
    attendee {
      id
      firstname
      lastname
    }
  }
}
    `;
export type ConvertBookingToWaitlistMutationFn = ApolloReactCommon.MutationFunction<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables>;
export type ConvertBookingToWaitlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables>, 'mutation'>;

    export const ConvertBookingToWaitlistComponent = (props: ConvertBookingToWaitlistComponentProps) => (
      <ApolloReactComponents.Mutation<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables> mutation={ConvertBookingToWaitlistDocument} {...props} />
    );
    
export type ConvertBookingToWaitlistProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables> & TChildProps;
export function withConvertBookingToWaitlist<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConvertBookingToWaitlistMutation,
  ConvertBookingToWaitlistMutationVariables,
  ConvertBookingToWaitlistProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables, ConvertBookingToWaitlistProps<TChildProps>>(ConvertBookingToWaitlistDocument, {
      alias: 'convertBookingToWaitlist',
      ...operationOptions
    });
};

/**
 * __useConvertBookingToWaitlistMutation__
 *
 * To run a mutation, you first call `useConvertBookingToWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertBookingToWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertBookingToWaitlistMutation, { data, loading, error }] = useConvertBookingToWaitlistMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useConvertBookingToWaitlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables>) {
        return ApolloReactHooks.useMutation<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables>(ConvertBookingToWaitlistDocument, baseOptions);
      }
export type ConvertBookingToWaitlistMutationHookResult = ReturnType<typeof useConvertBookingToWaitlistMutation>;
export type ConvertBookingToWaitlistMutationResult = ApolloReactCommon.MutationResult<ConvertBookingToWaitlistMutation>;
export type ConvertBookingToWaitlistMutationOptions = ApolloReactCommon.BaseMutationOptions<ConvertBookingToWaitlistMutation, ConvertBookingToWaitlistMutationVariables>;
export const ConvertWaitlistToBookingDocument = gql`
    mutation convertWaitlistToBooking($bookingId: ID!) {
  convertWaitlistToBooking(bookingId: $bookingId) {
    id
    bookingType
    bookingNumberPrefix
    bookingNumber
    attendee {
      id
      firstname
      lastname
    }
  }
}
    `;
export type ConvertWaitlistToBookingMutationFn = ApolloReactCommon.MutationFunction<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables>;
export type ConvertWaitlistToBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables>, 'mutation'>;

    export const ConvertWaitlistToBookingComponent = (props: ConvertWaitlistToBookingComponentProps) => (
      <ApolloReactComponents.Mutation<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables> mutation={ConvertWaitlistToBookingDocument} {...props} />
    );
    
export type ConvertWaitlistToBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables> & TChildProps;
export function withConvertWaitlistToBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConvertWaitlistToBookingMutation,
  ConvertWaitlistToBookingMutationVariables,
  ConvertWaitlistToBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables, ConvertWaitlistToBookingProps<TChildProps>>(ConvertWaitlistToBookingDocument, {
      alias: 'convertWaitlistToBooking',
      ...operationOptions
    });
};

/**
 * __useConvertWaitlistToBookingMutation__
 *
 * To run a mutation, you first call `useConvertWaitlistToBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertWaitlistToBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertWaitlistToBookingMutation, { data, loading, error }] = useConvertWaitlistToBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useConvertWaitlistToBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables>(ConvertWaitlistToBookingDocument, baseOptions);
      }
export type ConvertWaitlistToBookingMutationHookResult = ReturnType<typeof useConvertWaitlistToBookingMutation>;
export type ConvertWaitlistToBookingMutationResult = ApolloReactCommon.MutationResult<ConvertWaitlistToBookingMutation>;
export type ConvertWaitlistToBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<ConvertWaitlistToBookingMutation, ConvertWaitlistToBookingMutationVariables>;
export const TransferAttendeeToCourseDocument = gql`
    mutation transferAttendeeToCourse($bookingId: ID!, $targetCourseId: ID!, $bookingDeleteAction: BookingDeleteAction, $usePlaceReservation: Boolean) {
  transferAttendeeToCourse(bookingId: $bookingId, targetCourseId: $targetCourseId, bookingDeleteAction: $bookingDeleteAction, usePlaceReservation: $usePlaceReservation) {
    id
    bookingType
    bookingNumberPrefix
    bookingType
    attendee {
      id
      firstname
      lastname
    }
    courseLessons {
      id
      course {
        id
        prefixedCourseNumber
        courseType {
          id
          name
        }
      }
    }
  }
}
    `;
export type TransferAttendeeToCourseMutationFn = ApolloReactCommon.MutationFunction<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables>;
export type TransferAttendeeToCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables>, 'mutation'>;

    export const TransferAttendeeToCourseComponent = (props: TransferAttendeeToCourseComponentProps) => (
      <ApolloReactComponents.Mutation<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables> mutation={TransferAttendeeToCourseDocument} {...props} />
    );
    
export type TransferAttendeeToCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables> & TChildProps;
export function withTransferAttendeeToCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransferAttendeeToCourseMutation,
  TransferAttendeeToCourseMutationVariables,
  TransferAttendeeToCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables, TransferAttendeeToCourseProps<TChildProps>>(TransferAttendeeToCourseDocument, {
      alias: 'transferAttendeeToCourse',
      ...operationOptions
    });
};

/**
 * __useTransferAttendeeToCourseMutation__
 *
 * To run a mutation, you first call `useTransferAttendeeToCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferAttendeeToCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferAttendeeToCourseMutation, { data, loading, error }] = useTransferAttendeeToCourseMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      targetCourseId: // value for 'targetCourseId'
 *      bookingDeleteAction: // value for 'bookingDeleteAction'
 *      usePlaceReservation: // value for 'usePlaceReservation'
 *   },
 * });
 */
export function useTransferAttendeeToCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables>(TransferAttendeeToCourseDocument, baseOptions);
      }
export type TransferAttendeeToCourseMutationHookResult = ReturnType<typeof useTransferAttendeeToCourseMutation>;
export type TransferAttendeeToCourseMutationResult = ApolloReactCommon.MutationResult<TransferAttendeeToCourseMutation>;
export type TransferAttendeeToCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferAttendeeToCourseMutation, TransferAttendeeToCourseMutationVariables>;
export const CopyAttendeeToCourseDocument = gql`
    mutation copyAttendeeToCourse($bookingId: ID!, $targetCourseId: ID!, $usePlaceReservation: Boolean) {
  copyAttendeeToCourse(bookingId: $bookingId, targetCourseId: $targetCourseId, usePlaceReservation: $usePlaceReservation) {
    id
    bookingType
    bookingNumberPrefix
    bookingType
    attendee {
      id
      firstname
      lastname
    }
    courseLessons {
      id
      course {
        id
        prefixedCourseNumber
        courseType {
          id
          name
        }
      }
    }
  }
}
    `;
export type CopyAttendeeToCourseMutationFn = ApolloReactCommon.MutationFunction<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables>;
export type CopyAttendeeToCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables>, 'mutation'>;

    export const CopyAttendeeToCourseComponent = (props: CopyAttendeeToCourseComponentProps) => (
      <ApolloReactComponents.Mutation<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables> mutation={CopyAttendeeToCourseDocument} {...props} />
    );
    
export type CopyAttendeeToCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables> & TChildProps;
export function withCopyAttendeeToCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CopyAttendeeToCourseMutation,
  CopyAttendeeToCourseMutationVariables,
  CopyAttendeeToCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables, CopyAttendeeToCourseProps<TChildProps>>(CopyAttendeeToCourseDocument, {
      alias: 'copyAttendeeToCourse',
      ...operationOptions
    });
};

/**
 * __useCopyAttendeeToCourseMutation__
 *
 * To run a mutation, you first call `useCopyAttendeeToCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAttendeeToCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAttendeeToCourseMutation, { data, loading, error }] = useCopyAttendeeToCourseMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      targetCourseId: // value for 'targetCourseId'
 *      usePlaceReservation: // value for 'usePlaceReservation'
 *   },
 * });
 */
export function useCopyAttendeeToCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables>(CopyAttendeeToCourseDocument, baseOptions);
      }
export type CopyAttendeeToCourseMutationHookResult = ReturnType<typeof useCopyAttendeeToCourseMutation>;
export type CopyAttendeeToCourseMutationResult = ApolloReactCommon.MutationResult<CopyAttendeeToCourseMutation>;
export type CopyAttendeeToCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyAttendeeToCourseMutation, CopyAttendeeToCourseMutationVariables>;
export const GetBookingsByIdsDocument = gql`
    query GetBookingsByIds($ids: [String!]!) {
  getBookingsByIds(ids: $ids) {
    id
    bookingNumberPrefix
    bookingNumber
    bookingType
    courseId
    attendee {
      id
      booker {
        id
        firstname
        lastname
        email
        mobileNumber
      }
    }
    canceled
    waitListCount
  }
}
    `;
export type GetBookingsByIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>, 'query'> & ({ variables: GetBookingsByIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByIdsComponent = (props: GetBookingsByIdsComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables> query={GetBookingsByIdsDocument} {...props} />
    );
    
export type GetBookingsByIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables> & TChildProps;
export function withGetBookingsByIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByIdsQuery,
  GetBookingsByIdsQueryVariables,
  GetBookingsByIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables, GetBookingsByIdsProps<TChildProps>>(GetBookingsByIdsDocument, {
      alias: 'getBookingsByIds',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByIdsQuery__
 *
 * To run a query within a React component, call `useGetBookingsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBookingsByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>(GetBookingsByIdsDocument, baseOptions);
      }
export function useGetBookingsByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>(GetBookingsByIdsDocument, baseOptions);
        }
export type GetBookingsByIdsQueryHookResult = ReturnType<typeof useGetBookingsByIdsQuery>;
export type GetBookingsByIdsLazyQueryHookResult = ReturnType<typeof useGetBookingsByIdsLazyQuery>;
export type GetBookingsByIdsQueryResult = ApolloReactCommon.QueryResult<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>;
export const GetBookingsByScheduledPaymentIdsDocument = gql`
    query GetBookingsByScheduledPaymentIds($scheduledPaymentIds: [String!]!) {
  getBookingsByScheduledPaymentIds(scheduledPaymentIds: $scheduledPaymentIds) {
    id
    bookingNumberPrefix
    bookingNumber
    bookingType
    attendee {
      id
      booker {
        id
        firstname
        lastname
        email
      }
    }
    canceled
  }
}
    `;
export type GetBookingsByScheduledPaymentIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>, 'query'> & ({ variables: GetBookingsByScheduledPaymentIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByScheduledPaymentIdsComponent = (props: GetBookingsByScheduledPaymentIdsComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables> query={GetBookingsByScheduledPaymentIdsDocument} {...props} />
    );
    
export type GetBookingsByScheduledPaymentIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables> & TChildProps;
export function withGetBookingsByScheduledPaymentIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByScheduledPaymentIdsQuery,
  GetBookingsByScheduledPaymentIdsQueryVariables,
  GetBookingsByScheduledPaymentIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables, GetBookingsByScheduledPaymentIdsProps<TChildProps>>(GetBookingsByScheduledPaymentIdsDocument, {
      alias: 'getBookingsByScheduledPaymentIds',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByScheduledPaymentIdsQuery__
 *
 * To run a query within a React component, call `useGetBookingsByScheduledPaymentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByScheduledPaymentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByScheduledPaymentIdsQuery({
 *   variables: {
 *      scheduledPaymentIds: // value for 'scheduledPaymentIds'
 *   },
 * });
 */
export function useGetBookingsByScheduledPaymentIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>(GetBookingsByScheduledPaymentIdsDocument, baseOptions);
      }
export function useGetBookingsByScheduledPaymentIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>(GetBookingsByScheduledPaymentIdsDocument, baseOptions);
        }
export type GetBookingsByScheduledPaymentIdsQueryHookResult = ReturnType<typeof useGetBookingsByScheduledPaymentIdsQuery>;
export type GetBookingsByScheduledPaymentIdsLazyQueryHookResult = ReturnType<typeof useGetBookingsByScheduledPaymentIdsLazyQuery>;
export type GetBookingsByScheduledPaymentIdsQueryResult = ApolloReactCommon.QueryResult<GetBookingsByScheduledPaymentIdsQuery, GetBookingsByScheduledPaymentIdsQueryVariables>;
export const GetBookingsByInvoiceIdsDocument = gql`
    query getBookingsByInvoiceIds($invoiceIds: [String!]!) {
  getBookingsByInvoiceIds(invoiceIds: $invoiceIds) {
    id
    bookingNumberPrefix
    bookingNumber
    bookingType
  }
}
    `;
export type GetBookingsByInvoiceIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>, 'query'> & ({ variables: GetBookingsByInvoiceIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByInvoiceIdsComponent = (props: GetBookingsByInvoiceIdsComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables> query={GetBookingsByInvoiceIdsDocument} {...props} />
    );
    
export type GetBookingsByInvoiceIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables> & TChildProps;
export function withGetBookingsByInvoiceIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByInvoiceIdsQuery,
  GetBookingsByInvoiceIdsQueryVariables,
  GetBookingsByInvoiceIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables, GetBookingsByInvoiceIdsProps<TChildProps>>(GetBookingsByInvoiceIdsDocument, {
      alias: 'getBookingsByInvoiceIds',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByInvoiceIdsQuery__
 *
 * To run a query within a React component, call `useGetBookingsByInvoiceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByInvoiceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByInvoiceIdsQuery({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useGetBookingsByInvoiceIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>(GetBookingsByInvoiceIdsDocument, baseOptions);
      }
export function useGetBookingsByInvoiceIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>(GetBookingsByInvoiceIdsDocument, baseOptions);
        }
export type GetBookingsByInvoiceIdsQueryHookResult = ReturnType<typeof useGetBookingsByInvoiceIdsQuery>;
export type GetBookingsByInvoiceIdsLazyQueryHookResult = ReturnType<typeof useGetBookingsByInvoiceIdsLazyQuery>;
export type GetBookingsByInvoiceIdsQueryResult = ApolloReactCommon.QueryResult<GetBookingsByInvoiceIdsQuery, GetBookingsByInvoiceIdsQueryVariables>;
export const GetBookingsByAttendeeIdWithOptionsDocument = gql`
    query GetBookingsByAttendeeIdWithOptions($id: ID!, $startDateTime: DateTime, $endDateTime: DateTime, $canceledBookings: Boolean) {
  getBookingsByAttendeeIdWithOptions(id: $id, startDateTime: $startDateTime, endDateTime: $endDateTime, canceledBookings: $canceledBookings) {
    id
    bookingNumber
    bookingNumberPrefix
    bookingType
    updateDate
    createDate
    startDateTime
    courseId
    noticeStaff
    attendanceListNote
    noticeBooker
    coursePriceId
    payDate
    grossPrice
    netPrice
    vat
    isSpecial
    coursePrice {
      id
      grossPrice
      netPrice
      vat
    }
    invoice {
      id
      billed
      invoiceNumber
      invoiceNumberPrefix
      createDate
    }
    reversalInvoice {
      id
      invoiceNumber
      invoiceNumberPrefix
      createDate
    }
    paymentType
    courseLessons {
      id
      startDateTime
      endDateTime
      locationId
      course {
        id
        freePlaces
        startDateTime
        paymentInterval
        minAttendees
        maxAttendees
        prefixedCourseNumber
        allowMonthlyPayment
        paymentInterval
        courseType {
          id
          name
          color
        }
        instructors {
          id
          firstname
          lastname
        }
        lessonCount
      }
      location {
        id
        city
        name
      }
    }
    attendee {
      id
      gender
      firstname
      lastname
      birthday
      customerNumberPrefix
      customerNumber
      booker {
        id
        gender
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        street
        houseNumber
        postCode
        city
        phoneNumber
        mobileNumber
        email
        gender
        nonDisclosureNotice
        credit
      }
    }
    bookedBy
    canceled
    emailNotifications {
      id
      templateType
      subject
      createDate
      bookingId
      email
    }
    bookingConfirmationSent
    waitingListConfirmationSent
    cancelationSent
    invoiceSent
    paymentMethod {
      id
      type
      cash
      esr
      iban
      bank
      bic
      sepaAccountholder
      bankTransfer
    }
    waitListCount
  }
}
    `;
export type GetBookingsByAttendeeIdWithOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>, 'query'> & ({ variables: GetBookingsByAttendeeIdWithOptionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByAttendeeIdWithOptionsComponent = (props: GetBookingsByAttendeeIdWithOptionsComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables> query={GetBookingsByAttendeeIdWithOptionsDocument} {...props} />
    );
    
export type GetBookingsByAttendeeIdWithOptionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables> & TChildProps;
export function withGetBookingsByAttendeeIdWithOptions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByAttendeeIdWithOptionsQuery,
  GetBookingsByAttendeeIdWithOptionsQueryVariables,
  GetBookingsByAttendeeIdWithOptionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables, GetBookingsByAttendeeIdWithOptionsProps<TChildProps>>(GetBookingsByAttendeeIdWithOptionsDocument, {
      alias: 'getBookingsByAttendeeIdWithOptions',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByAttendeeIdWithOptionsQuery__
 *
 * To run a query within a React component, call `useGetBookingsByAttendeeIdWithOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByAttendeeIdWithOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByAttendeeIdWithOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      canceledBookings: // value for 'canceledBookings'
 *   },
 * });
 */
export function useGetBookingsByAttendeeIdWithOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>(GetBookingsByAttendeeIdWithOptionsDocument, baseOptions);
      }
export function useGetBookingsByAttendeeIdWithOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>(GetBookingsByAttendeeIdWithOptionsDocument, baseOptions);
        }
export type GetBookingsByAttendeeIdWithOptionsQueryHookResult = ReturnType<typeof useGetBookingsByAttendeeIdWithOptionsQuery>;
export type GetBookingsByAttendeeIdWithOptionsLazyQueryHookResult = ReturnType<typeof useGetBookingsByAttendeeIdWithOptionsLazyQuery>;
export type GetBookingsByAttendeeIdWithOptionsQueryResult = ApolloReactCommon.QueryResult<GetBookingsByAttendeeIdWithOptionsQuery, GetBookingsByAttendeeIdWithOptionsQueryVariables>;
export const UpdateAttendanceListNoteDocument = gql`
    mutation UpdateAttendanceListNote($bookingId: ID!, $attendanceListNote: String) {
  updateAttendanceListNote(bookingId: $bookingId, attendanceListNote: $attendanceListNote) {
    id
    attendanceListNote
  }
}
    `;
export type UpdateAttendanceListNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables>;
export type UpdateAttendanceListNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables>, 'mutation'>;

    export const UpdateAttendanceListNoteComponent = (props: UpdateAttendanceListNoteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables> mutation={UpdateAttendanceListNoteDocument} {...props} />
    );
    
export type UpdateAttendanceListNoteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables> & TChildProps;
export function withUpdateAttendanceListNote<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAttendanceListNoteMutation,
  UpdateAttendanceListNoteMutationVariables,
  UpdateAttendanceListNoteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables, UpdateAttendanceListNoteProps<TChildProps>>(UpdateAttendanceListNoteDocument, {
      alias: 'updateAttendanceListNote',
      ...operationOptions
    });
};

/**
 * __useUpdateAttendanceListNoteMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceListNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceListNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceListNoteMutation, { data, loading, error }] = useUpdateAttendanceListNoteMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      attendanceListNote: // value for 'attendanceListNote'
 *   },
 * });
 */
export function useUpdateAttendanceListNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables>(UpdateAttendanceListNoteDocument, baseOptions);
      }
export type UpdateAttendanceListNoteMutationHookResult = ReturnType<typeof useUpdateAttendanceListNoteMutation>;
export type UpdateAttendanceListNoteMutationResult = ApolloReactCommon.MutationResult<UpdateAttendanceListNoteMutation>;
export type UpdateAttendanceListNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAttendanceListNoteMutation, UpdateAttendanceListNoteMutationVariables>;
export const UpdateBookingPaymentMethodDocument = gql`
    mutation UpdateBookingPaymentMethod($bookingId: ID!, $paymentMethodId: ID!) {
  updateBookingPaymentMethod(bookingId: $bookingId, paymentMethodId: $paymentMethodId) {
    id
    paymentMethodId
  }
}
    `;
export type UpdateBookingPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables>;
export type UpdateBookingPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables>, 'mutation'>;

    export const UpdateBookingPaymentMethodComponent = (props: UpdateBookingPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables> mutation={UpdateBookingPaymentMethodDocument} {...props} />
    );
    
export type UpdateBookingPaymentMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables> & TChildProps;
export function withUpdateBookingPaymentMethod<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookingPaymentMethodMutation,
  UpdateBookingPaymentMethodMutationVariables,
  UpdateBookingPaymentMethodProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables, UpdateBookingPaymentMethodProps<TChildProps>>(UpdateBookingPaymentMethodDocument, {
      alias: 'updateBookingPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useUpdateBookingPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateBookingPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingPaymentMethodMutation, { data, loading, error }] = useUpdateBookingPaymentMethodMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateBookingPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables>(UpdateBookingPaymentMethodDocument, baseOptions);
      }
export type UpdateBookingPaymentMethodMutationHookResult = ReturnType<typeof useUpdateBookingPaymentMethodMutation>;
export type UpdateBookingPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateBookingPaymentMethodMutation>;
export type UpdateBookingPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookingPaymentMethodMutation, UpdateBookingPaymentMethodMutationVariables>;
export const AddMultiplePlaceReservationsDocument = gql`
    mutation AddMultiplePlaceReservations($courseId: ID!, $count: Float!) {
  addMultiplePlaceReservations(courseId: $courseId, count: $count) {
    id
  }
}
    `;
export type AddMultiplePlaceReservationsMutationFn = ApolloReactCommon.MutationFunction<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables>;
export type AddMultiplePlaceReservationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables>, 'mutation'>;

    export const AddMultiplePlaceReservationsComponent = (props: AddMultiplePlaceReservationsComponentProps) => (
      <ApolloReactComponents.Mutation<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables> mutation={AddMultiplePlaceReservationsDocument} {...props} />
    );
    
export type AddMultiplePlaceReservationsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables> & TChildProps;
export function withAddMultiplePlaceReservations<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddMultiplePlaceReservationsMutation,
  AddMultiplePlaceReservationsMutationVariables,
  AddMultiplePlaceReservationsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables, AddMultiplePlaceReservationsProps<TChildProps>>(AddMultiplePlaceReservationsDocument, {
      alias: 'addMultiplePlaceReservations',
      ...operationOptions
    });
};

/**
 * __useAddMultiplePlaceReservationsMutation__
 *
 * To run a mutation, you first call `useAddMultiplePlaceReservationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultiplePlaceReservationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultiplePlaceReservationsMutation, { data, loading, error }] = useAddMultiplePlaceReservationsMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useAddMultiplePlaceReservationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables>(AddMultiplePlaceReservationsDocument, baseOptions);
      }
export type AddMultiplePlaceReservationsMutationHookResult = ReturnType<typeof useAddMultiplePlaceReservationsMutation>;
export type AddMultiplePlaceReservationsMutationResult = ApolloReactCommon.MutationResult<AddMultiplePlaceReservationsMutation>;
export type AddMultiplePlaceReservationsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMultiplePlaceReservationsMutation, AddMultiplePlaceReservationsMutationVariables>;
export const DeleteAllPlaceReservationsByCourseIdDocument = gql`
    mutation DeleteAllPlaceReservationsByCourseId($courseId: ID!) {
  deleteAllPlaceReservationsByCourseId(courseId: $courseId)
}
    `;
export type DeleteAllPlaceReservationsByCourseIdMutationFn = ApolloReactCommon.MutationFunction<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables>;
export type DeleteAllPlaceReservationsByCourseIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables>, 'mutation'>;

    export const DeleteAllPlaceReservationsByCourseIdComponent = (props: DeleteAllPlaceReservationsByCourseIdComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables> mutation={DeleteAllPlaceReservationsByCourseIdDocument} {...props} />
    );
    
export type DeleteAllPlaceReservationsByCourseIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables> & TChildProps;
export function withDeleteAllPlaceReservationsByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAllPlaceReservationsByCourseIdMutation,
  DeleteAllPlaceReservationsByCourseIdMutationVariables,
  DeleteAllPlaceReservationsByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables, DeleteAllPlaceReservationsByCourseIdProps<TChildProps>>(DeleteAllPlaceReservationsByCourseIdDocument, {
      alias: 'deleteAllPlaceReservationsByCourseId',
      ...operationOptions
    });
};

/**
 * __useDeleteAllPlaceReservationsByCourseIdMutation__
 *
 * To run a mutation, you first call `useDeleteAllPlaceReservationsByCourseIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllPlaceReservationsByCourseIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllPlaceReservationsByCourseIdMutation, { data, loading, error }] = useDeleteAllPlaceReservationsByCourseIdMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDeleteAllPlaceReservationsByCourseIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables>(DeleteAllPlaceReservationsByCourseIdDocument, baseOptions);
      }
export type DeleteAllPlaceReservationsByCourseIdMutationHookResult = ReturnType<typeof useDeleteAllPlaceReservationsByCourseIdMutation>;
export type DeleteAllPlaceReservationsByCourseIdMutationResult = ApolloReactCommon.MutationResult<DeleteAllPlaceReservationsByCourseIdMutation>;
export type DeleteAllPlaceReservationsByCourseIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAllPlaceReservationsByCourseIdMutation, DeleteAllPlaceReservationsByCourseIdMutationVariables>;
export const DeleteSelectionOfWaitlistDocument = gql`
    mutation DeleteSelectionOfWaitlist($bookingIds: [ID!]!) {
  deleteSelectionOfWaitlist(bookingIds: $bookingIds)
}
    `;
export type DeleteSelectionOfWaitlistMutationFn = ApolloReactCommon.MutationFunction<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables>;
export type DeleteSelectionOfWaitlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables>, 'mutation'>;

    export const DeleteSelectionOfWaitlistComponent = (props: DeleteSelectionOfWaitlistComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables> mutation={DeleteSelectionOfWaitlistDocument} {...props} />
    );
    
export type DeleteSelectionOfWaitlistProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables> & TChildProps;
export function withDeleteSelectionOfWaitlist<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSelectionOfWaitlistMutation,
  DeleteSelectionOfWaitlistMutationVariables,
  DeleteSelectionOfWaitlistProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables, DeleteSelectionOfWaitlistProps<TChildProps>>(DeleteSelectionOfWaitlistDocument, {
      alias: 'deleteSelectionOfWaitlist',
      ...operationOptions
    });
};

/**
 * __useDeleteSelectionOfWaitlistMutation__
 *
 * To run a mutation, you first call `useDeleteSelectionOfWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelectionOfWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelectionOfWaitlistMutation, { data, loading, error }] = useDeleteSelectionOfWaitlistMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useDeleteSelectionOfWaitlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables>(DeleteSelectionOfWaitlistDocument, baseOptions);
      }
export type DeleteSelectionOfWaitlistMutationHookResult = ReturnType<typeof useDeleteSelectionOfWaitlistMutation>;
export type DeleteSelectionOfWaitlistMutationResult = ApolloReactCommon.MutationResult<DeleteSelectionOfWaitlistMutation>;
export type DeleteSelectionOfWaitlistMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSelectionOfWaitlistMutation, DeleteSelectionOfWaitlistMutationVariables>;
export const GetBookingsByCourseRefactoredDocument = gql`
    query GetBookingsByCourseRefactored($options: ArrayDataOptions, $id: ID!) {
  getBookingsByCourse(options: $options, id: $id) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      updateDate
      createDate
      noticeStaff
      noticeBooker
      attendanceListNote
      coursePriceId
      courseId
      noticeBooker
      payDate
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        id
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        id
        startDateTime
        endDateTime
        locationId
        course {
          id
          freePlaces
          startDateTime
          paymentInterval
          minAttendees
          maxAttendees
          prefixedCourseNumber
          allowMonthlyPayment
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          gender
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          gender
          nonDisclosureNotice
          credit
        }
      }
      bookedBy
      canceled
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      waitListCount
    }
  }
}
    `;
export type GetBookingsByCourseRefactoredComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>, 'query'> & ({ variables: GetBookingsByCourseRefactoredQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByCourseRefactoredComponent = (props: GetBookingsByCourseRefactoredComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables> query={GetBookingsByCourseRefactoredDocument} {...props} />
    );
    
export type GetBookingsByCourseRefactoredProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables> & TChildProps;
export function withGetBookingsByCourseRefactored<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByCourseRefactoredQuery,
  GetBookingsByCourseRefactoredQueryVariables,
  GetBookingsByCourseRefactoredProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables, GetBookingsByCourseRefactoredProps<TChildProps>>(GetBookingsByCourseRefactoredDocument, {
      alias: 'getBookingsByCourseRefactored',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByCourseRefactoredQuery__
 *
 * To run a query within a React component, call `useGetBookingsByCourseRefactoredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByCourseRefactoredQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByCourseRefactoredQuery({
 *   variables: {
 *      options: // value for 'options'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingsByCourseRefactoredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>(GetBookingsByCourseRefactoredDocument, baseOptions);
      }
export function useGetBookingsByCourseRefactoredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>(GetBookingsByCourseRefactoredDocument, baseOptions);
        }
export type GetBookingsByCourseRefactoredQueryHookResult = ReturnType<typeof useGetBookingsByCourseRefactoredQuery>;
export type GetBookingsByCourseRefactoredLazyQueryHookResult = ReturnType<typeof useGetBookingsByCourseRefactoredLazyQuery>;
export type GetBookingsByCourseRefactoredQueryResult = ApolloReactCommon.QueryResult<GetBookingsByCourseRefactoredQuery, GetBookingsByCourseRefactoredQueryVariables>;
export const TransferMultipleBookingsDocument = gql`
    mutation TransferMultipleBookings($transferMultipleBookingsInput: TransferMultipleBookingsInput!) {
  transferMultipleBookings(transferMultipleBookingsInput: $transferMultipleBookingsInput) {
    id
    bookingNumberPrefix
    bookingNumber
    grossPrice
    courseId
    scheduledPayments {
      id
      paymentDue
      paymentAmount
      type
    }
    attendee {
      id
      firstname
      lastname
    }
  }
}
    `;
export type TransferMultipleBookingsMutationFn = ApolloReactCommon.MutationFunction<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables>;
export type TransferMultipleBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables>, 'mutation'>;

    export const TransferMultipleBookingsComponent = (props: TransferMultipleBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables> mutation={TransferMultipleBookingsDocument} {...props} />
    );
    
export type TransferMultipleBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables> & TChildProps;
export function withTransferMultipleBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransferMultipleBookingsMutation,
  TransferMultipleBookingsMutationVariables,
  TransferMultipleBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables, TransferMultipleBookingsProps<TChildProps>>(TransferMultipleBookingsDocument, {
      alias: 'transferMultipleBookings',
      ...operationOptions
    });
};

/**
 * __useTransferMultipleBookingsMutation__
 *
 * To run a mutation, you first call `useTransferMultipleBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferMultipleBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferMultipleBookingsMutation, { data, loading, error }] = useTransferMultipleBookingsMutation({
 *   variables: {
 *      transferMultipleBookingsInput: // value for 'transferMultipleBookingsInput'
 *   },
 * });
 */
export function useTransferMultipleBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables>(TransferMultipleBookingsDocument, baseOptions);
      }
export type TransferMultipleBookingsMutationHookResult = ReturnType<typeof useTransferMultipleBookingsMutation>;
export type TransferMultipleBookingsMutationResult = ApolloReactCommon.MutationResult<TransferMultipleBookingsMutation>;
export type TransferMultipleBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferMultipleBookingsMutation, TransferMultipleBookingsMutationVariables>;
export const GetBookingsInStartedCoursesDocument = gql`
    query GetBookingsInStartedCourses($options: ArrayDataOptions) {
  bookingsInStartedCourses(options: $options) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      updateDate
      createDate
      startDateTime
      courseId
      noticeStaff
      attendanceListNote
      noticeBooker
      coursePriceId
      payDate
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        id
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        id
        startDateTime
        endDateTime
        locationId
        course {
          id
          freePlaces
          startDateTime
          paymentInterval
          minAttendees
          maxAttendees
          prefixedCourseNumber
          allowMonthlyPayment
          paymentInterval
          courseType {
            id
            name
            color
          }
          instructors {
            id
            firstname
            lastname
          }
          lessonCount
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          gender
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          gender
          nonDisclosureNotice
          credit
        }
      }
      bookedBy
      canceled
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      waitListCount
      additionalInfo
    }
  }
}
    `;
export type GetBookingsInStartedCoursesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>, 'query'>;

    export const GetBookingsInStartedCoursesComponent = (props: GetBookingsInStartedCoursesComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables> query={GetBookingsInStartedCoursesDocument} {...props} />
    );
    
export type GetBookingsInStartedCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables> & TChildProps;
export function withGetBookingsInStartedCourses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsInStartedCoursesQuery,
  GetBookingsInStartedCoursesQueryVariables,
  GetBookingsInStartedCoursesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables, GetBookingsInStartedCoursesProps<TChildProps>>(GetBookingsInStartedCoursesDocument, {
      alias: 'getBookingsInStartedCourses',
      ...operationOptions
    });
};

/**
 * __useGetBookingsInStartedCoursesQuery__
 *
 * To run a query within a React component, call `useGetBookingsInStartedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsInStartedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsInStartedCoursesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBookingsInStartedCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>(GetBookingsInStartedCoursesDocument, baseOptions);
      }
export function useGetBookingsInStartedCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>(GetBookingsInStartedCoursesDocument, baseOptions);
        }
export type GetBookingsInStartedCoursesQueryHookResult = ReturnType<typeof useGetBookingsInStartedCoursesQuery>;
export type GetBookingsInStartedCoursesLazyQueryHookResult = ReturnType<typeof useGetBookingsInStartedCoursesLazyQuery>;
export type GetBookingsInStartedCoursesQueryResult = ApolloReactCommon.QueryResult<GetBookingsInStartedCoursesQuery, GetBookingsInStartedCoursesQueryVariables>;
export const AuthenticateDocument = gql`
    query Authenticate {
  authenticate {
    id
    email
    firstName
    lastName
    roles
    hasProfile
  }
}
    `;
export type AuthenticateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthenticateQuery, AuthenticateQueryVariables>, 'query'>;

    export const AuthenticateComponent = (props: AuthenticateComponentProps) => (
      <ApolloReactComponents.Query<AuthenticateQuery, AuthenticateQueryVariables> query={AuthenticateDocument} {...props} />
    );
    
export type AuthenticateProps<TChildProps = {}> = ApolloReactHoc.DataProps<AuthenticateQuery, AuthenticateQueryVariables> & TChildProps;
export function withAuthenticate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthenticateQuery,
  AuthenticateQueryVariables,
  AuthenticateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AuthenticateQuery, AuthenticateQueryVariables, AuthenticateProps<TChildProps>>(AuthenticateDocument, {
      alias: 'authenticate',
      ...operationOptions
    });
};

/**
 * __useAuthenticateQuery__
 *
 * To run a query within a React component, call `useAuthenticateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthenticateQuery, AuthenticateQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthenticateQuery, AuthenticateQueryVariables>(AuthenticateDocument, baseOptions);
      }
export function useAuthenticateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthenticateQuery, AuthenticateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthenticateQuery, AuthenticateQueryVariables>(AuthenticateDocument, baseOptions);
        }
export type AuthenticateQueryHookResult = ReturnType<typeof useAuthenticateQuery>;
export type AuthenticateLazyQueryHookResult = ReturnType<typeof useAuthenticateLazyQuery>;
export type AuthenticateQueryResult = ApolloReactCommon.QueryResult<AuthenticateQuery, AuthenticateQueryVariables>;
export const GetUserRolesDocument = gql`
    query GetUserRoles {
  getUserRoles
}
    `;
export type GetUserRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserRolesQuery, GetUserRolesQueryVariables>, 'query'>;

    export const GetUserRolesComponent = (props: GetUserRolesComponentProps) => (
      <ApolloReactComponents.Query<GetUserRolesQuery, GetUserRolesQueryVariables> query={GetUserRolesDocument} {...props} />
    );
    
export type GetUserRolesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserRolesQuery, GetUserRolesQueryVariables> & TChildProps;
export function withGetUserRoles<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserRolesQuery,
  GetUserRolesQueryVariables,
  GetUserRolesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserRolesQuery, GetUserRolesQueryVariables, GetUserRolesProps<TChildProps>>(GetUserRolesDocument, {
      alias: 'getUserRoles',
      ...operationOptions
    });
};

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, baseOptions);
      }
export function useGetUserRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, baseOptions);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = ApolloReactCommon.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export const DebugDefaultCourseDetailsDocument = gql`
    mutation DebugDefaultCourseDetails($courseId: ID!) {
  refactoredCourseDetails(courseId: $courseId)
}
    `;
export type DebugDefaultCourseDetailsMutationFn = ApolloReactCommon.MutationFunction<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables>;
export type DebugDefaultCourseDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables>, 'mutation'>;

    export const DebugDefaultCourseDetailsComponent = (props: DebugDefaultCourseDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables> mutation={DebugDefaultCourseDetailsDocument} {...props} />
    );
    
export type DebugDefaultCourseDetailsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables> & TChildProps;
export function withDebugDefaultCourseDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DebugDefaultCourseDetailsMutation,
  DebugDefaultCourseDetailsMutationVariables,
  DebugDefaultCourseDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables, DebugDefaultCourseDetailsProps<TChildProps>>(DebugDefaultCourseDetailsDocument, {
      alias: 'debugDefaultCourseDetails',
      ...operationOptions
    });
};

/**
 * __useDebugDefaultCourseDetailsMutation__
 *
 * To run a mutation, you first call `useDebugDefaultCourseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebugDefaultCourseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debugDefaultCourseDetailsMutation, { data, loading, error }] = useDebugDefaultCourseDetailsMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDebugDefaultCourseDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables>(DebugDefaultCourseDetailsDocument, baseOptions);
      }
export type DebugDefaultCourseDetailsMutationHookResult = ReturnType<typeof useDebugDefaultCourseDetailsMutation>;
export type DebugDefaultCourseDetailsMutationResult = ApolloReactCommon.MutationResult<DebugDefaultCourseDetailsMutation>;
export type DebugDefaultCourseDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<DebugDefaultCourseDetailsMutation, DebugDefaultCourseDetailsMutationVariables>;
export const GetPaymentMethodTypeByIdDocument = gql`
    query GetPaymentMethodTypeById($paymentMethodId: String!) {
  getPaymentMethodTypeById(paymentMethodId: $paymentMethodId)
}
    `;
export type GetPaymentMethodTypeByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>, 'query'> & ({ variables: GetPaymentMethodTypeByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPaymentMethodTypeByIdComponent = (props: GetPaymentMethodTypeByIdComponentProps) => (
      <ApolloReactComponents.Query<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables> query={GetPaymentMethodTypeByIdDocument} {...props} />
    );
    
export type GetPaymentMethodTypeByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables> & TChildProps;
export function withGetPaymentMethodTypeById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPaymentMethodTypeByIdQuery,
  GetPaymentMethodTypeByIdQueryVariables,
  GetPaymentMethodTypeByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables, GetPaymentMethodTypeByIdProps<TChildProps>>(GetPaymentMethodTypeByIdDocument, {
      alias: 'getPaymentMethodTypeById',
      ...operationOptions
    });
};

/**
 * __useGetPaymentMethodTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodTypeByIdQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetPaymentMethodTypeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>(GetPaymentMethodTypeByIdDocument, baseOptions);
      }
export function useGetPaymentMethodTypeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>(GetPaymentMethodTypeByIdDocument, baseOptions);
        }
export type GetPaymentMethodTypeByIdQueryHookResult = ReturnType<typeof useGetPaymentMethodTypeByIdQuery>;
export type GetPaymentMethodTypeByIdLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodTypeByIdLazyQuery>;
export type GetPaymentMethodTypeByIdQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodTypeByIdQuery, GetPaymentMethodTypeByIdQueryVariables>;
export const GetUserInfoByIdDocument = gql`
    query GetUserInfoById($userId: String!) {
  getInfoUserByUserId(userId: $userId) {
    id
    email
    firstName
    lastName
  }
}
    `;
export type GetUserInfoByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>, 'query'> & ({ variables: GetUserInfoByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserInfoByIdComponent = (props: GetUserInfoByIdComponentProps) => (
      <ApolloReactComponents.Query<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables> query={GetUserInfoByIdDocument} {...props} />
    );
    
export type GetUserInfoByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables> & TChildProps;
export function withGetUserInfoById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserInfoByIdQuery,
  GetUserInfoByIdQueryVariables,
  GetUserInfoByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables, GetUserInfoByIdProps<TChildProps>>(GetUserInfoByIdDocument, {
      alias: 'getUserInfoById',
      ...operationOptions
    });
};

/**
 * __useGetUserInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetUserInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInfoByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, baseOptions);
      }
export function useGetUserInfoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, baseOptions);
        }
export type GetUserInfoByIdQueryHookResult = ReturnType<typeof useGetUserInfoByIdQuery>;
export type GetUserInfoByIdLazyQueryHookResult = ReturnType<typeof useGetUserInfoByIdLazyQuery>;
export type GetUserInfoByIdQueryResult = ApolloReactCommon.QueryResult<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    email
    firstName
    lastName
  }
}
    `;
export type GetCurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>, 'query'>;

    export const GetCurrentUserComponent = (props: GetCurrentUserComponentProps) => (
      <ApolloReactComponents.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> query={GetCurrentUserDocument} {...props} />
    );
    
export type GetCurrentUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCurrentUserQuery, GetCurrentUserQueryVariables> & TChildProps;
export function withGetCurrentUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetCurrentUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCurrentUserQuery, GetCurrentUserQueryVariables, GetCurrentUserProps<TChildProps>>(GetCurrentUserDocument, {
      alias: 'getCurrentUser',
      ...operationOptions
    });
};

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetAdminAndInstructorUsersDocument = gql`
    query GetAdminAndInstructorUsers($koOrganizationId: String!) {
  getAdminAndInstructorUsers(koOrganizationId: $koOrganizationId) {
    id
    email
    firstName
    lastName
    hasProfile
    roles
  }
}
    `;
export type GetAdminAndInstructorUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>, 'query'> & ({ variables: GetAdminAndInstructorUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAdminAndInstructorUsersComponent = (props: GetAdminAndInstructorUsersComponentProps) => (
      <ApolloReactComponents.Query<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables> query={GetAdminAndInstructorUsersDocument} {...props} />
    );
    
export type GetAdminAndInstructorUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables> & TChildProps;
export function withGetAdminAndInstructorUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAdminAndInstructorUsersQuery,
  GetAdminAndInstructorUsersQueryVariables,
  GetAdminAndInstructorUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables, GetAdminAndInstructorUsersProps<TChildProps>>(GetAdminAndInstructorUsersDocument, {
      alias: 'getAdminAndInstructorUsers',
      ...operationOptions
    });
};

/**
 * __useGetAdminAndInstructorUsersQuery__
 *
 * To run a query within a React component, call `useGetAdminAndInstructorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAndInstructorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAndInstructorUsersQuery({
 *   variables: {
 *      koOrganizationId: // value for 'koOrganizationId'
 *   },
 * });
 */
export function useGetAdminAndInstructorUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>(GetAdminAndInstructorUsersDocument, baseOptions);
      }
export function useGetAdminAndInstructorUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>(GetAdminAndInstructorUsersDocument, baseOptions);
        }
export type GetAdminAndInstructorUsersQueryHookResult = ReturnType<typeof useGetAdminAndInstructorUsersQuery>;
export type GetAdminAndInstructorUsersLazyQueryHookResult = ReturnType<typeof useGetAdminAndInstructorUsersLazyQuery>;
export type GetAdminAndInstructorUsersQueryResult = ApolloReactCommon.QueryResult<GetAdminAndInstructorUsersQuery, GetAdminAndInstructorUsersQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($userId: String!) {
  userDetails: getUserDetails(userId: $userId) {
    id
    email
    verified
    insertInstant
    lastLoginInstant
    roles
    loginAttempts {
      status
    }
    instructorDetails {
      level
      active
      showInWeb
      firstname
      lastname
      email
      joinDate
      leaveDate
    }
  }
}
    `;
export type GetUserDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>, 'query'> & ({ variables: GetUserDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserDetailsComponent = (props: GetUserDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetUserDetailsQuery, GetUserDetailsQueryVariables> query={GetUserDetailsDocument} {...props} />
    );
    
export type GetUserDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserDetailsQuery, GetUserDetailsQueryVariables> & TChildProps;
export function withGetUserDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserDetailsQuery,
  GetUserDetailsQueryVariables,
  GetUserDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserDetailsQuery, GetUserDetailsQueryVariables, GetUserDetailsProps<TChildProps>>(GetUserDetailsDocument, {
      alias: 'getUserDetails',
      ...operationOptions
    });
};

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = ApolloReactCommon.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const CourseLessonBookingsByCourseDocument = gql`
    query CourseLessonBookingsByCourse($courseId: String!) {
  courseLessonBookingsByCourse(courseId: $courseId) {
    id
    attendance
    booking {
      id
      attendee {
        id
        firstname
        lastname
        birthday
        booker {
          id
          firstname
          lastname
        }
      }
    }
    courseLessonId
    bookingId
  }
}
    `;
export type CourseLessonBookingsByCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>, 'query'> & ({ variables: CourseLessonBookingsByCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CourseLessonBookingsByCourseComponent = (props: CourseLessonBookingsByCourseComponentProps) => (
      <ApolloReactComponents.Query<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables> query={CourseLessonBookingsByCourseDocument} {...props} />
    );
    
export type CourseLessonBookingsByCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables> & TChildProps;
export function withCourseLessonBookingsByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CourseLessonBookingsByCourseQuery,
  CourseLessonBookingsByCourseQueryVariables,
  CourseLessonBookingsByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables, CourseLessonBookingsByCourseProps<TChildProps>>(CourseLessonBookingsByCourseDocument, {
      alias: 'courseLessonBookingsByCourse',
      ...operationOptions
    });
};

/**
 * __useCourseLessonBookingsByCourseQuery__
 *
 * To run a query within a React component, call `useCourseLessonBookingsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseLessonBookingsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseLessonBookingsByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseLessonBookingsByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>(CourseLessonBookingsByCourseDocument, baseOptions);
      }
export function useCourseLessonBookingsByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>(CourseLessonBookingsByCourseDocument, baseOptions);
        }
export type CourseLessonBookingsByCourseQueryHookResult = ReturnType<typeof useCourseLessonBookingsByCourseQuery>;
export type CourseLessonBookingsByCourseLazyQueryHookResult = ReturnType<typeof useCourseLessonBookingsByCourseLazyQuery>;
export type CourseLessonBookingsByCourseQueryResult = ApolloReactCommon.QueryResult<CourseLessonBookingsByCourseQuery, CourseLessonBookingsByCourseQueryVariables>;
export const AttendanceListByCourseDocument = gql`
    query AttendanceListByCourse($courseId: String!) {
  attendanceListByCourse(courseId: $courseId) {
    id
    attendee {
      id
      firstname
      lastname
      customer {
        firstname
        lastname
      }
      birthday
      customerNumber
      customerNumberPrefix
      booker {
        id
        firstname
        lastname
        phoneNumber
        mobileNumber
        email
        street
        houseNumber
        postCode
        city
      }
    }
    courseLessons {
      id
      startDateTime
    }
    courseLessonBookings {
      id
      attendance
      note
      courseLessonId
    }
    noticeStaff
    attendanceListNote
    courseId
  }
}
    `;
export type AttendanceListByCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>, 'query'> & ({ variables: AttendanceListByCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AttendanceListByCourseComponent = (props: AttendanceListByCourseComponentProps) => (
      <ApolloReactComponents.Query<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables> query={AttendanceListByCourseDocument} {...props} />
    );
    
export type AttendanceListByCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables> & TChildProps;
export function withAttendanceListByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AttendanceListByCourseQuery,
  AttendanceListByCourseQueryVariables,
  AttendanceListByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables, AttendanceListByCourseProps<TChildProps>>(AttendanceListByCourseDocument, {
      alias: 'attendanceListByCourse',
      ...operationOptions
    });
};

/**
 * __useAttendanceListByCourseQuery__
 *
 * To run a query within a React component, call `useAttendanceListByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceListByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceListByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useAttendanceListByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>(AttendanceListByCourseDocument, baseOptions);
      }
export function useAttendanceListByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>(AttendanceListByCourseDocument, baseOptions);
        }
export type AttendanceListByCourseQueryHookResult = ReturnType<typeof useAttendanceListByCourseQuery>;
export type AttendanceListByCourseLazyQueryHookResult = ReturnType<typeof useAttendanceListByCourseLazyQuery>;
export type AttendanceListByCourseQueryResult = ApolloReactCommon.QueryResult<AttendanceListByCourseQuery, AttendanceListByCourseQueryVariables>;
export const UpdateCourseLessonBookingDocument = gql`
    mutation UpdateCourseLessonBooking($updateCourseLessonBookingInput: UpdateCourseLessonBookingInput!) {
  updateCourseLessonBooking(updateCourseLessonBookingInput: $updateCourseLessonBookingInput) {
    id
    attendance
    note
  }
}
    `;
export type UpdateCourseLessonBookingMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables>;
export type UpdateCourseLessonBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables>, 'mutation'>;

    export const UpdateCourseLessonBookingComponent = (props: UpdateCourseLessonBookingComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables> mutation={UpdateCourseLessonBookingDocument} {...props} />
    );
    
export type UpdateCourseLessonBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables> & TChildProps;
export function withUpdateCourseLessonBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCourseLessonBookingMutation,
  UpdateCourseLessonBookingMutationVariables,
  UpdateCourseLessonBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables, UpdateCourseLessonBookingProps<TChildProps>>(UpdateCourseLessonBookingDocument, {
      alias: 'updateCourseLessonBooking',
      ...operationOptions
    });
};

/**
 * __useUpdateCourseLessonBookingMutation__
 *
 * To run a mutation, you first call `useUpdateCourseLessonBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseLessonBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseLessonBookingMutation, { data, loading, error }] = useUpdateCourseLessonBookingMutation({
 *   variables: {
 *      updateCourseLessonBookingInput: // value for 'updateCourseLessonBookingInput'
 *   },
 * });
 */
export function useUpdateCourseLessonBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables>(UpdateCourseLessonBookingDocument, baseOptions);
      }
export type UpdateCourseLessonBookingMutationHookResult = ReturnType<typeof useUpdateCourseLessonBookingMutation>;
export type UpdateCourseLessonBookingMutationResult = ApolloReactCommon.MutationResult<UpdateCourseLessonBookingMutation>;
export type UpdateCourseLessonBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseLessonBookingMutation, UpdateCourseLessonBookingMutationVariables>;
export const CreateCourseLessonBookingsByCourseIdDocument = gql`
    mutation CreateCourseLessonBookingsByCourseId($courseId: String!) {
  createCourseLessonBookingsByCourseId(courseId: $courseId)
}
    `;
export type CreateCourseLessonBookingsByCourseIdMutationFn = ApolloReactCommon.MutationFunction<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables>;
export type CreateCourseLessonBookingsByCourseIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables>, 'mutation'>;

    export const CreateCourseLessonBookingsByCourseIdComponent = (props: CreateCourseLessonBookingsByCourseIdComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables> mutation={CreateCourseLessonBookingsByCourseIdDocument} {...props} />
    );
    
export type CreateCourseLessonBookingsByCourseIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables> & TChildProps;
export function withCreateCourseLessonBookingsByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCourseLessonBookingsByCourseIdMutation,
  CreateCourseLessonBookingsByCourseIdMutationVariables,
  CreateCourseLessonBookingsByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables, CreateCourseLessonBookingsByCourseIdProps<TChildProps>>(CreateCourseLessonBookingsByCourseIdDocument, {
      alias: 'createCourseLessonBookingsByCourseId',
      ...operationOptions
    });
};

/**
 * __useCreateCourseLessonBookingsByCourseIdMutation__
 *
 * To run a mutation, you first call `useCreateCourseLessonBookingsByCourseIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseLessonBookingsByCourseIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseLessonBookingsByCourseIdMutation, { data, loading, error }] = useCreateCourseLessonBookingsByCourseIdMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCreateCourseLessonBookingsByCourseIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables>(CreateCourseLessonBookingsByCourseIdDocument, baseOptions);
      }
export type CreateCourseLessonBookingsByCourseIdMutationHookResult = ReturnType<typeof useCreateCourseLessonBookingsByCourseIdMutation>;
export type CreateCourseLessonBookingsByCourseIdMutationResult = ApolloReactCommon.MutationResult<CreateCourseLessonBookingsByCourseIdMutation>;
export type CreateCourseLessonBookingsByCourseIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseLessonBookingsByCourseIdMutation, CreateCourseLessonBookingsByCourseIdMutationVariables>;
export const GetCourseLessonBookingByCourseLessonAndBookingDocument = gql`
    query GetCourseLessonBookingByCourseLessonAndBooking($courseLessonId: String!, $bookingId: String!) {
  courseLessonBookingByCourseLessonAndBooking(courseLessonId: $courseLessonId, bookingId: $bookingId) {
    id
    bookingId
    attendance
    courseLessonId
  }
}
    `;
export type GetCourseLessonBookingByCourseLessonAndBookingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>, 'query'> & ({ variables: GetCourseLessonBookingByCourseLessonAndBookingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonBookingByCourseLessonAndBookingComponent = (props: GetCourseLessonBookingByCourseLessonAndBookingComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables> query={GetCourseLessonBookingByCourseLessonAndBookingDocument} {...props} />
    );
    
export type GetCourseLessonBookingByCourseLessonAndBookingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables> & TChildProps;
export function withGetCourseLessonBookingByCourseLessonAndBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonBookingByCourseLessonAndBookingQuery,
  GetCourseLessonBookingByCourseLessonAndBookingQueryVariables,
  GetCourseLessonBookingByCourseLessonAndBookingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables, GetCourseLessonBookingByCourseLessonAndBookingProps<TChildProps>>(GetCourseLessonBookingByCourseLessonAndBookingDocument, {
      alias: 'getCourseLessonBookingByCourseLessonAndBooking',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonBookingByCourseLessonAndBookingQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonBookingByCourseLessonAndBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonBookingByCourseLessonAndBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonBookingByCourseLessonAndBookingQuery({
 *   variables: {
 *      courseLessonId: // value for 'courseLessonId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetCourseLessonBookingByCourseLessonAndBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>(GetCourseLessonBookingByCourseLessonAndBookingDocument, baseOptions);
      }
export function useGetCourseLessonBookingByCourseLessonAndBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>(GetCourseLessonBookingByCourseLessonAndBookingDocument, baseOptions);
        }
export type GetCourseLessonBookingByCourseLessonAndBookingQueryHookResult = ReturnType<typeof useGetCourseLessonBookingByCourseLessonAndBookingQuery>;
export type GetCourseLessonBookingByCourseLessonAndBookingLazyQueryHookResult = ReturnType<typeof useGetCourseLessonBookingByCourseLessonAndBookingLazyQuery>;
export type GetCourseLessonBookingByCourseLessonAndBookingQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonBookingByCourseLessonAndBookingQuery, GetCourseLessonBookingByCourseLessonAndBookingQueryVariables>;
export const GetCourseTypeDocument = gql`
    query GetCourseType($id: String!) {
  courseTypeById(id: $id) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
    fee
    vat
    participationPrerequisites {
      id
      groupLabel
      groupDescription
      optionalInputFields
      minCheckCount
      courseTypeId
    }
    selfBookerCourse
  }
}
    `;
export type GetCourseTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseTypeQuery, GetCourseTypeQueryVariables>, 'query'> & ({ variables: GetCourseTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseTypeComponent = (props: GetCourseTypeComponentProps) => (
      <ApolloReactComponents.Query<GetCourseTypeQuery, GetCourseTypeQueryVariables> query={GetCourseTypeDocument} {...props} />
    );
    
export type GetCourseTypeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseTypeQuery, GetCourseTypeQueryVariables> & TChildProps;
export function withGetCourseType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseTypeQuery,
  GetCourseTypeQueryVariables,
  GetCourseTypeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseTypeQuery, GetCourseTypeQueryVariables, GetCourseTypeProps<TChildProps>>(GetCourseTypeDocument, {
      alias: 'getCourseType',
      ...operationOptions
    });
};

/**
 * __useGetCourseTypeQuery__
 *
 * To run a query within a React component, call `useGetCourseTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseTypeQuery, GetCourseTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseTypeQuery, GetCourseTypeQueryVariables>(GetCourseTypeDocument, baseOptions);
      }
export function useGetCourseTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypeQuery, GetCourseTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseTypeQuery, GetCourseTypeQueryVariables>(GetCourseTypeDocument, baseOptions);
        }
export type GetCourseTypeQueryHookResult = ReturnType<typeof useGetCourseTypeQuery>;
export type GetCourseTypeLazyQueryHookResult = ReturnType<typeof useGetCourseTypeLazyQuery>;
export type GetCourseTypeQueryResult = ApolloReactCommon.QueryResult<GetCourseTypeQuery, GetCourseTypeQueryVariables>;
export const GetDetailedCourseTypesDocument = gql`
    query GetDetailedCourseTypes {
  courseTypes {
    id
    courseCount
    shortHandId
    name
    updateDate
    description
    minAge
    maxAge
    paymentInterval
    fee
    vat
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    color
    isActive
    showInWeb
    participationPrerequisites {
      id
      groupLabel
      groupDescription
      optionalInputFields
      minCheckCount
      courseTypeId
    }
    selfBookerCourse
  }
}
    `;
export type GetDetailedCourseTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>, 'query'>;

    export const GetDetailedCourseTypesComponent = (props: GetDetailedCourseTypesComponentProps) => (
      <ApolloReactComponents.Query<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables> query={GetDetailedCourseTypesDocument} {...props} />
    );
    
export type GetDetailedCourseTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables> & TChildProps;
export function withGetDetailedCourseTypes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDetailedCourseTypesQuery,
  GetDetailedCourseTypesQueryVariables,
  GetDetailedCourseTypesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables, GetDetailedCourseTypesProps<TChildProps>>(GetDetailedCourseTypesDocument, {
      alias: 'getDetailedCourseTypes',
      ...operationOptions
    });
};

/**
 * __useGetDetailedCourseTypesQuery__
 *
 * To run a query within a React component, call `useGetDetailedCourseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailedCourseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailedCourseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailedCourseTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>(GetDetailedCourseTypesDocument, baseOptions);
      }
export function useGetDetailedCourseTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>(GetDetailedCourseTypesDocument, baseOptions);
        }
export type GetDetailedCourseTypesQueryHookResult = ReturnType<typeof useGetDetailedCourseTypesQuery>;
export type GetDetailedCourseTypesLazyQueryHookResult = ReturnType<typeof useGetDetailedCourseTypesLazyQuery>;
export type GetDetailedCourseTypesQueryResult = ApolloReactCommon.QueryResult<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>;
export const GetCourseTypesDocument = gql`
    query GetCourseTypes {
  courseTypes {
    id
    name
    description
    courseCount
  }
}
    `;
export type GetCourseTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseTypesQuery, GetCourseTypesQueryVariables>, 'query'>;

    export const GetCourseTypesComponent = (props: GetCourseTypesComponentProps) => (
      <ApolloReactComponents.Query<GetCourseTypesQuery, GetCourseTypesQueryVariables> query={GetCourseTypesDocument} {...props} />
    );
    
export type GetCourseTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseTypesQuery, GetCourseTypesQueryVariables> & TChildProps;
export function withGetCourseTypes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseTypesQuery,
  GetCourseTypesQueryVariables,
  GetCourseTypesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseTypesQuery, GetCourseTypesQueryVariables, GetCourseTypesProps<TChildProps>>(GetCourseTypesDocument, {
      alias: 'getCourseTypes',
      ...operationOptions
    });
};

/**
 * __useGetCourseTypesQuery__
 *
 * To run a query within a React component, call `useGetCourseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseTypesQuery, GetCourseTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseTypesQuery, GetCourseTypesQueryVariables>(GetCourseTypesDocument, baseOptions);
      }
export function useGetCourseTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypesQuery, GetCourseTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseTypesQuery, GetCourseTypesQueryVariables>(GetCourseTypesDocument, baseOptions);
        }
export type GetCourseTypesQueryHookResult = ReturnType<typeof useGetCourseTypesQuery>;
export type GetCourseTypesLazyQueryHookResult = ReturnType<typeof useGetCourseTypesLazyQuery>;
export type GetCourseTypesQueryResult = ApolloReactCommon.QueryResult<GetCourseTypesQuery, GetCourseTypesQueryVariables>;
export const GetPrerequisiteCoursesDocument = gql`
    query GetPrerequisiteCourses($options: ArrayDataOptions) {
  prerequisiteCourses(options: $options) {
    existsMore
    total
    items {
      id
      courseNumber
      prefixedCourseNumber
      bookingCountWithoutWaitlist
      billedBookingCount
      freePlaces
      waitListCount
      bookingCount
      placeReservationsCount
      canceledBookingsCount
      settledBookingCount
      courseType {
        id
        name
        description
        color
      }
      startDateTime
      endDateTime
      endDateTimeFirstCourseLesson
      location {
        id
        city
        name
      }
      venue {
        id
        name
      }
      annotation
      instructors {
        id
        firstname
        lastname
      }
      maxAttendees
      coursePrice {
        id
        grossPrice
        netPrice
        vat
        discount
        discountUnit
        isSpecial
      }
      paymentInterval
      allowMonthlyPayment
      monthlySchedule
      isActive
      showInWeb
      paymentType
      installmentRates {
        id
        courseId
        installmentAmount
        installmentDue
        installmentType
      }
      lessonCount
      lessons {
        id
        startDateTime
        endDateTime
      }
      visibility {
        visibleForAll
        restriction {
          prerequisiteCourses {
            id
          }
          membersOnly
          startDateTime
          endDateTime
          inTimeRange
          expireAction
        }
        reason
        hideAfterDays
      }
      enabledCourses {
        id
        prefixedCourseNumber
        courseType {
          id
          color
        }
        enabledRestriction {
          id
          includeBookingList
          includeWaitingList
          membersOnly
        }
      }
      bookingRestriction {
        id
        active
        visibleForAll
        includeBookingList
        includeWaitingList
        membersOnly
        blockedPlaces
        startDateTime
        endDateTime
      }
    }
  }
}
    `;
export type GetPrerequisiteCoursesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>, 'query'>;

    export const GetPrerequisiteCoursesComponent = (props: GetPrerequisiteCoursesComponentProps) => (
      <ApolloReactComponents.Query<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables> query={GetPrerequisiteCoursesDocument} {...props} />
    );
    
export type GetPrerequisiteCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables> & TChildProps;
export function withGetPrerequisiteCourses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPrerequisiteCoursesQuery,
  GetPrerequisiteCoursesQueryVariables,
  GetPrerequisiteCoursesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables, GetPrerequisiteCoursesProps<TChildProps>>(GetPrerequisiteCoursesDocument, {
      alias: 'getPrerequisiteCourses',
      ...operationOptions
    });
};

/**
 * __useGetPrerequisiteCoursesQuery__
 *
 * To run a query within a React component, call `useGetPrerequisiteCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrerequisiteCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrerequisiteCoursesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPrerequisiteCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>(GetPrerequisiteCoursesDocument, baseOptions);
      }
export function useGetPrerequisiteCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>(GetPrerequisiteCoursesDocument, baseOptions);
        }
export type GetPrerequisiteCoursesQueryHookResult = ReturnType<typeof useGetPrerequisiteCoursesQuery>;
export type GetPrerequisiteCoursesLazyQueryHookResult = ReturnType<typeof useGetPrerequisiteCoursesLazyQuery>;
export type GetPrerequisiteCoursesQueryResult = ApolloReactCommon.QueryResult<GetPrerequisiteCoursesQuery, GetPrerequisiteCoursesQueryVariables>;
export const GetLocalStateDocument = gql`
    query getLocalState {
  LocalState @client {
    createCourse {
      locationId
    }
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
      selectedCourseId
    }
  }
}
    `;
export type GetLocalStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocalStateQuery, GetLocalStateQueryVariables>, 'query'>;

    export const GetLocalStateComponent = (props: GetLocalStateComponentProps) => (
      <ApolloReactComponents.Query<GetLocalStateQuery, GetLocalStateQueryVariables> query={GetLocalStateDocument} {...props} />
    );
    
export type GetLocalStateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocalStateQuery, GetLocalStateQueryVariables> & TChildProps;
export function withGetLocalState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocalStateQuery,
  GetLocalStateQueryVariables,
  GetLocalStateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocalStateQuery, GetLocalStateQueryVariables, GetLocalStateProps<TChildProps>>(GetLocalStateDocument, {
      alias: 'getLocalState',
      ...operationOptions
    });
};

/**
 * __useGetLocalStateQuery__
 *
 * To run a query within a React component, call `useGetLocalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalStateQuery, GetLocalStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocalStateQuery, GetLocalStateQueryVariables>(GetLocalStateDocument, baseOptions);
      }
export function useGetLocalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalStateQuery, GetLocalStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocalStateQuery, GetLocalStateQueryVariables>(GetLocalStateDocument, baseOptions);
        }
export type GetLocalStateQueryHookResult = ReturnType<typeof useGetLocalStateQuery>;
export type GetLocalStateLazyQueryHookResult = ReturnType<typeof useGetLocalStateLazyQuery>;
export type GetLocalStateQueryResult = ApolloReactCommon.QueryResult<GetLocalStateQuery, GetLocalStateQueryVariables>;
export const GetInstructorsByIdDocument = gql`
    query getInstructorsById($ids: [ID!]!) {
  findInstructorByIds(instructorIds: $ids) {
    id
    firstname
    lastname
    level
  }
}
    `;
export type GetInstructorsByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>, 'query'> & ({ variables: GetInstructorsByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInstructorsByIdComponent = (props: GetInstructorsByIdComponentProps) => (
      <ApolloReactComponents.Query<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables> query={GetInstructorsByIdDocument} {...props} />
    );
    
export type GetInstructorsByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables> & TChildProps;
export function withGetInstructorsById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstructorsByIdQuery,
  GetInstructorsByIdQueryVariables,
  GetInstructorsByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables, GetInstructorsByIdProps<TChildProps>>(GetInstructorsByIdDocument, {
      alias: 'getInstructorsById',
      ...operationOptions
    });
};

/**
 * __useGetInstructorsByIdQuery__
 *
 * To run a query within a React component, call `useGetInstructorsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetInstructorsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>(GetInstructorsByIdDocument, baseOptions);
      }
export function useGetInstructorsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>(GetInstructorsByIdDocument, baseOptions);
        }
export type GetInstructorsByIdQueryHookResult = ReturnType<typeof useGetInstructorsByIdQuery>;
export type GetInstructorsByIdLazyQueryHookResult = ReturnType<typeof useGetInstructorsByIdLazyQuery>;
export type GetInstructorsByIdQueryResult = ApolloReactCommon.QueryResult<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>;
export const GetReferenceCoursesLinkDocument = gql`
    query getReferenceCoursesLink($courseId: ID!) {
  getReferenceCoursesLink(courseId: $courseId)
}
    `;
export type GetReferenceCoursesLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>, 'query'> & ({ variables: GetReferenceCoursesLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReferenceCoursesLinkComponent = (props: GetReferenceCoursesLinkComponentProps) => (
      <ApolloReactComponents.Query<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables> query={GetReferenceCoursesLinkDocument} {...props} />
    );
    
export type GetReferenceCoursesLinkProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables> & TChildProps;
export function withGetReferenceCoursesLink<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReferenceCoursesLinkQuery,
  GetReferenceCoursesLinkQueryVariables,
  GetReferenceCoursesLinkProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables, GetReferenceCoursesLinkProps<TChildProps>>(GetReferenceCoursesLinkDocument, {
      alias: 'getReferenceCoursesLink',
      ...operationOptions
    });
};

/**
 * __useGetReferenceCoursesLinkQuery__
 *
 * To run a query within a React component, call `useGetReferenceCoursesLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCoursesLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCoursesLinkQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetReferenceCoursesLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>(GetReferenceCoursesLinkDocument, baseOptions);
      }
export function useGetReferenceCoursesLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>(GetReferenceCoursesLinkDocument, baseOptions);
        }
export type GetReferenceCoursesLinkQueryHookResult = ReturnType<typeof useGetReferenceCoursesLinkQuery>;
export type GetReferenceCoursesLinkLazyQueryHookResult = ReturnType<typeof useGetReferenceCoursesLinkLazyQuery>;
export type GetReferenceCoursesLinkQueryResult = ApolloReactCommon.QueryResult<GetReferenceCoursesLinkQuery, GetReferenceCoursesLinkQueryVariables>;
export const CourseIsPrerequisiteCourseForDocument = gql`
    query courseIsPrerequisiteCourseFor($courseId: ID!) {
  courseIsPrerequisiteCourseFor(courseId: $courseId) {
    id
    prefixedCourseNumber
    courseType {
      id
      name
      color
    }
    bookingRestriction {
      id
      includeWaitingList
      includeBookingList
    }
    visibility {
      visibleForAll
      restriction {
        membersOnly
        inTimeRange
        expireAction
      }
      reason
    }
  }
}
    `;
export type CourseIsPrerequisiteCourseForComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>, 'query'> & ({ variables: CourseIsPrerequisiteCourseForQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CourseIsPrerequisiteCourseForComponent = (props: CourseIsPrerequisiteCourseForComponentProps) => (
      <ApolloReactComponents.Query<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables> query={CourseIsPrerequisiteCourseForDocument} {...props} />
    );
    
export type CourseIsPrerequisiteCourseForProps<TChildProps = {}> = ApolloReactHoc.DataProps<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables> & TChildProps;
export function withCourseIsPrerequisiteCourseFor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CourseIsPrerequisiteCourseForQuery,
  CourseIsPrerequisiteCourseForQueryVariables,
  CourseIsPrerequisiteCourseForProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables, CourseIsPrerequisiteCourseForProps<TChildProps>>(CourseIsPrerequisiteCourseForDocument, {
      alias: 'courseIsPrerequisiteCourseFor',
      ...operationOptions
    });
};

/**
 * __useCourseIsPrerequisiteCourseForQuery__
 *
 * To run a query within a React component, call `useCourseIsPrerequisiteCourseForQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseIsPrerequisiteCourseForQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseIsPrerequisiteCourseForQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseIsPrerequisiteCourseForQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>(CourseIsPrerequisiteCourseForDocument, baseOptions);
      }
export function useCourseIsPrerequisiteCourseForLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>(CourseIsPrerequisiteCourseForDocument, baseOptions);
        }
export type CourseIsPrerequisiteCourseForQueryHookResult = ReturnType<typeof useCourseIsPrerequisiteCourseForQuery>;
export type CourseIsPrerequisiteCourseForLazyQueryHookResult = ReturnType<typeof useCourseIsPrerequisiteCourseForLazyQuery>;
export type CourseIsPrerequisiteCourseForQueryResult = ApolloReactCommon.QueryResult<CourseIsPrerequisiteCourseForQuery, CourseIsPrerequisiteCourseForQueryVariables>;
export const RemoveCourseDocument = gql`
    mutation removeCourse($id: ID!) {
  removeCourse(id: $id)
}
    `;
export type RemoveCourseMutationFn = ApolloReactCommon.MutationFunction<RemoveCourseMutation, RemoveCourseMutationVariables>;
export type RemoveCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCourseMutation, RemoveCourseMutationVariables>, 'mutation'>;

    export const RemoveCourseComponent = (props: RemoveCourseComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCourseMutation, RemoveCourseMutationVariables> mutation={RemoveCourseDocument} {...props} />
    );
    
export type RemoveCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveCourseMutation, RemoveCourseMutationVariables> & TChildProps;
export function withRemoveCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveCourseMutation,
  RemoveCourseMutationVariables,
  RemoveCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveCourseMutation, RemoveCourseMutationVariables, RemoveCourseProps<TChildProps>>(RemoveCourseDocument, {
      alias: 'removeCourse',
      ...operationOptions
    });
};

/**
 * __useRemoveCourseMutation__
 *
 * To run a mutation, you first call `useRemoveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseMutation, { data, loading, error }] = useRemoveCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCourseMutation, RemoveCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCourseMutation, RemoveCourseMutationVariables>(RemoveCourseDocument, baseOptions);
      }
export type RemoveCourseMutationHookResult = ReturnType<typeof useRemoveCourseMutation>;
export type RemoveCourseMutationResult = ApolloReactCommon.MutationResult<RemoveCourseMutation>;
export type RemoveCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCourseMutation, RemoveCourseMutationVariables>;
export const BookAttendeeGetLocalStateDocument = gql`
    query bookAttendeeGetLocalState {
  LocalState @client {
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
      selectedCourseId
    }
  }
}
    `;
export type BookAttendeeGetLocalStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>, 'query'>;

    export const BookAttendeeGetLocalStateComponent = (props: BookAttendeeGetLocalStateComponentProps) => (
      <ApolloReactComponents.Query<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables> query={BookAttendeeGetLocalStateDocument} {...props} />
    );
    
export type BookAttendeeGetLocalStateProps<TChildProps = {}> = ApolloReactHoc.DataProps<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables> & TChildProps;
export function withBookAttendeeGetLocalState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookAttendeeGetLocalStateQuery,
  BookAttendeeGetLocalStateQueryVariables,
  BookAttendeeGetLocalStateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables, BookAttendeeGetLocalStateProps<TChildProps>>(BookAttendeeGetLocalStateDocument, {
      alias: 'bookAttendeeGetLocalState',
      ...operationOptions
    });
};

/**
 * __useBookAttendeeGetLocalStateQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetLocalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetLocalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetLocalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetLocalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>) {
        return ApolloReactHooks.useQuery<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>(BookAttendeeGetLocalStateDocument, baseOptions);
      }
export function useBookAttendeeGetLocalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>(BookAttendeeGetLocalStateDocument, baseOptions);
        }
export type BookAttendeeGetLocalStateQueryHookResult = ReturnType<typeof useBookAttendeeGetLocalStateQuery>;
export type BookAttendeeGetLocalStateLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetLocalStateLazyQuery>;
export type BookAttendeeGetLocalStateQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>;
export const BookAttendeeGetSelectedAttendeeDocument = gql`
    query bookAttendeeGetSelectedAttendee {
  LocalState @client {
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
    }
  }
}
    `;
export type BookAttendeeGetSelectedAttendeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>, 'query'>;

    export const BookAttendeeGetSelectedAttendeeComponent = (props: BookAttendeeGetSelectedAttendeeComponentProps) => (
      <ApolloReactComponents.Query<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables> query={BookAttendeeGetSelectedAttendeeDocument} {...props} />
    );
    
export type BookAttendeeGetSelectedAttendeeProps<TChildProps = {}> = ApolloReactHoc.DataProps<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables> & TChildProps;
export function withBookAttendeeGetSelectedAttendee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookAttendeeGetSelectedAttendeeQuery,
  BookAttendeeGetSelectedAttendeeQueryVariables,
  BookAttendeeGetSelectedAttendeeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables, BookAttendeeGetSelectedAttendeeProps<TChildProps>>(BookAttendeeGetSelectedAttendeeDocument, {
      alias: 'bookAttendeeGetSelectedAttendee',
      ...operationOptions
    });
};

/**
 * __useBookAttendeeGetSelectedAttendeeQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetSelectedAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetSelectedAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetSelectedAttendeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetSelectedAttendeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>) {
        return ApolloReactHooks.useQuery<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>(BookAttendeeGetSelectedAttendeeDocument, baseOptions);
      }
export function useBookAttendeeGetSelectedAttendeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>(BookAttendeeGetSelectedAttendeeDocument, baseOptions);
        }
export type BookAttendeeGetSelectedAttendeeQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedAttendeeQuery>;
export type BookAttendeeGetSelectedAttendeeLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedAttendeeLazyQuery>;
export type BookAttendeeGetSelectedAttendeeQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>;
export const BookAttendeeGetSelectedCourseDocument = gql`
    query bookAttendeeGetSelectedCourse {
  LocalState @client {
    bookAttendees {
      selectedCourseId
    }
  }
}
    `;
export type BookAttendeeGetSelectedCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>, 'query'>;

    export const BookAttendeeGetSelectedCourseComponent = (props: BookAttendeeGetSelectedCourseComponentProps) => (
      <ApolloReactComponents.Query<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables> query={BookAttendeeGetSelectedCourseDocument} {...props} />
    );
    
export type BookAttendeeGetSelectedCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables> & TChildProps;
export function withBookAttendeeGetSelectedCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookAttendeeGetSelectedCourseQuery,
  BookAttendeeGetSelectedCourseQueryVariables,
  BookAttendeeGetSelectedCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables, BookAttendeeGetSelectedCourseProps<TChildProps>>(BookAttendeeGetSelectedCourseDocument, {
      alias: 'bookAttendeeGetSelectedCourse',
      ...operationOptions
    });
};

/**
 * __useBookAttendeeGetSelectedCourseQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetSelectedCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetSelectedCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetSelectedCourseQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetSelectedCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>(BookAttendeeGetSelectedCourseDocument, baseOptions);
      }
export function useBookAttendeeGetSelectedCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>(BookAttendeeGetSelectedCourseDocument, baseOptions);
        }
export type BookAttendeeGetSelectedCourseQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedCourseQuery>;
export type BookAttendeeGetSelectedCourseLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedCourseLazyQuery>;
export type BookAttendeeGetSelectedCourseQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>;
export const BookAttendeeSetSelectedAttendeeDocument = gql`
    mutation bookAttendeeSetSelectedAttendee($id: ID, $firstname: String, $bookedCourseIds: [String]) {
  bookAttendeeSetSelectedAttendee(id: $id, firstname: $firstname, bookedCourseIds: $bookedCourseIds) @client
}
    `;
export type BookAttendeeSetSelectedAttendeeMutationFn = ApolloReactCommon.MutationFunction<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>;
export type BookAttendeeSetSelectedAttendeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>, 'mutation'>;

    export const BookAttendeeSetSelectedAttendeeComponent = (props: BookAttendeeSetSelectedAttendeeComponentProps) => (
      <ApolloReactComponents.Mutation<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables> mutation={BookAttendeeSetSelectedAttendeeDocument} {...props} />
    );
    
export type BookAttendeeSetSelectedAttendeeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables> & TChildProps;
export function withBookAttendeeSetSelectedAttendee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookAttendeeSetSelectedAttendeeMutation,
  BookAttendeeSetSelectedAttendeeMutationVariables,
  BookAttendeeSetSelectedAttendeeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables, BookAttendeeSetSelectedAttendeeProps<TChildProps>>(BookAttendeeSetSelectedAttendeeDocument, {
      alias: 'bookAttendeeSetSelectedAttendee',
      ...operationOptions
    });
};

/**
 * __useBookAttendeeSetSelectedAttendeeMutation__
 *
 * To run a mutation, you first call `useBookAttendeeSetSelectedAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeSetSelectedAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAttendeeSetSelectedAttendeeMutation, { data, loading, error }] = useBookAttendeeSetSelectedAttendeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      bookedCourseIds: // value for 'bookedCourseIds'
 *   },
 * });
 */
export function useBookAttendeeSetSelectedAttendeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>) {
        return ApolloReactHooks.useMutation<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>(BookAttendeeSetSelectedAttendeeDocument, baseOptions);
      }
export type BookAttendeeSetSelectedAttendeeMutationHookResult = ReturnType<typeof useBookAttendeeSetSelectedAttendeeMutation>;
export type BookAttendeeSetSelectedAttendeeMutationResult = ApolloReactCommon.MutationResult<BookAttendeeSetSelectedAttendeeMutation>;
export type BookAttendeeSetSelectedAttendeeMutationOptions = ApolloReactCommon.BaseMutationOptions<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>;
export const BookAttendeeSetSelectedCourseDocument = gql`
    mutation bookAttendeeSetSelectedCourse($id: ID) {
  bookAttendeeSetSelectedCourse(id: $id) @client
}
    `;
export type BookAttendeeSetSelectedCourseMutationFn = ApolloReactCommon.MutationFunction<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>;
export type BookAttendeeSetSelectedCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>, 'mutation'>;

    export const BookAttendeeSetSelectedCourseComponent = (props: BookAttendeeSetSelectedCourseComponentProps) => (
      <ApolloReactComponents.Mutation<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables> mutation={BookAttendeeSetSelectedCourseDocument} {...props} />
    );
    
export type BookAttendeeSetSelectedCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables> & TChildProps;
export function withBookAttendeeSetSelectedCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookAttendeeSetSelectedCourseMutation,
  BookAttendeeSetSelectedCourseMutationVariables,
  BookAttendeeSetSelectedCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables, BookAttendeeSetSelectedCourseProps<TChildProps>>(BookAttendeeSetSelectedCourseDocument, {
      alias: 'bookAttendeeSetSelectedCourse',
      ...operationOptions
    });
};

/**
 * __useBookAttendeeSetSelectedCourseMutation__
 *
 * To run a mutation, you first call `useBookAttendeeSetSelectedCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeSetSelectedCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAttendeeSetSelectedCourseMutation, { data, loading, error }] = useBookAttendeeSetSelectedCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookAttendeeSetSelectedCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>(BookAttendeeSetSelectedCourseDocument, baseOptions);
      }
export type BookAttendeeSetSelectedCourseMutationHookResult = ReturnType<typeof useBookAttendeeSetSelectedCourseMutation>;
export type BookAttendeeSetSelectedCourseMutationResult = ApolloReactCommon.MutationResult<BookAttendeeSetSelectedCourseMutation>;
export type BookAttendeeSetSelectedCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>;
export const UpdateLocalLocationIdDocument = gql`
    mutation updateLocalLocationId($id: ID) {
  updateLocalLocationId(id: $id) @client
}
    `;
export type UpdateLocalLocationIdMutationFn = ApolloReactCommon.MutationFunction<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>;
export type UpdateLocalLocationIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>, 'mutation'>;

    export const UpdateLocalLocationIdComponent = (props: UpdateLocalLocationIdComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables> mutation={UpdateLocalLocationIdDocument} {...props} />
    );
    
export type UpdateLocalLocationIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables> & TChildProps;
export function withUpdateLocalLocationId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLocalLocationIdMutation,
  UpdateLocalLocationIdMutationVariables,
  UpdateLocalLocationIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables, UpdateLocalLocationIdProps<TChildProps>>(UpdateLocalLocationIdDocument, {
      alias: 'updateLocalLocationId',
      ...operationOptions
    });
};

/**
 * __useUpdateLocalLocationIdMutation__
 *
 * To run a mutation, you first call `useUpdateLocalLocationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalLocationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalLocationIdMutation, { data, loading, error }] = useUpdateLocalLocationIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLocalLocationIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>(UpdateLocalLocationIdDocument, baseOptions);
      }
export type UpdateLocalLocationIdMutationHookResult = ReturnType<typeof useUpdateLocalLocationIdMutation>;
export type UpdateLocalLocationIdMutationResult = ApolloReactCommon.MutationResult<UpdateLocalLocationIdMutation>;
export type UpdateLocalLocationIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>;
export const GetCoursesDocument = gql`
    query GetCourses {
  courses {
    id
    courseNumber
    prefixedCourseNumber
    bookingCount
    billedBookingCount
    freePlaces
    settledBookingCount
    courseType {
      id
      name
      description
      color
    }
    startDateTime
    endDateTime
    location {
      id
      city
      name
    }
    venue {
      id
      name
    }
    annotation
    instructors {
      id
      firstname
      lastname
    }
    maxAttendees
    coursePrice {
      id
      grossPrice
      netPrice
      vat
      discount
      discountUnit
      isSpecial
    }
    paymentInterval
    allowMonthlyPayment
    monthlySchedule
    isActive
    showInWeb
  }
}
    `;
export type GetCoursesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesQuery, GetCoursesQueryVariables>, 'query'>;

    export const GetCoursesComponent = (props: GetCoursesComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesQuery, GetCoursesQueryVariables> query={GetCoursesDocument} {...props} />
    );
    
export type GetCoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesQuery, GetCoursesQueryVariables> & TChildProps;
export function withGetCourses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesQuery,
  GetCoursesQueryVariables,
  GetCoursesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesQuery, GetCoursesQueryVariables, GetCoursesProps<TChildProps>>(GetCoursesDocument, {
      alias: 'getCourses',
      ...operationOptions
    });
};

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
      }
export function useGetCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = ApolloReactCommon.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>;
export const GetCoursesWithPaginationDocument = gql`
    query GetCoursesWithPagination($options: ArrayDataOptions, $currentUserOnly: Boolean, $excludedCourseIds: [String!]) {
  coursesWithPagination(options: $options, currentUserOnly: $currentUserOnly, excludedCourseIds: $excludedCourseIds) {
    existsMore
    total
    items {
      id
      courseNumber
      prefixedCourseNumber
      bookingCountWithoutWaitlist
      billedBookingCount
      freePlaces
      waitListCount
      bookingCount
      placeReservationsCount
      canceledBookingsCount
      settledBookingCount
      courseType {
        id
        name
        description
        color
      }
      startDateTime
      endDateTime
      endDateTimeFirstCourseLesson
      location {
        id
        city
        name
      }
      venue {
        id
        name
      }
      annotation
      instructors {
        id
        firstname
        lastname
      }
      maxAttendees
      coursePrice {
        id
        grossPrice
        netPrice
        vat
        discount
        discountUnit
        isSpecial
      }
      paymentInterval
      allowMonthlyPayment
      monthlySchedule
      isActive
      showInWeb
      paymentType
      installmentRates {
        id
        courseId
        installmentAmount
        installmentDue
        installmentType
      }
      lessonCount
      lessons {
        id
        startDateTime
        endDateTime
      }
      visibility {
        visibleForAll
        restriction {
          prerequisiteCourses {
            id
          }
          membersOnly
          startDateTime
          endDateTime
          inTimeRange
          expireAction
        }
        reason
        hideAfterDays
      }
    }
  }
}
    `;
export type GetCoursesWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>, 'query'>;

    export const GetCoursesWithPaginationComponent = (props: GetCoursesWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables> query={GetCoursesWithPaginationDocument} {...props} />
    );
    
export type GetCoursesWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables> & TChildProps;
export function withGetCoursesWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesWithPaginationQuery,
  GetCoursesWithPaginationQueryVariables,
  GetCoursesWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables, GetCoursesWithPaginationProps<TChildProps>>(GetCoursesWithPaginationDocument, {
      alias: 'getCoursesWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetCoursesWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetCoursesWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesWithPaginationQuery({
 *   variables: {
 *      options: // value for 'options'
 *      currentUserOnly: // value for 'currentUserOnly'
 *      excludedCourseIds: // value for 'excludedCourseIds'
 *   },
 * });
 */
export function useGetCoursesWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>(GetCoursesWithPaginationDocument, baseOptions);
      }
export function useGetCoursesWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>(GetCoursesWithPaginationDocument, baseOptions);
        }
export type GetCoursesWithPaginationQueryHookResult = ReturnType<typeof useGetCoursesWithPaginationQuery>;
export type GetCoursesWithPaginationLazyQueryHookResult = ReturnType<typeof useGetCoursesWithPaginationLazyQuery>;
export type GetCoursesWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetCoursesWithPaginationQuery, GetCoursesWithPaginationQueryVariables>;
export const GetCourseByIdDocument = gql`
    query getCourseById($id: String!) {
  courseById(id: $id) {
    id
    courseType {
      id
      name
      description
      showInWeb
    }
    courseNumber
    prefixedCourseNumber
    courseNumberFormat
    customCourseNumberPrefix
    location {
      id
      city
      name
      venues {
        id
        name
      }
    }
    venue {
      id
      name
    }
    instructors {
      id
      firstname
      lastname
      level
      email
    }
    coursePrice {
      id
      grossPrice
      vat
      discount
      discountUnit
      isSpecial
      includedEntranceFee
    }
    paymentInterval
    minAttendees
    maxAttendees
    maxWaitlist
    bookingCount
    canceledBookingsCount
    startDateTime
    endDateTime
    endDateTimeFirstCourseLesson
    cycle
    annotation
    websiteInfo
    additionalShortInfo
    allowMonthlyPayment
    allowUpFrontPayment
    monthlySchedule
    lessons {
      id
      locationId
      location {
        id
        city
      }
      venueId
      venue {
        id
        name
      }
      startDateTime
      endDateTime
      instructors {
        id
        firstname
        lastname
      }
      courseLessonBookings {
        id
        bookingId
        attendance
        courseLessonId
      }
    }
    isActive
    showInWeb
    freePlaces
    installmentRates {
      id
      courseId
      installmentAmount
      installmentDue
      installmentType
    }
    paymentType
    visibility {
      visibleForAll
      restriction {
        prerequisiteCourses {
          id
        }
        membersOnly
        startDateTime
        endDateTime
        inTimeRange
        expireAction
      }
      reason
      hideAfterDays
    }
  }
}
    `;
export type GetCourseByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>, 'query'> & ({ variables: GetCourseByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseByIdComponent = (props: GetCourseByIdComponentProps) => (
      <ApolloReactComponents.Query<GetCourseByIdQuery, GetCourseByIdQueryVariables> query={GetCourseByIdDocument} {...props} />
    );
    
export type GetCourseByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseByIdQuery, GetCourseByIdQueryVariables> & TChildProps;
export function withGetCourseById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseByIdQuery,
  GetCourseByIdQueryVariables,
  GetCourseByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseByIdQuery, GetCourseByIdQueryVariables, GetCourseByIdProps<TChildProps>>(GetCourseByIdDocument, {
      alias: 'getCourseById',
      ...operationOptions
    });
};

/**
 * __useGetCourseByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, baseOptions);
      }
export function useGetCourseByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, baseOptions);
        }
export type GetCourseByIdQueryHookResult = ReturnType<typeof useGetCourseByIdQuery>;
export type GetCourseByIdLazyQueryHookResult = ReturnType<typeof useGetCourseByIdLazyQuery>;
export type GetCourseByIdQueryResult = ApolloReactCommon.QueryResult<GetCourseByIdQuery, GetCourseByIdQueryVariables>;
export const GetCourseByLocationIdDocument = gql`
    query GetCourseByLocationId($id: ID!) {
  courseByLocationId(id: $id) {
    id
    lessons {
      startDateTime
      endDateTime
    }
    courseType {
      name
      description
      annotation
      websiteInfo
    }
  }
}
    `;
export type GetCourseByLocationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>, 'query'> & ({ variables: GetCourseByLocationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseByLocationIdComponent = (props: GetCourseByLocationIdComponentProps) => (
      <ApolloReactComponents.Query<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables> query={GetCourseByLocationIdDocument} {...props} />
    );
    
export type GetCourseByLocationIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables> & TChildProps;
export function withGetCourseByLocationId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseByLocationIdQuery,
  GetCourseByLocationIdQueryVariables,
  GetCourseByLocationIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables, GetCourseByLocationIdProps<TChildProps>>(GetCourseByLocationIdDocument, {
      alias: 'getCourseByLocationId',
      ...operationOptions
    });
};

/**
 * __useGetCourseByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByLocationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByLocationIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>(GetCourseByLocationIdDocument, baseOptions);
      }
export function useGetCourseByLocationIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>(GetCourseByLocationIdDocument, baseOptions);
        }
export type GetCourseByLocationIdQueryHookResult = ReturnType<typeof useGetCourseByLocationIdQuery>;
export type GetCourseByLocationIdLazyQueryHookResult = ReturnType<typeof useGetCourseByLocationIdLazyQuery>;
export type GetCourseByLocationIdQueryResult = ApolloReactCommon.QueryResult<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>;
export const UpdateCourseDocument = gql`
    mutation updateCourse($id: String!, $editCourseData: EditCourseInput!, $fillFromWaitingList: Boolean, $notifyBooker: Boolean) {
  updateCourse(id: $id, editCourseData: $editCourseData, fillFromWaitingList: $fillFromWaitingList, notifyBooker: $notifyBooker) {
    id
    coursePrice {
      id
      vat
      grossPrice
      discount
      discountUnit
      courseId
      includedEntranceFee
    }
    paymentInterval
    minAttendees
    maxAttendees
    maxWaitlist
    startDateTime
    endDateTime
    endDateTimeFirstCourseLesson
    cycle
    annotation
    websiteInfo
    allowMonthlyPayment
    monthlySchedule
    isActive
    showInWeb
  }
}
    `;
export type UpdateCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;
export type UpdateCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseMutation, UpdateCourseMutationVariables>, 'mutation'>;

    export const UpdateCourseComponent = (props: UpdateCourseComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseMutation, UpdateCourseMutationVariables> mutation={UpdateCourseDocument} {...props} />
    );
    
export type UpdateCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCourseMutation, UpdateCourseMutationVariables> & TChildProps;
export function withUpdateCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCourseMutation,
  UpdateCourseMutationVariables,
  UpdateCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCourseMutation, UpdateCourseMutationVariables, UpdateCourseProps<TChildProps>>(UpdateCourseDocument, {
      alias: 'updateCourse',
      ...operationOptions
    });
};

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      editCourseData: // value for 'editCourseData'
 *      fillFromWaitingList: // value for 'fillFromWaitingList'
 *      notifyBooker: // value for 'notifyBooker'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, baseOptions);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = ApolloReactCommon.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const GetCitiesDocument = gql`
    query GetCities {
  cities {
    city
  }
}
    `;
export type GetCitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCitiesQuery, GetCitiesQueryVariables>, 'query'>;

    export const GetCitiesComponent = (props: GetCitiesComponentProps) => (
      <ApolloReactComponents.Query<GetCitiesQuery, GetCitiesQueryVariables> query={GetCitiesDocument} {...props} />
    );
    
export type GetCitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCitiesQuery, GetCitiesQueryVariables> & TChildProps;
export function withGetCities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCitiesQuery,
  GetCitiesQueryVariables,
  GetCitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCitiesQuery, GetCitiesQueryVariables, GetCitiesProps<TChildProps>>(GetCitiesDocument, {
      alias: 'getCities',
      ...operationOptions
    });
};

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, baseOptions);
      }
export function useGetCitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, baseOptions);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesQueryResult = ApolloReactCommon.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const GetLocationsSortedByCityDocument = gql`
    query GetLocationsSortedByCity {
  locationsSortedByCity {
    city
    locations {
      id
      city
      name
      venues {
        id
        name
      }
    }
  }
}
    `;
export type GetLocationsSortedByCityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>, 'query'>;

    export const GetLocationsSortedByCityComponent = (props: GetLocationsSortedByCityComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables> query={GetLocationsSortedByCityDocument} {...props} />
    );
    
export type GetLocationsSortedByCityProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables> & TChildProps;
export function withGetLocationsSortedByCity<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsSortedByCityQuery,
  GetLocationsSortedByCityQueryVariables,
  GetLocationsSortedByCityProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables, GetLocationsSortedByCityProps<TChildProps>>(GetLocationsSortedByCityDocument, {
      alias: 'getLocationsSortedByCity',
      ...operationOptions
    });
};

/**
 * __useGetLocationsSortedByCityQuery__
 *
 * To run a query within a React component, call `useGetLocationsSortedByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsSortedByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsSortedByCityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsSortedByCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>(GetLocationsSortedByCityDocument, baseOptions);
      }
export function useGetLocationsSortedByCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>(GetLocationsSortedByCityDocument, baseOptions);
        }
export type GetLocationsSortedByCityQueryHookResult = ReturnType<typeof useGetLocationsSortedByCityQuery>;
export type GetLocationsSortedByCityLazyQueryHookResult = ReturnType<typeof useGetLocationsSortedByCityLazyQuery>;
export type GetLocationsSortedByCityQueryResult = ApolloReactCommon.QueryResult<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>;
export const GetLocationsByCityDocument = gql`
    query GetLocationsByCity($city: String!) {
  locationsByCity(city: $city) {
    id
    name
    venues {
      id
      name
    }
  }
}
    `;
export type GetLocationsByCityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>, 'query'> & ({ variables: GetLocationsByCityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationsByCityComponent = (props: GetLocationsByCityComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsByCityQuery, GetLocationsByCityQueryVariables> query={GetLocationsByCityDocument} {...props} />
    );
    
export type GetLocationsByCityProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsByCityQuery, GetLocationsByCityQueryVariables> & TChildProps;
export function withGetLocationsByCity<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsByCityQuery,
  GetLocationsByCityQueryVariables,
  GetLocationsByCityProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsByCityQuery, GetLocationsByCityQueryVariables, GetLocationsByCityProps<TChildProps>>(GetLocationsByCityDocument, {
      alias: 'getLocationsByCity',
      ...operationOptions
    });
};

/**
 * __useGetLocationsByCityQuery__
 *
 * To run a query within a React component, call `useGetLocationsByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *   },
 * });
 */
export function useGetLocationsByCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>(GetLocationsByCityDocument, baseOptions);
      }
export function useGetLocationsByCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>(GetLocationsByCityDocument, baseOptions);
        }
export type GetLocationsByCityQueryHookResult = ReturnType<typeof useGetLocationsByCityQuery>;
export type GetLocationsByCityLazyQueryHookResult = ReturnType<typeof useGetLocationsByCityLazyQuery>;
export type GetLocationsByCityQueryResult = ApolloReactCommon.QueryResult<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>;
export const GetInstructorsDocument = gql`
    query GetInstructors {
  instructors {
    id
    firstname
    lastname
    level
    leaveDate
    active
  }
}
    `;
export type GetInstructorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstructorsQuery, GetInstructorsQueryVariables>, 'query'>;

    export const GetInstructorsComponent = (props: GetInstructorsComponentProps) => (
      <ApolloReactComponents.Query<GetInstructorsQuery, GetInstructorsQueryVariables> query={GetInstructorsDocument} {...props} />
    );
    
export type GetInstructorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstructorsQuery, GetInstructorsQueryVariables> & TChildProps;
export function withGetInstructors<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstructorsQuery,
  GetInstructorsQueryVariables,
  GetInstructorsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstructorsQuery, GetInstructorsQueryVariables, GetInstructorsProps<TChildProps>>(GetInstructorsDocument, {
      alias: 'getInstructors',
      ...operationOptions
    });
};

/**
 * __useGetInstructorsQuery__
 *
 * To run a query within a React component, call `useGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, baseOptions);
      }
export function useGetInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, baseOptions);
        }
export type GetInstructorsQueryHookResult = ReturnType<typeof useGetInstructorsQuery>;
export type GetInstructorsLazyQueryHookResult = ReturnType<typeof useGetInstructorsLazyQuery>;
export type GetInstructorsQueryResult = ApolloReactCommon.QueryResult<GetInstructorsQuery, GetInstructorsQueryVariables>;
export const CreateCourseDocument = gql`
    mutation CreateCourse($newCourseData: NewCourseInput!) {
  createCourse(newCourseData: $newCourseData) {
    id
  }
}
    `;
export type CreateCourseMutationFn = ApolloReactCommon.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;
export type CreateCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCourseMutation, CreateCourseMutationVariables>, 'mutation'>;

    export const CreateCourseComponent = (props: CreateCourseComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCourseMutation, CreateCourseMutationVariables> mutation={CreateCourseDocument} {...props} />
    );
    
export type CreateCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCourseMutation, CreateCourseMutationVariables> & TChildProps;
export function withCreateCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCourseMutation,
  CreateCourseMutationVariables,
  CreateCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCourseMutation, CreateCourseMutationVariables, CreateCourseProps<TChildProps>>(CreateCourseDocument, {
      alias: 'createCourse',
      ...operationOptions
    });
};

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      newCourseData: // value for 'newCourseData'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, baseOptions);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = ApolloReactCommon.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const GetCourseTypeStateDocument = gql`
    query GetCourseTypeState($id: String!) {
  courseTypeStateById(id: $id) {
    id
    isActive
    showInWeb
    name
  }
}
    `;
export type GetCourseTypeStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>, 'query'> & ({ variables: GetCourseTypeStateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseTypeStateComponent = (props: GetCourseTypeStateComponentProps) => (
      <ApolloReactComponents.Query<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables> query={GetCourseTypeStateDocument} {...props} />
    );
    
export type GetCourseTypeStateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables> & TChildProps;
export function withGetCourseTypeState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseTypeStateQuery,
  GetCourseTypeStateQueryVariables,
  GetCourseTypeStateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables, GetCourseTypeStateProps<TChildProps>>(GetCourseTypeStateDocument, {
      alias: 'getCourseTypeState',
      ...operationOptions
    });
};

/**
 * __useGetCourseTypeStateQuery__
 *
 * To run a query within a React component, call `useGetCourseTypeStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypeStateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypeStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseTypeStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>(GetCourseTypeStateDocument, baseOptions);
      }
export function useGetCourseTypeStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>(GetCourseTypeStateDocument, baseOptions);
        }
export type GetCourseTypeStateQueryHookResult = ReturnType<typeof useGetCourseTypeStateQuery>;
export type GetCourseTypeStateLazyQueryHookResult = ReturnType<typeof useGetCourseTypeStateLazyQuery>;
export type GetCourseTypeStateQueryResult = ApolloReactCommon.QueryResult<GetCourseTypeStateQuery, GetCourseTypeStateQueryVariables>;
export const RemoveCourseTypeDocument = gql`
    mutation RemoveCourseType($id: ID!) {
  removeCourseType(id: $id)
}
    `;
export type RemoveCourseTypeMutationFn = ApolloReactCommon.MutationFunction<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>;
export type RemoveCourseTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>, 'mutation'>;

    export const RemoveCourseTypeComponent = (props: RemoveCourseTypeComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables> mutation={RemoveCourseTypeDocument} {...props} />
    );
    
export type RemoveCourseTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables> & TChildProps;
export function withRemoveCourseType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveCourseTypeMutation,
  RemoveCourseTypeMutationVariables,
  RemoveCourseTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables, RemoveCourseTypeProps<TChildProps>>(RemoveCourseTypeDocument, {
      alias: 'removeCourseType',
      ...operationOptions
    });
};

/**
 * __useRemoveCourseTypeMutation__
 *
 * To run a mutation, you first call `useRemoveCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseTypeMutation, { data, loading, error }] = useRemoveCourseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>(RemoveCourseTypeDocument, baseOptions);
      }
export type RemoveCourseTypeMutationHookResult = ReturnType<typeof useRemoveCourseTypeMutation>;
export type RemoveCourseTypeMutationResult = ApolloReactCommon.MutationResult<RemoveCourseTypeMutation>;
export type RemoveCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>;
export const CreateCourseTypeDocument = gql`
    mutation CreateCourseType($newCourseTypeData: NewCourseTypeInput!) {
  createCourseType(newCourseTypeData: $newCourseTypeData) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
    participationPrerequisites {
      id
      groupLabel
      groupDescription
      optionalInputFields
      minCheckCount
      courseTypeId
    }
  }
}
    `;
export type CreateCourseTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>;
export type CreateCourseTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>, 'mutation'>;

    export const CreateCourseTypeComponent = (props: CreateCourseTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCourseTypeMutation, CreateCourseTypeMutationVariables> mutation={CreateCourseTypeDocument} {...props} />
    );
    
export type CreateCourseTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCourseTypeMutation, CreateCourseTypeMutationVariables> & TChildProps;
export function withCreateCourseType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCourseTypeMutation,
  CreateCourseTypeMutationVariables,
  CreateCourseTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCourseTypeMutation, CreateCourseTypeMutationVariables, CreateCourseTypeProps<TChildProps>>(CreateCourseTypeDocument, {
      alias: 'createCourseType',
      ...operationOptions
    });
};

/**
 * __useCreateCourseTypeMutation__
 *
 * To run a mutation, you first call `useCreateCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseTypeMutation, { data, loading, error }] = useCreateCourseTypeMutation({
 *   variables: {
 *      newCourseTypeData: // value for 'newCourseTypeData'
 *   },
 * });
 */
export function useCreateCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>(CreateCourseTypeDocument, baseOptions);
      }
export type CreateCourseTypeMutationHookResult = ReturnType<typeof useCreateCourseTypeMutation>;
export type CreateCourseTypeMutationResult = ApolloReactCommon.MutationResult<CreateCourseTypeMutation>;
export type CreateCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>;
export const UpdateCourseTypeDocument = gql`
    mutation UpdateCourseType($id: String!, $newCourseTypeData: NewCourseTypeInput!) {
  updateCourseType(id: $id, newCourseTypeData: $newCourseTypeData) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
  }
}
    `;
export type UpdateCourseTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>;
export type UpdateCourseTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>, 'mutation'>;

    export const UpdateCourseTypeComponent = (props: UpdateCourseTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables> mutation={UpdateCourseTypeDocument} {...props} />
    );
    
export type UpdateCourseTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables> & TChildProps;
export function withUpdateCourseType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCourseTypeMutation,
  UpdateCourseTypeMutationVariables,
  UpdateCourseTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables, UpdateCourseTypeProps<TChildProps>>(UpdateCourseTypeDocument, {
      alias: 'updateCourseType',
      ...operationOptions
    });
};

/**
 * __useUpdateCourseTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseTypeMutation, { data, loading, error }] = useUpdateCourseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCourseTypeData: // value for 'newCourseTypeData'
 *   },
 * });
 */
export function useUpdateCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>(UpdateCourseTypeDocument, baseOptions);
      }
export type UpdateCourseTypeMutationHookResult = ReturnType<typeof useUpdateCourseTypeMutation>;
export type UpdateCourseTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCourseTypeMutation>;
export type UpdateCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>;
export const CourseTypeGetCourseCategoriesDocument = gql`
    query CourseTypeGetCourseCategories {
  courseCategories {
    id
    name
  }
}
    `;
export type CourseTypeGetCourseCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>, 'query'>;

    export const CourseTypeGetCourseCategoriesComponent = (props: CourseTypeGetCourseCategoriesComponentProps) => (
      <ApolloReactComponents.Query<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables> query={CourseTypeGetCourseCategoriesDocument} {...props} />
    );
    
export type CourseTypeGetCourseCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables> & TChildProps;
export function withCourseTypeGetCourseCategories<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CourseTypeGetCourseCategoriesQuery,
  CourseTypeGetCourseCategoriesQueryVariables,
  CourseTypeGetCourseCategoriesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables, CourseTypeGetCourseCategoriesProps<TChildProps>>(CourseTypeGetCourseCategoriesDocument, {
      alias: 'courseTypeGetCourseCategories',
      ...operationOptions
    });
};

/**
 * __useCourseTypeGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useCourseTypeGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseTypeGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseTypeGetCourseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseTypeGetCourseCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>(CourseTypeGetCourseCategoriesDocument, baseOptions);
      }
export function useCourseTypeGetCourseCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>(CourseTypeGetCourseCategoriesDocument, baseOptions);
        }
export type CourseTypeGetCourseCategoriesQueryHookResult = ReturnType<typeof useCourseTypeGetCourseCategoriesQuery>;
export type CourseTypeGetCourseCategoriesLazyQueryHookResult = ReturnType<typeof useCourseTypeGetCourseCategoriesLazyQuery>;
export type CourseTypeGetCourseCategoriesQueryResult = ApolloReactCommon.QueryResult<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>;
export const GetCourseCategoriesDocument = gql`
    query GetCourseCategories {
  courseCategories {
    id
    isActive
    name
    description
    linkedCourseTypes {
      id
      name
      color
    }
  }
}
    `;
export type GetCourseCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>, 'query'>;

    export const GetCourseCategoriesComponent = (props: GetCourseCategoriesComponentProps) => (
      <ApolloReactComponents.Query<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables> query={GetCourseCategoriesDocument} {...props} />
    );
    
export type GetCourseCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables> & TChildProps;
export function withGetCourseCategories<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseCategoriesQuery,
  GetCourseCategoriesQueryVariables,
  GetCourseCategoriesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables, GetCourseCategoriesProps<TChildProps>>(GetCourseCategoriesDocument, {
      alias: 'getCourseCategories',
      ...operationOptions
    });
};

/**
 * __useGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, baseOptions);
      }
export function useGetCourseCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, baseOptions);
        }
export type GetCourseCategoriesQueryHookResult = ReturnType<typeof useGetCourseCategoriesQuery>;
export type GetCourseCategoriesLazyQueryHookResult = ReturnType<typeof useGetCourseCategoriesLazyQuery>;
export type GetCourseCategoriesQueryResult = ApolloReactCommon.QueryResult<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>;
export const GetCourseCategoryDocument = gql`
    query GetCourseCategory($id: String!) {
  courseCategoryById(id: $id) {
    id
    isActive
    name
    description
    linkedCourseTypes {
      id
      name
    }
  }
}
    `;
export type GetCourseCategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>, 'query'> & ({ variables: GetCourseCategoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseCategoryComponent = (props: GetCourseCategoryComponentProps) => (
      <ApolloReactComponents.Query<GetCourseCategoryQuery, GetCourseCategoryQueryVariables> query={GetCourseCategoryDocument} {...props} />
    );
    
export type GetCourseCategoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseCategoryQuery, GetCourseCategoryQueryVariables> & TChildProps;
export function withGetCourseCategory<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseCategoryQuery,
  GetCourseCategoryQueryVariables,
  GetCourseCategoryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseCategoryQuery, GetCourseCategoryQueryVariables, GetCourseCategoryProps<TChildProps>>(GetCourseCategoryDocument, {
      alias: 'getCourseCategory',
      ...operationOptions
    });
};

/**
 * __useGetCourseCategoryQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, baseOptions);
      }
export function useGetCourseCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, baseOptions);
        }
export type GetCourseCategoryQueryHookResult = ReturnType<typeof useGetCourseCategoryQuery>;
export type GetCourseCategoryLazyQueryHookResult = ReturnType<typeof useGetCourseCategoryLazyQuery>;
export type GetCourseCategoryQueryResult = ApolloReactCommon.QueryResult<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>;
export const CreateCourseCategoryDocument = gql`
    mutation CreateCourseCategory($newCourseCategoryData: NewCourseCategoryInput!) {
  createCourseCategory(newCourseCategoryData: $newCourseCategoryData) {
    id
    name
    isActive
    description
    linkedCourseTypes {
      id
    }
  }
}
    `;
export type CreateCourseCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>;
export type CreateCourseCategoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>, 'mutation'>;

    export const CreateCourseCategoryComponent = (props: CreateCourseCategoryComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables> mutation={CreateCourseCategoryDocument} {...props} />
    );
    
export type CreateCourseCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables> & TChildProps;
export function withCreateCourseCategory<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCourseCategoryMutation,
  CreateCourseCategoryMutationVariables,
  CreateCourseCategoryProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables, CreateCourseCategoryProps<TChildProps>>(CreateCourseCategoryDocument, {
      alias: 'createCourseCategory',
      ...operationOptions
    });
};

/**
 * __useCreateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseCategoryMutation, { data, loading, error }] = useCreateCourseCategoryMutation({
 *   variables: {
 *      newCourseCategoryData: // value for 'newCourseCategoryData'
 *   },
 * });
 */
export function useCreateCourseCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>(CreateCourseCategoryDocument, baseOptions);
      }
export type CreateCourseCategoryMutationHookResult = ReturnType<typeof useCreateCourseCategoryMutation>;
export type CreateCourseCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCourseCategoryMutation>;
export type CreateCourseCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>;
export const UpdateCourseCategoryDocument = gql`
    mutation UpdateCourseCategory($id: String!, $newCourseCategoryData: NewCourseCategoryInput!) {
  updateCourseCategory(id: $id, newCourseCategoryData: $newCourseCategoryData) {
    id
    name
    isActive
    description
    linkedCourseTypes {
      id
    }
  }
}
    `;
export type UpdateCourseCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>;
export type UpdateCourseCategoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>, 'mutation'>;

    export const UpdateCourseCategoryComponent = (props: UpdateCourseCategoryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables> mutation={UpdateCourseCategoryDocument} {...props} />
    );
    
export type UpdateCourseCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables> & TChildProps;
export function withUpdateCourseCategory<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCourseCategoryMutation,
  UpdateCourseCategoryMutationVariables,
  UpdateCourseCategoryProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables, UpdateCourseCategoryProps<TChildProps>>(UpdateCourseCategoryDocument, {
      alias: 'updateCourseCategory',
      ...operationOptions
    });
};

/**
 * __useUpdateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCategoryMutation, { data, loading, error }] = useUpdateCourseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCourseCategoryData: // value for 'newCourseCategoryData'
 *   },
 * });
 */
export function useUpdateCourseCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>(UpdateCourseCategoryDocument, baseOptions);
      }
export type UpdateCourseCategoryMutationHookResult = ReturnType<typeof useUpdateCourseCategoryMutation>;
export type UpdateCourseCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCourseCategoryMutation>;
export type UpdateCourseCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>;
export const CourseGetInstructorsDocument = gql`
    query CourseGetInstructors {
  instructors {
    id
    firstname
    lastname
    level
  }
}
    `;
export type CourseGetInstructorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>, 'query'>;

    export const CourseGetInstructorsComponent = (props: CourseGetInstructorsComponentProps) => (
      <ApolloReactComponents.Query<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables> query={CourseGetInstructorsDocument} {...props} />
    );
    
export type CourseGetInstructorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables> & TChildProps;
export function withCourseGetInstructors<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CourseGetInstructorsQuery,
  CourseGetInstructorsQueryVariables,
  CourseGetInstructorsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables, CourseGetInstructorsProps<TChildProps>>(CourseGetInstructorsDocument, {
      alias: 'courseGetInstructors',
      ...operationOptions
    });
};

/**
 * __useCourseGetInstructorsQuery__
 *
 * To run a query within a React component, call `useCourseGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseGetInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseGetInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>(CourseGetInstructorsDocument, baseOptions);
      }
export function useCourseGetInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>(CourseGetInstructorsDocument, baseOptions);
        }
export type CourseGetInstructorsQueryHookResult = ReturnType<typeof useCourseGetInstructorsQuery>;
export type CourseGetInstructorsLazyQueryHookResult = ReturnType<typeof useCourseGetInstructorsLazyQuery>;
export type CourseGetInstructorsQueryResult = ApolloReactCommon.QueryResult<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>;
export const CourseGetInstructorsByLocationDocument = gql`
    query CourseGetInstructorsByLocation($locationId: String!) {
  findInstructorsByLocation(locationId: $locationId) {
    id
    firstname
    lastname
    level
    active
    leaveDate
  }
}
    `;
export type CourseGetInstructorsByLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>, 'query'> & ({ variables: CourseGetInstructorsByLocationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CourseGetInstructorsByLocationComponent = (props: CourseGetInstructorsByLocationComponentProps) => (
      <ApolloReactComponents.Query<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables> query={CourseGetInstructorsByLocationDocument} {...props} />
    );
    
export type CourseGetInstructorsByLocationProps<TChildProps = {}> = ApolloReactHoc.DataProps<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables> & TChildProps;
export function withCourseGetInstructorsByLocation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CourseGetInstructorsByLocationQuery,
  CourseGetInstructorsByLocationQueryVariables,
  CourseGetInstructorsByLocationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables, CourseGetInstructorsByLocationProps<TChildProps>>(CourseGetInstructorsByLocationDocument, {
      alias: 'courseGetInstructorsByLocation',
      ...operationOptions
    });
};

/**
 * __useCourseGetInstructorsByLocationQuery__
 *
 * To run a query within a React component, call `useCourseGetInstructorsByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseGetInstructorsByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseGetInstructorsByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCourseGetInstructorsByLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>(CourseGetInstructorsByLocationDocument, baseOptions);
      }
export function useCourseGetInstructorsByLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>(CourseGetInstructorsByLocationDocument, baseOptions);
        }
export type CourseGetInstructorsByLocationQueryHookResult = ReturnType<typeof useCourseGetInstructorsByLocationQuery>;
export type CourseGetInstructorsByLocationLazyQueryHookResult = ReturnType<typeof useCourseGetInstructorsByLocationLazyQuery>;
export type CourseGetInstructorsByLocationQueryResult = ApolloReactCommon.QueryResult<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>;
export const GetCourseLessonsByCourseIdDocument = gql`
    query GetCourseLessonsByCourseId($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    endDateTime
  }
}
    `;
export type GetCourseLessonsByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByCourseIdComponent = (props: GetCourseLessonsByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables> query={GetCourseLessonsByCourseIdDocument} {...props} />
    );
    
export type GetCourseLessonsByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables> & TChildProps;
export function withGetCourseLessonsByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByCourseIdQuery,
  GetCourseLessonsByCourseIdQueryVariables,
  GetCourseLessonsByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables, GetCourseLessonsByCourseIdProps<TChildProps>>(GetCourseLessonsByCourseIdDocument, {
      alias: 'getCourseLessonsByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseLessonsByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>(GetCourseLessonsByCourseIdDocument, baseOptions);
      }
export function useGetCourseLessonsByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>(GetCourseLessonsByCourseIdDocument, baseOptions);
        }
export type GetCourseLessonsByCourseIdQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdQuery>;
export type GetCourseLessonsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdLazyQuery>;
export type GetCourseLessonsByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>;
export const GetCourseLessonsByCourseIdWithCourseLessonBookingsDocument = gql`
    query GetCourseLessonsByCourseIdWithCourseLessonBookings($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    endDateTime
    bookings {
      id
      attendee {
        id
        firstname
      }
    }
    courseLessonBookings {
      id
      courseLessonId
      attendance
      bookingId
    }
  }
}
    `;
export type GetCourseLessonsByCourseIdWithCourseLessonBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByCourseIdWithCourseLessonBookingsComponent = (props: GetCourseLessonsByCourseIdWithCourseLessonBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables> query={GetCourseLessonsByCourseIdWithCourseLessonBookingsDocument} {...props} />
    );
    
export type GetCourseLessonsByCourseIdWithCourseLessonBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables> & TChildProps;
export function withGetCourseLessonsByCourseIdWithCourseLessonBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery,
  GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables,
  GetCourseLessonsByCourseIdWithCourseLessonBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables, GetCourseLessonsByCourseIdWithCourseLessonBookingsProps<TChildProps>>(GetCourseLessonsByCourseIdWithCourseLessonBookingsDocument, {
      alias: 'getCourseLessonsByCourseIdWithCourseLessonBookings',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>(GetCourseLessonsByCourseIdWithCourseLessonBookingsDocument, baseOptions);
      }
export function useGetCourseLessonsByCourseIdWithCourseLessonBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>(GetCourseLessonsByCourseIdWithCourseLessonBookingsDocument, baseOptions);
        }
export type GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdWithCourseLessonBookingsQuery>;
export type GetCourseLessonsByCourseIdWithCourseLessonBookingsLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdWithCourseLessonBookingsLazyQuery>;
export type GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCourseIdWithCourseLessonBookingsQuery, GetCourseLessonsByCourseIdWithCourseLessonBookingsQueryVariables>;
export const GetCourseLessonsByCourseIdWithLocationDocument = gql`
    query GetCourseLessonsByCourseIdWithLocation($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    endDateTime
    location {
      id
      city
      name
    }
  }
}
    `;
export type GetCourseLessonsByCourseIdWithLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByCourseIdWithLocationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByCourseIdWithLocationComponent = (props: GetCourseLessonsByCourseIdWithLocationComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables> query={GetCourseLessonsByCourseIdWithLocationDocument} {...props} />
    );
    
export type GetCourseLessonsByCourseIdWithLocationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables> & TChildProps;
export function withGetCourseLessonsByCourseIdWithLocation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByCourseIdWithLocationQuery,
  GetCourseLessonsByCourseIdWithLocationQueryVariables,
  GetCourseLessonsByCourseIdWithLocationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables, GetCourseLessonsByCourseIdWithLocationProps<TChildProps>>(GetCourseLessonsByCourseIdWithLocationDocument, {
      alias: 'getCourseLessonsByCourseIdWithLocation',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByCourseIdWithLocationQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCourseIdWithLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCourseIdWithLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCourseIdWithLocationQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseLessonsByCourseIdWithLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>(GetCourseLessonsByCourseIdWithLocationDocument, baseOptions);
      }
export function useGetCourseLessonsByCourseIdWithLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>(GetCourseLessonsByCourseIdWithLocationDocument, baseOptions);
        }
export type GetCourseLessonsByCourseIdWithLocationQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdWithLocationQuery>;
export type GetCourseLessonsByCourseIdWithLocationLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdWithLocationLazyQuery>;
export type GetCourseLessonsByCourseIdWithLocationQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCourseIdWithLocationQuery, GetCourseLessonsByCourseIdWithLocationQueryVariables>;
export const GetCourseVariablesForTemplateDocument = gql`
    query GetCourseVariablesForTemplate($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    location {
      id
      city
      name
    }
    course {
      id
      courseNumber
      prefixedCourseNumber
      courseType {
        id
        name
        description
      }
    }
    endDateTime
  }
}
    `;
export type GetCourseVariablesForTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>, 'query'> & ({ variables: GetCourseVariablesForTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseVariablesForTemplateComponent = (props: GetCourseVariablesForTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables> query={GetCourseVariablesForTemplateDocument} {...props} />
    );
    
export type GetCourseVariablesForTemplateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables> & TChildProps;
export function withGetCourseVariablesForTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseVariablesForTemplateQuery,
  GetCourseVariablesForTemplateQueryVariables,
  GetCourseVariablesForTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables, GetCourseVariablesForTemplateProps<TChildProps>>(GetCourseVariablesForTemplateDocument, {
      alias: 'getCourseVariablesForTemplate',
      ...operationOptions
    });
};

/**
 * __useGetCourseVariablesForTemplateQuery__
 *
 * To run a query within a React component, call `useGetCourseVariablesForTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseVariablesForTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseVariablesForTemplateQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseVariablesForTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>(GetCourseVariablesForTemplateDocument, baseOptions);
      }
export function useGetCourseVariablesForTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>(GetCourseVariablesForTemplateDocument, baseOptions);
        }
export type GetCourseVariablesForTemplateQueryHookResult = ReturnType<typeof useGetCourseVariablesForTemplateQuery>;
export type GetCourseVariablesForTemplateLazyQueryHookResult = ReturnType<typeof useGetCourseVariablesForTemplateLazyQuery>;
export type GetCourseVariablesForTemplateQueryResult = ApolloReactCommon.QueryResult<GetCourseVariablesForTemplateQuery, GetCourseVariablesForTemplateQueryVariables>;
export const IsCourseNumberAvailableDocument = gql`
    query IsCourseNumberAvailable($customCourseNumberPrefix: String!, $courseId: String!) {
  isCourseNameUnique(customCourseNumberPrefix: $customCourseNumberPrefix, courseId: $courseId)
}
    `;
export type IsCourseNumberAvailableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>, 'query'> & ({ variables: IsCourseNumberAvailableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsCourseNumberAvailableComponent = (props: IsCourseNumberAvailableComponentProps) => (
      <ApolloReactComponents.Query<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables> query={IsCourseNumberAvailableDocument} {...props} />
    );
    
export type IsCourseNumberAvailableProps<TChildProps = {}> = ApolloReactHoc.DataProps<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables> & TChildProps;
export function withIsCourseNumberAvailable<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IsCourseNumberAvailableQuery,
  IsCourseNumberAvailableQueryVariables,
  IsCourseNumberAvailableProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables, IsCourseNumberAvailableProps<TChildProps>>(IsCourseNumberAvailableDocument, {
      alias: 'isCourseNumberAvailable',
      ...operationOptions
    });
};

/**
 * __useIsCourseNumberAvailableQuery__
 *
 * To run a query within a React component, call `useIsCourseNumberAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCourseNumberAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCourseNumberAvailableQuery({
 *   variables: {
 *      customCourseNumberPrefix: // value for 'customCourseNumberPrefix'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useIsCourseNumberAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>(IsCourseNumberAvailableDocument, baseOptions);
      }
export function useIsCourseNumberAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>(IsCourseNumberAvailableDocument, baseOptions);
        }
export type IsCourseNumberAvailableQueryHookResult = ReturnType<typeof useIsCourseNumberAvailableQuery>;
export type IsCourseNumberAvailableLazyQueryHookResult = ReturnType<typeof useIsCourseNumberAvailableLazyQuery>;
export type IsCourseNumberAvailableQueryResult = ApolloReactCommon.QueryResult<IsCourseNumberAvailableQuery, IsCourseNumberAvailableQueryVariables>;
export const GetFreePlacesByCourseIdDocument = gql`
    query getFreePlacesByCourseId($id: String!) {
  courseById(id: $id) {
    id
    freePlaces
    bookingCount
  }
}
    `;
export type GetFreePlacesByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>, 'query'> & ({ variables: GetFreePlacesByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFreePlacesByCourseIdComponent = (props: GetFreePlacesByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables> query={GetFreePlacesByCourseIdDocument} {...props} />
    );
    
export type GetFreePlacesByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables> & TChildProps;
export function withGetFreePlacesByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFreePlacesByCourseIdQuery,
  GetFreePlacesByCourseIdQueryVariables,
  GetFreePlacesByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables, GetFreePlacesByCourseIdProps<TChildProps>>(GetFreePlacesByCourseIdDocument, {
      alias: 'getFreePlacesByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetFreePlacesByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetFreePlacesByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreePlacesByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreePlacesByCourseIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFreePlacesByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>(GetFreePlacesByCourseIdDocument, baseOptions);
      }
export function useGetFreePlacesByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>(GetFreePlacesByCourseIdDocument, baseOptions);
        }
export type GetFreePlacesByCourseIdQueryHookResult = ReturnType<typeof useGetFreePlacesByCourseIdQuery>;
export type GetFreePlacesByCourseIdLazyQueryHookResult = ReturnType<typeof useGetFreePlacesByCourseIdLazyQuery>;
export type GetFreePlacesByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetFreePlacesByCourseIdQuery, GetFreePlacesByCourseIdQueryVariables>;
export const GetMaxAttendeesByCourseIdDocument = gql`
    query getMaxAttendeesByCourseId($id: String!) {
  courseById(id: $id) {
    id
    maxAttendees
  }
}
    `;
export type GetMaxAttendeesByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>, 'query'> & ({ variables: GetMaxAttendeesByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMaxAttendeesByCourseIdComponent = (props: GetMaxAttendeesByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables> query={GetMaxAttendeesByCourseIdDocument} {...props} />
    );
    
export type GetMaxAttendeesByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables> & TChildProps;
export function withGetMaxAttendeesByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMaxAttendeesByCourseIdQuery,
  GetMaxAttendeesByCourseIdQueryVariables,
  GetMaxAttendeesByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables, GetMaxAttendeesByCourseIdProps<TChildProps>>(GetMaxAttendeesByCourseIdDocument, {
      alias: 'getMaxAttendeesByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetMaxAttendeesByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetMaxAttendeesByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxAttendeesByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxAttendeesByCourseIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMaxAttendeesByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>(GetMaxAttendeesByCourseIdDocument, baseOptions);
      }
export function useGetMaxAttendeesByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>(GetMaxAttendeesByCourseIdDocument, baseOptions);
        }
export type GetMaxAttendeesByCourseIdQueryHookResult = ReturnType<typeof useGetMaxAttendeesByCourseIdQuery>;
export type GetMaxAttendeesByCourseIdLazyQueryHookResult = ReturnType<typeof useGetMaxAttendeesByCourseIdLazyQuery>;
export type GetMaxAttendeesByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetMaxAttendeesByCourseIdQuery, GetMaxAttendeesByCourseIdQueryVariables>;
export const GetAllCoursesWithAllowMonthlyPaymentDocument = gql`
    query GetAllCoursesWithAllowMonthlyPayment {
  getAllCoursesWithAllowMonthlyPayment {
    id
    prefixedCourseNumber
    allowMonthlyPayment
  }
}
    `;
export type GetAllCoursesWithAllowMonthlyPaymentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>, 'query'>;

    export const GetAllCoursesWithAllowMonthlyPaymentComponent = (props: GetAllCoursesWithAllowMonthlyPaymentComponentProps) => (
      <ApolloReactComponents.Query<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables> query={GetAllCoursesWithAllowMonthlyPaymentDocument} {...props} />
    );
    
export type GetAllCoursesWithAllowMonthlyPaymentProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables> & TChildProps;
export function withGetAllCoursesWithAllowMonthlyPayment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAllCoursesWithAllowMonthlyPaymentQuery,
  GetAllCoursesWithAllowMonthlyPaymentQueryVariables,
  GetAllCoursesWithAllowMonthlyPaymentProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables, GetAllCoursesWithAllowMonthlyPaymentProps<TChildProps>>(GetAllCoursesWithAllowMonthlyPaymentDocument, {
      alias: 'getAllCoursesWithAllowMonthlyPayment',
      ...operationOptions
    });
};

/**
 * __useGetAllCoursesWithAllowMonthlyPaymentQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesWithAllowMonthlyPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesWithAllowMonthlyPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesWithAllowMonthlyPaymentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCoursesWithAllowMonthlyPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>(GetAllCoursesWithAllowMonthlyPaymentDocument, baseOptions);
      }
export function useGetAllCoursesWithAllowMonthlyPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>(GetAllCoursesWithAllowMonthlyPaymentDocument, baseOptions);
        }
export type GetAllCoursesWithAllowMonthlyPaymentQueryHookResult = ReturnType<typeof useGetAllCoursesWithAllowMonthlyPaymentQuery>;
export type GetAllCoursesWithAllowMonthlyPaymentLazyQueryHookResult = ReturnType<typeof useGetAllCoursesWithAllowMonthlyPaymentLazyQuery>;
export type GetAllCoursesWithAllowMonthlyPaymentQueryResult = ApolloReactCommon.QueryResult<GetAllCoursesWithAllowMonthlyPaymentQuery, GetAllCoursesWithAllowMonthlyPaymentQueryVariables>;
export const GetCourseAnnotationDocument = gql`
    query getCourseAnnotation($id: String!) {
  courseById(id: $id) {
    id
    annotation
  }
}
    `;
export type GetCourseAnnotationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>, 'query'> & ({ variables: GetCourseAnnotationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseAnnotationComponent = (props: GetCourseAnnotationComponentProps) => (
      <ApolloReactComponents.Query<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables> query={GetCourseAnnotationDocument} {...props} />
    );
    
export type GetCourseAnnotationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables> & TChildProps;
export function withGetCourseAnnotation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseAnnotationQuery,
  GetCourseAnnotationQueryVariables,
  GetCourseAnnotationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables, GetCourseAnnotationProps<TChildProps>>(GetCourseAnnotationDocument, {
      alias: 'getCourseAnnotation',
      ...operationOptions
    });
};

/**
 * __useGetCourseAnnotationQuery__
 *
 * To run a query within a React component, call `useGetCourseAnnotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseAnnotationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseAnnotationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseAnnotationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>(GetCourseAnnotationDocument, baseOptions);
      }
export function useGetCourseAnnotationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>(GetCourseAnnotationDocument, baseOptions);
        }
export type GetCourseAnnotationQueryHookResult = ReturnType<typeof useGetCourseAnnotationQuery>;
export type GetCourseAnnotationLazyQueryHookResult = ReturnType<typeof useGetCourseAnnotationLazyQuery>;
export type GetCourseAnnotationQueryResult = ApolloReactCommon.QueryResult<GetCourseAnnotationQuery, GetCourseAnnotationQueryVariables>;
export const UpdateCourseAnnotationDocument = gql`
    mutation updateCourseAnnotation($id: String!, $annotation: String) {
  updateCourseAnnotation(id: $id, annotation: $annotation) {
    id
    annotation
  }
}
    `;
export type UpdateCourseAnnotationMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables>;
export type UpdateCourseAnnotationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables>, 'mutation'>;

    export const UpdateCourseAnnotationComponent = (props: UpdateCourseAnnotationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables> mutation={UpdateCourseAnnotationDocument} {...props} />
    );
    
export type UpdateCourseAnnotationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables> & TChildProps;
export function withUpdateCourseAnnotation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCourseAnnotationMutation,
  UpdateCourseAnnotationMutationVariables,
  UpdateCourseAnnotationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables, UpdateCourseAnnotationProps<TChildProps>>(UpdateCourseAnnotationDocument, {
      alias: 'updateCourseAnnotation',
      ...operationOptions
    });
};

/**
 * __useUpdateCourseAnnotationMutation__
 *
 * To run a mutation, you first call `useUpdateCourseAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseAnnotationMutation, { data, loading, error }] = useUpdateCourseAnnotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      annotation: // value for 'annotation'
 *   },
 * });
 */
export function useUpdateCourseAnnotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables>(UpdateCourseAnnotationDocument, baseOptions);
      }
export type UpdateCourseAnnotationMutationHookResult = ReturnType<typeof useUpdateCourseAnnotationMutation>;
export type UpdateCourseAnnotationMutationResult = ApolloReactCommon.MutationResult<UpdateCourseAnnotationMutation>;
export type UpdateCourseAnnotationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseAnnotationMutation, UpdateCourseAnnotationMutationVariables>;
export const GetCourseMemberDiscountDocument = gql`
    query getCourseMemberDiscount($id: String!) {
  courseById(id: $id) {
    id
    coursePrice {
      id
      discount
      discountUnit
    }
  }
}
    `;
export type GetCourseMemberDiscountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>, 'query'> & ({ variables: GetCourseMemberDiscountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseMemberDiscountComponent = (props: GetCourseMemberDiscountComponentProps) => (
      <ApolloReactComponents.Query<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables> query={GetCourseMemberDiscountDocument} {...props} />
    );
    
export type GetCourseMemberDiscountProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables> & TChildProps;
export function withGetCourseMemberDiscount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseMemberDiscountQuery,
  GetCourseMemberDiscountQueryVariables,
  GetCourseMemberDiscountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables, GetCourseMemberDiscountProps<TChildProps>>(GetCourseMemberDiscountDocument, {
      alias: 'getCourseMemberDiscount',
      ...operationOptions
    });
};

/**
 * __useGetCourseMemberDiscountQuery__
 *
 * To run a query within a React component, call `useGetCourseMemberDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseMemberDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseMemberDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseMemberDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>(GetCourseMemberDiscountDocument, baseOptions);
      }
export function useGetCourseMemberDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>(GetCourseMemberDiscountDocument, baseOptions);
        }
export type GetCourseMemberDiscountQueryHookResult = ReturnType<typeof useGetCourseMemberDiscountQuery>;
export type GetCourseMemberDiscountLazyQueryHookResult = ReturnType<typeof useGetCourseMemberDiscountLazyQuery>;
export type GetCourseMemberDiscountQueryResult = ApolloReactCommon.QueryResult<GetCourseMemberDiscountQuery, GetCourseMemberDiscountQueryVariables>;
export const GetCoursesByIdsDocument = gql`
    query getCoursesByIds($ids: [String!]!) {
  coursesByIds(ids: $ids) {
    id
    courseNumber
    prefixedCourseNumber
    bookingCount
    billedBookingCount
    freePlaces
    settledBookingCount
    courseType {
      id
      name
      description
      color
    }
    startDateTime
    endDateTime
    endDateTimeFirstCourseLesson
    location {
      id
      city
      name
    }
    venue {
      id
      name
    }
    annotation
    instructors {
      id
      firstname
      lastname
    }
    maxAttendees
    coursePrice {
      id
      grossPrice
      netPrice
      vat
      discount
      discountUnit
      isSpecial
    }
    paymentInterval
    allowMonthlyPayment
    monthlySchedule
    isActive
    showInWeb
  }
}
    `;
export type GetCoursesByIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>, 'query'> & ({ variables: GetCoursesByIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCoursesByIdsComponent = (props: GetCoursesByIdsComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables> query={GetCoursesByIdsDocument} {...props} />
    );
    
export type GetCoursesByIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables> & TChildProps;
export function withGetCoursesByIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesByIdsQuery,
  GetCoursesByIdsQueryVariables,
  GetCoursesByIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables, GetCoursesByIdsProps<TChildProps>>(GetCoursesByIdsDocument, {
      alias: 'getCoursesByIds',
      ...operationOptions
    });
};

/**
 * __useGetCoursesByIdsQuery__
 *
 * To run a query within a React component, call `useGetCoursesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCoursesByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>(GetCoursesByIdsDocument, baseOptions);
      }
export function useGetCoursesByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>(GetCoursesByIdsDocument, baseOptions);
        }
export type GetCoursesByIdsQueryHookResult = ReturnType<typeof useGetCoursesByIdsQuery>;
export type GetCoursesByIdsLazyQueryHookResult = ReturnType<typeof useGetCoursesByIdsLazyQuery>;
export type GetCoursesByIdsQueryResult = ApolloReactCommon.QueryResult<GetCoursesByIdsQuery, GetCoursesByIdsQueryVariables>;
export const GetCoursesByIdsMinimalDocument = gql`
    query getCoursesByIdsMinimal($ids: [String!]!) {
  coursesByIds(ids: $ids) {
    id
    prefixedCourseNumber
    courseType {
      id
      name
      color
    }
    startDateTime
    endDateTime
    endDateTimeFirstCourseLesson
  }
}
    `;
export type GetCoursesByIdsMinimalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>, 'query'> & ({ variables: GetCoursesByIdsMinimalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCoursesByIdsMinimalComponent = (props: GetCoursesByIdsMinimalComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables> query={GetCoursesByIdsMinimalDocument} {...props} />
    );
    
export type GetCoursesByIdsMinimalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables> & TChildProps;
export function withGetCoursesByIdsMinimal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesByIdsMinimalQuery,
  GetCoursesByIdsMinimalQueryVariables,
  GetCoursesByIdsMinimalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables, GetCoursesByIdsMinimalProps<TChildProps>>(GetCoursesByIdsMinimalDocument, {
      alias: 'getCoursesByIdsMinimal',
      ...operationOptions
    });
};

/**
 * __useGetCoursesByIdsMinimalQuery__
 *
 * To run a query within a React component, call `useGetCoursesByIdsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesByIdsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesByIdsMinimalQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCoursesByIdsMinimalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>(GetCoursesByIdsMinimalDocument, baseOptions);
      }
export function useGetCoursesByIdsMinimalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>(GetCoursesByIdsMinimalDocument, baseOptions);
        }
export type GetCoursesByIdsMinimalQueryHookResult = ReturnType<typeof useGetCoursesByIdsMinimalQuery>;
export type GetCoursesByIdsMinimalLazyQueryHookResult = ReturnType<typeof useGetCoursesByIdsMinimalLazyQuery>;
export type GetCoursesByIdsMinimalQueryResult = ApolloReactCommon.QueryResult<GetCoursesByIdsMinimalQuery, GetCoursesByIdsMinimalQueryVariables>;
export const UpdateMultipleCoursesShowInWebDocument = gql`
    mutation updateMultipleCoursesShowInWeb($courseIds: [ID!]!, $showInWeb: Boolean!) {
  updateMultipleCoursesShowInWeb(courseIds: $courseIds, showInWeb: $showInWeb) {
    id
    showInWeb
  }
}
    `;
export type UpdateMultipleCoursesShowInWebMutationFn = ApolloReactCommon.MutationFunction<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables>;
export type UpdateMultipleCoursesShowInWebComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables>, 'mutation'>;

    export const UpdateMultipleCoursesShowInWebComponent = (props: UpdateMultipleCoursesShowInWebComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables> mutation={UpdateMultipleCoursesShowInWebDocument} {...props} />
    );
    
export type UpdateMultipleCoursesShowInWebProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables> & TChildProps;
export function withUpdateMultipleCoursesShowInWeb<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMultipleCoursesShowInWebMutation,
  UpdateMultipleCoursesShowInWebMutationVariables,
  UpdateMultipleCoursesShowInWebProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables, UpdateMultipleCoursesShowInWebProps<TChildProps>>(UpdateMultipleCoursesShowInWebDocument, {
      alias: 'updateMultipleCoursesShowInWeb',
      ...operationOptions
    });
};

/**
 * __useUpdateMultipleCoursesShowInWebMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleCoursesShowInWebMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleCoursesShowInWebMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleCoursesShowInWebMutation, { data, loading, error }] = useUpdateMultipleCoursesShowInWebMutation({
 *   variables: {
 *      courseIds: // value for 'courseIds'
 *      showInWeb: // value for 'showInWeb'
 *   },
 * });
 */
export function useUpdateMultipleCoursesShowInWebMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables>(UpdateMultipleCoursesShowInWebDocument, baseOptions);
      }
export type UpdateMultipleCoursesShowInWebMutationHookResult = ReturnType<typeof useUpdateMultipleCoursesShowInWebMutation>;
export type UpdateMultipleCoursesShowInWebMutationResult = ApolloReactCommon.MutationResult<UpdateMultipleCoursesShowInWebMutation>;
export type UpdateMultipleCoursesShowInWebMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMultipleCoursesShowInWebMutation, UpdateMultipleCoursesShowInWebMutationVariables>;
export const GetInstallmentPlanDocument = gql`
    query GetInstallmentPlan($newInstallmentPlanInput: NewInstallmentPlanInput!) {
  getInstallmentPlanPreview(newInstallmentPlanInput: $newInstallmentPlanInput)
}
    `;
export type GetInstallmentPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>, 'query'> & ({ variables: GetInstallmentPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInstallmentPlanComponent = (props: GetInstallmentPlanComponentProps) => (
      <ApolloReactComponents.Query<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables> query={GetInstallmentPlanDocument} {...props} />
    );
    
export type GetInstallmentPlanProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables> & TChildProps;
export function withGetInstallmentPlan<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstallmentPlanQuery,
  GetInstallmentPlanQueryVariables,
  GetInstallmentPlanProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables, GetInstallmentPlanProps<TChildProps>>(GetInstallmentPlanDocument, {
      alias: 'getInstallmentPlan',
      ...operationOptions
    });
};

/**
 * __useGetInstallmentPlanQuery__
 *
 * To run a query within a React component, call `useGetInstallmentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallmentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallmentPlanQuery({
 *   variables: {
 *      newInstallmentPlanInput: // value for 'newInstallmentPlanInput'
 *   },
 * });
 */
export function useGetInstallmentPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>(GetInstallmentPlanDocument, baseOptions);
      }
export function useGetInstallmentPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>(GetInstallmentPlanDocument, baseOptions);
        }
export type GetInstallmentPlanQueryHookResult = ReturnType<typeof useGetInstallmentPlanQuery>;
export type GetInstallmentPlanLazyQueryHookResult = ReturnType<typeof useGetInstallmentPlanLazyQuery>;
export type GetInstallmentPlanQueryResult = ApolloReactCommon.QueryResult<GetInstallmentPlanQuery, GetInstallmentPlanQueryVariables>;
export const GetInstallmentRatesByCourseIdDocument = gql`
    query GetInstallmentRatesByCourseId($courseId: ID!) {
  installmentRatesByCourseId(courseId: $courseId) {
    id
    courseId
    installmentAmount
    installmentDue
  }
}
    `;
export type GetInstallmentRatesByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>, 'query'> & ({ variables: GetInstallmentRatesByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInstallmentRatesByCourseIdComponent = (props: GetInstallmentRatesByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables> query={GetInstallmentRatesByCourseIdDocument} {...props} />
    );
    
export type GetInstallmentRatesByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables> & TChildProps;
export function withGetInstallmentRatesByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstallmentRatesByCourseIdQuery,
  GetInstallmentRatesByCourseIdQueryVariables,
  GetInstallmentRatesByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables, GetInstallmentRatesByCourseIdProps<TChildProps>>(GetInstallmentRatesByCourseIdDocument, {
      alias: 'getInstallmentRatesByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetInstallmentRatesByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetInstallmentRatesByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallmentRatesByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallmentRatesByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetInstallmentRatesByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>(GetInstallmentRatesByCourseIdDocument, baseOptions);
      }
export function useGetInstallmentRatesByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>(GetInstallmentRatesByCourseIdDocument, baseOptions);
        }
export type GetInstallmentRatesByCourseIdQueryHookResult = ReturnType<typeof useGetInstallmentRatesByCourseIdQuery>;
export type GetInstallmentRatesByCourseIdLazyQueryHookResult = ReturnType<typeof useGetInstallmentRatesByCourseIdLazyQuery>;
export type GetInstallmentRatesByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetInstallmentRatesByCourseIdQuery, GetInstallmentRatesByCourseIdQueryVariables>;
export const GetAttendeeDocument = gql`
    query GetAttendee($id: String!) {
  attendee(id: $id) {
    id
    firstname
    lastname
    birthday
    gender
    booker {
      id
      firstname
      lastname
      gender
    }
  }
}
    `;
export type GetAttendeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeQuery, GetAttendeeQueryVariables>, 'query'> & ({ variables: GetAttendeeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeComponent = (props: GetAttendeeComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeQuery, GetAttendeeQueryVariables> query={GetAttendeeDocument} {...props} />
    );
    
export type GetAttendeeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeQuery, GetAttendeeQueryVariables> & TChildProps;
export function withGetAttendee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeQuery,
  GetAttendeeQueryVariables,
  GetAttendeeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeQuery, GetAttendeeQueryVariables, GetAttendeeProps<TChildProps>>(GetAttendeeDocument, {
      alias: 'getAttendee',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeQuery__
 *
 * To run a query within a React component, call `useGetAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeQuery, GetAttendeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeQuery, GetAttendeeQueryVariables>(GetAttendeeDocument, baseOptions);
      }
export function useGetAttendeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeQuery, GetAttendeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeQuery, GetAttendeeQueryVariables>(GetAttendeeDocument, baseOptions);
        }
export type GetAttendeeQueryHookResult = ReturnType<typeof useGetAttendeeQuery>;
export type GetAttendeeLazyQueryHookResult = ReturnType<typeof useGetAttendeeLazyQuery>;
export type GetAttendeeQueryResult = ApolloReactCommon.QueryResult<GetAttendeeQuery, GetAttendeeQueryVariables>;
export const GetAttendeesByIdsDocument = gql`
    query GetAttendeesByIds($attendeeIds: [ID!]!) {
  attendeesByIds(attendeeIds: $attendeeIds) {
    id
    firstname
    lastname
    booker {
      id
      firstname
      lastname
      email
    }
  }
}
    `;
export type GetAttendeesByIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>, 'query'> & ({ variables: GetAttendeesByIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeesByIdsComponent = (props: GetAttendeesByIdsComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables> query={GetAttendeesByIdsDocument} {...props} />
    );
    
export type GetAttendeesByIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables> & TChildProps;
export function withGetAttendeesByIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeesByIdsQuery,
  GetAttendeesByIdsQueryVariables,
  GetAttendeesByIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables, GetAttendeesByIdsProps<TChildProps>>(GetAttendeesByIdsDocument, {
      alias: 'getAttendeesByIds',
      ...operationOptions
    });
};

/**
 * __useGetAttendeesByIdsQuery__
 *
 * To run a query within a React component, call `useGetAttendeesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeesByIdsQuery({
 *   variables: {
 *      attendeeIds: // value for 'attendeeIds'
 *   },
 * });
 */
export function useGetAttendeesByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>(GetAttendeesByIdsDocument, baseOptions);
      }
export function useGetAttendeesByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>(GetAttendeesByIdsDocument, baseOptions);
        }
export type GetAttendeesByIdsQueryHookResult = ReturnType<typeof useGetAttendeesByIdsQuery>;
export type GetAttendeesByIdsLazyQueryHookResult = ReturnType<typeof useGetAttendeesByIdsLazyQuery>;
export type GetAttendeesByIdsQueryResult = ApolloReactCommon.QueryResult<GetAttendeesByIdsQuery, GetAttendeesByIdsQueryVariables>;
export const AttendeeOverviewGetAttendeesMinimalDocument = gql`
    query AttendeeOverviewGetAttendeesMinimal($options: ArrayDataOptions) {
  attendees(options: $options) {
    existsMore
    total
    items {
      id
      customerNumberPrefix
      customerNumber
      firstname
      lastname
      birthday
      gender
      deletable
      member
      addition
      annotation
      booker {
        id
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        street
        houseNumber
        postCode
        city
        phoneNumber
        mobileNumber
        email
        initialContact
        addition
        annotation
        credit
        nonDisclosureNotice
        paymentMethods {
          id
          cash
          esr
          creditCardAccountholder
          creditcardNumber
          expirationDate
          validFrom
          cvc
          paypalAccountholder
          email
          iban
          bank
          bic
          sepaAccountholder
        }
        credit
      }
      defaultPaymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
      }
      bookingsKo9 {
        id
        courseNumber
        courseName
        firstCourseLesson
      }
    }
  }
}
    `;
export type AttendeeOverviewGetAttendeesMinimalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>, 'query'>;

    export const AttendeeOverviewGetAttendeesMinimalComponent = (props: AttendeeOverviewGetAttendeesMinimalComponentProps) => (
      <ApolloReactComponents.Query<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables> query={AttendeeOverviewGetAttendeesMinimalDocument} {...props} />
    );
    
export type AttendeeOverviewGetAttendeesMinimalProps<TChildProps = {}> = ApolloReactHoc.DataProps<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables> & TChildProps;
export function withAttendeeOverviewGetAttendeesMinimal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AttendeeOverviewGetAttendeesMinimalQuery,
  AttendeeOverviewGetAttendeesMinimalQueryVariables,
  AttendeeOverviewGetAttendeesMinimalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables, AttendeeOverviewGetAttendeesMinimalProps<TChildProps>>(AttendeeOverviewGetAttendeesMinimalDocument, {
      alias: 'attendeeOverviewGetAttendeesMinimal',
      ...operationOptions
    });
};

/**
 * __useAttendeeOverviewGetAttendeesMinimalQuery__
 *
 * To run a query within a React component, call `useAttendeeOverviewGetAttendeesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeOverviewGetAttendeesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeOverviewGetAttendeesMinimalQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAttendeeOverviewGetAttendeesMinimalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>(AttendeeOverviewGetAttendeesMinimalDocument, baseOptions);
      }
export function useAttendeeOverviewGetAttendeesMinimalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>(AttendeeOverviewGetAttendeesMinimalDocument, baseOptions);
        }
export type AttendeeOverviewGetAttendeesMinimalQueryHookResult = ReturnType<typeof useAttendeeOverviewGetAttendeesMinimalQuery>;
export type AttendeeOverviewGetAttendeesMinimalLazyQueryHookResult = ReturnType<typeof useAttendeeOverviewGetAttendeesMinimalLazyQuery>;
export type AttendeeOverviewGetAttendeesMinimalQueryResult = ApolloReactCommon.QueryResult<AttendeeOverviewGetAttendeesMinimalQuery, AttendeeOverviewGetAttendeesMinimalQueryVariables>;
export const CheckIfIsBookedIntoCourseDocument = gql`
    query CheckIfIsBookedIntoCourse($courseId: ID!, $attendeeId: ID!) {
  checkIfIsBookedIntoCourse(courseId: $courseId, attendeeId: $attendeeId)
}
    `;
export type CheckIfIsBookedIntoCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>, 'query'> & ({ variables: CheckIfIsBookedIntoCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckIfIsBookedIntoCourseComponent = (props: CheckIfIsBookedIntoCourseComponentProps) => (
      <ApolloReactComponents.Query<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables> query={CheckIfIsBookedIntoCourseDocument} {...props} />
    );
    
export type CheckIfIsBookedIntoCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables> & TChildProps;
export function withCheckIfIsBookedIntoCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckIfIsBookedIntoCourseQuery,
  CheckIfIsBookedIntoCourseQueryVariables,
  CheckIfIsBookedIntoCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables, CheckIfIsBookedIntoCourseProps<TChildProps>>(CheckIfIsBookedIntoCourseDocument, {
      alias: 'checkIfIsBookedIntoCourse',
      ...operationOptions
    });
};

/**
 * __useCheckIfIsBookedIntoCourseQuery__
 *
 * To run a query within a React component, call `useCheckIfIsBookedIntoCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfIsBookedIntoCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfIsBookedIntoCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useCheckIfIsBookedIntoCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>(CheckIfIsBookedIntoCourseDocument, baseOptions);
      }
export function useCheckIfIsBookedIntoCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>(CheckIfIsBookedIntoCourseDocument, baseOptions);
        }
export type CheckIfIsBookedIntoCourseQueryHookResult = ReturnType<typeof useCheckIfIsBookedIntoCourseQuery>;
export type CheckIfIsBookedIntoCourseLazyQueryHookResult = ReturnType<typeof useCheckIfIsBookedIntoCourseLazyQuery>;
export type CheckIfIsBookedIntoCourseQueryResult = ApolloReactCommon.QueryResult<CheckIfIsBookedIntoCourseQuery, CheckIfIsBookedIntoCourseQueryVariables>;
export const GetBookedAttendeesByCourseIdDocument = gql`
    query GetBookedAttendeesByCourseId($courseId: ID!) {
  bookedAttendeesByCourseId(courseId: $courseId) {
    id
  }
}
    `;
export type GetBookedAttendeesByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>, 'query'> & ({ variables: GetBookedAttendeesByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookedAttendeesByCourseIdComponent = (props: GetBookedAttendeesByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables> query={GetBookedAttendeesByCourseIdDocument} {...props} />
    );
    
export type GetBookedAttendeesByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables> & TChildProps;
export function withGetBookedAttendeesByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookedAttendeesByCourseIdQuery,
  GetBookedAttendeesByCourseIdQueryVariables,
  GetBookedAttendeesByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables, GetBookedAttendeesByCourseIdProps<TChildProps>>(GetBookedAttendeesByCourseIdDocument, {
      alias: 'getBookedAttendeesByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetBookedAttendeesByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetBookedAttendeesByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookedAttendeesByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookedAttendeesByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetBookedAttendeesByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>(GetBookedAttendeesByCourseIdDocument, baseOptions);
      }
export function useGetBookedAttendeesByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>(GetBookedAttendeesByCourseIdDocument, baseOptions);
        }
export type GetBookedAttendeesByCourseIdQueryHookResult = ReturnType<typeof useGetBookedAttendeesByCourseIdQuery>;
export type GetBookedAttendeesByCourseIdLazyQueryHookResult = ReturnType<typeof useGetBookedAttendeesByCourseIdLazyQuery>;
export type GetBookedAttendeesByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetBookedAttendeesByCourseIdQuery, GetBookedAttendeesByCourseIdQueryVariables>;
export const GetAttendeeVariableForTemplateDocument = gql`
    query GetAttendeeVariableForTemplate($id: String!) {
  attendee(id: $id) {
    id
    firstname
    lastname
    birthday
    booker {
      id
      firstname
      lastname
    }
  }
}
    `;
export type GetAttendeeVariableForTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>, 'query'> & ({ variables: GetAttendeeVariableForTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeVariableForTemplateComponent = (props: GetAttendeeVariableForTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables> query={GetAttendeeVariableForTemplateDocument} {...props} />
    );
    
export type GetAttendeeVariableForTemplateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables> & TChildProps;
export function withGetAttendeeVariableForTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeVariableForTemplateQuery,
  GetAttendeeVariableForTemplateQueryVariables,
  GetAttendeeVariableForTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables, GetAttendeeVariableForTemplateProps<TChildProps>>(GetAttendeeVariableForTemplateDocument, {
      alias: 'getAttendeeVariableForTemplate',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeVariableForTemplateQuery__
 *
 * To run a query within a React component, call `useGetAttendeeVariableForTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeVariableForTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeVariableForTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeVariableForTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>(GetAttendeeVariableForTemplateDocument, baseOptions);
      }
export function useGetAttendeeVariableForTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>(GetAttendeeVariableForTemplateDocument, baseOptions);
        }
export type GetAttendeeVariableForTemplateQueryHookResult = ReturnType<typeof useGetAttendeeVariableForTemplateQuery>;
export type GetAttendeeVariableForTemplateLazyQueryHookResult = ReturnType<typeof useGetAttendeeVariableForTemplateLazyQuery>;
export type GetAttendeeVariableForTemplateQueryResult = ApolloReactCommon.QueryResult<GetAttendeeVariableForTemplateQuery, GetAttendeeVariableForTemplateQueryVariables>;
export const GetAttendeeDetailsDocument = gql`
    query GetAttendeeDetails($id: String!) {
  attendee(id: $id) {
    id
    firstname
    lastname
    birthday
    gender
    member
    defaultPaymentMethod {
      id
      type
      cash
      esr
      iban
      bank
      bic
      sepaAccountholder
    }
    booker {
      id
      firstname
      lastname
      gender
    }
    customerNumberPrefix
    customerNumber
  }
}
    `;
export type GetAttendeeDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>, 'query'> & ({ variables: GetAttendeeDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeDetailsComponent = (props: GetAttendeeDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables> query={GetAttendeeDetailsDocument} {...props} />
    );
    
export type GetAttendeeDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables> & TChildProps;
export function withGetAttendeeDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeDetailsQuery,
  GetAttendeeDetailsQueryVariables,
  GetAttendeeDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables, GetAttendeeDetailsProps<TChildProps>>(GetAttendeeDetailsDocument, {
      alias: 'getAttendeeDetails',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeDetailsQuery__
 *
 * To run a query within a React component, call `useGetAttendeeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>(GetAttendeeDetailsDocument, baseOptions);
      }
export function useGetAttendeeDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>(GetAttendeeDetailsDocument, baseOptions);
        }
export type GetAttendeeDetailsQueryHookResult = ReturnType<typeof useGetAttendeeDetailsQuery>;
export type GetAttendeeDetailsLazyQueryHookResult = ReturnType<typeof useGetAttendeeDetailsLazyQuery>;
export type GetAttendeeDetailsQueryResult = ApolloReactCommon.QueryResult<GetAttendeeDetailsQuery, GetAttendeeDetailsQueryVariables>;
export const GetAttendeeDetailsByIdDocument = gql`
    query GetAttendeeDetailsById($id: String!) {
  attendee(id: $id) {
    id
    firstname
    lastname
    birthday
    gender
    member
    defaultPaymentMethod {
      id
      type
      cash
      esr
      iban
      bank
      bic
      sepaAccountholder
    }
    booker {
      id
      firstname
      lastname
      gender
    }
    customerNumberPrefix
    customerNumber
  }
}
    `;
export type GetAttendeeDetailsByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>, 'query'> & ({ variables: GetAttendeeDetailsByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeDetailsByIdComponent = (props: GetAttendeeDetailsByIdComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables> query={GetAttendeeDetailsByIdDocument} {...props} />
    );
    
export type GetAttendeeDetailsByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables> & TChildProps;
export function withGetAttendeeDetailsById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeDetailsByIdQuery,
  GetAttendeeDetailsByIdQueryVariables,
  GetAttendeeDetailsByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables, GetAttendeeDetailsByIdProps<TChildProps>>(GetAttendeeDetailsByIdDocument, {
      alias: 'getAttendeeDetailsById',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetAttendeeDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeDetailsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeDetailsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>(GetAttendeeDetailsByIdDocument, baseOptions);
      }
export function useGetAttendeeDetailsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>(GetAttendeeDetailsByIdDocument, baseOptions);
        }
export type GetAttendeeDetailsByIdQueryHookResult = ReturnType<typeof useGetAttendeeDetailsByIdQuery>;
export type GetAttendeeDetailsByIdLazyQueryHookResult = ReturnType<typeof useGetAttendeeDetailsByIdLazyQuery>;
export type GetAttendeeDetailsByIdQueryResult = ApolloReactCommon.QueryResult<GetAttendeeDetailsByIdQuery, GetAttendeeDetailsByIdQueryVariables>;
export const TransferAttendeeToBookerDocument = gql`
    mutation TransferAttendeeToBooker($attendeeId: ID!, $newBookerId: ID!) {
  transferAttendeeToBooker(attendeeId: $attendeeId, newBookerId: $newBookerId) {
    id
    firstname
    lastname
    birthday
    customerNumberPrefix
    customerNumber
  }
}
    `;
export type TransferAttendeeToBookerMutationFn = ApolloReactCommon.MutationFunction<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables>;
export type TransferAttendeeToBookerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables>, 'mutation'>;

    export const TransferAttendeeToBookerComponent = (props: TransferAttendeeToBookerComponentProps) => (
      <ApolloReactComponents.Mutation<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables> mutation={TransferAttendeeToBookerDocument} {...props} />
    );
    
export type TransferAttendeeToBookerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables> & TChildProps;
export function withTransferAttendeeToBooker<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransferAttendeeToBookerMutation,
  TransferAttendeeToBookerMutationVariables,
  TransferAttendeeToBookerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables, TransferAttendeeToBookerProps<TChildProps>>(TransferAttendeeToBookerDocument, {
      alias: 'transferAttendeeToBooker',
      ...operationOptions
    });
};

/**
 * __useTransferAttendeeToBookerMutation__
 *
 * To run a mutation, you first call `useTransferAttendeeToBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferAttendeeToBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferAttendeeToBookerMutation, { data, loading, error }] = useTransferAttendeeToBookerMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      newBookerId: // value for 'newBookerId'
 *   },
 * });
 */
export function useTransferAttendeeToBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables>(TransferAttendeeToBookerDocument, baseOptions);
      }
export type TransferAttendeeToBookerMutationHookResult = ReturnType<typeof useTransferAttendeeToBookerMutation>;
export type TransferAttendeeToBookerMutationResult = ApolloReactCommon.MutationResult<TransferAttendeeToBookerMutation>;
export type TransferAttendeeToBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferAttendeeToBookerMutation, TransferAttendeeToBookerMutationVariables>;
export const GetAttendeeMemberStatusDocument = gql`
    query GetAttendeeMemberStatus($id: String!) {
  attendee(id: $id) {
    id
    member
  }
}
    `;
export type GetAttendeeMemberStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>, 'query'> & ({ variables: GetAttendeeMemberStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttendeeMemberStatusComponent = (props: GetAttendeeMemberStatusComponentProps) => (
      <ApolloReactComponents.Query<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables> query={GetAttendeeMemberStatusDocument} {...props} />
    );
    
export type GetAttendeeMemberStatusProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables> & TChildProps;
export function withGetAttendeeMemberStatus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendeeMemberStatusQuery,
  GetAttendeeMemberStatusQueryVariables,
  GetAttendeeMemberStatusProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables, GetAttendeeMemberStatusProps<TChildProps>>(GetAttendeeMemberStatusDocument, {
      alias: 'getAttendeeMemberStatus',
      ...operationOptions
    });
};

/**
 * __useGetAttendeeMemberStatusQuery__
 *
 * To run a query within a React component, call `useGetAttendeeMemberStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeMemberStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeMemberStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendeeMemberStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>(GetAttendeeMemberStatusDocument, baseOptions);
      }
export function useGetAttendeeMemberStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>(GetAttendeeMemberStatusDocument, baseOptions);
        }
export type GetAttendeeMemberStatusQueryHookResult = ReturnType<typeof useGetAttendeeMemberStatusQuery>;
export type GetAttendeeMemberStatusLazyQueryHookResult = ReturnType<typeof useGetAttendeeMemberStatusLazyQuery>;
export type GetAttendeeMemberStatusQueryResult = ApolloReactCommon.QueryResult<GetAttendeeMemberStatusQuery, GetAttendeeMemberStatusQueryVariables>;
export const ChangeAttendeeMemberStatusDocument = gql`
    mutation ChangeAttendeeMemberStatus($id: ID!, $member: Boolean!) {
  changeAttendeeMemberStatus(id: $id, member: $member) {
    id
    member
  }
}
    `;
export type ChangeAttendeeMemberStatusMutationFn = ApolloReactCommon.MutationFunction<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables>;
export type ChangeAttendeeMemberStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables>, 'mutation'>;

    export const ChangeAttendeeMemberStatusComponent = (props: ChangeAttendeeMemberStatusComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables> mutation={ChangeAttendeeMemberStatusDocument} {...props} />
    );
    
export type ChangeAttendeeMemberStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables> & TChildProps;
export function withChangeAttendeeMemberStatus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeAttendeeMemberStatusMutation,
  ChangeAttendeeMemberStatusMutationVariables,
  ChangeAttendeeMemberStatusProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables, ChangeAttendeeMemberStatusProps<TChildProps>>(ChangeAttendeeMemberStatusDocument, {
      alias: 'changeAttendeeMemberStatus',
      ...operationOptions
    });
};

/**
 * __useChangeAttendeeMemberStatusMutation__
 *
 * To run a mutation, you first call `useChangeAttendeeMemberStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAttendeeMemberStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAttendeeMemberStatusMutation, { data, loading, error }] = useChangeAttendeeMemberStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      member: // value for 'member'
 *   },
 * });
 */
export function useChangeAttendeeMemberStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables>(ChangeAttendeeMemberStatusDocument, baseOptions);
      }
export type ChangeAttendeeMemberStatusMutationHookResult = ReturnType<typeof useChangeAttendeeMemberStatusMutation>;
export type ChangeAttendeeMemberStatusMutationResult = ApolloReactCommon.MutationResult<ChangeAttendeeMemberStatusMutation>;
export type ChangeAttendeeMemberStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAttendeeMemberStatusMutation, ChangeAttendeeMemberStatusMutationVariables>;
export const ChangeDefaultPaymentMethodDocument = gql`
    mutation ChangeDefaultPaymentMethod($attendeeId: ID!, $paymentMethodId: ID!) {
  changeDefaultPaymentMethod(attendeeId: $attendeeId, paymentMethodId: $paymentMethodId) {
    id
    defaultPaymentMethod {
      id
      cash
      esr
      creditcardNumber
      creditCardAccountholder
      expirationDate
      sepaAccountholder
      iban
      bic
      bank
      paypalAccountholder
      email
      bankTransfer
    }
  }
}
    `;
export type ChangeDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables>;
export type ChangeDefaultPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables>, 'mutation'>;

    export const ChangeDefaultPaymentMethodComponent = (props: ChangeDefaultPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables> mutation={ChangeDefaultPaymentMethodDocument} {...props} />
    );
    
export type ChangeDefaultPaymentMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables> & TChildProps;
export function withChangeDefaultPaymentMethod<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeDefaultPaymentMethodMutation,
  ChangeDefaultPaymentMethodMutationVariables,
  ChangeDefaultPaymentMethodProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables, ChangeDefaultPaymentMethodProps<TChildProps>>(ChangeDefaultPaymentMethodDocument, {
      alias: 'changeDefaultPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useChangeDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useChangeDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDefaultPaymentMethodMutation, { data, loading, error }] = useChangeDefaultPaymentMethodMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useChangeDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables>(ChangeDefaultPaymentMethodDocument, baseOptions);
      }
export type ChangeDefaultPaymentMethodMutationHookResult = ReturnType<typeof useChangeDefaultPaymentMethodMutation>;
export type ChangeDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<ChangeDefaultPaymentMethodMutation>;
export type ChangeDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeDefaultPaymentMethodMutation, ChangeDefaultPaymentMethodMutationVariables>;
export const CreateBookerDocument = gql`
    mutation CreateBooker($newBookerData: BookerInput!) {
  createBooker(newBookerData: $newBookerData) {
    id
    gender
    firstname
    lastname
    birthday
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
    }
    attendees {
      id
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
      addition
      annotation
    }
  }
}
    `;
export type CreateBookerMutationFn = ApolloReactCommon.MutationFunction<CreateBookerMutation, CreateBookerMutationVariables>;
export type CreateBookerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBookerMutation, CreateBookerMutationVariables>, 'mutation'>;

    export const CreateBookerComponent = (props: CreateBookerComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBookerMutation, CreateBookerMutationVariables> mutation={CreateBookerDocument} {...props} />
    );
    
export type CreateBookerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateBookerMutation, CreateBookerMutationVariables> & TChildProps;
export function withCreateBooker<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBookerMutation,
  CreateBookerMutationVariables,
  CreateBookerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBookerMutation, CreateBookerMutationVariables, CreateBookerProps<TChildProps>>(CreateBookerDocument, {
      alias: 'createBooker',
      ...operationOptions
    });
};

/**
 * __useCreateBookerMutation__
 *
 * To run a mutation, you first call `useCreateBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookerMutation, { data, loading, error }] = useCreateBookerMutation({
 *   variables: {
 *      newBookerData: // value for 'newBookerData'
 *   },
 * });
 */
export function useCreateBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookerMutation, CreateBookerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBookerMutation, CreateBookerMutationVariables>(CreateBookerDocument, baseOptions);
      }
export type CreateBookerMutationHookResult = ReturnType<typeof useCreateBookerMutation>;
export type CreateBookerMutationResult = ApolloReactCommon.MutationResult<CreateBookerMutation>;
export type CreateBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookerMutation, CreateBookerMutationVariables>;
export const GetBookerWithAttendeeBookingsDocument = gql`
    query GetBookerWithAttendeeBookings($id: String!) {
  booker(id: $id) {
    id
    customerId
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    initialContact
    dsgvo
    newsletter
    credit
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      bankTransfer
    }
    attendees {
      id
      customerId
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
          courseId
          course {
            id
            maxAttendees
          }
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
      addition
      annotation
      deletable
    }
    customerNumber
    userId
  }
}
    `;
export type GetBookerWithAttendeeBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>, 'query'> & ({ variables: GetBookerWithAttendeeBookingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookerWithAttendeeBookingsComponent = (props: GetBookerWithAttendeeBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables> query={GetBookerWithAttendeeBookingsDocument} {...props} />
    );
    
export type GetBookerWithAttendeeBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables> & TChildProps;
export function withGetBookerWithAttendeeBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookerWithAttendeeBookingsQuery,
  GetBookerWithAttendeeBookingsQueryVariables,
  GetBookerWithAttendeeBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables, GetBookerWithAttendeeBookingsProps<TChildProps>>(GetBookerWithAttendeeBookingsDocument, {
      alias: 'getBookerWithAttendeeBookings',
      ...operationOptions
    });
};

/**
 * __useGetBookerWithAttendeeBookingsQuery__
 *
 * To run a query within a React component, call `useGetBookerWithAttendeeBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookerWithAttendeeBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookerWithAttendeeBookingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookerWithAttendeeBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>(GetBookerWithAttendeeBookingsDocument, baseOptions);
      }
export function useGetBookerWithAttendeeBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>(GetBookerWithAttendeeBookingsDocument, baseOptions);
        }
export type GetBookerWithAttendeeBookingsQueryHookResult = ReturnType<typeof useGetBookerWithAttendeeBookingsQuery>;
export type GetBookerWithAttendeeBookingsLazyQueryHookResult = ReturnType<typeof useGetBookerWithAttendeeBookingsLazyQuery>;
export type GetBookerWithAttendeeBookingsQueryResult = ApolloReactCommon.QueryResult<GetBookerWithAttendeeBookingsQuery, GetBookerWithAttendeeBookingsQueryVariables>;
export const GetBookerForDuplicateBookerOverviewDocument = gql`
    query GetBookerForDuplicateBookerOverview($id: String!) {
  booker(id: $id) {
    id
    customerId
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    initialContact
    dsgvo
    newsletter
    credit
    healthInsuranceFund
    annotation
    attendees {
      id
      customerId
      customerNumberPrefix
      customerNumber
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        bookingNumberPrefix
        bookingType
        bookingNumber
        invoice {
          id
        }
        canceled
        courseLessons {
          id
          courseId
          course {
            id
            courseNumber
            courseType {
              id
              color
              name
            }
          }
        }
      }
      annotation
    }
    customerNumber
    customerNumberPrefix
    userId
  }
}
    `;
export type GetBookerForDuplicateBookerOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>, 'query'> & ({ variables: GetBookerForDuplicateBookerOverviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookerForDuplicateBookerOverviewComponent = (props: GetBookerForDuplicateBookerOverviewComponentProps) => (
      <ApolloReactComponents.Query<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables> query={GetBookerForDuplicateBookerOverviewDocument} {...props} />
    );
    
export type GetBookerForDuplicateBookerOverviewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables> & TChildProps;
export function withGetBookerForDuplicateBookerOverview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookerForDuplicateBookerOverviewQuery,
  GetBookerForDuplicateBookerOverviewQueryVariables,
  GetBookerForDuplicateBookerOverviewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables, GetBookerForDuplicateBookerOverviewProps<TChildProps>>(GetBookerForDuplicateBookerOverviewDocument, {
      alias: 'getBookerForDuplicateBookerOverview',
      ...operationOptions
    });
};

/**
 * __useGetBookerForDuplicateBookerOverviewQuery__
 *
 * To run a query within a React component, call `useGetBookerForDuplicateBookerOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookerForDuplicateBookerOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookerForDuplicateBookerOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookerForDuplicateBookerOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>(GetBookerForDuplicateBookerOverviewDocument, baseOptions);
      }
export function useGetBookerForDuplicateBookerOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>(GetBookerForDuplicateBookerOverviewDocument, baseOptions);
        }
export type GetBookerForDuplicateBookerOverviewQueryHookResult = ReturnType<typeof useGetBookerForDuplicateBookerOverviewQuery>;
export type GetBookerForDuplicateBookerOverviewLazyQueryHookResult = ReturnType<typeof useGetBookerForDuplicateBookerOverviewLazyQuery>;
export type GetBookerForDuplicateBookerOverviewQueryResult = ApolloReactCommon.QueryResult<GetBookerForDuplicateBookerOverviewQuery, GetBookerForDuplicateBookerOverviewQueryVariables>;
export const UpdateBookerDocument = gql`
    mutation UpdateBooker($updateBookerData: BookerInput!) {
  updateBooker(updateBookerData: $updateBookerData) {
    id
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
    }
    attendees {
      id
      gender
      lastname
      firstname
      birthday
      member
      deletable
      startedCourses {
        id
        prefixedCourseNumber
      }
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
      addition
      annotation
    }
  }
}
    `;
export type UpdateBookerMutationFn = ApolloReactCommon.MutationFunction<UpdateBookerMutation, UpdateBookerMutationVariables>;
export type UpdateBookerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookerMutation, UpdateBookerMutationVariables>, 'mutation'>;

    export const UpdateBookerComponent = (props: UpdateBookerComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookerMutation, UpdateBookerMutationVariables> mutation={UpdateBookerDocument} {...props} />
    );
    
export type UpdateBookerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookerMutation, UpdateBookerMutationVariables> & TChildProps;
export function withUpdateBooker<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookerMutation,
  UpdateBookerMutationVariables,
  UpdateBookerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookerMutation, UpdateBookerMutationVariables, UpdateBookerProps<TChildProps>>(UpdateBookerDocument, {
      alias: 'updateBooker',
      ...operationOptions
    });
};

/**
 * __useUpdateBookerMutation__
 *
 * To run a mutation, you first call `useUpdateBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookerMutation, { data, loading, error }] = useUpdateBookerMutation({
 *   variables: {
 *      updateBookerData: // value for 'updateBookerData'
 *   },
 * });
 */
export function useUpdateBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookerMutation, UpdateBookerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookerMutation, UpdateBookerMutationVariables>(UpdateBookerDocument, baseOptions);
      }
export type UpdateBookerMutationHookResult = ReturnType<typeof useUpdateBookerMutation>;
export type UpdateBookerMutationResult = ApolloReactCommon.MutationResult<UpdateBookerMutation>;
export type UpdateBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookerMutation, UpdateBookerMutationVariables>;
export const AttendeeTableGetCourseLessonsDocument = gql`
    query AttendeeTableGetCourseLessons($ids: [String!]) {
  courseLessons(ids: $ids) {
    id
    course {
      id
      prefixedCourseNumber
      courseType {
        id
        name
        color
      }
    }
    location {
      id
      name
      city
    }
    startDateTime
  }
}
    `;
export type AttendeeTableGetCourseLessonsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>, 'query'>;

    export const AttendeeTableGetCourseLessonsComponent = (props: AttendeeTableGetCourseLessonsComponentProps) => (
      <ApolloReactComponents.Query<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables> query={AttendeeTableGetCourseLessonsDocument} {...props} />
    );
    
export type AttendeeTableGetCourseLessonsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables> & TChildProps;
export function withAttendeeTableGetCourseLessons<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AttendeeTableGetCourseLessonsQuery,
  AttendeeTableGetCourseLessonsQueryVariables,
  AttendeeTableGetCourseLessonsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables, AttendeeTableGetCourseLessonsProps<TChildProps>>(AttendeeTableGetCourseLessonsDocument, {
      alias: 'attendeeTableGetCourseLessons',
      ...operationOptions
    });
};

/**
 * __useAttendeeTableGetCourseLessonsQuery__
 *
 * To run a query within a React component, call `useAttendeeTableGetCourseLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeTableGetCourseLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeTableGetCourseLessonsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAttendeeTableGetCourseLessonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>(AttendeeTableGetCourseLessonsDocument, baseOptions);
      }
export function useAttendeeTableGetCourseLessonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>(AttendeeTableGetCourseLessonsDocument, baseOptions);
        }
export type AttendeeTableGetCourseLessonsQueryHookResult = ReturnType<typeof useAttendeeTableGetCourseLessonsQuery>;
export type AttendeeTableGetCourseLessonsLazyQueryHookResult = ReturnType<typeof useAttendeeTableGetCourseLessonsLazyQuery>;
export type AttendeeTableGetCourseLessonsQueryResult = ApolloReactCommon.QueryResult<AttendeeTableGetCourseLessonsQuery, AttendeeTableGetCourseLessonsQueryVariables>;
export const GetBookingsByAttendeeDocument = gql`
    query GetBookingsByAttendee($attendeeId: ID!) {
  getBookingsByAttendeeId(id: $attendeeId) {
    id
    bookingNumber
    bookingNumberPrefix
    bookingType
    canceled
    noticeStaff
    startDateTime
    courseLessons {
      id
      course {
        id
        prefixedCourseNumber
        courseNumber
        courseType {
          id
          name
          color
        }
      }
    }
  }
}
    `;
export type GetBookingsByAttendeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>, 'query'> & ({ variables: GetBookingsByAttendeeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookingsByAttendeeComponent = (props: GetBookingsByAttendeeComponentProps) => (
      <ApolloReactComponents.Query<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables> query={GetBookingsByAttendeeDocument} {...props} />
    );
    
export type GetBookingsByAttendeeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables> & TChildProps;
export function withGetBookingsByAttendee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookingsByAttendeeQuery,
  GetBookingsByAttendeeQueryVariables,
  GetBookingsByAttendeeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables, GetBookingsByAttendeeProps<TChildProps>>(GetBookingsByAttendeeDocument, {
      alias: 'getBookingsByAttendee',
      ...operationOptions
    });
};

/**
 * __useGetBookingsByAttendeeQuery__
 *
 * To run a query within a React component, call `useGetBookingsByAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByAttendeeQuery({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useGetBookingsByAttendeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>(GetBookingsByAttendeeDocument, baseOptions);
      }
export function useGetBookingsByAttendeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>(GetBookingsByAttendeeDocument, baseOptions);
        }
export type GetBookingsByAttendeeQueryHookResult = ReturnType<typeof useGetBookingsByAttendeeQuery>;
export type GetBookingsByAttendeeLazyQueryHookResult = ReturnType<typeof useGetBookingsByAttendeeLazyQuery>;
export type GetBookingsByAttendeeQueryResult = ApolloReactCommon.QueryResult<GetBookingsByAttendeeQuery, GetBookingsByAttendeeQueryVariables>;
export const DeleteAttendeeDocument = gql`
    mutation DeleteAttendee($id: String!) {
  deleteAttendee(id: $id)
}
    `;
export type DeleteAttendeeMutationFn = ApolloReactCommon.MutationFunction<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>;
export type DeleteAttendeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>, 'mutation'>;

    export const DeleteAttendeeComponent = (props: DeleteAttendeeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAttendeeMutation, DeleteAttendeeMutationVariables> mutation={DeleteAttendeeDocument} {...props} />
    );
    
export type DeleteAttendeeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteAttendeeMutation, DeleteAttendeeMutationVariables> & TChildProps;
export function withDeleteAttendee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAttendeeMutation,
  DeleteAttendeeMutationVariables,
  DeleteAttendeeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAttendeeMutation, DeleteAttendeeMutationVariables, DeleteAttendeeProps<TChildProps>>(DeleteAttendeeDocument, {
      alias: 'deleteAttendee',
      ...operationOptions
    });
};

/**
 * __useDeleteAttendeeMutation__
 *
 * To run a mutation, you first call `useDeleteAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttendeeMutation, { data, loading, error }] = useDeleteAttendeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttendeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>(DeleteAttendeeDocument, baseOptions);
      }
export type DeleteAttendeeMutationHookResult = ReturnType<typeof useDeleteAttendeeMutation>;
export type DeleteAttendeeMutationResult = ApolloReactCommon.MutationResult<DeleteAttendeeMutation>;
export type DeleteAttendeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>;
export const GetCourseLessonStartDatesDocument = gql`
    query GetCourseLessonStartDates($ids: [String!]) {
  courseLessons(ids: $ids) {
    id
    startDateTime
    course {
      id
      prefixedCourseNumber
    }
  }
}
    `;
export type GetCourseLessonStartDatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>, 'query'>;

    export const GetCourseLessonStartDatesComponent = (props: GetCourseLessonStartDatesComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables> query={GetCourseLessonStartDatesDocument} {...props} />
    );
    
export type GetCourseLessonStartDatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables> & TChildProps;
export function withGetCourseLessonStartDates<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonStartDatesQuery,
  GetCourseLessonStartDatesQueryVariables,
  GetCourseLessonStartDatesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables, GetCourseLessonStartDatesProps<TChildProps>>(GetCourseLessonStartDatesDocument, {
      alias: 'getCourseLessonStartDates',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonStartDatesQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonStartDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonStartDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonStartDatesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCourseLessonStartDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>(GetCourseLessonStartDatesDocument, baseOptions);
      }
export function useGetCourseLessonStartDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>(GetCourseLessonStartDatesDocument, baseOptions);
        }
export type GetCourseLessonStartDatesQueryHookResult = ReturnType<typeof useGetCourseLessonStartDatesQuery>;
export type GetCourseLessonStartDatesLazyQueryHookResult = ReturnType<typeof useGetCourseLessonStartDatesLazyQuery>;
export type GetCourseLessonStartDatesQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>;
export const GetBookersOverviewDocument = gql`
    query GetBookersOverview($options: ArrayDataOptions) {
  bookers(options: $options) {
    existsMore
    total
    items {
      id
      customerNumberPrefix
      customerNumber
      firstname
      lastname
      attendees {
        firstname
        lastname
        id
        deletable
        addition
        annotation
        member
      }
      street
      houseNumber
      postCode
      city
      paymentMethods {
        id
        bankTransfer
        cash
        esr
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
      }
      dsgvo
      newsletter
      email
      mobileNumber
      phoneNumber
      birthday
      gender
      initialContact
      healthInsuranceFund
      addition
      annotation
      credit
      nonDisclosureNotice
      credit
      userId
    }
  }
}
    `;
export type GetBookersOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>, 'query'>;

    export const GetBookersOverviewComponent = (props: GetBookersOverviewComponentProps) => (
      <ApolloReactComponents.Query<GetBookersOverviewQuery, GetBookersOverviewQueryVariables> query={GetBookersOverviewDocument} {...props} />
    );
    
export type GetBookersOverviewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookersOverviewQuery, GetBookersOverviewQueryVariables> & TChildProps;
export function withGetBookersOverview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookersOverviewQuery,
  GetBookersOverviewQueryVariables,
  GetBookersOverviewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookersOverviewQuery, GetBookersOverviewQueryVariables, GetBookersOverviewProps<TChildProps>>(GetBookersOverviewDocument, {
      alias: 'getBookersOverview',
      ...operationOptions
    });
};

/**
 * __useGetBookersOverviewQuery__
 *
 * To run a query within a React component, call `useGetBookersOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookersOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookersOverviewQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBookersOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>(GetBookersOverviewDocument, baseOptions);
      }
export function useGetBookersOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>(GetBookersOverviewDocument, baseOptions);
        }
export type GetBookersOverviewQueryHookResult = ReturnType<typeof useGetBookersOverviewQuery>;
export type GetBookersOverviewLazyQueryHookResult = ReturnType<typeof useGetBookersOverviewLazyQuery>;
export type GetBookersOverviewQueryResult = ApolloReactCommon.QueryResult<GetBookersOverviewQuery, GetBookersOverviewQueryVariables>;
export const GetDynamicBookersDocument = gql`
    query GetDynamicBookers($options: ArrayDataOptions, $includePayment: Boolean!, $includeAttendees: Boolean!) {
  bookersDynamic(options: $options) {
    __typename
    existsMore
    total
    items {
      ...BookerCoreFields
      ...BookerPaymentMethodsFields @include(if: $includePayment)
      ...BookerAttendeesFields @include(if: $includeAttendees)
    }
  }
}
    ${BookerCoreFieldsFragmentDoc}
${BookerPaymentMethodsFieldsFragmentDoc}
${BookerAttendeesFieldsFragmentDoc}`;
export type GetDynamicBookersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>, 'query'> & ({ variables: GetDynamicBookersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDynamicBookersComponent = (props: GetDynamicBookersComponentProps) => (
      <ApolloReactComponents.Query<GetDynamicBookersQuery, GetDynamicBookersQueryVariables> query={GetDynamicBookersDocument} {...props} />
    );
    
export type GetDynamicBookersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDynamicBookersQuery, GetDynamicBookersQueryVariables> & TChildProps;
export function withGetDynamicBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDynamicBookersQuery,
  GetDynamicBookersQueryVariables,
  GetDynamicBookersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDynamicBookersQuery, GetDynamicBookersQueryVariables, GetDynamicBookersProps<TChildProps>>(GetDynamicBookersDocument, {
      alias: 'getDynamicBookers',
      ...operationOptions
    });
};

/**
 * __useGetDynamicBookersQuery__
 *
 * To run a query within a React component, call `useGetDynamicBookersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicBookersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicBookersQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includePayment: // value for 'includePayment'
 *      includeAttendees: // value for 'includeAttendees'
 *   },
 * });
 */
export function useGetDynamicBookersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>(GetDynamicBookersDocument, baseOptions);
      }
export function useGetDynamicBookersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>(GetDynamicBookersDocument, baseOptions);
        }
export type GetDynamicBookersQueryHookResult = ReturnType<typeof useGetDynamicBookersQuery>;
export type GetDynamicBookersLazyQueryHookResult = ReturnType<typeof useGetDynamicBookersLazyQuery>;
export type GetDynamicBookersQueryResult = ApolloReactCommon.QueryResult<GetDynamicBookersQuery, GetDynamicBookersQueryVariables>;
export const GetBookerByEmailDocument = gql`
    query GetBookerByEmail($email: String!) {
  getBookerByEmail(email: $email) {
    id
    firstname
    lastname
    email
  }
}
    `;
export type GetBookerByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>, 'query'> & ({ variables: GetBookerByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookerByEmailComponent = (props: GetBookerByEmailComponentProps) => (
      <ApolloReactComponents.Query<GetBookerByEmailQuery, GetBookerByEmailQueryVariables> query={GetBookerByEmailDocument} {...props} />
    );
    
export type GetBookerByEmailProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookerByEmailQuery, GetBookerByEmailQueryVariables> & TChildProps;
export function withGetBookerByEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookerByEmailQuery,
  GetBookerByEmailQueryVariables,
  GetBookerByEmailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookerByEmailQuery, GetBookerByEmailQueryVariables, GetBookerByEmailProps<TChildProps>>(GetBookerByEmailDocument, {
      alias: 'getBookerByEmail',
      ...operationOptions
    });
};

/**
 * __useGetBookerByEmailQuery__
 *
 * To run a query within a React component, call `useGetBookerByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookerByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookerByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetBookerByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>(GetBookerByEmailDocument, baseOptions);
      }
export function useGetBookerByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>(GetBookerByEmailDocument, baseOptions);
        }
export type GetBookerByEmailQueryHookResult = ReturnType<typeof useGetBookerByEmailQuery>;
export type GetBookerByEmailLazyQueryHookResult = ReturnType<typeof useGetBookerByEmailLazyQuery>;
export type GetBookerByEmailQueryResult = ApolloReactCommon.QueryResult<GetBookerByEmailQuery, GetBookerByEmailQueryVariables>;
export const GetBookersByIdDocument = gql`
    query GetBookersById($bookerIds: [String!]!) {
  bookersById(bookerIds: $bookerIds) {
    id
    firstname
    lastname
    email
  }
}
    `;
export type GetBookersByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookersByIdQuery, GetBookersByIdQueryVariables>, 'query'> & ({ variables: GetBookersByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookersByIdComponent = (props: GetBookersByIdComponentProps) => (
      <ApolloReactComponents.Query<GetBookersByIdQuery, GetBookersByIdQueryVariables> query={GetBookersByIdDocument} {...props} />
    );
    
export type GetBookersByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookersByIdQuery, GetBookersByIdQueryVariables> & TChildProps;
export function withGetBookersById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookersByIdQuery,
  GetBookersByIdQueryVariables,
  GetBookersByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookersByIdQuery, GetBookersByIdQueryVariables, GetBookersByIdProps<TChildProps>>(GetBookersByIdDocument, {
      alias: 'getBookersById',
      ...operationOptions
    });
};

/**
 * __useGetBookersByIdQuery__
 *
 * To run a query within a React component, call `useGetBookersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookersByIdQuery({
 *   variables: {
 *      bookerIds: // value for 'bookerIds'
 *   },
 * });
 */
export function useGetBookersByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookersByIdQuery, GetBookersByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookersByIdQuery, GetBookersByIdQueryVariables>(GetBookersByIdDocument, baseOptions);
      }
export function useGetBookersByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookersByIdQuery, GetBookersByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookersByIdQuery, GetBookersByIdQueryVariables>(GetBookersByIdDocument, baseOptions);
        }
export type GetBookersByIdQueryHookResult = ReturnType<typeof useGetBookersByIdQuery>;
export type GetBookersByIdLazyQueryHookResult = ReturnType<typeof useGetBookersByIdLazyQuery>;
export type GetBookersByIdQueryResult = ApolloReactCommon.QueryResult<GetBookersByIdQuery, GetBookersByIdQueryVariables>;
export const SendBlankMailToMultipleBookersDocument = gql`
    mutation sendBlankMailToMultipleBookers($input: BlankMailInputTypes!, $file: Upload) {
  sendBlankMailToMultipleBookers(input: $input, file: $file)
}
    `;
export type SendBlankMailToMultipleBookersMutationFn = ApolloReactCommon.MutationFunction<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables>;
export type SendBlankMailToMultipleBookersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables>, 'mutation'>;

    export const SendBlankMailToMultipleBookersComponent = (props: SendBlankMailToMultipleBookersComponentProps) => (
      <ApolloReactComponents.Mutation<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables> mutation={SendBlankMailToMultipleBookersDocument} {...props} />
    );
    
export type SendBlankMailToMultipleBookersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables> & TChildProps;
export function withSendBlankMailToMultipleBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendBlankMailToMultipleBookersMutation,
  SendBlankMailToMultipleBookersMutationVariables,
  SendBlankMailToMultipleBookersProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables, SendBlankMailToMultipleBookersProps<TChildProps>>(SendBlankMailToMultipleBookersDocument, {
      alias: 'sendBlankMailToMultipleBookers',
      ...operationOptions
    });
};

/**
 * __useSendBlankMailToMultipleBookersMutation__
 *
 * To run a mutation, you first call `useSendBlankMailToMultipleBookersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBlankMailToMultipleBookersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBlankMailToMultipleBookersMutation, { data, loading, error }] = useSendBlankMailToMultipleBookersMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSendBlankMailToMultipleBookersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables>(SendBlankMailToMultipleBookersDocument, baseOptions);
      }
export type SendBlankMailToMultipleBookersMutationHookResult = ReturnType<typeof useSendBlankMailToMultipleBookersMutation>;
export type SendBlankMailToMultipleBookersMutationResult = ApolloReactCommon.MutationResult<SendBlankMailToMultipleBookersMutation>;
export type SendBlankMailToMultipleBookersMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBlankMailToMultipleBookersMutation, SendBlankMailToMultipleBookersMutationVariables>;
export const GetBookersByCourseIdsDocument = gql`
    query getBookersByCourseIds($courseIds: [String!]!) {
  getBookersByCourseIds(courseIds: $courseIds) {
    id
    customerNumberPrefix
    customerNumber
    firstname
    lastname
    attendees {
      firstname
      lastname
      id
      deletable
      startedCourses {
        id
        prefixedCourseNumber
      }
      addition
      annotation
      member
    }
    street
    houseNumber
    postCode
    city
    paymentMethods {
      id
      bankTransfer
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
    }
    dsgvo
    newsletter
    email
    mobileNumber
    phoneNumber
    birthday
    gender
    initialContact
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
  }
}
    `;
export type GetBookersByCourseIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>, 'query'> & ({ variables: GetBookersByCourseIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookersByCourseIdsComponent = (props: GetBookersByCourseIdsComponentProps) => (
      <ApolloReactComponents.Query<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables> query={GetBookersByCourseIdsDocument} {...props} />
    );
    
export type GetBookersByCourseIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables> & TChildProps;
export function withGetBookersByCourseIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookersByCourseIdsQuery,
  GetBookersByCourseIdsQueryVariables,
  GetBookersByCourseIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables, GetBookersByCourseIdsProps<TChildProps>>(GetBookersByCourseIdsDocument, {
      alias: 'getBookersByCourseIds',
      ...operationOptions
    });
};

/**
 * __useGetBookersByCourseIdsQuery__
 *
 * To run a query within a React component, call `useGetBookersByCourseIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookersByCourseIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookersByCourseIdsQuery({
 *   variables: {
 *      courseIds: // value for 'courseIds'
 *   },
 * });
 */
export function useGetBookersByCourseIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>(GetBookersByCourseIdsDocument, baseOptions);
      }
export function useGetBookersByCourseIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>(GetBookersByCourseIdsDocument, baseOptions);
        }
export type GetBookersByCourseIdsQueryHookResult = ReturnType<typeof useGetBookersByCourseIdsQuery>;
export type GetBookersByCourseIdsLazyQueryHookResult = ReturnType<typeof useGetBookersByCourseIdsLazyQuery>;
export type GetBookersByCourseIdsQueryResult = ApolloReactCommon.QueryResult<GetBookersByCourseIdsQuery, GetBookersByCourseIdsQueryVariables>;
export const GetReceiversByCourseIdsDocument = gql`
    query getReceiversByCourseIds($courseIds: [String!]!) {
  getBookersByCourseIds(courseIds: $courseIds) {
    id
    firstname
    lastname
    email
  }
}
    `;
export type GetReceiversByCourseIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>, 'query'> & ({ variables: GetReceiversByCourseIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReceiversByCourseIdsComponent = (props: GetReceiversByCourseIdsComponentProps) => (
      <ApolloReactComponents.Query<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables> query={GetReceiversByCourseIdsDocument} {...props} />
    );
    
export type GetReceiversByCourseIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables> & TChildProps;
export function withGetReceiversByCourseIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReceiversByCourseIdsQuery,
  GetReceiversByCourseIdsQueryVariables,
  GetReceiversByCourseIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables, GetReceiversByCourseIdsProps<TChildProps>>(GetReceiversByCourseIdsDocument, {
      alias: 'getReceiversByCourseIds',
      ...operationOptions
    });
};

/**
 * __useGetReceiversByCourseIdsQuery__
 *
 * To run a query within a React component, call `useGetReceiversByCourseIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiversByCourseIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiversByCourseIdsQuery({
 *   variables: {
 *      courseIds: // value for 'courseIds'
 *   },
 * });
 */
export function useGetReceiversByCourseIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>(GetReceiversByCourseIdsDocument, baseOptions);
      }
export function useGetReceiversByCourseIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>(GetReceiversByCourseIdsDocument, baseOptions);
        }
export type GetReceiversByCourseIdsQueryHookResult = ReturnType<typeof useGetReceiversByCourseIdsQuery>;
export type GetReceiversByCourseIdsLazyQueryHookResult = ReturnType<typeof useGetReceiversByCourseIdsLazyQuery>;
export type GetReceiversByCourseIdsQueryResult = ApolloReactCommon.QueryResult<GetReceiversByCourseIdsQuery, GetReceiversByCourseIdsQueryVariables>;
export const DeleteBookerDocument = gql`
    mutation DeleteBooker($id: String!) {
  deleteBooker(id: $id)
}
    `;
export type DeleteBookerMutationFn = ApolloReactCommon.MutationFunction<DeleteBookerMutation, DeleteBookerMutationVariables>;
export type DeleteBookerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBookerMutation, DeleteBookerMutationVariables>, 'mutation'>;

    export const DeleteBookerComponent = (props: DeleteBookerComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBookerMutation, DeleteBookerMutationVariables> mutation={DeleteBookerDocument} {...props} />
    );
    
export type DeleteBookerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteBookerMutation, DeleteBookerMutationVariables> & TChildProps;
export function withDeleteBooker<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteBookerMutation,
  DeleteBookerMutationVariables,
  DeleteBookerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteBookerMutation, DeleteBookerMutationVariables, DeleteBookerProps<TChildProps>>(DeleteBookerDocument, {
      alias: 'deleteBooker',
      ...operationOptions
    });
};

/**
 * __useDeleteBookerMutation__
 *
 * To run a mutation, you first call `useDeleteBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookerMutation, { data, loading, error }] = useDeleteBookerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBookerMutation, DeleteBookerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBookerMutation, DeleteBookerMutationVariables>(DeleteBookerDocument, baseOptions);
      }
export type DeleteBookerMutationHookResult = ReturnType<typeof useDeleteBookerMutation>;
export type DeleteBookerMutationResult = ApolloReactCommon.MutationResult<DeleteBookerMutation>;
export type DeleteBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBookerMutation, DeleteBookerMutationVariables>;
export const RemoveAllBookersOfTenantDocument = gql`
    mutation RemoveAllBookersOfTenant {
  removeAllBookersOfTenant
}
    `;
export type RemoveAllBookersOfTenantMutationFn = ApolloReactCommon.MutationFunction<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables>;
export type RemoveAllBookersOfTenantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables>, 'mutation'>;

    export const RemoveAllBookersOfTenantComponent = (props: RemoveAllBookersOfTenantComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables> mutation={RemoveAllBookersOfTenantDocument} {...props} />
    );
    
export type RemoveAllBookersOfTenantProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables> & TChildProps;
export function withRemoveAllBookersOfTenant<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveAllBookersOfTenantMutation,
  RemoveAllBookersOfTenantMutationVariables,
  RemoveAllBookersOfTenantProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables, RemoveAllBookersOfTenantProps<TChildProps>>(RemoveAllBookersOfTenantDocument, {
      alias: 'removeAllBookersOfTenant',
      ...operationOptions
    });
};

/**
 * __useRemoveAllBookersOfTenantMutation__
 *
 * To run a mutation, you first call `useRemoveAllBookersOfTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllBookersOfTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllBookersOfTenantMutation, { data, loading, error }] = useRemoveAllBookersOfTenantMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllBookersOfTenantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables>(RemoveAllBookersOfTenantDocument, baseOptions);
      }
export type RemoveAllBookersOfTenantMutationHookResult = ReturnType<typeof useRemoveAllBookersOfTenantMutation>;
export type RemoveAllBookersOfTenantMutationResult = ApolloReactCommon.MutationResult<RemoveAllBookersOfTenantMutation>;
export type RemoveAllBookersOfTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAllBookersOfTenantMutation, RemoveAllBookersOfTenantMutationVariables>;
export const AddPaymentMethodDocument = gql`
    mutation AddPaymentMethod($bookerId: String!, $paymentMethodType: String!, $sepaDebitInput: SepaDebitIntput) {
  addPaymentMethod(bookerId: $bookerId, paymentMethodType: $paymentMethodType, sepaDebitIntput: $sepaDebitInput) {
    id
    cash
    esr
    creditCardAccountholder
    creditcardNumber
    expirationDate
    validFrom
    cvc
    paypalAccountholder
    email
    iban
    bank
    bic
    sepaAccountholder
    type
  }
}
    `;
export type AddPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export type AddPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>, 'mutation'>;

    export const AddPaymentMethodComponent = (props: AddPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables> mutation={AddPaymentMethodDocument} {...props} />
    );
    
export type AddPaymentMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddPaymentMethodMutation, AddPaymentMethodMutationVariables> & TChildProps;
export function withAddPaymentMethod<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables,
  AddPaymentMethodProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddPaymentMethodMutation, AddPaymentMethodMutationVariables, AddPaymentMethodProps<TChildProps>>(AddPaymentMethodDocument, {
      alias: 'addPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      sepaDebitInput: // value for 'sepaDebitInput'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(AddPaymentMethodDocument, baseOptions);
      }
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = ApolloReactCommon.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export const GetPaymentMethodsByBookerIdDocument = gql`
    query GetPaymentMethodsByBookerId($bookerId: String!) {
  getPaymentMethodsByBookerId(bookerId: $bookerId) {
    id
    cash
    esr
    creditcardNumber
    creditCardAccountholder
    expirationDate
    sepaAccountholder
    iban
    bic
    bank
    paypalAccountholder
    email
    bankTransfer
  }
}
    `;
export type GetPaymentMethodsByBookerIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>, 'query'> & ({ variables: GetPaymentMethodsByBookerIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPaymentMethodsByBookerIdComponent = (props: GetPaymentMethodsByBookerIdComponentProps) => (
      <ApolloReactComponents.Query<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables> query={GetPaymentMethodsByBookerIdDocument} {...props} />
    );
    
export type GetPaymentMethodsByBookerIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables> & TChildProps;
export function withGetPaymentMethodsByBookerId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPaymentMethodsByBookerIdQuery,
  GetPaymentMethodsByBookerIdQueryVariables,
  GetPaymentMethodsByBookerIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables, GetPaymentMethodsByBookerIdProps<TChildProps>>(GetPaymentMethodsByBookerIdDocument, {
      alias: 'getPaymentMethodsByBookerId',
      ...operationOptions
    });
};

/**
 * __useGetPaymentMethodsByBookerIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsByBookerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsByBookerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsByBookerIdQuery({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *   },
 * });
 */
export function useGetPaymentMethodsByBookerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>(GetPaymentMethodsByBookerIdDocument, baseOptions);
      }
export function useGetPaymentMethodsByBookerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>(GetPaymentMethodsByBookerIdDocument, baseOptions);
        }
export type GetPaymentMethodsByBookerIdQueryHookResult = ReturnType<typeof useGetPaymentMethodsByBookerIdQuery>;
export type GetPaymentMethodsByBookerIdLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsByBookerIdLazyQuery>;
export type GetPaymentMethodsByBookerIdQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodsByBookerIdQuery, GetPaymentMethodsByBookerIdQueryVariables>;
export const GetBookerDetailsDocument = gql`
    query GetBookerDetails($id: String!) {
  booker(id: $id) {
    id
    customerId
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    initialContact
    dsgvo
    newsletter
    credit
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      bankTransfer
    }
    attendees {
      id
      customerId
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
          courseId
          course {
            id
            maxAttendees
          }
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
      addition
      annotation
      customerNumber
      customerNumberPrefix
    }
    customerNumber
    customerNumberPrefix
  }
}
    `;
export type GetBookerDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>, 'query'> & ({ variables: GetBookerDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookerDetailsComponent = (props: GetBookerDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetBookerDetailsQuery, GetBookerDetailsQueryVariables> query={GetBookerDetailsDocument} {...props} />
    );
    
export type GetBookerDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookerDetailsQuery, GetBookerDetailsQueryVariables> & TChildProps;
export function withGetBookerDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookerDetailsQuery,
  GetBookerDetailsQueryVariables,
  GetBookerDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookerDetailsQuery, GetBookerDetailsQueryVariables, GetBookerDetailsProps<TChildProps>>(GetBookerDetailsDocument, {
      alias: 'getBookerDetails',
      ...operationOptions
    });
};

/**
 * __useGetBookerDetailsQuery__
 *
 * To run a query within a React component, call `useGetBookerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookerDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>(GetBookerDetailsDocument, baseOptions);
      }
export function useGetBookerDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>(GetBookerDetailsDocument, baseOptions);
        }
export type GetBookerDetailsQueryHookResult = ReturnType<typeof useGetBookerDetailsQuery>;
export type GetBookerDetailsLazyQueryHookResult = ReturnType<typeof useGetBookerDetailsLazyQuery>;
export type GetBookerDetailsQueryResult = ApolloReactCommon.QueryResult<GetBookerDetailsQuery, GetBookerDetailsQueryVariables>;
export const GetDuplicateBookersDocument = gql`
    query GetDuplicateBookers($options: ArrayDataOptions) {
  getDuplicateBookers(options: $options) {
    id
    firstname
    lastname
    gender
    customerNumber
    customerNumberPrefix
    email
    userId
    addition
    street
    houseNumber
    postCode
    city
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    annotation
    nonDisclosureNotice
    credit
    birthday
    updateDate
    createdBy
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      type
      bankTransfer
    }
    attendees {
      id
      firstname
      lastname
      customerNumber
      customerNumberPrefix
      bookings {
        id
        bookingNumber
        bookingNumberPrefix
        bookingType
        startDateTime
        canceled
        courseLessons {
          id
          course {
            id
            courseType {
              id
              name
              color
            }
          }
        }
      }
    }
  }
}
    `;
export type GetDuplicateBookersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>, 'query'>;

    export const GetDuplicateBookersComponent = (props: GetDuplicateBookersComponentProps) => (
      <ApolloReactComponents.Query<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables> query={GetDuplicateBookersDocument} {...props} />
    );
    
export type GetDuplicateBookersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables> & TChildProps;
export function withGetDuplicateBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDuplicateBookersQuery,
  GetDuplicateBookersQueryVariables,
  GetDuplicateBookersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables, GetDuplicateBookersProps<TChildProps>>(GetDuplicateBookersDocument, {
      alias: 'getDuplicateBookers',
      ...operationOptions
    });
};

/**
 * __useGetDuplicateBookersQuery__
 *
 * To run a query within a React component, call `useGetDuplicateBookersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateBookersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateBookersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetDuplicateBookersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>(GetDuplicateBookersDocument, baseOptions);
      }
export function useGetDuplicateBookersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>(GetDuplicateBookersDocument, baseOptions);
        }
export type GetDuplicateBookersQueryHookResult = ReturnType<typeof useGetDuplicateBookersQuery>;
export type GetDuplicateBookersLazyQueryHookResult = ReturnType<typeof useGetDuplicateBookersLazyQuery>;
export type GetDuplicateBookersQueryResult = ApolloReactCommon.QueryResult<GetDuplicateBookersQuery, GetDuplicateBookersQueryVariables>;
export const MergeBookersDocument = gql`
    mutation MergeBookers($updateBookerData: BookerInput!, $mergedBookerId: ID!) {
  mergeBookers(updateBookerData: $updateBookerData, mergedBookerId: $mergedBookerId) {
    id
    firstname
    lastname
    gender
    customerNumber
    email
    userId
    addition
    street
    houseNumber
    postCode
    city
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    annotation
    nonDisclosureNotice
    credit
    birthday
    updateDate
    createdBy
    paymentMethods {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      type
      bankTransfer
    }
    attendees {
      id
      firstname
      lastname
      customerNumber
      bookings {
        id
        bookingNumber
        bookingNumberPrefix
        bookingType
        startDateTime
        canceled
        courseLessons {
          id
          course {
            id
            courseType {
              id
              name
              color
            }
          }
        }
      }
    }
  }
}
    `;
export type MergeBookersMutationFn = ApolloReactCommon.MutationFunction<MergeBookersMutation, MergeBookersMutationVariables>;
export type MergeBookersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MergeBookersMutation, MergeBookersMutationVariables>, 'mutation'>;

    export const MergeBookersComponent = (props: MergeBookersComponentProps) => (
      <ApolloReactComponents.Mutation<MergeBookersMutation, MergeBookersMutationVariables> mutation={MergeBookersDocument} {...props} />
    );
    
export type MergeBookersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<MergeBookersMutation, MergeBookersMutationVariables> & TChildProps;
export function withMergeBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MergeBookersMutation,
  MergeBookersMutationVariables,
  MergeBookersProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, MergeBookersMutation, MergeBookersMutationVariables, MergeBookersProps<TChildProps>>(MergeBookersDocument, {
      alias: 'mergeBookers',
      ...operationOptions
    });
};

/**
 * __useMergeBookersMutation__
 *
 * To run a mutation, you first call `useMergeBookersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeBookersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeBookersMutation, { data, loading, error }] = useMergeBookersMutation({
 *   variables: {
 *      updateBookerData: // value for 'updateBookerData'
 *      mergedBookerId: // value for 'mergedBookerId'
 *   },
 * });
 */
export function useMergeBookersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MergeBookersMutation, MergeBookersMutationVariables>) {
        return ApolloReactHooks.useMutation<MergeBookersMutation, MergeBookersMutationVariables>(MergeBookersDocument, baseOptions);
      }
export type MergeBookersMutationHookResult = ReturnType<typeof useMergeBookersMutation>;
export type MergeBookersMutationResult = ApolloReactCommon.MutationResult<MergeBookersMutation>;
export type MergeBookersMutationOptions = ApolloReactCommon.BaseMutationOptions<MergeBookersMutation, MergeBookersMutationVariables>;
export const UpdateBookerCreditDocument = gql`
    mutation UpdateBookerCredit($bookerId: ID!, $credit: Float!) {
  updateBookerCredit(bookerId: $bookerId, credit: $credit) {
    id
    credit
  }
}
    `;
export type UpdateBookerCreditMutationFn = ApolloReactCommon.MutationFunction<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables>;
export type UpdateBookerCreditComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables>, 'mutation'>;

    export const UpdateBookerCreditComponent = (props: UpdateBookerCreditComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables> mutation={UpdateBookerCreditDocument} {...props} />
    );
    
export type UpdateBookerCreditProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables> & TChildProps;
export function withUpdateBookerCredit<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookerCreditMutation,
  UpdateBookerCreditMutationVariables,
  UpdateBookerCreditProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables, UpdateBookerCreditProps<TChildProps>>(UpdateBookerCreditDocument, {
      alias: 'updateBookerCredit',
      ...operationOptions
    });
};

/**
 * __useUpdateBookerCreditMutation__
 *
 * To run a mutation, you first call `useUpdateBookerCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookerCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookerCreditMutation, { data, loading, error }] = useUpdateBookerCreditMutation({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *      credit: // value for 'credit'
 *   },
 * });
 */
export function useUpdateBookerCreditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables>(UpdateBookerCreditDocument, baseOptions);
      }
export type UpdateBookerCreditMutationHookResult = ReturnType<typeof useUpdateBookerCreditMutation>;
export type UpdateBookerCreditMutationResult = ApolloReactCommon.MutationResult<UpdateBookerCreditMutation>;
export type UpdateBookerCreditMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookerCreditMutation, UpdateBookerCreditMutationVariables>;
export const GetBookersExportDocument = gql`
    query GetBookersExport($options: ArrayDataOptions) {
  bookersExport(options: $options) {
    id
    customerNumberPrefix
    customerNumber
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    email
    mobileNumber
    phoneNumber
    birthday
    gender
    initialContact
    healthInsuranceFund
    addition
    annotation
    credit
    nonDisclosureNotice
    attendees {
      id
      firstname
      lastname
      member
      addition
      annotation
    }
  }
}
    `;
export type GetBookersExportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookersExportQuery, GetBookersExportQueryVariables>, 'query'>;

    export const GetBookersExportComponent = (props: GetBookersExportComponentProps) => (
      <ApolloReactComponents.Query<GetBookersExportQuery, GetBookersExportQueryVariables> query={GetBookersExportDocument} {...props} />
    );
    
export type GetBookersExportProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookersExportQuery, GetBookersExportQueryVariables> & TChildProps;
export function withGetBookersExport<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookersExportQuery,
  GetBookersExportQueryVariables,
  GetBookersExportProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookersExportQuery, GetBookersExportQueryVariables, GetBookersExportProps<TChildProps>>(GetBookersExportDocument, {
      alias: 'getBookersExport',
      ...operationOptions
    });
};

/**
 * __useGetBookersExportQuery__
 *
 * To run a query within a React component, call `useGetBookersExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookersExportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookersExportQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBookersExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookersExportQuery, GetBookersExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookersExportQuery, GetBookersExportQueryVariables>(GetBookersExportDocument, baseOptions);
      }
export function useGetBookersExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookersExportQuery, GetBookersExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookersExportQuery, GetBookersExportQueryVariables>(GetBookersExportDocument, baseOptions);
        }
export type GetBookersExportQueryHookResult = ReturnType<typeof useGetBookersExportQuery>;
export type GetBookersExportLazyQueryHookResult = ReturnType<typeof useGetBookersExportLazyQuery>;
export type GetBookersExportQueryResult = ApolloReactCommon.QueryResult<GetBookersExportQuery, GetBookersExportQueryVariables>;
export const GetOnlineBookingsByTimeRangeCountDocument = gql`
    query getOnlineBookingsByTimeRangeCount($startDateTime: DateTime!, $endDateTime: DateTime!) {
  getOnlineBookingsByTimeRangeCount(startDateTime: $startDateTime, endDateTime: $endDateTime)
}
    `;
export type GetOnlineBookingsByTimeRangeCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>, 'query'> & ({ variables: GetOnlineBookingsByTimeRangeCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOnlineBookingsByTimeRangeCountComponent = (props: GetOnlineBookingsByTimeRangeCountComponentProps) => (
      <ApolloReactComponents.Query<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables> query={GetOnlineBookingsByTimeRangeCountDocument} {...props} />
    );
    
export type GetOnlineBookingsByTimeRangeCountProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables> & TChildProps;
export function withGetOnlineBookingsByTimeRangeCount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOnlineBookingsByTimeRangeCountQuery,
  GetOnlineBookingsByTimeRangeCountQueryVariables,
  GetOnlineBookingsByTimeRangeCountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables, GetOnlineBookingsByTimeRangeCountProps<TChildProps>>(GetOnlineBookingsByTimeRangeCountDocument, {
      alias: 'getOnlineBookingsByTimeRangeCount',
      ...operationOptions
    });
};

/**
 * __useGetOnlineBookingsByTimeRangeCountQuery__
 *
 * To run a query within a React component, call `useGetOnlineBookingsByTimeRangeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnlineBookingsByTimeRangeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnlineBookingsByTimeRangeCountQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useGetOnlineBookingsByTimeRangeCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>(GetOnlineBookingsByTimeRangeCountDocument, baseOptions);
      }
export function useGetOnlineBookingsByTimeRangeCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>(GetOnlineBookingsByTimeRangeCountDocument, baseOptions);
        }
export type GetOnlineBookingsByTimeRangeCountQueryHookResult = ReturnType<typeof useGetOnlineBookingsByTimeRangeCountQuery>;
export type GetOnlineBookingsByTimeRangeCountLazyQueryHookResult = ReturnType<typeof useGetOnlineBookingsByTimeRangeCountLazyQuery>;
export type GetOnlineBookingsByTimeRangeCountQueryResult = ApolloReactCommon.QueryResult<GetOnlineBookingsByTimeRangeCountQuery, GetOnlineBookingsByTimeRangeCountQueryVariables>;
export const GetUpcomingCoursesByTimeRangeDocument = gql`
    query GetUpcomingCoursesByTimeRange($startDateTime: DateTime!, $endDateTime: DateTime!, $options: ArrayDataOptions) {
  getUpcomingCoursesByTimeRange(startDateTime: $startDateTime, endDateTime: $endDateTime, options: $options) {
    existsMore
    total
    items {
      id
      startDateTime
      prefixedCourseNumber
      maxAttendees
      minAttendees
      freePlaces
      bookingCount
      waitListCount
      placeReservationsCount
      courseType {
        id
        name
        color
      }
      location {
        id
        name
        city
      }
      instructors {
        id
        firstname
        lastname
      }
    }
  }
}
    `;
export type GetUpcomingCoursesByTimeRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>, 'query'> & ({ variables: GetUpcomingCoursesByTimeRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUpcomingCoursesByTimeRangeComponent = (props: GetUpcomingCoursesByTimeRangeComponentProps) => (
      <ApolloReactComponents.Query<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables> query={GetUpcomingCoursesByTimeRangeDocument} {...props} />
    );
    
export type GetUpcomingCoursesByTimeRangeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables> & TChildProps;
export function withGetUpcomingCoursesByTimeRange<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUpcomingCoursesByTimeRangeQuery,
  GetUpcomingCoursesByTimeRangeQueryVariables,
  GetUpcomingCoursesByTimeRangeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables, GetUpcomingCoursesByTimeRangeProps<TChildProps>>(GetUpcomingCoursesByTimeRangeDocument, {
      alias: 'getUpcomingCoursesByTimeRange',
      ...operationOptions
    });
};

/**
 * __useGetUpcomingCoursesByTimeRangeQuery__
 *
 * To run a query within a React component, call `useGetUpcomingCoursesByTimeRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingCoursesByTimeRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingCoursesByTimeRangeQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUpcomingCoursesByTimeRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>(GetUpcomingCoursesByTimeRangeDocument, baseOptions);
      }
export function useGetUpcomingCoursesByTimeRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>(GetUpcomingCoursesByTimeRangeDocument, baseOptions);
        }
export type GetUpcomingCoursesByTimeRangeQueryHookResult = ReturnType<typeof useGetUpcomingCoursesByTimeRangeQuery>;
export type GetUpcomingCoursesByTimeRangeLazyQueryHookResult = ReturnType<typeof useGetUpcomingCoursesByTimeRangeLazyQuery>;
export type GetUpcomingCoursesByTimeRangeQueryResult = ApolloReactCommon.QueryResult<GetUpcomingCoursesByTimeRangeQuery, GetUpcomingCoursesByTimeRangeQueryVariables>;
export const GetUpcomingCoursesByTimeRangeMinDocument = gql`
    query GetUpcomingCoursesByTimeRangeMin($startDateTime: DateTime!, $endDateTime: DateTime!, $options: ArrayDataOptions) {
  getUpcomingCoursesByTimeRange(startDateTime: $startDateTime, endDateTime: $endDateTime, options: $options) {
    existsMore
    total
    items {
      id
      maxAttendees
      freePlaces
      waitListCount
      bookingCount
    }
  }
}
    `;
export type GetUpcomingCoursesByTimeRangeMinComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>, 'query'> & ({ variables: GetUpcomingCoursesByTimeRangeMinQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUpcomingCoursesByTimeRangeMinComponent = (props: GetUpcomingCoursesByTimeRangeMinComponentProps) => (
      <ApolloReactComponents.Query<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables> query={GetUpcomingCoursesByTimeRangeMinDocument} {...props} />
    );
    
export type GetUpcomingCoursesByTimeRangeMinProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables> & TChildProps;
export function withGetUpcomingCoursesByTimeRangeMin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUpcomingCoursesByTimeRangeMinQuery,
  GetUpcomingCoursesByTimeRangeMinQueryVariables,
  GetUpcomingCoursesByTimeRangeMinProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables, GetUpcomingCoursesByTimeRangeMinProps<TChildProps>>(GetUpcomingCoursesByTimeRangeMinDocument, {
      alias: 'getUpcomingCoursesByTimeRangeMin',
      ...operationOptions
    });
};

/**
 * __useGetUpcomingCoursesByTimeRangeMinQuery__
 *
 * To run a query within a React component, call `useGetUpcomingCoursesByTimeRangeMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingCoursesByTimeRangeMinQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingCoursesByTimeRangeMinQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUpcomingCoursesByTimeRangeMinQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>(GetUpcomingCoursesByTimeRangeMinDocument, baseOptions);
      }
export function useGetUpcomingCoursesByTimeRangeMinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>(GetUpcomingCoursesByTimeRangeMinDocument, baseOptions);
        }
export type GetUpcomingCoursesByTimeRangeMinQueryHookResult = ReturnType<typeof useGetUpcomingCoursesByTimeRangeMinQuery>;
export type GetUpcomingCoursesByTimeRangeMinLazyQueryHookResult = ReturnType<typeof useGetUpcomingCoursesByTimeRangeMinLazyQuery>;
export type GetUpcomingCoursesByTimeRangeMinQueryResult = ApolloReactCommon.QueryResult<GetUpcomingCoursesByTimeRangeMinQuery, GetUpcomingCoursesByTimeRangeMinQueryVariables>;
export const GetCoursesWithHighDemandDocument = gql`
    query GetCoursesWithHighDemand($options: ArrayDataOptions) {
  getCoursesWithHighDemand(options: $options) {
    existsMore
    total
    items {
      id
      maxAttendees
      waitListCount
      bookingCount
      prefixedCourseNumber
      startDateTime
      location {
        id
        name
      }
      courseType {
        id
        name
        color
      }
    }
  }
}
    `;
export type GetCoursesWithHighDemandComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>, 'query'>;

    export const GetCoursesWithHighDemandComponent = (props: GetCoursesWithHighDemandComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables> query={GetCoursesWithHighDemandDocument} {...props} />
    );
    
export type GetCoursesWithHighDemandProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables> & TChildProps;
export function withGetCoursesWithHighDemand<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesWithHighDemandQuery,
  GetCoursesWithHighDemandQueryVariables,
  GetCoursesWithHighDemandProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables, GetCoursesWithHighDemandProps<TChildProps>>(GetCoursesWithHighDemandDocument, {
      alias: 'getCoursesWithHighDemand',
      ...operationOptions
    });
};

/**
 * __useGetCoursesWithHighDemandQuery__
 *
 * To run a query within a React component, call `useGetCoursesWithHighDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesWithHighDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesWithHighDemandQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCoursesWithHighDemandQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>(GetCoursesWithHighDemandDocument, baseOptions);
      }
export function useGetCoursesWithHighDemandLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>(GetCoursesWithHighDemandDocument, baseOptions);
        }
export type GetCoursesWithHighDemandQueryHookResult = ReturnType<typeof useGetCoursesWithHighDemandQuery>;
export type GetCoursesWithHighDemandLazyQueryHookResult = ReturnType<typeof useGetCoursesWithHighDemandLazyQuery>;
export type GetCoursesWithHighDemandQueryResult = ApolloReactCommon.QueryResult<GetCoursesWithHighDemandQuery, GetCoursesWithHighDemandQueryVariables>;
export const SendSimpleEmailDocument = gql`
    mutation SendSimpleEmail($sendParamsMessageInput: SendParamsMessageInput!, $sandboxMode: Boolean!) {
  sendMail(sendParamsMessageInput: $sendParamsMessageInput, sandboxMode: $sandboxMode)
}
    `;
export type SendSimpleEmailMutationFn = ApolloReactCommon.MutationFunction<SendSimpleEmailMutation, SendSimpleEmailMutationVariables>;
export type SendSimpleEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendSimpleEmailMutation, SendSimpleEmailMutationVariables>, 'mutation'>;

    export const SendSimpleEmailComponent = (props: SendSimpleEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SendSimpleEmailMutation, SendSimpleEmailMutationVariables> mutation={SendSimpleEmailDocument} {...props} />
    );
    
export type SendSimpleEmailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendSimpleEmailMutation, SendSimpleEmailMutationVariables> & TChildProps;
export function withSendSimpleEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendSimpleEmailMutation,
  SendSimpleEmailMutationVariables,
  SendSimpleEmailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendSimpleEmailMutation, SendSimpleEmailMutationVariables, SendSimpleEmailProps<TChildProps>>(SendSimpleEmailDocument, {
      alias: 'sendSimpleEmail',
      ...operationOptions
    });
};

/**
 * __useSendSimpleEmailMutation__
 *
 * To run a mutation, you first call `useSendSimpleEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSimpleEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSimpleEmailMutation, { data, loading, error }] = useSendSimpleEmailMutation({
 *   variables: {
 *      sendParamsMessageInput: // value for 'sendParamsMessageInput'
 *      sandboxMode: // value for 'sandboxMode'
 *   },
 * });
 */
export function useSendSimpleEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSimpleEmailMutation, SendSimpleEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSimpleEmailMutation, SendSimpleEmailMutationVariables>(SendSimpleEmailDocument, baseOptions);
      }
export type SendSimpleEmailMutationHookResult = ReturnType<typeof useSendSimpleEmailMutation>;
export type SendSimpleEmailMutationResult = ApolloReactCommon.MutationResult<SendSimpleEmailMutation>;
export type SendSimpleEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSimpleEmailMutation, SendSimpleEmailMutationVariables>;
export const FaUserByEmailDocument = gql`
    query FaUserByEmail($email: String!) {
  faUserByEmail(email: $email) {
    id
    lastLoginInstant
    passwordLastUpdateInstant
    verified
    active
    email
    firstName
    lastName
    fullName
    imageUrl
    insertInstant
    lastUpdateInstant
    registrations {
      applicationId
      data
      id
      insertInstant
      lastLoginInstant
      lastUpdateInstant
      roles
      username
      verified
    }
    tenantId
    timezone
  }
}
    `;
export type FaUserByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaUserByEmailQuery, FaUserByEmailQueryVariables>, 'query'> & ({ variables: FaUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FaUserByEmailComponent = (props: FaUserByEmailComponentProps) => (
      <ApolloReactComponents.Query<FaUserByEmailQuery, FaUserByEmailQueryVariables> query={FaUserByEmailDocument} {...props} />
    );
    
export type FaUserByEmailProps<TChildProps = {}> = ApolloReactHoc.DataProps<FaUserByEmailQuery, FaUserByEmailQueryVariables> & TChildProps;
export function withFaUserByEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaUserByEmailQuery,
  FaUserByEmailQueryVariables,
  FaUserByEmailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FaUserByEmailQuery, FaUserByEmailQueryVariables, FaUserByEmailProps<TChildProps>>(FaUserByEmailDocument, {
      alias: 'faUserByEmail',
      ...operationOptions
    });
};

/**
 * __useFaUserByEmailQuery__
 *
 * To run a query within a React component, call `useFaUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFaUserByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaUserByEmailQuery, FaUserByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<FaUserByEmailQuery, FaUserByEmailQueryVariables>(FaUserByEmailDocument, baseOptions);
      }
export function useFaUserByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaUserByEmailQuery, FaUserByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaUserByEmailQuery, FaUserByEmailQueryVariables>(FaUserByEmailDocument, baseOptions);
        }
export type FaUserByEmailQueryHookResult = ReturnType<typeof useFaUserByEmailQuery>;
export type FaUserByEmailLazyQueryHookResult = ReturnType<typeof useFaUserByEmailLazyQuery>;
export type FaUserByEmailQueryResult = ApolloReactCommon.QueryResult<FaUserByEmailQuery, FaUserByEmailQueryVariables>;
export const FaUserByUserIdDocument = gql`
    query FaUserByUserId($userId: String!) {
  faUserByUserId(userId: $userId) {
    id
    lastLoginInstant
    passwordLastUpdateInstant
    verified
    active
    email
    firstName
    lastName
    fullName
    imageUrl
    insertInstant
    lastUpdateInstant
    registrations {
      applicationId
      data
      id
      insertInstant
      lastLoginInstant
      lastUpdateInstant
      roles
      username
      verified
    }
    tenantId
    timezone
  }
}
    `;
export type FaUserByUserIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>, 'query'> & ({ variables: FaUserByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FaUserByUserIdComponent = (props: FaUserByUserIdComponentProps) => (
      <ApolloReactComponents.Query<FaUserByUserIdQuery, FaUserByUserIdQueryVariables> query={FaUserByUserIdDocument} {...props} />
    );
    
export type FaUserByUserIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<FaUserByUserIdQuery, FaUserByUserIdQueryVariables> & TChildProps;
export function withFaUserByUserId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaUserByUserIdQuery,
  FaUserByUserIdQueryVariables,
  FaUserByUserIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FaUserByUserIdQuery, FaUserByUserIdQueryVariables, FaUserByUserIdProps<TChildProps>>(FaUserByUserIdDocument, {
      alias: 'faUserByUserId',
      ...operationOptions
    });
};

/**
 * __useFaUserByUserIdQuery__
 *
 * To run a query within a React component, call `useFaUserByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaUserByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaUserByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFaUserByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>(FaUserByUserIdDocument, baseOptions);
      }
export function useFaUserByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>(FaUserByUserIdDocument, baseOptions);
        }
export type FaUserByUserIdQueryHookResult = ReturnType<typeof useFaUserByUserIdQuery>;
export type FaUserByUserIdLazyQueryHookResult = ReturnType<typeof useFaUserByUserIdLazyQuery>;
export type FaUserByUserIdQueryResult = ApolloReactCommon.QueryResult<FaUserByUserIdQuery, FaUserByUserIdQueryVariables>;
export const CreateFaUserWithOrAddEndUserRightsDocument = gql`
    mutation CreateFaUserWithOrAddEndUserRights($email: String!) {
  createFaUserWithOrAddEndUserRights(email: $email) {
    id
    lastLoginInstant
    passwordLastUpdateInstant
    verified
    active
    email
    firstName
    lastName
    fullName
    imageUrl
    insertInstant
    lastUpdateInstant
    registrations {
      applicationId
      data
      id
      insertInstant
      lastLoginInstant
      lastUpdateInstant
      roles
      username
      verified
    }
    tenantId
    timezone
  }
}
    `;
export type CreateFaUserWithOrAddEndUserRightsMutationFn = ApolloReactCommon.MutationFunction<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables>;
export type CreateFaUserWithOrAddEndUserRightsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables>, 'mutation'>;

    export const CreateFaUserWithOrAddEndUserRightsComponent = (props: CreateFaUserWithOrAddEndUserRightsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables> mutation={CreateFaUserWithOrAddEndUserRightsDocument} {...props} />
    );
    
export type CreateFaUserWithOrAddEndUserRightsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables> & TChildProps;
export function withCreateFaUserWithOrAddEndUserRights<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFaUserWithOrAddEndUserRightsMutation,
  CreateFaUserWithOrAddEndUserRightsMutationVariables,
  CreateFaUserWithOrAddEndUserRightsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables, CreateFaUserWithOrAddEndUserRightsProps<TChildProps>>(CreateFaUserWithOrAddEndUserRightsDocument, {
      alias: 'createFaUserWithOrAddEndUserRights',
      ...operationOptions
    });
};

/**
 * __useCreateFaUserWithOrAddEndUserRightsMutation__
 *
 * To run a mutation, you first call `useCreateFaUserWithOrAddEndUserRightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaUserWithOrAddEndUserRightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaUserWithOrAddEndUserRightsMutation, { data, loading, error }] = useCreateFaUserWithOrAddEndUserRightsMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateFaUserWithOrAddEndUserRightsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables>(CreateFaUserWithOrAddEndUserRightsDocument, baseOptions);
      }
export type CreateFaUserWithOrAddEndUserRightsMutationHookResult = ReturnType<typeof useCreateFaUserWithOrAddEndUserRightsMutation>;
export type CreateFaUserWithOrAddEndUserRightsMutationResult = ApolloReactCommon.MutationResult<CreateFaUserWithOrAddEndUserRightsMutation>;
export type CreateFaUserWithOrAddEndUserRightsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFaUserWithOrAddEndUserRightsMutation, CreateFaUserWithOrAddEndUserRightsMutationVariables>;
export const AddFaUserByBookerIdDocument = gql`
    mutation AddFaUserByBookerId($bookerId: String!) {
  addFaUserByBookerId(bookerId: $bookerId) {
    id
    lastLoginInstant
    passwordLastUpdateInstant
    verified
    active
    email
    firstName
    lastName
    fullName
    imageUrl
    insertInstant
    lastUpdateInstant
    registrations {
      applicationId
      data
      id
      insertInstant
      lastLoginInstant
      lastUpdateInstant
      roles
      username
      verified
    }
    tenantId
    timezone
  }
}
    `;
export type AddFaUserByBookerIdMutationFn = ApolloReactCommon.MutationFunction<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables>;
export type AddFaUserByBookerIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables>, 'mutation'>;

    export const AddFaUserByBookerIdComponent = (props: AddFaUserByBookerIdComponentProps) => (
      <ApolloReactComponents.Mutation<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables> mutation={AddFaUserByBookerIdDocument} {...props} />
    );
    
export type AddFaUserByBookerIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables> & TChildProps;
export function withAddFaUserByBookerId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddFaUserByBookerIdMutation,
  AddFaUserByBookerIdMutationVariables,
  AddFaUserByBookerIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables, AddFaUserByBookerIdProps<TChildProps>>(AddFaUserByBookerIdDocument, {
      alias: 'addFaUserByBookerId',
      ...operationOptions
    });
};

/**
 * __useAddFaUserByBookerIdMutation__
 *
 * To run a mutation, you first call `useAddFaUserByBookerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFaUserByBookerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFaUserByBookerIdMutation, { data, loading, error }] = useAddFaUserByBookerIdMutation({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *   },
 * });
 */
export function useAddFaUserByBookerIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables>(AddFaUserByBookerIdDocument, baseOptions);
      }
export type AddFaUserByBookerIdMutationHookResult = ReturnType<typeof useAddFaUserByBookerIdMutation>;
export type AddFaUserByBookerIdMutationResult = ApolloReactCommon.MutationResult<AddFaUserByBookerIdMutation>;
export type AddFaUserByBookerIdMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFaUserByBookerIdMutation, AddFaUserByBookerIdMutationVariables>;
export const CheckEmailUniquenessDocument = gql`
    query checkEmailUniqueness($email: String!) {
  checkEmailUniqueness(email: $email)
}
    `;
export type CheckEmailUniquenessComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>, 'query'> & ({ variables: CheckEmailUniquenessQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckEmailUniquenessComponent = (props: CheckEmailUniquenessComponentProps) => (
      <ApolloReactComponents.Query<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables> query={CheckEmailUniquenessDocument} {...props} />
    );
    
export type CheckEmailUniquenessProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables> & TChildProps;
export function withCheckEmailUniqueness<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckEmailUniquenessQuery,
  CheckEmailUniquenessQueryVariables,
  CheckEmailUniquenessProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables, CheckEmailUniquenessProps<TChildProps>>(CheckEmailUniquenessDocument, {
      alias: 'checkEmailUniqueness',
      ...operationOptions
    });
};

/**
 * __useCheckEmailUniquenessQuery__
 *
 * To run a query within a React component, call `useCheckEmailUniquenessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailUniquenessQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailUniquenessQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailUniquenessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>(CheckEmailUniquenessDocument, baseOptions);
      }
export function useCheckEmailUniquenessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>(CheckEmailUniquenessDocument, baseOptions);
        }
export type CheckEmailUniquenessQueryHookResult = ReturnType<typeof useCheckEmailUniquenessQuery>;
export type CheckEmailUniquenessLazyQueryHookResult = ReturnType<typeof useCheckEmailUniquenessLazyQuery>;
export type CheckEmailUniquenessQueryResult = ApolloReactCommon.QueryResult<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>;
export const GetHolidaysByStateCodeDocument = gql`
    query GetHolidaysByStateCode($stateCode: StateCode!) {
  holidaysByStateCode(stateCode: $stateCode) {
    id
    name
    slug
    start
    end
    year
    stateCode
    holidayStateColor
    type
    days
    color
  }
}
    `;
export type GetHolidaysByStateCodeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>, 'query'> & ({ variables: GetHolidaysByStateCodeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetHolidaysByStateCodeComponent = (props: GetHolidaysByStateCodeComponentProps) => (
      <ApolloReactComponents.Query<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables> query={GetHolidaysByStateCodeDocument} {...props} />
    );
    
export type GetHolidaysByStateCodeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables> & TChildProps;
export function withGetHolidaysByStateCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetHolidaysByStateCodeQuery,
  GetHolidaysByStateCodeQueryVariables,
  GetHolidaysByStateCodeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables, GetHolidaysByStateCodeProps<TChildProps>>(GetHolidaysByStateCodeDocument, {
      alias: 'getHolidaysByStateCode',
      ...operationOptions
    });
};

/**
 * __useGetHolidaysByStateCodeQuery__
 *
 * To run a query within a React component, call `useGetHolidaysByStateCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysByStateCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysByStateCodeQuery({
 *   variables: {
 *      stateCode: // value for 'stateCode'
 *   },
 * });
 */
export function useGetHolidaysByStateCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>(GetHolidaysByStateCodeDocument, baseOptions);
      }
export function useGetHolidaysByStateCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>(GetHolidaysByStateCodeDocument, baseOptions);
        }
export type GetHolidaysByStateCodeQueryHookResult = ReturnType<typeof useGetHolidaysByStateCodeQuery>;
export type GetHolidaysByStateCodeLazyQueryHookResult = ReturnType<typeof useGetHolidaysByStateCodeLazyQuery>;
export type GetHolidaysByStateCodeQueryResult = ApolloReactCommon.QueryResult<GetHolidaysByStateCodeQuery, GetHolidaysByStateCodeQueryVariables>;
export const GetHolidaysByCompanyDocument = gql`
    query GetHolidaysByCompany {
  holidaysByCompany {
    id
    name
    slug
    start
    end
    year
    stateCode
    holidayStateColor
    type
    days
    color
  }
}
    `;
export type GetHolidaysByCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>, 'query'>;

    export const GetHolidaysByCompanyComponent = (props: GetHolidaysByCompanyComponentProps) => (
      <ApolloReactComponents.Query<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables> query={GetHolidaysByCompanyDocument} {...props} />
    );
    
export type GetHolidaysByCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables> & TChildProps;
export function withGetHolidaysByCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetHolidaysByCompanyQuery,
  GetHolidaysByCompanyQueryVariables,
  GetHolidaysByCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables, GetHolidaysByCompanyProps<TChildProps>>(GetHolidaysByCompanyDocument, {
      alias: 'getHolidaysByCompany',
      ...operationOptions
    });
};

/**
 * __useGetHolidaysByCompanyQuery__
 *
 * To run a query within a React component, call `useGetHolidaysByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysByCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHolidaysByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>(GetHolidaysByCompanyDocument, baseOptions);
      }
export function useGetHolidaysByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>(GetHolidaysByCompanyDocument, baseOptions);
        }
export type GetHolidaysByCompanyQueryHookResult = ReturnType<typeof useGetHolidaysByCompanyQuery>;
export type GetHolidaysByCompanyLazyQueryHookResult = ReturnType<typeof useGetHolidaysByCompanyLazyQuery>;
export type GetHolidaysByCompanyQueryResult = ApolloReactCommon.QueryResult<GetHolidaysByCompanyQuery, GetHolidaysByCompanyQueryVariables>;
export const GetHolidaysByCompanyAndDateRangeDocument = gql`
    query GetHolidaysByCompanyAndDateRange($start: DateTime!, $end: DateTime!) {
  holidaysByCompanyAndDateRange(start: $start, end: $end) {
    id
    name
    slug
    start
    end
    year
    stateCode
    holidayStateColor
    type
    days
    color
  }
}
    `;
export type GetHolidaysByCompanyAndDateRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>, 'query'> & ({ variables: GetHolidaysByCompanyAndDateRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetHolidaysByCompanyAndDateRangeComponent = (props: GetHolidaysByCompanyAndDateRangeComponentProps) => (
      <ApolloReactComponents.Query<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables> query={GetHolidaysByCompanyAndDateRangeDocument} {...props} />
    );
    
export type GetHolidaysByCompanyAndDateRangeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables> & TChildProps;
export function withGetHolidaysByCompanyAndDateRange<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetHolidaysByCompanyAndDateRangeQuery,
  GetHolidaysByCompanyAndDateRangeQueryVariables,
  GetHolidaysByCompanyAndDateRangeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables, GetHolidaysByCompanyAndDateRangeProps<TChildProps>>(GetHolidaysByCompanyAndDateRangeDocument, {
      alias: 'getHolidaysByCompanyAndDateRange',
      ...operationOptions
    });
};

/**
 * __useGetHolidaysByCompanyAndDateRangeQuery__
 *
 * To run a query within a React component, call `useGetHolidaysByCompanyAndDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysByCompanyAndDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysByCompanyAndDateRangeQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetHolidaysByCompanyAndDateRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>(GetHolidaysByCompanyAndDateRangeDocument, baseOptions);
      }
export function useGetHolidaysByCompanyAndDateRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>(GetHolidaysByCompanyAndDateRangeDocument, baseOptions);
        }
export type GetHolidaysByCompanyAndDateRangeQueryHookResult = ReturnType<typeof useGetHolidaysByCompanyAndDateRangeQuery>;
export type GetHolidaysByCompanyAndDateRangeLazyQueryHookResult = ReturnType<typeof useGetHolidaysByCompanyAndDateRangeLazyQuery>;
export type GetHolidaysByCompanyAndDateRangeQueryResult = ApolloReactCommon.QueryResult<GetHolidaysByCompanyAndDateRangeQuery, GetHolidaysByCompanyAndDateRangeQueryVariables>;
export const GetCustomHolidaysByCompanyDocument = gql`
    query GetCustomHolidaysByCompany {
  customHolidaysByCompany {
    id
    name
    slug
    start
    end
    year
    stateCode
    holidayStateColor
    type
    color
  }
}
    `;
export type GetCustomHolidaysByCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>, 'query'>;

    export const GetCustomHolidaysByCompanyComponent = (props: GetCustomHolidaysByCompanyComponentProps) => (
      <ApolloReactComponents.Query<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables> query={GetCustomHolidaysByCompanyDocument} {...props} />
    );
    
export type GetCustomHolidaysByCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables> & TChildProps;
export function withGetCustomHolidaysByCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomHolidaysByCompanyQuery,
  GetCustomHolidaysByCompanyQueryVariables,
  GetCustomHolidaysByCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables, GetCustomHolidaysByCompanyProps<TChildProps>>(GetCustomHolidaysByCompanyDocument, {
      alias: 'getCustomHolidaysByCompany',
      ...operationOptions
    });
};

/**
 * __useGetCustomHolidaysByCompanyQuery__
 *
 * To run a query within a React component, call `useGetCustomHolidaysByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomHolidaysByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomHolidaysByCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomHolidaysByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>(GetCustomHolidaysByCompanyDocument, baseOptions);
      }
export function useGetCustomHolidaysByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>(GetCustomHolidaysByCompanyDocument, baseOptions);
        }
export type GetCustomHolidaysByCompanyQueryHookResult = ReturnType<typeof useGetCustomHolidaysByCompanyQuery>;
export type GetCustomHolidaysByCompanyLazyQueryHookResult = ReturnType<typeof useGetCustomHolidaysByCompanyLazyQuery>;
export type GetCustomHolidaysByCompanyQueryResult = ApolloReactCommon.QueryResult<GetCustomHolidaysByCompanyQuery, GetCustomHolidaysByCompanyQueryVariables>;
export const UpdateCustomCompanyHolidaysDocument = gql`
    mutation UpdateCustomCompanyHolidays($holidayInputs: [HolidayInput!]!) {
  updateCustomCompanyHolidays(holidayInputs: $holidayInputs) {
    id
    name
    slug
    start
    end
    year
    stateCode
    holidayStateColor
    type
    color
  }
}
    `;
export type UpdateCustomCompanyHolidaysMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables>;
export type UpdateCustomCompanyHolidaysComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables>, 'mutation'>;

    export const UpdateCustomCompanyHolidaysComponent = (props: UpdateCustomCompanyHolidaysComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables> mutation={UpdateCustomCompanyHolidaysDocument} {...props} />
    );
    
export type UpdateCustomCompanyHolidaysProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables> & TChildProps;
export function withUpdateCustomCompanyHolidays<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomCompanyHolidaysMutation,
  UpdateCustomCompanyHolidaysMutationVariables,
  UpdateCustomCompanyHolidaysProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables, UpdateCustomCompanyHolidaysProps<TChildProps>>(UpdateCustomCompanyHolidaysDocument, {
      alias: 'updateCustomCompanyHolidays',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomCompanyHolidaysMutation__
 *
 * To run a mutation, you first call `useUpdateCustomCompanyHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomCompanyHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomCompanyHolidaysMutation, { data, loading, error }] = useUpdateCustomCompanyHolidaysMutation({
 *   variables: {
 *      holidayInputs: // value for 'holidayInputs'
 *   },
 * });
 */
export function useUpdateCustomCompanyHolidaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables>(UpdateCustomCompanyHolidaysDocument, baseOptions);
      }
export type UpdateCustomCompanyHolidaysMutationHookResult = ReturnType<typeof useUpdateCustomCompanyHolidaysMutation>;
export type UpdateCustomCompanyHolidaysMutationResult = ApolloReactCommon.MutationResult<UpdateCustomCompanyHolidaysMutation>;
export type UpdateCustomCompanyHolidaysMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomCompanyHolidaysMutation, UpdateCustomCompanyHolidaysMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($newInvoiceData: InvoiceInput!, $sendMail: Boolean) {
  createInvoice(newInvoiceData: $newInvoiceData, sendMail: $sendMail) {
    id
  }
}
    `;
export type CreateInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export type CreateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>, 'mutation'>;

    export const CreateInvoiceComponent = (props: CreateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> mutation={CreateInvoiceDocument} {...props} />
    );
    
export type CreateInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateInvoiceMutation, CreateInvoiceMutationVariables> & TChildProps;
export function withCreateInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  CreateInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoiceMutation, CreateInvoiceMutationVariables, CreateInvoiceProps<TChildProps>>(CreateInvoiceDocument, {
      alias: 'createInvoice',
      ...operationOptions
    });
};

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      newInvoiceData: // value for 'newInvoiceData'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const CreateInvoicesByCourseIdDocument = gql`
    mutation CreateInvoicesByCourseId($courseId: String!, $sendMail: Boolean) {
  createInvoicesByCourseId(courseId: $courseId, sendMail: $sendMail) {
    id
  }
}
    `;
export type CreateInvoicesByCourseIdMutationFn = ApolloReactCommon.MutationFunction<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables>;
export type CreateInvoicesByCourseIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables>, 'mutation'>;

    export const CreateInvoicesByCourseIdComponent = (props: CreateInvoicesByCourseIdComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables> mutation={CreateInvoicesByCourseIdDocument} {...props} />
    );
    
export type CreateInvoicesByCourseIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables> & TChildProps;
export function withCreateInvoicesByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoicesByCourseIdMutation,
  CreateInvoicesByCourseIdMutationVariables,
  CreateInvoicesByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables, CreateInvoicesByCourseIdProps<TChildProps>>(CreateInvoicesByCourseIdDocument, {
      alias: 'createInvoicesByCourseId',
      ...operationOptions
    });
};

/**
 * __useCreateInvoicesByCourseIdMutation__
 *
 * To run a mutation, you first call `useCreateInvoicesByCourseIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoicesByCourseIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoicesByCourseIdMutation, { data, loading, error }] = useCreateInvoicesByCourseIdMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateInvoicesByCourseIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables>(CreateInvoicesByCourseIdDocument, baseOptions);
      }
export type CreateInvoicesByCourseIdMutationHookResult = ReturnType<typeof useCreateInvoicesByCourseIdMutation>;
export type CreateInvoicesByCourseIdMutationResult = ApolloReactCommon.MutationResult<CreateInvoicesByCourseIdMutation>;
export type CreateInvoicesByCourseIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoicesByCourseIdMutation, CreateInvoicesByCourseIdMutationVariables>;
export const CreateInvoicesByBookingIdsDocument = gql`
    mutation CreateInvoicesByBookingIds($bookingIds: [ID!]!, $sendMail: Boolean) {
  createInvoicesByBookingIds(bookingIds: $bookingIds, sendMail: $sendMail) {
    id
    bookingId
  }
}
    `;
export type CreateInvoicesByBookingIdsMutationFn = ApolloReactCommon.MutationFunction<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables>;
export type CreateInvoicesByBookingIdsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables>, 'mutation'>;

    export const CreateInvoicesByBookingIdsComponent = (props: CreateInvoicesByBookingIdsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables> mutation={CreateInvoicesByBookingIdsDocument} {...props} />
    );
    
export type CreateInvoicesByBookingIdsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables> & TChildProps;
export function withCreateInvoicesByBookingIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoicesByBookingIdsMutation,
  CreateInvoicesByBookingIdsMutationVariables,
  CreateInvoicesByBookingIdsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables, CreateInvoicesByBookingIdsProps<TChildProps>>(CreateInvoicesByBookingIdsDocument, {
      alias: 'createInvoicesByBookingIds',
      ...operationOptions
    });
};

/**
 * __useCreateInvoicesByBookingIdsMutation__
 *
 * To run a mutation, you first call `useCreateInvoicesByBookingIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoicesByBookingIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoicesByBookingIdsMutation, { data, loading, error }] = useCreateInvoicesByBookingIdsMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateInvoicesByBookingIdsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables>(CreateInvoicesByBookingIdsDocument, baseOptions);
      }
export type CreateInvoicesByBookingIdsMutationHookResult = ReturnType<typeof useCreateInvoicesByBookingIdsMutation>;
export type CreateInvoicesByBookingIdsMutationResult = ApolloReactCommon.MutationResult<CreateInvoicesByBookingIdsMutation>;
export type CreateInvoicesByBookingIdsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoicesByBookingIdsMutation, CreateInvoicesByBookingIdsMutationVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices($options: ArrayDataOptions) {
  invoices(options: $options) {
    existsMore
    total
    items {
      id
      invoiceBody
      invoiceFileName
    }
  }
}
    `;
export type GetInvoicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoicesQuery, GetInvoicesQueryVariables>, 'query'>;

    export const GetInvoicesComponent = (props: GetInvoicesComponentProps) => (
      <ApolloReactComponents.Query<GetInvoicesQuery, GetInvoicesQueryVariables> query={GetInvoicesDocument} {...props} />
    );
    
export type GetInvoicesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoicesQuery, GetInvoicesQueryVariables> & TChildProps;
export function withGetInvoices<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  GetInvoicesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoicesQuery, GetInvoicesQueryVariables, GetInvoicesProps<TChildProps>>(GetInvoicesDocument, {
      alias: 'getInvoices',
      ...operationOptions
    });
};

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
      }
export function useGetInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = ApolloReactCommon.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetInvoiceBodyByIdDocument = gql`
    query GetInvoiceBodyById($id: String!) {
  invoiceBodyById(id: $id) {
    id
    invoiceBody
    invoiceFileName
  }
}
    `;
export type GetInvoiceBodyByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>, 'query'> & ({ variables: GetInvoiceBodyByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceBodyByIdComponent = (props: GetInvoiceBodyByIdComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables> query={GetInvoiceBodyByIdDocument} {...props} />
    );
    
export type GetInvoiceBodyByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables> & TChildProps;
export function withGetInvoiceBodyById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoiceBodyByIdQuery,
  GetInvoiceBodyByIdQueryVariables,
  GetInvoiceBodyByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables, GetInvoiceBodyByIdProps<TChildProps>>(GetInvoiceBodyByIdDocument, {
      alias: 'getInvoiceBodyById',
      ...operationOptions
    });
};

/**
 * __useGetInvoiceBodyByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceBodyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceBodyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceBodyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceBodyByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>(GetInvoiceBodyByIdDocument, baseOptions);
      }
export function useGetInvoiceBodyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>(GetInvoiceBodyByIdDocument, baseOptions);
        }
export type GetInvoiceBodyByIdQueryHookResult = ReturnType<typeof useGetInvoiceBodyByIdQuery>;
export type GetInvoiceBodyByIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceBodyByIdLazyQuery>;
export type GetInvoiceBodyByIdQueryResult = ApolloReactCommon.QueryResult<GetInvoiceBodyByIdQuery, GetInvoiceBodyByIdQueryVariables>;
export const GetInvoiceBodyByBookingIdDocument = gql`
    query GetInvoiceBodyByBookingId($bookingId: String!) {
  invoiceBodyByBookingId(bookingId: $bookingId) {
    id
    invoiceBody
    invoiceFileName
  }
}
    `;
export type GetInvoiceBodyByBookingIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>, 'query'> & ({ variables: GetInvoiceBodyByBookingIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceBodyByBookingIdComponent = (props: GetInvoiceBodyByBookingIdComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables> query={GetInvoiceBodyByBookingIdDocument} {...props} />
    );
    
export type GetInvoiceBodyByBookingIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables> & TChildProps;
export function withGetInvoiceBodyByBookingId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoiceBodyByBookingIdQuery,
  GetInvoiceBodyByBookingIdQueryVariables,
  GetInvoiceBodyByBookingIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables, GetInvoiceBodyByBookingIdProps<TChildProps>>(GetInvoiceBodyByBookingIdDocument, {
      alias: 'getInvoiceBodyByBookingId',
      ...operationOptions
    });
};

/**
 * __useGetInvoiceBodyByBookingIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceBodyByBookingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceBodyByBookingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceBodyByBookingIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetInvoiceBodyByBookingIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>(GetInvoiceBodyByBookingIdDocument, baseOptions);
      }
export function useGetInvoiceBodyByBookingIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>(GetInvoiceBodyByBookingIdDocument, baseOptions);
        }
export type GetInvoiceBodyByBookingIdQueryHookResult = ReturnType<typeof useGetInvoiceBodyByBookingIdQuery>;
export type GetInvoiceBodyByBookingIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceBodyByBookingIdLazyQuery>;
export type GetInvoiceBodyByBookingIdQueryResult = ApolloReactCommon.QueryResult<GetInvoiceBodyByBookingIdQuery, GetInvoiceBodyByBookingIdQueryVariables>;
export const GetCoursesDetailsDocument = gql`
    query GetCoursesDetails {
  courses {
    id
    settledBookings {
      id
      attendee {
        id
        firstname
        lastname
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          customerNumberPrefix
          customerNumber
        }
      }
      invoice {
        id
        billed
        createDate
        invoiceNumberPrefix
        invoiceNumber
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      canceled
      bookingType
    }
    unSettledBookings {
      id
      attendee {
        id
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          customerNumberPrefix
          customerNumber
        }
      }
      bookingNumber
      bookingNumberPrefix
      invoice {
        id
        billed
        createDate
        invoiceNumberPrefix
        invoiceNumber
      }
      canceled
      bookingType
      bookedBy
    }
  }
}
    `;
export type GetCoursesDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>, 'query'>;

    export const GetCoursesDetailsComponent = (props: GetCoursesDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables> query={GetCoursesDetailsDocument} {...props} />
    );
    
export type GetCoursesDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables> & TChildProps;
export function withGetCoursesDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCoursesDetailsQuery,
  GetCoursesDetailsQueryVariables,
  GetCoursesDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables, GetCoursesDetailsProps<TChildProps>>(GetCoursesDetailsDocument, {
      alias: 'getCoursesDetails',
      ...operationOptions
    });
};

/**
 * __useGetCoursesDetailsQuery__
 *
 * To run a query within a React component, call `useGetCoursesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoursesDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>(GetCoursesDetailsDocument, baseOptions);
      }
export function useGetCoursesDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>(GetCoursesDetailsDocument, baseOptions);
        }
export type GetCoursesDetailsQueryHookResult = ReturnType<typeof useGetCoursesDetailsQuery>;
export type GetCoursesDetailsLazyQueryHookResult = ReturnType<typeof useGetCoursesDetailsLazyQuery>;
export type GetCoursesDetailsQueryResult = ApolloReactCommon.QueryResult<GetCoursesDetailsQuery, GetCoursesDetailsQueryVariables>;
export const GetUnsettledBookingsDocument = gql`
    query GetUnsettledBookings($options: ArrayDataOptions) {
  getUnSettledBookings(options: $options) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      noticeStaff
      coursePriceId
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
        id
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        startDateTime
        endDateTime
        id
        locationId
        course {
          freePlaces
          startDateTime
          id
          prefixedCourseNumber
          allowMonthlyPayment
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          paymentMethods {
            id
            type
            cash
            esr
            iban
            bank
            bic
            sepaAccountholder
            bankTransfer
          }
          credit
        }
        defaultPaymentMethod {
          id
          cash
          esr
          iban
          bank
          bic
          sepaAccountholder
        }
      }
      paymentMethodId
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      bookingType
      canceled
      createDate
      updateDate
      waitListCount
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      bookedBy
    }
  }
}
    `;
export type GetUnsettledBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>, 'query'>;

    export const GetUnsettledBookingsComponent = (props: GetUnsettledBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables> query={GetUnsettledBookingsDocument} {...props} />
    );
    
export type GetUnsettledBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables> & TChildProps;
export function withGetUnsettledBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnsettledBookingsQuery,
  GetUnsettledBookingsQueryVariables,
  GetUnsettledBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables, GetUnsettledBookingsProps<TChildProps>>(GetUnsettledBookingsDocument, {
      alias: 'getUnsettledBookings',
      ...operationOptions
    });
};

/**
 * __useGetUnsettledBookingsQuery__
 *
 * To run a query within a React component, call `useGetUnsettledBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsettledBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsettledBookingsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUnsettledBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>(GetUnsettledBookingsDocument, baseOptions);
      }
export function useGetUnsettledBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>(GetUnsettledBookingsDocument, baseOptions);
        }
export type GetUnsettledBookingsQueryHookResult = ReturnType<typeof useGetUnsettledBookingsQuery>;
export type GetUnsettledBookingsLazyQueryHookResult = ReturnType<typeof useGetUnsettledBookingsLazyQuery>;
export type GetUnsettledBookingsQueryResult = ApolloReactCommon.QueryResult<GetUnsettledBookingsQuery, GetUnsettledBookingsQueryVariables>;
export const GetSettledBookingsDocument = gql`
    query GetSettledBookings($options: ArrayDataOptions) {
  getSettledBookings(options: $options) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      noticeStaff
      coursePriceId
      grossPrice
      netPrice
      vat
      isSpecial
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
        id
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        startDateTime
        endDateTime
        id
        locationId
        course {
          freePlaces
          startDateTime
          id
          prefixedCourseNumber
          allowMonthlyPayment
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        birthday
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          paymentMethods {
            id
            type
            cash
            esr
            iban
            bank
            bic
            sepaAccountholder
            bankTransfer
          }
          credit
        }
        defaultPaymentMethod {
          id
          cash
          esr
          iban
          bank
          bic
          sepaAccountholder
        }
      }
      paymentMethodId
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      bookingType
      canceled
      payDate
      createDate
      updateDate
      waitListCount
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
      bookedBy
    }
  }
}
    `;
export type GetSettledBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>, 'query'>;

    export const GetSettledBookingsComponent = (props: GetSettledBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetSettledBookingsQuery, GetSettledBookingsQueryVariables> query={GetSettledBookingsDocument} {...props} />
    );
    
export type GetSettledBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSettledBookingsQuery, GetSettledBookingsQueryVariables> & TChildProps;
export function withGetSettledBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSettledBookingsQuery,
  GetSettledBookingsQueryVariables,
  GetSettledBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSettledBookingsQuery, GetSettledBookingsQueryVariables, GetSettledBookingsProps<TChildProps>>(GetSettledBookingsDocument, {
      alias: 'getSettledBookings',
      ...operationOptions
    });
};

/**
 * __useGetSettledBookingsQuery__
 *
 * To run a query within a React component, call `useGetSettledBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettledBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettledBookingsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSettledBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>(GetSettledBookingsDocument, baseOptions);
      }
export function useGetSettledBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>(GetSettledBookingsDocument, baseOptions);
        }
export type GetSettledBookingsQueryHookResult = ReturnType<typeof useGetSettledBookingsQuery>;
export type GetSettledBookingsLazyQueryHookResult = ReturnType<typeof useGetSettledBookingsLazyQuery>;
export type GetSettledBookingsQueryResult = ApolloReactCommon.QueryResult<GetSettledBookingsQuery, GetSettledBookingsQueryVariables>;
export const GetCanceledBookingsDocument = gql`
    query GetCanceledBookings($options: ArrayDataOptions) {
  getCanceledBookings(options: $options) {
    existsMore
    total
    items {
      id
      bookingNumber
      bookingNumberPrefix
      bookingType
      noticeStaff
      coursePriceId
      grossPrice
      netPrice
      coursePrice {
        id
        grossPrice
        netPrice
        vat
      }
      invoice {
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
        id
      }
      reversalInvoice {
        id
        invoiceNumber
        invoiceNumberPrefix
        createDate
      }
      paymentType
      courseLessons {
        startDateTime
        endDateTime
        id
        locationId
        course {
          freePlaces
          startDateTime
          id
          prefixedCourseNumber
          allowMonthlyPayment
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      attendee {
        id
        gender
        birthday
        firstname
        lastname
        birthday
        customerNumberPrefix
        customerNumber
        booker {
          id
          firstname
          lastname
          customerNumberPrefix
          customerNumber
          street
          houseNumber
          postCode
          city
          phoneNumber
          mobileNumber
          email
          paymentMethods {
            id
            type
            cash
            esr
            iban
            bank
            bic
            sepaAccountholder
            bankTransfer
          }
        }
        defaultPaymentMethod {
          id
          cash
          esr
          iban
          bank
          bic
          sepaAccountholder
        }
      }
      paymentMethodId
      paymentMethod {
        id
        type
        cash
        esr
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
      }
      bookingType
      canceled
      payDate
      createDate
      updateDate
      waitListCount
      emailNotifications {
        id
        templateType
        subject
        createDate
        bookingId
        email
      }
      bookingConfirmationSent
      waitingListConfirmationSent
      cancelationSent
      invoiceSent
    }
  }
}
    `;
export type GetCanceledBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>, 'query'>;

    export const GetCanceledBookingsComponent = (props: GetCanceledBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables> query={GetCanceledBookingsDocument} {...props} />
    );
    
export type GetCanceledBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables> & TChildProps;
export function withGetCanceledBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCanceledBookingsQuery,
  GetCanceledBookingsQueryVariables,
  GetCanceledBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables, GetCanceledBookingsProps<TChildProps>>(GetCanceledBookingsDocument, {
      alias: 'getCanceledBookings',
      ...operationOptions
    });
};

/**
 * __useGetCanceledBookingsQuery__
 *
 * To run a query within a React component, call `useGetCanceledBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanceledBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanceledBookingsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCanceledBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>(GetCanceledBookingsDocument, baseOptions);
      }
export function useGetCanceledBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>(GetCanceledBookingsDocument, baseOptions);
        }
export type GetCanceledBookingsQueryHookResult = ReturnType<typeof useGetCanceledBookingsQuery>;
export type GetCanceledBookingsLazyQueryHookResult = ReturnType<typeof useGetCanceledBookingsLazyQuery>;
export type GetCanceledBookingsQueryResult = ApolloReactCommon.QueryResult<GetCanceledBookingsQuery, GetCanceledBookingsQueryVariables>;
export const GetBookersByCourseWithBookingTypeDocument = gql`
    query GetBookersByCourseWithBookingType($courseId: String!) {
  getBookersByCourseWithBookingType(courseId: $courseId) {
    id
    customerNumberPrefix
    customerNumber
    firstname
    lastname
    email
    gender
    phoneNumber
    email
    attendees {
      id
      firstname
      lastname
      birthday
    }
  }
}
    `;
export type GetBookersByCourseWithBookingTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>, 'query'> & ({ variables: GetBookersByCourseWithBookingTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBookersByCourseWithBookingTypeComponent = (props: GetBookersByCourseWithBookingTypeComponentProps) => (
      <ApolloReactComponents.Query<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables> query={GetBookersByCourseWithBookingTypeDocument} {...props} />
    );
    
export type GetBookersByCourseWithBookingTypeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables> & TChildProps;
export function withGetBookersByCourseWithBookingType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookersByCourseWithBookingTypeQuery,
  GetBookersByCourseWithBookingTypeQueryVariables,
  GetBookersByCourseWithBookingTypeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables, GetBookersByCourseWithBookingTypeProps<TChildProps>>(GetBookersByCourseWithBookingTypeDocument, {
      alias: 'getBookersByCourseWithBookingType',
      ...operationOptions
    });
};

/**
 * __useGetBookersByCourseWithBookingTypeQuery__
 *
 * To run a query within a React component, call `useGetBookersByCourseWithBookingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookersByCourseWithBookingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookersByCourseWithBookingTypeQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetBookersByCourseWithBookingTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>(GetBookersByCourseWithBookingTypeDocument, baseOptions);
      }
export function useGetBookersByCourseWithBookingTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>(GetBookersByCourseWithBookingTypeDocument, baseOptions);
        }
export type GetBookersByCourseWithBookingTypeQueryHookResult = ReturnType<typeof useGetBookersByCourseWithBookingTypeQuery>;
export type GetBookersByCourseWithBookingTypeLazyQueryHookResult = ReturnType<typeof useGetBookersByCourseWithBookingTypeLazyQuery>;
export type GetBookersByCourseWithBookingTypeQueryResult = ApolloReactCommon.QueryResult<GetBookersByCourseWithBookingTypeQuery, GetBookersByCourseWithBookingTypeQueryVariables>;
export const GetInvoicePreviewDocument = gql`
    query GetInvoicePreview($invoiceId: String!) {
  getInvoicePreview(invoiceId: $invoiceId)
}
    `;
export type GetInvoicePreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>, 'query'> & ({ variables: GetInvoicePreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoicePreviewComponent = (props: GetInvoicePreviewComponentProps) => (
      <ApolloReactComponents.Query<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables> query={GetInvoicePreviewDocument} {...props} />
    );
    
export type GetInvoicePreviewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables> & TChildProps;
export function withGetInvoicePreview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoicePreviewQuery,
  GetInvoicePreviewQueryVariables,
  GetInvoicePreviewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables, GetInvoicePreviewProps<TChildProps>>(GetInvoicePreviewDocument, {
      alias: 'getInvoicePreview',
      ...operationOptions
    });
};

/**
 * __useGetInvoicePreviewQuery__
 *
 * To run a query within a React component, call `useGetInvoicePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePreviewQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoicePreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>(GetInvoicePreviewDocument, baseOptions);
      }
export function useGetInvoicePreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>(GetInvoicePreviewDocument, baseOptions);
        }
export type GetInvoicePreviewQueryHookResult = ReturnType<typeof useGetInvoicePreviewQuery>;
export type GetInvoicePreviewLazyQueryHookResult = ReturnType<typeof useGetInvoicePreviewLazyQuery>;
export type GetInvoicePreviewQueryResult = ApolloReactCommon.QueryResult<GetInvoicePreviewQuery, GetInvoicePreviewQueryVariables>;
export const GetInvoiceDummyPreviewDocument = gql`
    query GetInvoiceDummyPreview($editTemplateInput: EditTemplateInput, $reversal: Boolean, $paymentType: PaymentType, $monthlySchedule: MonthlySchedule) {
  getInvoiceDummyPreview(editTemplateInput: $editTemplateInput, reversal: $reversal, paymentType: $paymentType, monthlySchedule: $monthlySchedule)
}
    `;
export type GetInvoiceDummyPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>, 'query'>;

    export const GetInvoiceDummyPreviewComponent = (props: GetInvoiceDummyPreviewComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables> query={GetInvoiceDummyPreviewDocument} {...props} />
    );
    
export type GetInvoiceDummyPreviewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables> & TChildProps;
export function withGetInvoiceDummyPreview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoiceDummyPreviewQuery,
  GetInvoiceDummyPreviewQueryVariables,
  GetInvoiceDummyPreviewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables, GetInvoiceDummyPreviewProps<TChildProps>>(GetInvoiceDummyPreviewDocument, {
      alias: 'getInvoiceDummyPreview',
      ...operationOptions
    });
};

/**
 * __useGetInvoiceDummyPreviewQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDummyPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDummyPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDummyPreviewQuery({
 *   variables: {
 *      editTemplateInput: // value for 'editTemplateInput'
 *      reversal: // value for 'reversal'
 *      paymentType: // value for 'paymentType'
 *      monthlySchedule: // value for 'monthlySchedule'
 *   },
 * });
 */
export function useGetInvoiceDummyPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>(GetInvoiceDummyPreviewDocument, baseOptions);
      }
export function useGetInvoiceDummyPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>(GetInvoiceDummyPreviewDocument, baseOptions);
        }
export type GetInvoiceDummyPreviewQueryHookResult = ReturnType<typeof useGetInvoiceDummyPreviewQuery>;
export type GetInvoiceDummyPreviewLazyQueryHookResult = ReturnType<typeof useGetInvoiceDummyPreviewLazyQuery>;
export type GetInvoiceDummyPreviewQueryResult = ApolloReactCommon.QueryResult<GetInvoiceDummyPreviewQuery, GetInvoiceDummyPreviewQueryVariables>;
export const SendBookingInvoiceDocument = gql`
    mutation SendBookingInvoice($bookingId: ID!, $reversal: Boolean) {
  sendBookingInvoice(bookingId: $bookingId, reversal: $reversal)
}
    `;
export type SendBookingInvoiceMutationFn = ApolloReactCommon.MutationFunction<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables>;
export type SendBookingInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables>, 'mutation'>;

    export const SendBookingInvoiceComponent = (props: SendBookingInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables> mutation={SendBookingInvoiceDocument} {...props} />
    );
    
export type SendBookingInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables> & TChildProps;
export function withSendBookingInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendBookingInvoiceMutation,
  SendBookingInvoiceMutationVariables,
  SendBookingInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables, SendBookingInvoiceProps<TChildProps>>(SendBookingInvoiceDocument, {
      alias: 'sendBookingInvoice',
      ...operationOptions
    });
};

/**
 * __useSendBookingInvoiceMutation__
 *
 * To run a mutation, you first call `useSendBookingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingInvoiceMutation, { data, loading, error }] = useSendBookingInvoiceMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      reversal: // value for 'reversal'
 *   },
 * });
 */
export function useSendBookingInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables>(SendBookingInvoiceDocument, baseOptions);
      }
export type SendBookingInvoiceMutationHookResult = ReturnType<typeof useSendBookingInvoiceMutation>;
export type SendBookingInvoiceMutationResult = ApolloReactCommon.MutationResult<SendBookingInvoiceMutation>;
export type SendBookingInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBookingInvoiceMutation, SendBookingInvoiceMutationVariables>;
export const SendBookingInvoicesByBookingIdsDocument = gql`
    mutation SendBookingInvoicesByBookingIds($bookingIds: [ID!]!, $reversal: Boolean) {
  sendBookingInvoicesByBookingIds(bookingIds: $bookingIds, reversal: $reversal)
}
    `;
export type SendBookingInvoicesByBookingIdsMutationFn = ApolloReactCommon.MutationFunction<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables>;
export type SendBookingInvoicesByBookingIdsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables>, 'mutation'>;

    export const SendBookingInvoicesByBookingIdsComponent = (props: SendBookingInvoicesByBookingIdsComponentProps) => (
      <ApolloReactComponents.Mutation<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables> mutation={SendBookingInvoicesByBookingIdsDocument} {...props} />
    );
    
export type SendBookingInvoicesByBookingIdsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables> & TChildProps;
export function withSendBookingInvoicesByBookingIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendBookingInvoicesByBookingIdsMutation,
  SendBookingInvoicesByBookingIdsMutationVariables,
  SendBookingInvoicesByBookingIdsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables, SendBookingInvoicesByBookingIdsProps<TChildProps>>(SendBookingInvoicesByBookingIdsDocument, {
      alias: 'sendBookingInvoicesByBookingIds',
      ...operationOptions
    });
};

/**
 * __useSendBookingInvoicesByBookingIdsMutation__
 *
 * To run a mutation, you first call `useSendBookingInvoicesByBookingIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingInvoicesByBookingIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingInvoicesByBookingIdsMutation, { data, loading, error }] = useSendBookingInvoicesByBookingIdsMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *      reversal: // value for 'reversal'
 *   },
 * });
 */
export function useSendBookingInvoicesByBookingIdsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables>(SendBookingInvoicesByBookingIdsDocument, baseOptions);
      }
export type SendBookingInvoicesByBookingIdsMutationHookResult = ReturnType<typeof useSendBookingInvoicesByBookingIdsMutation>;
export type SendBookingInvoicesByBookingIdsMutationResult = ApolloReactCommon.MutationResult<SendBookingInvoicesByBookingIdsMutation>;
export type SendBookingInvoicesByBookingIdsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBookingInvoicesByBookingIdsMutation, SendBookingInvoicesByBookingIdsMutationVariables>;
export const CreateCorrectionInvoiceDocument = gql`
    mutation CreateCorrectionInvoice($bookingId: String!, $sendMail: Boolean) {
  createCorrectionInvoice(bookingId: $bookingId, sendMail: $sendMail) {
    id
    invoiceFileName
  }
}
    `;
export type CreateCorrectionInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables>;
export type CreateCorrectionInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables>, 'mutation'>;

    export const CreateCorrectionInvoiceComponent = (props: CreateCorrectionInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables> mutation={CreateCorrectionInvoiceDocument} {...props} />
    );
    
export type CreateCorrectionInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables> & TChildProps;
export function withCreateCorrectionInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCorrectionInvoiceMutation,
  CreateCorrectionInvoiceMutationVariables,
  CreateCorrectionInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables, CreateCorrectionInvoiceProps<TChildProps>>(CreateCorrectionInvoiceDocument, {
      alias: 'createCorrectionInvoice',
      ...operationOptions
    });
};

/**
 * __useCreateCorrectionInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateCorrectionInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorrectionInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorrectionInvoiceMutation, { data, loading, error }] = useCreateCorrectionInvoiceMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateCorrectionInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables>(CreateCorrectionInvoiceDocument, baseOptions);
      }
export type CreateCorrectionInvoiceMutationHookResult = ReturnType<typeof useCreateCorrectionInvoiceMutation>;
export type CreateCorrectionInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateCorrectionInvoiceMutation>;
export type CreateCorrectionInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCorrectionInvoiceMutation, CreateCorrectionInvoiceMutationVariables>;
export const GetReversalInvoicesDocument = gql`
    query GetReversalInvoices($options: ArrayDataOptions) {
  reversalInvoices(options: $options) {
    existsMore
    total
    items {
      id
      reversalInvoiceBody
      reversalInvoiceFileName
    }
  }
}
    `;
export type GetReversalInvoicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>, 'query'>;

    export const GetReversalInvoicesComponent = (props: GetReversalInvoicesComponentProps) => (
      <ApolloReactComponents.Query<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables> query={GetReversalInvoicesDocument} {...props} />
    );
    
export type GetReversalInvoicesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables> & TChildProps;
export function withGetReversalInvoices<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReversalInvoicesQuery,
  GetReversalInvoicesQueryVariables,
  GetReversalInvoicesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables, GetReversalInvoicesProps<TChildProps>>(GetReversalInvoicesDocument, {
      alias: 'getReversalInvoices',
      ...operationOptions
    });
};

/**
 * __useGetReversalInvoicesQuery__
 *
 * To run a query within a React component, call `useGetReversalInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReversalInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReversalInvoicesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetReversalInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>(GetReversalInvoicesDocument, baseOptions);
      }
export function useGetReversalInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>(GetReversalInvoicesDocument, baseOptions);
        }
export type GetReversalInvoicesQueryHookResult = ReturnType<typeof useGetReversalInvoicesQuery>;
export type GetReversalInvoicesLazyQueryHookResult = ReturnType<typeof useGetReversalInvoicesLazyQuery>;
export type GetReversalInvoicesQueryResult = ApolloReactCommon.QueryResult<GetReversalInvoicesQuery, GetReversalInvoicesQueryVariables>;
export const GetReversalInvoiceBodyByIdDocument = gql`
    query GetReversalInvoiceBodyById($id: String!) {
  reversalInvoiceBodyById(id: $id) {
    id
    reversalInvoiceBody
    reversalInvoiceFileName
  }
}
    `;
export type GetReversalInvoiceBodyByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>, 'query'> & ({ variables: GetReversalInvoiceBodyByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReversalInvoiceBodyByIdComponent = (props: GetReversalInvoiceBodyByIdComponentProps) => (
      <ApolloReactComponents.Query<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables> query={GetReversalInvoiceBodyByIdDocument} {...props} />
    );
    
export type GetReversalInvoiceBodyByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables> & TChildProps;
export function withGetReversalInvoiceBodyById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReversalInvoiceBodyByIdQuery,
  GetReversalInvoiceBodyByIdQueryVariables,
  GetReversalInvoiceBodyByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables, GetReversalInvoiceBodyByIdProps<TChildProps>>(GetReversalInvoiceBodyByIdDocument, {
      alias: 'getReversalInvoiceBodyById',
      ...operationOptions
    });
};

/**
 * __useGetReversalInvoiceBodyByIdQuery__
 *
 * To run a query within a React component, call `useGetReversalInvoiceBodyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReversalInvoiceBodyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReversalInvoiceBodyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReversalInvoiceBodyByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>(GetReversalInvoiceBodyByIdDocument, baseOptions);
      }
export function useGetReversalInvoiceBodyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>(GetReversalInvoiceBodyByIdDocument, baseOptions);
        }
export type GetReversalInvoiceBodyByIdQueryHookResult = ReturnType<typeof useGetReversalInvoiceBodyByIdQuery>;
export type GetReversalInvoiceBodyByIdLazyQueryHookResult = ReturnType<typeof useGetReversalInvoiceBodyByIdLazyQuery>;
export type GetReversalInvoiceBodyByIdQueryResult = ApolloReactCommon.QueryResult<GetReversalInvoiceBodyByIdQuery, GetReversalInvoiceBodyByIdQueryVariables>;
export const CancelBookingAndGenerateReversalInvoiceDocument = gql`
    mutation CancelBookingAndGenerateReversalInvoice($id: String!, $sendMail: Boolean) {
  cancelBookingAndGenerateReversalInvoice(id: $id, sendMail: $sendMail)
}
    `;
export type CancelBookingAndGenerateReversalInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables>;
export type CancelBookingAndGenerateReversalInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables>, 'mutation'>;

    export const CancelBookingAndGenerateReversalInvoiceComponent = (props: CancelBookingAndGenerateReversalInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables> mutation={CancelBookingAndGenerateReversalInvoiceDocument} {...props} />
    );
    
export type CancelBookingAndGenerateReversalInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables> & TChildProps;
export function withCancelBookingAndGenerateReversalInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CancelBookingAndGenerateReversalInvoiceMutation,
  CancelBookingAndGenerateReversalInvoiceMutationVariables,
  CancelBookingAndGenerateReversalInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables, CancelBookingAndGenerateReversalInvoiceProps<TChildProps>>(CancelBookingAndGenerateReversalInvoiceDocument, {
      alias: 'cancelBookingAndGenerateReversalInvoice',
      ...operationOptions
    });
};

/**
 * __useCancelBookingAndGenerateReversalInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelBookingAndGenerateReversalInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingAndGenerateReversalInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingAndGenerateReversalInvoiceMutation, { data, loading, error }] = useCancelBookingAndGenerateReversalInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCancelBookingAndGenerateReversalInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables>(CancelBookingAndGenerateReversalInvoiceDocument, baseOptions);
      }
export type CancelBookingAndGenerateReversalInvoiceMutationHookResult = ReturnType<typeof useCancelBookingAndGenerateReversalInvoiceMutation>;
export type CancelBookingAndGenerateReversalInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelBookingAndGenerateReversalInvoiceMutation>;
export type CancelBookingAndGenerateReversalInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBookingAndGenerateReversalInvoiceMutation, CancelBookingAndGenerateReversalInvoiceMutationVariables>;
export const GetCourseLessonsByDateDocument = gql`
    query GetCourseLessonsByDate($date: DateTime!) {
  courseLessonsByDate(date: $date) {
    id
    startDateTime
    endDateTime
    course {
      id
      courseNumber
      prefixedCourseNumber
      courseType {
        id
        name
        color
      }
      instructors {
        id
        firstname
        lastname
        level
      }
    }
    location {
      id
      city
      name
      abbreviation
    }
    indexOfLessonInCourse
  }
}
    `;
export type GetCourseLessonsByDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByDateComponent = (props: GetCourseLessonsByDateComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables> query={GetCourseLessonsByDateDocument} {...props} />
    );
    
export type GetCourseLessonsByDateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables> & TChildProps;
export function withGetCourseLessonsByDate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByDateQuery,
  GetCourseLessonsByDateQueryVariables,
  GetCourseLessonsByDateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables, GetCourseLessonsByDateProps<TChildProps>>(GetCourseLessonsByDateDocument, {
      alias: 'getCourseLessonsByDate',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByDateQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCourseLessonsByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>(GetCourseLessonsByDateDocument, baseOptions);
      }
export function useGetCourseLessonsByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>(GetCourseLessonsByDateDocument, baseOptions);
        }
export type GetCourseLessonsByDateQueryHookResult = ReturnType<typeof useGetCourseLessonsByDateQuery>;
export type GetCourseLessonsByDateLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByDateLazyQuery>;
export type GetCourseLessonsByDateQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByDateQuery, GetCourseLessonsByDateQueryVariables>;
export const GetCourseLessonsWithPaginationDocument = gql`
    query GetCourseLessonsWithPagination($date: DateTime, $options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  courseLessonsWithPagination(date: $date, options: $options, onlyCurrentUser: $onlyCurrentUser) {
    existsMore
    total
    items {
      id
      startDateTime
      endDateTime
      course {
        id
        courseNumber
        prefixedCourseNumber
        courseType {
          id
          name
          color
        }
        bookingCount
      }
      location {
        id
        city
        name
        abbreviation
      }
      instructors {
        id
        firstname
        lastname
        level
      }
      indexOfLessonInCourse
    }
  }
}
    `;
export type GetCourseLessonsWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>, 'query'>;

    export const GetCourseLessonsWithPaginationComponent = (props: GetCourseLessonsWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables> query={GetCourseLessonsWithPaginationDocument} {...props} />
    );
    
export type GetCourseLessonsWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables> & TChildProps;
export function withGetCourseLessonsWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsWithPaginationQuery,
  GetCourseLessonsWithPaginationQueryVariables,
  GetCourseLessonsWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables, GetCourseLessonsWithPaginationProps<TChildProps>>(GetCourseLessonsWithPaginationDocument, {
      alias: 'getCourseLessonsWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsWithPaginationQuery({
 *   variables: {
 *      date: // value for 'date'
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetCourseLessonsWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>(GetCourseLessonsWithPaginationDocument, baseOptions);
      }
export function useGetCourseLessonsWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>(GetCourseLessonsWithPaginationDocument, baseOptions);
        }
export type GetCourseLessonsWithPaginationQueryHookResult = ReturnType<typeof useGetCourseLessonsWithPaginationQuery>;
export type GetCourseLessonsWithPaginationLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsWithPaginationLazyQuery>;
export type GetCourseLessonsWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsWithPaginationQuery, GetCourseLessonsWithPaginationQueryVariables>;
export const GetCourseLessonsForMonthViewDocument = gql`
    query GetCourseLessonsForMonthView($month: Float!, $year: Float!, $options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  courseLessonsForMonthView(month: $month, year: $year, options: $options, onlyCurrentUser: $onlyCurrentUser) {
    id
    startDateTime
    course {
      id
      prefixedCourseNumber
      courseType {
        id
        color
      }
    }
  }
}
    `;
export type GetCourseLessonsForMonthViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>, 'query'> & ({ variables: GetCourseLessonsForMonthViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsForMonthViewComponent = (props: GetCourseLessonsForMonthViewComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables> query={GetCourseLessonsForMonthViewDocument} {...props} />
    );
    
export type GetCourseLessonsForMonthViewProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables> & TChildProps;
export function withGetCourseLessonsForMonthView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsForMonthViewQuery,
  GetCourseLessonsForMonthViewQueryVariables,
  GetCourseLessonsForMonthViewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables, GetCourseLessonsForMonthViewProps<TChildProps>>(GetCourseLessonsForMonthViewDocument, {
      alias: 'getCourseLessonsForMonthView',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsForMonthViewQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsForMonthViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsForMonthViewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsForMonthViewQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetCourseLessonsForMonthViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>(GetCourseLessonsForMonthViewDocument, baseOptions);
      }
export function useGetCourseLessonsForMonthViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>(GetCourseLessonsForMonthViewDocument, baseOptions);
        }
export type GetCourseLessonsForMonthViewQueryHookResult = ReturnType<typeof useGetCourseLessonsForMonthViewQuery>;
export type GetCourseLessonsForMonthViewLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsForMonthViewLazyQuery>;
export type GetCourseLessonsForMonthViewQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsForMonthViewQuery, GetCourseLessonsForMonthViewQueryVariables>;
export const GetCourseLessonsByCalendarWeekDocument = gql`
    query GetCourseLessonsByCalendarWeek($calendarWeek: Float!, $year: Float!, $options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  courseLessonsByCalendarWeek(calendarWeek: $calendarWeek, year: $year, options: $options, onlyCurrentUser: $onlyCurrentUser) {
    id
    startDateTime
    endDateTime
    course {
      id
      courseNumber
      prefixedCourseNumber
      courseType {
        id
        name
        color
      }
    }
    location {
      id
      city
      name
      abbreviation
    }
    indexOfLessonInCourse
    instructors {
      id
      firstname
      lastname
      level
    }
  }
}
    `;
export type GetCourseLessonsByCalendarWeekComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByCalendarWeekQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByCalendarWeekComponent = (props: GetCourseLessonsByCalendarWeekComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables> query={GetCourseLessonsByCalendarWeekDocument} {...props} />
    );
    
export type GetCourseLessonsByCalendarWeekProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables> & TChildProps;
export function withGetCourseLessonsByCalendarWeek<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByCalendarWeekQuery,
  GetCourseLessonsByCalendarWeekQueryVariables,
  GetCourseLessonsByCalendarWeekProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables, GetCourseLessonsByCalendarWeekProps<TChildProps>>(GetCourseLessonsByCalendarWeekDocument, {
      alias: 'getCourseLessonsByCalendarWeek',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByCalendarWeekQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCalendarWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCalendarWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCalendarWeekQuery({
 *   variables: {
 *      calendarWeek: // value for 'calendarWeek'
 *      year: // value for 'year'
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetCourseLessonsByCalendarWeekQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>(GetCourseLessonsByCalendarWeekDocument, baseOptions);
      }
export function useGetCourseLessonsByCalendarWeekLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>(GetCourseLessonsByCalendarWeekDocument, baseOptions);
        }
export type GetCourseLessonsByCalendarWeekQueryHookResult = ReturnType<typeof useGetCourseLessonsByCalendarWeekQuery>;
export type GetCourseLessonsByCalendarWeekLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCalendarWeekLazyQuery>;
export type GetCourseLessonsByCalendarWeekQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCalendarWeekQuery, GetCourseLessonsByCalendarWeekQueryVariables>;
export const GetCourseLessonsByCourseIdAttendanceDocument = gql`
    query GetCourseLessonsByCourseIdAttendance($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    endDateTime
    course {
      id
      courseNumber
      prefixedCourseNumber
      courseType {
        id
        name
        color
      }
      instructors {
        id
        firstname
        lastname
        level
      }
    }
    location {
      id
      city
      name
      abbreviation
    }
    indexOfLessonInCourse
  }
}
    `;
export type GetCourseLessonsByCourseIdAttendanceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>, 'query'> & ({ variables: GetCourseLessonsByCourseIdAttendanceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsByCourseIdAttendanceComponent = (props: GetCourseLessonsByCourseIdAttendanceComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables> query={GetCourseLessonsByCourseIdAttendanceDocument} {...props} />
    );
    
export type GetCourseLessonsByCourseIdAttendanceProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables> & TChildProps;
export function withGetCourseLessonsByCourseIdAttendance<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsByCourseIdAttendanceQuery,
  GetCourseLessonsByCourseIdAttendanceQueryVariables,
  GetCourseLessonsByCourseIdAttendanceProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables, GetCourseLessonsByCourseIdAttendanceProps<TChildProps>>(GetCourseLessonsByCourseIdAttendanceDocument, {
      alias: 'getCourseLessonsByCourseIdAttendance',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsByCourseIdAttendanceQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCourseIdAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCourseIdAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCourseIdAttendanceQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseLessonsByCourseIdAttendanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>(GetCourseLessonsByCourseIdAttendanceDocument, baseOptions);
      }
export function useGetCourseLessonsByCourseIdAttendanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>(GetCourseLessonsByCourseIdAttendanceDocument, baseOptions);
        }
export type GetCourseLessonsByCourseIdAttendanceQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdAttendanceQuery>;
export type GetCourseLessonsByCourseIdAttendanceLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdAttendanceLazyQuery>;
export type GetCourseLessonsByCourseIdAttendanceQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCourseIdAttendanceQuery, GetCourseLessonsByCourseIdAttendanceQueryVariables>;
export const GetCourseLessonsWithCollitionsDocument = gql`
    query GetCourseLessonsWithCollitions($collitionCheckInput: CollitionCheckInput!) {
  courseLessonsWithCollitions(collitionCheckInput: $collitionCheckInput) {
    id
    startDateTime
    endDateTime
    course {
      id
      prefixedCourseNumber
    }
    indexOfLessonInCourse
    originalCourseLessonId
  }
}
    `;
export type GetCourseLessonsWithCollitionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>, 'query'> & ({ variables: GetCourseLessonsWithCollitionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonsWithCollitionsComponent = (props: GetCourseLessonsWithCollitionsComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables> query={GetCourseLessonsWithCollitionsDocument} {...props} />
    );
    
export type GetCourseLessonsWithCollitionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables> & TChildProps;
export function withGetCourseLessonsWithCollitions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonsWithCollitionsQuery,
  GetCourseLessonsWithCollitionsQueryVariables,
  GetCourseLessonsWithCollitionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables, GetCourseLessonsWithCollitionsProps<TChildProps>>(GetCourseLessonsWithCollitionsDocument, {
      alias: 'getCourseLessonsWithCollitions',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonsWithCollitionsQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsWithCollitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsWithCollitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsWithCollitionsQuery({
 *   variables: {
 *      collitionCheckInput: // value for 'collitionCheckInput'
 *   },
 * });
 */
export function useGetCourseLessonsWithCollitionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>(GetCourseLessonsWithCollitionsDocument, baseOptions);
      }
export function useGetCourseLessonsWithCollitionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>(GetCourseLessonsWithCollitionsDocument, baseOptions);
        }
export type GetCourseLessonsWithCollitionsQueryHookResult = ReturnType<typeof useGetCourseLessonsWithCollitionsQuery>;
export type GetCourseLessonsWithCollitionsLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsWithCollitionsLazyQuery>;
export type GetCourseLessonsWithCollitionsQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsWithCollitionsQuery, GetCourseLessonsWithCollitionsQueryVariables>;
export const GetMultipleCourseLessonsCollisionCheckDocument = gql`
    query GetMultipleCourseLessonsCollisionCheck($collitionCheckInput: [CollitionCheckInput!]!) {
  multipleCourseLessonsCollisionCheck(collitionCheckInput: $collitionCheckInput) {
    id
    startDateTime
    endDateTime
    course {
      id
      prefixedCourseNumber
    }
    indexOfLessonInCourse
    originalCourseLessonId
  }
}
    `;
export type GetMultipleCourseLessonsCollisionCheckComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>, 'query'> & ({ variables: GetMultipleCourseLessonsCollisionCheckQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMultipleCourseLessonsCollisionCheckComponent = (props: GetMultipleCourseLessonsCollisionCheckComponentProps) => (
      <ApolloReactComponents.Query<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables> query={GetMultipleCourseLessonsCollisionCheckDocument} {...props} />
    );
    
export type GetMultipleCourseLessonsCollisionCheckProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables> & TChildProps;
export function withGetMultipleCourseLessonsCollisionCheck<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMultipleCourseLessonsCollisionCheckQuery,
  GetMultipleCourseLessonsCollisionCheckQueryVariables,
  GetMultipleCourseLessonsCollisionCheckProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables, GetMultipleCourseLessonsCollisionCheckProps<TChildProps>>(GetMultipleCourseLessonsCollisionCheckDocument, {
      alias: 'getMultipleCourseLessonsCollisionCheck',
      ...operationOptions
    });
};

/**
 * __useGetMultipleCourseLessonsCollisionCheckQuery__
 *
 * To run a query within a React component, call `useGetMultipleCourseLessonsCollisionCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleCourseLessonsCollisionCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleCourseLessonsCollisionCheckQuery({
 *   variables: {
 *      collitionCheckInput: // value for 'collitionCheckInput'
 *   },
 * });
 */
export function useGetMultipleCourseLessonsCollisionCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>(GetMultipleCourseLessonsCollisionCheckDocument, baseOptions);
      }
export function useGetMultipleCourseLessonsCollisionCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>(GetMultipleCourseLessonsCollisionCheckDocument, baseOptions);
        }
export type GetMultipleCourseLessonsCollisionCheckQueryHookResult = ReturnType<typeof useGetMultipleCourseLessonsCollisionCheckQuery>;
export type GetMultipleCourseLessonsCollisionCheckLazyQueryHookResult = ReturnType<typeof useGetMultipleCourseLessonsCollisionCheckLazyQuery>;
export type GetMultipleCourseLessonsCollisionCheckQueryResult = ApolloReactCommon.QueryResult<GetMultipleCourseLessonsCollisionCheckQuery, GetMultipleCourseLessonsCollisionCheckQueryVariables>;
export const GetCourseLessonByIdDocument = gql`
    query GetCourseLessonById($id: String!) {
  courseLessonById(id: $id) {
    id
    startDateTime
    endDateTime
    course {
      id
      courseNumber
      prefixedCourseNumber
      courseType {
        id
        name
        color
      }
      instructors {
        id
        firstname
        lastname
        level
      }
      freePlaces
      waitListCount
      bookingCount
      placeReservationsCount
      lessonCount
      maxAttendees
      maxWaitlist
      startDateTime
      endDateTime
    }
    location {
      id
      city
      name
      abbreviation
    }
    indexOfLessonInCourse
  }
}
    `;
export type GetCourseLessonByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>, 'query'> & ({ variables: GetCourseLessonByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCourseLessonByIdComponent = (props: GetCourseLessonByIdComponentProps) => (
      <ApolloReactComponents.Query<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables> query={GetCourseLessonByIdDocument} {...props} />
    );
    
export type GetCourseLessonByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables> & TChildProps;
export function withGetCourseLessonById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseLessonByIdQuery,
  GetCourseLessonByIdQueryVariables,
  GetCourseLessonByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables, GetCourseLessonByIdProps<TChildProps>>(GetCourseLessonByIdDocument, {
      alias: 'getCourseLessonById',
      ...operationOptions
    });
};

/**
 * __useGetCourseLessonByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseLessonByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>(GetCourseLessonByIdDocument, baseOptions);
      }
export function useGetCourseLessonByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>(GetCourseLessonByIdDocument, baseOptions);
        }
export type GetCourseLessonByIdQueryHookResult = ReturnType<typeof useGetCourseLessonByIdQuery>;
export type GetCourseLessonByIdLazyQueryHookResult = ReturnType<typeof useGetCourseLessonByIdLazyQuery>;
export type GetCourseLessonByIdQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonByIdQuery, GetCourseLessonByIdQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    id
    abbreviation
    city
    name
    venues {
      id
      name
    }
    generalLocationContact {
      email
      phoneNumber
    }
    courses {
      id
      prefixedCourseNumber
    }
  }
}
    `;
export type GetLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsQuery, GetLocationsQueryVariables>, 'query'>;

    export const GetLocationsComponent = (props: GetLocationsComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsQuery, GetLocationsQueryVariables> query={GetLocationsDocument} {...props} />
    );
    
export type GetLocationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsQuery, GetLocationsQueryVariables> & TChildProps;
export function withGetLocations<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsQuery,
  GetLocationsQueryVariables,
  GetLocationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsQuery, GetLocationsQueryVariables, GetLocationsProps<TChildProps>>(GetLocationsDocument, {
      alias: 'getLocations',
      ...operationOptions
    });
};

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
      }
export function useGetLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = ApolloReactCommon.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationsWithPaginationDocument = gql`
    query GetLocationsWithPagination($options: ArrayDataOptions, $currentUserOnly: Boolean) {
  locationsWithPagination(options: $options, currentUserOnly: $currentUserOnly) {
    existsMore
    total
    items {
      id
      abbreviation
      city
      name
      venues {
        id
        name
      }
      generalLocationContact {
        email
        phoneNumber
      }
      courses {
        id
        prefixedCourseNumber
      }
      instructors {
        id
        firstname
        lastname
      }
    }
  }
}
    `;
export type GetLocationsWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>, 'query'>;

    export const GetLocationsWithPaginationComponent = (props: GetLocationsWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables> query={GetLocationsWithPaginationDocument} {...props} />
    );
    
export type GetLocationsWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables> & TChildProps;
export function withGetLocationsWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsWithPaginationQuery,
  GetLocationsWithPaginationQueryVariables,
  GetLocationsWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables, GetLocationsWithPaginationProps<TChildProps>>(GetLocationsWithPaginationDocument, {
      alias: 'getLocationsWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetLocationsWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetLocationsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsWithPaginationQuery({
 *   variables: {
 *      options: // value for 'options'
 *      currentUserOnly: // value for 'currentUserOnly'
 *   },
 * });
 */
export function useGetLocationsWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>(GetLocationsWithPaginationDocument, baseOptions);
      }
export function useGetLocationsWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>(GetLocationsWithPaginationDocument, baseOptions);
        }
export type GetLocationsWithPaginationQueryHookResult = ReturnType<typeof useGetLocationsWithPaginationQuery>;
export type GetLocationsWithPaginationLazyQueryHookResult = ReturnType<typeof useGetLocationsWithPaginationLazyQuery>;
export type GetLocationsWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetLocationsWithPaginationQuery, GetLocationsWithPaginationQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($newLocationData: NewLocationInput!) {
  createLocation(newLocationData: $newLocationData) {
    id
    name
    isPublished
    zipCode
    city
    street
    website
    abbreviation
    info
    venues {
      id
      name
      isActive
      rent
      rentType
    }
    generalLocationContact {
      phoneNumber
      mobileNumber
      fax
      email
      note
    }
    locationContacts {
      id
      firstname
      lastname
      phoneNumber
      mobileNumber
      fax
      email
    }
  }
}
    `;
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;
export type CreateLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLocationMutation, CreateLocationMutationVariables>, 'mutation'>;

    export const CreateLocationComponent = (props: CreateLocationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLocationMutation, CreateLocationMutationVariables> mutation={CreateLocationDocument} {...props} />
    );
    
export type CreateLocationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateLocationMutation, CreateLocationMutationVariables> & TChildProps;
export function withCreateLocation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateLocationMutation,
  CreateLocationMutationVariables,
  CreateLocationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateLocationMutation, CreateLocationMutationVariables, CreateLocationProps<TChildProps>>(CreateLocationDocument, {
      alias: 'createLocation',
      ...operationOptions
    });
};

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      newLocationData: // value for 'newLocationData'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const GetLocationByIdDocument = gql`
    query GetLocationById($id: ID!) {
  locationById(id: $id) {
    id
    name
    isPublished
    zipCode
    city
    street
    website
    abbreviation
    info
    venues {
      id
      name
      isActive
      rent
      rentType
    }
    generalLocationContact {
      phoneNumber
      mobileNumber
      fax
      email
      note
    }
    locationContacts {
      id
      salutation
      firstname
      lastname
      department
      phoneNumber
      mobileNumber
      fax
      email
      note
    }
    courses {
      id
      locationId
      venueId
      courseNumber
    }
    customFields
  }
}
    `;
export type GetLocationByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>, 'query'> & ({ variables: GetLocationByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationByIdComponent = (props: GetLocationByIdComponentProps) => (
      <ApolloReactComponents.Query<GetLocationByIdQuery, GetLocationByIdQueryVariables> query={GetLocationByIdDocument} {...props} />
    );
    
export type GetLocationByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationByIdQuery, GetLocationByIdQueryVariables> & TChildProps;
export function withGetLocationById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationByIdQuery,
  GetLocationByIdQueryVariables,
  GetLocationByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationByIdQuery, GetLocationByIdQueryVariables, GetLocationByIdProps<TChildProps>>(GetLocationByIdDocument, {
      alias: 'getLocationById',
      ...operationOptions
    });
};

/**
 * __useGetLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, baseOptions);
      }
export function useGetLocationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, baseOptions);
        }
export type GetLocationByIdQueryHookResult = ReturnType<typeof useGetLocationByIdQuery>;
export type GetLocationByIdLazyQueryHookResult = ReturnType<typeof useGetLocationByIdLazyQuery>;
export type GetLocationByIdQueryResult = ApolloReactCommon.QueryResult<GetLocationByIdQuery, GetLocationByIdQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($editLocationData: EditLocationInput!) {
  updateLocation(editLocationData: $editLocationData) {
    id
    name
    isPublished
    zipCode
    city
    street
    website
    abbreviation
    info
    venues {
      id
      name
      isActive
      rent
      rentType
    }
    generalLocationContact {
      phoneNumber
      mobileNumber
      fax
      email
      note
    }
    locationContacts {
      id
      salutation
      firstname
      lastname
      department
      phoneNumber
      mobileNumber
      fax
      email
      note
    }
  }
}
    `;
export type UpdateLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;
export type UpdateLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLocationMutation, UpdateLocationMutationVariables>, 'mutation'>;

    export const UpdateLocationComponent = (props: UpdateLocationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLocationMutation, UpdateLocationMutationVariables> mutation={UpdateLocationDocument} {...props} />
    );
    
export type UpdateLocationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateLocationMutation, UpdateLocationMutationVariables> & TChildProps;
export function withUpdateLocation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLocationMutation,
  UpdateLocationMutationVariables,
  UpdateLocationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLocationMutation, UpdateLocationMutationVariables, UpdateLocationProps<TChildProps>>(UpdateLocationDocument, {
      alias: 'updateLocation',
      ...operationOptions
    });
};

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      editLocationData: // value for 'editLocationData'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const RemoveLocationByIdDocument = gql`
    mutation RemoveLocationById($id: ID!) {
  removeLocation(id: $id)
}
    `;
export type RemoveLocationByIdMutationFn = ApolloReactCommon.MutationFunction<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables>;
export type RemoveLocationByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables>, 'mutation'>;

    export const RemoveLocationByIdComponent = (props: RemoveLocationByIdComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables> mutation={RemoveLocationByIdDocument} {...props} />
    );
    
export type RemoveLocationByIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables> & TChildProps;
export function withRemoveLocationById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveLocationByIdMutation,
  RemoveLocationByIdMutationVariables,
  RemoveLocationByIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables, RemoveLocationByIdProps<TChildProps>>(RemoveLocationByIdDocument, {
      alias: 'removeLocationById',
      ...operationOptions
    });
};

/**
 * __useRemoveLocationByIdMutation__
 *
 * To run a mutation, you first call `useRemoveLocationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationByIdMutation, { data, loading, error }] = useRemoveLocationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLocationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables>(RemoveLocationByIdDocument, baseOptions);
      }
export type RemoveLocationByIdMutationHookResult = ReturnType<typeof useRemoveLocationByIdMutation>;
export type RemoveLocationByIdMutationResult = ApolloReactCommon.MutationResult<RemoveLocationByIdMutation>;
export type RemoveLocationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLocationByIdMutation, RemoveLocationByIdMutationVariables>;
export const CreateLocationContactDocument = gql`
    mutation CreateLocationContact($newLocationContactData: NewLocationContactInput!) {
  createLocationContact(newLocationContactData: $newLocationContactData) {
    id
    salutation
    firstname
    lastname
    department
    phoneNumber
    mobileNumber
    fax
    email
    note
  }
}
    `;
export type CreateLocationContactMutationFn = ApolloReactCommon.MutationFunction<CreateLocationContactMutation, CreateLocationContactMutationVariables>;
export type CreateLocationContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLocationContactMutation, CreateLocationContactMutationVariables>, 'mutation'>;

    export const CreateLocationContactComponent = (props: CreateLocationContactComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLocationContactMutation, CreateLocationContactMutationVariables> mutation={CreateLocationContactDocument} {...props} />
    );
    
export type CreateLocationContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateLocationContactMutation, CreateLocationContactMutationVariables> & TChildProps;
export function withCreateLocationContact<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateLocationContactMutation,
  CreateLocationContactMutationVariables,
  CreateLocationContactProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateLocationContactMutation, CreateLocationContactMutationVariables, CreateLocationContactProps<TChildProps>>(CreateLocationContactDocument, {
      alias: 'createLocationContact',
      ...operationOptions
    });
};

/**
 * __useCreateLocationContactMutation__
 *
 * To run a mutation, you first call `useCreateLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationContactMutation, { data, loading, error }] = useCreateLocationContactMutation({
 *   variables: {
 *      newLocationContactData: // value for 'newLocationContactData'
 *   },
 * });
 */
export function useCreateLocationContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationContactMutation, CreateLocationContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLocationContactMutation, CreateLocationContactMutationVariables>(CreateLocationContactDocument, baseOptions);
      }
export type CreateLocationContactMutationHookResult = ReturnType<typeof useCreateLocationContactMutation>;
export type CreateLocationContactMutationResult = ApolloReactCommon.MutationResult<CreateLocationContactMutation>;
export type CreateLocationContactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLocationContactMutation, CreateLocationContactMutationVariables>;
export const UpdateLocationContactDocument = gql`
    mutation UpdateLocationContact($editLocationContactData: EditLocationContactInput!) {
  updateLocationContact(editLocationContactData: $editLocationContactData) {
    id
    salutation
    firstname
    lastname
    department
    phoneNumber
    mobileNumber
    fax
    email
    note
  }
}
    `;
export type UpdateLocationContactMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationContactMutation, UpdateLocationContactMutationVariables>;
export type UpdateLocationContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLocationContactMutation, UpdateLocationContactMutationVariables>, 'mutation'>;

    export const UpdateLocationContactComponent = (props: UpdateLocationContactComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLocationContactMutation, UpdateLocationContactMutationVariables> mutation={UpdateLocationContactDocument} {...props} />
    );
    
export type UpdateLocationContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateLocationContactMutation, UpdateLocationContactMutationVariables> & TChildProps;
export function withUpdateLocationContact<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLocationContactMutation,
  UpdateLocationContactMutationVariables,
  UpdateLocationContactProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLocationContactMutation, UpdateLocationContactMutationVariables, UpdateLocationContactProps<TChildProps>>(UpdateLocationContactDocument, {
      alias: 'updateLocationContact',
      ...operationOptions
    });
};

/**
 * __useUpdateLocationContactMutation__
 *
 * To run a mutation, you first call `useUpdateLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationContactMutation, { data, loading, error }] = useUpdateLocationContactMutation({
 *   variables: {
 *      editLocationContactData: // value for 'editLocationContactData'
 *   },
 * });
 */
export function useUpdateLocationContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationContactMutation, UpdateLocationContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationContactMutation, UpdateLocationContactMutationVariables>(UpdateLocationContactDocument, baseOptions);
      }
export type UpdateLocationContactMutationHookResult = ReturnType<typeof useUpdateLocationContactMutation>;
export type UpdateLocationContactMutationResult = ApolloReactCommon.MutationResult<UpdateLocationContactMutation>;
export type UpdateLocationContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationContactMutation, UpdateLocationContactMutationVariables>;
export const GetLocationContactsDocument = gql`
    query GetLocationContacts {
  locationContacts {
    id
    salutation
    firstname
    lastname
    department
    phoneNumber
    mobileNumber
    email
    fax
    note
  }
}
    `;
export type GetLocationContactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationContactsQuery, GetLocationContactsQueryVariables>, 'query'>;

    export const GetLocationContactsComponent = (props: GetLocationContactsComponentProps) => (
      <ApolloReactComponents.Query<GetLocationContactsQuery, GetLocationContactsQueryVariables> query={GetLocationContactsDocument} {...props} />
    );
    
export type GetLocationContactsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationContactsQuery, GetLocationContactsQueryVariables> & TChildProps;
export function withGetLocationContacts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationContactsQuery,
  GetLocationContactsQueryVariables,
  GetLocationContactsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationContactsQuery, GetLocationContactsQueryVariables, GetLocationContactsProps<TChildProps>>(GetLocationContactsDocument, {
      alias: 'getLocationContacts',
      ...operationOptions
    });
};

/**
 * __useGetLocationContactsQuery__
 *
 * To run a query within a React component, call `useGetLocationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationContactsQuery, GetLocationContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationContactsQuery, GetLocationContactsQueryVariables>(GetLocationContactsDocument, baseOptions);
      }
export function useGetLocationContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationContactsQuery, GetLocationContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationContactsQuery, GetLocationContactsQueryVariables>(GetLocationContactsDocument, baseOptions);
        }
export type GetLocationContactsQueryHookResult = ReturnType<typeof useGetLocationContactsQuery>;
export type GetLocationContactsLazyQueryHookResult = ReturnType<typeof useGetLocationContactsLazyQuery>;
export type GetLocationContactsQueryResult = ApolloReactCommon.QueryResult<GetLocationContactsQuery, GetLocationContactsQueryVariables>;
export const GetLocationsByLocationContactIdDocument = gql`
    query GetLocationsByLocationContactId($id: ID!) {
  locationsByLocationContactId(id: $id) {
    name
  }
}
    `;
export type GetLocationsByLocationContactIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>, 'query'> & ({ variables: GetLocationsByLocationContactIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationsByLocationContactIdComponent = (props: GetLocationsByLocationContactIdComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables> query={GetLocationsByLocationContactIdDocument} {...props} />
    );
    
export type GetLocationsByLocationContactIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables> & TChildProps;
export function withGetLocationsByLocationContactId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsByLocationContactIdQuery,
  GetLocationsByLocationContactIdQueryVariables,
  GetLocationsByLocationContactIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables, GetLocationsByLocationContactIdProps<TChildProps>>(GetLocationsByLocationContactIdDocument, {
      alias: 'getLocationsByLocationContactId',
      ...operationOptions
    });
};

/**
 * __useGetLocationsByLocationContactIdQuery__
 *
 * To run a query within a React component, call `useGetLocationsByLocationContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByLocationContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByLocationContactIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationsByLocationContactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>(GetLocationsByLocationContactIdDocument, baseOptions);
      }
export function useGetLocationsByLocationContactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>(GetLocationsByLocationContactIdDocument, baseOptions);
        }
export type GetLocationsByLocationContactIdQueryHookResult = ReturnType<typeof useGetLocationsByLocationContactIdQuery>;
export type GetLocationsByLocationContactIdLazyQueryHookResult = ReturnType<typeof useGetLocationsByLocationContactIdLazyQuery>;
export type GetLocationsByLocationContactIdQueryResult = ApolloReactCommon.QueryResult<GetLocationsByLocationContactIdQuery, GetLocationsByLocationContactIdQueryVariables>;
export const RemoveLocationContactDocument = gql`
    mutation RemoveLocationContact($id: ID!) {
  removeLocationContact(id: $id)
}
    `;
export type RemoveLocationContactMutationFn = ApolloReactCommon.MutationFunction<RemoveLocationContactMutation, RemoveLocationContactMutationVariables>;
export type RemoveLocationContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveLocationContactMutation, RemoveLocationContactMutationVariables>, 'mutation'>;

    export const RemoveLocationContactComponent = (props: RemoveLocationContactComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveLocationContactMutation, RemoveLocationContactMutationVariables> mutation={RemoveLocationContactDocument} {...props} />
    );
    
export type RemoveLocationContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveLocationContactMutation, RemoveLocationContactMutationVariables> & TChildProps;
export function withRemoveLocationContact<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveLocationContactMutation,
  RemoveLocationContactMutationVariables,
  RemoveLocationContactProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveLocationContactMutation, RemoveLocationContactMutationVariables, RemoveLocationContactProps<TChildProps>>(RemoveLocationContactDocument, {
      alias: 'removeLocationContact',
      ...operationOptions
    });
};

/**
 * __useRemoveLocationContactMutation__
 *
 * To run a mutation, you first call `useRemoveLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationContactMutation, { data, loading, error }] = useRemoveLocationContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLocationContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLocationContactMutation, RemoveLocationContactMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveLocationContactMutation, RemoveLocationContactMutationVariables>(RemoveLocationContactDocument, baseOptions);
      }
export type RemoveLocationContactMutationHookResult = ReturnType<typeof useRemoveLocationContactMutation>;
export type RemoveLocationContactMutationResult = ApolloReactCommon.MutationResult<RemoveLocationContactMutation>;
export type RemoveLocationContactMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLocationContactMutation, RemoveLocationContactMutationVariables>;
export const GetLocationsPublicDocument = gql`
    query GetLocationsPublic {
  locationsPublic {
    id
    abbreviation
    city
    name
    generalLocationContact {
      email
      phoneNumber
    }
  }
}
    `;
export type GetLocationsPublicComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>, 'query'>;

    export const GetLocationsPublicComponent = (props: GetLocationsPublicComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsPublicQuery, GetLocationsPublicQueryVariables> query={GetLocationsPublicDocument} {...props} />
    );
    
export type GetLocationsPublicProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationsPublicQuery, GetLocationsPublicQueryVariables> & TChildProps;
export function withGetLocationsPublic<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationsPublicQuery,
  GetLocationsPublicQueryVariables,
  GetLocationsPublicProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationsPublicQuery, GetLocationsPublicQueryVariables, GetLocationsPublicProps<TChildProps>>(GetLocationsPublicDocument, {
      alias: 'getLocationsPublic',
      ...operationOptions
    });
};

/**
 * __useGetLocationsPublicQuery__
 *
 * To run a query within a React component, call `useGetLocationsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>(GetLocationsPublicDocument, baseOptions);
      }
export function useGetLocationsPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>(GetLocationsPublicDocument, baseOptions);
        }
export type GetLocationsPublicQueryHookResult = ReturnType<typeof useGetLocationsPublicQuery>;
export type GetLocationsPublicLazyQueryHookResult = ReturnType<typeof useGetLocationsPublicLazyQuery>;
export type GetLocationsPublicQueryResult = ApolloReactCommon.QueryResult<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>;
export const PasswordlessLoginDocument = gql`
    mutation passwordlessLogin($loginData: PasswordlessLoginInputType!) {
  passwordlessLogin(loginData: $loginData)
}
    `;
export type PasswordlessLoginMutationFn = ApolloReactCommon.MutationFunction<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;
export type PasswordlessLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>, 'mutation'>;

    export const PasswordlessLoginComponent = (props: PasswordlessLoginComponentProps) => (
      <ApolloReactComponents.Mutation<PasswordlessLoginMutation, PasswordlessLoginMutationVariables> mutation={PasswordlessLoginDocument} {...props} />
    );
    
export type PasswordlessLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PasswordlessLoginMutation, PasswordlessLoginMutationVariables> & TChildProps;
export function withPasswordlessLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PasswordlessLoginMutation,
  PasswordlessLoginMutationVariables,
  PasswordlessLoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PasswordlessLoginMutation, PasswordlessLoginMutationVariables, PasswordlessLoginProps<TChildProps>>(PasswordlessLoginDocument, {
      alias: 'passwordlessLogin',
      ...operationOptions
    });
};

/**
 * __usePasswordlessLoginMutation__
 *
 * To run a mutation, you first call `usePasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordlessLoginMutation, { data, loading, error }] = usePasswordlessLoginMutation({
 *   variables: {
 *      loginData: // value for 'loginData'
 *   },
 * });
 */
export function usePasswordlessLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>(PasswordlessLoginDocument, baseOptions);
      }
export type PasswordlessLoginMutationHookResult = ReturnType<typeof usePasswordlessLoginMutation>;
export type PasswordlessLoginMutationResult = ApolloReactCommon.MutationResult<PasswordlessLoginMutation>;
export type PasswordlessLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;
export const CompletePasswordlessFlowDocument = gql`
    mutation completePasswordlessFlow($loginCode: String!) {
  completePasswordlessFlow(loginCode: $loginCode) {
    id
    roles
    email
  }
}
    `;
export type CompletePasswordlessFlowMutationFn = ApolloReactCommon.MutationFunction<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>;
export type CompletePasswordlessFlowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>, 'mutation'>;

    export const CompletePasswordlessFlowComponent = (props: CompletePasswordlessFlowComponentProps) => (
      <ApolloReactComponents.Mutation<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables> mutation={CompletePasswordlessFlowDocument} {...props} />
    );
    
export type CompletePasswordlessFlowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables> & TChildProps;
export function withCompletePasswordlessFlow<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompletePasswordlessFlowMutation,
  CompletePasswordlessFlowMutationVariables,
  CompletePasswordlessFlowProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables, CompletePasswordlessFlowProps<TChildProps>>(CompletePasswordlessFlowDocument, {
      alias: 'completePasswordlessFlow',
      ...operationOptions
    });
};

/**
 * __useCompletePasswordlessFlowMutation__
 *
 * To run a mutation, you first call `useCompletePasswordlessFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePasswordlessFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePasswordlessFlowMutation, { data, loading, error }] = useCompletePasswordlessFlowMutation({
 *   variables: {
 *      loginCode: // value for 'loginCode'
 *   },
 * });
 */
export function useCompletePasswordlessFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>(CompletePasswordlessFlowDocument, baseOptions);
      }
export type CompletePasswordlessFlowMutationHookResult = ReturnType<typeof useCompletePasswordlessFlowMutation>;
export type CompletePasswordlessFlowMutationResult = ApolloReactCommon.MutationResult<CompletePasswordlessFlowMutation>;
export type CompletePasswordlessFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>;
export const ChangeForgottenPasswordDocument = gql`
    mutation changeForgottenPassword($changePasswordId: String!, $newPassword: String!) {
  changeForgottenPassword(changePasswordId: $changePasswordId, newPassword: $newPassword)
}
    `;
export type ChangeForgottenPasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>;
export type ChangeForgottenPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>, 'mutation'>;

    export const ChangeForgottenPasswordComponent = (props: ChangeForgottenPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables> mutation={ChangeForgottenPasswordDocument} {...props} />
    );
    
export type ChangeForgottenPasswordProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables> & TChildProps;
export function withChangeForgottenPassword<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeForgottenPasswordMutation,
  ChangeForgottenPasswordMutationVariables,
  ChangeForgottenPasswordProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables, ChangeForgottenPasswordProps<TChildProps>>(ChangeForgottenPasswordDocument, {
      alias: 'changeForgottenPassword',
      ...operationOptions
    });
};

/**
 * __useChangeForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useChangeForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeForgottenPasswordMutation, { data, loading, error }] = useChangeForgottenPasswordMutation({
 *   variables: {
 *      changePasswordId: // value for 'changePasswordId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangeForgottenPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>(ChangeForgottenPasswordDocument, baseOptions);
      }
export type ChangeForgottenPasswordMutationHookResult = ReturnType<typeof useChangeForgottenPasswordMutation>;
export type ChangeForgottenPasswordMutationResult = ApolloReactCommon.MutationResult<ChangeForgottenPasswordMutation>;
export type ChangeForgottenPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logoutUser
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;
export type LogoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogoutMutation, LogoutMutationVariables>, 'mutation'>;

    export const LogoutComponent = (props: LogoutComponentProps) => (
      <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables> mutation={LogoutDocument} {...props} />
    );
    
export type LogoutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogoutMutation, LogoutMutationVariables> & TChildProps;
export function withLogout<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogoutMutation,
  LogoutMutationVariables,
  LogoutProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogoutMutation, LogoutMutationVariables, LogoutProps<TChildProps>>(LogoutDocument, {
      alias: 'logout',
      ...operationOptions
    });
};

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetEmailNotificationsDocument = gql`
    query GetEmailNotifications($options: ArrayDataOptions) {
  emailNotifications(options: $options) {
    existsMore
    total
    items {
      id
      createDate
      status
      email
      receiverName
      subject
      templateType
      mailjetTemplateId
      messageHref
      text
      messageID
      templateName
      bookerId
      bookingId
      booking {
        id
        bookingNumber
        bookingNumberPrefix
        bookingType
        canceled
        invoice {
          id
        }
        reversalInvoice {
          id
        }
      }
      messageHistory {
        Count
        Data {
          EventAt
          EventType
          State
        }
      }
      messageStatus {
        ArrivedAt
        Status
      }
      attachments
      lastMessageStatus {
        Data {
          ArrivedAt
          Status
        }
      }
      bookingDeleted
    }
  }
}
    `;
export type GetEmailNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>, 'query'>;

    export const GetEmailNotificationsComponent = (props: GetEmailNotificationsComponentProps) => (
      <ApolloReactComponents.Query<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables> query={GetEmailNotificationsDocument} {...props} />
    );
    
export type GetEmailNotificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables> & TChildProps;
export function withGetEmailNotifications<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEmailNotificationsQuery,
  GetEmailNotificationsQueryVariables,
  GetEmailNotificationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables, GetEmailNotificationsProps<TChildProps>>(GetEmailNotificationsDocument, {
      alias: 'getEmailNotifications',
      ...operationOptions
    });
};

/**
 * __useGetEmailNotificationsQuery__
 *
 * To run a query within a React component, call `useGetEmailNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailNotificationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetEmailNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>(GetEmailNotificationsDocument, baseOptions);
      }
export function useGetEmailNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>(GetEmailNotificationsDocument, baseOptions);
        }
export type GetEmailNotificationsQueryHookResult = ReturnType<typeof useGetEmailNotificationsQuery>;
export type GetEmailNotificationsLazyQueryHookResult = ReturnType<typeof useGetEmailNotificationsLazyQuery>;
export type GetEmailNotificationsQueryResult = ApolloReactCommon.QueryResult<GetEmailNotificationsQuery, GetEmailNotificationsQueryVariables>;
export const GetEmailNotificationsByBookingIdDocument = gql`
    query GetEmailNotificationsByBookingId($bookingId: ID!) {
  notificationsByBooking(bookingId: $bookingId) {
    id
    createDate
    status
    email
    receiverName
    subject
    templateType
    mailjetTemplateId
    messageHref
    text
    messageID
    templateName
    messageHistory {
      Count
      Data {
        EventAt
        EventType
        State
      }
    }
    messageStatus {
      ArrivedAt
      Status
    }
    attachments
  }
}
    `;
export type GetEmailNotificationsByBookingIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>, 'query'> & ({ variables: GetEmailNotificationsByBookingIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmailNotificationsByBookingIdComponent = (props: GetEmailNotificationsByBookingIdComponentProps) => (
      <ApolloReactComponents.Query<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables> query={GetEmailNotificationsByBookingIdDocument} {...props} />
    );
    
export type GetEmailNotificationsByBookingIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables> & TChildProps;
export function withGetEmailNotificationsByBookingId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEmailNotificationsByBookingIdQuery,
  GetEmailNotificationsByBookingIdQueryVariables,
  GetEmailNotificationsByBookingIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables, GetEmailNotificationsByBookingIdProps<TChildProps>>(GetEmailNotificationsByBookingIdDocument, {
      alias: 'getEmailNotificationsByBookingId',
      ...operationOptions
    });
};

/**
 * __useGetEmailNotificationsByBookingIdQuery__
 *
 * To run a query within a React component, call `useGetEmailNotificationsByBookingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailNotificationsByBookingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailNotificationsByBookingIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetEmailNotificationsByBookingIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>(GetEmailNotificationsByBookingIdDocument, baseOptions);
      }
export function useGetEmailNotificationsByBookingIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>(GetEmailNotificationsByBookingIdDocument, baseOptions);
        }
export type GetEmailNotificationsByBookingIdQueryHookResult = ReturnType<typeof useGetEmailNotificationsByBookingIdQuery>;
export type GetEmailNotificationsByBookingIdLazyQueryHookResult = ReturnType<typeof useGetEmailNotificationsByBookingIdLazyQuery>;
export type GetEmailNotificationsByBookingIdQueryResult = ApolloReactCommon.QueryResult<GetEmailNotificationsByBookingIdQuery, GetEmailNotificationsByBookingIdQueryVariables>;
export const GetEmailNotificationsByBookingIdOrEmailDocument = gql`
    query GetEmailNotificationsByBookingIdOrEmail($bookerId: ID!, $email: String, $options: ArrayDataOptions) {
  notificationsByBookerOrEmail(bookerId: $bookerId, email: $email, options: $options) {
    existsMore
    total
    items {
      id
      createDate
      status
      email
      receiverName
      subject
      templateType
      mailjetTemplateId
      messageHref
      text
      messageID
      templateName
      bookerId
      bookingId
      booking {
        id
        bookingNumber
        bookingNumberPrefix
        bookingType
        canceled
        invoice {
          id
        }
        reversalInvoice {
          id
        }
      }
      messageHistory {
        Count
        Data {
          EventAt
          EventType
          State
        }
      }
      messageStatus {
        ArrivedAt
        Status
      }
      attachments
    }
  }
}
    `;
export type GetEmailNotificationsByBookingIdOrEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>, 'query'> & ({ variables: GetEmailNotificationsByBookingIdOrEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmailNotificationsByBookingIdOrEmailComponent = (props: GetEmailNotificationsByBookingIdOrEmailComponentProps) => (
      <ApolloReactComponents.Query<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables> query={GetEmailNotificationsByBookingIdOrEmailDocument} {...props} />
    );
    
export type GetEmailNotificationsByBookingIdOrEmailProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables> & TChildProps;
export function withGetEmailNotificationsByBookingIdOrEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEmailNotificationsByBookingIdOrEmailQuery,
  GetEmailNotificationsByBookingIdOrEmailQueryVariables,
  GetEmailNotificationsByBookingIdOrEmailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables, GetEmailNotificationsByBookingIdOrEmailProps<TChildProps>>(GetEmailNotificationsByBookingIdOrEmailDocument, {
      alias: 'getEmailNotificationsByBookingIdOrEmail',
      ...operationOptions
    });
};

/**
 * __useGetEmailNotificationsByBookingIdOrEmailQuery__
 *
 * To run a query within a React component, call `useGetEmailNotificationsByBookingIdOrEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailNotificationsByBookingIdOrEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailNotificationsByBookingIdOrEmailQuery({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *      email: // value for 'email'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetEmailNotificationsByBookingIdOrEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>(GetEmailNotificationsByBookingIdOrEmailDocument, baseOptions);
      }
export function useGetEmailNotificationsByBookingIdOrEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>(GetEmailNotificationsByBookingIdOrEmailDocument, baseOptions);
        }
export type GetEmailNotificationsByBookingIdOrEmailQueryHookResult = ReturnType<typeof useGetEmailNotificationsByBookingIdOrEmailQuery>;
export type GetEmailNotificationsByBookingIdOrEmailLazyQueryHookResult = ReturnType<typeof useGetEmailNotificationsByBookingIdOrEmailLazyQuery>;
export type GetEmailNotificationsByBookingIdOrEmailQueryResult = ApolloReactCommon.QueryResult<GetEmailNotificationsByBookingIdOrEmailQuery, GetEmailNotificationsByBookingIdOrEmailQueryVariables>;
export const GetSentEmailStatusFromMailjetDocument = gql`
    query GetSentEmailStatusFromMailjet($messageID: String!) {
  sentEmailStatusFromMailjet(messageID: $messageID)
}
    `;
export type GetSentEmailStatusFromMailjetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>, 'query'> & ({ variables: GetSentEmailStatusFromMailjetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSentEmailStatusFromMailjetComponent = (props: GetSentEmailStatusFromMailjetComponentProps) => (
      <ApolloReactComponents.Query<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables> query={GetSentEmailStatusFromMailjetDocument} {...props} />
    );
    
export type GetSentEmailStatusFromMailjetProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables> & TChildProps;
export function withGetSentEmailStatusFromMailjet<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSentEmailStatusFromMailjetQuery,
  GetSentEmailStatusFromMailjetQueryVariables,
  GetSentEmailStatusFromMailjetProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables, GetSentEmailStatusFromMailjetProps<TChildProps>>(GetSentEmailStatusFromMailjetDocument, {
      alias: 'getSentEmailStatusFromMailjet',
      ...operationOptions
    });
};

/**
 * __useGetSentEmailStatusFromMailjetQuery__
 *
 * To run a query within a React component, call `useGetSentEmailStatusFromMailjetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentEmailStatusFromMailjetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentEmailStatusFromMailjetQuery({
 *   variables: {
 *      messageID: // value for 'messageID'
 *   },
 * });
 */
export function useGetSentEmailStatusFromMailjetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>(GetSentEmailStatusFromMailjetDocument, baseOptions);
      }
export function useGetSentEmailStatusFromMailjetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>(GetSentEmailStatusFromMailjetDocument, baseOptions);
        }
export type GetSentEmailStatusFromMailjetQueryHookResult = ReturnType<typeof useGetSentEmailStatusFromMailjetQuery>;
export type GetSentEmailStatusFromMailjetLazyQueryHookResult = ReturnType<typeof useGetSentEmailStatusFromMailjetLazyQuery>;
export type GetSentEmailStatusFromMailjetQueryResult = ApolloReactCommon.QueryResult<GetSentEmailStatusFromMailjetQuery, GetSentEmailStatusFromMailjetQueryVariables>;
export const GetBookerIdMissmatchDocument = gql`
    query GetBookerIdMissmatch($options: ArrayDataOptions) {
  getBookerIdMissmatchesWithPagination(options: $options) {
    total
    existsMore
    items {
      id
      email
      receiverName
      createDate
      subject
      templateName
      bookerId
      bookingId
      currentBooker {
        firstname
        lastname
        email
        id
      }
      foundByMailBooker {
        firstname
        lastname
        email
        id
      }
    }
  }
}
    `;
export type GetBookerIdMissmatchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>, 'query'>;

    export const GetBookerIdMissmatchComponent = (props: GetBookerIdMissmatchComponentProps) => (
      <ApolloReactComponents.Query<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables> query={GetBookerIdMissmatchDocument} {...props} />
    );
    
export type GetBookerIdMissmatchProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables> & TChildProps;
export function withGetBookerIdMissmatch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBookerIdMissmatchQuery,
  GetBookerIdMissmatchQueryVariables,
  GetBookerIdMissmatchProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables, GetBookerIdMissmatchProps<TChildProps>>(GetBookerIdMissmatchDocument, {
      alias: 'getBookerIdMissmatch',
      ...operationOptions
    });
};

/**
 * __useGetBookerIdMissmatchQuery__
 *
 * To run a query within a React component, call `useGetBookerIdMissmatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookerIdMissmatchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookerIdMissmatchQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBookerIdMissmatchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>(GetBookerIdMissmatchDocument, baseOptions);
      }
export function useGetBookerIdMissmatchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>(GetBookerIdMissmatchDocument, baseOptions);
        }
export type GetBookerIdMissmatchQueryHookResult = ReturnType<typeof useGetBookerIdMissmatchQuery>;
export type GetBookerIdMissmatchLazyQueryHookResult = ReturnType<typeof useGetBookerIdMissmatchLazyQuery>;
export type GetBookerIdMissmatchQueryResult = ApolloReactCommon.QueryResult<GetBookerIdMissmatchQuery, GetBookerIdMissmatchQueryVariables>;
export const UpdateBookerIdMissmatchDocument = gql`
    mutation UpdateBookerIdMissmatch($notificationId: ID!, $oldBookerId: ID, $newBookerId: ID!) {
  updateBookerIdMissmatch(notificationId: $notificationId, oldBookerId: $oldBookerId, newBookerId: $newBookerId) {
    id
    email
    receiverName
    createDate
    subject
    templateName
    bookerId
    bookingId
  }
}
    `;
export type UpdateBookerIdMissmatchMutationFn = ApolloReactCommon.MutationFunction<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables>;
export type UpdateBookerIdMissmatchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables>, 'mutation'>;

    export const UpdateBookerIdMissmatchComponent = (props: UpdateBookerIdMissmatchComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables> mutation={UpdateBookerIdMissmatchDocument} {...props} />
    );
    
export type UpdateBookerIdMissmatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables> & TChildProps;
export function withUpdateBookerIdMissmatch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookerIdMissmatchMutation,
  UpdateBookerIdMissmatchMutationVariables,
  UpdateBookerIdMissmatchProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables, UpdateBookerIdMissmatchProps<TChildProps>>(UpdateBookerIdMissmatchDocument, {
      alias: 'updateBookerIdMissmatch',
      ...operationOptions
    });
};

/**
 * __useUpdateBookerIdMissmatchMutation__
 *
 * To run a mutation, you first call `useUpdateBookerIdMissmatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookerIdMissmatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookerIdMissmatchMutation, { data, loading, error }] = useUpdateBookerIdMissmatchMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      oldBookerId: // value for 'oldBookerId'
 *      newBookerId: // value for 'newBookerId'
 *   },
 * });
 */
export function useUpdateBookerIdMissmatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables>(UpdateBookerIdMissmatchDocument, baseOptions);
      }
export type UpdateBookerIdMissmatchMutationHookResult = ReturnType<typeof useUpdateBookerIdMissmatchMutation>;
export type UpdateBookerIdMissmatchMutationResult = ApolloReactCommon.MutationResult<UpdateBookerIdMissmatchMutation>;
export type UpdateBookerIdMissmatchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookerIdMissmatchMutation, UpdateBookerIdMissmatchMutationVariables>;
export const UpdateMultipleBookerIdMissmatchesDocument = gql`
    mutation UpdateMultipleBookerIdMissmatches($updateMultipleBookerIdMissmatchesInput: [UpdateBookerIdMissmatchesInput!]!) {
  updateMultipleBookerIdMissmatches(updateMultipleBookerIdMissmatchesInput: $updateMultipleBookerIdMissmatchesInput) {
    id
    email
    receiverName
    createDate
    subject
    templateName
    bookerId
    bookingId
  }
}
    `;
export type UpdateMultipleBookerIdMissmatchesMutationFn = ApolloReactCommon.MutationFunction<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables>;
export type UpdateMultipleBookerIdMissmatchesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables>, 'mutation'>;

    export const UpdateMultipleBookerIdMissmatchesComponent = (props: UpdateMultipleBookerIdMissmatchesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables> mutation={UpdateMultipleBookerIdMissmatchesDocument} {...props} />
    );
    
export type UpdateMultipleBookerIdMissmatchesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables> & TChildProps;
export function withUpdateMultipleBookerIdMissmatches<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMultipleBookerIdMissmatchesMutation,
  UpdateMultipleBookerIdMissmatchesMutationVariables,
  UpdateMultipleBookerIdMissmatchesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables, UpdateMultipleBookerIdMissmatchesProps<TChildProps>>(UpdateMultipleBookerIdMissmatchesDocument, {
      alias: 'updateMultipleBookerIdMissmatches',
      ...operationOptions
    });
};

/**
 * __useUpdateMultipleBookerIdMissmatchesMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleBookerIdMissmatchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleBookerIdMissmatchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleBookerIdMissmatchesMutation, { data, loading, error }] = useUpdateMultipleBookerIdMissmatchesMutation({
 *   variables: {
 *      updateMultipleBookerIdMissmatchesInput: // value for 'updateMultipleBookerIdMissmatchesInput'
 *   },
 * });
 */
export function useUpdateMultipleBookerIdMissmatchesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables>(UpdateMultipleBookerIdMissmatchesDocument, baseOptions);
      }
export type UpdateMultipleBookerIdMissmatchesMutationHookResult = ReturnType<typeof useUpdateMultipleBookerIdMissmatchesMutation>;
export type UpdateMultipleBookerIdMissmatchesMutationResult = ApolloReactCommon.MutationResult<UpdateMultipleBookerIdMissmatchesMutation>;
export type UpdateMultipleBookerIdMissmatchesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMultipleBookerIdMissmatchesMutation, UpdateMultipleBookerIdMissmatchesMutationVariables>;
export const CreateParticipationPrerequisiteDocument = gql`
    mutation createParticipationPrerequisite($newParticipationPrerequisitesInput: NewParticipationPrerequisitesInput!) {
  createParticipationPrerequisite(newParticipationPrerequisitesInput: $newParticipationPrerequisitesInput) {
    id
    enabled
    groupDescription
    groupLabel
    minCheckCount
    optionalInputFields
    courseTypeId
  }
}
    `;
export type CreateParticipationPrerequisiteMutationFn = ApolloReactCommon.MutationFunction<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables>;
export type CreateParticipationPrerequisiteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables>, 'mutation'>;

    export const CreateParticipationPrerequisiteComponent = (props: CreateParticipationPrerequisiteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables> mutation={CreateParticipationPrerequisiteDocument} {...props} />
    );
    
export type CreateParticipationPrerequisiteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables> & TChildProps;
export function withCreateParticipationPrerequisite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateParticipationPrerequisiteMutation,
  CreateParticipationPrerequisiteMutationVariables,
  CreateParticipationPrerequisiteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables, CreateParticipationPrerequisiteProps<TChildProps>>(CreateParticipationPrerequisiteDocument, {
      alias: 'createParticipationPrerequisite',
      ...operationOptions
    });
};

/**
 * __useCreateParticipationPrerequisiteMutation__
 *
 * To run a mutation, you first call `useCreateParticipationPrerequisiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipationPrerequisiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipationPrerequisiteMutation, { data, loading, error }] = useCreateParticipationPrerequisiteMutation({
 *   variables: {
 *      newParticipationPrerequisitesInput: // value for 'newParticipationPrerequisitesInput'
 *   },
 * });
 */
export function useCreateParticipationPrerequisiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables>(CreateParticipationPrerequisiteDocument, baseOptions);
      }
export type CreateParticipationPrerequisiteMutationHookResult = ReturnType<typeof useCreateParticipationPrerequisiteMutation>;
export type CreateParticipationPrerequisiteMutationResult = ApolloReactCommon.MutationResult<CreateParticipationPrerequisiteMutation>;
export type CreateParticipationPrerequisiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateParticipationPrerequisiteMutation, CreateParticipationPrerequisiteMutationVariables>;
export const UpdateParticipationPrerequisiteDocument = gql`
    mutation updateParticipationPrerequisite($updateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput!) {
  updateParticipationPrerequisite(updateParticipationPrerequisitesInput: $updateParticipationPrerequisitesInput) {
    id
    enabled
    groupDescription
    groupLabel
    minCheckCount
    optionalInputFields
    courseTypeId
  }
}
    `;
export type UpdateParticipationPrerequisiteMutationFn = ApolloReactCommon.MutationFunction<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables>;
export type UpdateParticipationPrerequisiteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables>, 'mutation'>;

    export const UpdateParticipationPrerequisiteComponent = (props: UpdateParticipationPrerequisiteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables> mutation={UpdateParticipationPrerequisiteDocument} {...props} />
    );
    
export type UpdateParticipationPrerequisiteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables> & TChildProps;
export function withUpdateParticipationPrerequisite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateParticipationPrerequisiteMutation,
  UpdateParticipationPrerequisiteMutationVariables,
  UpdateParticipationPrerequisiteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables, UpdateParticipationPrerequisiteProps<TChildProps>>(UpdateParticipationPrerequisiteDocument, {
      alias: 'updateParticipationPrerequisite',
      ...operationOptions
    });
};

/**
 * __useUpdateParticipationPrerequisiteMutation__
 *
 * To run a mutation, you first call `useUpdateParticipationPrerequisiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipationPrerequisiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipationPrerequisiteMutation, { data, loading, error }] = useUpdateParticipationPrerequisiteMutation({
 *   variables: {
 *      updateParticipationPrerequisitesInput: // value for 'updateParticipationPrerequisitesInput'
 *   },
 * });
 */
export function useUpdateParticipationPrerequisiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables>(UpdateParticipationPrerequisiteDocument, baseOptions);
      }
export type UpdateParticipationPrerequisiteMutationHookResult = ReturnType<typeof useUpdateParticipationPrerequisiteMutation>;
export type UpdateParticipationPrerequisiteMutationResult = ApolloReactCommon.MutationResult<UpdateParticipationPrerequisiteMutation>;
export type UpdateParticipationPrerequisiteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateParticipationPrerequisiteMutation, UpdateParticipationPrerequisiteMutationVariables>;
export const RemoveParticipationPrerequisiteDocument = gql`
    mutation removeParticipationPrerequisite($id: ID!) {
  removeParticipationPrerequisite(id: $id)
}
    `;
export type RemoveParticipationPrerequisiteMutationFn = ApolloReactCommon.MutationFunction<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables>;
export type RemoveParticipationPrerequisiteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables>, 'mutation'>;

    export const RemoveParticipationPrerequisiteComponent = (props: RemoveParticipationPrerequisiteComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables> mutation={RemoveParticipationPrerequisiteDocument} {...props} />
    );
    
export type RemoveParticipationPrerequisiteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables> & TChildProps;
export function withRemoveParticipationPrerequisite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveParticipationPrerequisiteMutation,
  RemoveParticipationPrerequisiteMutationVariables,
  RemoveParticipationPrerequisiteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables, RemoveParticipationPrerequisiteProps<TChildProps>>(RemoveParticipationPrerequisiteDocument, {
      alias: 'removeParticipationPrerequisite',
      ...operationOptions
    });
};

/**
 * __useRemoveParticipationPrerequisiteMutation__
 *
 * To run a mutation, you first call `useRemoveParticipationPrerequisiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParticipationPrerequisiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParticipationPrerequisiteMutation, { data, loading, error }] = useRemoveParticipationPrerequisiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveParticipationPrerequisiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables>(RemoveParticipationPrerequisiteDocument, baseOptions);
      }
export type RemoveParticipationPrerequisiteMutationHookResult = ReturnType<typeof useRemoveParticipationPrerequisiteMutation>;
export type RemoveParticipationPrerequisiteMutationResult = ApolloReactCommon.MutationResult<RemoveParticipationPrerequisiteMutation>;
export type RemoveParticipationPrerequisiteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveParticipationPrerequisiteMutation, RemoveParticipationPrerequisiteMutationVariables>;
export const GetParticipationPrerequisitesByCourseTypeIdDocument = gql`
    query getParticipationPrerequisitesByCourseTypeId($courseTypeId: ID!) {
  participationPrerequisitesByCourseTypeId(courseTypeId: $courseTypeId) {
    id
    enabled
    groupDescription
    groupLabel
    minCheckCount
    optionalInputFields
    courseTypeId
  }
}
    `;
export type GetParticipationPrerequisitesByCourseTypeIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>, 'query'> & ({ variables: GetParticipationPrerequisitesByCourseTypeIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetParticipationPrerequisitesByCourseTypeIdComponent = (props: GetParticipationPrerequisitesByCourseTypeIdComponentProps) => (
      <ApolloReactComponents.Query<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables> query={GetParticipationPrerequisitesByCourseTypeIdDocument} {...props} />
    );
    
export type GetParticipationPrerequisitesByCourseTypeIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables> & TChildProps;
export function withGetParticipationPrerequisitesByCourseTypeId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetParticipationPrerequisitesByCourseTypeIdQuery,
  GetParticipationPrerequisitesByCourseTypeIdQueryVariables,
  GetParticipationPrerequisitesByCourseTypeIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables, GetParticipationPrerequisitesByCourseTypeIdProps<TChildProps>>(GetParticipationPrerequisitesByCourseTypeIdDocument, {
      alias: 'getParticipationPrerequisitesByCourseTypeId',
      ...operationOptions
    });
};

/**
 * __useGetParticipationPrerequisitesByCourseTypeIdQuery__
 *
 * To run a query within a React component, call `useGetParticipationPrerequisitesByCourseTypeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationPrerequisitesByCourseTypeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationPrerequisitesByCourseTypeIdQuery({
 *   variables: {
 *      courseTypeId: // value for 'courseTypeId'
 *   },
 * });
 */
export function useGetParticipationPrerequisitesByCourseTypeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>(GetParticipationPrerequisitesByCourseTypeIdDocument, baseOptions);
      }
export function useGetParticipationPrerequisitesByCourseTypeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>(GetParticipationPrerequisitesByCourseTypeIdDocument, baseOptions);
        }
export type GetParticipationPrerequisitesByCourseTypeIdQueryHookResult = ReturnType<typeof useGetParticipationPrerequisitesByCourseTypeIdQuery>;
export type GetParticipationPrerequisitesByCourseTypeIdLazyQueryHookResult = ReturnType<typeof useGetParticipationPrerequisitesByCourseTypeIdLazyQuery>;
export type GetParticipationPrerequisitesByCourseTypeIdQueryResult = ApolloReactCommon.QueryResult<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>;
export const GetExportedSepaXmlsDocument = gql`
    query GetExportedSepaXmls($options: ArrayDataOptions) {
  exportedSepaXmls(options: $options) {
    existsMore
    total
    items {
      id
      object
      xml
      createDate
      createdByUserId
      updateDate
      updatedByUserId
      fileName
    }
  }
}
    `;
export type GetExportedSepaXmlsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>, 'query'>;

    export const GetExportedSepaXmlsComponent = (props: GetExportedSepaXmlsComponentProps) => (
      <ApolloReactComponents.Query<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables> query={GetExportedSepaXmlsDocument} {...props} />
    );
    
export type GetExportedSepaXmlsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables> & TChildProps;
export function withGetExportedSepaXmls<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExportedSepaXmlsQuery,
  GetExportedSepaXmlsQueryVariables,
  GetExportedSepaXmlsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables, GetExportedSepaXmlsProps<TChildProps>>(GetExportedSepaXmlsDocument, {
      alias: 'getExportedSepaXmls',
      ...operationOptions
    });
};

/**
 * __useGetExportedSepaXmlsQuery__
 *
 * To run a query within a React component, call `useGetExportedSepaXmlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedSepaXmlsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedSepaXmlsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetExportedSepaXmlsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>(GetExportedSepaXmlsDocument, baseOptions);
      }
export function useGetExportedSepaXmlsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>(GetExportedSepaXmlsDocument, baseOptions);
        }
export type GetExportedSepaXmlsQueryHookResult = ReturnType<typeof useGetExportedSepaXmlsQuery>;
export type GetExportedSepaXmlsLazyQueryHookResult = ReturnType<typeof useGetExportedSepaXmlsLazyQuery>;
export type GetExportedSepaXmlsQueryResult = ApolloReactCommon.QueryResult<GetExportedSepaXmlsQuery, GetExportedSepaXmlsQueryVariables>;
export const GetExportedSepaXmlDocument = gql`
    query GetExportedSepaXml($id: String!) {
  exportedSepaXml(id: $id) {
    id
    object
    xml
    createDate
    createdByUserId
    updateDate
    updatedByUserId
    fileName
  }
}
    `;
export type GetExportedSepaXmlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>, 'query'> & ({ variables: GetExportedSepaXmlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetExportedSepaXmlComponent = (props: GetExportedSepaXmlComponentProps) => (
      <ApolloReactComponents.Query<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables> query={GetExportedSepaXmlDocument} {...props} />
    );
    
export type GetExportedSepaXmlProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables> & TChildProps;
export function withGetExportedSepaXml<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExportedSepaXmlQuery,
  GetExportedSepaXmlQueryVariables,
  GetExportedSepaXmlProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables, GetExportedSepaXmlProps<TChildProps>>(GetExportedSepaXmlDocument, {
      alias: 'getExportedSepaXml',
      ...operationOptions
    });
};

/**
 * __useGetExportedSepaXmlQuery__
 *
 * To run a query within a React component, call `useGetExportedSepaXmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedSepaXmlQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedSepaXmlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExportedSepaXmlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>(GetExportedSepaXmlDocument, baseOptions);
      }
export function useGetExportedSepaXmlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>(GetExportedSepaXmlDocument, baseOptions);
        }
export type GetExportedSepaXmlQueryHookResult = ReturnType<typeof useGetExportedSepaXmlQuery>;
export type GetExportedSepaXmlLazyQueryHookResult = ReturnType<typeof useGetExportedSepaXmlLazyQuery>;
export type GetExportedSepaXmlQueryResult = ApolloReactCommon.QueryResult<GetExportedSepaXmlQuery, GetExportedSepaXmlQueryVariables>;
export const GetBilledBookingsDocument = gql`
    query GetBilledBookings($courseType: Boolean!, $courseNumber: Boolean!, $booker: Boolean!, $attendee: Boolean!, $location: Boolean!, $firstCourse: Boolean!, $paymentType: Boolean!, $amount: Boolean!, $paymentDate: Boolean!, $invoice: Boolean!, $due: Boolean!, $discount: Boolean!) {
  getBilledBookings {
    id
    bookingNumber
    bookingNumberPrefix
    courseLessons {
      id
      startDateTime @include(if: $firstCourse)
      endDateTime @include(if: $firstCourse)
      course {
        id
        prefixedCourseNumber @include(if: $courseNumber)
        courseType @include(if: $courseType) {
          id
          name
          color
        }
      }
    }
    attendee {
      firstname @include(if: $attendee)
      lastname @include(if: $attendee)
      id
      booker {
        id
        firstname @include(if: $booker)
        lastname @include(if: $booker)
        city @include(if: $location)
      }
    }
    paymentType @include(if: $paymentType)
    coursePrice {
      id
      grossPrice @include(if: $amount)
      discount @include(if: $discount)
    }
    invoice {
      id
      billed @include(if: $paymentDate)
      paymentDue @include(if: $due)
      invoiceNumber @include(if: $invoice)
      invoiceNumberPrefix @include(if: $invoice)
    }
  }
}
    `;
export type GetBilledBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>, 'query'> & ({ variables: GetBilledBookingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBilledBookingsComponent = (props: GetBilledBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetBilledBookingsQuery, GetBilledBookingsQueryVariables> query={GetBilledBookingsDocument} {...props} />
    );
    
export type GetBilledBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBilledBookingsQuery, GetBilledBookingsQueryVariables> & TChildProps;
export function withGetBilledBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBilledBookingsQuery,
  GetBilledBookingsQueryVariables,
  GetBilledBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBilledBookingsQuery, GetBilledBookingsQueryVariables, GetBilledBookingsProps<TChildProps>>(GetBilledBookingsDocument, {
      alias: 'getBilledBookings',
      ...operationOptions
    });
};

/**
 * __useGetBilledBookingsQuery__
 *
 * To run a query within a React component, call `useGetBilledBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBilledBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBilledBookingsQuery({
 *   variables: {
 *      courseType: // value for 'courseType'
 *      courseNumber: // value for 'courseNumber'
 *      booker: // value for 'booker'
 *      attendee: // value for 'attendee'
 *      location: // value for 'location'
 *      firstCourse: // value for 'firstCourse'
 *      paymentType: // value for 'paymentType'
 *      amount: // value for 'amount'
 *      paymentDate: // value for 'paymentDate'
 *      invoice: // value for 'invoice'
 *      due: // value for 'due'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useGetBilledBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>(GetBilledBookingsDocument, baseOptions);
      }
export function useGetBilledBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>(GetBilledBookingsDocument, baseOptions);
        }
export type GetBilledBookingsQueryHookResult = ReturnType<typeof useGetBilledBookingsQuery>;
export type GetBilledBookingsLazyQueryHookResult = ReturnType<typeof useGetBilledBookingsLazyQuery>;
export type GetBilledBookingsQueryResult = ApolloReactCommon.QueryResult<GetBilledBookingsQuery, GetBilledBookingsQueryVariables>;
export const GetUnBilledBookingsDocument = gql`
    query GetUnBilledBookings($courseType: Boolean!, $courseNumber: Boolean!, $booker: Boolean!, $attendee: Boolean!, $location: Boolean!, $firstCourse: Boolean!, $paymentType: Boolean!, $amount: Boolean!, $paymentDate: Boolean!, $invoice: Boolean!, $due: Boolean!, $discount: Boolean!, $place: Boolean!, $invoiceDate: Boolean!) {
  getUnBilledBookings {
    id
    bookingNumber
    bookingNumberPrefix
    courseLessons {
      id
      startDateTime @include(if: $firstCourse)
      endDateTime @include(if: $firstCourse)
      location @include(if: $place) {
        id
        city
      }
      course {
        id
        prefixedCourseNumber @include(if: $courseNumber)
        courseType @include(if: $courseType) {
          id
          name
          color
        }
      }
    }
    attendee {
      firstname @include(if: $attendee)
      lastname @include(if: $attendee)
      id
      booker {
        id
        firstname @include(if: $booker)
        lastname @include(if: $booker)
        city @include(if: $location)
      }
    }
    paymentType @include(if: $paymentType)
    coursePrice {
      id
      grossPrice @include(if: $amount)
      discount @include(if: $discount)
    }
    payDate @include(if: $invoiceDate)
    paymentMethod {
      id
      type
    }
    invoice {
      id
      billed @include(if: $paymentDate)
      paymentDue @include(if: $due)
      invoiceNumber @include(if: $invoice)
      invoiceNumberPrefix @include(if: $invoice)
    }
  }
}
    `;
export type GetUnBilledBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>, 'query'> & ({ variables: GetUnBilledBookingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnBilledBookingsComponent = (props: GetUnBilledBookingsComponentProps) => (
      <ApolloReactComponents.Query<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables> query={GetUnBilledBookingsDocument} {...props} />
    );
    
export type GetUnBilledBookingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables> & TChildProps;
export function withGetUnBilledBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnBilledBookingsQuery,
  GetUnBilledBookingsQueryVariables,
  GetUnBilledBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables, GetUnBilledBookingsProps<TChildProps>>(GetUnBilledBookingsDocument, {
      alias: 'getUnBilledBookings',
      ...operationOptions
    });
};

/**
 * __useGetUnBilledBookingsQuery__
 *
 * To run a query within a React component, call `useGetUnBilledBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnBilledBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnBilledBookingsQuery({
 *   variables: {
 *      courseType: // value for 'courseType'
 *      courseNumber: // value for 'courseNumber'
 *      booker: // value for 'booker'
 *      attendee: // value for 'attendee'
 *      location: // value for 'location'
 *      firstCourse: // value for 'firstCourse'
 *      paymentType: // value for 'paymentType'
 *      amount: // value for 'amount'
 *      paymentDate: // value for 'paymentDate'
 *      invoice: // value for 'invoice'
 *      due: // value for 'due'
 *      discount: // value for 'discount'
 *      place: // value for 'place'
 *      invoiceDate: // value for 'invoiceDate'
 *   },
 * });
 */
export function useGetUnBilledBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>(GetUnBilledBookingsDocument, baseOptions);
      }
export function useGetUnBilledBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>(GetUnBilledBookingsDocument, baseOptions);
        }
export type GetUnBilledBookingsQueryHookResult = ReturnType<typeof useGetUnBilledBookingsQuery>;
export type GetUnBilledBookingsLazyQueryHookResult = ReturnType<typeof useGetUnBilledBookingsLazyQuery>;
export type GetUnBilledBookingsQueryResult = ApolloReactCommon.QueryResult<GetUnBilledBookingsQuery, GetUnBilledBookingsQueryVariables>;
export const GetUnBilledBookingsMinimalDocument = gql`
    query GetUnBilledBookingsMinimal {
  getUnBilledBookings {
    id
    bookingNumber
    bookingNumberPrefix
  }
}
    `;
export type GetUnBilledBookingsMinimalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>, 'query'>;

    export const GetUnBilledBookingsMinimalComponent = (props: GetUnBilledBookingsMinimalComponentProps) => (
      <ApolloReactComponents.Query<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables> query={GetUnBilledBookingsMinimalDocument} {...props} />
    );
    
export type GetUnBilledBookingsMinimalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables> & TChildProps;
export function withGetUnBilledBookingsMinimal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnBilledBookingsMinimalQuery,
  GetUnBilledBookingsMinimalQueryVariables,
  GetUnBilledBookingsMinimalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables, GetUnBilledBookingsMinimalProps<TChildProps>>(GetUnBilledBookingsMinimalDocument, {
      alias: 'getUnBilledBookingsMinimal',
      ...operationOptions
    });
};

/**
 * __useGetUnBilledBookingsMinimalQuery__
 *
 * To run a query within a React component, call `useGetUnBilledBookingsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnBilledBookingsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnBilledBookingsMinimalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnBilledBookingsMinimalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>(GetUnBilledBookingsMinimalDocument, baseOptions);
      }
export function useGetUnBilledBookingsMinimalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>(GetUnBilledBookingsMinimalDocument, baseOptions);
        }
export type GetUnBilledBookingsMinimalQueryHookResult = ReturnType<typeof useGetUnBilledBookingsMinimalQuery>;
export type GetUnBilledBookingsMinimalLazyQueryHookResult = ReturnType<typeof useGetUnBilledBookingsMinimalLazyQuery>;
export type GetUnBilledBookingsMinimalQueryResult = ApolloReactCommon.QueryResult<GetUnBilledBookingsMinimalQuery, GetUnBilledBookingsMinimalQueryVariables>;
export const GetBilledBookingsMinimalDocument = gql`
    query GetBilledBookingsMinimal {
  getBilledBookings {
    id
    bookingNumber
    bookingNumberPrefix
  }
}
    `;
export type GetBilledBookingsMinimalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>, 'query'>;

    export const GetBilledBookingsMinimalComponent = (props: GetBilledBookingsMinimalComponentProps) => (
      <ApolloReactComponents.Query<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables> query={GetBilledBookingsMinimalDocument} {...props} />
    );
    
export type GetBilledBookingsMinimalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables> & TChildProps;
export function withGetBilledBookingsMinimal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBilledBookingsMinimalQuery,
  GetBilledBookingsMinimalQueryVariables,
  GetBilledBookingsMinimalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables, GetBilledBookingsMinimalProps<TChildProps>>(GetBilledBookingsMinimalDocument, {
      alias: 'getBilledBookingsMinimal',
      ...operationOptions
    });
};

/**
 * __useGetBilledBookingsMinimalQuery__
 *
 * To run a query within a React component, call `useGetBilledBookingsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBilledBookingsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBilledBookingsMinimalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBilledBookingsMinimalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>(GetBilledBookingsMinimalDocument, baseOptions);
      }
export function useGetBilledBookingsMinimalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>(GetBilledBookingsMinimalDocument, baseOptions);
        }
export type GetBilledBookingsMinimalQueryHookResult = ReturnType<typeof useGetBilledBookingsMinimalQuery>;
export type GetBilledBookingsMinimalLazyQueryHookResult = ReturnType<typeof useGetBilledBookingsMinimalLazyQuery>;
export type GetBilledBookingsMinimalQueryResult = ApolloReactCommon.QueryResult<GetBilledBookingsMinimalQuery, GetBilledBookingsMinimalQueryVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: String!, $editInvoiceData: EditInvoiceInput!) {
  updateInvoice(editInvoiceData: $editInvoiceData, id: $id) {
    id
  }
}
    `;
export type UpdateInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export type UpdateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>, 'mutation'>;

    export const UpdateInvoiceComponent = (props: UpdateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> mutation={UpdateInvoiceDocument} {...props} />
    );
    
export type UpdateInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> & TChildProps;
export function withUpdateInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
  UpdateInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceMutation, UpdateInvoiceMutationVariables, UpdateInvoiceProps<TChildProps>>(UpdateInvoiceDocument, {
      alias: 'updateInvoice',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      editInvoiceData: // value for 'editInvoiceData'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const BillMultipleBookingsDocument = gql`
    mutation BillMultipleBookings($ids: [ID!]!, $billDate: DateTime) {
  billMultipleBookings(ids: $ids, billDate: $billDate) {
    id
  }
}
    `;
export type BillMultipleBookingsMutationFn = ApolloReactCommon.MutationFunction<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables>;
export type BillMultipleBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables>, 'mutation'>;

    export const BillMultipleBookingsComponent = (props: BillMultipleBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables> mutation={BillMultipleBookingsDocument} {...props} />
    );
    
export type BillMultipleBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables> & TChildProps;
export function withBillMultipleBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillMultipleBookingsMutation,
  BillMultipleBookingsMutationVariables,
  BillMultipleBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables, BillMultipleBookingsProps<TChildProps>>(BillMultipleBookingsDocument, {
      alias: 'billMultipleBookings',
      ...operationOptions
    });
};

/**
 * __useBillMultipleBookingsMutation__
 *
 * To run a mutation, you first call `useBillMultipleBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillMultipleBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billMultipleBookingsMutation, { data, loading, error }] = useBillMultipleBookingsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      billDate: // value for 'billDate'
 *   },
 * });
 */
export function useBillMultipleBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables>(BillMultipleBookingsDocument, baseOptions);
      }
export type BillMultipleBookingsMutationHookResult = ReturnType<typeof useBillMultipleBookingsMutation>;
export type BillMultipleBookingsMutationResult = ApolloReactCommon.MutationResult<BillMultipleBookingsMutation>;
export type BillMultipleBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<BillMultipleBookingsMutation, BillMultipleBookingsMutationVariables>;
export const UnBillMultipleBookingsDocument = gql`
    mutation UnBillMultipleBookings($ids: [ID!]!) {
  unBillMultipleBookings(ids: $ids) {
    id
  }
}
    `;
export type UnBillMultipleBookingsMutationFn = ApolloReactCommon.MutationFunction<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables>;
export type UnBillMultipleBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables>, 'mutation'>;

    export const UnBillMultipleBookingsComponent = (props: UnBillMultipleBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables> mutation={UnBillMultipleBookingsDocument} {...props} />
    );
    
export type UnBillMultipleBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables> & TChildProps;
export function withUnBillMultipleBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnBillMultipleBookingsMutation,
  UnBillMultipleBookingsMutationVariables,
  UnBillMultipleBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables, UnBillMultipleBookingsProps<TChildProps>>(UnBillMultipleBookingsDocument, {
      alias: 'unBillMultipleBookings',
      ...operationOptions
    });
};

/**
 * __useUnBillMultipleBookingsMutation__
 *
 * To run a mutation, you first call `useUnBillMultipleBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnBillMultipleBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unBillMultipleBookingsMutation, { data, loading, error }] = useUnBillMultipleBookingsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUnBillMultipleBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables>(UnBillMultipleBookingsDocument, baseOptions);
      }
export type UnBillMultipleBookingsMutationHookResult = ReturnType<typeof useUnBillMultipleBookingsMutation>;
export type UnBillMultipleBookingsMutationResult = ApolloReactCommon.MutationResult<UnBillMultipleBookingsMutation>;
export type UnBillMultipleBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UnBillMultipleBookingsMutation, UnBillMultipleBookingsMutationVariables>;
export const GetScheduledPaymentsDocument = gql`
    query GetScheduledPayments($options: ArrayDataOptions) {
  scheduledPayments(options: $options) {
    existsMore
    total
    items {
      id
      paymentDue
      paymentAmount
      openAmount
      createDate
      paymentDone
      invoice {
        billed
        invoiceNumber
        invoiceNumberPrefix
        createDate
        updateDate
        id
      }
      booking {
        id
        courseId
        bookingNumberPrefix
        bookingNumber
        bookingType
        isSpecial
        vat
        netPrice
        grossPrice
        bookedBy
        paymentType
        paymentMethod {
          id
          cash
          esr
          creditCardAccountholder
          creditcardNumber
          expirationDate
          validFrom
          cvc
          paypalAccountholder
          email
          iban
          bank
          bic
          sepaAccountholder
          type
          bankTransfer
        }
        attendee {
          id
          firstname
          lastname
          booker {
            id
            firstname
            lastname
            email
          }
        }
        courseLessons {
          id
          startDateTime
          endDateTime
          course {
            id
            startDateTime
            paymentInterval
            prefixedCourseNumber
            courseType {
              id
              name
              color
            }
          }
          location {
            id
            city
            name
          }
        }
        canceled
        bookingType
        noticeStaff
      }
      paymentTransferType
      paymentMethodId
      paymentMethod {
        id
        cash
        esr
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        type
        bankTransfer
      }
      title
      type
    }
  }
}
    `;
export type GetScheduledPaymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>, 'query'>;

    export const GetScheduledPaymentsComponent = (props: GetScheduledPaymentsComponentProps) => (
      <ApolloReactComponents.Query<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables> query={GetScheduledPaymentsDocument} {...props} />
    );
    
export type GetScheduledPaymentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables> & TChildProps;
export function withGetScheduledPayments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScheduledPaymentsQuery,
  GetScheduledPaymentsQueryVariables,
  GetScheduledPaymentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables, GetScheduledPaymentsProps<TChildProps>>(GetScheduledPaymentsDocument, {
      alias: 'getScheduledPayments',
      ...operationOptions
    });
};

/**
 * __useGetScheduledPaymentsQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetScheduledPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>(GetScheduledPaymentsDocument, baseOptions);
      }
export function useGetScheduledPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>(GetScheduledPaymentsDocument, baseOptions);
        }
export type GetScheduledPaymentsQueryHookResult = ReturnType<typeof useGetScheduledPaymentsQuery>;
export type GetScheduledPaymentsLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentsLazyQuery>;
export type GetScheduledPaymentsQueryResult = ApolloReactCommon.QueryResult<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>;
export const GetUnpaidScheduledPaymentsDocument = gql`
    query getUnpaidScheduledPayments($options: ArrayDataOptions, $bookingId: ID) {
  unpaidScheduledPayments(options: $options, bookingId: $bookingId) {
    existsMore
    total
    items {
      id
      paymentDue
      paymentAmount
      openAmount
      createDate
      paymentDone
      invoice {
        id
        createDate
        updateDate
        invoiceNumber
        invoiceNumberPrefix
      }
      booking {
        id
        courseId
        bookingNumberPrefix
        bookingNumber
        bookingType
        isSpecial
        vat
        netPrice
        grossPrice
        bookedBy
        paymentType
        paymentMethod {
          id
          cash
          esr
          creditCardAccountholder
          creditcardNumber
          expirationDate
          validFrom
          cvc
          paypalAccountholder
          email
          iban
          bank
          bic
          sepaAccountholder
          type
          bankTransfer
        }
        attendee {
          id
          firstname
          lastname
          booker {
            id
            firstname
            lastname
            email
          }
        }
        courseLessons {
          id
          startDateTime
          endDateTime
          course {
            id
            startDateTime
            paymentInterval
            prefixedCourseNumber
            courseType {
              id
              name
              color
            }
          }
          location {
            id
            city
            name
          }
        }
        canceled
        bookingType
        noticeStaff
      }
      sepaClearance
      paymentTransferType
      paymentMethodId
      paymentMethod {
        id
        cash
        esr
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        type
        bankTransfer
      }
      title
      type
    }
  }
}
    `;
export type GetUnpaidScheduledPaymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>, 'query'>;

    export const GetUnpaidScheduledPaymentsComponent = (props: GetUnpaidScheduledPaymentsComponentProps) => (
      <ApolloReactComponents.Query<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables> query={GetUnpaidScheduledPaymentsDocument} {...props} />
    );
    
export type GetUnpaidScheduledPaymentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables> & TChildProps;
export function withGetUnpaidScheduledPayments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnpaidScheduledPaymentsQuery,
  GetUnpaidScheduledPaymentsQueryVariables,
  GetUnpaidScheduledPaymentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables, GetUnpaidScheduledPaymentsProps<TChildProps>>(GetUnpaidScheduledPaymentsDocument, {
      alias: 'getUnpaidScheduledPayments',
      ...operationOptions
    });
};

/**
 * __useGetUnpaidScheduledPaymentsQuery__
 *
 * To run a query within a React component, call `useGetUnpaidScheduledPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidScheduledPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidScheduledPaymentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetUnpaidScheduledPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>(GetUnpaidScheduledPaymentsDocument, baseOptions);
      }
export function useGetUnpaidScheduledPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>(GetUnpaidScheduledPaymentsDocument, baseOptions);
        }
export type GetUnpaidScheduledPaymentsQueryHookResult = ReturnType<typeof useGetUnpaidScheduledPaymentsQuery>;
export type GetUnpaidScheduledPaymentsLazyQueryHookResult = ReturnType<typeof useGetUnpaidScheduledPaymentsLazyQuery>;
export type GetUnpaidScheduledPaymentsQueryResult = ApolloReactCommon.QueryResult<GetUnpaidScheduledPaymentsQuery, GetUnpaidScheduledPaymentsQueryVariables>;
export const GetUnpaidScheduledPaymentsZeroValuesDocument = gql`
    query GetUnpaidScheduledPaymentsZeroValues($options: ArrayDataOptions) {
  unpaidScheduledPaymentsZeroValues(options: $options) {
    id
    paymentDue
    paymentAmount
    openAmount
    createDate
    paymentDone
    invoice {
      id
      createDate
      updateDate
      invoiceNumber
      invoiceNumberPrefix
    }
    booking {
      id
      courseId
      bookingNumberPrefix
      bookingNumber
      bookingType
      isSpecial
      vat
      netPrice
      grossPrice
      bookedBy
      paymentType
      paymentMethod {
        id
        cash
        esr
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        type
        bankTransfer
      }
      attendee {
        id
        firstname
        lastname
        booker {
          id
          firstname
          lastname
          email
        }
      }
      courseLessons {
        id
        startDateTime
        endDateTime
        course {
          id
          startDateTime
          paymentInterval
          prefixedCourseNumber
          courseType {
            id
            name
            color
          }
        }
        location {
          id
          city
          name
        }
      }
      canceled
      bookingType
      noticeStaff
    }
    sepaClearance
    paymentTransferType
    paymentMethodId
    paymentMethod {
      id
      cash
      esr
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      type
      bankTransfer
    }
    title
    type
  }
}
    `;
export type GetUnpaidScheduledPaymentsZeroValuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>, 'query'>;

    export const GetUnpaidScheduledPaymentsZeroValuesComponent = (props: GetUnpaidScheduledPaymentsZeroValuesComponentProps) => (
      <ApolloReactComponents.Query<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables> query={GetUnpaidScheduledPaymentsZeroValuesDocument} {...props} />
    );
    
export type GetUnpaidScheduledPaymentsZeroValuesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables> & TChildProps;
export function withGetUnpaidScheduledPaymentsZeroValues<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnpaidScheduledPaymentsZeroValuesQuery,
  GetUnpaidScheduledPaymentsZeroValuesQueryVariables,
  GetUnpaidScheduledPaymentsZeroValuesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables, GetUnpaidScheduledPaymentsZeroValuesProps<TChildProps>>(GetUnpaidScheduledPaymentsZeroValuesDocument, {
      alias: 'getUnpaidScheduledPaymentsZeroValues',
      ...operationOptions
    });
};

/**
 * __useGetUnpaidScheduledPaymentsZeroValuesQuery__
 *
 * To run a query within a React component, call `useGetUnpaidScheduledPaymentsZeroValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidScheduledPaymentsZeroValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidScheduledPaymentsZeroValuesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUnpaidScheduledPaymentsZeroValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>(GetUnpaidScheduledPaymentsZeroValuesDocument, baseOptions);
      }
export function useGetUnpaidScheduledPaymentsZeroValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>(GetUnpaidScheduledPaymentsZeroValuesDocument, baseOptions);
        }
export type GetUnpaidScheduledPaymentsZeroValuesQueryHookResult = ReturnType<typeof useGetUnpaidScheduledPaymentsZeroValuesQuery>;
export type GetUnpaidScheduledPaymentsZeroValuesLazyQueryHookResult = ReturnType<typeof useGetUnpaidScheduledPaymentsZeroValuesLazyQuery>;
export type GetUnpaidScheduledPaymentsZeroValuesQueryResult = ApolloReactCommon.QueryResult<GetUnpaidScheduledPaymentsZeroValuesQuery, GetUnpaidScheduledPaymentsZeroValuesQueryVariables>;
export const GetPaidScheduledPaymentsDocument = gql`
    query getPaidScheduledPayments($options: ArrayDataOptions, $bookingId: ID) {
  paidScheduledPayments(options: $options, bookingId: $bookingId) {
    existsMore
    total
    items {
      id
      paymentDue
      paymentAmount
      openAmount
      createDate
      paymentDone
      invoice {
        id
        createDate
        updateDate
        invoiceNumber
        invoiceNumberPrefix
      }
      booking {
        id
        courseId
        bookingNumberPrefix
        bookingNumber
        bookingType
        isSpecial
        vat
        netPrice
        grossPrice
        bookedBy
        paymentType
        paymentMethod {
          id
          cash
          esr
          creditCardAccountholder
          creditcardNumber
          expirationDate
          validFrom
          cvc
          paypalAccountholder
          email
          iban
          bank
          bic
          sepaAccountholder
          type
          bankTransfer
        }
        attendee {
          id
          firstname
          lastname
          booker {
            id
            firstname
            lastname
            email
          }
        }
        courseLessons {
          id
          startDateTime
          endDateTime
          course {
            id
            startDateTime
            paymentInterval
            prefixedCourseNumber
            courseType {
              id
              name
              color
            }
          }
          location {
            id
            city
            name
          }
        }
        canceled
        bookingType
        noticeStaff
      }
      sepaClearance
      paymentTransferType
      paymentMethodId
      paymentMethod {
        id
        cash
        esr
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        type
        bankTransfer
      }
      title
      type
    }
  }
}
    `;
export type GetPaidScheduledPaymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>, 'query'>;

    export const GetPaidScheduledPaymentsComponent = (props: GetPaidScheduledPaymentsComponentProps) => (
      <ApolloReactComponents.Query<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables> query={GetPaidScheduledPaymentsDocument} {...props} />
    );
    
export type GetPaidScheduledPaymentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables> & TChildProps;
export function withGetPaidScheduledPayments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPaidScheduledPaymentsQuery,
  GetPaidScheduledPaymentsQueryVariables,
  GetPaidScheduledPaymentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables, GetPaidScheduledPaymentsProps<TChildProps>>(GetPaidScheduledPaymentsDocument, {
      alias: 'getPaidScheduledPayments',
      ...operationOptions
    });
};

/**
 * __useGetPaidScheduledPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaidScheduledPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidScheduledPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidScheduledPaymentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetPaidScheduledPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>(GetPaidScheduledPaymentsDocument, baseOptions);
      }
export function useGetPaidScheduledPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>(GetPaidScheduledPaymentsDocument, baseOptions);
        }
export type GetPaidScheduledPaymentsQueryHookResult = ReturnType<typeof useGetPaidScheduledPaymentsQuery>;
export type GetPaidScheduledPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaidScheduledPaymentsLazyQuery>;
export type GetPaidScheduledPaymentsQueryResult = ApolloReactCommon.QueryResult<GetPaidScheduledPaymentsQuery, GetPaidScheduledPaymentsQueryVariables>;
export const GetScheduledPaymentsSummaryDocument = gql`
    query GetScheduledPaymentsSummary($paymentDone: Boolean!, $options: ArrayDataOptions) {
  scheduledPaymentSummary(paymentDone: $paymentDone, options: $options) {
    filtered {
      count
      sum
    }
    total {
      count
      sum
    }
  }
}
    `;
export type GetScheduledPaymentsSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>, 'query'> & ({ variables: GetScheduledPaymentsSummaryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetScheduledPaymentsSummaryComponent = (props: GetScheduledPaymentsSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables> query={GetScheduledPaymentsSummaryDocument} {...props} />
    );
    
export type GetScheduledPaymentsSummaryProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables> & TChildProps;
export function withGetScheduledPaymentsSummary<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScheduledPaymentsSummaryQuery,
  GetScheduledPaymentsSummaryQueryVariables,
  GetScheduledPaymentsSummaryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables, GetScheduledPaymentsSummaryProps<TChildProps>>(GetScheduledPaymentsSummaryDocument, {
      alias: 'getScheduledPaymentsSummary',
      ...operationOptions
    });
};

/**
 * __useGetScheduledPaymentsSummaryQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentsSummaryQuery({
 *   variables: {
 *      paymentDone: // value for 'paymentDone'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetScheduledPaymentsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>(GetScheduledPaymentsSummaryDocument, baseOptions);
      }
export function useGetScheduledPaymentsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>(GetScheduledPaymentsSummaryDocument, baseOptions);
        }
export type GetScheduledPaymentsSummaryQueryHookResult = ReturnType<typeof useGetScheduledPaymentsSummaryQuery>;
export type GetScheduledPaymentsSummaryLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentsSummaryLazyQuery>;
export type GetScheduledPaymentsSummaryQueryResult = ApolloReactCommon.QueryResult<GetScheduledPaymentsSummaryQuery, GetScheduledPaymentsSummaryQueryVariables>;
export const GetScheduledPaymentsMinDocument = gql`
    query GetScheduledPaymentsMin($options: ArrayDataOptions) {
  scheduledPayments(options: $options) {
    existsMore
    total
    items {
      id
      paymentDue
      paymentAmount
      openAmount
      paymentTransferType
      title
    }
  }
}
    `;
export type GetScheduledPaymentsMinComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>, 'query'>;

    export const GetScheduledPaymentsMinComponent = (props: GetScheduledPaymentsMinComponentProps) => (
      <ApolloReactComponents.Query<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables> query={GetScheduledPaymentsMinDocument} {...props} />
    );
    
export type GetScheduledPaymentsMinProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables> & TChildProps;
export function withGetScheduledPaymentsMin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScheduledPaymentsMinQuery,
  GetScheduledPaymentsMinQueryVariables,
  GetScheduledPaymentsMinProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables, GetScheduledPaymentsMinProps<TChildProps>>(GetScheduledPaymentsMinDocument, {
      alias: 'getScheduledPaymentsMin',
      ...operationOptions
    });
};

/**
 * __useGetScheduledPaymentsMinQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentsMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentsMinQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentsMinQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetScheduledPaymentsMinQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>(GetScheduledPaymentsMinDocument, baseOptions);
      }
export function useGetScheduledPaymentsMinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>(GetScheduledPaymentsMinDocument, baseOptions);
        }
export type GetScheduledPaymentsMinQueryHookResult = ReturnType<typeof useGetScheduledPaymentsMinQuery>;
export type GetScheduledPaymentsMinLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentsMinLazyQuery>;
export type GetScheduledPaymentsMinQueryResult = ApolloReactCommon.QueryResult<GetScheduledPaymentsMinQuery, GetScheduledPaymentsMinQueryVariables>;
export const GetScheduledPaymentByIdDocument = gql`
    query getScheduledPaymentById($id: ID!) {
  scheduledPaymentById(id: $id) {
    id
  }
}
    `;
export type GetScheduledPaymentByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>, 'query'> & ({ variables: GetScheduledPaymentByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetScheduledPaymentByIdComponent = (props: GetScheduledPaymentByIdComponentProps) => (
      <ApolloReactComponents.Query<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables> query={GetScheduledPaymentByIdDocument} {...props} />
    );
    
export type GetScheduledPaymentByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables> & TChildProps;
export function withGetScheduledPaymentById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScheduledPaymentByIdQuery,
  GetScheduledPaymentByIdQueryVariables,
  GetScheduledPaymentByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables, GetScheduledPaymentByIdProps<TChildProps>>(GetScheduledPaymentByIdDocument, {
      alias: 'getScheduledPaymentById',
      ...operationOptions
    });
};

/**
 * __useGetScheduledPaymentByIdQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduledPaymentByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>(GetScheduledPaymentByIdDocument, baseOptions);
      }
export function useGetScheduledPaymentByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>(GetScheduledPaymentByIdDocument, baseOptions);
        }
export type GetScheduledPaymentByIdQueryHookResult = ReturnType<typeof useGetScheduledPaymentByIdQuery>;
export type GetScheduledPaymentByIdLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentByIdLazyQuery>;
export type GetScheduledPaymentByIdQueryResult = ApolloReactCommon.QueryResult<GetScheduledPaymentByIdQuery, GetScheduledPaymentByIdQueryVariables>;
export const DeleteScheduledPaymentDocument = gql`
    mutation DeleteScheduledPayment($id: String!) {
  deleteScheduledPayment(id: $id) {
    id
  }
}
    `;
export type DeleteScheduledPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables>;
export type DeleteScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables>, 'mutation'>;

    export const DeleteScheduledPaymentComponent = (props: DeleteScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables> mutation={DeleteScheduledPaymentDocument} {...props} />
    );
    
export type DeleteScheduledPaymentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables> & TChildProps;
export function withDeleteScheduledPayment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteScheduledPaymentMutation,
  DeleteScheduledPaymentMutationVariables,
  DeleteScheduledPaymentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables, DeleteScheduledPaymentProps<TChildProps>>(DeleteScheduledPaymentDocument, {
      alias: 'deleteScheduledPayment',
      ...operationOptions
    });
};

/**
 * __useDeleteScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledPaymentMutation, { data, loading, error }] = useDeleteScheduledPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduledPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables>(DeleteScheduledPaymentDocument, baseOptions);
      }
export type DeleteScheduledPaymentMutationHookResult = ReturnType<typeof useDeleteScheduledPaymentMutation>;
export type DeleteScheduledPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteScheduledPaymentMutation>;
export type DeleteScheduledPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScheduledPaymentMutation, DeleteScheduledPaymentMutationVariables>;
export const UpdateScheduledPaymentDocument = gql`
    mutation UpdateScheduledPayment($editScheduledPaymentData: EditScheduledPaymentInput!, $id: String!) {
  updateScheduledPayment(editScheduledPaymentData: $editScheduledPaymentData, id: $id) {
    id
    paymentDone
  }
}
    `;
export type UpdateScheduledPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>;
export type UpdateScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>, 'mutation'>;

    export const UpdateScheduledPaymentComponent = (props: UpdateScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables> mutation={UpdateScheduledPaymentDocument} {...props} />
    );
    
export type UpdateScheduledPaymentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables> & TChildProps;
export function withUpdateScheduledPayment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateScheduledPaymentMutation,
  UpdateScheduledPaymentMutationVariables,
  UpdateScheduledPaymentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables, UpdateScheduledPaymentProps<TChildProps>>(UpdateScheduledPaymentDocument, {
      alias: 'updateScheduledPayment',
      ...operationOptions
    });
};

/**
 * __useUpdateScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPaymentMutation, { data, loading, error }] = useUpdateScheduledPaymentMutation({
 *   variables: {
 *      editScheduledPaymentData: // value for 'editScheduledPaymentData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateScheduledPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>(UpdateScheduledPaymentDocument, baseOptions);
      }
export type UpdateScheduledPaymentMutationHookResult = ReturnType<typeof useUpdateScheduledPaymentMutation>;
export type UpdateScheduledPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateScheduledPaymentMutation>;
export type UpdateScheduledPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>;
export const UpdateManyScheduledPaymentIdsDocument = gql`
    mutation UpdateManyScheduledPaymentIds($paymentIds: [ID!]!, $editScheduledPaymentData: EditScheduledPaymentInput!) {
  updateManyScheduledPaymentIds(paymentIds: $paymentIds, editScheduledPaymentData: $editScheduledPaymentData) {
    id
    paymentDone
  }
}
    `;
export type UpdateManyScheduledPaymentIdsMutationFn = ApolloReactCommon.MutationFunction<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables>;
export type UpdateManyScheduledPaymentIdsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables>, 'mutation'>;

    export const UpdateManyScheduledPaymentIdsComponent = (props: UpdateManyScheduledPaymentIdsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables> mutation={UpdateManyScheduledPaymentIdsDocument} {...props} />
    );
    
export type UpdateManyScheduledPaymentIdsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables> & TChildProps;
export function withUpdateManyScheduledPaymentIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateManyScheduledPaymentIdsMutation,
  UpdateManyScheduledPaymentIdsMutationVariables,
  UpdateManyScheduledPaymentIdsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables, UpdateManyScheduledPaymentIdsProps<TChildProps>>(UpdateManyScheduledPaymentIdsDocument, {
      alias: 'updateManyScheduledPaymentIds',
      ...operationOptions
    });
};

/**
 * __useUpdateManyScheduledPaymentIdsMutation__
 *
 * To run a mutation, you first call `useUpdateManyScheduledPaymentIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyScheduledPaymentIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyScheduledPaymentIdsMutation, { data, loading, error }] = useUpdateManyScheduledPaymentIdsMutation({
 *   variables: {
 *      paymentIds: // value for 'paymentIds'
 *      editScheduledPaymentData: // value for 'editScheduledPaymentData'
 *   },
 * });
 */
export function useUpdateManyScheduledPaymentIdsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables>(UpdateManyScheduledPaymentIdsDocument, baseOptions);
      }
export type UpdateManyScheduledPaymentIdsMutationHookResult = ReturnType<typeof useUpdateManyScheduledPaymentIdsMutation>;
export type UpdateManyScheduledPaymentIdsMutationResult = ApolloReactCommon.MutationResult<UpdateManyScheduledPaymentIdsMutation>;
export type UpdateManyScheduledPaymentIdsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateManyScheduledPaymentIdsMutation, UpdateManyScheduledPaymentIdsMutationVariables>;
export const SetMultipleScheduledPaymentsPaidDocument = gql`
    mutation SetMultipleScheduledPaymentsPaid($paymentIds: [ID!]!, $paymentDone: DateTime!) {
  setMultipleScheduledPaymentsPaid(paymentIds: $paymentIds, paymentDone: $paymentDone)
}
    `;
export type SetMultipleScheduledPaymentsPaidMutationFn = ApolloReactCommon.MutationFunction<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables>;
export type SetMultipleScheduledPaymentsPaidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables>, 'mutation'>;

    export const SetMultipleScheduledPaymentsPaidComponent = (props: SetMultipleScheduledPaymentsPaidComponentProps) => (
      <ApolloReactComponents.Mutation<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables> mutation={SetMultipleScheduledPaymentsPaidDocument} {...props} />
    );
    
export type SetMultipleScheduledPaymentsPaidProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables> & TChildProps;
export function withSetMultipleScheduledPaymentsPaid<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetMultipleScheduledPaymentsPaidMutation,
  SetMultipleScheduledPaymentsPaidMutationVariables,
  SetMultipleScheduledPaymentsPaidProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables, SetMultipleScheduledPaymentsPaidProps<TChildProps>>(SetMultipleScheduledPaymentsPaidDocument, {
      alias: 'setMultipleScheduledPaymentsPaid',
      ...operationOptions
    });
};

/**
 * __useSetMultipleScheduledPaymentsPaidMutation__
 *
 * To run a mutation, you first call `useSetMultipleScheduledPaymentsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMultipleScheduledPaymentsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMultipleScheduledPaymentsPaidMutation, { data, loading, error }] = useSetMultipleScheduledPaymentsPaidMutation({
 *   variables: {
 *      paymentIds: // value for 'paymentIds'
 *      paymentDone: // value for 'paymentDone'
 *   },
 * });
 */
export function useSetMultipleScheduledPaymentsPaidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables>(SetMultipleScheduledPaymentsPaidDocument, baseOptions);
      }
export type SetMultipleScheduledPaymentsPaidMutationHookResult = ReturnType<typeof useSetMultipleScheduledPaymentsPaidMutation>;
export type SetMultipleScheduledPaymentsPaidMutationResult = ApolloReactCommon.MutationResult<SetMultipleScheduledPaymentsPaidMutation>;
export type SetMultipleScheduledPaymentsPaidMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMultipleScheduledPaymentsPaidMutation, SetMultipleScheduledPaymentsPaidMutationVariables>;
export const SetSepaClearanceDocument = gql`
    mutation setSepaClearance($id: ID!, $sepaClearance: Boolean!) {
  setSepaClearance(id: $id, sepaClearance: $sepaClearance) {
    id
    sepaClearance
  }
}
    `;
export type SetSepaClearanceMutationFn = ApolloReactCommon.MutationFunction<SetSepaClearanceMutation, SetSepaClearanceMutationVariables>;
export type SetSepaClearanceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetSepaClearanceMutation, SetSepaClearanceMutationVariables>, 'mutation'>;

    export const SetSepaClearanceComponent = (props: SetSepaClearanceComponentProps) => (
      <ApolloReactComponents.Mutation<SetSepaClearanceMutation, SetSepaClearanceMutationVariables> mutation={SetSepaClearanceDocument} {...props} />
    );
    
export type SetSepaClearanceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetSepaClearanceMutation, SetSepaClearanceMutationVariables> & TChildProps;
export function withSetSepaClearance<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetSepaClearanceMutation,
  SetSepaClearanceMutationVariables,
  SetSepaClearanceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetSepaClearanceMutation, SetSepaClearanceMutationVariables, SetSepaClearanceProps<TChildProps>>(SetSepaClearanceDocument, {
      alias: 'setSepaClearance',
      ...operationOptions
    });
};

/**
 * __useSetSepaClearanceMutation__
 *
 * To run a mutation, you first call `useSetSepaClearanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSepaClearanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSepaClearanceMutation, { data, loading, error }] = useSetSepaClearanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sepaClearance: // value for 'sepaClearance'
 *   },
 * });
 */
export function useSetSepaClearanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetSepaClearanceMutation, SetSepaClearanceMutationVariables>) {
        return ApolloReactHooks.useMutation<SetSepaClearanceMutation, SetSepaClearanceMutationVariables>(SetSepaClearanceDocument, baseOptions);
      }
export type SetSepaClearanceMutationHookResult = ReturnType<typeof useSetSepaClearanceMutation>;
export type SetSepaClearanceMutationResult = ApolloReactCommon.MutationResult<SetSepaClearanceMutation>;
export type SetSepaClearanceMutationOptions = ApolloReactCommon.BaseMutationOptions<SetSepaClearanceMutation, SetSepaClearanceMutationVariables>;
export const GetScheduledPaymentsByBookingIdDocument = gql`
    query GetScheduledPaymentsByBookingId($bookingId: ID!) {
  scheduledPaymentsByBookingId(bookingId: $bookingId) {
    id
    paymentDue
    paymentAmount
    openAmount
    createDate
    paymentDone
    paymentTransferType
    invoice {
      id
      createDate
      invoiceNumber
      invoiceNumberPrefix
    }
    title
  }
}
    `;
export type GetScheduledPaymentsByBookingIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>, 'query'> & ({ variables: GetScheduledPaymentsByBookingIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetScheduledPaymentsByBookingIdComponent = (props: GetScheduledPaymentsByBookingIdComponentProps) => (
      <ApolloReactComponents.Query<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables> query={GetScheduledPaymentsByBookingIdDocument} {...props} />
    );
    
export type GetScheduledPaymentsByBookingIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables> & TChildProps;
export function withGetScheduledPaymentsByBookingId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScheduledPaymentsByBookingIdQuery,
  GetScheduledPaymentsByBookingIdQueryVariables,
  GetScheduledPaymentsByBookingIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables, GetScheduledPaymentsByBookingIdProps<TChildProps>>(GetScheduledPaymentsByBookingIdDocument, {
      alias: 'getScheduledPaymentsByBookingId',
      ...operationOptions
    });
};

/**
 * __useGetScheduledPaymentsByBookingIdQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentsByBookingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentsByBookingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentsByBookingIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetScheduledPaymentsByBookingIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>(GetScheduledPaymentsByBookingIdDocument, baseOptions);
      }
export function useGetScheduledPaymentsByBookingIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>(GetScheduledPaymentsByBookingIdDocument, baseOptions);
        }
export type GetScheduledPaymentsByBookingIdQueryHookResult = ReturnType<typeof useGetScheduledPaymentsByBookingIdQuery>;
export type GetScheduledPaymentsByBookingIdLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentsByBookingIdLazyQuery>;
export type GetScheduledPaymentsByBookingIdQueryResult = ApolloReactCommon.QueryResult<GetScheduledPaymentsByBookingIdQuery, GetScheduledPaymentsByBookingIdQueryVariables>;
export const GeneratePaymentSchedulePreviewDocument = gql`
    mutation GeneratePaymentSchedulePreview($lessonDates: [DateTime!]!, $bookingPrice: Float!, $monthlySchedule: MonthlySchedule!, $paymentType: PaymentType!, $courseId: ID) {
  generatePaymentSchedulePreview(lessonDates: $lessonDates, bookingPrice: $bookingPrice, monthlySchedule: $monthlySchedule, paymentType: $paymentType, courseId: $courseId)
}
    `;
export type GeneratePaymentSchedulePreviewMutationFn = ApolloReactCommon.MutationFunction<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables>;
export type GeneratePaymentSchedulePreviewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables>, 'mutation'>;

    export const GeneratePaymentSchedulePreviewComponent = (props: GeneratePaymentSchedulePreviewComponentProps) => (
      <ApolloReactComponents.Mutation<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables> mutation={GeneratePaymentSchedulePreviewDocument} {...props} />
    );
    
export type GeneratePaymentSchedulePreviewProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables> & TChildProps;
export function withGeneratePaymentSchedulePreview<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GeneratePaymentSchedulePreviewMutation,
  GeneratePaymentSchedulePreviewMutationVariables,
  GeneratePaymentSchedulePreviewProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables, GeneratePaymentSchedulePreviewProps<TChildProps>>(GeneratePaymentSchedulePreviewDocument, {
      alias: 'generatePaymentSchedulePreview',
      ...operationOptions
    });
};

/**
 * __useGeneratePaymentSchedulePreviewMutation__
 *
 * To run a mutation, you first call `useGeneratePaymentSchedulePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePaymentSchedulePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePaymentSchedulePreviewMutation, { data, loading, error }] = useGeneratePaymentSchedulePreviewMutation({
 *   variables: {
 *      lessonDates: // value for 'lessonDates'
 *      bookingPrice: // value for 'bookingPrice'
 *      monthlySchedule: // value for 'monthlySchedule'
 *      paymentType: // value for 'paymentType'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGeneratePaymentSchedulePreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables>) {
        return ApolloReactHooks.useMutation<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables>(GeneratePaymentSchedulePreviewDocument, baseOptions);
      }
export type GeneratePaymentSchedulePreviewMutationHookResult = ReturnType<typeof useGeneratePaymentSchedulePreviewMutation>;
export type GeneratePaymentSchedulePreviewMutationResult = ApolloReactCommon.MutationResult<GeneratePaymentSchedulePreviewMutation>;
export type GeneratePaymentSchedulePreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<GeneratePaymentSchedulePreviewMutation, GeneratePaymentSchedulePreviewMutationVariables>;
export const GenerateUpdatedSchedulesNewBookingPriceDocument = gql`
    mutation GenerateUpdatedSchedulesNewBookingPrice($scheduledPayments: [UpdateScheduledPaymentByBookingInput!]!, $bookingPrice: Float!) {
  generateUpdatedSchedulesNewBookingPrice(scheduledPayments: $scheduledPayments, bookingPrice: $bookingPrice) {
    id
    paymentAmount
    openAmount
    paymentDue
  }
}
    `;
export type GenerateUpdatedSchedulesNewBookingPriceMutationFn = ApolloReactCommon.MutationFunction<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables>;
export type GenerateUpdatedSchedulesNewBookingPriceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables>, 'mutation'>;

    export const GenerateUpdatedSchedulesNewBookingPriceComponent = (props: GenerateUpdatedSchedulesNewBookingPriceComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables> mutation={GenerateUpdatedSchedulesNewBookingPriceDocument} {...props} />
    );
    
export type GenerateUpdatedSchedulesNewBookingPriceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables> & TChildProps;
export function withGenerateUpdatedSchedulesNewBookingPrice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateUpdatedSchedulesNewBookingPriceMutation,
  GenerateUpdatedSchedulesNewBookingPriceMutationVariables,
  GenerateUpdatedSchedulesNewBookingPriceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables, GenerateUpdatedSchedulesNewBookingPriceProps<TChildProps>>(GenerateUpdatedSchedulesNewBookingPriceDocument, {
      alias: 'generateUpdatedSchedulesNewBookingPrice',
      ...operationOptions
    });
};

/**
 * __useGenerateUpdatedSchedulesNewBookingPriceMutation__
 *
 * To run a mutation, you first call `useGenerateUpdatedSchedulesNewBookingPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUpdatedSchedulesNewBookingPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUpdatedSchedulesNewBookingPriceMutation, { data, loading, error }] = useGenerateUpdatedSchedulesNewBookingPriceMutation({
 *   variables: {
 *      scheduledPayments: // value for 'scheduledPayments'
 *      bookingPrice: // value for 'bookingPrice'
 *   },
 * });
 */
export function useGenerateUpdatedSchedulesNewBookingPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables>(GenerateUpdatedSchedulesNewBookingPriceDocument, baseOptions);
      }
export type GenerateUpdatedSchedulesNewBookingPriceMutationHookResult = ReturnType<typeof useGenerateUpdatedSchedulesNewBookingPriceMutation>;
export type GenerateUpdatedSchedulesNewBookingPriceMutationResult = ApolloReactCommon.MutationResult<GenerateUpdatedSchedulesNewBookingPriceMutation>;
export type GenerateUpdatedSchedulesNewBookingPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateUpdatedSchedulesNewBookingPriceMutation, GenerateUpdatedSchedulesNewBookingPriceMutationVariables>;
export const ResetScheduledPaymentsOfBookingDocument = gql`
    mutation ResetScheduledPaymentsOfBooking($bookingId: ID!, $preview: Boolean) {
  resetScheduledPaymentsOfBooking(bookingId: $bookingId, preview: $preview) {
    bookingId
    bookingNumber
    bookingPrice
    coursePrice
    creditedBalance
    currentScheduledPaymentsCount
    newScheduledPaymentsCount
    currentScheduledPaymnetsCount
    installmentPlanAmountsAndDueDates {
      key
      value {
        due
        amount
      }
    }
    newScheduledPaymentsAmountsAndDueDates {
      key
      value {
        due
        amount
      }
    }
    currentScheduledPaymentsAmountsAndDueDates {
      key
      value {
        due
        amount
      }
    }
  }
}
    `;
export type ResetScheduledPaymentsOfBookingMutationFn = ApolloReactCommon.MutationFunction<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables>;
export type ResetScheduledPaymentsOfBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables>, 'mutation'>;

    export const ResetScheduledPaymentsOfBookingComponent = (props: ResetScheduledPaymentsOfBookingComponentProps) => (
      <ApolloReactComponents.Mutation<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables> mutation={ResetScheduledPaymentsOfBookingDocument} {...props} />
    );
    
export type ResetScheduledPaymentsOfBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables> & TChildProps;
export function withResetScheduledPaymentsOfBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetScheduledPaymentsOfBookingMutation,
  ResetScheduledPaymentsOfBookingMutationVariables,
  ResetScheduledPaymentsOfBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables, ResetScheduledPaymentsOfBookingProps<TChildProps>>(ResetScheduledPaymentsOfBookingDocument, {
      alias: 'resetScheduledPaymentsOfBooking',
      ...operationOptions
    });
};

/**
 * __useResetScheduledPaymentsOfBookingMutation__
 *
 * To run a mutation, you first call `useResetScheduledPaymentsOfBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetScheduledPaymentsOfBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetScheduledPaymentsOfBookingMutation, { data, loading, error }] = useResetScheduledPaymentsOfBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useResetScheduledPaymentsOfBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables>(ResetScheduledPaymentsOfBookingDocument, baseOptions);
      }
export type ResetScheduledPaymentsOfBookingMutationHookResult = ReturnType<typeof useResetScheduledPaymentsOfBookingMutation>;
export type ResetScheduledPaymentsOfBookingMutationResult = ApolloReactCommon.MutationResult<ResetScheduledPaymentsOfBookingMutation>;
export type ResetScheduledPaymentsOfBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetScheduledPaymentsOfBookingMutation, ResetScheduledPaymentsOfBookingMutationVariables>;
export const ResetAllUnsetteledScheduledPaymentsOfCourseDocument = gql`
    mutation ResetAllUnsetteledScheduledPaymentsOfCourse($courseId: ID!, $preview: Boolean) {
  resetAllUnsetteledScheduledPaymentsOfCourse(courseId: $courseId, preview: $preview) {
    courseId
    coursePrice
    courseDueDates {
      key
      value {
        due
        amount
      }
    }
    bookings {
      bookingId
      bookingNumber
      bookingPrice
      creditedBalance
      currentScheduledPaymentsCount
      newScheduledPaymentsCount
      currentScheduledPaymnetsCount
      newScheduledPaymentsAmountsAndDueDates {
        key
        value {
          due
          amount
        }
      }
      currentScheduledPaymentsAmountsAndDueDates {
        key
        value {
          due
          amount
        }
      }
    }
  }
}
    `;
export type ResetAllUnsetteledScheduledPaymentsOfCourseMutationFn = ApolloReactCommon.MutationFunction<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables>;
export type ResetAllUnsetteledScheduledPaymentsOfCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables>, 'mutation'>;

    export const ResetAllUnsetteledScheduledPaymentsOfCourseComponent = (props: ResetAllUnsetteledScheduledPaymentsOfCourseComponentProps) => (
      <ApolloReactComponents.Mutation<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables> mutation={ResetAllUnsetteledScheduledPaymentsOfCourseDocument} {...props} />
    );
    
export type ResetAllUnsetteledScheduledPaymentsOfCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables> & TChildProps;
export function withResetAllUnsetteledScheduledPaymentsOfCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetAllUnsetteledScheduledPaymentsOfCourseMutation,
  ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables,
  ResetAllUnsetteledScheduledPaymentsOfCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables, ResetAllUnsetteledScheduledPaymentsOfCourseProps<TChildProps>>(ResetAllUnsetteledScheduledPaymentsOfCourseDocument, {
      alias: 'resetAllUnsetteledScheduledPaymentsOfCourse',
      ...operationOptions
    });
};

/**
 * __useResetAllUnsetteledScheduledPaymentsOfCourseMutation__
 *
 * To run a mutation, you first call `useResetAllUnsetteledScheduledPaymentsOfCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAllUnsetteledScheduledPaymentsOfCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAllUnsetteledScheduledPaymentsOfCourseMutation, { data, loading, error }] = useResetAllUnsetteledScheduledPaymentsOfCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useResetAllUnsetteledScheduledPaymentsOfCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables>(ResetAllUnsetteledScheduledPaymentsOfCourseDocument, baseOptions);
      }
export type ResetAllUnsetteledScheduledPaymentsOfCourseMutationHookResult = ReturnType<typeof useResetAllUnsetteledScheduledPaymentsOfCourseMutation>;
export type ResetAllUnsetteledScheduledPaymentsOfCourseMutationResult = ApolloReactCommon.MutationResult<ResetAllUnsetteledScheduledPaymentsOfCourseMutation>;
export type ResetAllUnsetteledScheduledPaymentsOfCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetAllUnsetteledScheduledPaymentsOfCourseMutation, ResetAllUnsetteledScheduledPaymentsOfCourseMutationVariables>;
export const ScheduledPaymentsByBookerIdDocument = gql`
    query ScheduledPaymentsByBookerId($bookerId: ID!) {
  scheduledPaymentsByBookerId(bookerId: $bookerId) {
    id
    paymentDue
    paymentAmount
    openAmount
    paymentDone
    createDate
    sepaClearance
    paymentMethodId
  }
}
    `;
export type ScheduledPaymentsByBookerIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>, 'query'> & ({ variables: ScheduledPaymentsByBookerIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ScheduledPaymentsByBookerIdComponent = (props: ScheduledPaymentsByBookerIdComponentProps) => (
      <ApolloReactComponents.Query<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables> query={ScheduledPaymentsByBookerIdDocument} {...props} />
    );
    
export type ScheduledPaymentsByBookerIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables> & TChildProps;
export function withScheduledPaymentsByBookerId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ScheduledPaymentsByBookerIdQuery,
  ScheduledPaymentsByBookerIdQueryVariables,
  ScheduledPaymentsByBookerIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables, ScheduledPaymentsByBookerIdProps<TChildProps>>(ScheduledPaymentsByBookerIdDocument, {
      alias: 'scheduledPaymentsByBookerId',
      ...operationOptions
    });
};

/**
 * __useScheduledPaymentsByBookerIdQuery__
 *
 * To run a query within a React component, call `useScheduledPaymentsByBookerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledPaymentsByBookerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledPaymentsByBookerIdQuery({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *   },
 * });
 */
export function useScheduledPaymentsByBookerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>(ScheduledPaymentsByBookerIdDocument, baseOptions);
      }
export function useScheduledPaymentsByBookerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>(ScheduledPaymentsByBookerIdDocument, baseOptions);
        }
export type ScheduledPaymentsByBookerIdQueryHookResult = ReturnType<typeof useScheduledPaymentsByBookerIdQuery>;
export type ScheduledPaymentsByBookerIdLazyQueryHookResult = ReturnType<typeof useScheduledPaymentsByBookerIdLazyQuery>;
export type ScheduledPaymentsByBookerIdQueryResult = ApolloReactCommon.QueryResult<ScheduledPaymentsByBookerIdQuery, ScheduledPaymentsByBookerIdQueryVariables>;
export const SetScheduledPaymentPaidDocument = gql`
    mutation SetScheduledPaymentPaid($id: String!, $paymentDone: DateTime!) {
  setScheduledPaymentPaid(id: $id, paymentDone: $paymentDone) {
    id
    paymentDone
  }
}
    `;
export type SetScheduledPaymentPaidMutationFn = ApolloReactCommon.MutationFunction<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables>;
export type SetScheduledPaymentPaidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables>, 'mutation'>;

    export const SetScheduledPaymentPaidComponent = (props: SetScheduledPaymentPaidComponentProps) => (
      <ApolloReactComponents.Mutation<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables> mutation={SetScheduledPaymentPaidDocument} {...props} />
    );
    
export type SetScheduledPaymentPaidProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables> & TChildProps;
export function withSetScheduledPaymentPaid<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetScheduledPaymentPaidMutation,
  SetScheduledPaymentPaidMutationVariables,
  SetScheduledPaymentPaidProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables, SetScheduledPaymentPaidProps<TChildProps>>(SetScheduledPaymentPaidDocument, {
      alias: 'setScheduledPaymentPaid',
      ...operationOptions
    });
};

/**
 * __useSetScheduledPaymentPaidMutation__
 *
 * To run a mutation, you first call `useSetScheduledPaymentPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScheduledPaymentPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScheduledPaymentPaidMutation, { data, loading, error }] = useSetScheduledPaymentPaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentDone: // value for 'paymentDone'
 *   },
 * });
 */
export function useSetScheduledPaymentPaidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables>) {
        return ApolloReactHooks.useMutation<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables>(SetScheduledPaymentPaidDocument, baseOptions);
      }
export type SetScheduledPaymentPaidMutationHookResult = ReturnType<typeof useSetScheduledPaymentPaidMutation>;
export type SetScheduledPaymentPaidMutationResult = ApolloReactCommon.MutationResult<SetScheduledPaymentPaidMutation>;
export type SetScheduledPaymentPaidMutationOptions = ApolloReactCommon.BaseMutationOptions<SetScheduledPaymentPaidMutation, SetScheduledPaymentPaidMutationVariables>;
export const SetScheduledPaymentUnpaidDocument = gql`
    mutation SetScheduledPaymentUnpaid($id: String!) {
  setScheduledPaymentUnpaid(id: $id) {
    id
    paymentDone
  }
}
    `;
export type SetScheduledPaymentUnpaidMutationFn = ApolloReactCommon.MutationFunction<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables>;
export type SetScheduledPaymentUnpaidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables>, 'mutation'>;

    export const SetScheduledPaymentUnpaidComponent = (props: SetScheduledPaymentUnpaidComponentProps) => (
      <ApolloReactComponents.Mutation<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables> mutation={SetScheduledPaymentUnpaidDocument} {...props} />
    );
    
export type SetScheduledPaymentUnpaidProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables> & TChildProps;
export function withSetScheduledPaymentUnpaid<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetScheduledPaymentUnpaidMutation,
  SetScheduledPaymentUnpaidMutationVariables,
  SetScheduledPaymentUnpaidProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables, SetScheduledPaymentUnpaidProps<TChildProps>>(SetScheduledPaymentUnpaidDocument, {
      alias: 'setScheduledPaymentUnpaid',
      ...operationOptions
    });
};

/**
 * __useSetScheduledPaymentUnpaidMutation__
 *
 * To run a mutation, you first call `useSetScheduledPaymentUnpaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScheduledPaymentUnpaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScheduledPaymentUnpaidMutation, { data, loading, error }] = useSetScheduledPaymentUnpaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetScheduledPaymentUnpaidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables>) {
        return ApolloReactHooks.useMutation<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables>(SetScheduledPaymentUnpaidDocument, baseOptions);
      }
export type SetScheduledPaymentUnpaidMutationHookResult = ReturnType<typeof useSetScheduledPaymentUnpaidMutation>;
export type SetScheduledPaymentUnpaidMutationResult = ApolloReactCommon.MutationResult<SetScheduledPaymentUnpaidMutation>;
export type SetScheduledPaymentUnpaidMutationOptions = ApolloReactCommon.BaseMutationOptions<SetScheduledPaymentUnpaidMutation, SetScheduledPaymentUnpaidMutationVariables>;
export const SetNewPaymentDoneDateDocument = gql`
    mutation SetNewPaymentDoneDate($id: String!, $paymentDoneDate: DateTime!) {
  setNewPaymentDoneDate(id: $id, paymentDoneDate: $paymentDoneDate) {
    id
    paymentDone
  }
}
    `;
export type SetNewPaymentDoneDateMutationFn = ApolloReactCommon.MutationFunction<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables>;
export type SetNewPaymentDoneDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables>, 'mutation'>;

    export const SetNewPaymentDoneDateComponent = (props: SetNewPaymentDoneDateComponentProps) => (
      <ApolloReactComponents.Mutation<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables> mutation={SetNewPaymentDoneDateDocument} {...props} />
    );
    
export type SetNewPaymentDoneDateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables> & TChildProps;
export function withSetNewPaymentDoneDate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetNewPaymentDoneDateMutation,
  SetNewPaymentDoneDateMutationVariables,
  SetNewPaymentDoneDateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables, SetNewPaymentDoneDateProps<TChildProps>>(SetNewPaymentDoneDateDocument, {
      alias: 'setNewPaymentDoneDate',
      ...operationOptions
    });
};

/**
 * __useSetNewPaymentDoneDateMutation__
 *
 * To run a mutation, you first call `useSetNewPaymentDoneDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPaymentDoneDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPaymentDoneDateMutation, { data, loading, error }] = useSetNewPaymentDoneDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentDoneDate: // value for 'paymentDoneDate'
 *   },
 * });
 */
export function useSetNewPaymentDoneDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables>(SetNewPaymentDoneDateDocument, baseOptions);
      }
export type SetNewPaymentDoneDateMutationHookResult = ReturnType<typeof useSetNewPaymentDoneDateMutation>;
export type SetNewPaymentDoneDateMutationResult = ApolloReactCommon.MutationResult<SetNewPaymentDoneDateMutation>;
export type SetNewPaymentDoneDateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetNewPaymentDoneDateMutation, SetNewPaymentDoneDateMutationVariables>;
export const SetNewPaymentAmountDocument = gql`
    mutation SetNewPaymentAmount($id: String!, $paymentAmount: Float!) {
  setNewPaymentAmount(id: $id, paymentAmount: $paymentAmount) {
    id
    paymentAmount
    openAmount
  }
}
    `;
export type SetNewPaymentAmountMutationFn = ApolloReactCommon.MutationFunction<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables>;
export type SetNewPaymentAmountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables>, 'mutation'>;

    export const SetNewPaymentAmountComponent = (props: SetNewPaymentAmountComponentProps) => (
      <ApolloReactComponents.Mutation<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables> mutation={SetNewPaymentAmountDocument} {...props} />
    );
    
export type SetNewPaymentAmountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables> & TChildProps;
export function withSetNewPaymentAmount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetNewPaymentAmountMutation,
  SetNewPaymentAmountMutationVariables,
  SetNewPaymentAmountProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables, SetNewPaymentAmountProps<TChildProps>>(SetNewPaymentAmountDocument, {
      alias: 'setNewPaymentAmount',
      ...operationOptions
    });
};

/**
 * __useSetNewPaymentAmountMutation__
 *
 * To run a mutation, you first call `useSetNewPaymentAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPaymentAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPaymentAmountMutation, { data, loading, error }] = useSetNewPaymentAmountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentAmount: // value for 'paymentAmount'
 *   },
 * });
 */
export function useSetNewPaymentAmountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables>) {
        return ApolloReactHooks.useMutation<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables>(SetNewPaymentAmountDocument, baseOptions);
      }
export type SetNewPaymentAmountMutationHookResult = ReturnType<typeof useSetNewPaymentAmountMutation>;
export type SetNewPaymentAmountMutationResult = ApolloReactCommon.MutationResult<SetNewPaymentAmountMutation>;
export type SetNewPaymentAmountMutationOptions = ApolloReactCommon.BaseMutationOptions<SetNewPaymentAmountMutation, SetNewPaymentAmountMutationVariables>;
export const ModifyScheduledPaymentDocument = gql`
    mutation ModifyScheduledPayment($modifyScheduledPaymentInput: ModifyScheduledPaymentInput!) {
  modifyScheduledPayment(modifyScheduledPaymentInput: $modifyScheduledPaymentInput) {
    id
    paymentAmount
    openAmount
    paymentDue
    paymentDone
    paymentMethodId
    sepaClearance
  }
}
    `;
export type ModifyScheduledPaymentMutationFn = ApolloReactCommon.MutationFunction<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables>;
export type ModifyScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables>, 'mutation'>;

    export const ModifyScheduledPaymentComponent = (props: ModifyScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables> mutation={ModifyScheduledPaymentDocument} {...props} />
    );
    
export type ModifyScheduledPaymentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables> & TChildProps;
export function withModifyScheduledPayment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModifyScheduledPaymentMutation,
  ModifyScheduledPaymentMutationVariables,
  ModifyScheduledPaymentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables, ModifyScheduledPaymentProps<TChildProps>>(ModifyScheduledPaymentDocument, {
      alias: 'modifyScheduledPayment',
      ...operationOptions
    });
};

/**
 * __useModifyScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useModifyScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyScheduledPaymentMutation, { data, loading, error }] = useModifyScheduledPaymentMutation({
 *   variables: {
 *      modifyScheduledPaymentInput: // value for 'modifyScheduledPaymentInput'
 *   },
 * });
 */
export function useModifyScheduledPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables>(ModifyScheduledPaymentDocument, baseOptions);
      }
export type ModifyScheduledPaymentMutationHookResult = ReturnType<typeof useModifyScheduledPaymentMutation>;
export type ModifyScheduledPaymentMutationResult = ApolloReactCommon.MutationResult<ModifyScheduledPaymentMutation>;
export type ModifyScheduledPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<ModifyScheduledPaymentMutation, ModifyScheduledPaymentMutationVariables>;
export const GenerateScheduledPaymentsForSettledBookingDocument = gql`
    mutation GenerateScheduledPaymentsForSettledBooking($bookingId: ID!, $savePayments: Boolean) {
  generateScheduledPaymentsForSettledBooking(bookingId: $bookingId, savePayments: $savePayments) {
    id
    paymentAmount
    openAmount
    paymentDue
    paymentDone
    paymentMethodId
    sepaClearance
  }
}
    `;
export type GenerateScheduledPaymentsForSettledBookingMutationFn = ApolloReactCommon.MutationFunction<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables>;
export type GenerateScheduledPaymentsForSettledBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables>, 'mutation'>;

    export const GenerateScheduledPaymentsForSettledBookingComponent = (props: GenerateScheduledPaymentsForSettledBookingComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables> mutation={GenerateScheduledPaymentsForSettledBookingDocument} {...props} />
    );
    
export type GenerateScheduledPaymentsForSettledBookingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables> & TChildProps;
export function withGenerateScheduledPaymentsForSettledBooking<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateScheduledPaymentsForSettledBookingMutation,
  GenerateScheduledPaymentsForSettledBookingMutationVariables,
  GenerateScheduledPaymentsForSettledBookingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables, GenerateScheduledPaymentsForSettledBookingProps<TChildProps>>(GenerateScheduledPaymentsForSettledBookingDocument, {
      alias: 'generateScheduledPaymentsForSettledBooking',
      ...operationOptions
    });
};

/**
 * __useGenerateScheduledPaymentsForSettledBookingMutation__
 *
 * To run a mutation, you first call `useGenerateScheduledPaymentsForSettledBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateScheduledPaymentsForSettledBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateScheduledPaymentsForSettledBookingMutation, { data, loading, error }] = useGenerateScheduledPaymentsForSettledBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      savePayments: // value for 'savePayments'
 *   },
 * });
 */
export function useGenerateScheduledPaymentsForSettledBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables>(GenerateScheduledPaymentsForSettledBookingDocument, baseOptions);
      }
export type GenerateScheduledPaymentsForSettledBookingMutationHookResult = ReturnType<typeof useGenerateScheduledPaymentsForSettledBookingMutation>;
export type GenerateScheduledPaymentsForSettledBookingMutationResult = ApolloReactCommon.MutationResult<GenerateScheduledPaymentsForSettledBookingMutation>;
export type GenerateScheduledPaymentsForSettledBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateScheduledPaymentsForSettledBookingMutation, GenerateScheduledPaymentsForSettledBookingMutationVariables>;
export const AddNamedScheduledPaymentDocument = gql`
    mutation AddNamedScheduledPayment($addNamedScheduledPaymentInput: AddNamedScheduledPaymentInput!) {
  addNamedScheduledPayment(addNamedScheduledPaymentInput: $addNamedScheduledPaymentInput) {
    id
    paymentAmount
    openAmount
    paymentDue
    paymentDone
    paymentMethodId
    sepaClearance
  }
}
    `;
export type AddNamedScheduledPaymentMutationFn = ApolloReactCommon.MutationFunction<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables>;
export type AddNamedScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables>, 'mutation'>;

    export const AddNamedScheduledPaymentComponent = (props: AddNamedScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables> mutation={AddNamedScheduledPaymentDocument} {...props} />
    );
    
export type AddNamedScheduledPaymentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables> & TChildProps;
export function withAddNamedScheduledPayment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddNamedScheduledPaymentMutation,
  AddNamedScheduledPaymentMutationVariables,
  AddNamedScheduledPaymentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables, AddNamedScheduledPaymentProps<TChildProps>>(AddNamedScheduledPaymentDocument, {
      alias: 'addNamedScheduledPayment',
      ...operationOptions
    });
};

/**
 * __useAddNamedScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useAddNamedScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNamedScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNamedScheduledPaymentMutation, { data, loading, error }] = useAddNamedScheduledPaymentMutation({
 *   variables: {
 *      addNamedScheduledPaymentInput: // value for 'addNamedScheduledPaymentInput'
 *   },
 * });
 */
export function useAddNamedScheduledPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables>(AddNamedScheduledPaymentDocument, baseOptions);
      }
export type AddNamedScheduledPaymentMutationHookResult = ReturnType<typeof useAddNamedScheduledPaymentMutation>;
export type AddNamedScheduledPaymentMutationResult = ApolloReactCommon.MutationResult<AddNamedScheduledPaymentMutation>;
export type AddNamedScheduledPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNamedScheduledPaymentMutation, AddNamedScheduledPaymentMutationVariables>;
export const GenerateScheduledPaymentsByCourseDocument = gql`
    mutation GenerateScheduledPaymentsByCourse($courseId: ID!, $attendeeId: ID, $omitMemberDiscount: Boolean, $creditedBalance: Float, $currentScheduledPayments: [ScheduledPaymentSimpleInput!], $paymentType: PaymentType, $memberDiscount: Float) {
  generateScheduledPaymentsByCourse(courseId: $courseId, attendeeId: $attendeeId, omitMemberDiscount: $omitMemberDiscount, creditedBalance: $creditedBalance, currentScheduledPayments: $currentScheduledPayments, paymentType: $paymentType, memberDiscount: $memberDiscount) {
    scheduledPayments {
      id
      paymentAmount
      paymentDue
      type
    }
    grossPrice
    netPrice
    memberDiscount
  }
}
    `;
export type GenerateScheduledPaymentsByCourseMutationFn = ApolloReactCommon.MutationFunction<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables>;
export type GenerateScheduledPaymentsByCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables>, 'mutation'>;

    export const GenerateScheduledPaymentsByCourseComponent = (props: GenerateScheduledPaymentsByCourseComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables> mutation={GenerateScheduledPaymentsByCourseDocument} {...props} />
    );
    
export type GenerateScheduledPaymentsByCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables> & TChildProps;
export function withGenerateScheduledPaymentsByCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateScheduledPaymentsByCourseMutation,
  GenerateScheduledPaymentsByCourseMutationVariables,
  GenerateScheduledPaymentsByCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables, GenerateScheduledPaymentsByCourseProps<TChildProps>>(GenerateScheduledPaymentsByCourseDocument, {
      alias: 'generateScheduledPaymentsByCourse',
      ...operationOptions
    });
};

/**
 * __useGenerateScheduledPaymentsByCourseMutation__
 *
 * To run a mutation, you first call `useGenerateScheduledPaymentsByCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateScheduledPaymentsByCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateScheduledPaymentsByCourseMutation, { data, loading, error }] = useGenerateScheduledPaymentsByCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      attendeeId: // value for 'attendeeId'
 *      omitMemberDiscount: // value for 'omitMemberDiscount'
 *      creditedBalance: // value for 'creditedBalance'
 *      currentScheduledPayments: // value for 'currentScheduledPayments'
 *      paymentType: // value for 'paymentType'
 *      memberDiscount: // value for 'memberDiscount'
 *   },
 * });
 */
export function useGenerateScheduledPaymentsByCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables>(GenerateScheduledPaymentsByCourseDocument, baseOptions);
      }
export type GenerateScheduledPaymentsByCourseMutationHookResult = ReturnType<typeof useGenerateScheduledPaymentsByCourseMutation>;
export type GenerateScheduledPaymentsByCourseMutationResult = ApolloReactCommon.MutationResult<GenerateScheduledPaymentsByCourseMutation>;
export type GenerateScheduledPaymentsByCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateScheduledPaymentsByCourseMutation, GenerateScheduledPaymentsByCourseMutationVariables>;
export const CreateSepaXmlByIdDocument = gql`
    mutation createSepaXmlById($createSingleSepaXmlInput: CreateSingleSepaXmlInput!) {
  createSepaXmlById(createSingleSepaXmlInput: $createSingleSepaXmlInput)
}
    `;
export type CreateSepaXmlByIdMutationFn = ApolloReactCommon.MutationFunction<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables>;
export type CreateSepaXmlByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables>, 'mutation'>;

    export const CreateSepaXmlByIdComponent = (props: CreateSepaXmlByIdComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables> mutation={CreateSepaXmlByIdDocument} {...props} />
    );
    
export type CreateSepaXmlByIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables> & TChildProps;
export function withCreateSepaXmlById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSepaXmlByIdMutation,
  CreateSepaXmlByIdMutationVariables,
  CreateSepaXmlByIdProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables, CreateSepaXmlByIdProps<TChildProps>>(CreateSepaXmlByIdDocument, {
      alias: 'createSepaXmlById',
      ...operationOptions
    });
};

/**
 * __useCreateSepaXmlByIdMutation__
 *
 * To run a mutation, you first call `useCreateSepaXmlByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSepaXmlByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSepaXmlByIdMutation, { data, loading, error }] = useCreateSepaXmlByIdMutation({
 *   variables: {
 *      createSingleSepaXmlInput: // value for 'createSingleSepaXmlInput'
 *   },
 * });
 */
export function useCreateSepaXmlByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables>(CreateSepaXmlByIdDocument, baseOptions);
      }
export type CreateSepaXmlByIdMutationHookResult = ReturnType<typeof useCreateSepaXmlByIdMutation>;
export type CreateSepaXmlByIdMutationResult = ApolloReactCommon.MutationResult<CreateSepaXmlByIdMutation>;
export type CreateSepaXmlByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSepaXmlByIdMutation, CreateSepaXmlByIdMutationVariables>;
export const CreateSepaXmlByTimeRangeDocument = gql`
    mutation createSepaXmlByTimeRange($createSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput!) {
  createSepaXmlByTimeRange(createSepaXmlByTimeRangeInput: $createSepaXmlByTimeRangeInput)
}
    `;
export type CreateSepaXmlByTimeRangeMutationFn = ApolloReactCommon.MutationFunction<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables>;
export type CreateSepaXmlByTimeRangeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables>, 'mutation'>;

    export const CreateSepaXmlByTimeRangeComponent = (props: CreateSepaXmlByTimeRangeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables> mutation={CreateSepaXmlByTimeRangeDocument} {...props} />
    );
    
export type CreateSepaXmlByTimeRangeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables> & TChildProps;
export function withCreateSepaXmlByTimeRange<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSepaXmlByTimeRangeMutation,
  CreateSepaXmlByTimeRangeMutationVariables,
  CreateSepaXmlByTimeRangeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables, CreateSepaXmlByTimeRangeProps<TChildProps>>(CreateSepaXmlByTimeRangeDocument, {
      alias: 'createSepaXmlByTimeRange',
      ...operationOptions
    });
};

/**
 * __useCreateSepaXmlByTimeRangeMutation__
 *
 * To run a mutation, you first call `useCreateSepaXmlByTimeRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSepaXmlByTimeRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSepaXmlByTimeRangeMutation, { data, loading, error }] = useCreateSepaXmlByTimeRangeMutation({
 *   variables: {
 *      createSepaXmlByTimeRangeInput: // value for 'createSepaXmlByTimeRangeInput'
 *   },
 * });
 */
export function useCreateSepaXmlByTimeRangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables>(CreateSepaXmlByTimeRangeDocument, baseOptions);
      }
export type CreateSepaXmlByTimeRangeMutationHookResult = ReturnType<typeof useCreateSepaXmlByTimeRangeMutation>;
export type CreateSepaXmlByTimeRangeMutationResult = ApolloReactCommon.MutationResult<CreateSepaXmlByTimeRangeMutation>;
export type CreateSepaXmlByTimeRangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSepaXmlByTimeRangeMutation, CreateSepaXmlByTimeRangeMutationVariables>;
export const GetBankDataDocument = gql`
    query getBankData($iban: String!) {
  getBankData(iban: $iban)
}
    `;
export type GetBankDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBankDataQuery, GetBankDataQueryVariables>, 'query'> & ({ variables: GetBankDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBankDataComponent = (props: GetBankDataComponentProps) => (
      <ApolloReactComponents.Query<GetBankDataQuery, GetBankDataQueryVariables> query={GetBankDataDocument} {...props} />
    );
    
export type GetBankDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBankDataQuery, GetBankDataQueryVariables> & TChildProps;
export function withGetBankData<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBankDataQuery,
  GetBankDataQueryVariables,
  GetBankDataProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBankDataQuery, GetBankDataQueryVariables, GetBankDataProps<TChildProps>>(GetBankDataDocument, {
      alias: 'getBankData',
      ...operationOptions
    });
};

/**
 * __useGetBankDataQuery__
 *
 * To run a query within a React component, call `useGetBankDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankDataQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useGetBankDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankDataQuery, GetBankDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankDataQuery, GetBankDataQueryVariables>(GetBankDataDocument, baseOptions);
      }
export function useGetBankDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankDataQuery, GetBankDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankDataQuery, GetBankDataQueryVariables>(GetBankDataDocument, baseOptions);
        }
export type GetBankDataQueryHookResult = ReturnType<typeof useGetBankDataQuery>;
export type GetBankDataLazyQueryHookResult = ReturnType<typeof useGetBankDataLazyQuery>;
export type GetBankDataQueryResult = ApolloReactCommon.QueryResult<GetBankDataQuery, GetBankDataQueryVariables>;
export const GetRestrictiveBookingsByCourseIdDocument = gql`
    query GetRestrictiveBookingsByCourseId($courseId: String!) {
  getBookingRestrictionByCourseId(courseId: $courseId) {
    ... on BookingRestriction {
      id
      courseId
      active
      visibleForAll
      blockedPlaces
      startDateTime
      endDateTime
      includeWaitingList
      includeBookingList
      membersOnly
      prerequisiteCourses {
        id
      }
      expireAction
    }
  }
}
    `;
export type GetRestrictiveBookingsByCourseIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>, 'query'> & ({ variables: GetRestrictiveBookingsByCourseIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRestrictiveBookingsByCourseIdComponent = (props: GetRestrictiveBookingsByCourseIdComponentProps) => (
      <ApolloReactComponents.Query<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables> query={GetRestrictiveBookingsByCourseIdDocument} {...props} />
    );
    
export type GetRestrictiveBookingsByCourseIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables> & TChildProps;
export function withGetRestrictiveBookingsByCourseId<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRestrictiveBookingsByCourseIdQuery,
  GetRestrictiveBookingsByCourseIdQueryVariables,
  GetRestrictiveBookingsByCourseIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables, GetRestrictiveBookingsByCourseIdProps<TChildProps>>(GetRestrictiveBookingsByCourseIdDocument, {
      alias: 'getRestrictiveBookingsByCourseId',
      ...operationOptions
    });
};

/**
 * __useGetRestrictiveBookingsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetRestrictiveBookingsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestrictiveBookingsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestrictiveBookingsByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetRestrictiveBookingsByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>(GetRestrictiveBookingsByCourseIdDocument, baseOptions);
      }
export function useGetRestrictiveBookingsByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>(GetRestrictiveBookingsByCourseIdDocument, baseOptions);
        }
export type GetRestrictiveBookingsByCourseIdQueryHookResult = ReturnType<typeof useGetRestrictiveBookingsByCourseIdQuery>;
export type GetRestrictiveBookingsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetRestrictiveBookingsByCourseIdLazyQuery>;
export type GetRestrictiveBookingsByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetRestrictiveBookingsByCourseIdQuery, GetRestrictiveBookingsByCourseIdQueryVariables>;
export const CreateBookingRestrictionDocument = gql`
    mutation CreateBookingRestriction($newBookingRestrictionInput: NewBookingRestrictionInput!) {
  createBookingRestriction(newBookingRestrictionInput: $newBookingRestrictionInput) {
    id
    active
    visibleForAll
    blockedPlaces
    startDateTime
    endDateTime
    includeWaitingList
    includeBookingList
    membersOnly
    prerequisiteCourses {
      id
    }
    expireAction
  }
}
    `;
export type CreateBookingRestrictionMutationFn = ApolloReactCommon.MutationFunction<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables>;
export type CreateBookingRestrictionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables>, 'mutation'>;

    export const CreateBookingRestrictionComponent = (props: CreateBookingRestrictionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables> mutation={CreateBookingRestrictionDocument} {...props} />
    );
    
export type CreateBookingRestrictionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables> & TChildProps;
export function withCreateBookingRestriction<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBookingRestrictionMutation,
  CreateBookingRestrictionMutationVariables,
  CreateBookingRestrictionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables, CreateBookingRestrictionProps<TChildProps>>(CreateBookingRestrictionDocument, {
      alias: 'createBookingRestriction',
      ...operationOptions
    });
};

/**
 * __useCreateBookingRestrictionMutation__
 *
 * To run a mutation, you first call `useCreateBookingRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingRestrictionMutation, { data, loading, error }] = useCreateBookingRestrictionMutation({
 *   variables: {
 *      newBookingRestrictionInput: // value for 'newBookingRestrictionInput'
 *   },
 * });
 */
export function useCreateBookingRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables>(CreateBookingRestrictionDocument, baseOptions);
      }
export type CreateBookingRestrictionMutationHookResult = ReturnType<typeof useCreateBookingRestrictionMutation>;
export type CreateBookingRestrictionMutationResult = ApolloReactCommon.MutationResult<CreateBookingRestrictionMutation>;
export type CreateBookingRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookingRestrictionMutation, CreateBookingRestrictionMutationVariables>;
export const UpdateBookingRestrictionDocument = gql`
    mutation UpdateBookingRestriction($updateBookingRestrictionInput: UpdateBookingRestrictionInput!) {
  updateBookingRestriction(updateBookingRestrictionInput: $updateBookingRestrictionInput) {
    id
    active
    visibleForAll
    blockedPlaces
    startDateTime
    endDateTime
    includeWaitingList
    includeBookingList
    membersOnly
    prerequisiteCourses {
      id
    }
    expireAction
  }
}
    `;
export type UpdateBookingRestrictionMutationFn = ApolloReactCommon.MutationFunction<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables>;
export type UpdateBookingRestrictionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables>, 'mutation'>;

    export const UpdateBookingRestrictionComponent = (props: UpdateBookingRestrictionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables> mutation={UpdateBookingRestrictionDocument} {...props} />
    );
    
export type UpdateBookingRestrictionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables> & TChildProps;
export function withUpdateBookingRestriction<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBookingRestrictionMutation,
  UpdateBookingRestrictionMutationVariables,
  UpdateBookingRestrictionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables, UpdateBookingRestrictionProps<TChildProps>>(UpdateBookingRestrictionDocument, {
      alias: 'updateBookingRestriction',
      ...operationOptions
    });
};

/**
 * __useUpdateBookingRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateBookingRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingRestrictionMutation, { data, loading, error }] = useUpdateBookingRestrictionMutation({
 *   variables: {
 *      updateBookingRestrictionInput: // value for 'updateBookingRestrictionInput'
 *   },
 * });
 */
export function useUpdateBookingRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables>(UpdateBookingRestrictionDocument, baseOptions);
      }
export type UpdateBookingRestrictionMutationHookResult = ReturnType<typeof useUpdateBookingRestrictionMutation>;
export type UpdateBookingRestrictionMutationResult = ApolloReactCommon.MutationResult<UpdateBookingRestrictionMutation>;
export type UpdateBookingRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookingRestrictionMutation, UpdateBookingRestrictionMutationVariables>;
export const DeleteBookingRestrictionDocument = gql`
    mutation DeleteBookingRestriction($bookingRestrictionId: String!) {
  deleteBookingRestriction(bookingRestrictionId: $bookingRestrictionId)
}
    `;
export type DeleteBookingRestrictionMutationFn = ApolloReactCommon.MutationFunction<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables>;
export type DeleteBookingRestrictionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables>, 'mutation'>;

    export const DeleteBookingRestrictionComponent = (props: DeleteBookingRestrictionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables> mutation={DeleteBookingRestrictionDocument} {...props} />
    );
    
export type DeleteBookingRestrictionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables> & TChildProps;
export function withDeleteBookingRestriction<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteBookingRestrictionMutation,
  DeleteBookingRestrictionMutationVariables,
  DeleteBookingRestrictionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables, DeleteBookingRestrictionProps<TChildProps>>(DeleteBookingRestrictionDocument, {
      alias: 'deleteBookingRestriction',
      ...operationOptions
    });
};

/**
 * __useDeleteBookingRestrictionMutation__
 *
 * To run a mutation, you first call `useDeleteBookingRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingRestrictionMutation, { data, loading, error }] = useDeleteBookingRestrictionMutation({
 *   variables: {
 *      bookingRestrictionId: // value for 'bookingRestrictionId'
 *   },
 * });
 */
export function useDeleteBookingRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables>(DeleteBookingRestrictionDocument, baseOptions);
      }
export type DeleteBookingRestrictionMutationHookResult = ReturnType<typeof useDeleteBookingRestrictionMutation>;
export type DeleteBookingRestrictionMutationResult = ApolloReactCommon.MutationResult<DeleteBookingRestrictionMutation>;
export type DeleteBookingRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBookingRestrictionMutation, DeleteBookingRestrictionMutationVariables>;
export const AddBookableCoursesDocument = gql`
    mutation AddBookableCourses($prerequisiteCourseId: String!, $bookableCourseIds: [String!]!) {
  addBookableCourses(prerequisiteCourseId: $prerequisiteCourseId, bookableCourseIds: $bookableCourseIds)
}
    `;
export type AddBookableCoursesMutationFn = ApolloReactCommon.MutationFunction<AddBookableCoursesMutation, AddBookableCoursesMutationVariables>;
export type AddBookableCoursesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddBookableCoursesMutation, AddBookableCoursesMutationVariables>, 'mutation'>;

    export const AddBookableCoursesComponent = (props: AddBookableCoursesComponentProps) => (
      <ApolloReactComponents.Mutation<AddBookableCoursesMutation, AddBookableCoursesMutationVariables> mutation={AddBookableCoursesDocument} {...props} />
    );
    
export type AddBookableCoursesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddBookableCoursesMutation, AddBookableCoursesMutationVariables> & TChildProps;
export function withAddBookableCourses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddBookableCoursesMutation,
  AddBookableCoursesMutationVariables,
  AddBookableCoursesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddBookableCoursesMutation, AddBookableCoursesMutationVariables, AddBookableCoursesProps<TChildProps>>(AddBookableCoursesDocument, {
      alias: 'addBookableCourses',
      ...operationOptions
    });
};

/**
 * __useAddBookableCoursesMutation__
 *
 * To run a mutation, you first call `useAddBookableCoursesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookableCoursesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookableCoursesMutation, { data, loading, error }] = useAddBookableCoursesMutation({
 *   variables: {
 *      prerequisiteCourseId: // value for 'prerequisiteCourseId'
 *      bookableCourseIds: // value for 'bookableCourseIds'
 *   },
 * });
 */
export function useAddBookableCoursesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBookableCoursesMutation, AddBookableCoursesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddBookableCoursesMutation, AddBookableCoursesMutationVariables>(AddBookableCoursesDocument, baseOptions);
      }
export type AddBookableCoursesMutationHookResult = ReturnType<typeof useAddBookableCoursesMutation>;
export type AddBookableCoursesMutationResult = ApolloReactCommon.MutationResult<AddBookableCoursesMutation>;
export type AddBookableCoursesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBookableCoursesMutation, AddBookableCoursesMutationVariables>;
export const RemoveBookableCourseDocument = gql`
    mutation RemoveBookableCourse($bookableCourseId: String!, $prerquisiteCourseId: String!) {
  removeBookableCourse(bookableCourseId: $bookableCourseId, prerquisiteCourseId: $prerquisiteCourseId)
}
    `;
export type RemoveBookableCourseMutationFn = ApolloReactCommon.MutationFunction<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables>;
export type RemoveBookableCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables>, 'mutation'>;

    export const RemoveBookableCourseComponent = (props: RemoveBookableCourseComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables> mutation={RemoveBookableCourseDocument} {...props} />
    );
    
export type RemoveBookableCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables> & TChildProps;
export function withRemoveBookableCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveBookableCourseMutation,
  RemoveBookableCourseMutationVariables,
  RemoveBookableCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables, RemoveBookableCourseProps<TChildProps>>(RemoveBookableCourseDocument, {
      alias: 'removeBookableCourse',
      ...operationOptions
    });
};

/**
 * __useRemoveBookableCourseMutation__
 *
 * To run a mutation, you first call `useRemoveBookableCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookableCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookableCourseMutation, { data, loading, error }] = useRemoveBookableCourseMutation({
 *   variables: {
 *      bookableCourseId: // value for 'bookableCourseId'
 *      prerquisiteCourseId: // value for 'prerquisiteCourseId'
 *   },
 * });
 */
export function useRemoveBookableCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables>(RemoveBookableCourseDocument, baseOptions);
      }
export type RemoveBookableCourseMutationHookResult = ReturnType<typeof useRemoveBookableCourseMutation>;
export type RemoveBookableCourseMutationResult = ApolloReactCommon.MutationResult<RemoveBookableCourseMutation>;
export type RemoveBookableCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBookableCourseMutation, RemoveBookableCourseMutationVariables>;
export const ImportBookersDocument = gql`
    mutation ImportBookers($importBookersInput: ImportBookersInput!) {
  importBookers(importBookersInput: $importBookersInput)
}
    `;
export type ImportBookersMutationFn = ApolloReactCommon.MutationFunction<ImportBookersMutation, ImportBookersMutationVariables>;
export type ImportBookersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportBookersMutation, ImportBookersMutationVariables>, 'mutation'>;

    export const ImportBookersComponent = (props: ImportBookersComponentProps) => (
      <ApolloReactComponents.Mutation<ImportBookersMutation, ImportBookersMutationVariables> mutation={ImportBookersDocument} {...props} />
    );
    
export type ImportBookersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportBookersMutation, ImportBookersMutationVariables> & TChildProps;
export function withImportBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportBookersMutation,
  ImportBookersMutationVariables,
  ImportBookersProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportBookersMutation, ImportBookersMutationVariables, ImportBookersProps<TChildProps>>(ImportBookersDocument, {
      alias: 'importBookers',
      ...operationOptions
    });
};

/**
 * __useImportBookersMutation__
 *
 * To run a mutation, you first call `useImportBookersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBookersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBookersMutation, { data, loading, error }] = useImportBookersMutation({
 *   variables: {
 *      importBookersInput: // value for 'importBookersInput'
 *   },
 * });
 */
export function useImportBookersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportBookersMutation, ImportBookersMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportBookersMutation, ImportBookersMutationVariables>(ImportBookersDocument, baseOptions);
      }
export type ImportBookersMutationHookResult = ReturnType<typeof useImportBookersMutation>;
export type ImportBookersMutationResult = ApolloReactCommon.MutationResult<ImportBookersMutation>;
export type ImportBookersMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportBookersMutation, ImportBookersMutationVariables>;
export const VerifyBookersDataDocument = gql`
    mutation VerifyBookersData($importBookersInput: ImportBookersInput!) {
  verifyBookersData(importBookersInput: $importBookersInput)
}
    `;
export type VerifyBookersDataMutationFn = ApolloReactCommon.MutationFunction<VerifyBookersDataMutation, VerifyBookersDataMutationVariables>;
export type VerifyBookersDataComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyBookersDataMutation, VerifyBookersDataMutationVariables>, 'mutation'>;

    export const VerifyBookersDataComponent = (props: VerifyBookersDataComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyBookersDataMutation, VerifyBookersDataMutationVariables> mutation={VerifyBookersDataDocument} {...props} />
    );
    
export type VerifyBookersDataProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerifyBookersDataMutation, VerifyBookersDataMutationVariables> & TChildProps;
export function withVerifyBookersData<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerifyBookersDataMutation,
  VerifyBookersDataMutationVariables,
  VerifyBookersDataProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerifyBookersDataMutation, VerifyBookersDataMutationVariables, VerifyBookersDataProps<TChildProps>>(VerifyBookersDataDocument, {
      alias: 'verifyBookersData',
      ...operationOptions
    });
};

/**
 * __useVerifyBookersDataMutation__
 *
 * To run a mutation, you first call `useVerifyBookersDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBookersDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBookersDataMutation, { data, loading, error }] = useVerifyBookersDataMutation({
 *   variables: {
 *      importBookersInput: // value for 'importBookersInput'
 *   },
 * });
 */
export function useVerifyBookersDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyBookersDataMutation, VerifyBookersDataMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyBookersDataMutation, VerifyBookersDataMutationVariables>(VerifyBookersDataDocument, baseOptions);
      }
export type VerifyBookersDataMutationHookResult = ReturnType<typeof useVerifyBookersDataMutation>;
export type VerifyBookersDataMutationResult = ApolloReactCommon.MutationResult<VerifyBookersDataMutation>;
export type VerifyBookersDataMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyBookersDataMutation, VerifyBookersDataMutationVariables>;
export const DeleteFusionAuthUsersByEmailsDocument = gql`
    mutation DeleteFusionAuthUsersByEmails($emailAddresses: [String!]!) {
  deleteFusionAuthUsersByEmails(emailAddresses: $emailAddresses)
}
    `;
export type DeleteFusionAuthUsersByEmailsMutationFn = ApolloReactCommon.MutationFunction<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables>;
export type DeleteFusionAuthUsersByEmailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables>, 'mutation'>;

    export const DeleteFusionAuthUsersByEmailsComponent = (props: DeleteFusionAuthUsersByEmailsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables> mutation={DeleteFusionAuthUsersByEmailsDocument} {...props} />
    );
    
export type DeleteFusionAuthUsersByEmailsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables> & TChildProps;
export function withDeleteFusionAuthUsersByEmails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFusionAuthUsersByEmailsMutation,
  DeleteFusionAuthUsersByEmailsMutationVariables,
  DeleteFusionAuthUsersByEmailsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables, DeleteFusionAuthUsersByEmailsProps<TChildProps>>(DeleteFusionAuthUsersByEmailsDocument, {
      alias: 'deleteFusionAuthUsersByEmails',
      ...operationOptions
    });
};

/**
 * __useDeleteFusionAuthUsersByEmailsMutation__
 *
 * To run a mutation, you first call `useDeleteFusionAuthUsersByEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFusionAuthUsersByEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFusionAuthUsersByEmailsMutation, { data, loading, error }] = useDeleteFusionAuthUsersByEmailsMutation({
 *   variables: {
 *      emailAddresses: // value for 'emailAddresses'
 *   },
 * });
 */
export function useDeleteFusionAuthUsersByEmailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables>(DeleteFusionAuthUsersByEmailsDocument, baseOptions);
      }
export type DeleteFusionAuthUsersByEmailsMutationHookResult = ReturnType<typeof useDeleteFusionAuthUsersByEmailsMutation>;
export type DeleteFusionAuthUsersByEmailsMutationResult = ApolloReactCommon.MutationResult<DeleteFusionAuthUsersByEmailsMutation>;
export type DeleteFusionAuthUsersByEmailsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFusionAuthUsersByEmailsMutation, DeleteFusionAuthUsersByEmailsMutationVariables>;
export const SettingEndUserAppDocument = gql`
    query SettingEndUserApp {
  settingEndUserApp {
    colors
    courseTable
    courseDetails
    filters
    textEntries
    optionalInputFields
    hideAfterDays
    passwordlessLoginEnabled
    passwordlessIdCodeLength
  }
}
    `;
export type SettingEndUserAppComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>, 'query'>;

    export const SettingEndUserAppComponent = (props: SettingEndUserAppComponentProps) => (
      <ApolloReactComponents.Query<SettingEndUserAppQuery, SettingEndUserAppQueryVariables> query={SettingEndUserAppDocument} {...props} />
    );
    
export type SettingEndUserAppProps<TChildProps = {}> = ApolloReactHoc.DataProps<SettingEndUserAppQuery, SettingEndUserAppQueryVariables> & TChildProps;
export function withSettingEndUserApp<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SettingEndUserAppQuery,
  SettingEndUserAppQueryVariables,
  SettingEndUserAppProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SettingEndUserAppQuery, SettingEndUserAppQueryVariables, SettingEndUserAppProps<TChildProps>>(SettingEndUserAppDocument, {
      alias: 'settingEndUserApp',
      ...operationOptions
    });
};

/**
 * __useSettingEndUserAppQuery__
 *
 * To run a query within a React component, call `useSettingEndUserAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingEndUserAppQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingEndUserAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingEndUserAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>) {
        return ApolloReactHooks.useQuery<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>(SettingEndUserAppDocument, baseOptions);
      }
export function useSettingEndUserAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>(SettingEndUserAppDocument, baseOptions);
        }
export type SettingEndUserAppQueryHookResult = ReturnType<typeof useSettingEndUserAppQuery>;
export type SettingEndUserAppLazyQueryHookResult = ReturnType<typeof useSettingEndUserAppLazyQuery>;
export type SettingEndUserAppQueryResult = ApolloReactCommon.QueryResult<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>;
export const UpdateSettingEndUserAppDocument = gql`
    mutation UpdateSettingEndUserApp($updateSettingEndUserAppInput: UpdateSettingEndUserAppInput!) {
  updateSettingEndUserApp(updateSettingEndUserAppInput: $updateSettingEndUserAppInput) {
    id
    colors
    courseTable
    courseDetails
    filters
    textEntries
    optionalInputFields
    hideAfterDays
    passwordlessLoginEnabled
    passwordlessIdCodeLength
  }
}
    `;
export type UpdateSettingEndUserAppMutationFn = ApolloReactCommon.MutationFunction<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables>;
export type UpdateSettingEndUserAppComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables>, 'mutation'>;

    export const UpdateSettingEndUserAppComponent = (props: UpdateSettingEndUserAppComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables> mutation={UpdateSettingEndUserAppDocument} {...props} />
    );
    
export type UpdateSettingEndUserAppProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables> & TChildProps;
export function withUpdateSettingEndUserApp<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSettingEndUserAppMutation,
  UpdateSettingEndUserAppMutationVariables,
  UpdateSettingEndUserAppProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables, UpdateSettingEndUserAppProps<TChildProps>>(UpdateSettingEndUserAppDocument, {
      alias: 'updateSettingEndUserApp',
      ...operationOptions
    });
};

/**
 * __useUpdateSettingEndUserAppMutation__
 *
 * To run a mutation, you first call `useUpdateSettingEndUserAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingEndUserAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingEndUserAppMutation, { data, loading, error }] = useUpdateSettingEndUserAppMutation({
 *   variables: {
 *      updateSettingEndUserAppInput: // value for 'updateSettingEndUserAppInput'
 *   },
 * });
 */
export function useUpdateSettingEndUserAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables>(UpdateSettingEndUserAppDocument, baseOptions);
      }
export type UpdateSettingEndUserAppMutationHookResult = ReturnType<typeof useUpdateSettingEndUserAppMutation>;
export type UpdateSettingEndUserAppMutationResult = ApolloReactCommon.MutationResult<UpdateSettingEndUserAppMutation>;
export type UpdateSettingEndUserAppMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSettingEndUserAppMutation, UpdateSettingEndUserAppMutationVariables>;
export const CreateSettingEndUserAppWithDefaultValuesDocument = gql`
    mutation CreateSettingEndUserAppWithDefaultValues {
  createSettingEndUserAppWithDefaultValues {
    id
    colors
    courseTable
    courseDetails
    filters
    textEntries
    optionalInputFields
    hideAfterDays
    passwordlessLoginEnabled
    passwordlessIdCodeLength
  }
}
    `;
export type CreateSettingEndUserAppWithDefaultValuesMutationFn = ApolloReactCommon.MutationFunction<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables>;
export type CreateSettingEndUserAppWithDefaultValuesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables>, 'mutation'>;

    export const CreateSettingEndUserAppWithDefaultValuesComponent = (props: CreateSettingEndUserAppWithDefaultValuesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables> mutation={CreateSettingEndUserAppWithDefaultValuesDocument} {...props} />
    );
    
export type CreateSettingEndUserAppWithDefaultValuesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables> & TChildProps;
export function withCreateSettingEndUserAppWithDefaultValues<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSettingEndUserAppWithDefaultValuesMutation,
  CreateSettingEndUserAppWithDefaultValuesMutationVariables,
  CreateSettingEndUserAppWithDefaultValuesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables, CreateSettingEndUserAppWithDefaultValuesProps<TChildProps>>(CreateSettingEndUserAppWithDefaultValuesDocument, {
      alias: 'createSettingEndUserAppWithDefaultValues',
      ...operationOptions
    });
};

/**
 * __useCreateSettingEndUserAppWithDefaultValuesMutation__
 *
 * To run a mutation, you first call `useCreateSettingEndUserAppWithDefaultValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSettingEndUserAppWithDefaultValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSettingEndUserAppWithDefaultValuesMutation, { data, loading, error }] = useCreateSettingEndUserAppWithDefaultValuesMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSettingEndUserAppWithDefaultValuesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables>(CreateSettingEndUserAppWithDefaultValuesDocument, baseOptions);
      }
export type CreateSettingEndUserAppWithDefaultValuesMutationHookResult = ReturnType<typeof useCreateSettingEndUserAppWithDefaultValuesMutation>;
export type CreateSettingEndUserAppWithDefaultValuesMutationResult = ApolloReactCommon.MutationResult<CreateSettingEndUserAppWithDefaultValuesMutation>;
export type CreateSettingEndUserAppWithDefaultValuesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSettingEndUserAppWithDefaultValuesMutation, CreateSettingEndUserAppWithDefaultValuesMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  uploadCompanyLogo(file: $file)
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export type UploadFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadFileMutation, UploadFileMutationVariables>, 'mutation'>;

    export const UploadFileComponent = (props: UploadFileComponentProps) => (
      <ApolloReactComponents.Mutation<UploadFileMutation, UploadFileMutationVariables> mutation={UploadFileDocument} {...props} />
    );
    
export type UploadFileProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UploadFileMutation, UploadFileMutationVariables> & TChildProps;
export function withUploadFile<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadFileMutation,
  UploadFileMutationVariables,
  UploadFileProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UploadFileMutation, UploadFileMutationVariables, UploadFileProps<TChildProps>>(UploadFileDocument, {
      alias: 'uploadFile',
      ...operationOptions
    });
};

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const DeleteCompanyLogoDocument = gql`
    mutation DeleteCompanyLogo {
  deleteCompanyLogo {
    id
    logo
  }
}
    `;
export type DeleteCompanyLogoMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>;
export type DeleteCompanyLogoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>, 'mutation'>;

    export const DeleteCompanyLogoComponent = (props: DeleteCompanyLogoComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables> mutation={DeleteCompanyLogoDocument} {...props} />
    );
    
export type DeleteCompanyLogoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables> & TChildProps;
export function withDeleteCompanyLogo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCompanyLogoMutation,
  DeleteCompanyLogoMutationVariables,
  DeleteCompanyLogoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables, DeleteCompanyLogoProps<TChildProps>>(DeleteCompanyLogoDocument, {
      alias: 'deleteCompanyLogo',
      ...operationOptions
    });
};

/**
 * __useDeleteCompanyLogoMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyLogoMutation, { data, loading, error }] = useDeleteCompanyLogoMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCompanyLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>(DeleteCompanyLogoDocument, baseOptions);
      }
export type DeleteCompanyLogoMutationHookResult = ReturnType<typeof useDeleteCompanyLogoMutation>;
export type DeleteCompanyLogoMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyLogoMutation>;
export type DeleteCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>;
export const GetAppSettingsDocument = gql`
    query GetAppSettings {
  settings {
    id
    courseNumberFormat
    customerNumberPrefix
    invoiceNumberPrefix
    currency
    grossNet
    defaultVat
    cash
    bankTransfer
    sepaDebit
    esr
    invoiceDueDays
    firstInstallmentDayOfMonth
    firstInstallmentMonth
    vatAppearance
    holidayStateCode
    holidayStateColor
    sevenIoApiKey
    smsSenderName
    sendReversalInvoice
  }
}
    `;
export type GetAppSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>, 'query'>;

    export const GetAppSettingsComponent = (props: GetAppSettingsComponentProps) => (
      <ApolloReactComponents.Query<GetAppSettingsQuery, GetAppSettingsQueryVariables> query={GetAppSettingsDocument} {...props} />
    );
    
export type GetAppSettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAppSettingsQuery, GetAppSettingsQueryVariables> & TChildProps;
export function withGetAppSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAppSettingsQuery,
  GetAppSettingsQueryVariables,
  GetAppSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAppSettingsQuery, GetAppSettingsQueryVariables, GetAppSettingsProps<TChildProps>>(GetAppSettingsDocument, {
      alias: 'getAppSettings',
      ...operationOptions
    });
};

/**
 * __useGetAppSettingsQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, baseOptions);
      }
export function useGetAppSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, baseOptions);
        }
export type GetAppSettingsQueryHookResult = ReturnType<typeof useGetAppSettingsQuery>;
export type GetAppSettingsLazyQueryHookResult = ReturnType<typeof useGetAppSettingsLazyQuery>;
export type GetAppSettingsQueryResult = ApolloReactCommon.QueryResult<GetAppSettingsQuery, GetAppSettingsQueryVariables>;
export const GetAppSettingsInvoiceDueDaysDocument = gql`
    query GetAppSettingsInvoiceDueDays {
  settings {
    id
    invoiceDueDays
  }
}
    `;
export type GetAppSettingsInvoiceDueDaysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>, 'query'>;

    export const GetAppSettingsInvoiceDueDaysComponent = (props: GetAppSettingsInvoiceDueDaysComponentProps) => (
      <ApolloReactComponents.Query<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables> query={GetAppSettingsInvoiceDueDaysDocument} {...props} />
    );
    
export type GetAppSettingsInvoiceDueDaysProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables> & TChildProps;
export function withGetAppSettingsInvoiceDueDays<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAppSettingsInvoiceDueDaysQuery,
  GetAppSettingsInvoiceDueDaysQueryVariables,
  GetAppSettingsInvoiceDueDaysProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables, GetAppSettingsInvoiceDueDaysProps<TChildProps>>(GetAppSettingsInvoiceDueDaysDocument, {
      alias: 'getAppSettingsInvoiceDueDays',
      ...operationOptions
    });
};

/**
 * __useGetAppSettingsInvoiceDueDaysQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsInvoiceDueDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsInvoiceDueDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsInvoiceDueDaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsInvoiceDueDaysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>(GetAppSettingsInvoiceDueDaysDocument, baseOptions);
      }
export function useGetAppSettingsInvoiceDueDaysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>(GetAppSettingsInvoiceDueDaysDocument, baseOptions);
        }
export type GetAppSettingsInvoiceDueDaysQueryHookResult = ReturnType<typeof useGetAppSettingsInvoiceDueDaysQuery>;
export type GetAppSettingsInvoiceDueDaysLazyQueryHookResult = ReturnType<typeof useGetAppSettingsInvoiceDueDaysLazyQuery>;
export type GetAppSettingsInvoiceDueDaysQueryResult = ApolloReactCommon.QueryResult<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>;
export const GetCourseNumberFormatDocument = gql`
    query GetCourseNumberFormat {
  settings {
    courseNumberFormat
  }
}
    `;
export type GetCourseNumberFormatComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>, 'query'>;

    export const GetCourseNumberFormatComponent = (props: GetCourseNumberFormatComponentProps) => (
      <ApolloReactComponents.Query<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables> query={GetCourseNumberFormatDocument} {...props} />
    );
    
export type GetCourseNumberFormatProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables> & TChildProps;
export function withGetCourseNumberFormat<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCourseNumberFormatQuery,
  GetCourseNumberFormatQueryVariables,
  GetCourseNumberFormatProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables, GetCourseNumberFormatProps<TChildProps>>(GetCourseNumberFormatDocument, {
      alias: 'getCourseNumberFormat',
      ...operationOptions
    });
};

/**
 * __useGetCourseNumberFormatQuery__
 *
 * To run a query within a React component, call `useGetCourseNumberFormatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseNumberFormatQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseNumberFormatQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseNumberFormatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>(GetCourseNumberFormatDocument, baseOptions);
      }
export function useGetCourseNumberFormatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>(GetCourseNumberFormatDocument, baseOptions);
        }
export type GetCourseNumberFormatQueryHookResult = ReturnType<typeof useGetCourseNumberFormatQuery>;
export type GetCourseNumberFormatLazyQueryHookResult = ReturnType<typeof useGetCourseNumberFormatLazyQuery>;
export type GetCourseNumberFormatQueryResult = ApolloReactCommon.QueryResult<GetCourseNumberFormatQuery, GetCourseNumberFormatQueryVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($newSettingsData: SettingInput!) {
  updateSettings(newSettingsData: $newSettingsData) {
    id
    courseNumberFormat
    customerNumberPrefix
    invoiceNumberPrefix
    currency
    grossNet
    defaultVat
    cash
    bankTransfer
    sepaDebit
    esr
    invoiceDueDays
    holidayStateCode
    holidayStateColor
    sendReversalInvoice
  }
}
    `;
export type UpdateSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export type UpdateSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>, 'mutation'>;

    export const UpdateSettingsComponent = (props: UpdateSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSettingsMutation, UpdateSettingsMutationVariables> mutation={UpdateSettingsDocument} {...props} />
    );
    
export type UpdateSettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateSettingsMutation, UpdateSettingsMutationVariables> & TChildProps;
export function withUpdateSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables,
  UpdateSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSettingsMutation, UpdateSettingsMutationVariables, UpdateSettingsProps<TChildProps>>(UpdateSettingsDocument, {
      alias: 'updateSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      newSettingsData: // value for 'newSettingsData'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, baseOptions);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export const GetCompanySettingsDocument = gql`
    query GetCompanySettings {
  companySettings {
    id
    name
    businessForm
    website
    agb
    privacyStatement
    ownerFirstName
    ownerLastName
    ownerStreet
    ownerStreetNumber
    ownerZip
    ownerCity
    ownerCountry
    ownerPhone
    ownerFax
    ownerEmail
    industry
    localCourt
    commercialRegister
    taxId
    taxNumber
    bank
    bankAccountHolder
    IBAN
    BIC
    creditorId
    qrIban
    logo
    koOrganization {
      id
    }
  }
}
    `;
export type GetCompanySettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>, 'query'>;

    export const GetCompanySettingsComponent = (props: GetCompanySettingsComponentProps) => (
      <ApolloReactComponents.Query<GetCompanySettingsQuery, GetCompanySettingsQueryVariables> query={GetCompanySettingsDocument} {...props} />
    );
    
export type GetCompanySettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanySettingsQuery, GetCompanySettingsQueryVariables> & TChildProps;
export function withGetCompanySettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanySettingsQuery,
  GetCompanySettingsQueryVariables,
  GetCompanySettingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanySettingsQuery, GetCompanySettingsQueryVariables, GetCompanySettingsProps<TChildProps>>(GetCompanySettingsDocument, {
      alias: 'getCompanySettings',
      ...operationOptions
    });
};

/**
 * __useGetCompanySettingsQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>(GetCompanySettingsDocument, baseOptions);
      }
export function useGetCompanySettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>(GetCompanySettingsDocument, baseOptions);
        }
export type GetCompanySettingsQueryHookResult = ReturnType<typeof useGetCompanySettingsQuery>;
export type GetCompanySettingsLazyQueryHookResult = ReturnType<typeof useGetCompanySettingsLazyQuery>;
export type GetCompanySettingsQueryResult = ApolloReactCommon.QueryResult<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>;
export const UpdateCompanySettingsDocument = gql`
    mutation updateCompanySettings($editCompanyData: CompanySettingInput!) {
  updateCompanySettings(newCompanyData: $editCompanyData) {
    id
    name
    businessForm
    website
    agb
    privacyStatement
    ownerFirstName
    ownerLastName
    ownerStreet
    ownerStreetNumber
    ownerZip
    ownerCity
    ownerCountry
    ownerPhone
    ownerFax
    ownerEmail
    industry
    localCourt
    commercialRegister
    taxId
    taxNumber
    bank
    bankAccountHolder
    IBAN
    BIC
    creditorId
    qrIban
  }
}
    `;
export type UpdateCompanySettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>;
export type UpdateCompanySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>, 'mutation'>;

    export const UpdateCompanySettingsComponent = (props: UpdateCompanySettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables> mutation={UpdateCompanySettingsDocument} {...props} />
    );
    
export type UpdateCompanySettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables> & TChildProps;
export function withUpdateCompanySettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanySettingsMutation,
  UpdateCompanySettingsMutationVariables,
  UpdateCompanySettingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables, UpdateCompanySettingsProps<TChildProps>>(UpdateCompanySettingsDocument, {
      alias: 'updateCompanySettings',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySettingsMutation, { data, loading, error }] = useUpdateCompanySettingsMutation({
 *   variables: {
 *      editCompanyData: // value for 'editCompanyData'
 *   },
 * });
 */
export function useUpdateCompanySettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>(UpdateCompanySettingsDocument, baseOptions);
      }
export type UpdateCompanySettingsMutationHookResult = ReturnType<typeof useUpdateCompanySettingsMutation>;
export type UpdateCompanySettingsMutationResult = ApolloReactCommon.MutationResult<UpdateCompanySettingsMutation>;
export type UpdateCompanySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>;
export const CreateTenantDocument = gql`
    mutation createTenant($koOrganizationData: KoOrganizationInputType!) {
  createOrganization(koOrganizationData: $koOrganizationData) {
    id
  }
}
    `;
export type CreateTenantMutationFn = ApolloReactCommon.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;
export type CreateTenantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTenantMutation, CreateTenantMutationVariables>, 'mutation'>;

    export const CreateTenantComponent = (props: CreateTenantComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTenantMutation, CreateTenantMutationVariables> mutation={CreateTenantDocument} {...props} />
    );
    
export type CreateTenantProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateTenantMutation, CreateTenantMutationVariables> & TChildProps;
export function withCreateTenant<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTenantMutation,
  CreateTenantMutationVariables,
  CreateTenantProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTenantMutation, CreateTenantMutationVariables, CreateTenantProps<TChildProps>>(CreateTenantDocument, {
      alias: 'createTenant',
      ...operationOptions
    });
};

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      koOrganizationData: // value for 'koOrganizationData'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, baseOptions);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = ApolloReactCommon.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const CreateAndRegisterAdminUserDocument = gql`
    mutation createAndRegisterAdminUser($userInput: UserInput!, $tenantId: String!) {
  createAndRegisterAdmin(userInput: $userInput, tenantId: $tenantId) {
    id
  }
}
    `;
export type CreateAndRegisterAdminUserMutationFn = ApolloReactCommon.MutationFunction<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables>;
export type CreateAndRegisterAdminUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables>, 'mutation'>;

    export const CreateAndRegisterAdminUserComponent = (props: CreateAndRegisterAdminUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables> mutation={CreateAndRegisterAdminUserDocument} {...props} />
    );
    
export type CreateAndRegisterAdminUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables> & TChildProps;
export function withCreateAndRegisterAdminUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAndRegisterAdminUserMutation,
  CreateAndRegisterAdminUserMutationVariables,
  CreateAndRegisterAdminUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables, CreateAndRegisterAdminUserProps<TChildProps>>(CreateAndRegisterAdminUserDocument, {
      alias: 'createAndRegisterAdminUser',
      ...operationOptions
    });
};

/**
 * __useCreateAndRegisterAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAndRegisterAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndRegisterAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndRegisterAdminUserMutation, { data, loading, error }] = useCreateAndRegisterAdminUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateAndRegisterAdminUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables>(CreateAndRegisterAdminUserDocument, baseOptions);
      }
export type CreateAndRegisterAdminUserMutationHookResult = ReturnType<typeof useCreateAndRegisterAdminUserMutation>;
export type CreateAndRegisterAdminUserMutationResult = ApolloReactCommon.MutationResult<CreateAndRegisterAdminUserMutation>;
export type CreateAndRegisterAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAndRegisterAdminUserMutation, CreateAndRegisterAdminUserMutationVariables>;
export const GetTenantsDocument = gql`
    query getTenants {
  koOrganizations {
    id
    name
    subscriptionType
    subscription {
      id
      featureSms
      featureStaffLogin
      featureRefactoredCourseDetails
      featureWeekPlaner
      featurePrerequisiteCourses
      featureExport
      holidays
      seatsAdmin
      seatsInstructor
    }
  }
}
    `;
export type GetTenantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantsQuery, GetTenantsQueryVariables>, 'query'>;

    export const GetTenantsComponent = (props: GetTenantsComponentProps) => (
      <ApolloReactComponents.Query<GetTenantsQuery, GetTenantsQueryVariables> query={GetTenantsDocument} {...props} />
    );
    
export type GetTenantsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTenantsQuery, GetTenantsQueryVariables> & TChildProps;
export function withGetTenants<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantsQuery,
  GetTenantsQueryVariables,
  GetTenantsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantsQuery, GetTenantsQueryVariables, GetTenantsProps<TChildProps>>(GetTenantsDocument, {
      alias: 'getTenants',
      ...operationOptions
    });
};

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, baseOptions);
      }
export function useGetTenantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, baseOptions);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = ApolloReactCommon.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetTenantsWithPaginationDocument = gql`
    query getTenantsWithPagination($options: ArrayDataOptions, $koOrganizationId: String) {
  tenantsWithPagination(options: $options, koOrganizationId: $koOrganizationId) {
    existsMore
    total
    items {
      id
      name
      subscriptionType
      subscription {
        id
        featureSms
        featureStaffLogin
        featureRefactoredCourseDetails
        featureWeekPlaner
        featurePrerequisiteCourses
        featureExport
        holidays
        seatsAdmin
        seatsInstructor
      }
    }
  }
}
    `;
export type GetTenantsWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>, 'query'>;

    export const GetTenantsWithPaginationComponent = (props: GetTenantsWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables> query={GetTenantsWithPaginationDocument} {...props} />
    );
    
export type GetTenantsWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables> & TChildProps;
export function withGetTenantsWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantsWithPaginationQuery,
  GetTenantsWithPaginationQueryVariables,
  GetTenantsWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables, GetTenantsWithPaginationProps<TChildProps>>(GetTenantsWithPaginationDocument, {
      alias: 'getTenantsWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetTenantsWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetTenantsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsWithPaginationQuery({
 *   variables: {
 *      options: // value for 'options'
 *      koOrganizationId: // value for 'koOrganizationId'
 *   },
 * });
 */
export function useGetTenantsWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>(GetTenantsWithPaginationDocument, baseOptions);
      }
export function useGetTenantsWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>(GetTenantsWithPaginationDocument, baseOptions);
        }
export type GetTenantsWithPaginationQueryHookResult = ReturnType<typeof useGetTenantsWithPaginationQuery>;
export type GetTenantsWithPaginationLazyQueryHookResult = ReturnType<typeof useGetTenantsWithPaginationLazyQuery>;
export type GetTenantsWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetTenantsWithPaginationQuery, GetTenantsWithPaginationQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany {
  companySettings {
    id
    name
  }
}
    `;
export type GetCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyQuery, GetCompanyQueryVariables>, 'query'>;

    export const GetCompanyComponent = (props: GetCompanyComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyQuery, GetCompanyQueryVariables> query={GetCompanyDocument} {...props} />
    );
    
export type GetCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyQuery, GetCompanyQueryVariables> & TChildProps;
export function withGetCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyQuery,
  GetCompanyQueryVariables,
  GetCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyQuery, GetCompanyQueryVariables, GetCompanyProps<TChildProps>>(GetCompanyDocument, {
      alias: 'getCompany',
      ...operationOptions
    });
};

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyInfoDocument = gql`
    query GetCompanyInfo {
  companyInfo {
    id
    name
    subscriptionType
    subscription {
      seatsAdmin
      seatsInstructor
      featureSms
      featureRefactoredCourseDetails
      featureStaffLogin
      featureWeekPlaner
      featurePrerequisiteCourses
      featureExport
    }
    companySettings {
      logo
    }
  }
}
    `;
export type GetCompanyInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>, 'query'>;

    export const GetCompanyInfoComponent = (props: GetCompanyInfoComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyInfoQuery, GetCompanyInfoQueryVariables> query={GetCompanyInfoDocument} {...props} />
    );
    
export type GetCompanyInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyInfoQuery, GetCompanyInfoQueryVariables> & TChildProps;
export function withGetCompanyInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyInfoQuery,
  GetCompanyInfoQueryVariables,
  GetCompanyInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyInfoQuery, GetCompanyInfoQueryVariables, GetCompanyInfoProps<TChildProps>>(GetCompanyInfoDocument, {
      alias: 'getCompanyInfo',
      ...operationOptions
    });
};

/**
 * __useGetCompanyInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, baseOptions);
      }
export function useGetCompanyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, baseOptions);
        }
export type GetCompanyInfoQueryHookResult = ReturnType<typeof useGetCompanyInfoQuery>;
export type GetCompanyInfoLazyQueryHookResult = ReturnType<typeof useGetCompanyInfoLazyQuery>;
export type GetCompanyInfoQueryResult = ApolloReactCommon.QueryResult<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>;
export const GetOwnerEmailDocument = gql`
    query GetOwnerEmail {
  ownerEmail {
    id
    ownerEmail
  }
}
    `;
export type GetOwnerEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>, 'query'>;

    export const GetOwnerEmailComponent = (props: GetOwnerEmailComponentProps) => (
      <ApolloReactComponents.Query<GetOwnerEmailQuery, GetOwnerEmailQueryVariables> query={GetOwnerEmailDocument} {...props} />
    );
    
export type GetOwnerEmailProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetOwnerEmailQuery, GetOwnerEmailQueryVariables> & TChildProps;
export function withGetOwnerEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOwnerEmailQuery,
  GetOwnerEmailQueryVariables,
  GetOwnerEmailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetOwnerEmailQuery, GetOwnerEmailQueryVariables, GetOwnerEmailProps<TChildProps>>(GetOwnerEmailDocument, {
      alias: 'getOwnerEmail',
      ...operationOptions
    });
};

/**
 * __useGetOwnerEmailQuery__
 *
 * To run a query within a React component, call `useGetOwnerEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnerEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>(GetOwnerEmailDocument, baseOptions);
      }
export function useGetOwnerEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>(GetOwnerEmailDocument, baseOptions);
        }
export type GetOwnerEmailQueryHookResult = ReturnType<typeof useGetOwnerEmailQuery>;
export type GetOwnerEmailLazyQueryHookResult = ReturnType<typeof useGetOwnerEmailLazyQuery>;
export type GetOwnerEmailQueryResult = ApolloReactCommon.QueryResult<GetOwnerEmailQuery, GetOwnerEmailQueryVariables>;
export const GetCompanyPublicDocument = gql`
    query GetCompanyPublic {
  companyPublic {
    name
    host
    website
    agb
    privacyStatement
  }
}
    `;
export type GetCompanyPublicComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>, 'query'>;

    export const GetCompanyPublicComponent = (props: GetCompanyPublicComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyPublicQuery, GetCompanyPublicQueryVariables> query={GetCompanyPublicDocument} {...props} />
    );
    
export type GetCompanyPublicProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyPublicQuery, GetCompanyPublicQueryVariables> & TChildProps;
export function withGetCompanyPublic<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyPublicQuery,
  GetCompanyPublicQueryVariables,
  GetCompanyPublicProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyPublicQuery, GetCompanyPublicQueryVariables, GetCompanyPublicProps<TChildProps>>(GetCompanyPublicDocument, {
      alias: 'getCompanyPublic',
      ...operationOptions
    });
};

/**
 * __useGetCompanyPublicQuery__
 *
 * To run a query within a React component, call `useGetCompanyPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>(GetCompanyPublicDocument, baseOptions);
      }
export function useGetCompanyPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>(GetCompanyPublicDocument, baseOptions);
        }
export type GetCompanyPublicQueryHookResult = ReturnType<typeof useGetCompanyPublicQuery>;
export type GetCompanyPublicLazyQueryHookResult = ReturnType<typeof useGetCompanyPublicLazyQuery>;
export type GetCompanyPublicQueryResult = ApolloReactCommon.QueryResult<GetCompanyPublicQuery, GetCompanyPublicQueryVariables>;
export const GetCurrencySettingDocument = gql`
    query GetCurrencySetting {
  settings {
    currency
  }
}
    `;
export type GetCurrencySettingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>, 'query'>;

    export const GetCurrencySettingComponent = (props: GetCurrencySettingComponentProps) => (
      <ApolloReactComponents.Query<GetCurrencySettingQuery, GetCurrencySettingQueryVariables> query={GetCurrencySettingDocument} {...props} />
    );
    
export type GetCurrencySettingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCurrencySettingQuery, GetCurrencySettingQueryVariables> & TChildProps;
export function withGetCurrencySetting<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCurrencySettingQuery,
  GetCurrencySettingQueryVariables,
  GetCurrencySettingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCurrencySettingQuery, GetCurrencySettingQueryVariables, GetCurrencySettingProps<TChildProps>>(GetCurrencySettingDocument, {
      alias: 'getCurrencySetting',
      ...operationOptions
    });
};

/**
 * __useGetCurrencySettingQuery__
 *
 * To run a query within a React component, call `useGetCurrencySettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencySettingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencySettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencySettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>(GetCurrencySettingDocument, baseOptions);
      }
export function useGetCurrencySettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>(GetCurrencySettingDocument, baseOptions);
        }
export type GetCurrencySettingQueryHookResult = ReturnType<typeof useGetCurrencySettingQuery>;
export type GetCurrencySettingLazyQueryHookResult = ReturnType<typeof useGetCurrencySettingLazyQuery>;
export type GetCurrencySettingQueryResult = ApolloReactCommon.QueryResult<GetCurrencySettingQuery, GetCurrencySettingQueryVariables>;
export const UpdateCompanyAgbDocument = gql`
    mutation UpdateCompanyAgb($agb: String!) {
  updateCompanyAgb(agb: $agb) {
    agb
  }
}
    `;
export type UpdateCompanyAgbMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables>;
export type UpdateCompanyAgbComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables>, 'mutation'>;

    export const UpdateCompanyAgbComponent = (props: UpdateCompanyAgbComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables> mutation={UpdateCompanyAgbDocument} {...props} />
    );
    
export type UpdateCompanyAgbProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables> & TChildProps;
export function withUpdateCompanyAgb<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyAgbMutation,
  UpdateCompanyAgbMutationVariables,
  UpdateCompanyAgbProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables, UpdateCompanyAgbProps<TChildProps>>(UpdateCompanyAgbDocument, {
      alias: 'updateCompanyAgb',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyAgbMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAgbMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAgbMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAgbMutation, { data, loading, error }] = useUpdateCompanyAgbMutation({
 *   variables: {
 *      agb: // value for 'agb'
 *   },
 * });
 */
export function useUpdateCompanyAgbMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables>(UpdateCompanyAgbDocument, baseOptions);
      }
export type UpdateCompanyAgbMutationHookResult = ReturnType<typeof useUpdateCompanyAgbMutation>;
export type UpdateCompanyAgbMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyAgbMutation>;
export type UpdateCompanyAgbMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyAgbMutation, UpdateCompanyAgbMutationVariables>;
export const UpdateCompanyPrivacyStatementDocument = gql`
    mutation UpdateCompanyPrivacyStatement($privacyStatement: String!) {
  updateCompanyPrivacyStatement(privacyStatement: $privacyStatement) {
    privacyStatement
  }
}
    `;
export type UpdateCompanyPrivacyStatementMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables>;
export type UpdateCompanyPrivacyStatementComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables>, 'mutation'>;

    export const UpdateCompanyPrivacyStatementComponent = (props: UpdateCompanyPrivacyStatementComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables> mutation={UpdateCompanyPrivacyStatementDocument} {...props} />
    );
    
export type UpdateCompanyPrivacyStatementProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables> & TChildProps;
export function withUpdateCompanyPrivacyStatement<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyPrivacyStatementMutation,
  UpdateCompanyPrivacyStatementMutationVariables,
  UpdateCompanyPrivacyStatementProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables, UpdateCompanyPrivacyStatementProps<TChildProps>>(UpdateCompanyPrivacyStatementDocument, {
      alias: 'updateCompanyPrivacyStatement',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyPrivacyStatementMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyPrivacyStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPrivacyStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPrivacyStatementMutation, { data, loading, error }] = useUpdateCompanyPrivacyStatementMutation({
 *   variables: {
 *      privacyStatement: // value for 'privacyStatement'
 *   },
 * });
 */
export function useUpdateCompanyPrivacyStatementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables>(UpdateCompanyPrivacyStatementDocument, baseOptions);
      }
export type UpdateCompanyPrivacyStatementMutationHookResult = ReturnType<typeof useUpdateCompanyPrivacyStatementMutation>;
export type UpdateCompanyPrivacyStatementMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyPrivacyStatementMutation>;
export type UpdateCompanyPrivacyStatementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyPrivacyStatementMutation, UpdateCompanyPrivacyStatementMutationVariables>;
export const UpdateSubscriptionFeaturesDocument = gql`
    mutation UpdateSubscriptionFeatures($subscriptionId: ID!, $features: SubscriptionFeaturesInput!) {
  updateSubscriptionFeatures(subscriptionId: $subscriptionId, features: $features) {
    id
    featureSms
    featureStaffLogin
    featureRefactoredCourseDetails
    featureWeekPlaner
    featurePrerequisiteCourses
    featureExport
    holidays
    seatsAdmin
    seatsInstructor
  }
}
    `;
export type UpdateSubscriptionFeaturesMutationFn = ApolloReactCommon.MutationFunction<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables>;
export type UpdateSubscriptionFeaturesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables>, 'mutation'>;

    export const UpdateSubscriptionFeaturesComponent = (props: UpdateSubscriptionFeaturesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables> mutation={UpdateSubscriptionFeaturesDocument} {...props} />
    );
    
export type UpdateSubscriptionFeaturesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables> & TChildProps;
export function withUpdateSubscriptionFeatures<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSubscriptionFeaturesMutation,
  UpdateSubscriptionFeaturesMutationVariables,
  UpdateSubscriptionFeaturesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables, UpdateSubscriptionFeaturesProps<TChildProps>>(UpdateSubscriptionFeaturesDocument, {
      alias: 'updateSubscriptionFeatures',
      ...operationOptions
    });
};

/**
 * __useUpdateSubscriptionFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionFeaturesMutation, { data, loading, error }] = useUpdateSubscriptionFeaturesMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useUpdateSubscriptionFeaturesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables>(UpdateSubscriptionFeaturesDocument, baseOptions);
      }
export type UpdateSubscriptionFeaturesMutationHookResult = ReturnType<typeof useUpdateSubscriptionFeaturesMutation>;
export type UpdateSubscriptionFeaturesMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionFeaturesMutation>;
export type UpdateSubscriptionFeaturesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubscriptionFeaturesMutation, UpdateSubscriptionFeaturesMutationVariables>;
export const GetTenantUsersDocument = gql`
    query GetTenantUsers($tenantId: ID!) {
  getTenantUsers(tenantId: $tenantId) {
    id
    firstName
    lastName
    email
    roles
  }
}
    `;
export type GetTenantUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTenantUsersQuery, GetTenantUsersQueryVariables>, 'query'> & ({ variables: GetTenantUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTenantUsersComponent = (props: GetTenantUsersComponentProps) => (
      <ApolloReactComponents.Query<GetTenantUsersQuery, GetTenantUsersQueryVariables> query={GetTenantUsersDocument} {...props} />
    );
    
export type GetTenantUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTenantUsersQuery, GetTenantUsersQueryVariables> & TChildProps;
export function withGetTenantUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTenantUsersQuery,
  GetTenantUsersQueryVariables,
  GetTenantUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTenantUsersQuery, GetTenantUsersQueryVariables, GetTenantUsersProps<TChildProps>>(GetTenantUsersDocument, {
      alias: 'getTenantUsers',
      ...operationOptions
    });
};

/**
 * __useGetTenantUsersQuery__
 *
 * To run a query within a React component, call `useGetTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantUsersQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetTenantUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTenantUsersQuery, GetTenantUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTenantUsersQuery, GetTenantUsersQueryVariables>(GetTenantUsersDocument, baseOptions);
      }
export function useGetTenantUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTenantUsersQuery, GetTenantUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTenantUsersQuery, GetTenantUsersQueryVariables>(GetTenantUsersDocument, baseOptions);
        }
export type GetTenantUsersQueryHookResult = ReturnType<typeof useGetTenantUsersQuery>;
export type GetTenantUsersLazyQueryHookResult = ReturnType<typeof useGetTenantUsersLazyQuery>;
export type GetTenantUsersQueryResult = ApolloReactCommon.QueryResult<GetTenantUsersQuery, GetTenantUsersQueryVariables>;
export const UpdateOrganizationSubscriptionTypeDocument = gql`
    mutation UpdateOrganizationSubscriptionType($koOrganizationId: ID!, $subscriptionType: SubscriptionType!) {
  updateOrganizationSubscriptionType(koOrganizationId: $koOrganizationId, subscriptionType: $subscriptionType) {
    id
    subscriptionType
  }
}
    `;
export type UpdateOrganizationSubscriptionTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables>;
export type UpdateOrganizationSubscriptionTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables>, 'mutation'>;

    export const UpdateOrganizationSubscriptionTypeComponent = (props: UpdateOrganizationSubscriptionTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables> mutation={UpdateOrganizationSubscriptionTypeDocument} {...props} />
    );
    
export type UpdateOrganizationSubscriptionTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables> & TChildProps;
export function withUpdateOrganizationSubscriptionType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationSubscriptionTypeMutation,
  UpdateOrganizationSubscriptionTypeMutationVariables,
  UpdateOrganizationSubscriptionTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables, UpdateOrganizationSubscriptionTypeProps<TChildProps>>(UpdateOrganizationSubscriptionTypeDocument, {
      alias: 'updateOrganizationSubscriptionType',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationSubscriptionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSubscriptionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSubscriptionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSubscriptionTypeMutation, { data, loading, error }] = useUpdateOrganizationSubscriptionTypeMutation({
 *   variables: {
 *      koOrganizationId: // value for 'koOrganizationId'
 *      subscriptionType: // value for 'subscriptionType'
 *   },
 * });
 */
export function useUpdateOrganizationSubscriptionTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables>(UpdateOrganizationSubscriptionTypeDocument, baseOptions);
      }
export type UpdateOrganizationSubscriptionTypeMutationHookResult = ReturnType<typeof useUpdateOrganizationSubscriptionTypeMutation>;
export type UpdateOrganizationSubscriptionTypeMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationSubscriptionTypeMutation>;
export type UpdateOrganizationSubscriptionTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationSubscriptionTypeMutation, UpdateOrganizationSubscriptionTypeMutationVariables>;
export const SaveAdminDocument = gql`
    mutation saveAdmin($adminInput: SaveAdminInput!) {
  saveAdmin(saveAdminData: $adminInput) {
    id
  }
}
    `;
export type SaveAdminMutationFn = ApolloReactCommon.MutationFunction<SaveAdminMutation, SaveAdminMutationVariables>;
export type SaveAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveAdminMutation, SaveAdminMutationVariables>, 'mutation'>;

    export const SaveAdminComponent = (props: SaveAdminComponentProps) => (
      <ApolloReactComponents.Mutation<SaveAdminMutation, SaveAdminMutationVariables> mutation={SaveAdminDocument} {...props} />
    );
    
export type SaveAdminProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveAdminMutation, SaveAdminMutationVariables> & TChildProps;
export function withSaveAdmin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveAdminMutation,
  SaveAdminMutationVariables,
  SaveAdminProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveAdminMutation, SaveAdminMutationVariables, SaveAdminProps<TChildProps>>(SaveAdminDocument, {
      alias: 'saveAdmin',
      ...operationOptions
    });
};

/**
 * __useSaveAdminMutation__
 *
 * To run a mutation, you first call `useSaveAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAdminMutation, { data, loading, error }] = useSaveAdminMutation({
 *   variables: {
 *      adminInput: // value for 'adminInput'
 *   },
 * });
 */
export function useSaveAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveAdminMutation, SaveAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveAdminMutation, SaveAdminMutationVariables>(SaveAdminDocument, baseOptions);
      }
export type SaveAdminMutationHookResult = ReturnType<typeof useSaveAdminMutation>;
export type SaveAdminMutationResult = ApolloReactCommon.MutationResult<SaveAdminMutation>;
export type SaveAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveAdminMutation, SaveAdminMutationVariables>;
export const CheckSmsApiKeyDocument = gql`
    query CheckSmsApiKey($apiKey: String!) {
  checkSmsApiKey(apiKey: $apiKey) {
    balance
  }
}
    `;
export type CheckSmsApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>, 'query'> & ({ variables: CheckSmsApiKeyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckSmsApiKeyComponent = (props: CheckSmsApiKeyComponentProps) => (
      <ApolloReactComponents.Query<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables> query={CheckSmsApiKeyDocument} {...props} />
    );
    
export type CheckSmsApiKeyProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables> & TChildProps;
export function withCheckSmsApiKey<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckSmsApiKeyQuery,
  CheckSmsApiKeyQueryVariables,
  CheckSmsApiKeyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables, CheckSmsApiKeyProps<TChildProps>>(CheckSmsApiKeyDocument, {
      alias: 'checkSmsApiKey',
      ...operationOptions
    });
};

/**
 * __useCheckSmsApiKeyQuery__
 *
 * To run a query within a React component, call `useCheckSmsApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSmsApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSmsApiKeyQuery({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useCheckSmsApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>(CheckSmsApiKeyDocument, baseOptions);
      }
export function useCheckSmsApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>(CheckSmsApiKeyDocument, baseOptions);
        }
export type CheckSmsApiKeyQueryHookResult = ReturnType<typeof useCheckSmsApiKeyQuery>;
export type CheckSmsApiKeyLazyQueryHookResult = ReturnType<typeof useCheckSmsApiKeyLazyQuery>;
export type CheckSmsApiKeyQueryResult = ApolloReactCommon.QueryResult<CheckSmsApiKeyQuery, CheckSmsApiKeyQueryVariables>;
export const GetSmsAccountBalanceDocument = gql`
    query GetSmsAccountBalance {
  getSmsAccountBalance {
    balance
  }
}
    `;
export type GetSmsAccountBalanceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>, 'query'>;

    export const GetSmsAccountBalanceComponent = (props: GetSmsAccountBalanceComponentProps) => (
      <ApolloReactComponents.Query<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables> query={GetSmsAccountBalanceDocument} {...props} />
    );
    
export type GetSmsAccountBalanceProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables> & TChildProps;
export function withGetSmsAccountBalance<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSmsAccountBalanceQuery,
  GetSmsAccountBalanceQueryVariables,
  GetSmsAccountBalanceProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables, GetSmsAccountBalanceProps<TChildProps>>(GetSmsAccountBalanceDocument, {
      alias: 'getSmsAccountBalance',
      ...operationOptions
    });
};

/**
 * __useGetSmsAccountBalanceQuery__
 *
 * To run a query within a React component, call `useGetSmsAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsAccountBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmsAccountBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>(GetSmsAccountBalanceDocument, baseOptions);
      }
export function useGetSmsAccountBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>(GetSmsAccountBalanceDocument, baseOptions);
        }
export type GetSmsAccountBalanceQueryHookResult = ReturnType<typeof useGetSmsAccountBalanceQuery>;
export type GetSmsAccountBalanceLazyQueryHookResult = ReturnType<typeof useGetSmsAccountBalanceLazyQuery>;
export type GetSmsAccountBalanceQueryResult = ApolloReactCommon.QueryResult<GetSmsAccountBalanceQuery, GetSmsAccountBalanceQueryVariables>;
export const GetSmsReceiversDocument = gql`
    query GetSmsReceivers($mailDataType: MailDataType!, $selectedIds: [String!]!) {
  getSmsReceivers(mailDataType: $mailDataType, selectedIds: $selectedIds) {
    bookerId
    fullName
    mobileNumber
    valid
    prefixedBookingNumber
  }
}
    `;
export type GetSmsReceiversComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>, 'query'> & ({ variables: GetSmsReceiversQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSmsReceiversComponent = (props: GetSmsReceiversComponentProps) => (
      <ApolloReactComponents.Query<GetSmsReceiversQuery, GetSmsReceiversQueryVariables> query={GetSmsReceiversDocument} {...props} />
    );
    
export type GetSmsReceiversProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSmsReceiversQuery, GetSmsReceiversQueryVariables> & TChildProps;
export function withGetSmsReceivers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSmsReceiversQuery,
  GetSmsReceiversQueryVariables,
  GetSmsReceiversProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSmsReceiversQuery, GetSmsReceiversQueryVariables, GetSmsReceiversProps<TChildProps>>(GetSmsReceiversDocument, {
      alias: 'getSmsReceivers',
      ...operationOptions
    });
};

/**
 * __useGetSmsReceiversQuery__
 *
 * To run a query within a React component, call `useGetSmsReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsReceiversQuery({
 *   variables: {
 *      mailDataType: // value for 'mailDataType'
 *      selectedIds: // value for 'selectedIds'
 *   },
 * });
 */
export function useGetSmsReceiversQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>(GetSmsReceiversDocument, baseOptions);
      }
export function useGetSmsReceiversLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>(GetSmsReceiversDocument, baseOptions);
        }
export type GetSmsReceiversQueryHookResult = ReturnType<typeof useGetSmsReceiversQuery>;
export type GetSmsReceiversLazyQueryHookResult = ReturnType<typeof useGetSmsReceiversLazyQuery>;
export type GetSmsReceiversQueryResult = ApolloReactCommon.QueryResult<GetSmsReceiversQuery, GetSmsReceiversQueryVariables>;
export const GetSmsReceiversCourseDocument = gql`
    query GetSmsReceiversCourse($getReceiversForCourseInput: GetReceiversForCourseInput!) {
  getSmsReceiversCourse(getReceiversForCourseInput: $getReceiversForCourseInput) {
    bookerId
    fullName
    mobileNumber
    valid
    prefixedBookingNumber
  }
}
    `;
export type GetSmsReceiversCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>, 'query'> & ({ variables: GetSmsReceiversCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSmsReceiversCourseComponent = (props: GetSmsReceiversCourseComponentProps) => (
      <ApolloReactComponents.Query<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables> query={GetSmsReceiversCourseDocument} {...props} />
    );
    
export type GetSmsReceiversCourseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables> & TChildProps;
export function withGetSmsReceiversCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSmsReceiversCourseQuery,
  GetSmsReceiversCourseQueryVariables,
  GetSmsReceiversCourseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables, GetSmsReceiversCourseProps<TChildProps>>(GetSmsReceiversCourseDocument, {
      alias: 'getSmsReceiversCourse',
      ...operationOptions
    });
};

/**
 * __useGetSmsReceiversCourseQuery__
 *
 * To run a query within a React component, call `useGetSmsReceiversCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsReceiversCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsReceiversCourseQuery({
 *   variables: {
 *      getReceiversForCourseInput: // value for 'getReceiversForCourseInput'
 *   },
 * });
 */
export function useGetSmsReceiversCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>(GetSmsReceiversCourseDocument, baseOptions);
      }
export function useGetSmsReceiversCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>(GetSmsReceiversCourseDocument, baseOptions);
        }
export type GetSmsReceiversCourseQueryHookResult = ReturnType<typeof useGetSmsReceiversCourseQuery>;
export type GetSmsReceiversCourseLazyQueryHookResult = ReturnType<typeof useGetSmsReceiversCourseLazyQuery>;
export type GetSmsReceiversCourseQueryResult = ApolloReactCommon.QueryResult<GetSmsReceiversCourseQuery, GetSmsReceiversCourseQueryVariables>;
export const SendSmsDocument = gql`
    mutation SendSms($smsParams: SendSmsInput!) {
  sendSms(smsParams: $smsParams) {
    success
    totalPrice
    balance
  }
}
    `;
export type SendSmsMutationFn = ApolloReactCommon.MutationFunction<SendSmsMutation, SendSmsMutationVariables>;
export type SendSmsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendSmsMutation, SendSmsMutationVariables>, 'mutation'>;

    export const SendSmsComponent = (props: SendSmsComponentProps) => (
      <ApolloReactComponents.Mutation<SendSmsMutation, SendSmsMutationVariables> mutation={SendSmsDocument} {...props} />
    );
    
export type SendSmsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendSmsMutation, SendSmsMutationVariables> & TChildProps;
export function withSendSms<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendSmsMutation,
  SendSmsMutationVariables,
  SendSmsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendSmsMutation, SendSmsMutationVariables, SendSmsProps<TChildProps>>(SendSmsDocument, {
      alias: 'sendSms',
      ...operationOptions
    });
};

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      smsParams: // value for 'smsParams'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, baseOptions);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = ApolloReactCommon.MutationResult<SendSmsMutation>;
export type SendSmsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>;
export const StaffSaveInstructorDocument = gql`
    mutation StaffSaveInstructor($saveInstructorData: SaveInstructorInput!) {
  saveInstructor(saveInstructorData: $saveInstructorData) {
    id
    level
    salutation
    assignedLocations {
      id
    }
    firstname
    lastname
    active
    showInWeb
    birthDate
    birthPlace
    annotation
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    joinDate
    leaveDate
    socialSecurityNumber
    healthInsuranceFund
    rate
    vat
    accountHolder
    iban
    bic
    bank
    taxId
    allowLogin
  }
}
    `;
export type StaffSaveInstructorMutationFn = ApolloReactCommon.MutationFunction<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables>;
export type StaffSaveInstructorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables>, 'mutation'>;

    export const StaffSaveInstructorComponent = (props: StaffSaveInstructorComponentProps) => (
      <ApolloReactComponents.Mutation<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables> mutation={StaffSaveInstructorDocument} {...props} />
    );
    
export type StaffSaveInstructorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables> & TChildProps;
export function withStaffSaveInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaffSaveInstructorMutation,
  StaffSaveInstructorMutationVariables,
  StaffSaveInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables, StaffSaveInstructorProps<TChildProps>>(StaffSaveInstructorDocument, {
      alias: 'staffSaveInstructor',
      ...operationOptions
    });
};

/**
 * __useStaffSaveInstructorMutation__
 *
 * To run a mutation, you first call `useStaffSaveInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffSaveInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffSaveInstructorMutation, { data, loading, error }] = useStaffSaveInstructorMutation({
 *   variables: {
 *      saveInstructorData: // value for 'saveInstructorData'
 *   },
 * });
 */
export function useStaffSaveInstructorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables>(StaffSaveInstructorDocument, baseOptions);
      }
export type StaffSaveInstructorMutationHookResult = ReturnType<typeof useStaffSaveInstructorMutation>;
export type StaffSaveInstructorMutationResult = ApolloReactCommon.MutationResult<StaffSaveInstructorMutation>;
export type StaffSaveInstructorMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffSaveInstructorMutation, StaffSaveInstructorMutationVariables>;
export const StaffGetInstructorDocument = gql`
    query StaffGetInstructor($id: String!) {
  instructor(id: $id) {
    id
    userId
    level
    assignedLocations {
      id
    }
    salutation
    firstname
    lastname
    active
    showInWeb
    birthDate
    birthPlace
    annotation
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    joinDate
    leaveDate
    socialSecurityNumber
    healthInsuranceFund
    rate
    vat
    accountHolder
    iban
    bic
    bank
    email
    taxId
    allowLogin
    customFields
  }
}
    `;
export type StaffGetInstructorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>, 'query'> & ({ variables: StaffGetInstructorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffGetInstructorComponent = (props: StaffGetInstructorComponentProps) => (
      <ApolloReactComponents.Query<StaffGetInstructorQuery, StaffGetInstructorQueryVariables> query={StaffGetInstructorDocument} {...props} />
    );
    
export type StaffGetInstructorProps<TChildProps = {}> = ApolloReactHoc.DataProps<StaffGetInstructorQuery, StaffGetInstructorQueryVariables> & TChildProps;
export function withStaffGetInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaffGetInstructorQuery,
  StaffGetInstructorQueryVariables,
  StaffGetInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, StaffGetInstructorQuery, StaffGetInstructorQueryVariables, StaffGetInstructorProps<TChildProps>>(StaffGetInstructorDocument, {
      alias: 'staffGetInstructor',
      ...operationOptions
    });
};

/**
 * __useStaffGetInstructorQuery__
 *
 * To run a query within a React component, call `useStaffGetInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffGetInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffGetInstructorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffGetInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>(StaffGetInstructorDocument, baseOptions);
      }
export function useStaffGetInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>(StaffGetInstructorDocument, baseOptions);
        }
export type StaffGetInstructorQueryHookResult = ReturnType<typeof useStaffGetInstructorQuery>;
export type StaffGetInstructorLazyQueryHookResult = ReturnType<typeof useStaffGetInstructorLazyQuery>;
export type StaffGetInstructorQueryResult = ApolloReactCommon.QueryResult<StaffGetInstructorQuery, StaffGetInstructorQueryVariables>;
export const StaffGetInstructorsDocument = gql`
    query StaffGetInstructors {
  instructors {
    id
    userId
    activeCourseCount
    level
    assignedLocations {
      id
      name
    }
    salutation
    firstname
    lastname
    active
    showInWeb
    birthDate
    birthPlace
    annotation
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    joinDate
    leaveDate
    socialSecurityNumber
    healthInsuranceFund
    rate
    vat
    accountHolder
    iban
    bic
    bank
    email
    allowLogin
  }
}
    `;
export type StaffGetInstructorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>, 'query'>;

    export const StaffGetInstructorsComponent = (props: StaffGetInstructorsComponentProps) => (
      <ApolloReactComponents.Query<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables> query={StaffGetInstructorsDocument} {...props} />
    );
    
export type StaffGetInstructorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables> & TChildProps;
export function withStaffGetInstructors<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaffGetInstructorsQuery,
  StaffGetInstructorsQueryVariables,
  StaffGetInstructorsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables, StaffGetInstructorsProps<TChildProps>>(StaffGetInstructorsDocument, {
      alias: 'staffGetInstructors',
      ...operationOptions
    });
};

/**
 * __useStaffGetInstructorsQuery__
 *
 * To run a query within a React component, call `useStaffGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffGetInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffGetInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>(StaffGetInstructorsDocument, baseOptions);
      }
export function useStaffGetInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>(StaffGetInstructorsDocument, baseOptions);
        }
export type StaffGetInstructorsQueryHookResult = ReturnType<typeof useStaffGetInstructorsQuery>;
export type StaffGetInstructorsLazyQueryHookResult = ReturnType<typeof useStaffGetInstructorsLazyQuery>;
export type StaffGetInstructorsQueryResult = ApolloReactCommon.QueryResult<StaffGetInstructorsQuery, StaffGetInstructorsQueryVariables>;
export const GetInstructorsByStaffIdsDocument = gql`
    query GetInstructorsByStaffIds($staffIds: [ID!]!) {
  instructorsByStaffIds(staffIds: $staffIds) {
    id
    userId
    activeCourseCount
    level
    assignedLocations {
      id
      name
    }
    salutation
    firstname
    lastname
    active
    showInWeb
    birthDate
    birthPlace
    annotation
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    joinDate
    leaveDate
    socialSecurityNumber
    healthInsuranceFund
    rate
    vat
    accountHolder
    iban
    bic
    bank
    email
    allowLogin
  }
}
    `;
export type GetInstructorsByStaffIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>, 'query'> & ({ variables: GetInstructorsByStaffIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInstructorsByStaffIdsComponent = (props: GetInstructorsByStaffIdsComponentProps) => (
      <ApolloReactComponents.Query<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables> query={GetInstructorsByStaffIdsDocument} {...props} />
    );
    
export type GetInstructorsByStaffIdsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables> & TChildProps;
export function withGetInstructorsByStaffIds<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstructorsByStaffIdsQuery,
  GetInstructorsByStaffIdsQueryVariables,
  GetInstructorsByStaffIdsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables, GetInstructorsByStaffIdsProps<TChildProps>>(GetInstructorsByStaffIdsDocument, {
      alias: 'getInstructorsByStaffIds',
      ...operationOptions
    });
};

/**
 * __useGetInstructorsByStaffIdsQuery__
 *
 * To run a query within a React component, call `useGetInstructorsByStaffIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsByStaffIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsByStaffIdsQuery({
 *   variables: {
 *      staffIds: // value for 'staffIds'
 *   },
 * });
 */
export function useGetInstructorsByStaffIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>(GetInstructorsByStaffIdsDocument, baseOptions);
      }
export function useGetInstructorsByStaffIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>(GetInstructorsByStaffIdsDocument, baseOptions);
        }
export type GetInstructorsByStaffIdsQueryHookResult = ReturnType<typeof useGetInstructorsByStaffIdsQuery>;
export type GetInstructorsByStaffIdsLazyQueryHookResult = ReturnType<typeof useGetInstructorsByStaffIdsLazyQuery>;
export type GetInstructorsByStaffIdsQueryResult = ApolloReactCommon.QueryResult<GetInstructorsByStaffIdsQuery, GetInstructorsByStaffIdsQueryVariables>;
export const GetInstructorsWithPaginationDocument = gql`
    query GetInstructorsWithPagination($options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  instructorsWithPagination(options: $options, onlyCurrentUser: $onlyCurrentUser) {
    existsMore
    total
    items {
      id
      userId
      activeCourseCount
      level
      assignedLocations {
        id
        name
      }
      salutation
      firstname
      lastname
      active
      showInWeb
      birthDate
      birthPlace
      annotation
      street
      houseNumber
      postCode
      city
      phoneNumber
      mobileNumber
      email
      joinDate
      leaveDate
      socialSecurityNumber
      healthInsuranceFund
      rate
      vat
      accountHolder
      iban
      bic
      bank
      email
      assignedLocations {
        id
        name
      }
      allowLogin
    }
  }
}
    `;
export type GetInstructorsWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>, 'query'>;

    export const GetInstructorsWithPaginationComponent = (props: GetInstructorsWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables> query={GetInstructorsWithPaginationDocument} {...props} />
    );
    
export type GetInstructorsWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables> & TChildProps;
export function withGetInstructorsWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstructorsWithPaginationQuery,
  GetInstructorsWithPaginationQueryVariables,
  GetInstructorsWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables, GetInstructorsWithPaginationProps<TChildProps>>(GetInstructorsWithPaginationDocument, {
      alias: 'getInstructorsWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetInstructorsWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetInstructorsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsWithPaginationQuery({
 *   variables: {
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetInstructorsWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>(GetInstructorsWithPaginationDocument, baseOptions);
      }
export function useGetInstructorsWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>(GetInstructorsWithPaginationDocument, baseOptions);
        }
export type GetInstructorsWithPaginationQueryHookResult = ReturnType<typeof useGetInstructorsWithPaginationQuery>;
export type GetInstructorsWithPaginationLazyQueryHookResult = ReturnType<typeof useGetInstructorsWithPaginationLazyQuery>;
export type GetInstructorsWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetInstructorsWithPaginationQuery, GetInstructorsWithPaginationQueryVariables>;
export const GetInstructorsWithPaginationInstructorDocument = gql`
    query GetInstructorsWithPaginationInstructor($options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
  instructorsWithPagination(options: $options, onlyCurrentUser: $onlyCurrentUser) {
    existsMore
    total
    items {
      id
      level
      assignedLocations {
        id
        name
      }
      firstname
      lastname
      active
      birthDate
      annotation
      phoneNumber
      mobileNumber
      email
      allowLogin
    }
  }
}
    `;
export type GetInstructorsWithPaginationInstructorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>, 'query'>;

    export const GetInstructorsWithPaginationInstructorComponent = (props: GetInstructorsWithPaginationInstructorComponentProps) => (
      <ApolloReactComponents.Query<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables> query={GetInstructorsWithPaginationInstructorDocument} {...props} />
    );
    
export type GetInstructorsWithPaginationInstructorProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables> & TChildProps;
export function withGetInstructorsWithPaginationInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInstructorsWithPaginationInstructorQuery,
  GetInstructorsWithPaginationInstructorQueryVariables,
  GetInstructorsWithPaginationInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables, GetInstructorsWithPaginationInstructorProps<TChildProps>>(GetInstructorsWithPaginationInstructorDocument, {
      alias: 'getInstructorsWithPaginationInstructor',
      ...operationOptions
    });
};

/**
 * __useGetInstructorsWithPaginationInstructorQuery__
 *
 * To run a query within a React component, call `useGetInstructorsWithPaginationInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsWithPaginationInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsWithPaginationInstructorQuery({
 *   variables: {
 *      options: // value for 'options'
 *      onlyCurrentUser: // value for 'onlyCurrentUser'
 *   },
 * });
 */
export function useGetInstructorsWithPaginationInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>(GetInstructorsWithPaginationInstructorDocument, baseOptions);
      }
export function useGetInstructorsWithPaginationInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>(GetInstructorsWithPaginationInstructorDocument, baseOptions);
        }
export type GetInstructorsWithPaginationInstructorQueryHookResult = ReturnType<typeof useGetInstructorsWithPaginationInstructorQuery>;
export type GetInstructorsWithPaginationInstructorLazyQueryHookResult = ReturnType<typeof useGetInstructorsWithPaginationInstructorLazyQuery>;
export type GetInstructorsWithPaginationInstructorQueryResult = ApolloReactCommon.QueryResult<GetInstructorsWithPaginationInstructorQuery, GetInstructorsWithPaginationInstructorQueryVariables>;
export const IsInstructorDocument = gql`
    query IsInstructor {
  isInstructor
}
    `;
export type IsInstructorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsInstructorQuery, IsInstructorQueryVariables>, 'query'>;

    export const IsInstructorComponent = (props: IsInstructorComponentProps) => (
      <ApolloReactComponents.Query<IsInstructorQuery, IsInstructorQueryVariables> query={IsInstructorDocument} {...props} />
    );
    
export type IsInstructorProps<TChildProps = {}> = ApolloReactHoc.DataProps<IsInstructorQuery, IsInstructorQueryVariables> & TChildProps;
export function withIsInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IsInstructorQuery,
  IsInstructorQueryVariables,
  IsInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IsInstructorQuery, IsInstructorQueryVariables, IsInstructorProps<TChildProps>>(IsInstructorDocument, {
      alias: 'isInstructor',
      ...operationOptions
    });
};

/**
 * __useIsInstructorQuery__
 *
 * To run a query within a React component, call `useIsInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsInstructorQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsInstructorQuery, IsInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<IsInstructorQuery, IsInstructorQueryVariables>(IsInstructorDocument, baseOptions);
      }
export function useIsInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsInstructorQuery, IsInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsInstructorQuery, IsInstructorQueryVariables>(IsInstructorDocument, baseOptions);
        }
export type IsInstructorQueryHookResult = ReturnType<typeof useIsInstructorQuery>;
export type IsInstructorLazyQueryHookResult = ReturnType<typeof useIsInstructorLazyQuery>;
export type IsInstructorQueryResult = ApolloReactCommon.QueryResult<IsInstructorQuery, IsInstructorQueryVariables>;
export const CanBecomeInstructorDocument = gql`
    query canBecomeInstructor($email: String!) {
  canBecomeInstructor(email: $email)
}
    `;
export type CanBecomeInstructorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>, 'query'> & ({ variables: CanBecomeInstructorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CanBecomeInstructorComponent = (props: CanBecomeInstructorComponentProps) => (
      <ApolloReactComponents.Query<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables> query={CanBecomeInstructorDocument} {...props} />
    );
    
export type CanBecomeInstructorProps<TChildProps = {}> = ApolloReactHoc.DataProps<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables> & TChildProps;
export function withCanBecomeInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CanBecomeInstructorQuery,
  CanBecomeInstructorQueryVariables,
  CanBecomeInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables, CanBecomeInstructorProps<TChildProps>>(CanBecomeInstructorDocument, {
      alias: 'canBecomeInstructor',
      ...operationOptions
    });
};

/**
 * __useCanBecomeInstructorQuery__
 *
 * To run a query within a React component, call `useCanBecomeInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanBecomeInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanBecomeInstructorQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCanBecomeInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>(CanBecomeInstructorDocument, baseOptions);
      }
export function useCanBecomeInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>(CanBecomeInstructorDocument, baseOptions);
        }
export type CanBecomeInstructorQueryHookResult = ReturnType<typeof useCanBecomeInstructorQuery>;
export type CanBecomeInstructorLazyQueryHookResult = ReturnType<typeof useCanBecomeInstructorLazyQuery>;
export type CanBecomeInstructorQueryResult = ApolloReactCommon.QueryResult<CanBecomeInstructorQuery, CanBecomeInstructorQueryVariables>;
export const StaffGetLocationsDocument = gql`
    query StaffGetLocations {
  locations {
    id
    name
  }
}
    `;
export type StaffGetLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>, 'query'>;

    export const StaffGetLocationsComponent = (props: StaffGetLocationsComponentProps) => (
      <ApolloReactComponents.Query<StaffGetLocationsQuery, StaffGetLocationsQueryVariables> query={StaffGetLocationsDocument} {...props} />
    );
    
export type StaffGetLocationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<StaffGetLocationsQuery, StaffGetLocationsQueryVariables> & TChildProps;
export function withStaffGetLocations<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaffGetLocationsQuery,
  StaffGetLocationsQueryVariables,
  StaffGetLocationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, StaffGetLocationsQuery, StaffGetLocationsQueryVariables, StaffGetLocationsProps<TChildProps>>(StaffGetLocationsDocument, {
      alias: 'staffGetLocations',
      ...operationOptions
    });
};

/**
 * __useStaffGetLocationsQuery__
 *
 * To run a query within a React component, call `useStaffGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>(StaffGetLocationsDocument, baseOptions);
      }
export function useStaffGetLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>(StaffGetLocationsDocument, baseOptions);
        }
export type StaffGetLocationsQueryHookResult = ReturnType<typeof useStaffGetLocationsQuery>;
export type StaffGetLocationsLazyQueryHookResult = ReturnType<typeof useStaffGetLocationsLazyQuery>;
export type StaffGetLocationsQueryResult = ApolloReactCommon.QueryResult<StaffGetLocationsQuery, StaffGetLocationsQueryVariables>;
export const RemoveInstructorDocument = gql`
    mutation RemoveInstructor($id: String!) {
  removeInstructor(id: $id)
}
    `;
export type RemoveInstructorMutationFn = ApolloReactCommon.MutationFunction<RemoveInstructorMutation, RemoveInstructorMutationVariables>;
export type RemoveInstructorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveInstructorMutation, RemoveInstructorMutationVariables>, 'mutation'>;

    export const RemoveInstructorComponent = (props: RemoveInstructorComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveInstructorMutation, RemoveInstructorMutationVariables> mutation={RemoveInstructorDocument} {...props} />
    );
    
export type RemoveInstructorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveInstructorMutation, RemoveInstructorMutationVariables> & TChildProps;
export function withRemoveInstructor<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveInstructorMutation,
  RemoveInstructorMutationVariables,
  RemoveInstructorProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveInstructorMutation, RemoveInstructorMutationVariables, RemoveInstructorProps<TChildProps>>(RemoveInstructorDocument, {
      alias: 'removeInstructor',
      ...operationOptions
    });
};

/**
 * __useRemoveInstructorMutation__
 *
 * To run a mutation, you first call `useRemoveInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInstructorMutation, { data, loading, error }] = useRemoveInstructorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveInstructorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveInstructorMutation, RemoveInstructorMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveInstructorMutation, RemoveInstructorMutationVariables>(RemoveInstructorDocument, baseOptions);
      }
export type RemoveInstructorMutationHookResult = ReturnType<typeof useRemoveInstructorMutation>;
export type RemoveInstructorMutationResult = ApolloReactCommon.MutationResult<RemoveInstructorMutation>;
export type RemoveInstructorMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveInstructorMutation, RemoveInstructorMutationVariables>;
export const GetContentOfBucketDoDocument = gql`
    query GetContentOfBucketDo {
  getContentOfBucketDo {
    ContentOfBucketResponse {
      IsTruncated
      Marker
      NextMarker
      Name
      Prefix
      Delimiter
      MaxKeys
      CommonPrefixes
      EncodingType
    }
    Folders {
      Name
      Key
      LastModified
      ETag
      Size
    }
    Files {
      Name
      Key
      LastModified
      ETag
      Size
      signedImageUrl
    }
  }
}
    `;
export type GetContentOfBucketDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>, 'query'>;

    export const GetContentOfBucketDoComponent = (props: GetContentOfBucketDoComponentProps) => (
      <ApolloReactComponents.Query<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables> query={GetContentOfBucketDoDocument} {...props} />
    );
    
export type GetContentOfBucketDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables> & TChildProps;
export function withGetContentOfBucketDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetContentOfBucketDoQuery,
  GetContentOfBucketDoQueryVariables,
  GetContentOfBucketDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables, GetContentOfBucketDoProps<TChildProps>>(GetContentOfBucketDoDocument, {
      alias: 'getContentOfBucketDo',
      ...operationOptions
    });
};

/**
 * __useGetContentOfBucketDoQuery__
 *
 * To run a query within a React component, call `useGetContentOfBucketDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentOfBucketDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentOfBucketDoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentOfBucketDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>(GetContentOfBucketDoDocument, baseOptions);
      }
export function useGetContentOfBucketDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>(GetContentOfBucketDoDocument, baseOptions);
        }
export type GetContentOfBucketDoQueryHookResult = ReturnType<typeof useGetContentOfBucketDoQuery>;
export type GetContentOfBucketDoLazyQueryHookResult = ReturnType<typeof useGetContentOfBucketDoLazyQuery>;
export type GetContentOfBucketDoQueryResult = ApolloReactCommon.QueryResult<GetContentOfBucketDoQuery, GetContentOfBucketDoQueryVariables>;
export const GetContentOfFolderDoDocument = gql`
    query GetContentOfFolderDo($folderName: String) {
  getContentOfFolderDo(folderName: $folderName) {
    ContentOfBucketResponse {
      IsTruncated
      Marker
      NextMarker
      Name
      Prefix
      Delimiter
      MaxKeys
      CommonPrefixes
      EncodingType
    }
    Folders {
      Name
      Key
      LastModified
      ETag
      Size
    }
    Files {
      Name
      Key
      LastModified
      ETag
      Size
      signedImageUrl
    }
  }
}
    `;
export type GetContentOfFolderDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>, 'query'>;

    export const GetContentOfFolderDoComponent = (props: GetContentOfFolderDoComponentProps) => (
      <ApolloReactComponents.Query<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables> query={GetContentOfFolderDoDocument} {...props} />
    );
    
export type GetContentOfFolderDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables> & TChildProps;
export function withGetContentOfFolderDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetContentOfFolderDoQuery,
  GetContentOfFolderDoQueryVariables,
  GetContentOfFolderDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables, GetContentOfFolderDoProps<TChildProps>>(GetContentOfFolderDoDocument, {
      alias: 'getContentOfFolderDo',
      ...operationOptions
    });
};

/**
 * __useGetContentOfFolderDoQuery__
 *
 * To run a query within a React component, call `useGetContentOfFolderDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentOfFolderDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentOfFolderDoQuery({
 *   variables: {
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useGetContentOfFolderDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>(GetContentOfFolderDoDocument, baseOptions);
      }
export function useGetContentOfFolderDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>(GetContentOfFolderDoDocument, baseOptions);
        }
export type GetContentOfFolderDoQueryHookResult = ReturnType<typeof useGetContentOfFolderDoQuery>;
export type GetContentOfFolderDoLazyQueryHookResult = ReturnType<typeof useGetContentOfFolderDoLazyQuery>;
export type GetContentOfFolderDoQueryResult = ApolloReactCommon.QueryResult<GetContentOfFolderDoQuery, GetContentOfFolderDoQueryVariables>;
export const GetContentByPathDoDocument = gql`
    query GetContentByPathDo($path: String!, $options: ArrayDataOptions) {
  getContentByPathDo(path: $path, options: $options) {
    ContentOfBucketResponse {
      IsTruncated
      Marker
      NextMarker
      Name
      Prefix
      Delimiter
      MaxKeys
      CommonPrefixes
      EncodingType
    }
    Folders {
      Name
      Key
      LastModified
      ETag
      Size
    }
    Files {
      Name
      Key
      LastModified
      ETag
      Size
      signedImageUrl
    }
  }
}
    `;
export type GetContentByPathDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>, 'query'> & ({ variables: GetContentByPathDoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContentByPathDoComponent = (props: GetContentByPathDoComponentProps) => (
      <ApolloReactComponents.Query<GetContentByPathDoQuery, GetContentByPathDoQueryVariables> query={GetContentByPathDoDocument} {...props} />
    );
    
export type GetContentByPathDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetContentByPathDoQuery, GetContentByPathDoQueryVariables> & TChildProps;
export function withGetContentByPathDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetContentByPathDoQuery,
  GetContentByPathDoQueryVariables,
  GetContentByPathDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetContentByPathDoQuery, GetContentByPathDoQueryVariables, GetContentByPathDoProps<TChildProps>>(GetContentByPathDoDocument, {
      alias: 'getContentByPathDo',
      ...operationOptions
    });
};

/**
 * __useGetContentByPathDoQuery__
 *
 * To run a query within a React component, call `useGetContentByPathDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByPathDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByPathDoQuery({
 *   variables: {
 *      path: // value for 'path'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetContentByPathDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>(GetContentByPathDoDocument, baseOptions);
      }
export function useGetContentByPathDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>(GetContentByPathDoDocument, baseOptions);
        }
export type GetContentByPathDoQueryHookResult = ReturnType<typeof useGetContentByPathDoQuery>;
export type GetContentByPathDoLazyQueryHookResult = ReturnType<typeof useGetContentByPathDoLazyQuery>;
export type GetContentByPathDoQueryResult = ApolloReactCommon.QueryResult<GetContentByPathDoQuery, GetContentByPathDoQueryVariables>;
export const GetPdfDoDocument = gql`
    query GetPdfDo($fileName: String!) {
  getFileDo(fileName: $fileName) {
    Name
    Key
    LastModified
    ETag
    Size
    pdfBlob
    signedImageUrl
  }
}
    `;
export type GetPdfDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPdfDoQuery, GetPdfDoQueryVariables>, 'query'> & ({ variables: GetPdfDoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPdfDoComponent = (props: GetPdfDoComponentProps) => (
      <ApolloReactComponents.Query<GetPdfDoQuery, GetPdfDoQueryVariables> query={GetPdfDoDocument} {...props} />
    );
    
export type GetPdfDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPdfDoQuery, GetPdfDoQueryVariables> & TChildProps;
export function withGetPdfDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPdfDoQuery,
  GetPdfDoQueryVariables,
  GetPdfDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPdfDoQuery, GetPdfDoQueryVariables, GetPdfDoProps<TChildProps>>(GetPdfDoDocument, {
      alias: 'getPdfDo',
      ...operationOptions
    });
};

/**
 * __useGetPdfDoQuery__
 *
 * To run a query within a React component, call `useGetPdfDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdfDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdfDoQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetPdfDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPdfDoQuery, GetPdfDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPdfDoQuery, GetPdfDoQueryVariables>(GetPdfDoDocument, baseOptions);
      }
export function useGetPdfDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPdfDoQuery, GetPdfDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPdfDoQuery, GetPdfDoQueryVariables>(GetPdfDoDocument, baseOptions);
        }
export type GetPdfDoQueryHookResult = ReturnType<typeof useGetPdfDoQuery>;
export type GetPdfDoLazyQueryHookResult = ReturnType<typeof useGetPdfDoLazyQuery>;
export type GetPdfDoQueryResult = ApolloReactCommon.QueryResult<GetPdfDoQuery, GetPdfDoQueryVariables>;
export const GetFileDoDocument = gql`
    query GetFileDo($fileName: String!) {
  getFileDo(fileName: $fileName) {
    Name
    Key
    LastModified
    ETag
    Size
    pdfBlob
    signedImageUrl
  }
}
    `;
export type GetFileDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFileDoQuery, GetFileDoQueryVariables>, 'query'> & ({ variables: GetFileDoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFileDoComponent = (props: GetFileDoComponentProps) => (
      <ApolloReactComponents.Query<GetFileDoQuery, GetFileDoQueryVariables> query={GetFileDoDocument} {...props} />
    );
    
export type GetFileDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetFileDoQuery, GetFileDoQueryVariables> & TChildProps;
export function withGetFileDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFileDoQuery,
  GetFileDoQueryVariables,
  GetFileDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetFileDoQuery, GetFileDoQueryVariables, GetFileDoProps<TChildProps>>(GetFileDoDocument, {
      alias: 'getFileDo',
      ...operationOptions
    });
};

/**
 * __useGetFileDoQuery__
 *
 * To run a query within a React component, call `useGetFileDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileDoQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetFileDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFileDoQuery, GetFileDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFileDoQuery, GetFileDoQueryVariables>(GetFileDoDocument, baseOptions);
      }
export function useGetFileDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFileDoQuery, GetFileDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFileDoQuery, GetFileDoQueryVariables>(GetFileDoDocument, baseOptions);
        }
export type GetFileDoQueryHookResult = ReturnType<typeof useGetFileDoQuery>;
export type GetFileDoLazyQueryHookResult = ReturnType<typeof useGetFileDoLazyQuery>;
export type GetFileDoQueryResult = ApolloReactCommon.QueryResult<GetFileDoQuery, GetFileDoQueryVariables>;
export const AddFolderDoDocument = gql`
    mutation AddFolderDo($folderName: String!) {
  addFolderDo(folderName: $folderName)
}
    `;
export type AddFolderDoMutationFn = ApolloReactCommon.MutationFunction<AddFolderDoMutation, AddFolderDoMutationVariables>;
export type AddFolderDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFolderDoMutation, AddFolderDoMutationVariables>, 'mutation'>;

    export const AddFolderDoComponent = (props: AddFolderDoComponentProps) => (
      <ApolloReactComponents.Mutation<AddFolderDoMutation, AddFolderDoMutationVariables> mutation={AddFolderDoDocument} {...props} />
    );
    
export type AddFolderDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddFolderDoMutation, AddFolderDoMutationVariables> & TChildProps;
export function withAddFolderDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddFolderDoMutation,
  AddFolderDoMutationVariables,
  AddFolderDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddFolderDoMutation, AddFolderDoMutationVariables, AddFolderDoProps<TChildProps>>(AddFolderDoDocument, {
      alias: 'addFolderDo',
      ...operationOptions
    });
};

/**
 * __useAddFolderDoMutation__
 *
 * To run a mutation, you first call `useAddFolderDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFolderDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFolderDoMutation, { data, loading, error }] = useAddFolderDoMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useAddFolderDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFolderDoMutation, AddFolderDoMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFolderDoMutation, AddFolderDoMutationVariables>(AddFolderDoDocument, baseOptions);
      }
export type AddFolderDoMutationHookResult = ReturnType<typeof useAddFolderDoMutation>;
export type AddFolderDoMutationResult = ApolloReactCommon.MutationResult<AddFolderDoMutation>;
export type AddFolderDoMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFolderDoMutation, AddFolderDoMutationVariables>;
export const DeleteFolderDoDocument = gql`
    mutation DeleteFolderDo($folderName: String!) {
  deleteFolderDo(folderName: $folderName)
}
    `;
export type DeleteFolderDoMutationFn = ApolloReactCommon.MutationFunction<DeleteFolderDoMutation, DeleteFolderDoMutationVariables>;
export type DeleteFolderDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFolderDoMutation, DeleteFolderDoMutationVariables>, 'mutation'>;

    export const DeleteFolderDoComponent = (props: DeleteFolderDoComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFolderDoMutation, DeleteFolderDoMutationVariables> mutation={DeleteFolderDoDocument} {...props} />
    );
    
export type DeleteFolderDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteFolderDoMutation, DeleteFolderDoMutationVariables> & TChildProps;
export function withDeleteFolderDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFolderDoMutation,
  DeleteFolderDoMutationVariables,
  DeleteFolderDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFolderDoMutation, DeleteFolderDoMutationVariables, DeleteFolderDoProps<TChildProps>>(DeleteFolderDoDocument, {
      alias: 'deleteFolderDo',
      ...operationOptions
    });
};

/**
 * __useDeleteFolderDoMutation__
 *
 * To run a mutation, you first call `useDeleteFolderDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderDoMutation, { data, loading, error }] = useDeleteFolderDoMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useDeleteFolderDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFolderDoMutation, DeleteFolderDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFolderDoMutation, DeleteFolderDoMutationVariables>(DeleteFolderDoDocument, baseOptions);
      }
export type DeleteFolderDoMutationHookResult = ReturnType<typeof useDeleteFolderDoMutation>;
export type DeleteFolderDoMutationResult = ApolloReactCommon.MutationResult<DeleteFolderDoMutation>;
export type DeleteFolderDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFolderDoMutation, DeleteFolderDoMutationVariables>;
export const UploadFilesDoDocument = gql`
    mutation UploadFilesDo($fileArray: [Upload!]!, $path: String!, $metadata: UploadMetadataInput!) {
  uploadFilesDo(fileArray: $fileArray, path: $path, metadata: $metadata)
}
    `;
export type UploadFilesDoMutationFn = ApolloReactCommon.MutationFunction<UploadFilesDoMutation, UploadFilesDoMutationVariables>;
export type UploadFilesDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadFilesDoMutation, UploadFilesDoMutationVariables>, 'mutation'>;

    export const UploadFilesDoComponent = (props: UploadFilesDoComponentProps) => (
      <ApolloReactComponents.Mutation<UploadFilesDoMutation, UploadFilesDoMutationVariables> mutation={UploadFilesDoDocument} {...props} />
    );
    
export type UploadFilesDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UploadFilesDoMutation, UploadFilesDoMutationVariables> & TChildProps;
export function withUploadFilesDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadFilesDoMutation,
  UploadFilesDoMutationVariables,
  UploadFilesDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UploadFilesDoMutation, UploadFilesDoMutationVariables, UploadFilesDoProps<TChildProps>>(UploadFilesDoDocument, {
      alias: 'uploadFilesDo',
      ...operationOptions
    });
};

/**
 * __useUploadFilesDoMutation__
 *
 * To run a mutation, you first call `useUploadFilesDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesDoMutation, { data, loading, error }] = useUploadFilesDoMutation({
 *   variables: {
 *      fileArray: // value for 'fileArray'
 *      path: // value for 'path'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUploadFilesDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFilesDoMutation, UploadFilesDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFilesDoMutation, UploadFilesDoMutationVariables>(UploadFilesDoDocument, baseOptions);
      }
export type UploadFilesDoMutationHookResult = ReturnType<typeof useUploadFilesDoMutation>;
export type UploadFilesDoMutationResult = ApolloReactCommon.MutationResult<UploadFilesDoMutation>;
export type UploadFilesDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFilesDoMutation, UploadFilesDoMutationVariables>;
export const UploadSingleFileDoDocument = gql`
    mutation UploadSingleFileDo($file: Upload!, $path: String!) {
  uploadSingleFileDo(file: $file, path: $path)
}
    `;
export type UploadSingleFileDoMutationFn = ApolloReactCommon.MutationFunction<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables>;
export type UploadSingleFileDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables>, 'mutation'>;

    export const UploadSingleFileDoComponent = (props: UploadSingleFileDoComponentProps) => (
      <ApolloReactComponents.Mutation<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables> mutation={UploadSingleFileDoDocument} {...props} />
    );
    
export type UploadSingleFileDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables> & TChildProps;
export function withUploadSingleFileDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadSingleFileDoMutation,
  UploadSingleFileDoMutationVariables,
  UploadSingleFileDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables, UploadSingleFileDoProps<TChildProps>>(UploadSingleFileDoDocument, {
      alias: 'uploadSingleFileDo',
      ...operationOptions
    });
};

/**
 * __useUploadSingleFileDoMutation__
 *
 * To run a mutation, you first call `useUploadSingleFileDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSingleFileDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSingleFileDoMutation, { data, loading, error }] = useUploadSingleFileDoMutation({
 *   variables: {
 *      file: // value for 'file'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUploadSingleFileDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables>(UploadSingleFileDoDocument, baseOptions);
      }
export type UploadSingleFileDoMutationHookResult = ReturnType<typeof useUploadSingleFileDoMutation>;
export type UploadSingleFileDoMutationResult = ApolloReactCommon.MutationResult<UploadSingleFileDoMutation>;
export type UploadSingleFileDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadSingleFileDoMutation, UploadSingleFileDoMutationVariables>;
export const DeleteFileDoDocument = gql`
    mutation DeleteFileDo($fileName: String!) {
  deleteFileDo(fileName: $fileName)
}
    `;
export type DeleteFileDoMutationFn = ApolloReactCommon.MutationFunction<DeleteFileDoMutation, DeleteFileDoMutationVariables>;
export type DeleteFileDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFileDoMutation, DeleteFileDoMutationVariables>, 'mutation'>;

    export const DeleteFileDoComponent = (props: DeleteFileDoComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFileDoMutation, DeleteFileDoMutationVariables> mutation={DeleteFileDoDocument} {...props} />
    );
    
export type DeleteFileDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteFileDoMutation, DeleteFileDoMutationVariables> & TChildProps;
export function withDeleteFileDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFileDoMutation,
  DeleteFileDoMutationVariables,
  DeleteFileDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFileDoMutation, DeleteFileDoMutationVariables, DeleteFileDoProps<TChildProps>>(DeleteFileDoDocument, {
      alias: 'deleteFileDo',
      ...operationOptions
    });
};

/**
 * __useDeleteFileDoMutation__
 *
 * To run a mutation, you first call `useDeleteFileDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileDoMutation, { data, loading, error }] = useDeleteFileDoMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useDeleteFileDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFileDoMutation, DeleteFileDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFileDoMutation, DeleteFileDoMutationVariables>(DeleteFileDoDocument, baseOptions);
      }
export type DeleteFileDoMutationHookResult = ReturnType<typeof useDeleteFileDoMutation>;
export type DeleteFileDoMutationResult = ApolloReactCommon.MutationResult<DeleteFileDoMutation>;
export type DeleteFileDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFileDoMutation, DeleteFileDoMutationVariables>;
export const RenameFileDoDocument = gql`
    mutation RenameFileDo($fileName: String!, $newFileName: String!) {
  renameFileDo(fileName: $fileName, newFileName: $newFileName)
}
    `;
export type RenameFileDoMutationFn = ApolloReactCommon.MutationFunction<RenameFileDoMutation, RenameFileDoMutationVariables>;
export type RenameFileDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameFileDoMutation, RenameFileDoMutationVariables>, 'mutation'>;

    export const RenameFileDoComponent = (props: RenameFileDoComponentProps) => (
      <ApolloReactComponents.Mutation<RenameFileDoMutation, RenameFileDoMutationVariables> mutation={RenameFileDoDocument} {...props} />
    );
    
export type RenameFileDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RenameFileDoMutation, RenameFileDoMutationVariables> & TChildProps;
export function withRenameFileDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameFileDoMutation,
  RenameFileDoMutationVariables,
  RenameFileDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RenameFileDoMutation, RenameFileDoMutationVariables, RenameFileDoProps<TChildProps>>(RenameFileDoDocument, {
      alias: 'renameFileDo',
      ...operationOptions
    });
};

/**
 * __useRenameFileDoMutation__
 *
 * To run a mutation, you first call `useRenameFileDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFileDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFileDoMutation, { data, loading, error }] = useRenameFileDoMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      newFileName: // value for 'newFileName'
 *   },
 * });
 */
export function useRenameFileDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameFileDoMutation, RenameFileDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameFileDoMutation, RenameFileDoMutationVariables>(RenameFileDoDocument, baseOptions);
      }
export type RenameFileDoMutationHookResult = ReturnType<typeof useRenameFileDoMutation>;
export type RenameFileDoMutationResult = ApolloReactCommon.MutationResult<RenameFileDoMutation>;
export type RenameFileDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameFileDoMutation, RenameFileDoMutationVariables>;
export const RenameFolderDoDocument = gql`
    mutation RenameFolderDo($folderName: String!, $newFolderName: String!) {
  renameFolderDo(folderName: $folderName, newFolderName: $newFolderName)
}
    `;
export type RenameFolderDoMutationFn = ApolloReactCommon.MutationFunction<RenameFolderDoMutation, RenameFolderDoMutationVariables>;
export type RenameFolderDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameFolderDoMutation, RenameFolderDoMutationVariables>, 'mutation'>;

    export const RenameFolderDoComponent = (props: RenameFolderDoComponentProps) => (
      <ApolloReactComponents.Mutation<RenameFolderDoMutation, RenameFolderDoMutationVariables> mutation={RenameFolderDoDocument} {...props} />
    );
    
export type RenameFolderDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RenameFolderDoMutation, RenameFolderDoMutationVariables> & TChildProps;
export function withRenameFolderDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameFolderDoMutation,
  RenameFolderDoMutationVariables,
  RenameFolderDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RenameFolderDoMutation, RenameFolderDoMutationVariables, RenameFolderDoProps<TChildProps>>(RenameFolderDoDocument, {
      alias: 'renameFolderDo',
      ...operationOptions
    });
};

/**
 * __useRenameFolderDoMutation__
 *
 * To run a mutation, you first call `useRenameFolderDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFolderDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFolderDoMutation, { data, loading, error }] = useRenameFolderDoMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      newFolderName: // value for 'newFolderName'
 *   },
 * });
 */
export function useRenameFolderDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameFolderDoMutation, RenameFolderDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameFolderDoMutation, RenameFolderDoMutationVariables>(RenameFolderDoDocument, baseOptions);
      }
export type RenameFolderDoMutationHookResult = ReturnType<typeof useRenameFolderDoMutation>;
export type RenameFolderDoMutationResult = ApolloReactCommon.MutationResult<RenameFolderDoMutation>;
export type RenameFolderDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameFolderDoMutation, RenameFolderDoMutationVariables>;
export const GetSignedUrlDoDocument = gql`
    query GetSignedUrlDo($fileName: String!) {
  getSignedUrlDo(fileName: $fileName)
}
    `;
export type GetSignedUrlDoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>, 'query'> & ({ variables: GetSignedUrlDoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSignedUrlDoComponent = (props: GetSignedUrlDoComponentProps) => (
      <ApolloReactComponents.Query<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables> query={GetSignedUrlDoDocument} {...props} />
    );
    
export type GetSignedUrlDoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables> & TChildProps;
export function withGetSignedUrlDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSignedUrlDoQuery,
  GetSignedUrlDoQueryVariables,
  GetSignedUrlDoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables, GetSignedUrlDoProps<TChildProps>>(GetSignedUrlDoDocument, {
      alias: 'getSignedUrlDo',
      ...operationOptions
    });
};

/**
 * __useGetSignedUrlDoQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlDoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlDoQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetSignedUrlDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>(GetSignedUrlDoDocument, baseOptions);
      }
export function useGetSignedUrlDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>(GetSignedUrlDoDocument, baseOptions);
        }
export type GetSignedUrlDoQueryHookResult = ReturnType<typeof useGetSignedUrlDoQuery>;
export type GetSignedUrlDoLazyQueryHookResult = ReturnType<typeof useGetSignedUrlDoLazyQuery>;
export type GetSignedUrlDoQueryResult = ApolloReactCommon.QueryResult<GetSignedUrlDoQuery, GetSignedUrlDoQueryVariables>;
export const DownloadFileDoDocument = gql`
    mutation DownloadFileDo($fileName: String!) {
  downloadFileDo(fileName: $fileName)
}
    `;
export type DownloadFileDoMutationFn = ApolloReactCommon.MutationFunction<DownloadFileDoMutation, DownloadFileDoMutationVariables>;
export type DownloadFileDoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DownloadFileDoMutation, DownloadFileDoMutationVariables>, 'mutation'>;

    export const DownloadFileDoComponent = (props: DownloadFileDoComponentProps) => (
      <ApolloReactComponents.Mutation<DownloadFileDoMutation, DownloadFileDoMutationVariables> mutation={DownloadFileDoDocument} {...props} />
    );
    
export type DownloadFileDoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DownloadFileDoMutation, DownloadFileDoMutationVariables> & TChildProps;
export function withDownloadFileDo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadFileDoMutation,
  DownloadFileDoMutationVariables,
  DownloadFileDoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DownloadFileDoMutation, DownloadFileDoMutationVariables, DownloadFileDoProps<TChildProps>>(DownloadFileDoDocument, {
      alias: 'downloadFileDo',
      ...operationOptions
    });
};

/**
 * __useDownloadFileDoMutation__
 *
 * To run a mutation, you first call `useDownloadFileDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFileDoMutation, { data, loading, error }] = useDownloadFileDoMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useDownloadFileDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DownloadFileDoMutation, DownloadFileDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DownloadFileDoMutation, DownloadFileDoMutationVariables>(DownloadFileDoDocument, baseOptions);
      }
export type DownloadFileDoMutationHookResult = ReturnType<typeof useDownloadFileDoMutation>;
export type DownloadFileDoMutationResult = ApolloReactCommon.MutationResult<DownloadFileDoMutation>;
export type DownloadFileDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadFileDoMutation, DownloadFileDoMutationVariables>;
export const GetPagesDocument = gql`
    query GetPages {
  getPages {
    pages {
      id
      url
      content {
        title
        subTitle
        body
      }
    }
  }
}
    `;
export type GetPagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPagesQuery, GetPagesQueryVariables>, 'query'>;

    export const GetPagesComponent = (props: GetPagesComponentProps) => (
      <ApolloReactComponents.Query<GetPagesQuery, GetPagesQueryVariables> query={GetPagesDocument} {...props} />
    );
    
export type GetPagesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPagesQuery, GetPagesQueryVariables> & TChildProps;
export function withGetPages<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPagesQuery,
  GetPagesQueryVariables,
  GetPagesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPagesQuery, GetPagesQueryVariables, GetPagesProps<TChildProps>>(GetPagesDocument, {
      alias: 'getPages',
      ...operationOptions
    });
};

/**
 * __useGetPagesQuery__
 *
 * To run a query within a React component, call `useGetPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, baseOptions);
      }
export function useGetPagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, baseOptions);
        }
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<typeof useGetPagesLazyQuery>;
export type GetPagesQueryResult = ApolloReactCommon.QueryResult<GetPagesQuery, GetPagesQueryVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($editPageInput: EditPageInput!) {
  updatePage(editPageInput: $editPageInput) {
    pages {
      id
      url
      content {
        title
        subTitle
        body
      }
    }
  }
}
    `;
export type UpdatePageMutationFn = ApolloReactCommon.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;
export type UpdatePageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePageMutation, UpdatePageMutationVariables>, 'mutation'>;

    export const UpdatePageComponent = (props: UpdatePageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePageMutation, UpdatePageMutationVariables> mutation={UpdatePageDocument} {...props} />
    );
    
export type UpdatePageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdatePageMutation, UpdatePageMutationVariables> & TChildProps;
export function withUpdatePage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdatePageMutation,
  UpdatePageMutationVariables,
  UpdatePageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdatePageMutation, UpdatePageMutationVariables, UpdatePageProps<TChildProps>>(UpdatePageDocument, {
      alias: 'updatePage',
      ...operationOptions
    });
};

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      editPageInput: // value for 'editPageInput'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, baseOptions);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = ApolloReactCommon.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const AddPageDocument = gql`
    mutation AddPage($newPageInput: NewPageInput!) {
  addPage(newPageInput: $newPageInput) {
    pages {
      id
      url
      content {
        title
        subTitle
        body
      }
    }
  }
}
    `;
export type AddPageMutationFn = ApolloReactCommon.MutationFunction<AddPageMutation, AddPageMutationVariables>;
export type AddPageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPageMutation, AddPageMutationVariables>, 'mutation'>;

    export const AddPageComponent = (props: AddPageComponentProps) => (
      <ApolloReactComponents.Mutation<AddPageMutation, AddPageMutationVariables> mutation={AddPageDocument} {...props} />
    );
    
export type AddPageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddPageMutation, AddPageMutationVariables> & TChildProps;
export function withAddPage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddPageMutation,
  AddPageMutationVariables,
  AddPageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddPageMutation, AddPageMutationVariables, AddPageProps<TChildProps>>(AddPageDocument, {
      alias: 'addPage',
      ...operationOptions
    });
};

/**
 * __useAddPageMutation__
 *
 * To run a mutation, you first call `useAddPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPageMutation, { data, loading, error }] = useAddPageMutation({
 *   variables: {
 *      newPageInput: // value for 'newPageInput'
 *   },
 * });
 */
export function useAddPageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPageMutation, AddPageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPageMutation, AddPageMutationVariables>(AddPageDocument, baseOptions);
      }
export type AddPageMutationHookResult = ReturnType<typeof useAddPageMutation>;
export type AddPageMutationResult = ApolloReactCommon.MutationResult<AddPageMutation>;
export type AddPageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPageMutation, AddPageMutationVariables>;
export const DeletePageDocument = gql`
    mutation DeletePage($pageId: String!) {
  deletePage(pageId: $pageId) {
    pages {
      id
      url
      content {
        title
        subTitle
        body
      }
    }
  }
}
    `;
export type DeletePageMutationFn = ApolloReactCommon.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;
export type DeletePageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePageMutation, DeletePageMutationVariables>, 'mutation'>;

    export const DeletePageComponent = (props: DeletePageComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePageMutation, DeletePageMutationVariables> mutation={DeletePageDocument} {...props} />
    );
    
export type DeletePageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePageMutation, DeletePageMutationVariables> & TChildProps;
export function withDeletePage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePageMutation,
  DeletePageMutationVariables,
  DeletePageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePageMutation, DeletePageMutationVariables, DeletePageProps<TChildProps>>(DeletePageDocument, {
      alias: 'deletePage',
      ...operationOptions
    });
};

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, baseOptions);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = ApolloReactCommon.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const UploadSingleCamtFileDocument = gql`
    mutation UploadSingleCamtFile($file: Upload!, $analysis: Boolean) {
  singleUpload(file: $file, analysis: $analysis)
}
    `;
export type UploadSingleCamtFileMutationFn = ApolloReactCommon.MutationFunction<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables>;
export type UploadSingleCamtFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables>, 'mutation'>;

    export const UploadSingleCamtFileComponent = (props: UploadSingleCamtFileComponentProps) => (
      <ApolloReactComponents.Mutation<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables> mutation={UploadSingleCamtFileDocument} {...props} />
    );
    
export type UploadSingleCamtFileProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables> & TChildProps;
export function withUploadSingleCamtFile<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadSingleCamtFileMutation,
  UploadSingleCamtFileMutationVariables,
  UploadSingleCamtFileProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables, UploadSingleCamtFileProps<TChildProps>>(UploadSingleCamtFileDocument, {
      alias: 'uploadSingleCamtFile',
      ...operationOptions
    });
};

/**
 * __useUploadSingleCamtFileMutation__
 *
 * To run a mutation, you first call `useUploadSingleCamtFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSingleCamtFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSingleCamtFileMutation, { data, loading, error }] = useUploadSingleCamtFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      analysis: // value for 'analysis'
 *   },
 * });
 */
export function useUploadSingleCamtFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables>(UploadSingleCamtFileDocument, baseOptions);
      }
export type UploadSingleCamtFileMutationHookResult = ReturnType<typeof useUploadSingleCamtFileMutation>;
export type UploadSingleCamtFileMutationResult = ApolloReactCommon.MutationResult<UploadSingleCamtFileMutation>;
export type UploadSingleCamtFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadSingleCamtFileMutation, UploadSingleCamtFileMutationVariables>;
export const CreateDummyCamtFileForMultiplePaymentsDocument = gql`
    mutation CreateDummyCamtFileForMultiplePayments($createDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput!) {
  createDummyCamtFileForMultiplePayments(createDummySwissCamtByScheduledPaymentsInput: $createDummySwissCamtByScheduledPaymentsInput)
}
    `;
export type CreateDummyCamtFileForMultiplePaymentsMutationFn = ApolloReactCommon.MutationFunction<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables>;
export type CreateDummyCamtFileForMultiplePaymentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables>, 'mutation'>;

    export const CreateDummyCamtFileForMultiplePaymentsComponent = (props: CreateDummyCamtFileForMultiplePaymentsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables> mutation={CreateDummyCamtFileForMultiplePaymentsDocument} {...props} />
    );
    
export type CreateDummyCamtFileForMultiplePaymentsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables> & TChildProps;
export function withCreateDummyCamtFileForMultiplePayments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDummyCamtFileForMultiplePaymentsMutation,
  CreateDummyCamtFileForMultiplePaymentsMutationVariables,
  CreateDummyCamtFileForMultiplePaymentsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables, CreateDummyCamtFileForMultiplePaymentsProps<TChildProps>>(CreateDummyCamtFileForMultiplePaymentsDocument, {
      alias: 'createDummyCamtFileForMultiplePayments',
      ...operationOptions
    });
};

/**
 * __useCreateDummyCamtFileForMultiplePaymentsMutation__
 *
 * To run a mutation, you first call `useCreateDummyCamtFileForMultiplePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDummyCamtFileForMultiplePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDummyCamtFileForMultiplePaymentsMutation, { data, loading, error }] = useCreateDummyCamtFileForMultiplePaymentsMutation({
 *   variables: {
 *      createDummySwissCamtByScheduledPaymentsInput: // value for 'createDummySwissCamtByScheduledPaymentsInput'
 *   },
 * });
 */
export function useCreateDummyCamtFileForMultiplePaymentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables>(CreateDummyCamtFileForMultiplePaymentsDocument, baseOptions);
      }
export type CreateDummyCamtFileForMultiplePaymentsMutationHookResult = ReturnType<typeof useCreateDummyCamtFileForMultiplePaymentsMutation>;
export type CreateDummyCamtFileForMultiplePaymentsMutationResult = ApolloReactCommon.MutationResult<CreateDummyCamtFileForMultiplePaymentsMutation>;
export type CreateDummyCamtFileForMultiplePaymentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDummyCamtFileForMultiplePaymentsMutation, CreateDummyCamtFileForMultiplePaymentsMutationVariables>;
export const SendEmailToSelectionOfCourseDocument = gql`
    mutation SendEmailToSelectionOfCourse($sendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput!) {
  sendEmailsToSelectionOfCourse(sendMailToSelectionOfCourseInput: $sendMailToSelectionOfCourseInput)
}
    `;
export type SendEmailToSelectionOfCourseMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables>;
export type SendEmailToSelectionOfCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfCourseComponent = (props: SendEmailToSelectionOfCourseComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables> mutation={SendEmailToSelectionOfCourseDocument} {...props} />
    );
    
export type SendEmailToSelectionOfCourseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfCourse<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfCourseMutation,
  SendEmailToSelectionOfCourseMutationVariables,
  SendEmailToSelectionOfCourseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables, SendEmailToSelectionOfCourseProps<TChildProps>>(SendEmailToSelectionOfCourseDocument, {
      alias: 'sendEmailToSelectionOfCourse',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfCourseMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfCourseMutation, { data, loading, error }] = useSendEmailToSelectionOfCourseMutation({
 *   variables: {
 *      sendMailToSelectionOfCourseInput: // value for 'sendMailToSelectionOfCourseInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables>(SendEmailToSelectionOfCourseDocument, baseOptions);
      }
export type SendEmailToSelectionOfCourseMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfCourseMutation>;
export type SendEmailToSelectionOfCourseMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfCourseMutation>;
export type SendEmailToSelectionOfCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfCourseMutation, SendEmailToSelectionOfCourseMutationVariables>;
export const SendEmailToSelectionOfBookingsDocument = gql`
    mutation SendEmailToSelectionOfBookings($sendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput!) {
  sendMailToSelectionOfBookings(sendMailToSelectionOfBookingInput: $sendMailToSelectionOfBookingInput)
}
    `;
export type SendEmailToSelectionOfBookingsMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables>;
export type SendEmailToSelectionOfBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfBookingsComponent = (props: SendEmailToSelectionOfBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables> mutation={SendEmailToSelectionOfBookingsDocument} {...props} />
    );
    
export type SendEmailToSelectionOfBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfBookingsMutation,
  SendEmailToSelectionOfBookingsMutationVariables,
  SendEmailToSelectionOfBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables, SendEmailToSelectionOfBookingsProps<TChildProps>>(SendEmailToSelectionOfBookingsDocument, {
      alias: 'sendEmailToSelectionOfBookings',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfBookingsMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfBookingsMutation, { data, loading, error }] = useSendEmailToSelectionOfBookingsMutation({
 *   variables: {
 *      sendMailToSelectionOfBookingInput: // value for 'sendMailToSelectionOfBookingInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables>(SendEmailToSelectionOfBookingsDocument, baseOptions);
      }
export type SendEmailToSelectionOfBookingsMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfBookingsMutation>;
export type SendEmailToSelectionOfBookingsMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfBookingsMutation>;
export type SendEmailToSelectionOfBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfBookingsMutation, SendEmailToSelectionOfBookingsMutationVariables>;
export const SendEmailToSelectionOfBookersDocument = gql`
    mutation SendEmailToSelectionOfBookers($sendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput!) {
  sendEmailToSelectionOfBookers(sendMailToSelectionOfBookersInput: $sendMailToSelectionOfBookersInput)
}
    `;
export type SendEmailToSelectionOfBookersMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables>;
export type SendEmailToSelectionOfBookersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfBookersComponent = (props: SendEmailToSelectionOfBookersComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables> mutation={SendEmailToSelectionOfBookersDocument} {...props} />
    );
    
export type SendEmailToSelectionOfBookersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfBookers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfBookersMutation,
  SendEmailToSelectionOfBookersMutationVariables,
  SendEmailToSelectionOfBookersProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables, SendEmailToSelectionOfBookersProps<TChildProps>>(SendEmailToSelectionOfBookersDocument, {
      alias: 'sendEmailToSelectionOfBookers',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfBookersMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfBookersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfBookersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfBookersMutation, { data, loading, error }] = useSendEmailToSelectionOfBookersMutation({
 *   variables: {
 *      sendMailToSelectionOfBookersInput: // value for 'sendMailToSelectionOfBookersInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfBookersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables>(SendEmailToSelectionOfBookersDocument, baseOptions);
      }
export type SendEmailToSelectionOfBookersMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfBookersMutation>;
export type SendEmailToSelectionOfBookersMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfBookersMutation>;
export type SendEmailToSelectionOfBookersMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfBookersMutation, SendEmailToSelectionOfBookersMutationVariables>;
export const SendEmailToSelectionOfStaffDocument = gql`
    mutation SendEmailToSelectionOfStaff($sendMailToSelectionOfStaffInput: SendMailToSelectionOfStaffInput!) {
  sendEmailToSelectionOfStaff(sendMailToSelectionOfStaffInput: $sendMailToSelectionOfStaffInput)
}
    `;
export type SendEmailToSelectionOfStaffMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables>;
export type SendEmailToSelectionOfStaffComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfStaffComponent = (props: SendEmailToSelectionOfStaffComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables> mutation={SendEmailToSelectionOfStaffDocument} {...props} />
    );
    
export type SendEmailToSelectionOfStaffProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfStaff<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfStaffMutation,
  SendEmailToSelectionOfStaffMutationVariables,
  SendEmailToSelectionOfStaffProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables, SendEmailToSelectionOfStaffProps<TChildProps>>(SendEmailToSelectionOfStaffDocument, {
      alias: 'sendEmailToSelectionOfStaff',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfStaffMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfStaffMutation, { data, loading, error }] = useSendEmailToSelectionOfStaffMutation({
 *   variables: {
 *      sendMailToSelectionOfStaffInput: // value for 'sendMailToSelectionOfStaffInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables>(SendEmailToSelectionOfStaffDocument, baseOptions);
      }
export type SendEmailToSelectionOfStaffMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfStaffMutation>;
export type SendEmailToSelectionOfStaffMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfStaffMutation>;
export type SendEmailToSelectionOfStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfStaffMutation, SendEmailToSelectionOfStaffMutationVariables>;
export const SendEmailToSelectionOfAttendeesDocument = gql`
    mutation SendEmailToSelectionOfAttendees($sendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput!) {
  sendEmailToSelectionOfAttendees(sendMailToSelectionOfAttendeesInput: $sendMailToSelectionOfAttendeesInput)
}
    `;
export type SendEmailToSelectionOfAttendeesMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables>;
export type SendEmailToSelectionOfAttendeesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfAttendeesComponent = (props: SendEmailToSelectionOfAttendeesComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables> mutation={SendEmailToSelectionOfAttendeesDocument} {...props} />
    );
    
export type SendEmailToSelectionOfAttendeesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfAttendees<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfAttendeesMutation,
  SendEmailToSelectionOfAttendeesMutationVariables,
  SendEmailToSelectionOfAttendeesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables, SendEmailToSelectionOfAttendeesProps<TChildProps>>(SendEmailToSelectionOfAttendeesDocument, {
      alias: 'sendEmailToSelectionOfAttendees',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfAttendeesMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfAttendeesMutation, { data, loading, error }] = useSendEmailToSelectionOfAttendeesMutation({
 *   variables: {
 *      sendMailToSelectionOfAttendeesInput: // value for 'sendMailToSelectionOfAttendeesInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfAttendeesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables>(SendEmailToSelectionOfAttendeesDocument, baseOptions);
      }
export type SendEmailToSelectionOfAttendeesMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfAttendeesMutation>;
export type SendEmailToSelectionOfAttendeesMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfAttendeesMutation>;
export type SendEmailToSelectionOfAttendeesMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfAttendeesMutation, SendEmailToSelectionOfAttendeesMutationVariables>;
export const SendEmailToSelectionOfScheduledPaymentsDocument = gql`
    mutation SendEmailToSelectionOfScheduledPayments($sendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput!) {
  sendEmailToSelectionOfScheduledPayments(sendMailToSelectionOfScheduledPaymentsInput: $sendMailToSelectionOfScheduledPaymentsInput)
}
    `;
export type SendEmailToSelectionOfScheduledPaymentsMutationFn = ApolloReactCommon.MutationFunction<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables>;
export type SendEmailToSelectionOfScheduledPaymentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables>, 'mutation'>;

    export const SendEmailToSelectionOfScheduledPaymentsComponent = (props: SendEmailToSelectionOfScheduledPaymentsComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables> mutation={SendEmailToSelectionOfScheduledPaymentsDocument} {...props} />
    );
    
export type SendEmailToSelectionOfScheduledPaymentsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables> & TChildProps;
export function withSendEmailToSelectionOfScheduledPayments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailToSelectionOfScheduledPaymentsMutation,
  SendEmailToSelectionOfScheduledPaymentsMutationVariables,
  SendEmailToSelectionOfScheduledPaymentsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables, SendEmailToSelectionOfScheduledPaymentsProps<TChildProps>>(SendEmailToSelectionOfScheduledPaymentsDocument, {
      alias: 'sendEmailToSelectionOfScheduledPayments',
      ...operationOptions
    });
};

/**
 * __useSendEmailToSelectionOfScheduledPaymentsMutation__
 *
 * To run a mutation, you first call `useSendEmailToSelectionOfScheduledPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSelectionOfScheduledPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSelectionOfScheduledPaymentsMutation, { data, loading, error }] = useSendEmailToSelectionOfScheduledPaymentsMutation({
 *   variables: {
 *      sendMailToSelectionOfScheduledPaymentsInput: // value for 'sendMailToSelectionOfScheduledPaymentsInput'
 *   },
 * });
 */
export function useSendEmailToSelectionOfScheduledPaymentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables>(SendEmailToSelectionOfScheduledPaymentsDocument, baseOptions);
      }
export type SendEmailToSelectionOfScheduledPaymentsMutationHookResult = ReturnType<typeof useSendEmailToSelectionOfScheduledPaymentsMutation>;
export type SendEmailToSelectionOfScheduledPaymentsMutationResult = ApolloReactCommon.MutationResult<SendEmailToSelectionOfScheduledPaymentsMutation>;
export type SendEmailToSelectionOfScheduledPaymentsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailToSelectionOfScheduledPaymentsMutation, SendEmailToSelectionOfScheduledPaymentsMutationVariables>;
export const SendInvoicesToSelectionOfBookingsDocument = gql`
    mutation SendInvoicesToSelectionOfBookings($sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput!) {
  sendInvoicesToSelectionOfBookings(sendInvoicesToSelectionOfBookingsInput: $sendInvoicesToSelectionOfBookingsInput)
}
    `;
export type SendInvoicesToSelectionOfBookingsMutationFn = ApolloReactCommon.MutationFunction<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables>;
export type SendInvoicesToSelectionOfBookingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables>, 'mutation'>;

    export const SendInvoicesToSelectionOfBookingsComponent = (props: SendInvoicesToSelectionOfBookingsComponentProps) => (
      <ApolloReactComponents.Mutation<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables> mutation={SendInvoicesToSelectionOfBookingsDocument} {...props} />
    );
    
export type SendInvoicesToSelectionOfBookingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables> & TChildProps;
export function withSendInvoicesToSelectionOfBookings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendInvoicesToSelectionOfBookingsMutation,
  SendInvoicesToSelectionOfBookingsMutationVariables,
  SendInvoicesToSelectionOfBookingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables, SendInvoicesToSelectionOfBookingsProps<TChildProps>>(SendInvoicesToSelectionOfBookingsDocument, {
      alias: 'sendInvoicesToSelectionOfBookings',
      ...operationOptions
    });
};

/**
 * __useSendInvoicesToSelectionOfBookingsMutation__
 *
 * To run a mutation, you first call `useSendInvoicesToSelectionOfBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoicesToSelectionOfBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoicesToSelectionOfBookingsMutation, { data, loading, error }] = useSendInvoicesToSelectionOfBookingsMutation({
 *   variables: {
 *      sendInvoicesToSelectionOfBookingsInput: // value for 'sendInvoicesToSelectionOfBookingsInput'
 *   },
 * });
 */
export function useSendInvoicesToSelectionOfBookingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables>(SendInvoicesToSelectionOfBookingsDocument, baseOptions);
      }
export type SendInvoicesToSelectionOfBookingsMutationHookResult = ReturnType<typeof useSendInvoicesToSelectionOfBookingsMutation>;
export type SendInvoicesToSelectionOfBookingsMutationResult = ApolloReactCommon.MutationResult<SendInvoicesToSelectionOfBookingsMutation>;
export type SendInvoicesToSelectionOfBookingsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendInvoicesToSelectionOfBookingsMutation, SendInvoicesToSelectionOfBookingsMutationVariables>;
export const SendEmailTemplateDocument = gql`
    mutation SendEmailTemplate($sendTemplateInput: SendTemplateInput!) {
  sendEmailTemplate(sendTemplateInput: $sendTemplateInput)
}
    `;
export type SendEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<SendEmailTemplateMutation, SendEmailTemplateMutationVariables>;
export type SendEmailTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailTemplateMutation, SendEmailTemplateMutationVariables>, 'mutation'>;

    export const SendEmailTemplateComponent = (props: SendEmailTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailTemplateMutation, SendEmailTemplateMutationVariables> mutation={SendEmailTemplateDocument} {...props} />
    );
    
export type SendEmailTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailTemplateMutation, SendEmailTemplateMutationVariables> & TChildProps;
export function withSendEmailTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailTemplateMutation,
  SendEmailTemplateMutationVariables,
  SendEmailTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailTemplateMutation, SendEmailTemplateMutationVariables, SendEmailTemplateProps<TChildProps>>(SendEmailTemplateDocument, {
      alias: 'sendEmailTemplate',
      ...operationOptions
    });
};

/**
 * __useSendEmailTemplateMutation__
 *
 * To run a mutation, you first call `useSendEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailTemplateMutation, { data, loading, error }] = useSendEmailTemplateMutation({
 *   variables: {
 *      sendTemplateInput: // value for 'sendTemplateInput'
 *   },
 * });
 */
export function useSendEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailTemplateMutation, SendEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailTemplateMutation, SendEmailTemplateMutationVariables>(SendEmailTemplateDocument, baseOptions);
      }
export type SendEmailTemplateMutationHookResult = ReturnType<typeof useSendEmailTemplateMutation>;
export type SendEmailTemplateMutationResult = ApolloReactCommon.MutationResult<SendEmailTemplateMutation>;
export type SendEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailTemplateMutation, SendEmailTemplateMutationVariables>;
export const GetTemplatesDocument = gql`
    query GetTemplates {
  templates {
    id
    templateType
    name
    isDefault
    mailDataType
    createDate
    updateDate
    includeCourseDetails
  }
}
    `;
export type GetTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesQuery, GetTemplatesQueryVariables>, 'query'>;

    export const GetTemplatesComponent = (props: GetTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesQuery, GetTemplatesQueryVariables> query={GetTemplatesDocument} {...props} />
    );
    
export type GetTemplatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplatesQuery, GetTemplatesQueryVariables> & TChildProps;
export function withGetTemplates<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  GetTemplatesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesQuery, GetTemplatesQueryVariables, GetTemplatesProps<TChildProps>>(GetTemplatesDocument, {
      alias: 'getTemplates',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
      }
export function useGetTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = ApolloReactCommon.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const GetTemplatesWithPaginationDocument = gql`
    query GetTemplatesWithPagination($options: ArrayDataOptions) {
  templatesWithPagination(options: $options) {
    existsMore
    items {
      id
      templateType
      name
      subject
      isDefault
      mailDataType
      createDate
      updateDate
      includeCourseDetails
    }
    total
  }
}
    `;
export type GetTemplatesWithPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>, 'query'>;

    export const GetTemplatesWithPaginationComponent = (props: GetTemplatesWithPaginationComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables> query={GetTemplatesWithPaginationDocument} {...props} />
    );
    
export type GetTemplatesWithPaginationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables> & TChildProps;
export function withGetTemplatesWithPagination<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesWithPaginationQuery,
  GetTemplatesWithPaginationQueryVariables,
  GetTemplatesWithPaginationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables, GetTemplatesWithPaginationProps<TChildProps>>(GetTemplatesWithPaginationDocument, {
      alias: 'getTemplatesWithPagination',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetTemplatesWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesWithPaginationQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetTemplatesWithPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>(GetTemplatesWithPaginationDocument, baseOptions);
      }
export function useGetTemplatesWithPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>(GetTemplatesWithPaginationDocument, baseOptions);
        }
export type GetTemplatesWithPaginationQueryHookResult = ReturnType<typeof useGetTemplatesWithPaginationQuery>;
export type GetTemplatesWithPaginationLazyQueryHookResult = ReturnType<typeof useGetTemplatesWithPaginationLazyQuery>;
export type GetTemplatesWithPaginationQueryResult = ApolloReactCommon.QueryResult<GetTemplatesWithPaginationQuery, GetTemplatesWithPaginationQueryVariables>;
export const CheckIfTemplateOfTypeExistsDocument = gql`
    query CheckIfTemplateOfTypeExists($templateType: TemplateType!) {
  checkIfTemplateOfTypeExists(templateType: $templateType)
}
    `;
export type CheckIfTemplateOfTypeExistsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>, 'query'> & ({ variables: CheckIfTemplateOfTypeExistsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckIfTemplateOfTypeExistsComponent = (props: CheckIfTemplateOfTypeExistsComponentProps) => (
      <ApolloReactComponents.Query<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables> query={CheckIfTemplateOfTypeExistsDocument} {...props} />
    );
    
export type CheckIfTemplateOfTypeExistsProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables> & TChildProps;
export function withCheckIfTemplateOfTypeExists<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckIfTemplateOfTypeExistsQuery,
  CheckIfTemplateOfTypeExistsQueryVariables,
  CheckIfTemplateOfTypeExistsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables, CheckIfTemplateOfTypeExistsProps<TChildProps>>(CheckIfTemplateOfTypeExistsDocument, {
      alias: 'checkIfTemplateOfTypeExists',
      ...operationOptions
    });
};

/**
 * __useCheckIfTemplateOfTypeExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfTemplateOfTypeExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfTemplateOfTypeExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfTemplateOfTypeExistsQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useCheckIfTemplateOfTypeExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>(CheckIfTemplateOfTypeExistsDocument, baseOptions);
      }
export function useCheckIfTemplateOfTypeExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>(CheckIfTemplateOfTypeExistsDocument, baseOptions);
        }
export type CheckIfTemplateOfTypeExistsQueryHookResult = ReturnType<typeof useCheckIfTemplateOfTypeExistsQuery>;
export type CheckIfTemplateOfTypeExistsLazyQueryHookResult = ReturnType<typeof useCheckIfTemplateOfTypeExistsLazyQuery>;
export type CheckIfTemplateOfTypeExistsQueryResult = ApolloReactCommon.QueryResult<CheckIfTemplateOfTypeExistsQuery, CheckIfTemplateOfTypeExistsQueryVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($newTemplateData: NewTemplateInput!) {
  createTemplate(newTemplateData: $newTemplateData) {
    id
  }
}
    `;
export type CreateTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;
export type CreateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTemplateMutation, CreateTemplateMutationVariables>, 'mutation'>;

    export const CreateTemplateComponent = (props: CreateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTemplateMutation, CreateTemplateMutationVariables> mutation={CreateTemplateDocument} {...props} />
    );
    
export type CreateTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateTemplateMutation, CreateTemplateMutationVariables> & TChildProps;
export function withCreateTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  CreateTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTemplateMutation, CreateTemplateMutationVariables, CreateTemplateProps<TChildProps>>(CreateTemplateDocument, {
      alias: 'createTemplate',
      ...operationOptions
    });
};

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      newTemplateData: // value for 'newTemplateData'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, baseOptions);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($id: String!) {
  deleteTemplate(id: $id)
}
    `;
export type DeleteTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export type DeleteTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>, 'mutation'>;

    export const DeleteTemplateComponent = (props: DeleteTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTemplateMutation, DeleteTemplateMutationVariables> mutation={DeleteTemplateDocument} {...props} />
    );
    
export type DeleteTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteTemplateMutation, DeleteTemplateMutationVariables> & TChildProps;
export function withDeleteTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
  DeleteTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTemplateMutation, DeleteTemplateMutationVariables, DeleteTemplateProps<TChildProps>>(DeleteTemplateDocument, {
      alias: 'deleteTemplate',
      ...operationOptions
    });
};

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, baseOptions);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const GetTemplateByIdDocument = gql`
    query GetTemplateById($id: String!) {
  template(id: $id) {
    id
    name
    templateType
    headerText
    footerText
    signature
    subject
    isDefault
    includeCourseDetails
  }
}
    `;
export type GetTemplateByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>, 'query'> & ({ variables: GetTemplateByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplateByIdComponent = (props: GetTemplateByIdComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateByIdQuery, GetTemplateByIdQueryVariables> query={GetTemplateByIdDocument} {...props} />
    );
    
export type GetTemplateByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplateByIdQuery, GetTemplateByIdQueryVariables> & TChildProps;
export function withGetTemplateById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateByIdQuery,
  GetTemplateByIdQueryVariables,
  GetTemplateByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateByIdQuery, GetTemplateByIdQueryVariables, GetTemplateByIdProps<TChildProps>>(GetTemplateByIdDocument, {
      alias: 'getTemplateById',
      ...operationOptions
    });
};

/**
 * __useGetTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>(GetTemplateByIdDocument, baseOptions);
      }
export function useGetTemplateByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>(GetTemplateByIdDocument, baseOptions);
        }
export type GetTemplateByIdQueryHookResult = ReturnType<typeof useGetTemplateByIdQuery>;
export type GetTemplateByIdLazyQueryHookResult = ReturnType<typeof useGetTemplateByIdLazyQuery>;
export type GetTemplateByIdQueryResult = ApolloReactCommon.QueryResult<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($editTemplateData: EditTemplateInput!, $id: String!) {
  updateTemplate(editTemplateData: $editTemplateData, id: $id) {
    id
    templateType
  }
}
    `;
export type UpdateTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export type UpdateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>, 'mutation'>;

    export const UpdateTemplateComponent = (props: UpdateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> mutation={UpdateTemplateDocument} {...props} />
    );
    
export type UpdateTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTemplateMutation, UpdateTemplateMutationVariables> & TChildProps;
export function withUpdateTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
  UpdateTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTemplateMutation, UpdateTemplateMutationVariables, UpdateTemplateProps<TChildProps>>(UpdateTemplateDocument, {
      alias: 'updateTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      editTemplateData: // value for 'editTemplateData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, baseOptions);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const GetTemplateByTypeDocument = gql`
    query GetTemplateByType($templateType: TemplateType!) {
  templateByType(templateType: $templateType) {
    id
    name
    templateType
    headerText
    footerText
    signature
    subject
    includeCourseDetails
  }
}
    `;
export type GetTemplateByTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>, 'query'> & ({ variables: GetTemplateByTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplateByTypeComponent = (props: GetTemplateByTypeComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables> query={GetTemplateByTypeDocument} {...props} />
    );
    
export type GetTemplateByTypeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables> & TChildProps;
export function withGetTemplateByType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateByTypeQuery,
  GetTemplateByTypeQueryVariables,
  GetTemplateByTypeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables, GetTemplateByTypeProps<TChildProps>>(GetTemplateByTypeDocument, {
      alias: 'getTemplateByType',
      ...operationOptions
    });
};

/**
 * __useGetTemplateByTypeQuery__
 *
 * To run a query within a React component, call `useGetTemplateByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateByTypeQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useGetTemplateByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>(GetTemplateByTypeDocument, baseOptions);
      }
export function useGetTemplateByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>(GetTemplateByTypeDocument, baseOptions);
        }
export type GetTemplateByTypeQueryHookResult = ReturnType<typeof useGetTemplateByTypeQuery>;
export type GetTemplateByTypeLazyQueryHookResult = ReturnType<typeof useGetTemplateByTypeLazyQuery>;
export type GetTemplateByTypeQueryResult = ApolloReactCommon.QueryResult<GetTemplateByTypeQuery, GetTemplateByTypeQueryVariables>;
export const SetIncludeCourseDetailsDocument = gql`
    mutation SetIncludeCourseDetails($templateId: ID!, $includeCourseDetails: Boolean!) {
  setIncludeCourseDetails(templateId: $templateId, includeCourseDetails: $includeCourseDetails) {
    id
    includeCourseDetails
  }
}
    `;
export type SetIncludeCourseDetailsMutationFn = ApolloReactCommon.MutationFunction<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables>;
export type SetIncludeCourseDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables>, 'mutation'>;

    export const SetIncludeCourseDetailsComponent = (props: SetIncludeCourseDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables> mutation={SetIncludeCourseDetailsDocument} {...props} />
    );
    
export type SetIncludeCourseDetailsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables> & TChildProps;
export function withSetIncludeCourseDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetIncludeCourseDetailsMutation,
  SetIncludeCourseDetailsMutationVariables,
  SetIncludeCourseDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables, SetIncludeCourseDetailsProps<TChildProps>>(SetIncludeCourseDetailsDocument, {
      alias: 'setIncludeCourseDetails',
      ...operationOptions
    });
};

/**
 * __useSetIncludeCourseDetailsMutation__
 *
 * To run a mutation, you first call `useSetIncludeCourseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIncludeCourseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIncludeCourseDetailsMutation, { data, loading, error }] = useSetIncludeCourseDetailsMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      includeCourseDetails: // value for 'includeCourseDetails'
 *   },
 * });
 */
export function useSetIncludeCourseDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables>(SetIncludeCourseDetailsDocument, baseOptions);
      }
export type SetIncludeCourseDetailsMutationHookResult = ReturnType<typeof useSetIncludeCourseDetailsMutation>;
export type SetIncludeCourseDetailsMutationResult = ApolloReactCommon.MutationResult<SetIncludeCourseDetailsMutation>;
export type SetIncludeCourseDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetIncludeCourseDetailsMutation, SetIncludeCourseDetailsMutationVariables>;
export const LoggerDocument = gql`
    mutation logger($input: LoggingInput!) {
  logging(input: $input)
}
    `;
export type LoggerMutationFn = ApolloReactCommon.MutationFunction<LoggerMutation, LoggerMutationVariables>;
export type LoggerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoggerMutation, LoggerMutationVariables>, 'mutation'>;

    export const LoggerComponent = (props: LoggerComponentProps) => (
      <ApolloReactComponents.Mutation<LoggerMutation, LoggerMutationVariables> mutation={LoggerDocument} {...props} />
    );
    
export type LoggerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoggerMutation, LoggerMutationVariables> & TChildProps;
export function withLogger<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoggerMutation,
  LoggerMutationVariables,
  LoggerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoggerMutation, LoggerMutationVariables, LoggerProps<TChildProps>>(LoggerDocument, {
      alias: 'logger',
      ...operationOptions
    });
};

/**
 * __useLoggerMutation__
 *
 * To run a mutation, you first call `useLoggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loggerMutation, { data, loading, error }] = useLoggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoggerMutation, LoggerMutationVariables>) {
        return ApolloReactHooks.useMutation<LoggerMutation, LoggerMutationVariables>(LoggerDocument, baseOptions);
      }
export type LoggerMutationHookResult = ReturnType<typeof useLoggerMutation>;
export type LoggerMutationResult = ApolloReactCommon.MutationResult<LoggerMutation>;
export type LoggerMutationOptions = ApolloReactCommon.BaseMutationOptions<LoggerMutation, LoggerMutationVariables>;
export const GetUserSettingDocument = gql`
    query getUserSetting {
  userSettings {
    id
    language
    profilePictureUrl
    columnSettings
  }
}
    `;
export type GetUserSettingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserSettingQuery, GetUserSettingQueryVariables>, 'query'>;

    export const GetUserSettingComponent = (props: GetUserSettingComponentProps) => (
      <ApolloReactComponents.Query<GetUserSettingQuery, GetUserSettingQueryVariables> query={GetUserSettingDocument} {...props} />
    );
    
export type GetUserSettingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserSettingQuery, GetUserSettingQueryVariables> & TChildProps;
export function withGetUserSetting<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserSettingQuery,
  GetUserSettingQueryVariables,
  GetUserSettingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserSettingQuery, GetUserSettingQueryVariables, GetUserSettingProps<TChildProps>>(GetUserSettingDocument, {
      alias: 'getUserSetting',
      ...operationOptions
    });
};

/**
 * __useGetUserSettingQuery__
 *
 * To run a query within a React component, call `useGetUserSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSettingQuery, GetUserSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSettingQuery, GetUserSettingQueryVariables>(GetUserSettingDocument, baseOptions);
      }
export function useGetUserSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSettingQuery, GetUserSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSettingQuery, GetUserSettingQueryVariables>(GetUserSettingDocument, baseOptions);
        }
export type GetUserSettingQueryHookResult = ReturnType<typeof useGetUserSettingQuery>;
export type GetUserSettingLazyQueryHookResult = ReturnType<typeof useGetUserSettingLazyQuery>;
export type GetUserSettingQueryResult = ApolloReactCommon.QueryResult<GetUserSettingQuery, GetUserSettingQueryVariables>;
export const GetUserSettingByIdAllDocument = gql`
    query getUserSettingByIdAll {
  userSettings {
    id
    language
    profilePictureUrl
    columnSettings
    optimizedCourseCalendar
  }
}
    `;
export type GetUserSettingByIdAllComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>, 'query'>;

    export const GetUserSettingByIdAllComponent = (props: GetUserSettingByIdAllComponentProps) => (
      <ApolloReactComponents.Query<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables> query={GetUserSettingByIdAllDocument} {...props} />
    );
    
export type GetUserSettingByIdAllProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables> & TChildProps;
export function withGetUserSettingByIdAll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserSettingByIdAllQuery,
  GetUserSettingByIdAllQueryVariables,
  GetUserSettingByIdAllProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables, GetUserSettingByIdAllProps<TChildProps>>(GetUserSettingByIdAllDocument, {
      alias: 'getUserSettingByIdAll',
      ...operationOptions
    });
};

/**
 * __useGetUserSettingByIdAllQuery__
 *
 * To run a query within a React component, call `useGetUserSettingByIdAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingByIdAllQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingByIdAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingByIdAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>(GetUserSettingByIdAllDocument, baseOptions);
      }
export function useGetUserSettingByIdAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>(GetUserSettingByIdAllDocument, baseOptions);
        }
export type GetUserSettingByIdAllQueryHookResult = ReturnType<typeof useGetUserSettingByIdAllQuery>;
export type GetUserSettingByIdAllLazyQueryHookResult = ReturnType<typeof useGetUserSettingByIdAllLazyQuery>;
export type GetUserSettingByIdAllQueryResult = ApolloReactCommon.QueryResult<GetUserSettingByIdAllQuery, GetUserSettingByIdAllQueryVariables>;
export const SetOptimizedCourseCalendarDocument = gql`
    mutation setOptimizedCourseCalendar($optimize: Boolean!) {
  userSettingSetOptimizedCourseCalendar(optimize: $optimize) {
    id
  }
}
    `;
export type SetOptimizedCourseCalendarMutationFn = ApolloReactCommon.MutationFunction<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables>;
export type SetOptimizedCourseCalendarComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables>, 'mutation'>;

    export const SetOptimizedCourseCalendarComponent = (props: SetOptimizedCourseCalendarComponentProps) => (
      <ApolloReactComponents.Mutation<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables> mutation={SetOptimizedCourseCalendarDocument} {...props} />
    );
    
export type SetOptimizedCourseCalendarProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables> & TChildProps;
export function withSetOptimizedCourseCalendar<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetOptimizedCourseCalendarMutation,
  SetOptimizedCourseCalendarMutationVariables,
  SetOptimizedCourseCalendarProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables, SetOptimizedCourseCalendarProps<TChildProps>>(SetOptimizedCourseCalendarDocument, {
      alias: 'setOptimizedCourseCalendar',
      ...operationOptions
    });
};

/**
 * __useSetOptimizedCourseCalendarMutation__
 *
 * To run a mutation, you first call `useSetOptimizedCourseCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOptimizedCourseCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOptimizedCourseCalendarMutation, { data, loading, error }] = useSetOptimizedCourseCalendarMutation({
 *   variables: {
 *      optimize: // value for 'optimize'
 *   },
 * });
 */
export function useSetOptimizedCourseCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables>(SetOptimizedCourseCalendarDocument, baseOptions);
      }
export type SetOptimizedCourseCalendarMutationHookResult = ReturnType<typeof useSetOptimizedCourseCalendarMutation>;
export type SetOptimizedCourseCalendarMutationResult = ApolloReactCommon.MutationResult<SetOptimizedCourseCalendarMutation>;
export type SetOptimizedCourseCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<SetOptimizedCourseCalendarMutation, SetOptimizedCourseCalendarMutationVariables>;
export const UpdateUserSettingDocument = gql`
    mutation updateUserSetting($userSettingsInput: UserSettingsInputTypes!) {
  userSettingsUpdateOrCreate(userSettingsInput: $userSettingsInput) {
    id
    language
    profilePictureUrl
    columnSettings
  }
}
    `;
export type UpdateUserSettingMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>;
export type UpdateUserSettingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>, 'mutation'>;

    export const UpdateUserSettingComponent = (props: UpdateUserSettingComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserSettingMutation, UpdateUserSettingMutationVariables> mutation={UpdateUserSettingDocument} {...props} />
    );
    
export type UpdateUserSettingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserSettingMutation, UpdateUserSettingMutationVariables> & TChildProps;
export function withUpdateUserSetting<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserSettingMutation,
  UpdateUserSettingMutationVariables,
  UpdateUserSettingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserSettingMutation, UpdateUserSettingMutationVariables, UpdateUserSettingProps<TChildProps>>(UpdateUserSettingDocument, {
      alias: 'updateUserSetting',
      ...operationOptions
    });
};

/**
 * __useUpdateUserSettingMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingMutation, { data, loading, error }] = useUpdateUserSettingMutation({
 *   variables: {
 *      userSettingsInput: // value for 'userSettingsInput'
 *   },
 * });
 */
export function useUpdateUserSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>(UpdateUserSettingDocument, baseOptions);
      }
export type UpdateUserSettingMutationHookResult = ReturnType<typeof useUpdateUserSettingMutation>;
export type UpdateUserSettingMutationResult = ApolloReactCommon.MutationResult<UpdateUserSettingMutation>;
export type UpdateUserSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>;
export const UpdateTableColumnSettingsDocument = gql`
    mutation updateTableColumnSettings($updateTableColumnSettingsInput: UpdateTableColumnSettingsInput!) {
  updateTableColumnSettings(updateTableColumnSettingsInput: $updateTableColumnSettingsInput) {
    id
    columnSettings
  }
}
    `;
export type UpdateTableColumnSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables>;
export type UpdateTableColumnSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables>, 'mutation'>;

    export const UpdateTableColumnSettingsComponent = (props: UpdateTableColumnSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables> mutation={UpdateTableColumnSettingsDocument} {...props} />
    );
    
export type UpdateTableColumnSettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables> & TChildProps;
export function withUpdateTableColumnSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTableColumnSettingsMutation,
  UpdateTableColumnSettingsMutationVariables,
  UpdateTableColumnSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables, UpdateTableColumnSettingsProps<TChildProps>>(UpdateTableColumnSettingsDocument, {
      alias: 'updateTableColumnSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateTableColumnSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTableColumnSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableColumnSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableColumnSettingsMutation, { data, loading, error }] = useUpdateTableColumnSettingsMutation({
 *   variables: {
 *      updateTableColumnSettingsInput: // value for 'updateTableColumnSettingsInput'
 *   },
 * });
 */
export function useUpdateTableColumnSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables>(UpdateTableColumnSettingsDocument, baseOptions);
      }
export type UpdateTableColumnSettingsMutationHookResult = ReturnType<typeof useUpdateTableColumnSettingsMutation>;
export type UpdateTableColumnSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateTableColumnSettingsMutation>;
export type UpdateTableColumnSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTableColumnSettingsMutation, UpdateTableColumnSettingsMutationVariables>;
export const GetColumnsForTableDocument = gql`
    query getColumnsForTable($table: TablesEnum!) {
  getColumnsForTable(table: $table)
}
    `;
export type GetColumnsForTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>, 'query'> & ({ variables: GetColumnsForTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetColumnsForTableComponent = (props: GetColumnsForTableComponentProps) => (
      <ApolloReactComponents.Query<GetColumnsForTableQuery, GetColumnsForTableQueryVariables> query={GetColumnsForTableDocument} {...props} />
    );
    
export type GetColumnsForTableProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetColumnsForTableQuery, GetColumnsForTableQueryVariables> & TChildProps;
export function withGetColumnsForTable<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetColumnsForTableQuery,
  GetColumnsForTableQueryVariables,
  GetColumnsForTableProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetColumnsForTableQuery, GetColumnsForTableQueryVariables, GetColumnsForTableProps<TChildProps>>(GetColumnsForTableDocument, {
      alias: 'getColumnsForTable',
      ...operationOptions
    });
};

/**
 * __useGetColumnsForTableQuery__
 *
 * To run a query within a React component, call `useGetColumnsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColumnsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColumnsForTableQuery({
 *   variables: {
 *      table: // value for 'table'
 *   },
 * });
 */
export function useGetColumnsForTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>(GetColumnsForTableDocument, baseOptions);
      }
export function useGetColumnsForTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>(GetColumnsForTableDocument, baseOptions);
        }
export type GetColumnsForTableQueryHookResult = ReturnType<typeof useGetColumnsForTableQuery>;
export type GetColumnsForTableLazyQueryHookResult = ReturnType<typeof useGetColumnsForTableLazyQuery>;
export type GetColumnsForTableQueryResult = ApolloReactCommon.QueryResult<GetColumnsForTableQuery, GetColumnsForTableQueryVariables>;
export const GetAttendanceListSettingsDocument = gql`
    query getAttendanceListSettings {
  userSettings {
    id
    attendanceListSettings
  }
}
    `;
export type GetAttendanceListSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>, 'query'>;

    export const GetAttendanceListSettingsComponent = (props: GetAttendanceListSettingsComponentProps) => (
      <ApolloReactComponents.Query<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables> query={GetAttendanceListSettingsDocument} {...props} />
    );
    
export type GetAttendanceListSettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables> & TChildProps;
export function withGetAttendanceListSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttendanceListSettingsQuery,
  GetAttendanceListSettingsQueryVariables,
  GetAttendanceListSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables, GetAttendanceListSettingsProps<TChildProps>>(GetAttendanceListSettingsDocument, {
      alias: 'getAttendanceListSettings',
      ...operationOptions
    });
};

/**
 * __useGetAttendanceListSettingsQuery__
 *
 * To run a query within a React component, call `useGetAttendanceListSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceListSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceListSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttendanceListSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>(GetAttendanceListSettingsDocument, baseOptions);
      }
export function useGetAttendanceListSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>(GetAttendanceListSettingsDocument, baseOptions);
        }
export type GetAttendanceListSettingsQueryHookResult = ReturnType<typeof useGetAttendanceListSettingsQuery>;
export type GetAttendanceListSettingsLazyQueryHookResult = ReturnType<typeof useGetAttendanceListSettingsLazyQuery>;
export type GetAttendanceListSettingsQueryResult = ApolloReactCommon.QueryResult<GetAttendanceListSettingsQuery, GetAttendanceListSettingsQueryVariables>;
export const UpdateAttendanceListSettingsDocument = gql`
    mutation updateAttendanceListSettings($updateAttendanceListSettingsInput: UpdateAttendanceListSettingsInput!) {
  updateAttendanceListSettings(updateAttendanceListSettingsInput: $updateAttendanceListSettingsInput) {
    id
    attendanceListSettings
  }
}
    `;
export type UpdateAttendanceListSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables>;
export type UpdateAttendanceListSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables>, 'mutation'>;

    export const UpdateAttendanceListSettingsComponent = (props: UpdateAttendanceListSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables> mutation={UpdateAttendanceListSettingsDocument} {...props} />
    );
    
export type UpdateAttendanceListSettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables> & TChildProps;
export function withUpdateAttendanceListSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAttendanceListSettingsMutation,
  UpdateAttendanceListSettingsMutationVariables,
  UpdateAttendanceListSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables, UpdateAttendanceListSettingsProps<TChildProps>>(UpdateAttendanceListSettingsDocument, {
      alias: 'updateAttendanceListSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateAttendanceListSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceListSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceListSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceListSettingsMutation, { data, loading, error }] = useUpdateAttendanceListSettingsMutation({
 *   variables: {
 *      updateAttendanceListSettingsInput: // value for 'updateAttendanceListSettingsInput'
 *   },
 * });
 */
export function useUpdateAttendanceListSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables>(UpdateAttendanceListSettingsDocument, baseOptions);
      }
export type UpdateAttendanceListSettingsMutationHookResult = ReturnType<typeof useUpdateAttendanceListSettingsMutation>;
export type UpdateAttendanceListSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateAttendanceListSettingsMutation>;
export type UpdateAttendanceListSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAttendanceListSettingsMutation, UpdateAttendanceListSettingsMutationVariables>;
export const GetMjmlTemplateByIdDocument = gql`
    query GetMjmlTemplateById($templateId: String!) {
  getEmailTemplateById(templateId: $templateId) {
    Count
    Data
    Total
  }
}
    `;
export type GetMjmlTemplateByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>, 'query'> & ({ variables: GetMjmlTemplateByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMjmlTemplateByIdComponent = (props: GetMjmlTemplateByIdComponentProps) => (
      <ApolloReactComponents.Query<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables> query={GetMjmlTemplateByIdDocument} {...props} />
    );
    
export type GetMjmlTemplateByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables> & TChildProps;
export function withGetMjmlTemplateById<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMjmlTemplateByIdQuery,
  GetMjmlTemplateByIdQueryVariables,
  GetMjmlTemplateByIdProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables, GetMjmlTemplateByIdProps<TChildProps>>(GetMjmlTemplateByIdDocument, {
      alias: 'getMjmlTemplateById',
      ...operationOptions
    });
};

/**
 * __useGetMjmlTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetMjmlTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMjmlTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMjmlTemplateByIdQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetMjmlTemplateByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>(GetMjmlTemplateByIdDocument, baseOptions);
      }
export function useGetMjmlTemplateByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>(GetMjmlTemplateByIdDocument, baseOptions);
        }
export type GetMjmlTemplateByIdQueryHookResult = ReturnType<typeof useGetMjmlTemplateByIdQuery>;
export type GetMjmlTemplateByIdLazyQueryHookResult = ReturnType<typeof useGetMjmlTemplateByIdLazyQuery>;
export type GetMjmlTemplateByIdQueryResult = ApolloReactCommon.QueryResult<GetMjmlTemplateByIdQuery, GetMjmlTemplateByIdQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Booking: ResolverTypeWrapper<Booking>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Attendee: ResolverTypeWrapper<Attendee>;
  AttendeeBadge: ResolverTypeWrapper<AttendeeBadge>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Badge: ResolverTypeWrapper<Badge>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Booker: ResolverTypeWrapper<Booker>;
  CreatedBy: CreatedBy;
  Customer: ResolverTypeWrapper<Customer>;
  Gender: Gender;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  ScheduledPayment: ResolverTypeWrapper<ScheduledPayment>;
  Invoice: ResolverTypeWrapper<Invoice>;
  KoOrganization: ResolverTypeWrapper<KoOrganization>;
  CompanySetting: ResolverTypeWrapper<CompanySetting>;
  Setting: ResolverTypeWrapper<Setting>;
  CourseNumberFormat: CourseNumberFormat;
  Currency: Currency;
  GrossNet: GrossNet;
  StateCode: StateCode;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionType: SubscriptionType;
  PaymentTransferType: PaymentTransferType;
  ScheduledPaymentType: ScheduledPaymentType;
  BookingsKo9: ResolverTypeWrapper<BookingsKo9>;
  Course: ResolverTypeWrapper<Course>;
  BookingRestriction: ResolverTypeWrapper<BookingRestriction>;
  ExpireAction: ExpireAction;
  CoursePrice: ResolverTypeWrapper<CoursePrice>;
  CourseType: ResolverTypeWrapper<CourseType>;
  CourseCategory: ResolverTypeWrapper<CourseCategory>;
  ParticipationPrerequisites: ResolverTypeWrapper<ParticipationPrerequisites>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  PaymentInterval: PaymentInterval;
  CourseCycle: CourseCycle;
  EnabledCourse: ResolverTypeWrapper<EnabledCourse>;
  CourseVisibilityResult: ResolverTypeWrapper<CourseVisibilityResult>;
  CourseNotVisibleReason: CourseNotVisibleReason;
  CourseVisibilityRestriction: ResolverTypeWrapper<CourseVisibilityRestriction>;
  InstallmentRate: ResolverTypeWrapper<InstallmentRate>;
  InstallmentType: InstallmentType;
  Instructor: ResolverTypeWrapper<Instructor>;
  Location: ResolverTypeWrapper<Location>;
  GeneralLocationContact: ResolverTypeWrapper<GeneralLocationContact>;
  CourseLesson: ResolverTypeWrapper<CourseLesson>;
  CourseLessonBooking: ResolverTypeWrapper<CourseLessonBooking>;
  Venue: ResolverTypeWrapper<Venue>;
  LocationContact: ResolverTypeWrapper<LocationContact>;
  SalutationType: SalutationType;
  MonthlySchedule: MonthlySchedule;
  PaymentType: PaymentType;
  BookedBy: BookedBy;
  EmailNotification: ResolverTypeWrapper<EmailNotification>;
  MessageStatus: ResolverTypeWrapper<MessageStatus>;
  MessageStatusData: ResolverTypeWrapper<MessageStatusData>;
  MailjetStatus: MailjetStatus;
  MessageHistory: ResolverTypeWrapper<MessageHistory>;
  MessageHistoryData: ResolverTypeWrapper<MessageHistoryData>;
  ReversalInvoice: ResolverTypeWrapper<ReversalInvoice>;
  ArrayDataOptions: ArrayDataOptions;
  FilterOption: FilterOption;
  filterCompareOperation: FilterCompareOperation;
  filterOperation: FilterOperation;
  SortOrder: SortOrder;
  AttendeeWithPagination: ResolverTypeWrapper<AttendeeWithPagination>;
  UserType: ResolverTypeWrapper<UserType>;
  BookerWithPagination: ResolverTypeWrapper<BookerWithPagination>;
  BookingWithPagination: ResolverTypeWrapper<BookingWithPagination>;
  AttendeesAgeAtCourseStart: ResolverTypeWrapper<AttendeesAgeAtCourseStart>;
  AgeError: AgeError;
  TemplateType: TemplateType;
  SmsBalanceResponse: ResolverTypeWrapper<SmsBalanceResponse>;
  LocationCity: ResolverTypeWrapper<LocationCity>;
  CollitionCheckInput: CollitionCheckInput;
  CourseLessonWithIndexOfCourseLessonInCourse: ResolverTypeWrapper<CourseLessonWithIndexOfCourseLessonInCourse>;
  CourseLessonWithPagination: ResolverTypeWrapper<CourseLessonWithPagination>;
  FilterExpressionInput: FilterExpressionInput;
  FilterInput: FilterInput;
  Operation: Operation;
  Operator: Operator;
  DayFilter: DayFilter;
  CourseWithPagination: ResolverTypeWrapper<CourseWithPagination>;
  CourseListFilters: CourseListFilters;
  MonthFilter: MonthFilter;
  RestrictiveCourses: RestrictiveCourses;
  Holiday: ResolverTypeWrapper<Holiday>;
  HolidayType: HolidayType;
  EmailNotificationWithPagination: ResolverTypeWrapper<EmailNotificationWithPagination>;
  ExportedSepaXml: ResolverTypeWrapper<ExportedSepaXml>;
  ExportedSepaXmlWithPagination: ResolverTypeWrapper<ExportedSepaXmlWithPagination>;
  FaUser: ResolverTypeWrapper<FaUser>;
  UserRegistration: ResolverTypeWrapper<UserRegistration>;
  Sms77AnalyticGroupByDate: ResolverTypeWrapper<Sms77AnalyticGroupByDate>;
  UserSetting: ResolverTypeWrapper<UserSetting>;
  Language: Language;
  PaginationInput: PaginationInput;
  BookerIdMissmatchWithPagination: ResolverTypeWrapper<BookerIdMissmatchWithPagination>;
  BookerIdMissmatch: ResolverTypeWrapper<BookerIdMissmatch>;
  TablesEnum: TablesEnum;
  ContentOfBucket: ResolverTypeWrapper<ContentOfBucket>;
  ContentOfBucketResponse: ResolverTypeWrapper<ContentOfBucketResponse>;
  Content: ResolverTypeWrapper<Content>;
  MjmlTemplate: ResolverTypeWrapper<MjmlTemplate>;
  NewInstallmentPlanInput: NewInstallmentPlanInput;
  EditTemplateInput: EditTemplateInput;
  MailjetSenderResponse: ResolverTypeWrapper<MailjetSenderResponse>;
  MailjetSender: ResolverTypeWrapper<MailjetSender>;
  MailjetSenderQueryParams: MailjetSenderQueryParams;
  ScheduledPaymentPreviewInput: ScheduledPaymentPreviewInput;
  PageStructure: ResolverTypeWrapper<PageStructure>;
  Page: ResolverTypeWrapper<Page>;
  PageContent: ResolverTypeWrapper<PageContent>;
  MailjetEmailQueryParams: MailjetEmailQueryParams;
  MailjetMessageResponse: ResolverTypeWrapper<MailjetMessageResponse>;
  MailjetMessage: ResolverTypeWrapper<MailjetMessage>;
  JournalParams: JournalParams;
  JournalType: JournalType;
  Sms77JournalOutbound: ResolverTypeWrapper<Sms77JournalOutbound>;
  Sms77PricingResponse: ResolverTypeWrapper<Sms77PricingResponse>;
  Sms77CountryPricing: ResolverTypeWrapper<Sms77CountryPricing>;
  CountryNetwork: ResolverTypeWrapper<CountryNetwork>;
  MailDataType: MailDataType;
  SmsReceiver: ResolverTypeWrapper<SmsReceiver>;
  GetReceiversForCourseInput: GetReceiversForCourseInput;
  UserDetailsType: ResolverTypeWrapper<UserDetailsType>;
  InstructorDetailsView: ResolverTypeWrapper<InstructorDetailsView>;
  LoginAttempt: ResolverTypeWrapper<LoginAttempt>;
  LoginStatus: LoginStatus;
  HolidayWithDays: ResolverTypeWrapper<HolidayWithDays>;
  InstructorsWithPagination: ResolverTypeWrapper<InstructorsWithPagination>;
  InvoiceWithPagination: ResolverTypeWrapper<InvoiceWithPagination>;
  LocalState: ResolverTypeWrapper<LocalState>;
  BookAttendees: ResolverTypeWrapper<BookAttendees>;
  SelectedAttendee: ResolverTypeWrapper<SelectedAttendee>;
  CreateCourse: ResolverTypeWrapper<CreateCourse>;
  LocationsGroupedByCity: ResolverTypeWrapper<LocationsGroupedByCity>;
  LocationGroupedByCityLocation: ResolverTypeWrapper<LocationGroupedByCityLocation>;
  LocationGroupedByCityVenue: ResolverTypeWrapper<LocationGroupedByCityVenue>;
  LocationsWithPagination: ResolverTypeWrapper<LocationsWithPagination>;
  ScheduledPaymentsWithPagination: ResolverTypeWrapper<ScheduledPaymentsWithPagination>;
  Payment: ResolverTypeWrapper<Payment>;
  SwissQRFile: ResolverTypeWrapper<SwissQrFile>;
  SwissQRFileRecord: ResolverTypeWrapper<SwissQrFileRecord>;
  ReversalInvoiceWithPagination: ResolverTypeWrapper<ReversalInvoiceWithPagination>;
  ScheduledPaymentSummary: ResolverTypeWrapper<ScheduledPaymentSummary>;
  ScheduledPaymentSummaryPart: ResolverTypeWrapper<ScheduledPaymentSummaryPart>;
  Staff: ResolverTypeWrapper<Staff>;
  SettingEndUserApp: ResolverTypeWrapper<SettingEndUserApp>;
  Template: ResolverTypeWrapper<Template>;
  TemplatesWithPagination: ResolverTypeWrapper<TemplatesWithPagination>;
  KoOrganizationWithPagination: ResolverTypeWrapper<KoOrganizationWithPagination>;
  Mutation: ResolverTypeWrapper<{}>;
  NewTemplateInput: NewTemplateInput;
  AddNamedScheduledPaymentInput: AddNamedScheduledPaymentInput;
  NewPageInput: NewPageInput;
  SepaDebitIntput: SepaDebitIntput;
  Sms77FormatApiResponse: ResolverTypeWrapper<Sms77FormatApiResponse>;
  UserInput: UserInput;
  ThirdPartyUserInput: ThirdPartyUserInput;
  BookerInput: BookerInput;
  BookerAttendeeInput: BookerAttendeeInput;
  BookerAttendeeBookingInput: BookerAttendeeBookingInput;
  BaseInput: BaseInput;
  NewBookingsKo9Input: NewBookingsKo9Input;
  BookerPaymentMethodInput: BookerPaymentMethodInput;
  NewAttendeeBadgeInput: NewAttendeeBadgeInput;
  NewAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput;
  NewBadgeInput: NewBadgeInput;
  NewBookingInput: NewBookingInput;
  UpdateScheduledPaymentByBookingInput: UpdateScheduledPaymentByBookingInput;
  NewBookingRestrictionInput: NewBookingRestrictionInput;
  NewCourseInput: NewCourseInput;
  NewCoursePriceInput: NewCoursePriceInput;
  DiscountUnit: DiscountUnit;
  CreateInstallmentRateByCourseCreateInput: CreateInstallmentRateByCourseCreateInput;
  CourseInstructorIdInput: CourseInstructorIdInput;
  NewCourseLessonInput: NewCourseLessonInput;
  NewCourseCategoryInput: NewCourseCategoryInput;
  NewCourseLessonBookingInput: NewCourseLessonBookingInput;
  NewCourseTypeInput: NewCourseTypeInput;
  UpdateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput;
  OptionalInputFieldsInput: OptionalInputFieldsInput;
  CreateDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput;
  CamtScheduledPaymentInput: CamtScheduledPaymentInput;
  NewInstallmentRateInput: NewInstallmentRateInput;
  InvoiceInput: InvoiceInput;
  NewLocationInput: NewLocationInput;
  GeneralLocationContactInput: GeneralLocationContactInput;
  LocationContactIdInput: LocationContactIdInput;
  NewVenueInput: NewVenueInput;
  RentType: RentType;
  NewLocationContactInput: NewLocationContactInput;
  CreateMailjetSenderInput: CreateMailjetSenderInput;
  EmailTypeEnum: EmailTypeEnum;
  CreateMailjetSenderOutput: ResolverTypeWrapper<CreateMailjetSenderOutput>;
  KoOrganizationInputType: KoOrganizationInputType;
  NewParticipationPrerequisitesInput: NewParticipationPrerequisitesInput;
  CreateSingleSepaXmlInput: CreateSingleSepaXmlInput;
  CreateSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
  SettingEndUserAppInput: SettingEndUserAppInput;
  ColorsInput: ColorsInput;
  CourseDetailsInput: CourseDetailsInput;
  CourseTableInput: CourseTableInput;
  FiltersInput: FiltersInput;
  TextEntriesInput: TextEntriesInput;
  ForgotPasswordWithStateInput: ForgotPasswordWithStateInput;
  ResetPasswordStateType: ResetPasswordStateType;
  ScheduledPaymentSimpleInput: ScheduledPaymentSimpleInput;
  ScheduledPaymentPreview: ResolverTypeWrapper<ScheduledPaymentPreview>;
  ScheduledPaymentSimple: ResolverTypeWrapper<ScheduledPaymentSimple>;
  ImportBookersInput: ImportBookersInput;
  ImportBookerInput: ImportBookerInput;
  LoggingInput: LoggingInput;
  LogLevelType: LogLevelType;
  ModifyScheduledPaymentInput: ModifyScheduledPaymentInput;
  PasswordlessLoginInputType: PasswordlessLoginInputType;
  EndUserPasswordlessLoginInputType: EndUserPasswordlessLoginInputType;
  BookingDeleteAction: BookingDeleteAction;
  BookingDeleteActionType: BookingDeleteActionType;
  ResetScheduledPaymentsOfCourseResult: ResolverTypeWrapper<ResetScheduledPaymentsOfCourseResult>;
  ResetScheduledPaymentsOfBookingResult: ResolverTypeWrapper<ResetScheduledPaymentsOfBookingResult>;
  Rate: ResolverTypeWrapper<Rate>;
  RateDetail: ResolverTypeWrapper<RateDetail>;
  SaveAdminInput: SaveAdminInput;
  Admin: ResolverTypeWrapper<Admin>;
  SaveInstructorInput: SaveInstructorInput;
  AssignedLocationIdInput: AssignedLocationIdInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  BlankMailInputTypes: BlankMailInputTypes;
  BlankMailReceiversInput: BlankMailReceiversInput;
  BlankMailTypes: BlankMailTypes;
  SendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput;
  Attachment: Attachment;
  SendTemplateInput: SendTemplateInput;
  EmailData: EmailData;
  MailjetEmailTemplates: MailjetEmailTemplates;
  SendParamsMessageInput: SendParamsMessageInput;
  SendParamsRecipient: SendParamsRecipient;
  InlinedAttachment: InlinedAttachment;
  MailjetVariables: MailjetVariables;
  KeyValue: KeyValue;
  SendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput;
  SendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput;
  SendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput;
  SendMailToSelectionOfStaffInput: SendMailToSelectionOfStaffInput;
  SendInvoicesToSelectionOfBookingInput: SendInvoicesToSelectionOfBookingInput;
  SendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput;
  SendSmsInput: SendSmsInput;
  Sms77JsonResponse: ResolverTypeWrapper<Sms77JsonResponse>;
  Sms77Message: ResolverTypeWrapper<Sms77Message>;
  ThirdPartyLoginInput: ThirdPartyLoginInput;
  TransferMultipleBookingsInput: TransferMultipleBookingsInput;
  TransferBookingInput: TransferBookingInput;
  BookingType: BookingType;
  UpdateAttendanceListSettingsInput: UpdateAttendanceListSettingsInput;
  AttendanceListFontSize: AttendanceListFontSize;
  EditAttendeeBadgeInput: EditAttendeeBadgeInput;
  UpdateBadgeInput: UpdateBadgeInput;
  UpdateBookerAccountEndUserAppInput: UpdateBookerAccountEndUserAppInput;
  EditBookingInput: EditBookingInput;
  UpdateBookingRestrictionInput: UpdateBookingRestrictionInput;
  CompanySettingInput: CompanySettingInput;
  LogoInput: LogoInput;
  EditCourseInput: EditCourseInput;
  EditCoursePriceInput: EditCoursePriceInput;
  UpdateInstallmentRateInput: UpdateInstallmentRateInput;
  EditCourseLessonInput: EditCourseLessonInput;
  UpdateCourseLessonBookingInput: UpdateCourseLessonBookingInput;
  HolidayInput: HolidayInput;
  EditInvoiceInput: EditInvoiceInput;
  EditLocationInput: EditLocationInput;
  EditVenueInput: EditVenueInput;
  EditLocationContactInput: EditLocationContactInput;
  EditScheduledPaymentInput: EditScheduledPaymentInput;
  UpdateBookerIdMissmatchesInput: UpdateBookerIdMissmatchesInput;
  EditPageInput: EditPageInput;
  UpdatePaymentMethodInput: UpdatePaymentMethodInput;
  UpdateSettingEndUserAppInput: UpdateSettingEndUserAppInput;
  SettingInput: SettingInput;
  VatAppearance: VatAppearance;
  SubscriptionFeaturesInput: SubscriptionFeaturesInput;
  UpdateTableColumnSettingsInput: UpdateTableColumnSettingsInput;
  UploadMetadataInput: UploadMetadataInput;
  UserSettingsInputTypes: UserSettingsInputTypes;
  AddPaymentMethodInput: AddPaymentMethodInput;
  AnalyticsParams: AnalyticsParams;
  Base: ResolverTypeWrapper<Base>;
  BaseLookupInput: BaseLookupInput;
  LookupType: LookupType;
  BasicEmailVariables: BasicEmailVariables;
  BookerCustomerInput: BookerCustomerInput;
  BookerCustomerUpdateInput: BookerCustomerUpdateInput;
  BookingHistory: ResolverTypeWrapper<BookingHistory>;
  BookingHistoryAction: BookingHistoryAction;
  BookingOptions: BookingOptions;
  CamtGeneratorInput: CamtGeneratorInput;
  CamtGeneratorTransactionInput: CamtGeneratorTransactionInput;
  CourseIdInput: CourseIdInput;
  CourseLessonBookingAttendanceList: ResolverTypeWrapper<CourseLessonBookingAttendanceList>;
  CourseLessonIdInput: CourseLessonIdInput;
  CoursePriceIdInput: CoursePriceIdInput;
  CourseWithPaginationWithEnabledCourses: ResolverTypeWrapper<CourseWithPaginationWithEnabledCourses>;
  CreateMultipleSepaXmlInput: CreateMultipleSepaXmlInput;
  CreatePrefixedCourseNumberInput: CreatePrefixedCourseNumberInput;
  DetailsCheckboxInput: DetailsCheckboxInput;
  ForgotPasswordInput: ForgotPasswordInput;
  ForgotPasswordWithApiKeyInput: ForgotPasswordWithApiKeyInput;
  MailReplacementData: MailReplacementData;
  MailSentStatus: MailSentStatus;
  NewNotificationInput: NewNotificationInput;
  PostInputTo: PostInputTo;
  NewSepaXmlInput: NewSepaXmlInput;
  SepaXmlPositionInput: SepaXmlPositionInput;
  SepaXmlPaymentInput: SepaXmlPaymentInput;
  PageContentInput: PageContentInput;
  PaymentInput: PaymentInput;
  PricingParams: PricingParams;
  SecureIdentity: ResolverTypeWrapper<SecureIdentity>;
  Sms77AnalyticBase: ResolverTypeWrapper<Sms77AnalyticBase>;
  Sms77JournalBase: ResolverTypeWrapper<Sms77JournalBase>;
  StaffLogin: ResolverTypeWrapper<StaffLogin>;
  TableColumnsInput: TableColumnsInput;
  UploadFileInput: UploadFileInput;
  WithEnabledCourse: ResolverTypeWrapper<WithEnabledCourse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  String: Scalars['String'];
  Booking: Booking;
  Boolean: Scalars['Boolean'];
  Attendee: Attendee;
  AttendeeBadge: AttendeeBadge;
  ID: Scalars['ID'];
  Badge: Badge;
  DateTime: Scalars['DateTime'];
  Float: Scalars['Float'];
  Booker: Booker;
  Customer: Customer;
  PaymentMethod: PaymentMethod;
  ScheduledPayment: ScheduledPayment;
  Invoice: Invoice;
  KoOrganization: KoOrganization;
  CompanySetting: CompanySetting;
  Setting: Setting;
  Subscription: {};
  BookingsKo9: BookingsKo9;
  Course: Course;
  BookingRestriction: BookingRestriction;
  CoursePrice: CoursePrice;
  CourseType: CourseType;
  CourseCategory: CourseCategory;
  ParticipationPrerequisites: ParticipationPrerequisites;
  JSONObject: Scalars['JSONObject'];
  EnabledCourse: EnabledCourse;
  CourseVisibilityResult: CourseVisibilityResult;
  CourseVisibilityRestriction: CourseVisibilityRestriction;
  InstallmentRate: InstallmentRate;
  Instructor: Instructor;
  Location: Location;
  GeneralLocationContact: GeneralLocationContact;
  CourseLesson: CourseLesson;
  CourseLessonBooking: CourseLessonBooking;
  Venue: Venue;
  LocationContact: LocationContact;
  EmailNotification: EmailNotification;
  MessageStatus: MessageStatus;
  MessageStatusData: MessageStatusData;
  MessageHistory: MessageHistory;
  MessageHistoryData: MessageHistoryData;
  ReversalInvoice: ReversalInvoice;
  ArrayDataOptions: ArrayDataOptions;
  FilterOption: FilterOption;
  AttendeeWithPagination: AttendeeWithPagination;
  UserType: UserType;
  BookerWithPagination: BookerWithPagination;
  BookingWithPagination: BookingWithPagination;
  AttendeesAgeAtCourseStart: AttendeesAgeAtCourseStart;
  SmsBalanceResponse: SmsBalanceResponse;
  LocationCity: LocationCity;
  CollitionCheckInput: CollitionCheckInput;
  CourseLessonWithIndexOfCourseLessonInCourse: CourseLessonWithIndexOfCourseLessonInCourse;
  CourseLessonWithPagination: CourseLessonWithPagination;
  FilterExpressionInput: FilterExpressionInput;
  FilterInput: FilterInput;
  CourseWithPagination: CourseWithPagination;
  CourseListFilters: CourseListFilters;
  Holiday: Holiday;
  EmailNotificationWithPagination: EmailNotificationWithPagination;
  ExportedSepaXml: ExportedSepaXml;
  ExportedSepaXmlWithPagination: ExportedSepaXmlWithPagination;
  FaUser: FaUser;
  UserRegistration: UserRegistration;
  Sms77AnalyticGroupByDate: Sms77AnalyticGroupByDate;
  UserSetting: UserSetting;
  PaginationInput: PaginationInput;
  BookerIdMissmatchWithPagination: BookerIdMissmatchWithPagination;
  BookerIdMissmatch: BookerIdMissmatch;
  ContentOfBucket: ContentOfBucket;
  ContentOfBucketResponse: ContentOfBucketResponse;
  Content: Content;
  MjmlTemplate: MjmlTemplate;
  NewInstallmentPlanInput: NewInstallmentPlanInput;
  EditTemplateInput: EditTemplateInput;
  MailjetSenderResponse: MailjetSenderResponse;
  MailjetSender: MailjetSender;
  MailjetSenderQueryParams: MailjetSenderQueryParams;
  ScheduledPaymentPreviewInput: ScheduledPaymentPreviewInput;
  PageStructure: PageStructure;
  Page: Page;
  PageContent: PageContent;
  MailjetEmailQueryParams: MailjetEmailQueryParams;
  MailjetMessageResponse: MailjetMessageResponse;
  MailjetMessage: MailjetMessage;
  JournalParams: JournalParams;
  Sms77JournalOutbound: Sms77JournalOutbound;
  Sms77PricingResponse: Sms77PricingResponse;
  Sms77CountryPricing: Sms77CountryPricing;
  CountryNetwork: CountryNetwork;
  SmsReceiver: SmsReceiver;
  GetReceiversForCourseInput: GetReceiversForCourseInput;
  UserDetailsType: UserDetailsType;
  InstructorDetailsView: InstructorDetailsView;
  LoginAttempt: LoginAttempt;
  HolidayWithDays: HolidayWithDays;
  InstructorsWithPagination: InstructorsWithPagination;
  InvoiceWithPagination: InvoiceWithPagination;
  LocalState: LocalState;
  BookAttendees: BookAttendees;
  SelectedAttendee: SelectedAttendee;
  CreateCourse: CreateCourse;
  LocationsGroupedByCity: LocationsGroupedByCity;
  LocationGroupedByCityLocation: LocationGroupedByCityLocation;
  LocationGroupedByCityVenue: LocationGroupedByCityVenue;
  LocationsWithPagination: LocationsWithPagination;
  ScheduledPaymentsWithPagination: ScheduledPaymentsWithPagination;
  Payment: Payment;
  SwissQRFile: SwissQrFile;
  SwissQRFileRecord: SwissQrFileRecord;
  ReversalInvoiceWithPagination: ReversalInvoiceWithPagination;
  ScheduledPaymentSummary: ScheduledPaymentSummary;
  ScheduledPaymentSummaryPart: ScheduledPaymentSummaryPart;
  Staff: Staff;
  SettingEndUserApp: SettingEndUserApp;
  Template: Template;
  TemplatesWithPagination: TemplatesWithPagination;
  KoOrganizationWithPagination: KoOrganizationWithPagination;
  Mutation: {};
  NewTemplateInput: NewTemplateInput;
  AddNamedScheduledPaymentInput: AddNamedScheduledPaymentInput;
  NewPageInput: NewPageInput;
  SepaDebitIntput: SepaDebitIntput;
  Sms77FormatApiResponse: Sms77FormatApiResponse;
  UserInput: UserInput;
  ThirdPartyUserInput: ThirdPartyUserInput;
  BookerInput: BookerInput;
  BookerAttendeeInput: BookerAttendeeInput;
  BookerAttendeeBookingInput: BookerAttendeeBookingInput;
  BaseInput: BaseInput;
  NewBookingsKo9Input: NewBookingsKo9Input;
  BookerPaymentMethodInput: BookerPaymentMethodInput;
  NewAttendeeBadgeInput: NewAttendeeBadgeInput;
  NewAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput;
  NewBadgeInput: NewBadgeInput;
  NewBookingInput: NewBookingInput;
  UpdateScheduledPaymentByBookingInput: UpdateScheduledPaymentByBookingInput;
  NewBookingRestrictionInput: NewBookingRestrictionInput;
  NewCourseInput: NewCourseInput;
  NewCoursePriceInput: NewCoursePriceInput;
  CreateInstallmentRateByCourseCreateInput: CreateInstallmentRateByCourseCreateInput;
  CourseInstructorIdInput: CourseInstructorIdInput;
  NewCourseLessonInput: NewCourseLessonInput;
  NewCourseCategoryInput: NewCourseCategoryInput;
  NewCourseLessonBookingInput: NewCourseLessonBookingInput;
  NewCourseTypeInput: NewCourseTypeInput;
  UpdateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput;
  OptionalInputFieldsInput: OptionalInputFieldsInput;
  CreateDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput;
  CamtScheduledPaymentInput: CamtScheduledPaymentInput;
  NewInstallmentRateInput: NewInstallmentRateInput;
  InvoiceInput: InvoiceInput;
  NewLocationInput: NewLocationInput;
  GeneralLocationContactInput: GeneralLocationContactInput;
  LocationContactIdInput: LocationContactIdInput;
  NewVenueInput: NewVenueInput;
  NewLocationContactInput: NewLocationContactInput;
  CreateMailjetSenderInput: CreateMailjetSenderInput;
  CreateMailjetSenderOutput: CreateMailjetSenderOutput;
  KoOrganizationInputType: KoOrganizationInputType;
  NewParticipationPrerequisitesInput: NewParticipationPrerequisitesInput;
  CreateSingleSepaXmlInput: CreateSingleSepaXmlInput;
  CreateSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
  SettingEndUserAppInput: SettingEndUserAppInput;
  ColorsInput: ColorsInput;
  CourseDetailsInput: CourseDetailsInput;
  CourseTableInput: CourseTableInput;
  FiltersInput: FiltersInput;
  TextEntriesInput: TextEntriesInput;
  ForgotPasswordWithStateInput: ForgotPasswordWithStateInput;
  ResetPasswordStateType: ResetPasswordStateType;
  ScheduledPaymentSimpleInput: ScheduledPaymentSimpleInput;
  ScheduledPaymentPreview: ScheduledPaymentPreview;
  ScheduledPaymentSimple: ScheduledPaymentSimple;
  ImportBookersInput: ImportBookersInput;
  ImportBookerInput: ImportBookerInput;
  LoggingInput: LoggingInput;
  ModifyScheduledPaymentInput: ModifyScheduledPaymentInput;
  PasswordlessLoginInputType: PasswordlessLoginInputType;
  EndUserPasswordlessLoginInputType: EndUserPasswordlessLoginInputType;
  BookingDeleteAction: BookingDeleteAction;
  ResetScheduledPaymentsOfCourseResult: ResetScheduledPaymentsOfCourseResult;
  ResetScheduledPaymentsOfBookingResult: ResetScheduledPaymentsOfBookingResult;
  Rate: Rate;
  RateDetail: RateDetail;
  SaveAdminInput: SaveAdminInput;
  Admin: Admin;
  SaveInstructorInput: SaveInstructorInput;
  AssignedLocationIdInput: AssignedLocationIdInput;
  Upload: Scalars['Upload'];
  BlankMailInputTypes: BlankMailInputTypes;
  BlankMailReceiversInput: BlankMailReceiversInput;
  SendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput;
  Attachment: Attachment;
  SendTemplateInput: SendTemplateInput;
  EmailData: EmailData;
  SendParamsMessageInput: SendParamsMessageInput;
  SendParamsRecipient: SendParamsRecipient;
  InlinedAttachment: InlinedAttachment;
  MailjetVariables: MailjetVariables;
  KeyValue: KeyValue;
  SendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput;
  SendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput;
  SendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput;
  SendMailToSelectionOfStaffInput: SendMailToSelectionOfStaffInput;
  SendInvoicesToSelectionOfBookingInput: SendInvoicesToSelectionOfBookingInput;
  SendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput;
  SendSmsInput: SendSmsInput;
  Sms77JsonResponse: Sms77JsonResponse;
  Sms77Message: Sms77Message;
  ThirdPartyLoginInput: ThirdPartyLoginInput;
  TransferMultipleBookingsInput: TransferMultipleBookingsInput;
  TransferBookingInput: TransferBookingInput;
  UpdateAttendanceListSettingsInput: UpdateAttendanceListSettingsInput;
  EditAttendeeBadgeInput: EditAttendeeBadgeInput;
  UpdateBadgeInput: UpdateBadgeInput;
  UpdateBookerAccountEndUserAppInput: UpdateBookerAccountEndUserAppInput;
  EditBookingInput: EditBookingInput;
  UpdateBookingRestrictionInput: UpdateBookingRestrictionInput;
  CompanySettingInput: CompanySettingInput;
  LogoInput: LogoInput;
  EditCourseInput: EditCourseInput;
  EditCoursePriceInput: EditCoursePriceInput;
  UpdateInstallmentRateInput: UpdateInstallmentRateInput;
  EditCourseLessonInput: EditCourseLessonInput;
  UpdateCourseLessonBookingInput: UpdateCourseLessonBookingInput;
  HolidayInput: HolidayInput;
  EditInvoiceInput: EditInvoiceInput;
  EditLocationInput: EditLocationInput;
  EditVenueInput: EditVenueInput;
  EditLocationContactInput: EditLocationContactInput;
  EditScheduledPaymentInput: EditScheduledPaymentInput;
  UpdateBookerIdMissmatchesInput: UpdateBookerIdMissmatchesInput;
  EditPageInput: EditPageInput;
  UpdatePaymentMethodInput: UpdatePaymentMethodInput;
  UpdateSettingEndUserAppInput: UpdateSettingEndUserAppInput;
  SettingInput: SettingInput;
  SubscriptionFeaturesInput: SubscriptionFeaturesInput;
  UpdateTableColumnSettingsInput: UpdateTableColumnSettingsInput;
  UploadMetadataInput: UploadMetadataInput;
  UserSettingsInputTypes: UserSettingsInputTypes;
  AddPaymentMethodInput: AddPaymentMethodInput;
  AnalyticsParams: AnalyticsParams;
  Base: Base;
  BaseLookupInput: BaseLookupInput;
  BasicEmailVariables: BasicEmailVariables;
  BookerCustomerInput: BookerCustomerInput;
  BookerCustomerUpdateInput: BookerCustomerUpdateInput;
  BookingHistory: BookingHistory;
  BookingOptions: BookingOptions;
  CamtGeneratorInput: CamtGeneratorInput;
  CamtGeneratorTransactionInput: CamtGeneratorTransactionInput;
  CourseIdInput: CourseIdInput;
  CourseLessonBookingAttendanceList: CourseLessonBookingAttendanceList;
  CourseLessonIdInput: CourseLessonIdInput;
  CoursePriceIdInput: CoursePriceIdInput;
  CourseWithPaginationWithEnabledCourses: CourseWithPaginationWithEnabledCourses;
  CreateMultipleSepaXmlInput: CreateMultipleSepaXmlInput;
  CreatePrefixedCourseNumberInput: CreatePrefixedCourseNumberInput;
  DetailsCheckboxInput: DetailsCheckboxInput;
  ForgotPasswordInput: ForgotPasswordInput;
  ForgotPasswordWithApiKeyInput: ForgotPasswordWithApiKeyInput;
  MailReplacementData: MailReplacementData;
  NewNotificationInput: NewNotificationInput;
  PostInputTo: PostInputTo;
  NewSepaXmlInput: NewSepaXmlInput;
  SepaXmlPositionInput: SepaXmlPositionInput;
  SepaXmlPaymentInput: SepaXmlPaymentInput;
  PageContentInput: PageContentInput;
  PaymentInput: PaymentInput;
  PricingParams: PricingParams;
  SecureIdentity: SecureIdentity;
  Sms77AnalyticBase: Sms77AnalyticBase;
  Sms77JournalBase: Sms77JournalBase;
  StaffLogin: StaffLogin;
  TableColumnsInput: TableColumnsInput;
  UploadFileInput: UploadFileInput;
  WithEnabledCourse: WithEnabledCourse;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  attendanceListByCourse?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryAttendanceListByCourseArgs, 'courseId'>>;
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<QueryAttendeeArgs, 'id'>>;
  attendeeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, Partial<QueryAttendeeCountArgs>>;
  attendees?: Resolver<ResolversTypes['AttendeeWithPagination'], ParentType, ContextType, Partial<QueryAttendeesArgs>>;
  attendeesByBookerFirstLast?: Resolver<ResolversTypes['AttendeeWithPagination'], ParentType, ContextType, RequireFields<QueryAttendeesByBookerFirstLastArgs, 'searchKey'>>;
  attendeesByIds?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType, RequireFields<QueryAttendeesByIdsArgs, 'attendeeIds'>>;
  authenticate?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  badge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<QueryBadgeArgs, 'id'>>;
  badges?: Resolver<Array<ResolversTypes['Badge']>, ParentType, ContextType>;
  bookedAttendeesByCourseId?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType, RequireFields<QueryBookedAttendeesByCourseIdArgs, 'courseId'>>;
  bookedSpots?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryBookedSpotsArgs, 'courseId'>>;
  booker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<QueryBookerArgs, 'id'>>;
  bookerByUserId?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<QueryBookerByUserIdArgs, 'id'>>;
  bookerExistsAsAttendeeEndUserApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryBookerExistsAsAttendeeEndUserAppArgs, 'bookerId'>>;
  bookers?: Resolver<ResolversTypes['BookerWithPagination'], ParentType, ContextType, Partial<QueryBookersArgs>>;
  bookersById?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryBookersByIdArgs, 'bookerIds'>>;
  bookersDynamic?: Resolver<ResolversTypes['BookerWithPagination'], ParentType, ContextType, Partial<QueryBookersDynamicArgs>>;
  bookersExport?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, Partial<QueryBookersExportArgs>>;
  booking?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryBookingArgs, 'id'>>;
  bookingById?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<QueryBookingByIdArgs, 'id'>>;
  bookingRestrictions?: Resolver<Array<ResolversTypes['BookingRestriction']>, ParentType, ContextType>;
  bookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryBookingsArgs>>;
  bookingsByUser?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryBookingsByUserArgs, 'userId'>>;
  bookingsDynamic?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryBookingsDynamicArgs>>;
  bookingsInStartedCourses?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryBookingsInStartedCoursesArgs>>;
  bookingsKo9?: Resolver<Array<ResolversTypes['BookingsKo9']>, ParentType, ContextType>;
  bookingsKo9ByAttendeeId?: Resolver<Array<ResolversTypes['BookingsKo9']>, ParentType, ContextType, RequireFields<QueryBookingsKo9ByAttendeeIdArgs, 'attendeeId'>>;
  canBecomeInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCanBecomeInstructorArgs, 'email'>>;
  checkAttendeesAgeAtCourseStart?: Resolver<ResolversTypes['AttendeesAgeAtCourseStart'], ParentType, ContextType, RequireFields<QueryCheckAttendeesAgeAtCourseStartArgs, 'attendeeId' | 'courseId'>>;
  checkEmailUniqueness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckEmailUniquenessArgs, 'email'>>;
  checkIfIsBookedIntoCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckIfIsBookedIntoCourseArgs, 'attendeeId' | 'courseId'>>;
  checkIfTemplateOfTypeExists?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryCheckIfTemplateOfTypeExistsArgs, 'templateType'>>;
  checkSmsApiKey?: Resolver<ResolversTypes['SmsBalanceResponse'], ParentType, ContextType, RequireFields<QueryCheckSmsApiKeyArgs, 'apiKey'>>;
  cities?: Resolver<Array<ResolversTypes['LocationCity']>, ParentType, ContextType>;
  companyCurrencyPublic?: Resolver<ResolversTypes['Setting'], ParentType, ContextType>;
  companyInfo?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  companyPublic?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  companySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  courseById?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<QueryCourseByIdArgs, 'id'>>;
  courseByIdPublic?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<QueryCourseByIdPublicArgs, 'id'>>;
  courseByLocationId?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCourseByLocationIdArgs, 'id'>>;
  courseCategories?: Resolver<Array<ResolversTypes['CourseCategory']>, ParentType, ContextType>;
  courseCategoriesPublic?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courseCategoryById?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<QueryCourseCategoryByIdArgs, 'id'>>;
  courseHasAtLeastOneCourseLessonBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCourseHasAtLeastOneCourseLessonBookingArgs, 'courseId'>>;
  courseIsPrerequisiteCourseFor?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCourseIsPrerequisiteCourseForArgs, 'courseId'>>;
  courseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<QueryCourseLessonBookingArgs, 'id'>>;
  courseLessonBookingByCourseLessonAndBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<QueryCourseLessonBookingByCourseLessonAndBookingArgs, 'bookingId' | 'courseLessonId'>>;
  courseLessonBookingsByCourse?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType, RequireFields<QueryCourseLessonBookingsByCourseArgs, 'courseId'>>;
  courseLessonById?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType, RequireFields<QueryCourseLessonByIdArgs, 'id'>>;
  courseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, Partial<QueryCourseLessonsArgs>>;
  courseLessonsByCalendarWeek?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByCalendarWeekArgs, 'calendarWeek' | 'year'>>;
  courseLessonsByCourseId?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByCourseIdArgs, 'courseId'>>;
  courseLessonsByCourseIdPublic?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByCourseIdPublicArgs, 'courseId'>>;
  courseLessonsByDate?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByDateArgs, 'date'>>;
  courseLessonsForMonthView?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsForMonthViewArgs, 'month' | 'year'>>;
  courseLessonsWithCollitions?: Resolver<Array<ResolversTypes['CourseLessonWithIndexOfCourseLessonInCourse']>, ParentType, ContextType, RequireFields<QueryCourseLessonsWithCollitionsArgs, 'collitionCheckInput'>>;
  courseLessonsWithPagination?: Resolver<ResolversTypes['CourseLessonWithPagination'], ParentType, ContextType, Partial<QueryCourseLessonsWithPaginationArgs>>;
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, Partial<QueryCoursesArgs>>;
  coursesByIds?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCoursesByIdsArgs, 'ids'>>;
  coursesPublic?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, Partial<QueryCoursesPublicArgs>>;
  coursesWithPagination?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, Partial<QueryCoursesWithPaginationArgs>>;
  coursesWithPaginationPublic?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, RequireFields<QueryCoursesWithPaginationPublicArgs, 'filters'>>;
  coursesWithPrerequisiteCourses?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, Partial<QueryCoursesWithPrerequisiteCoursesArgs>>;
  courseTypeById?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<QueryCourseTypeByIdArgs, 'id'>>;
  courseTypes?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courseTypesPublic?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courseTypeStateById?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<QueryCourseTypeStateByIdArgs, 'id'>>;
  currentUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  customerById?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<QueryCustomerByIdArgs, 'id'>>;
  customHolidaysByCompany?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType>;
  emailNotificationById?: Resolver<ResolversTypes['EmailNotification'], ParentType, ContextType, RequireFields<QueryEmailNotificationByIdArgs, 'id'>>;
  emailNotifications?: Resolver<ResolversTypes['EmailNotificationWithPagination'], ParentType, ContextType, Partial<QueryEmailNotificationsArgs>>;
  exportedSepaXml?: Resolver<ResolversTypes['ExportedSepaXml'], ParentType, ContextType, RequireFields<QueryExportedSepaXmlArgs, 'id'>>;
  exportedSepaXmls?: Resolver<ResolversTypes['ExportedSepaXmlWithPagination'], ParentType, ContextType, Partial<QueryExportedSepaXmlsArgs>>;
  faUserByEmail?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<QueryFaUserByEmailArgs, 'email'>>;
  faUserByUserId?: Resolver<Maybe<ResolversTypes['FaUser']>, ParentType, ContextType, RequireFields<QueryFaUserByUserIdArgs, 'userId'>>;
  findByCourseId?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryFindByCourseIdArgs, 'id'>>;
  findDuplicateBookersUnperformant?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, Partial<QueryFindDuplicateBookersUnperformantArgs>>;
  findDuplicateBookersWithoutEmail?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, Partial<QueryFindDuplicateBookersWithoutEmailArgs>>;
  findInstructorById?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryFindInstructorByIdArgs, 'instructorId'>>;
  findInstructorByIds?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType, RequireFields<QueryFindInstructorByIdsArgs, 'instructorIds'>>;
  findInstructorsByLocation?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType, RequireFields<QueryFindInstructorsByLocationArgs, 'locationId'>>;
  getAdminAndInstructorUsers?: Resolver<Array<ResolversTypes['UserType']>, ParentType, ContextType, RequireFields<QueryGetAdminAndInstructorUsersArgs, 'koOrganizationId'>>;
  getAllCoursesWithAllowMonthlyPayment?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  getAnalyticsByDate?: Resolver<Array<ResolversTypes['Sms77AnalyticGroupByDate']>, ParentType, ContextType, Partial<QueryGetAnalyticsByDateArgs>>;
  getAttendanceListSettings?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType>;
  getAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<QueryGetAttendeeBadgeArgs, 'id'>>;
  getAttendeeBadges?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType>;
  getAttendeeBadgesByAttendeeId?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType, RequireFields<QueryGetAttendeeBadgesByAttendeeIdArgs, 'attendeeId'>>;
  getAttendeeBadgesByBadgeId?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType, RequireFields<QueryGetAttendeeBadgesByBadgeIdArgs, 'badgeId'>>;
  getBankData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetBankDataArgs, 'iban'>>;
  getBilledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, Partial<QueryGetBilledBookingsArgs>>;
  getBookerByEmail?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookerByEmailArgs, 'email'>>;
  getBookerIdMissmatchesWithPagination?: Resolver<ResolversTypes['BookerIdMissmatchWithPagination'], ParentType, ContextType, Partial<QueryGetBookerIdMissmatchesWithPaginationArgs>>;
  getBookersByBookingIds?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByBookingIdsArgs, 'bookingIds'>>;
  getBookersByCourseIds?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByCourseIdsArgs, 'courseIds'>>;
  getBookersByCourseWithBookingType?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByCourseWithBookingTypeArgs, 'courseId'>>;
  getBookingRestrictionByCourseId?: Resolver<Maybe<ResolversTypes['BookingRestriction']>, ParentType, ContextType, RequireFields<QueryGetBookingRestrictionByCourseIdArgs, 'courseId'>>;
  getBookingsAsCsv?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetBookingsAsCsvArgs, 'bookingIds' | 'filterFields'>>;
  getBookingsByAttendeeId?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByAttendeeIdArgs, 'id'>>;
  getBookingsByAttendeeIdWithOptions?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByAttendeeIdWithOptionsArgs, 'id'>>;
  getBookingsByCourse?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetBookingsByCourseArgs, 'id'>>;
  getBookingsByCourseIds?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetBookingsByCourseIdsArgs, 'ids'>>;
  getBookingsByIds?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByIdsArgs, 'ids'>>;
  getBookingsByInvoiceIds?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByInvoiceIdsArgs, 'invoiceIds'>>;
  getBookingsByScheduledPaymentIds?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByScheduledPaymentIdsArgs, 'scheduledPaymentIds'>>;
  getCanceledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryGetCanceledBookingsArgs>>;
  getColumnsForTable?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetColumnsForTableArgs, 'table'>>;
  getContentByPathDo?: Resolver<ResolversTypes['ContentOfBucket'], ParentType, ContextType, RequireFields<QueryGetContentByPathDoArgs, 'path'>>;
  getContentOfBucketDo?: Resolver<ResolversTypes['ContentOfBucket'], ParentType, ContextType>;
  getContentOfFolderDo?: Resolver<ResolversTypes['ContentOfBucket'], ParentType, ContextType, Partial<QueryGetContentOfFolderDoArgs>>;
  getCourseMinMaxAge?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetCourseMinMaxAgeArgs, 'courseId'>>;
  getCoursesWithHighDemand?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, Partial<QueryGetCoursesWithHighDemandArgs>>;
  getCourseVisibility?: Resolver<ResolversTypes['CourseVisibilityResult'], ParentType, ContextType, RequireFields<QueryGetCourseVisibilityArgs, 'courseId'>>;
  getDuplicateBookers?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, Partial<QueryGetDuplicateBookersArgs>>;
  getEmailTemplateById?: Resolver<ResolversTypes['MjmlTemplate'], ParentType, ContextType, RequireFields<QueryGetEmailTemplateByIdArgs, 'templateId'>>;
  getFileDo?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<QueryGetFileDoArgs, 'fileName'>>;
  getInfoUserByUserId?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<QueryGetInfoUserByUserIdArgs, 'userId'>>;
  getInstallmentPlanPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetInstallmentPlanPreviewArgs, 'newInstallmentPlanInput'>>;
  getInvoiceDummyPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, Partial<QueryGetInvoiceDummyPreviewArgs>>;
  getInvoicePreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetInvoicePreviewArgs, 'invoiceId'>>;
  getLastCustomer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  getMailjetSenderById?: Resolver<ResolversTypes['MailjetSenderResponse'], ParentType, ContextType, RequireFields<QueryGetMailjetSenderByIdArgs, 'mailjetSenderId'>>;
  getMailjetSenders?: Resolver<ResolversTypes['MailjetSenderResponse'], ParentType, ContextType, Partial<QueryGetMailjetSendersArgs>>;
  getMonthlyPaymentPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetMonthlyPaymentPreviewArgs, 'monthlyPaymentPreviewOptions'>>;
  getOnlineBookingsByTimeRangeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, Partial<QueryGetOnlineBookingsByTimeRangeCountArgs>>;
  getPages?: Resolver<ResolversTypes['PageStructure'], ParentType, ContextType>;
  getPasswordlessLoginSettings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  getPaymentMethodsByBookerId?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryGetPaymentMethodsByBookerIdArgs, 'bookerId'>>;
  getPaymentMethodTypeById?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetPaymentMethodTypeByIdArgs, 'paymentMethodId'>>;
  getReferenceCoursesLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetReferenceCoursesLinkArgs, 'courseId'>>;
  getSentMailjetMessage?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessageArgs, 'messageId'>>;
  getSentMailjetMessageHistory?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessageHistoryArgs, 'messageID'>>;
  getSentMailjetMessagemessageInformation?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessagemessageInformationArgs, 'messageID'>>;
  getSentMailjetMessagesByMessageStatus?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessagesByMessageStatusArgs, 'MessageStatus'>>;
  getSentMailjetMessagesWithParams?: Resolver<ResolversTypes['MailjetMessageResponse'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessagesWithParamsArgs, 'queryParameters'>>;
  getSentSmsMessages?: Resolver<Array<ResolversTypes['Sms77JournalOutbound']>, ParentType, ContextType, RequireFields<QueryGetSentSmsMessagesArgs, 'journalParams'>>;
  getSettledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryGetSettledBookingsArgs>>;
  getSignedUrlDo?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetSignedUrlDoArgs, 'fileName'>>;
  getSmsAccountBalance?: Resolver<ResolversTypes['SmsBalanceResponse'], ParentType, ContextType>;
  getSmsPricing?: Resolver<ResolversTypes['Sms77PricingResponse'], ParentType, ContextType, Partial<QueryGetSmsPricingArgs>>;
  getSmsPricingByCountryCode?: Resolver<ResolversTypes['Sms77PricingResponse'], ParentType, ContextType, RequireFields<QueryGetSmsPricingByCountryCodeArgs, 'countryCode'>>;
  getSmsReceivers?: Resolver<Array<ResolversTypes['SmsReceiver']>, ParentType, ContextType, RequireFields<QueryGetSmsReceiversArgs, 'mailDataType' | 'selectedIds'>>;
  getSmsReceiversCourse?: Resolver<Array<ResolversTypes['SmsReceiver']>, ParentType, ContextType, RequireFields<QueryGetSmsReceiversCourseArgs, 'getReceiversForCourseInput'>>;
  getTenantUsers?: Resolver<Array<ResolversTypes['UserType']>, ParentType, ContextType, RequireFields<QueryGetTenantUsersArgs, 'tenantId'>>;
  getUnBilledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, Partial<QueryGetUnBilledBookingsArgs>>;
  getUnSettledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, Partial<QueryGetUnSettledBookingsArgs>>;
  getUpcomingCoursesByTimeRange?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, RequireFields<QueryGetUpcomingCoursesByTimeRangeArgs, 'endDateTime' | 'startDateTime'>>;
  getUserDetails?: Resolver<ResolversTypes['UserDetailsType'], ParentType, ContextType, RequireFields<QueryGetUserDetailsArgs, 'userId'>>;
  getUserRoles?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  getWaitlistByCourse?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetWaitlistByCourseArgs, 'id'>>;
  getWaitlistCountOfCourse?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryGetWaitlistCountOfCourseArgs, 'id'>>;
  holidays?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType>;
  holidaysByCompany?: Resolver<Array<ResolversTypes['HolidayWithDays']>, ParentType, ContextType>;
  holidaysByCompanyAndDateRange?: Resolver<Array<ResolversTypes['HolidayWithDays']>, ParentType, ContextType, RequireFields<QueryHolidaysByCompanyAndDateRangeArgs, 'end' | 'start'>>;
  holidaysByStateCode?: Resolver<Array<ResolversTypes['HolidayWithDays']>, ParentType, ContextType, RequireFields<QueryHolidaysByStateCodeArgs, 'stateCode'>>;
  installmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<QueryInstallmentRateArgs, 'id'>>;
  installmentRates?: Resolver<Array<ResolversTypes['InstallmentRate']>, ParentType, ContextType>;
  installmentRatesByCourseId?: Resolver<Array<ResolversTypes['InstallmentRate']>, ParentType, ContextType, RequireFields<QueryInstallmentRatesByCourseIdArgs, 'courseId'>>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryInstructorArgs, 'id'>>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  instructorsByStaffIds?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType, RequireFields<QueryInstructorsByStaffIdsArgs, 'staffIds'>>;
  instructorsPublic?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  instructorsWithPagination?: Resolver<ResolversTypes['InstructorsWithPagination'], ParentType, ContextType, Partial<QueryInstructorsWithPaginationArgs>>;
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id'>>;
  invoiceBodyByBookingId?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceBodyByBookingIdArgs, 'bookingId'>>;
  invoiceBodyById?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceBodyByIdArgs, 'id'>>;
  invoices?: Resolver<ResolversTypes['InvoiceWithPagination'], ParentType, ContextType, Partial<QueryInvoicesArgs>>;
  invoicesWithRelations?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType>;
  isBadgeNameUnique?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsBadgeNameUniqueArgs, 'name'>>;
  isCourseNameUnique?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsCourseNameUniqueArgs, 'courseId' | 'customCourseNumberPrefix'>>;
  isInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUniqueEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsUniqueEmailArgs, 'email'>>;
  isUserEmailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsUserEmailVerifiedArgs, 'userId'>>;
  koOrganizations?: Resolver<Array<ResolversTypes['KoOrganization']>, ParentType, ContextType>;
  listBucketsDO?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  LocalState?: Resolver<ResolversTypes['LocalState'], ParentType, ContextType>;
  locationById?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<QueryLocationByIdArgs, 'id'>>;
  locationContactById?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<QueryLocationContactByIdArgs, 'id'>>;
  locationContacts?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType>;
  locationContactsByLocation?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType, RequireFields<QueryLocationContactsByLocationArgs, 'locationId'>>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  locationsByCity?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationsByCityArgs, 'city'>>;
  locationsByLocationContactId?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationsByLocationContactIdArgs, 'id'>>;
  locationsPublic?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  locationsSortedByCity?: Resolver<Array<ResolversTypes['LocationsGroupedByCity']>, ParentType, ContextType>;
  locationsWithPagination?: Resolver<ResolversTypes['LocationsWithPagination'], ParentType, ContextType, Partial<QueryLocationsWithPaginationArgs>>;
  multipleCourseLessonsCollisionCheck?: Resolver<Array<ResolversTypes['CourseLessonWithIndexOfCourseLessonInCourse']>, ParentType, ContextType, RequireFields<QueryMultipleCourseLessonsCollisionCheckArgs, 'collitionCheckInput'>>;
  notificationsByBookerId?: Resolver<ResolversTypes['EmailNotificationWithPagination'], ParentType, ContextType, RequireFields<QueryNotificationsByBookerIdArgs, 'bookerId'>>;
  notificationsByBookerOrEmail?: Resolver<ResolversTypes['EmailNotificationWithPagination'], ParentType, ContextType, RequireFields<QueryNotificationsByBookerOrEmailArgs, 'bookerId'>>;
  notificationsByBooking?: Resolver<Array<ResolversTypes['EmailNotification']>, ParentType, ContextType, RequireFields<QueryNotificationsByBookingArgs, 'bookingId'>>;
  ownerEmail?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  paidScheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, Partial<QueryPaidScheduledPaymentsArgs>>;
  participationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<QueryParticipationPrerequisiteArgs, 'id'>>;
  participationPrerequisites?: Resolver<Array<ResolversTypes['ParticipationPrerequisites']>, ParentType, ContextType>;
  participationPrerequisitesByCourseTypeId?: Resolver<Array<ResolversTypes['ParticipationPrerequisites']>, ParentType, ContextType, RequireFields<QueryParticipationPrerequisitesByCourseTypeIdArgs, 'courseTypeId'>>;
  paymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<QueryPaymentMethodArgs, 'id'>>;
  paymentMethods?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  prerequisiteCourses?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, Partial<QueryPrerequisiteCoursesArgs>>;
  reversalInvoiceBodyById?: Resolver<ResolversTypes['ReversalInvoice'], ParentType, ContextType, RequireFields<QueryReversalInvoiceBodyByIdArgs, 'id'>>;
  reversalInvoices?: Resolver<ResolversTypes['ReversalInvoiceWithPagination'], ParentType, ContextType, Partial<QueryReversalInvoicesArgs>>;
  scheduledPayment?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentArgs, 'id'>>;
  scheduledPaymentById?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<QueryScheduledPaymentByIdArgs, 'id'>>;
  scheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, Partial<QueryScheduledPaymentsArgs>>;
  scheduledPaymentsByBookerId?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentsByBookerIdArgs, 'bookerId'>>;
  scheduledPaymentsByBookingId?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentsByBookingIdArgs, 'bookingId'>>;
  scheduledPaymentSummary?: Resolver<ResolversTypes['ScheduledPaymentSummary'], ParentType, ContextType, RequireFields<QueryScheduledPaymentSummaryArgs, 'paymentDone'>>;
  searchAttendeesByFullName?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType, RequireFields<QuerySearchAttendeesByFullNameArgs, 'name'>>;
  searchBilledPayments?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QuerySearchBilledPaymentsArgs, 'searchKey'>>;
  searchBookerByStreetAndHouseNumberAndPpostCodeAndCity?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookerByStreetAndHouseNumberAndPpostCodeAndCityArgs, 'searchKey'>>;
  searchBookersByFirstAndLastName?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookersByFirstAndLastNameArgs, 'searchKey'>>;
  searchBookersByStreetAndHouseNumberAndPostCodeAndCity?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookersByStreetAndHouseNumberAndPostCodeAndCityArgs, 'searchKey'>>;
  searchBookingByBookingNumberAndBookingNumberPrefix?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchBookingByBookingNumberAndBookingNumberPrefixArgs, 'searchKey'>>;
  searchBookingByCourseLocationPrefixAndCourseNumber?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchBookingByCourseLocationPrefixAndCourseNumberArgs, 'searchKey'>>;
  searchCourseByCourseLocationPrefixAndCourseNumber?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QuerySearchCourseByCourseLocationPrefixAndCourseNumberArgs, 'searchKey'>>;
  searchCourseByInstructorFirstNameAndLastName?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QuerySearchCourseByInstructorFirstNameAndLastNameArgs, 'searchKey'>>;
  searchCustomerByCustomerNumberPrefixAndCustomerNumber?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QuerySearchCustomerByCustomerNumberPrefixAndCustomerNumberArgs, 'searchKey'>>;
  searchCustomerByFirstNameAndLastName?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QuerySearchCustomerByFirstNameAndLastNameArgs, 'searchKey'>>;
  searchInvoicesByCourseNumber?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QuerySearchInvoicesByCourseNumberArgs, 'courseNumber'>>;
  searchInvoicesByInvoiceNumberAndInvoiceNumberPrefix?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QuerySearchInvoicesByInvoiceNumberAndInvoiceNumberPrefixArgs, 'searchKey'>>;
  searchLocationsByName?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QuerySearchLocationsByNameArgs, 'name'>>;
  searchLocationsByPhoneNumberAndEmail?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QuerySearchLocationsByPhoneNumberAndEmailArgs, 'searchKey'>>;
  searchStaffByFullnameAndAddress?: Resolver<Array<ResolversTypes['Staff']>, ParentType, ContextType, RequireFields<QuerySearchStaffByFullnameAndAddressArgs, 'searchKey'>>;
  searchUnBilledPayments?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QuerySearchUnBilledPaymentsArgs, 'searchKey'>>;
  searchUnSettledBooking?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchUnSettledBookingArgs, 'searchKey'>>;
  sentEmailStatusFromMailjet?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QuerySentEmailStatusFromMailjetArgs, 'messageID'>>;
  settingEndUserApp?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  settingEndUserAppPublic?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType>;
  stressTestUserQuery?: Resolver<Array<ResolversTypes['UserType']>, ParentType, ContextType, RequireFields<QueryStressTestUserQueryArgs, 'tenantId'>>;
  swissQRFiles?: Resolver<Array<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<QueryTemplateArgs, 'id'>>;
  templateByType?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<QueryTemplateByTypeArgs, 'templateType'>>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  templatesWithPagination?: Resolver<ResolversTypes['TemplatesWithPagination'], ParentType, ContextType, Partial<QueryTemplatesWithPaginationArgs>>;
  tenantsWithPagination?: Resolver<ResolversTypes['KoOrganizationWithPagination'], ParentType, ContextType, Partial<QueryTenantsWithPaginationArgs>>;
  testFusioAuthFunctions?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryTestFusioAuthFunctionsArgs, 'userId'>>;
  unpaidScheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, Partial<QueryUnpaidScheduledPaymentsArgs>>;
  unpaidScheduledPaymentsZeroValues?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, Partial<QueryUnpaidScheduledPaymentsZeroValuesArgs>>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSetting']>, ParentType, ContextType>;
};

export type BookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booking'] = ResolversParentTypes['Booking']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  agbConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  attendanceListNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attendee?: Resolver<Maybe<ResolversTypes['Attendee']>, ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookedBy?: Resolver<ResolversTypes['BookedBy'], ParentType, ContextType>;
  bookingConfirmationSent?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  bookingNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cancelationSent?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  canceled?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  courseLessonBookings?: Resolver<Maybe<Array<ResolversTypes['CourseLessonBooking']>>, ParentType, ContextType>;
  courseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['CoursePrice'], ParentType, ContextType>;
  coursePriceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditedBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emailNotifications?: Resolver<Maybe<Array<ResolversTypes['EmailNotification']>>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  invoiceSent?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isSpecial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlySchedule?: Resolver<ResolversTypes['MonthlySchedule'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  noticeBooker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noticeStaff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  privacyStatementConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reversalInvoice?: Resolver<Maybe<ResolversTypes['ReversalInvoice']>, ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  waitingListConfirmationSent?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  waitListCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attendee'] = ResolversParentTypes['Attendee']> = {
  addition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  badgeXAttendee?: Resolver<Maybe<Array<ResolversTypes['AttendeeBadge']>>, ParentType, ContextType>;
  birthday?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  bookingsKo9?: Resolver<Maybe<Array<ResolversTypes['BookingsKo9']>>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  defaultPaymentMethodId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  member?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startedCourses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeBadge'] = ResolversParentTypes['AttendeeBadge']> = {
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  badge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType>;
  badgeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfAchivement?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Badge'] = ResolversParentTypes['Badge']> = {
  badgeXAttendee?: Resolver<Maybe<Array<ResolversTypes['AttendeeBadge']>>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type BookerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booker'] = ResolversParentTypes['Booker']> = {
  addition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attendees?: Resolver<Maybe<Array<ResolversTypes['Attendee']>>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dsgvo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  initialContact?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newsletter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nonDisclosureNotice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethods?: Resolver<Maybe<Array<ResolversTypes['PaymentMethod']>>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankTransfer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  cash?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditCardAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditcardNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cvc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  esr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paypalAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  sepaAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PaymentTransferType']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPayment'] = ResolversParentTypes['ScheduledPayment']> = {
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  invoiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  openAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paymentDone?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentDue?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentTransferType?: Resolver<Maybe<ResolversTypes['PaymentTransferType']>, ParentType, ContextType>;
  sepaClearance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ScheduledPaymentType'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  billed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  correctionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceBody?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  koOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  paymentDue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  qrReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KoOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['KoOrganization'] = ResolversParentTypes['KoOrganization']> = {
  companySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  companySettingsId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType>;
  settingsId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  subscriptionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  subscriptionType?: Resolver<ResolversTypes['SubscriptionType'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanySettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySetting'] = ResolversParentTypes['CompanySetting']> = {
  agb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BIC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessForm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commercialRegister?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creditorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  host?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IBAN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  koOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  localCourt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mailjetSenderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerFax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerStreet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerStreetNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerZip?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privacyStatement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qrIban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendFromEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Setting'] = ResolversParentTypes['Setting']> = {
  bankTransfer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cash?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  courseNumberFormat?: Resolver<ResolversTypes['CourseNumberFormat'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  esr?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  firstInstallmentDayOfMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstInstallmentMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  grossNet?: Resolver<ResolversTypes['GrossNet'], ParentType, ContextType>;
  holidayStateCode?: Resolver<Maybe<ResolversTypes['StateCode']>, ParentType, ContextType>;
  holidayStateColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceDueDays?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sendReversalInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sepaDebit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sevenIoApiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smsSenderName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vatAppearance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  createDate?: SubscriptionResolver<ResolversTypes['DateTime'], "createDate", ParentType, ContextType>;
  featureExport?: SubscriptionResolver<ResolversTypes['Boolean'], "featureExport", ParentType, ContextType>;
  featurePrerequisiteCourses?: SubscriptionResolver<ResolversTypes['Boolean'], "featurePrerequisiteCourses", ParentType, ContextType>;
  featureRefactoredCourseDetails?: SubscriptionResolver<ResolversTypes['Boolean'], "featureRefactoredCourseDetails", ParentType, ContextType>;
  featureSms?: SubscriptionResolver<ResolversTypes['Boolean'], "featureSms", ParentType, ContextType>;
  featureStaffLogin?: SubscriptionResolver<ResolversTypes['Boolean'], "featureStaffLogin", ParentType, ContextType>;
  featureWeekPlaner?: SubscriptionResolver<ResolversTypes['Boolean'], "featureWeekPlaner", ParentType, ContextType>;
  holidays?: SubscriptionResolver<ResolversTypes['Boolean'], "holidays", ParentType, ContextType>;
  id?: SubscriptionResolver<ResolversTypes['ID'], "id", ParentType, ContextType>;
  seatsAdmin?: SubscriptionResolver<ResolversTypes['Float'], "seatsAdmin", ParentType, ContextType>;
  seatsInstructor?: SubscriptionResolver<ResolversTypes['Float'], "seatsInstructor", ParentType, ContextType>;
  updateDate?: SubscriptionResolver<ResolversTypes['DateTime'], "updateDate", ParentType, ContextType>;
};

export type BookingsKo9Resolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingsKo9'] = ResolversParentTypes['BookingsKo9']> = {
  attendee?: Resolver<Maybe<ResolversTypes['Attendee']>, ParentType, ContextType>;
  attendeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstCourseLesson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  teilnahmeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']> = {
  additionalShortInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allowMonthlyPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowUpFrontPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  annotation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billedBookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingCountWithoutWaitlist?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingRestriction?: Resolver<Maybe<ResolversTypes['BookingRestriction']>, ParentType, ContextType>;
  bookingRestrictionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  canceledBookingsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  courseNumberFormat?: Resolver<ResolversTypes['CourseNumberFormat'], ParentType, ContextType>;
  coursePrice?: Resolver<Array<ResolversTypes['CoursePrice']>, ParentType, ContextType>;
  coursePrices?: Resolver<Array<ResolversTypes['CoursePrice']>, ParentType, ContextType>;
  courseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customCourseNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cycle?: Resolver<ResolversTypes['CourseCycle'], ParentType, ContextType>;
  enabledCourses?: Resolver<Maybe<Array<ResolversTypes['EnabledCourse']>>, ParentType, ContextType>;
  endDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endDateTimeFirstCourseLesson?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  freePlaces?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  installmentRates?: Resolver<Array<ResolversTypes['InstallmentRate']>, ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lessonCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxAttendees?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maxWaitlist?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAttendees?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  monthlySchedule?: Resolver<ResolversTypes['MonthlySchedule'], ParentType, ContextType>;
  paymentInterval?: Resolver<Maybe<ResolversTypes['PaymentInterval']>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  placeReservationsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  prefixedCourseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prerequisiteCourses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  settledBookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  settledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  unSettledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['CourseVisibilityResult'], ParentType, ContextType>;
  waitListCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  websiteInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingRestrictionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingRestriction'] = ResolversParentTypes['BookingRestriction']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockedPlaces?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes['Course']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expireAction?: Resolver<Maybe<ResolversTypes['ExpireAction']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  includeBookingList?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  includeWaitingList?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membersOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  prerequisiteCourses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visibleForAll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoursePrice'] = ResolversParentTypes['CoursePrice']> = {
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discountUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  includedEntranceFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isSpecial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseType'] = ResolversParentTypes['CourseType']> = {
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['CourseCategory']>, ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inWeekPlanner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participationPrerequisites?: Resolver<Maybe<Array<ResolversTypes['ParticipationPrerequisites']>>, ParentType, ContextType>;
  paymentInterval?: Resolver<Maybe<ResolversTypes['PaymentInterval']>, ParentType, ContextType>;
  selfBookerCourse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shortHandId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  websiteInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseCategory'] = ResolversParentTypes['CourseCategory']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  linkedCourseTypes?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipationPrerequisitesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipationPrerequisites'] = ResolversParentTypes['ParticipationPrerequisites']> = {
  courseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType>;
  courseTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  groupDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  minCheckCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionalInputFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type EnabledCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnabledCourse'] = ResolversParentTypes['EnabledCourse']> = {
  bookingRestriction?: Resolver<Maybe<ResolversTypes['BookingRestriction']>, ParentType, ContextType>;
  courseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType>;
  enabledPrerequisites?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  enabledRestriction?: Resolver<Maybe<ResolversTypes['BookingRestriction']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  prefixedCourseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visibility?: Resolver<Maybe<ResolversTypes['CourseVisibilityResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseVisibilityResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseVisibilityResult'] = ResolversParentTypes['CourseVisibilityResult']> = {
  hideAfterDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reason?: Resolver<Array<ResolversTypes['CourseNotVisibleReason']>, ParentType, ContextType>;
  restriction?: Resolver<ResolversTypes['CourseVisibilityRestriction'], ParentType, ContextType>;
  visibleForAll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseVisibilityRestrictionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseVisibilityRestriction'] = ResolversParentTypes['CourseVisibilityRestriction']> = {
  endDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expireAction?: Resolver<ResolversTypes['ExpireAction'], ParentType, ContextType>;
  inTimeRange?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membersOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  prerequisiteCourses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstallmentRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallmentRate'] = ResolversParentTypes['InstallmentRate']> = {
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  installmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  installmentDue?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  installmentType?: Resolver<ResolversTypes['InstallmentType'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Instructor'] = ResolversParentTypes['Instructor']> = {
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  activeCourseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  allowLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignedLocations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['SalutationType'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  abbreviation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  generalLocationContact?: Resolver<ResolversTypes['GeneralLocationContact'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lessons?: Resolver<Maybe<Array<ResolversTypes['CourseLesson']>>, ParentType, ContextType>;
  locationContacts?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralLocationContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneralLocationContact'] = ResolversParentTypes['GeneralLocationContact']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLesson'] = ResolversParentTypes['CourseLesson']> = {
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  courseLessonBookings?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  indexOfLessonInCourse?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonBooking'] = ResolversParentTypes['CourseLessonBooking']> = {
  attendance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseLesson?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType>;
  courseLessonId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venue'] = ResolversParentTypes['Venue']> = {
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lessons?: Resolver<Maybe<Array<ResolversTypes['CourseLesson']>>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationContact'] = ResolversParentTypes['LocationContact']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['SalutationType'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailNotification'] = ResolversParentTypes['EmailNotification']> = {
  attachments?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingDeleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  bookingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastMessageStatus?: Resolver<Maybe<ResolversTypes['MessageStatus']>, ParentType, ContextType>;
  mailjetTemplateId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageHistory?: Resolver<Maybe<ResolversTypes['MessageHistory']>, ParentType, ContextType>;
  messageHref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  messageStatus?: Resolver<Maybe<ResolversTypes['MessageStatusData']>, ParentType, ContextType>;
  receiverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageStatus'] = ResolversParentTypes['MessageStatus']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<Array<ResolversTypes['MessageStatusData']>, ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageStatusDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageStatusData'] = ResolversParentTypes['MessageStatusData']> = {
  ArrivedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  AttachmentCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  AttemptCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ContactAlt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ContactID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Delay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  DestinationID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  FilterTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IsClickTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsHTMLPartIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsOpenTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsTextPartIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsUnsubTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  MessageSize?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SenderID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SpamassassinScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SpamassRules?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  StatePermanent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['MailjetStatus'], ParentType, ContextType>;
  Subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageHistory'] = ResolversParentTypes['MessageHistory']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<Array<ResolversTypes['MessageHistoryData']>, ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageHistoryDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageHistoryData'] = ResolversParentTypes['MessageHistoryData']> = {
  Comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  EventAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  EventType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  State?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Useragent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UseragentID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReversalInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReversalInvoice'] = ResolversParentTypes['ReversalInvoice']> = {
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  koOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  qrReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reversalInvoiceBody?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reversalInvoiceFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeWithPagination'] = ResolversParentTypes['AttendeeWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserType'] = ResolversParentTypes['UserType']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookerWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookerWithPagination'] = ResolversParentTypes['BookerWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingWithPagination'] = ResolversParentTypes['BookingWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeesAgeAtCourseStartResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeesAgeAtCourseStart'] = ResolversParentTypes['AttendeesAgeAtCourseStart']> = {
  ageError?: Resolver<Maybe<ResolversTypes['AgeError']>, ParentType, ContextType>;
  attendeeAge?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attendeeBirthday?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  attendeesAgeAtFirstCourseLessonDate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstCourseLessonDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  maxAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxAgeAnniversary?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  minAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAgeAnniversary?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmsBalanceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmsBalanceResponse'] = ResolversParentTypes['SmsBalanceResponse']> = {
  balance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationCity'] = ResolversParentTypes['LocationCity']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonWithIndexOfCourseLessonInCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonWithIndexOfCourseLessonInCourse'] = ResolversParentTypes['CourseLessonWithIndexOfCourseLessonInCourse']> = {
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  courseLessonBookings?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  indexOfLessonInCourse?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  originalCourseLessonId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonWithPagination'] = ResolversParentTypes['CourseLessonWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseWithPagination'] = ResolversParentTypes['CourseWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Holiday'] = ResolversParentTypes['Holiday']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  holidayStateColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  koOrganizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  stateCode?: Resolver<ResolversTypes['StateCode'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HolidayType'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailNotificationWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailNotificationWithPagination'] = ResolversParentTypes['EmailNotificationWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['EmailNotification']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportedSepaXmlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportedSepaXml'] = ResolversParentTypes['ExportedSepaXml']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  object?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportedSepaXmlWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportedSepaXmlWithPagination'] = ResolversParentTypes['ExportedSepaXmlWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ExportedSepaXml']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaUser'] = ResolversParentTypes['FaUser']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  breachedPasswordLastCheckedInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  connectorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredLanguages?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  registrations?: Resolver<Maybe<Array<ResolversTypes['UserRegistration']>>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqueUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRegistrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegistration'] = ResolversParentTypes['UserRegistration']> = {
  applicationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  authenticationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredLanguages?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77AnalyticGroupByDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77AnalyticGroupByDate'] = ResolversParentTypes['Sms77AnalyticGroupByDate']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hlr?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inbound?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mnp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sms?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  usageEur?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  voice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSetting'] = ResolversParentTypes['UserSetting']> = {
  attendanceListSettings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  columnSettings?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  optimizedCourseCalendar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profilePictureUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookerIdMissmatchWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookerIdMissmatchWithPagination'] = ResolversParentTypes['BookerIdMissmatchWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['BookerIdMissmatch']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookerIdMissmatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookerIdMissmatch'] = ResolversParentTypes['BookerIdMissmatch']> = {
  attachments?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingDeleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  bookingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentBooker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  foundByMailBooker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastMessageStatus?: Resolver<Maybe<ResolversTypes['MessageStatus']>, ParentType, ContextType>;
  mailjetTemplateId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageHistory?: Resolver<Maybe<ResolversTypes['MessageHistory']>, ParentType, ContextType>;
  messageHref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  messageStatus?: Resolver<Maybe<ResolversTypes['MessageStatusData']>, ParentType, ContextType>;
  receiverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentOfBucketResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentOfBucket'] = ResolversParentTypes['ContentOfBucket']> = {
  ContentOfBucketResponse?: Resolver<Maybe<ResolversTypes['ContentOfBucketResponse']>, ParentType, ContextType>;
  Files?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  Folders?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentOfBucketResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentOfBucketResponse'] = ResolversParentTypes['ContentOfBucketResponse']> = {
  CommonPrefixes?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  Contents?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  Delimiter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EncodingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsTruncated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Marker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MaxKeys?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextMarker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  ETag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  LastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pdfBlob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StorageClass?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MjmlTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MjmlTemplate'] = ResolversParentTypes['MjmlTemplate']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailjetSenderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailjetSenderResponse'] = ResolversParentTypes['MailjetSenderResponse']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<Array<ResolversTypes['MailjetSender']>, ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailjetSenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailjetSender'] = ResolversParentTypes['MailjetSender']> = {
  CreatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DNSID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  EmailType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  IsDefaultSender?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageStructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageStructure'] = ResolversParentTypes['PageStructure']> = {
  pages?: Resolver<Array<ResolversTypes['Page']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  content?: Resolver<ResolversTypes['PageContent'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageContent'] = ResolversParentTypes['PageContent']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subPages?: Resolver<Maybe<Array<ResolversTypes['Page']>>, ParentType, ContextType>;
  subTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailjetMessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailjetMessageResponse'] = ResolversParentTypes['MailjetMessageResponse']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<Array<ResolversTypes['MailjetMessage']>, ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailjetMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailjetMessage'] = ResolversParentTypes['MailjetMessage']> = {
  ArrivedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  AttachmentCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  AttemptCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  CampaignID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContactAlt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  CustomID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Delay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  DestinationID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  FilterTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  IsClickTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsHTMLPartIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsOpenTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsTextPartIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  IsUnsubTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  MessageSize?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SenderID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SpamassassinScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  SpamassRules?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatePermanent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['MailjetStatus'], ParentType, ContextType>;
  Subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77JournalOutboundResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77JournalOutbound'] = ResolversParentTypes['Sms77JournalOutbound']> = {
  channel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dlr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dlrTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  foreignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mccmnc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77PricingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77PricingResponse'] = ResolversParentTypes['Sms77PricingResponse']> = {
  countCountries?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countNetworks?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countries?: Resolver<Array<ResolversTypes['Sms77CountryPricing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77CountryPricingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77CountryPricing'] = ResolversParentTypes['Sms77CountryPricing']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  networks?: Resolver<Array<ResolversTypes['CountryNetwork']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryNetworkResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryNetwork'] = ResolversParentTypes['CountryNetwork']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  mcc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mncs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  networkName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmsReceiverResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmsReceiver'] = ResolversParentTypes['SmsReceiver']> = {
  bookerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prefixedBookingNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDetailsType'] = ResolversParentTypes['UserDetailsType']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instructorDetails?: Resolver<Maybe<ResolversTypes['InstructorDetailsView']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loginAttempts?: Resolver<Maybe<Array<ResolversTypes['LoginAttempt']>>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSetting']>, ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorDetailsViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstructorDetailsView'] = ResolversParentTypes['InstructorDetailsView']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginAttemptResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginAttempt'] = ResolversParentTypes['LoginAttempt']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loginCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LoginStatus'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayWithDaysResolvers<ContextType = any, ParentType extends ResolversParentTypes['HolidayWithDays'] = ResolversParentTypes['HolidayWithDays']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Array<ResolversTypes['DateTime']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  holidayStateColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  koOrganizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  stateCode?: Resolver<ResolversTypes['StateCode'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HolidayType'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstructorsWithPagination'] = ResolversParentTypes['InstructorsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceWithPagination'] = ResolversParentTypes['InvoiceWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalState'] = ResolversParentTypes['LocalState']> = {
  bookAttendees?: Resolver<ResolversTypes['BookAttendees'], ParentType, ContextType>;
  createCourse?: Resolver<ResolversTypes['CreateCourse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookAttendeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookAttendees'] = ResolversParentTypes['BookAttendees']> = {
  selectedAttendee?: Resolver<Maybe<ResolversTypes['SelectedAttendee']>, ParentType, ContextType>;
  selectedCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectedAttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectedAttendee'] = ResolversParentTypes['SelectedAttendee']> = {
  bookedCourseIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCourse'] = ResolversParentTypes['CreateCourse']> = {
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationsGroupedByCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationsGroupedByCity'] = ResolversParentTypes['LocationsGroupedByCity']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['LocationGroupedByCityLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGroupedByCityLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationGroupedByCityLocation'] = ResolversParentTypes['LocationGroupedByCityLocation']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['LocationGroupedByCityVenue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGroupedByCityVenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationGroupedByCityVenue'] = ResolversParentTypes['LocationGroupedByCityVenue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationsWithPagination'] = ResolversParentTypes['LocationsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentsWithPagination'] = ResolversParentTypes['ScheduledPaymentsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  swissQRFile?: Resolver<Maybe<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valutaDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwissQrFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwissQRFile'] = ResolversParentTypes['SwissQRFile']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  records?: Resolver<Maybe<Array<ResolversTypes['SwissQRFileRecord']>>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwissQrFileRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwissQRFileRecord'] = ResolversParentTypes['SwissQRFileRecord']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType>;
  successful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  swissQRFile?: Resolver<Maybe<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  swissQRFileId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  swissQrRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valutaDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReversalInvoiceWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReversalInvoiceWithPagination'] = ResolversParentTypes['ReversalInvoiceWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ReversalInvoice']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentSummary'] = ResolversParentTypes['ScheduledPaymentSummary']> = {
  filtered?: Resolver<ResolversTypes['ScheduledPaymentSummaryPart'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['ScheduledPaymentSummaryPart'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentSummaryPartResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentSummaryPart'] = ResolversParentTypes['ScheduledPaymentSummaryPart']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffResolvers<ContextType = any, ParentType extends ResolversParentTypes['Staff'] = ResolversParentTypes['Staff']> = {
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salutation?: Resolver<Maybe<ResolversTypes['SalutationType']>, ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingEndUserAppResolvers<ContextType = any, ParentType extends ResolversParentTypes['SettingEndUserApp'] = ResolversParentTypes['SettingEndUserApp']> = {
  colors?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  courseDetails?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  courseTable?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  hideAfterDays?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  optionalInputFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  passwordlessIdCodeLength?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  passwordlessLoginEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  textEntries?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  footerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  includeCourseDetails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mailDataType?: Resolver<Maybe<ResolversTypes['MailDataType']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplatesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplatesWithPagination'] = ResolversParentTypes['TemplatesWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KoOrganizationWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['KoOrganizationWithPagination'] = ResolversParentTypes['KoOrganizationWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['KoOrganization']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addBookableCourses?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddBookableCoursesArgs, 'bookableCourseIds' | 'prerequisiteCourseId'>>;
  addBookerAsAttendee?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationAddBookerAsAttendeeArgs, 'bookerId'>>;
  addDefaultTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationAddDefaultTemplateArgs, 'newTemplateData'>>;
  addFaUserByBookerId?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<MutationAddFaUserByBookerIdArgs, 'bookerId'>>;
  addFolderDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationAddFolderDoArgs, 'folderName'>>;
  addMultiplePlaceReservations?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationAddMultiplePlaceReservationsArgs, 'count' | 'courseId'>>;
  addNamedScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationAddNamedScheduledPaymentArgs, 'addNamedScheduledPaymentInput'>>;
  addPage?: Resolver<ResolversTypes['PageStructure'], ParentType, ContextType, RequireFields<MutationAddPageArgs, 'newPageInput'>>;
  addPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<MutationAddPaymentMethodArgs, 'bookerId' | 'paymentMethodType'>>;
  addRoleToOrganization?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddRoleToOrganizationArgs, 'role'>>;
  billMultipleBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationBillMultipleBookingsArgs, 'ids'>>;
  bookAttendeeSetSelectedAttendee?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, Partial<MutationBookAttendeeSetSelectedAttendeeArgs>>;
  bookAttendeeSetSelectedCourse?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, Partial<MutationBookAttendeeSetSelectedCourseArgs>>;
  cancelBookingAndGenerateReversalInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelBookingAndGenerateReversalInvoiceArgs, 'id' | 'sendMail'>>;
  changeAttendeeMemberStatus?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<MutationChangeAttendeeMemberStatusArgs, 'id' | 'member'>>;
  changeDefaultPaymentMethod?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<MutationChangeDefaultPaymentMethodArgs, 'attendeeId' | 'paymentMethodId'>>;
  changeForgottenPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangeForgottenPasswordArgs, 'changePasswordId' | 'newPassword'>>;
  completePasswordlessFlow?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCompletePasswordlessFlowArgs, 'loginCode'>>;
  completePasswordlessFlowEndUserApp?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCompletePasswordlessFlowEndUserAppArgs, 'loginCode'>>;
  confirmAGB?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmAgbArgs, 'agbConfirmed' | 'bookingId'>>;
  confirmPrivacyStatement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmPrivacyStatementArgs, 'bookingId' | 'privacyStatementConfirmed'>>;
  convertBookingToWaitlist?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationConvertBookingToWaitlistArgs, 'bookingId'>>;
  convertPhoneNumbersToInternationalFormat?: Resolver<Array<ResolversTypes['Sms77FormatApiResponse']>, ParentType, ContextType, RequireFields<MutationConvertPhoneNumbersToInternationalFormatArgs, 'phoneNumber'>>;
  convertWaitlistToBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationConvertWaitlistToBookingArgs, 'bookingId'>>;
  copyAttendeeToCourse?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationCopyAttendeeToCourseArgs, 'bookingId' | 'targetCourseId'>>;
  createAndRegisterAdmin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterAdminArgs, 'tenantId' | 'userInput'>>;
  createAndRegisterThirdPartyUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterThirdPartyUserArgs, 'tenantId' | 'thirdPartyUserInput'>>;
  createAndRegisterThirdPartyUserAndBooker?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterThirdPartyUserAndBookerArgs, 'newBookingData' | 'thirdPartyUserInput'>>;
  createAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationCreateAttendeeBadgeArgs, 'newAttendeeBadgeInput'>>;
  createAttendeeBadgeByBadgeName?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationCreateAttendeeBadgeByBadgeNameArgs, 'newAttendeeBadgeByBadgeNameInput'>>;
  createBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationCreateBadgeArgs, 'newBadgeInput'>>;
  createBooker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationCreateBookerArgs, 'newBookerData'>>;
  createBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationCreateBookingArgs, 'newBookingData' | 'sendMail'>>;
  createBookingRestriction?: Resolver<ResolversTypes['BookingRestriction'], ParentType, ContextType, RequireFields<MutationCreateBookingRestrictionArgs, 'newBookingRestrictionInput'>>;
  createCorrectionInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationCreateCorrectionInvoiceArgs, 'bookingId' | 'sendMail'>>;
  createCourse?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<MutationCreateCourseArgs, 'newCourseData'>>;
  createCourseCategory?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationCreateCourseCategoryArgs, 'newCourseCategoryData'>>;
  createCourseLesson?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonArgs, 'newCourseLessonData'>>;
  createCourseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonBookingArgs, 'newCourseLessonBookingInput'>>;
  createCourseLessonBookingsByCourseId?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonBookingsByCourseIdArgs, 'courseId'>>;
  createCourseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<MutationCreateCourseLessonsArgs, 'newCourseLessonData'>>;
  createCourseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<MutationCreateCourseTypeArgs, 'newCourseTypeData'>>;
  createDummyCamtFileForMultiplePayments?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateDummyCamtFileForMultiplePaymentsArgs, 'createDummySwissCamtByScheduledPaymentsInput'>>;
  createFaUserWithOrAddEndUserRights?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<MutationCreateFaUserWithOrAddEndUserRightsArgs, 'email'>>;
  createInstallmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<MutationCreateInstallmentRateArgs, 'newInstallmentRateInput'>>;
  createInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationCreateInvoiceArgs, 'newInvoiceData' | 'sendMail'>>;
  createInvoicesByBookingIds?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<MutationCreateInvoicesByBookingIdsArgs, 'bookingIds' | 'sendMail'>>;
  createInvoicesByCourseId?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<MutationCreateInvoicesByCourseIdArgs, 'courseId' | 'sendMail'>>;
  createLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<MutationCreateLocationArgs, 'newLocationData'>>;
  createLocationContact?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<MutationCreateLocationContactArgs, 'newLocationContactData'>>;
  createMailjetSender?: Resolver<ResolversTypes['CreateMailjetSenderOutput'], ParentType, ContextType, RequireFields<MutationCreateMailjetSenderArgs, 'createMailjetSenderInput'>>;
  createOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'koOrganizationData'>>;
  createParticipationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<MutationCreateParticipationPrerequisiteArgs, 'newParticipationPrerequisitesInput'>>;
  createSepaXmlById?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateSepaXmlByIdArgs, 'createSingleSepaXmlInput'>>;
  createSepaXmlByTimeRange?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateSepaXmlByTimeRangeArgs, 'createSepaXmlByTimeRangeInput'>>;
  createSettingEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateSettingEndUserAppArgs, 'settingEndUserAppInput'>>;
  createSettingEndUserAppWithDefaultValues?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  createTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'newTemplateData'>>;
  deleteAllPlaceReservationsByCourseId?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<MutationDeleteAllPlaceReservationsByCourseIdArgs, 'courseId'>>;
  deleteAttendee?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAttendeeArgs, 'id'>>;
  deleteAttendeeBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAttendeeBadgeArgs, 'id'>>;
  deleteBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBadgeArgs, 'id'>>;
  deleteBooker?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBookerArgs, 'id'>>;
  deleteBookingRestriction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBookingRestrictionArgs, 'bookingRestrictionId'>>;
  deleteCategoryById?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationDeleteCategoryByIdArgs, 'id'>>;
  deleteCompanyLogo?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  deleteCourseLessonBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCourseLessonBookingArgs, 'id'>>;
  deleteFileDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationDeleteFileDoArgs, 'fileName'>>;
  deleteFolderDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationDeleteFolderDoArgs, 'folderName'>>;
  deleteFusionAuthUserByIds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFusionAuthUserByIdsArgs, 'koOrganizationId' | 'userIds'>>;
  deleteFusionAuthUsersByEmails?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationDeleteFusionAuthUsersByEmailsArgs, 'emailAddresses'>>;
  deleteInstallmentRate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteInstallmentRateArgs, 'id'>>;
  deleteInstallmentRatesByCourseId?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<MutationDeleteInstallmentRatesByCourseIdArgs, 'courseId'>>;
  deletePage?: Resolver<ResolversTypes['PageStructure'], ParentType, ContextType, RequireFields<MutationDeletePageArgs, 'pageId'>>;
  deleteScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationDeleteScheduledPaymentArgs, 'id'>>;
  deleteSelectionOfWaitlist?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<MutationDeleteSelectionOfWaitlistArgs, 'bookingIds'>>;
  deleteTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'id'>>;
  disablePasswordlessLoginEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  downloadFileDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationDownloadFileDoArgs, 'fileName'>>;
  enablePasswordlessLoginEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationEnablePasswordlessLoginEndUserAppArgs, 'passwordlessIdCodeLength'>>;
  fetchByYear?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType, RequireFields<MutationFetchByYearArgs, 'year'>>;
  fetchByYearAndStateCode?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType, RequireFields<MutationFetchByYearAndStateCodeArgs, 'stateCode' | 'year'>>;
  fetchPublicHolidaysByYear?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType, RequireFields<MutationFetchPublicHolidaysByYearArgs, 'year'>>;
  forgetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgetPasswordArgs, 'email'>>;
  forgotPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'forgotPasswordWithStateInput'>>;
  generatePaymentSchedulePreview?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType, RequireFields<MutationGeneratePaymentSchedulePreviewArgs, 'bookingPrice' | 'lessonDates' | 'monthlySchedule' | 'paymentType'>>;
  generateScheduledPaymentsByCourse?: Resolver<ResolversTypes['ScheduledPaymentPreview'], ParentType, ContextType, RequireFields<MutationGenerateScheduledPaymentsByCourseArgs, 'courseId'>>;
  generateScheduledPaymentsForSettledBooking?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<MutationGenerateScheduledPaymentsForSettledBookingArgs, 'bookingId'>>;
  generateUpdatedSchedulesNewBookingPrice?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<MutationGenerateUpdatedSchedulesNewBookingPriceArgs, 'bookingPrice' | 'scheduledPayments'>>;
  importBookers?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationImportBookersArgs, 'importBookersInput'>>;
  logging?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLoggingArgs, 'input'>>;
  logoutUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mergeBookers?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationMergeBookersArgs, 'mergedBookerId' | 'updateBookerData'>>;
  modifyScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationModifyScheduledPaymentArgs, 'modifyScheduledPaymentInput'>>;
  passwordlessLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPasswordlessLoginArgs, 'loginData'>>;
  passwordlessLoginEndUserApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPasswordlessLoginEndUserAppArgs, 'endUserPasswordlessLoginInput'>>;
  refactoredCourseDetails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRefactoredCourseDetailsArgs, 'courseId'>>;
  refreshAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRefreshAccessTokenArgs, 'refreshToken'>>;
  removeAllBookersOfTenant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removeAllBookings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removeBookableCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveBookableCourseArgs, 'bookableCourseId' | 'prerquisiteCourseId'>>;
  removeBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveBookingArgs, 'id'>>;
  removeBookings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveBookingsArgs, 'ids'>>;
  removeCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseArgs, 'id'>>;
  removeCourseLesson?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseLessonArgs, 'id'>>;
  removeCourseType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseTypeArgs, 'id'>>;
  removeInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveInstructorArgs, 'id'>>;
  removeLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveLocationArgs, 'id'>>;
  removeLocationContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveLocationContactArgs, 'id'>>;
  removeParticipationPrerequisite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveParticipationPrerequisiteArgs, 'id'>>;
  renameFileDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationRenameFileDoArgs, 'fileName' | 'newFileName'>>;
  renameFolderDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationRenameFolderDoArgs, 'folderName' | 'newFolderName'>>;
  reOrderBadges?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReOrderBadgesArgs, 'movedBadgeId' | 'targetBadgeId'>>;
  resendBookingConfirmation?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationResendBookingConfirmationArgs, 'bookingId'>>;
  resendEmailVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResendEmailVerificationArgs, 'userId'>>;
  resetAllUnsetteledScheduledPaymentsOfCourse?: Resolver<ResolversTypes['ResetScheduledPaymentsOfCourseResult'], ParentType, ContextType, RequireFields<MutationResetAllUnsetteledScheduledPaymentsOfCourseArgs, 'courseId'>>;
  resetScheduledPaymentsOfBooking?: Resolver<ResolversTypes['ResetScheduledPaymentsOfBookingResult'], ParentType, ContextType, RequireFields<MutationResetScheduledPaymentsOfBookingArgs, 'bookingId'>>;
  rollbackPaymentStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRollbackPaymentStatusArgs, 'id'>>;
  saveAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationSaveAdminArgs, 'saveAdminData'>>;
  saveInstructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<MutationSaveInstructorArgs, 'saveInstructorData'>>;
  sendBlankMailToMultipleBookers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendBlankMailToMultipleBookersArgs, 'input'>>;
  sendBookingConfirmation?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingConfirmationArgs, 'bookingId'>>;
  sendBookingInvoice?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingInvoiceArgs, 'bookingId'>>;
  sendBookingInvoicesByBookingIds?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingInvoicesByBookingIdsArgs, 'bookingIds'>>;
  sendEmailsToSelectionOfCourse?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailsToSelectionOfCourseArgs, 'sendMailToSelectionOfCourseInput'>>;
  sendEmailTemplate?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailTemplateArgs, 'sendTemplateInput'>>;
  sendEmailToSelectionOfAttendees?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailToSelectionOfAttendeesArgs, 'sendMailToSelectionOfAttendeesInput'>>;
  sendEmailToSelectionOfBookers?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailToSelectionOfBookersArgs, 'sendMailToSelectionOfBookersInput'>>;
  sendEmailToSelectionOfScheduledPayments?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailToSelectionOfScheduledPaymentsArgs, 'sendMailToSelectionOfScheduledPaymentsInput'>>;
  sendEmailToSelectionOfStaff?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailToSelectionOfStaffArgs, 'sendMailToSelectionOfStaffInput'>>;
  sendInvoicesToSelectionOfBookings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendInvoicesToSelectionOfBookingsArgs, 'sendInvoicesToSelectionOfBookingsInput'>>;
  sendMail?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType, RequireFields<MutationSendMailArgs, 'sendParamsMessageInput'>>;
  sendMailToSelectionOfBookings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendMailToSelectionOfBookingsArgs, 'sendMailToSelectionOfBookingInput'>>;
  sendSms?: Resolver<ResolversTypes['Sms77JsonResponse'], ParentType, ContextType, RequireFields<MutationSendSmsArgs, 'smsParams'>>;
  sendTestMail?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, Partial<MutationSendTestMailArgs>>;
  sendTestMailBooker?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendTestMailBookerArgs, 'bookerId'>>;
  setIncludeCourseDetails?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationSetIncludeCourseDetailsArgs, 'includeCourseDetails' | 'templateId'>>;
  setMultipleScheduledPaymentsPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetMultipleScheduledPaymentsPaidArgs, 'paymentIds'>>;
  setNewCustomerNumber?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationSetNewCustomerNumberArgs, 'newCustomerNumber'>>;
  setNewPaymentAmount?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetNewPaymentAmountArgs, 'id' | 'paymentAmount'>>;
  setNewPaymentDoneDate?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetNewPaymentDoneDateArgs, 'id' | 'paymentDoneDate'>>;
  setScheduledPaymentPaid?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetScheduledPaymentPaidArgs, 'id'>>;
  setScheduledPaymentUnpaid?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetScheduledPaymentUnpaidArgs, 'id'>>;
  setSepaClearance?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetSepaClearanceArgs, 'id' | 'sepaClearance'>>;
  singleUpload?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSingleUploadArgs, 'file'>>;
  softDeleteBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationSoftDeleteBadgeArgs, 'id'>>;
  thirdPartyLogin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationThirdPartyLoginArgs, 'thirdPartyLoginInput'>>;
  transferAttendeeToBooker?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<MutationTransferAttendeeToBookerArgs, 'attendeeId' | 'newBookerId'>>;
  transferAttendeeToCourse?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationTransferAttendeeToCourseArgs, 'bookingId' | 'targetCourseId'>>;
  transferMultipleBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationTransferMultipleBookingsArgs, 'transferMultipleBookingsInput'>>;
  unBillMultipleBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationUnBillMultipleBookingsArgs, 'ids'>>;
  updateAttendanceListNote?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateAttendanceListNoteArgs, 'bookingId'>>;
  updateAttendanceListSettings?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUpdateAttendanceListSettingsArgs, 'updateAttendanceListSettingsInput'>>;
  updateAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationUpdateAttendeeBadgeArgs, 'editAttendeeBadgeInput'>>;
  updateBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationUpdateBadgeArgs, 'updateBadgeInput'>>;
  updateBooker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationUpdateBookerArgs, 'fromEndUserApp' | 'updateBookerData'>>;
  updateBookerAccountEndUserApp?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationUpdateBookerAccountEndUserAppArgs, 'fromEndUserApp' | 'updateBookerEndUserAppData'>>;
  updateBookerCredit?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationUpdateBookerCreditArgs, 'bookerId' | 'credit'>>;
  updateBookerIdMissmatch?: Resolver<ResolversTypes['EmailNotification'], ParentType, ContextType, RequireFields<MutationUpdateBookerIdMissmatchArgs, 'newBookerId' | 'notificationId'>>;
  updateBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateBookingArgs, 'editBookingData' | 'id'>>;
  updateBookingPaymentMethod?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateBookingPaymentMethodArgs, 'bookingId' | 'paymentMethodId'>>;
  updateBookingRestriction?: Resolver<ResolversTypes['BookingRestriction'], ParentType, ContextType, RequireFields<MutationUpdateBookingRestrictionArgs, 'updateBookingRestrictionInput'>>;
  updateCompanyAgb?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanyAgbArgs, 'agb'>>;
  updateCompanyPrivacyStatement?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanyPrivacyStatementArgs, 'privacyStatement'>>;
  updateCompanySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanySettingsArgs, 'newCompanyData'>>;
  updateCourse?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<MutationUpdateCourseArgs, 'editCourseData' | 'id'>>;
  updateCourseAnnotation?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<MutationUpdateCourseAnnotationArgs, 'id'>>;
  updateCourseCategory?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationUpdateCourseCategoryArgs, 'id' | 'newCourseCategoryData'>>;
  updateCourseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<MutationUpdateCourseLessonBookingArgs, 'updateCourseLessonBookingInput'>>;
  updateCoursesStartDateTime?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, Partial<MutationUpdateCoursesStartDateTimeArgs>>;
  updateCourseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<MutationUpdateCourseTypeArgs, 'id' | 'newCourseTypeData'>>;
  updateCustomCompanyHolidays?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType, RequireFields<MutationUpdateCustomCompanyHolidaysArgs, 'holidayInputs'>>;
  updateInstallmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<MutationUpdateInstallmentRateArgs, 'updateInstallmentRateInput'>>;
  updateInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationUpdateInvoiceArgs, 'editInvoiceData' | 'id'>>;
  updateLocalLocationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, Partial<MutationUpdateLocalLocationIdArgs>>;
  updateLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<MutationUpdateLocationArgs, 'editLocationData'>>;
  updateLocationContact?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<MutationUpdateLocationContactArgs, 'editLocationContactData'>>;
  updateManyScheduledPaymentIds?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationUpdateManyScheduledPaymentIdsArgs, 'editScheduledPaymentData' | 'paymentIds'>>;
  updateMultipleBookerIdMissmatches?: Resolver<Array<ResolversTypes['EmailNotification']>, ParentType, ContextType, RequireFields<MutationUpdateMultipleBookerIdMissmatchesArgs, 'updateMultipleBookerIdMissmatchesInput'>>;
  updateMultipleCoursesShowInWeb?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<MutationUpdateMultipleCoursesShowInWebArgs, 'courseIds' | 'showInWeb'>>;
  updateNoticeStaff?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateNoticeStaffArgs, 'id' | 'noticeStaff'>>;
  updateOrganizationSubscriptionType?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType, RequireFields<MutationUpdateOrganizationSubscriptionTypeArgs, 'koOrganizationId' | 'subscriptionType'>>;
  updatePage?: Resolver<ResolversTypes['PageStructure'], ParentType, ContextType, RequireFields<MutationUpdatePageArgs, 'editPageInput'>>;
  updateParticipationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<MutationUpdateParticipationPrerequisiteArgs, 'updateParticipationPrerequisitesInput'>>;
  updateScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationUpdateScheduledPaymentArgs, 'editScheduledPaymentData' | 'id'>>;
  updateSepaPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<MutationUpdateSepaPaymentMethodArgs, 'input'>>;
  updateSettingEndUserApp?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType, RequireFields<MutationUpdateSettingEndUserAppArgs, 'updateSettingEndUserAppInput'>>;
  updateSettings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType, RequireFields<MutationUpdateSettingsArgs, 'newSettingsData'>>;
  updateSubscriptionFeatures?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType, RequireFields<MutationUpdateSubscriptionFeaturesArgs, 'features' | 'subscriptionId'>>;
  updateTableColumnSettings?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUpdateTableColumnSettingsArgs, 'updateTableColumnSettingsInput'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'editTemplateData' | 'id'>>;
  uploadCompanyLogo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationUploadCompanyLogoArgs, 'file'>>;
  uploadDummyFile?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  uploadFilesDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationUploadFilesDoArgs, 'fileArray' | 'path'>>;
  uploadSingleFileDo?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationUploadSingleFileDoArgs, 'file' | 'path'>>;
  userSettingSetOptimizedCourseCalendar?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUserSettingSetOptimizedCourseCalendarArgs, 'optimize'>>;
  userSettingsUpdateOrCreate?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUserSettingsUpdateOrCreateArgs, 'userSettingsInput'>>;
  verifyBookersData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationVerifyBookersDataArgs, 'importBookersInput'>>;
};

export type Sms77FormatApiResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77FormatApiResponse'] = ResolversParentTypes['Sms77FormatApiResponse']> = {
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryIso?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  international?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  internationalFormatted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  national?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  networkType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMailjetSenderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMailjetSenderOutput'] = ResolversParentTypes['CreateMailjetSenderOutput']> = {
  CreatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DNSID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  EmailType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  IsDefaultSender?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentPreview'] = ResolversParentTypes['ScheduledPaymentPreview']> = {
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  memberDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  scheduledPayments?: Resolver<Array<ResolversTypes['ScheduledPaymentSimple']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentSimpleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentSimple'] = ResolversParentTypes['ScheduledPaymentSimple']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paymentDue?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ScheduledPaymentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetScheduledPaymentsOfCourseResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetScheduledPaymentsOfCourseResult'] = ResolversParentTypes['ResetScheduledPaymentsOfCourseResult']> = {
  bookings?: Resolver<Array<ResolversTypes['ResetScheduledPaymentsOfBookingResult']>, ParentType, ContextType>;
  courseDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetScheduledPaymentsOfBookingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetScheduledPaymentsOfBookingResult'] = ResolversParentTypes['ResetScheduledPaymentsOfBookingResult']> = {
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creditedBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currentScheduledPaymentsAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  currentScheduledPaymentsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currentScheduledPaymnetsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  installmentPlanAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  newScheduledPaymentsAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  newScheduledPaymentsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['RateDetail'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateDetail'] = ResolversParentTypes['RateDetail']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  due?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']> = {
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salutation?: Resolver<Maybe<ResolversTypes['SalutationType']>, ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type Sms77JsonResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77JsonResponse'] = ResolversParentTypes['Sms77JsonResponse']> = {
  balance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  debug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messages?: Resolver<Array<ResolversTypes['Sms77Message']>, ParentType, ContextType>;
  smsType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77Message'] = ResolversParentTypes['Sms77Message']> = {
  encoding?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBinary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  udh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Base'] = ResolversParentTypes['Base']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingHistory'] = ResolversParentTypes['BookingHistory']> = {
  action?: Resolver<ResolversTypes['BookingHistoryAction'], ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookedBy?: Resolver<ResolversTypes['BookedBy'], ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bookingNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingType?: Resolver<ResolversTypes['BookingType'], ParentType, ContextType>;
  canceled?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonBookingAttendanceListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonBookingAttendanceList'] = ResolversParentTypes['CourseLessonBookingAttendanceList']> = {
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType>;
  booker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseLessonBooking?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWithPaginationWithEnabledCoursesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseWithPaginationWithEnabledCourses'] = ResolversParentTypes['CourseWithPaginationWithEnabledCourses']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureIdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['SecureIdentity'] = ResolversParentTypes['SecureIdentity']> = {
  breachedPasswordLastCheckedInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connectorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqueUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77AnalyticBaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77AnalyticBase'] = ResolversParentTypes['Sms77AnalyticBase']> = {
  hlr?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inbound?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mnp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sms?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  usageEur?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  voice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Sms77JournalBaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sms77JournalBase'] = ResolversParentTypes['Sms77JournalBase']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffLoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffLogin'] = ResolversParentTypes['StaffLogin']> = {
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pages?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WithEnabledCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WithEnabledCourse'] = ResolversParentTypes['WithEnabledCourse']> = {
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  enabledCourses?: Resolver<Array<ResolversTypes['EnabledCourse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  Booking?: BookingResolvers<ContextType>;
  Attendee?: AttendeeResolvers<ContextType>;
  AttendeeBadge?: AttendeeBadgeResolvers<ContextType>;
  Badge?: BadgeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Booker?: BookerResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  ScheduledPayment?: ScheduledPaymentResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  KoOrganization?: KoOrganizationResolvers<ContextType>;
  CompanySetting?: CompanySettingResolvers<ContextType>;
  Setting?: SettingResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  BookingsKo9?: BookingsKo9Resolvers<ContextType>;
  Course?: CourseResolvers<ContextType>;
  BookingRestriction?: BookingRestrictionResolvers<ContextType>;
  CoursePrice?: CoursePriceResolvers<ContextType>;
  CourseType?: CourseTypeResolvers<ContextType>;
  CourseCategory?: CourseCategoryResolvers<ContextType>;
  ParticipationPrerequisites?: ParticipationPrerequisitesResolvers<ContextType>;
  JSONObject?: GraphQLScalarType;
  EnabledCourse?: EnabledCourseResolvers<ContextType>;
  CourseVisibilityResult?: CourseVisibilityResultResolvers<ContextType>;
  CourseVisibilityRestriction?: CourseVisibilityRestrictionResolvers<ContextType>;
  InstallmentRate?: InstallmentRateResolvers<ContextType>;
  Instructor?: InstructorResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  GeneralLocationContact?: GeneralLocationContactResolvers<ContextType>;
  CourseLesson?: CourseLessonResolvers<ContextType>;
  CourseLessonBooking?: CourseLessonBookingResolvers<ContextType>;
  Venue?: VenueResolvers<ContextType>;
  LocationContact?: LocationContactResolvers<ContextType>;
  EmailNotification?: EmailNotificationResolvers<ContextType>;
  MessageStatus?: MessageStatusResolvers<ContextType>;
  MessageStatusData?: MessageStatusDataResolvers<ContextType>;
  MessageHistory?: MessageHistoryResolvers<ContextType>;
  MessageHistoryData?: MessageHistoryDataResolvers<ContextType>;
  ReversalInvoice?: ReversalInvoiceResolvers<ContextType>;
  AttendeeWithPagination?: AttendeeWithPaginationResolvers<ContextType>;
  UserType?: UserTypeResolvers<ContextType>;
  BookerWithPagination?: BookerWithPaginationResolvers<ContextType>;
  BookingWithPagination?: BookingWithPaginationResolvers<ContextType>;
  AttendeesAgeAtCourseStart?: AttendeesAgeAtCourseStartResolvers<ContextType>;
  SmsBalanceResponse?: SmsBalanceResponseResolvers<ContextType>;
  LocationCity?: LocationCityResolvers<ContextType>;
  CourseLessonWithIndexOfCourseLessonInCourse?: CourseLessonWithIndexOfCourseLessonInCourseResolvers<ContextType>;
  CourseLessonWithPagination?: CourseLessonWithPaginationResolvers<ContextType>;
  CourseWithPagination?: CourseWithPaginationResolvers<ContextType>;
  Holiday?: HolidayResolvers<ContextType>;
  EmailNotificationWithPagination?: EmailNotificationWithPaginationResolvers<ContextType>;
  ExportedSepaXml?: ExportedSepaXmlResolvers<ContextType>;
  ExportedSepaXmlWithPagination?: ExportedSepaXmlWithPaginationResolvers<ContextType>;
  FaUser?: FaUserResolvers<ContextType>;
  UserRegistration?: UserRegistrationResolvers<ContextType>;
  Sms77AnalyticGroupByDate?: Sms77AnalyticGroupByDateResolvers<ContextType>;
  UserSetting?: UserSettingResolvers<ContextType>;
  BookerIdMissmatchWithPagination?: BookerIdMissmatchWithPaginationResolvers<ContextType>;
  BookerIdMissmatch?: BookerIdMissmatchResolvers<ContextType>;
  ContentOfBucket?: ContentOfBucketResolvers<ContextType>;
  ContentOfBucketResponse?: ContentOfBucketResponseResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  MjmlTemplate?: MjmlTemplateResolvers<ContextType>;
  MailjetSenderResponse?: MailjetSenderResponseResolvers<ContextType>;
  MailjetSender?: MailjetSenderResolvers<ContextType>;
  PageStructure?: PageStructureResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PageContent?: PageContentResolvers<ContextType>;
  MailjetMessageResponse?: MailjetMessageResponseResolvers<ContextType>;
  MailjetMessage?: MailjetMessageResolvers<ContextType>;
  Sms77JournalOutbound?: Sms77JournalOutboundResolvers<ContextType>;
  Sms77PricingResponse?: Sms77PricingResponseResolvers<ContextType>;
  Sms77CountryPricing?: Sms77CountryPricingResolvers<ContextType>;
  CountryNetwork?: CountryNetworkResolvers<ContextType>;
  SmsReceiver?: SmsReceiverResolvers<ContextType>;
  UserDetailsType?: UserDetailsTypeResolvers<ContextType>;
  InstructorDetailsView?: InstructorDetailsViewResolvers<ContextType>;
  LoginAttempt?: LoginAttemptResolvers<ContextType>;
  HolidayWithDays?: HolidayWithDaysResolvers<ContextType>;
  InstructorsWithPagination?: InstructorsWithPaginationResolvers<ContextType>;
  InvoiceWithPagination?: InvoiceWithPaginationResolvers<ContextType>;
  LocalState?: LocalStateResolvers<ContextType>;
  BookAttendees?: BookAttendeesResolvers<ContextType>;
  SelectedAttendee?: SelectedAttendeeResolvers<ContextType>;
  CreateCourse?: CreateCourseResolvers<ContextType>;
  LocationsGroupedByCity?: LocationsGroupedByCityResolvers<ContextType>;
  LocationGroupedByCityLocation?: LocationGroupedByCityLocationResolvers<ContextType>;
  LocationGroupedByCityVenue?: LocationGroupedByCityVenueResolvers<ContextType>;
  LocationsWithPagination?: LocationsWithPaginationResolvers<ContextType>;
  ScheduledPaymentsWithPagination?: ScheduledPaymentsWithPaginationResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  SwissQRFile?: SwissQrFileResolvers<ContextType>;
  SwissQRFileRecord?: SwissQrFileRecordResolvers<ContextType>;
  ReversalInvoiceWithPagination?: ReversalInvoiceWithPaginationResolvers<ContextType>;
  ScheduledPaymentSummary?: ScheduledPaymentSummaryResolvers<ContextType>;
  ScheduledPaymentSummaryPart?: ScheduledPaymentSummaryPartResolvers<ContextType>;
  Staff?: StaffResolvers<ContextType>;
  SettingEndUserApp?: SettingEndUserAppResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplatesWithPagination?: TemplatesWithPaginationResolvers<ContextType>;
  KoOrganizationWithPagination?: KoOrganizationWithPaginationResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Sms77FormatApiResponse?: Sms77FormatApiResponseResolvers<ContextType>;
  CreateMailjetSenderOutput?: CreateMailjetSenderOutputResolvers<ContextType>;
  ScheduledPaymentPreview?: ScheduledPaymentPreviewResolvers<ContextType>;
  ScheduledPaymentSimple?: ScheduledPaymentSimpleResolvers<ContextType>;
  ResetScheduledPaymentsOfCourseResult?: ResetScheduledPaymentsOfCourseResultResolvers<ContextType>;
  ResetScheduledPaymentsOfBookingResult?: ResetScheduledPaymentsOfBookingResultResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RateDetail?: RateDetailResolvers<ContextType>;
  Admin?: AdminResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  Sms77JsonResponse?: Sms77JsonResponseResolvers<ContextType>;
  Sms77Message?: Sms77MessageResolvers<ContextType>;
  Base?: BaseResolvers<ContextType>;
  BookingHistory?: BookingHistoryResolvers<ContextType>;
  CourseLessonBookingAttendanceList?: CourseLessonBookingAttendanceListResolvers<ContextType>;
  CourseWithPaginationWithEnabledCourses?: CourseWithPaginationWithEnabledCoursesResolvers<ContextType>;
  SecureIdentity?: SecureIdentityResolvers<ContextType>;
  Sms77AnalyticBase?: Sms77AnalyticBaseResolvers<ContextType>;
  Sms77JournalBase?: Sms77JournalBaseResolvers<ContextType>;
  StaffLogin?: StaffLoginResolvers<ContextType>;
  WithEnabledCourse?: WithEnabledCourseResolvers<ContextType>;
};

