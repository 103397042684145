import {TableType as BookerTableType} from "../../containers/customers/BookersOverviewColumns";
import {CurrencyShort} from "../renderAmount";
import {
    csvAddress,
    csvArray,
    csvBoolean,
    csvCustomerNumber,
    csvDate,
    csvGender,
    csvMoney,
    csvPaymentMethod,
} from "./TableColumnExports";

export const bookerValues = (
    key: string,
    row: BookerTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    outputFormat?: "pdf" | "csv",
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);

    const address = {
        street: row.street,
        houseNumber: row.houseNumber,
        postCode: row.postCode,
        city: row.city,
    };

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "customerNumber":
            return csvCustomerNumber(row.customerNumber, row.customerNumberPrefix);
        case "firstname":
            return `${row.firstname}`;
        case "lastname":
            return `${row.lastname}`;
        case "email":
            return `${row.email}`;
        case "fullname":
            return `${row.firstname} ${row.lastname}`;
        case "attendees":
            return csvArray(row.attendees.map((attendee) => `${attendee.firstname} ${attendee.lastname}`));
        case "address":
            return csvAddress(address);
        case "street":
            return `${address.street}`;
        case "houseNumber":
            return `${address.houseNumber}`;
        case "postCode":
            return `${address.postCode}`;
        case "city":
            return `${address.city}`;
        case "paymentMethods":
            return csvArray(row.paymentMethods.map((pm) => csvPaymentMethod(pm)));
        case "gender":
            return csvGender(row.gender);
        case "dsgvo":
            return csvBoolean(Boolean(row.dsgvo));
        case "newsletter":
            return csvBoolean(Boolean(row.newsletter));
        case "annotation":
            return `${row.annotation}`;
        case "addition":
            return `${row.addition}`;
        case "nonDisclosureNotice":
            return csvBoolean(Boolean(row.nonDisclosureNotice));
        case "mobileNumber":
            return `${row.mobileNumber}`;
        case "phoneNumber":
            return `${row.phoneNumber}`;
        case "healthInsuranceFund":
            return `${row.healthInsuranceFund}`;
        case "birthday":
            return csvDate(row.birthday);
        case "initialContact":
            return csvDate(row.initialContact);
        case "credit":
            return csvMoney(row.credit, currencyShort, outputFormat);
        default:
            return notExportable;
    }
};
